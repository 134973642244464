import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { LWModal } from 'redesign';
import { useArchiveProject } from './hooks/use-archive-project';
import { ProjectDetail } from '../../../../../shared/components/planner/project-detail-modal/detail/project-detail';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import { useDialog } from '../../../../../ogp/providers/dialog-provider';
import { useAlert } from '../../../../../shared/components/alerts';

type Props = {
  projectId: string;
  onClose: () => void;
};

export const ProjectPlanningDetail = (props: Props) => {
  const { projectId: id, onClose } = props;

  const archiveProject = useArchiveProject();
  const generatePath = useHqRelativePath();
  const { alertError } = useAlert();

  const navigate = useNavigate();
  const { openDialog, enableDialog, disableDialog, closeDialog } = useDialog();

  const handleEdit = useCallback(() => {
    navigate(generatePath(hqRoutes.ProjectsEdit, { id }));
  }, [id]);

  const handleDuplicate = useCallback(() => {
    navigate(generatePath(hqRoutes.ProjectsDuplicate, { id }));
  }, [id]);

  const handleArchive = useCallback(() => {
    openDialog(
      {
        title: 'Verwijdering van het project',
        content: 'Weet u zeker dat u dit project wilt verwijderen?',
      },
      () => {
        disableDialog();
        archiveProject.mutate(id, {
          onSettled: enableDialog,
          onSuccess: () => {
            closeDialog();
            navigate(generatePath(hqRoutes.PlannerProject));
          },
          onError: (e) => alertError(e),
        });
      }
    );
  }, [id]);

  return (
    <LWModal isOpen={!!id} onClose={onClose} testId="project-detail">
      {id ? (
        <ProjectDetail
          id={id}
          close={onClose}
          handleEdit={handleEdit}
          handleDuplicate={handleDuplicate}
          handleArchive={handleArchive}
        />
      ) : null}
    </LWModal>
  );
};
