import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';

export const useRejectFlexWorkerIdentityCheck = (
  identityVerificationId: string = '',
  options?: UseMutationOptions<unknown, unknown>
) => {
  const mutationFn = async () =>
    await flexWorkerService.rejectFlexWorkerIdCheck(identityVerificationId);

  return useMutation(mutationFn, options);
};
