import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useGetCompaniesAsSelect } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { useForm } from 'react-hook-form';
import { useAlert } from 'shared/components/alerts';
import { LwButton, LwFormInput, LwFormNumberInput, LwFormSelect, Toolbox } from 'redesign';
import { useGetBillingEntitiesAsSelect } from 'hq/hooks/queryHooks/billing-entities/use-get-billing-entities';
import { useGenerateCollectiveInvoice } from 'hq/hooks/queryHooks/billing-entities/use-generate-collective-invoice';
import { RenderUnsettledUI } from 'shared/components';

type CollectiveInvoicesFormData = {
  companyId: string;
  billingEntityId: string;
  email?: string;
  paymentTerm?: string;
};

export type CollectiveInvoicesFormPayload = {
  billingEntityId: string;
  email?: string;
  paymentTerm?: number;
};

const CollectiveInvoices = ({
  areCheckoutsVisible,
  setAreCheckoutsVisible,
}: {
  areCheckoutsVisible: boolean;
  setAreCheckoutsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    reset: clearForm,
  } = useForm<CollectiveInvoicesFormData>();
  const { mutate } = useGenerateCollectiveInvoice();

  const companyIdWatch = watch('companyId');
  const companiesQuery = useGetCompaniesAsSelect();
  const billingEntitiesQuery = useGetBillingEntitiesAsSelect(companyIdWatch);

  const styles = getStyles();

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  if (billingEntitiesQuery.status !== 'success' && billingEntitiesQuery.status !== 'idle') {
    return <RenderUnsettledUI data={billingEntitiesQuery} />;
  }

  const onSubmit = (f: CollectiveInvoicesFormData) => {
    const paymentTerm = f.paymentTerm;
    const payload: CollectiveInvoicesFormPayload = {
      billingEntityId: f.billingEntityId,
      email: f.email ? f.email : undefined,
      paymentTerm: paymentTerm ? parseInt(paymentTerm, 10) : undefined,
    };
    mutate(payload, {
      onSuccess: () => {
        alertSuccess('Verzamelfactuur wordt aangemaakt');
      },
      onError: (e) => alertError(e),
    });
  };

  const companyOptions = companiesQuery.data ?? [];
  const billingEntitiesOptions = billingEntitiesQuery.data ?? [];

  return (
    <>
      <Toolbox>
        <LwButton color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
          Download
        </LwButton>
        <LwButton color="secondary" onClick={() => clearForm()}>
          Wissen
        </LwButton>
        <Toolbox.PushRight>
          <LwButton onClick={() => setAreCheckoutsVisible((prev) => !prev)}>
            Show {areCheckoutsVisible ? 'Verzamelfactuur' : 'Checkouts'}
          </LwButton>
        </Toolbox.PushRight>
      </Toolbox>
      <Box>
        <form className={styles.form}>
          <Box className={styles.formFields}>
            <LwFormSelect
              name="companyId"
              label="Bedrijf"
              defaultLabel="Alle bedrijven"
              options={companyOptions || []}
              control={control}
              onChange={(e) => {
                setValue('companyId', e.target.value as string);
                setValue('billingEntityId', '');
              }}
              rules={{ required: 'Selecteer een bedrijf' }}
            />
            <LwFormSelect
              name="billingEntityId"
              options={billingEntitiesOptions}
              label="Facturatiegegevens"
              control={control}
              disabled={!watch('companyId')}
              onChange={(e) => {
                setValue('billingEntityId', e.target.value as string);
              }}
              rules={{ required: 'Selecteer een facturatiegegevens' }}
            />
            <LwFormNumberInput
              name="paymentTerm"
              control={control}
              label="Betaaltermijn in dagen"
              min={1}
              max={60}
              step={1}
            />
            <LwFormInput name="email" control={control} label="Email" inputType="email" />
          </Box>
        </form>
      </Box>
    </>
  );
};

const getStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: 600,
  },
  formFields: {
    padding: theme.spacing(2, 0),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  horRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  drawerPaper: {
    zIndex: 1,
    paddingTop: theme.spacing(12),
  },
  actions: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
  },
}));

export { CollectiveInvoices };
