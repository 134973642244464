import { useMemo, useState } from 'react';
import { PaginationResultSizes } from '@types';

export const useLevelPagination = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(PaginationResultSizes[0]);
  const pagination = useMemo(
    () => ({
      page,
      setPage,
      size,
      setSize,
    }),
    [page, size]
  );

  return pagination;
};
