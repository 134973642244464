import { RenderUnsettledUI } from 'shared/components';
import { FlexWorkerTableOptions } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work-table.types';
import { FlexWorkersForProjectFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { FlexWorkersTable } from '../../../../flex-workers-for-work-table/flex-workers-table';
import { useGetFlexWorkersForProject } from '../applications/hooks/get-flex-workers-for-project';

type ProviderProps = {
  projectId: string;
  filters?: FlexWorkersForProjectFilters;
  getRowActions: FlexWorkerTableOptions['getRowActions'];
};

export const CreatePlacementTableProvider = ({
  filters,
  projectId,
  getRowActions,
}: ProviderProps) => {
  const flexWorkerQuery = useGetFlexWorkersForProject(projectId, DEFAULT_PAGE_SIZE, filters);
  const { status } = flexWorkerQuery;

  if (status !== 'success') {
    return <RenderUnsettledUI data={flexWorkerQuery} />;
  }

  return (
    <FlexWorkersTable
      queryResult={flexWorkerQuery}
      entityId={projectId}
      getRowActions={getRowActions}
      showAvailabilityColumn={true}
    />
  );
};
