import { Amplify } from 'aws-amplify';
import { Pool } from './config';

const configureAmplify = (pool: Pool) => {
  Amplify.configure({
    Auth: {
      ...pool,
      region: 'eu-west-1',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  });
};

export { configureAmplify };
