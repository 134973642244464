import { Box, Grow, Modal, styled } from '@mui/material';
import { useState } from 'react';
import { LWModalProps } from './modal.types';

export const LWModal = ({ isOpen, onClose: close, children, testId }: LWModalProps) => {
  const [isFullyOpen, setIsFullyOpen] = useState(false);

  return (
    <StyledModal open={isOpen} onClose={close} closeAfterTransition data-testid={testId}>
      <Grow
        in={isOpen}
        onExited={() => setIsFullyOpen(false)}
        onEntered={() => setIsFullyOpen(true)}
      >
        <ModalContentWrapper>
          <Box display="flex" flexDirection="column" height="100%">
            {isFullyOpen ? children : null}
          </Box>
        </ModalContentWrapper>
      </Grow>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  padding: theme.spacing(8),
}));

const ModalContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  maxWidth: `calc(1280px - ${theme.spacing(24)})`,
  position: 'relative',
  backgroundColor: 'white',
  borderRadius: theme.spacing(3),
  boxShadow: theme.shadows[4],
  ':focus-visible': {
    outline: 'none',
  },
}));
