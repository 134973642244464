import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { userService } from '../../../services';

export const useRolesMatrix = () => {
  return useQuery(QUERY_KEYS_OGP.rolesMatrix(), async () => await userService.getRolesMatrix(), {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
    select: (data) => data.departmentRolesMatrix,
  });
};
