import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { BranchOfficeFormData } from '@types';

type BranchOfficeMutationData = { branchOfficeId: string; data: BranchOfficeFormData };
type BranchOfficeMutationOptions = UseMutationOptions<unknown, unknown, BranchOfficeMutationData>;

export const useUpdateBranchOffice = (options?: BranchOfficeMutationOptions) => {
  const mutationFn = async (branchOffice: BranchOfficeMutationData) =>
    await branchOfficeService.updateBranchOffice(branchOffice.branchOfficeId, branchOffice.data);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: BranchOfficeMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.branchOfficesList());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.branchOffice(variables.branchOfficeId));
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
