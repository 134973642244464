import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContextMenuItem, Table } from 'redesign';
import { PaginatedCollection } from '@types';
import {
  IconApproved,
  IconCounterOffer,
  IconFlexPools,
  IconNoShow,
} from '../../../../../../../../../assets/img';
import { useFeatureFlagContext } from '../../../../../../../../../shared/contexts/feature-flag-provider';
import { formatFullName } from '../../../../../../../../../shared/utils/formatting';
import { useRateShiftClaim } from '../../../../../../../../hooks/queryHooks/shiftclaims/useRateShiftClaim';
import { Sorting } from '../../../../../shared';
import { ClaimPendingExtended } from '../../../../shared/types/checkouts.types';
import { getInboxColumnDefinition } from '../helpers/get-inbox-column-definition';
import { getInboxTableData } from '../helpers/get-inbox-table-data';
import { InboxTableColumnDefinition, InboxTableOptions } from '../helpers/inbox-table.types';

type Props = {
  openNoShowDialog: (shiftClaimId: string, shiftId?: string) => void;
  openCounterOfferDialog: (claim: ClaimPendingExtended) => void;
  openChooseFlexWorkerFlexPoolsDialog: (
    shiftId: string,
    flexWorkerId: string,
    flexWorkerFullName: string
  ) => void;
  data: PaginatedCollection<ClaimPendingExtended>;
  handleSortClick: (id: string) => void;
  onSelectionChange: (rows: InboxTableColumnDefinition[]) => void;
  handleSettleClaims: (ids: string[]) => void;
  sortState: Sorting;
  noShowSetIsFlexWorker: (isFlexWorker: boolean) => void;
};

export const InboxTable = ({
  openNoShowDialog,
  openCounterOfferDialog,
  openChooseFlexWorkerFlexPoolsDialog,
  data,
  handleSortClick,
  handleSettleClaims,
  sortState,
  onSelectionChange,
  noShowSetIsFlexWorker,
}: Props) => {
  const ratingQuery = useRateShiftClaim();
  const { allowExpenses } = useFeatureFlagContext();
  const columns = useMemo(() => getInboxColumnDefinition({ allowExpenses }), [allowExpenses]);
  const [tableData, setTableData] = useState(getInboxTableData(data));

  const getMenuActionsFromClaim = useCallback((claim: ClaimPendingExtended) => {
    noShowSetIsFlexWorker(claim.worker.isFlexWorker);
    const flexPoolManagementItem =
      claim.worker.isFlexWorker === true ? (
        <ContextMenuItem
          key="flex-pool-assignment"
          onClick={() => {
            openChooseFlexWorkerFlexPoolsDialog(
              claim.shift.id,
              claim.worker.workerId,
              formatFullName(claim.worker)
            );
          }}
        >
          <IconFlexPools />
          Flexpool beheer
        </ContextMenuItem>
      ) : null;

    return [
      <ContextMenuItem key="counter-offer" onClick={() => openCounterOfferDialog(claim)}>
        <IconCounterOffer />
        Tegenvoorstel
      </ContextMenuItem>,
      <ContextMenuItem key="approve" onClick={() => handleSettleClaims([claim.id])}>
        <IconApproved /> Akkoord
      </ContextMenuItem>,
      <ContextMenuItem key="no-show" onClick={() => openNoShowDialog(claim.id)}>
        <IconNoShow />
        No show
      </ContextMenuItem>,
      flexPoolManagementItem,
    ];
  }, []);

  useEffect(() => {
    setTableData(getInboxTableData(data));
  }, [data]);

  const handleRatingChange = useCallback(
    (ratingId: string) => {
      if (!ratingId) {
        return;
      }
      const rowToChange = tableData.find((i) => i.rating.id === ratingId);
      if (!rowToChange) {
        return;
      }
      const { id, value } = rowToChange.rating;
      ratingQuery.mutate({ ratingId: id, value: !value });
      setTableData((old) => {
        return old.map((row) => {
          if (row.rating.id === ratingId) {
            return {
              ...row,
              rating: {
                ...row.rating,
                value: !value,
              },
            };
          }
          return row;
        });
      });
    },
    [tableData, ratingQuery]
  );

  return (
    <Table<InboxTableColumnDefinition, InboxTableOptions>
      columns={columns}
      data={tableData}
      getRowActions={getMenuActionsFromClaim}
      sortState={sortState}
      onSortClick={handleSortClick}
      onChangeRating={handleRatingChange}
      selectAllMode="VISIBLE"
      selectable={true}
      onSelectionChange={onSelectionChange}
    />
  );
};
