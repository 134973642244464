import { AccreditationFormRepresentation } from './file-handling-utils.types';

export const addAccreditationFiles = (
  formData: FormData,
  accreditations?: AccreditationFormRepresentation[]
): void => {
  if (!accreditations) {
    return;
  }
  accreditations.forEach((accreditation) => {
    if (!accreditation.files) {
      return;
    }
    accreditation.files.forEach((file) => {
      formData.append(`${accreditation.id}-files[]`, file);
    });
  });
};

export const addFieldsToFormData = (
  formData: FormData,
  body: { [key: string]: string | Blob | undefined }
): void => {
  for (const key in body) {
    if (body[key] === undefined) {
      continue;
    }
    formData.append(key, body[key] as string | Blob);
  }
};
