import { Dialog, DialogContent, DialogTitle, Table, TableCell, TableRow } from '@mui/material';
import { HQShiftClaimForFlexWorkerListItem } from 'hq/services/shift-claim-service.types';
import { useState } from 'react';
import { LwButton } from 'redesign';

type Props = {
  shiftClaim: HQShiftClaimForFlexWorkerListItem;
};

export const ShiftClaimDetailsModal = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <LwButton onClick={() => setOpen(true)} color="secondary">
        Meer info
      </LwButton>

      <Dialog maxWidth="sm" onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Shift claim details</DialogTitle>
        <DialogContent>
          <Table>
            <TableRow>
              <TableCell>Job name</TableCell>
              <TableCell>{props.shiftClaim.jobName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bedrijf</TableCell>
              <TableCell>{props.shiftClaim.companyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vestiging</TableCell>
              <TableCell>{props.shiftClaim.branchOfficeName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shiftclaim-ID</TableCell>
              <TableCell>{props.shiftClaim.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shift-ID</TableCell>
              <TableCell>{props.shiftClaim.shiftId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bedrijfs-ID</TableCell>
              <TableCell>{props.shiftClaim.companyId}</TableCell>
            </TableRow>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};
