import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LwFormInput, LwFormNumberInput } from 'redesign';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { CompanyRole } from '@types';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { BillingEntityFormFields } from './billing-entity-form.types';
import { DynamicFees } from './dynamic-fees';
import { useFeeTypeProvider } from '../../providers/fee-type-provider';

const BillingEntityForm = () => {
  const { control } = useFormContext<BillingEntityFormFields>();
  const feeTypes = useFeeTypeProvider();

  return (
    <form name="billing-entity-form">
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een name in' }}
            label="Naam"
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormNumberInput
            name="cocNumber"
            control={control}
            rules={{
              required: 'Voer een KVK-nummer in',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Een KVK-nummer bestaat uit acht cijfers',
              },
            }}
            label="KVK-nummer"
            placeholder="12345678"
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput name="vatNumber" control={control} label="BTW-nummer" />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="contactPerson"
            control={control}
            rules={{
              required: 'Voer een contactpersoon in',
            }}
            label="Contactpersoon"
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="contactEmail"
            inputType="email"
            control={control}
            rules={{
              required: 'Voer een email contactpersoon in',
            }}
            label="E-mail contactpersoon"
          />
        </Grid>
      </Grid>
      <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
        <Grid container item xs={12}>
          {feeTypes.map((feeType) => (
            <Grid my={4} item key={feeType} xs={12}>
              <DynamicFees feeType={feeType} />
            </Grid>
          ))}
        </Grid>
      </AuthenticatedElement>
      <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
        <Grid container item xs={12}>
          <LwFormNumberInput
            name="paymentTerm"
            control={control}
            rules={{ required: 'Voer een betalingstermijn in' }}
            label="Betalingstermijn in dagen"
            placeholder="30"
          />
        </Grid>
      </AuthenticatedElement>
    </form>
  );
};

export { BillingEntityForm };
