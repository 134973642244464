import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { CancelledColumnDefinitionProject } from '../types/cancelled-table-project.types';
import { getProjectCancelationReason } from '../helper';
import { ProjectCancellation } from '../../../../../services/project-service.types';

export const getCancelledTableDataProject = (
  cancellations: ProjectCancellation[],
  hourlyRateCents: number
): CancelledColumnDefinitionProject[] => {
  const cancelledPlacements = cancellations.filter((cancellation) =>
    ['canceled', 'terminated', 'completed', 'denied'].includes(cancellation.state)
  );
  const result = cancelledPlacements.map((placement) => {
    const { flexWorker } = placement;
    return {
      flexWorkerImage: {
        id: flexWorker.id,
        firstName: flexWorker.firstName,
        lastName: flexWorker.lastName,
        imageUrl: undefined,
        relation: {
          id: placement.relation.id,
          relation:
            placement.relation.relation === 'placement'
              ? RelationType.PLACEMENT
              : RelationType.SHIFTAPPLICATION,
        },
        hasCandidateLevel: flexWorker.hasCandidateLevel ?? false,
        employmentType: flexWorker.employmentType,
      },
      cancellationReason: getProjectCancelationReason(placement),
      flexPoolIndicator: false,
      flexWorkerFullName: flexWorker.fullName,
      hourlyRate: hourlyRateCents / 100,
    };
  });

  return result;
};
