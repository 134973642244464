import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { ProjectFormType } from 'shared/components/project-forms/types/project-form.types';
import { Header, Page, useFormButtons } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { InputSelectOption, JobListItem } from '@types';
import { useCompanyRelativePath } from '../../../../../../../hooks';
import { paths } from '../../../../../../../paths';
import { routes } from '../../../../../../../Routes';
import { useCreateProject } from '../../../hooks/use-create-project';
import { OgpProjectsCreatePayload } from '../../../../../../../services/project-service.types';

type ProjectCreateProps = {
  jobsData: JobListItem[];
  defaultValues?: Partial<ProjectFormType>;
  successMessage: string;
  pageTitle: string;
  departments: InputSelectOption[];
};

export const ProjectCreateFormPage = ({
  jobsData,
  defaultValues = {},
  successMessage,
  pageTitle,
  departments,
}: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<ProjectFormType>({
    defaultValues,
  });
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useCreateProject();
  const { returnTo } = useFormButtons(mutation.status);

  const handleSubmit = () => {
    formMethods.handleSubmit((data: ProjectFormType) => {
      const payload: OgpProjectsCreatePayload = {
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        hourlyRateCents: Math.round(+data.hourlyRate * 100),
      };
      mutation.mutate(payload, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.Projects, {}, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={returnTo(paths.Projects)}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <ProjectForm
              mode="create"
              jobsData={jobsData}
              isProjectWithActivePlacement={false}
              departments={departments}
            />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
