import { GetPartnerWorkerResponse } from 'partner/shared/services/partner-worker-service.types';
import { Box, Chip, styled, Typography } from '@mui/material';

export const PartnerWorkerDetailAccreditations = ({
  accreditations,
}: {
  accreditations: GetPartnerWorkerResponse['accreditations'];
}) => {
  return (
    <>
      <Typography variant="subtitle2" mb={6}>
        Certificaten
      </Typography>
      <BoxStyled>
        {accreditations.map((acc) => (
          <AccreditationChip
            key={acc.id}
            label={acc.name}
            onClick={() => {
              if (acc.fileUrl) {
                window.open(acc.fileUrl, '_blank');
              }
            }}
          />
        ))}
      </BoxStyled>
    </>
  );
};

const AccreditationChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.lwPrimary[5],
}));
const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));
