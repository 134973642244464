import { RenderUnsettledUI } from 'shared/components';
import { ShiftPlanningShiftsPartner } from 'hq/services/shift-planning-service.types';
import { useAlert } from 'shared/components/alerts';
import { useGetShiftsPartners } from './hooks/use-get-shifts-partners';
import { BulkForwardToPartnerDialogForm } from './dialog-components/dialog-form';
import { useBulkOpenShiftsToPartners } from './hooks/use-bulk-open-shifts-to-partners';

export type BulkForwardToShiftsFormInput = {
  partners: ShiftPlanningShiftsPartner[];
};

type Props = {
  shiftIds: string[];
  closeDialog: () => void;
};

export const BulkForwardToPartnerDialog = ({ shiftIds, closeDialog }: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const getShiftEligiblePartnersQuery = useGetShiftsPartners(shiftIds);
  const mutation = useBulkOpenShiftsToPartners();

  const onSubmit = (data: BulkForwardToShiftsFormInput) => {
    mutation.mutate(
      {
        shiftIds,
        partnerIds: data.partners.map((partner) => partner.id),
      },
      {
        onSuccess: () => {
          closeDialog();
          alertSuccess('Shift(s) succesvol doorgezet naar partner(s)');
        },
        onError: (e) => alertError(e),
      }
    );
  };

  return getShiftEligiblePartnersQuery.status === 'success' ? (
    <BulkForwardToPartnerDialogForm
      availablePartners={getShiftEligiblePartnersQuery.data}
      numberOfShifts={shiftIds.length}
      closeDialog={closeDialog}
      onSubmit={onSubmit}
    />
  ) : (
    <RenderUnsettledUI data={getShiftEligiblePartnersQuery} />
  );
};
