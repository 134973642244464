import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';

type Result = Awaited<ReturnType<typeof flexPoolService.getJobs>>;

const useGetFlexPoolJobs = (flexPoolId: string) => {
  return useQuery<Result, Error>(
    QUERY_KEYS_OGP.flexPoolJobs(flexPoolId),
    async () => await flexPoolService.getJobs(flexPoolId)
  );
};

export { useGetFlexPoolJobs };
