import { IconApproved, IconCancel } from 'assets/img';
import { LwButton } from 'redesign';
import { useNavigate } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { Box } from '@mui/material';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { accreditationsAlerts, useAlert } from 'shared/components/alerts';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { AddAccreditationFormTypes } from './add-accreditation-hq-form-data-provider';
import { useAddAndAcceptAccreditation } from '../hooks/use-add-and-approve-accreditation';

export const AddAccreditationsToolbox = () => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useAddAndAcceptAccreditation();

  const { flexWorkerOption, expirationDate, accreditations } =
    useFormContext<AddAccreditationFormTypes>().watch();

  const flexWorkerId = flexWorkerOption?.value;

  const handleAddAndAcceptAccreditation = useCallback(() => {
    if (!(flexWorkerId && accreditations)) {
      return;
    }

    openDialog(
      {
        title: expirationDate
          ? 'Are you sure you want to add this accreditaiton?'
          : 'Are you sure you want to update the certificate without an expiration date?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </SecondaryButton>
        ),
      },
      () => {
        disableDialog();

        const mutationData = {
          flexWorkerId,
          accreditations,
          expirationDate,
        };
        mutation.mutate(mutationData, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(accreditationsAlerts.success.addedAndAccepted);
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  }, [flexWorkerId, accreditations, expirationDate, mutation]);

  const submitDisabled = !flexWorkerOption || !accreditations?.length;
  return (
    <>
      <Box>
        <LwButton
          type="submit"
          iconColorMode="stroke"
          color="primary"
          startIcon={<IconApproved />}
          onClick={handleAddAndAcceptAccreditation}
          disabled={submitDisabled}
        >
          Indienen
        </LwButton>
      </Box>
      <Box>
        <LwButton
          onClick={() => navigate(generatePath(hqRoutes.Accreditations))}
          iconColorMode="fill"
          color="secondary"
          startIcon={<IconCancel />}
        >
          Annuleer
        </LwButton>
      </Box>
    </>
  );
};
