import { Sidebar, SidebarProvider } from 'ogp/components/views/sidebar';
import { Outlet } from 'react-router-dom';
import { Wrapper } from './helpers';

const BaseLayout = () => {
  return (
    <>
      <SidebarProvider>
        <Sidebar />
      </SidebarProvider>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
};

export { BaseLayout };
