import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useState } from 'react';
import { RenderUnsettledUI, SmallLoader } from 'shared/components';
import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { invariant } from 'shared/utils/utils';
import { useDeleteFlexWorkerFromFlexPool } from 'ogp/hooks/queryHooks/flexPools/useDeleteFlexWorkerFromFlexPool';
import { flexPoolAlerts, useAlert } from 'shared/components/alerts';
import { DeleteButton, PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { IconCancel } from 'assets/img';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { FlexWorkersTable } from './flex-workers-table';
import { useGetFlexPoolFlexWorkers } from './hooks/use-get-flex-pool-flex-workers';
import type { FlexWorkersProps } from './flex-workers.types';
import { FlexWorkersSearchBar } from './components/flex-workers-search-bar';

const FlexWorkers = ({ flexPoolId }: FlexWorkersProps) => {
  const { alertSuccess, alertError } = useAlert();
  const [nameInputValue, setNameInputValue] = useState('');
  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();
  const debouncedNameInput = useDebounce(nameInputValue, 300);
  const { data, error, refetch, status } = useGetFlexPoolFlexWorkers(
    flexPoolId,
    debouncedNameInput.length ? debouncedNameInput : undefined
  );
  const { mutate } = useDeleteFlexWorkerFromFlexPool();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const invalidateQuery = useInvalidateQuery();

  const handleShowFlexWorker = (id: string) => {
    openPopupProfile(id, {
      relation: RelationType.FLEXPOOL,
      id: flexPoolId,
    });
  };

  const removeFlexWorker = ({
    flexPoolId,
    flexWorkerId,
  }: Record<'flexPoolId' | 'flexWorkerId', string>) => {
    mutate(
      {
        flexPoolId,
        flexWorkerId,
      },
      {
        onSuccess: () => {
          invalidateQuery(QUERY_KEYS_OGP.flexPoolList());
          alertSuccess(flexPoolAlerts.success.removedFlexWorkerFromFlexPool);
          closeDialog();
        },
        onError: (e) => alertError(e),
        onSettled: () => {
          enableDialog();
        },
      }
    );
  };

  const handleRemoveFlexWorker = (id: string) => {
    invariant(id, 'Flexworker ID should be defined by now');
    invariant(data, 'Flexworkers should be defined by now');

    const flexWorker = data.find((item) => item.id === id);

    invariant(flexWorker, `Flexworker ${id} was not found in the list of flexworkers`);

    openDialog(
      {
        title: 'Flexwerker verwijderen',
        content: `Weet je zeker dat je ${flexWorker.name} wilt verwijderen uit jouw flexpool?`,
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode={'fill'} startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => <DeleteButton {...props} />,
      },
      () => {
        disableDialog();
        removeFlexWorker({ flexPoolId, flexWorkerId: id });
      }
    );
  };

  if (status !== 'success') {
    return <RenderUnsettledUI renderLoading={<SmallLoader />} data={{ error, refetch, status }} />;
  }

  return (
    <>
      {PopupProfileElement}
      <FlexWorkersSearchBar
        value={nameInputValue}
        setValue={setNameInputValue}
        id="search_flexworkers"
      />
      {data.length === 0 ? (
        <p>Er zitten geen flexwerkers in deze flexpool</p>
      ) : (
        <FlexWorkersTable
          data={data}
          onRemoveFlexWorker={handleRemoveFlexWorker}
          onShowFlexWorker={handleShowFlexWorker}
        />
      )}
    </>
  );
};

export { FlexWorkers };
