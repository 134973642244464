import { ProjectPlanningProjectResponse } from 'hq/services/project-planning-service.types';
import { DateTime } from 'luxon';
import { HqProjectPlanningColumnDefinition } from '../project-planning-table.types';

export const getProjectPlanningTableData = (
  data: ProjectPlanningProjectResponse[]
): HqProjectPlanningColumnDefinition[] => {
  const result: HqProjectPlanningColumnDefinition[] = data.map((item) => {
    return {
      id: item.id,
      jobName: item.jobName,
      companyName: item.companyName,
      departmentName: item.departmentName,
      candidateState: item.candidateState,
      action: item.id,
      fromDate: DateTime.fromISO(item.fromDateTime),
      toDate: DateTime.fromISO(item.toDateTime),
    };
  });
  return result;
};
