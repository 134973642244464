import { Box, Grid, styled } from '@mui/material';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { RenderUnsettledUI } from 'shared/components';
import { BlockLink, BlockProps, LwBlock } from 'redesign';
import { CompaniesFilterParams } from './companies.types';
import { IconBranchOffices } from '../../../../assets/img';
import { routePrefix } from '../../../../ogp/settings';
import { GetCompaniesResponse } from '../../../services/company-service.types';

export const CompaniesList = ({ params }: { params: CompaniesFilterParams }) => {
  const companiesQuery = useGetCompanies(params.filter);

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return <CompaniesImpl data={companiesQuery.data} />;
};

const CompaniesImpl = ({ data }: { data: GetCompaniesResponse[] }) => {
  const generatePath = useHqRelativePath();

  const blocks: BlockProps[] = data.map((block) => {
    return {
      id: block.id,
      title: block.name,
      chipLabels: [
        ...(block.offersFreelanceWork ? ['Freelance'] : []),
        ...(block.offersTempWork ? ['Uitzendkracht'] : []),
      ],
    };
  });

  return (
    <Box width="100%">
      <StyledGrid container spacing={4}>
        {blocks.map((block) => (
          <Grid item xs={6} md={4} lg={3} key={block.id} aria-label="block-element">
            <BlockLink to={generatePath(routes.CompanyDetail, { id: block.id })}>
              <LwBlock
                icon={<IconBranchOffices width={24} height={24} />}
                id={block.id}
                title={block.title}
                chipLabels={block.chipLabels}
                dataBlocks={block.dataBlocks}
                navigateCallback={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  window.open(`${routePrefix}/${block.id}/planning`);
                }}
                navigateLabel="Naar portal"
              />
            </BlockLink>
          </Grid>
        ))}
      </StyledGrid>
    </Box>
  );
};

const StyledGrid = styled(Grid)({
  marginBottom: '32px',
  marginTop: 0,
  width: '100%',
  marginLeft: '-8px',
  paddingBottom: '8px',
});
