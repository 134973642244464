import { RenderUnsettledUI } from 'shared/components';
import { ShiftPlanningFiltersDataProviderProps } from './shift-planning-filters.types';
import { ShiftPlanningFilters } from './shift-planning-filters';
import {
  useGetShiftPlanningBusinessUnitsData,
  useGetShiftPlanningJobTypesData,
} from '../hooks/use-get-shift-planning-filters-data';

export const ShiftPlanningDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: ShiftPlanningFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetShiftPlanningBusinessUnitsData({
    jobType: appliedFilters.jobType,
  });

  const jobTypeQuery = useGetShiftPlanningJobTypesData({
    company: appliedFilters.company,
    branchOffice: appliedFilters.branchOffice,
    department: appliedFilters.department,
  });

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ShiftPlanningFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
      }}
    />
  );
};
