import { DateTime, Duration, DurationObjectUnits } from 'luxon';
import { Level, WorkerEmploymentType } from '@types';

/**
 * Bob de Bouwer
 */
export function formatFullName(user: { firstName: string; lastName: string; infix?: string }) {
  return `${user.firstName}${user.infix ? ` ${user.infix}` : ''} ${user.lastName}`;
}

/**
 * Bouwer, de, Bob
 */
export function formatFullNameReverse({
  firstName,
  infix,
  lastName,
}: {
  firstName: string;
  infix?: string;
  lastName: string;
}) {
  return infix ? `${lastName}, ${infix}, ${firstName}` : `${lastName}, ${firstName}`;
}

/** Format a JS date as 12:34 */
export function formatTime(d: Date, zone?: string) {
  return DateTime.fromJSDate(d, { zone }).setLocale('nl').toLocaleString(DateTime.TIME_24_SIMPLE);
}

/** Format a JS date as "Ma, 12 jan" */
export function formatDate(d: Date) {
  return DateTime.fromJSDate(d)
    .toFormat('EEE, dd MMM')
    .replace(/\./g, '')
    .replace(/^\w/, (w) => w.toUpperCase());
}

export function formatCsvExportDateTime(d: Date) {
  return DateTime.fromJSDate(d).toFormat('ddMMyyyy HHmm');
}
/** Format a JS date as "Ma, 12 jan 12:34" */
export function formatDateTime(d: Date) {
  return `${formatDate(d)} ${formatTime(d)}`;
}

export function formatDuration(duration: DurationObjectUnits) {
  const result = Duration.fromObject(duration).normalize().shiftTo('hours', 'minutes');

  if (result.hours > 0) {
    return `${result.toFormat('hh:mm')} u`;
  }

  if (result.minutes > 10) {
    return `${result.toFormat('mm')} min`;
  }

  return `${result.toFormat('m')} min`;
}

export function formatFlexworkerCount(flexWorkerCount: number) {
  return flexWorkerCount === 0
    ? 'geen flex werkers'
    : flexWorkerCount === 1
      ? '1 actieve flex werker'
      : `${flexWorkerCount} actieve flex werkers`;
}

export function formatEmploymentType(employmentType: WorkerEmploymentType | null) {
  if (employmentType === 'FREELANCER') {
    return 'Zelfstandige';
  }
  if (employmentType === 'TEMPWORKER') {
    return 'Uitzendkracht';
  }
  return '';
}

export function formatFlexWorkerLevel(level: Level) {
  if (level === null) {
    return 'Incomplete';
  }
  return titleCase(level);
}

export const formatFloatNumber = (value: number): string => {
  const formatter = new Intl.NumberFormat('nl');

  return formatter.format(value);
};

export const formatCurrencyCents = (amount: number): string => {
  const formatter = new Intl.NumberFormat('nl', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(amount / 100);
};

export const formatDateFromString = (date: string): string => {
  return DateTime.fromISO(date).setLocale('nl').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
};

export const formatDateTimeFromString = (dateTime: string): string => {
  return DateTime.fromISO(dateTime).setLocale('nl').toLocaleString(DateTime.DATETIME_SHORT);
};

function titleCase(word: string) {
  return word.charAt(0) + word.substring(1).toLowerCase();
}
