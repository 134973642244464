import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { RenderUnsettledUI } from 'shared/components';
import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { JobCreatePage } from './job-create-page';
import { jobAlerts } from '../../../../../../../shared/components/alerts';

export const JobCreateDataProvider = () => {
  const jobCreateDataQuery = useGetJobCreateData();

  const employmentType = useGetCompanyEmploymentTypes();

  if (employmentType.status !== 'success') {
    return <RenderUnsettledUI data={employmentType} />;
  }

  if (jobCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobCreateDataQuery} />;
  }

  return (
    <JobCreatePage
      jobCreateData={jobCreateDataQuery.data}
      pageTitle={'Nieuwe functie aanmaken'}
      successMessage={jobAlerts.success.created}
      employmentType={employmentType.data}
    />
  );
};
