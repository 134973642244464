import urbanistFont from 'assets/fonts/urbanist/urbanist-variable-font_wght.ttf';
import interFont from 'assets/fonts/inter/inter-variable-font_slnt_wght.ttf';

const inter = {
  fontFamily: 'Inter',
  src: `url(${interFont}) format(truetype) tech(variations)`,
  fontVariationSettings: '"wght" 400',
  fontWeight: '100 900',
  ascentOverride: '100%',
};

const urbanist = {
  fontFamily: 'Urbanist',
  src: `url(${urbanistFont}) format(truetype) tech(variations)`,
  fontVariationSettings: '"wght" 400',
  fontWeight: '100 900',
  ascentOverride: '100%',
};

export { inter, urbanist };
