import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useQuery } from 'react-query';
import { ShiftPlanningPartnerUnitsData } from 'hq/services/shift-planning-service.types';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';

const useGetShiftPlanningPartnersData = () => {
  const { allowPartners } = useFeatureFlagContext();
  return useQuery<ShiftPlanningPartnerUnitsData | null, Error>({
    queryKey: QUERY_KEYS_HQ.partnersFilter(),
    queryFn: async () => {
      if (allowPartners) {
        return await shiftPlanningService.getShiftPlanningPartnersData();
      }
      return null;
    },
  });
};

export { useGetShiftPlanningPartnersData };
