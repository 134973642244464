import { CollectiveInvoicesFormPayload } from 'hq/components/views/finance/collective-invoices';
import { collectiveInvoicesService } from 'hq/services';
import { useMutation } from 'react-query';

export const useGenerateCollectiveInvoice = () => {
  const mutationFn = async (data: CollectiveInvoicesFormPayload) =>
    await collectiveInvoicesService.generateCollectiveInvoice(data);

  return useMutation({ mutationFn });
};
