import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSidebarProvider } from './sidebar-provider';

const SiteInfoContainer = ({ children }: React.PropsWithChildren<unknown>) => {
  const { isOpen } = useSidebarProvider();

  return (
    <Typography
      component={'div'}
      style={{
        color: grey['400'],
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '.5rem',
        marginLeft: '2rem',
        marginRight: '2rem',
        marginTop: '1rem',
        textAlign: 'center',
      }}
      variant="body2"
    >
      {isOpen ? children : <span>&nbsp;</span>}
    </Typography>
  );
};

export { SiteInfoContainer };
