import { ShiftPlanningShiftResponse } from 'hq/services/shift-planning-service.types';
import { DateTime } from 'luxon';
import { HqShiftPlanningColumnDefinition } from '../shift-planning-table.types';

export const getShiftPlanningTableData = (
  data: ShiftPlanningShiftResponse[]
): HqShiftPlanningColumnDefinition[] => {
  const result: HqShiftPlanningColumnDefinition[] = data.map((item) => {
    const dateTime = {
      fromDateTime: DateTime.fromISO(item.dateTime.fromDateTime),
      toDateTime: DateTime.fromISO(item.dateTime.toDateTime),
    };

    return {
      id: item.id,
      jobName: item.jobName,
      companyName: item.companyName,
      departmentName: item.departmentName,
      location: item.location,
      partners: item.partners,
      dateTime,
      state: item.state,
      action: item.id,
    };
  });
  return result;
};
