import { CompanyRole, DepartmentRole } from 'ogp/services/user-service.types';
import {
  GetPartnerUserInfoResponse,
  PartnerRole,
} from 'partner/shared/services/partner-user-service.types';
import { HqRole, HqUserInfo, OgpUserInfo } from '@types';

export enum PolicyGroups {
  DEPARTMENT = 'DEPARTMENT',
  COMPANY = 'COMPANY',
  HQ = 'HQ',
  PARTNER = 'PARTNER',
}

type PolicyGroupToRole = {
  [PolicyGroups.DEPARTMENT]: DepartmentRole | ReadonlyArray<DepartmentRole>;
  [PolicyGroups.COMPANY]: CompanyRole | ReadonlyArray<CompanyRole>;
  [PolicyGroups.HQ]: HqRole;
  [PolicyGroups.PARTNER]: PartnerRole;
};

/**
 * The `policyGroup` and `roles` fields, linked together so that
 * the role(s) have to belong to the policy group
 */
export type PolicyGroupAndRoles =
  | {
      policyGroup: PolicyGroups.DEPARTMENT;
      roles: PolicyGroupToRole[PolicyGroups.DEPARTMENT];
    }
  | {
      policyGroup: PolicyGroups.COMPANY;
      roles: PolicyGroupToRole[PolicyGroups.COMPANY];
    }
  | {
      policyGroup: PolicyGroups.HQ;
      roles: PolicyGroupToRole[PolicyGroups.HQ];
    }
  | {
      policyGroup: PolicyGroups.PARTNER;
      roles: PolicyGroupToRole[PolicyGroups.PARTNER];
    };

/**
 * Optional `policyGroup` and `roles` fields, linked together so that  the role(s) have
 * to belong to the policy group or both have to be undefined.
 */
export type PolicyGroupAndRolesOptional =
  | PolicyGroupAndRoles
  | { policyGroup?: undefined; roles?: undefined };

/**
 * Check whether the given user satisfies the required policy group and role.
 * You can only pass role(s) belonging to the given policy group.
 */
export function hasAccessWithinPolicyGroup<P extends PolicyGroups>(
  policyGroup: P,
  userInfo: OgpUserInfo | HqUserInfo | GetPartnerUserInfoResponse,
  requiredRole: PolicyGroupToRole[P]
) {
  switch (policyGroup) {
    case PolicyGroups.DEPARTMENT:
      const {
        roles: { departmentRoles },
      } = userInfo as OgpUserInfo;

      return hasAccessToBranchOfficePolicy(
        departmentRoles && departmentRoles.flatMap((x) => x.roles),
        requiredRole as DepartmentRole | DepartmentRole[]
      );
    case PolicyGroups.COMPANY:
      const {
        roles: { companyRoles },
      } = userInfo as OgpUserInfo;
      return (
        (companyRoles &&
          hasAccessToCompanyPolicy(
            companyRoles.roles,
            requiredRole as CompanyRole | CompanyRole[]
          )) ??
        false
      );
    case PolicyGroups.HQ:
      const { roles } = userInfo as HqUserInfo;
      return roles && hasAccessToHqPolicy(roles.hqRoles ?? [], requiredRole as HqRole | HqRole[]);
    case PolicyGroups.PARTNER:
      return true;
    default:
      return false;
  }
}

function hasAccessToBranchOfficePolicy(
  roles: DepartmentRole[],
  policies: DepartmentRole | DepartmentRole[]
): boolean {
  if (Array.isArray(policies)) {
    return policies.some((x) => roles.includes(x));
  }

  return roles.includes(policies);
}

function hasAccessToCompanyPolicy(
  roles: CompanyRole[],
  policies: CompanyRole | CompanyRole[]
): boolean {
  if (Array.isArray(policies)) {
    return policies.some((x) => roles.includes(x));
  }

  return roles.includes(policies);
}

function hasAccessToHqPolicy(roles: HqRole[], policies: HqRole | HqRole[]): boolean {
  if (Array.isArray(policies)) {
    return policies.some((x) => roles.includes(x));
  }

  return roles.includes(policies);
}
