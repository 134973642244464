import { Box, Divider, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { getFilterClasses, LwButton } from 'redesign';
import { useCallback, useMemo } from 'react';
import { useCalendarDefaults } from 'shared/hooks/useCalendarDefaults';
import clsx from 'clsx';
import { Calendar } from 'react-multi-date-picker';
import { useCalendar } from 'shared/hooks/use-calendar';
import { FilterProps, InboxFormValues } from './inbox-filter-form.types';
import { BusinessUnitFiltering } from './business-unit-filtering';
import { getCheckoutDefaultFilterValues } from './use-filter-default-values';
import { InboxFiltersParams } from '../../helpers/checkouts.types';

export const InboxFilterForm = ({
  activeFilters,
  applyFilters,
  onCancel,
  clear,
  data,
}: FilterProps<InboxFiltersParams>) => {
  const filterClasses = getFilterClasses();
  const { monthLabels, dayLabels, calendarStyles } = useCalendarDefaults();
  const maxDate = useMemo(() => new Date(), []);

  const defaultFilterValues = useMemo(() => {
    return getCheckoutDefaultFilterValues({ branchOffices: data.branchOffices, activeFilters });
  }, [data.branchOffices, activeFilters]);

  const { dateRange, handleDateChange, getDateRangeFrom, handleResetDateRange } = useCalendar({
    fromDate: activeFilters.fromDate,
    toDate: activeFilters.toDate,
  });

  const form = useForm<InboxFormValues>({
    defaultValues: {
      ...activeFilters,
      flexWorkers: activeFilters.flexWorkerIds?.map((flexWorkerId) => {
        const flexWorker = data.flexWorkers.find((fw) => fw.id === flexWorkerId);
        return { name: flexWorker?.fullName ?? '', id: flexWorkerId };
      }),
      branchOffice: {
        label: defaultFilterValues.defaultBranchOffice?.label,
        value: defaultFilterValues.defaultBranchOffice?.value,
      },
      department: {
        label: defaultFilterValues.defaultDepartment?.label,
        value: defaultFilterValues.defaultDepartment?.value,
      },
    },
  });

  const onSubmit = useCallback(
    (formData: InboxFormValues) => {
      let departmentIds = [];

      if (formData.department === null || formData.department.value === undefined) {
        const foundBranchOffice = data.branchOffices.find(
          (bo: any) => bo.id === formData.branchOffice?.value
        );
        departmentIds = foundBranchOffice?.departments.map((dep) => dep.id) ?? [];
      } else {
        departmentIds = [formData.department.value];
      }

      const submitData: InboxFiltersParams = {
        branchOffice: formData.branchOffice,
        departmentIds,
        flexWorkerIds: formData.flexWorkers?.map((flexWorker) => flexWorker.id),
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        filterByPrio: formData.filterByPrio,
      };

      applyFilters(submitData);
      onCancel();
    },
    [applyFilters, data.branchOffices, dateRange.fromDate, dateRange.toDate, onCancel]
  );

  const clearForm = useCallback(() => {
    clear();
    form.reset({
      flexWorkers: null,
      branchOffice: null,
      department: null,
    });
    handleResetDateRange();
  }, [clear, form, handleResetDateRange]);

  return (
    <FormProvider {...form}>
      <Box className={filterClasses.content}>
        <Typography variant="h2" className={filterClasses.title}>
          Filter Checkouts
        </Typography>
        <Divider className={filterClasses.divider} />
        <form onSubmit={form.handleSubmit(onSubmit)} className={filterClasses.form}>
          <Box>
            <Calendar
              className={clsx(calendarStyles.calendar)}
              weekStartDayIndex={1}
              range
              months={monthLabels}
              weekDays={dayLabels}
              value={getDateRangeFrom(dateRange.fromDate, dateRange.toDate)}
              maxDate={maxDate}
              onChange={handleDateChange}
            />
            <Box flex="1" justifyContent="flex-end" marginBottom={6}>
              <LwButton color="secondary" onClick={handleResetDateRange}>
                Datumselectie wissen
              </LwButton>
            </Box>
            <BusinessUnitFiltering {...{ applyFilters, onCancel, clear }} data={data} />
          </Box>
          <div className={filterClasses.actions}>
            <Box flexBasis="100%">
              <LwButton color="primary" type="submit">
                Filter
              </LwButton>
            </Box>
            <LwButton color="secondary" onClick={onCancel}>
              Terug
            </LwButton>
            <LwButton color="secondary" onClick={clearForm}>
              Filters wissen
            </LwButton>
          </div>
        </form>
      </Box>
    </FormProvider>
  );
};
