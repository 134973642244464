import React from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createThemeForEnvironment } from 'theme';
import { theme } from 'redesign';
import { useAppSettingsProvider } from './AppSettingsContext';

const LegacyThemeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { env } = useAppSettingsProvider();
  const theme = createThemeForEnvironment(env.shortLabel);
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

const ThemeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export { ThemeProvider, LegacyThemeProvider };
