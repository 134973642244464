import { Interval } from 'luxon';

const hasOverlappingIntervals = (intervals: Interval[]) => {
  for (let i = 0; i < intervals.length; i += 1) {
    for (let j = i + 1; j < intervals.length; j += 1) {
      if (intervals[i].overlaps(intervals[j])) {
        return true;
      }
    }
  }
  return false;
};

export { hasOverlappingIntervals };
