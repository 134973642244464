import { FlexWorkerData } from '@types';

export function shouldShowBsnField(
  flexWorkerData: FlexWorkerData,
  isVatRequired: boolean
): boolean {
  return (
    !!flexWorkerData.bsn ||
    (flexWorkerData.employmentType === 'FREELANCER' &&
      isVatRequired === false &&
      !flexWorkerData.vatNumber)
  );
}
