import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeConfig } from 'redesign';

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: themeConfig.backgrounds.main,
  width: '100%',
  height: '100%',
  paddingRight: theme.spacing(8),
  paddingLeft: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  minWidth: '0',
}));

export { Wrapper };
