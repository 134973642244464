import { GetCompanyPartnerRelationsResponse } from 'hq/services/company-service.types';
import { PartnerRelationColumnDefinition } from '../company-partners-list.types';

export const getCompanyPartnersTableData = (
  data: GetCompanyPartnerRelationsResponse[],
  sorting: 'ASC' | 'DESC'
): PartnerRelationColumnDefinition[] => {
  const result: PartnerRelationColumnDefinition[] = data.map((partnerRelation) => {
    return {
      partner: partnerRelation.partnerName,
      branchOffice: partnerRelation.branchOfficeName,
      jobTypes: partnerRelation.jobTypes.map((i) => i.name),
    };
  });
  return result.sort((a, b) =>
    sorting === 'ASC'
      ? a.branchOffice.localeCompare(b.branchOffice)
      : b.branchOffice.localeCompare(a.branchOffice)
  );
};
