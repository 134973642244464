import { MutationFunction, useMutation, UseMutationOptions } from 'react-query';
import { saveAs } from 'file-saver';
import { placementService as ogpPlacementService } from 'ogp/services';
import { placementService as hqPlacementService } from 'hq/services';
import { SiteDetectionResult } from '../../../hooks';

type PlacementOvoMutationOptions = UseMutationOptions<Blob, unknown, WorkApplicationMutationParams>;

type WorkApplicationMutationParams = {
  placementId: string;
  siteDetectionResult: SiteDetectionResult;
};

export const useGetPlacementContract = (options: PlacementOvoMutationOptions) => {
  const mutationFn: MutationFunction<Blob, WorkApplicationMutationParams> = async (
    params: WorkApplicationMutationParams
  ) => getMutationFunction(params);

  return useMutation<Blob, unknown, WorkApplicationMutationParams>(mutationFn, {
    onSuccess: async (data, { placementId, siteDetectionResult }, context) => {
      saveAs(data, `contract_${placementId}.pdf`);
      if (options.onSuccess) {
        options.onSuccess(data, { placementId, siteDetectionResult }, context);
      }
    },
    onError: options.onError,
  });
};

const getMutationFunction = async (params: WorkApplicationMutationParams): Promise<Blob> => {
  const { siteDetectionResult, placementId } = params;
  if (siteDetectionResult.isHQ) {
    return hqPlacementService.downloadContract(placementId);
  }
  if (siteDetectionResult.isOGP) {
    return ogpPlacementService.downloadContract(placementId);
  }

  throw new Error(
    `useGetPlacementContract: site "${siteDetectionResult.site}" is neither HQ nor OGP.`
  );
};
