import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS_SHARED } from 'shared/react-query/query-keys';
import { QUERY_KEYS_HQ } from '../../../../query-keys';
import { adminSettingsService } from '../../../../services';
import { UpdatePlatformSettingsPayload } from '../../../../services/admin-settings-service.types';

export const useUpdatePlatformSettings = () => {
  const queryClient = useQueryClient();

  const mutationFn = async (updatePayload: UpdatePlatformSettingsPayload) =>
    await adminSettingsService.updatePlatformSettings(updatePayload);

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.platformSettings());
      queryClient.invalidateQueries(QUERY_KEYS_SHARED.environmentConfiguration());
    },
  });
};
