import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { jobService } from 'hq/services';
import { useQuery, UseQueryResult } from 'react-query';
import { FreelanceJobDetailsWithId, TempWorkJobDetailsWithId } from '@types';

export const useHqGetJob = <T = FreelanceJobDetailsWithId | TempWorkJobDetailsWithId>(
  jobId: string = ''
): UseQueryResult<T, Error> => {
  const queryFn = async () => await jobService.getJob<T>(jobId);

  const options = {
    enabled: jobId.length > 0,
  };

  return useQuery(QUERY_KEYS_HQ.job(jobId), queryFn, options);
};
