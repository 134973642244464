import { DateTime } from 'luxon';

export function validateDateString(dateString: string) {
  return DateTime.fromISO(dateString).isValid;
}

export function compareDateStrings(dateString_1: string, dateString_2: string) {
  const validStringDate1 = validateDateString(dateString_1);
  const validStringDate2 = validateDateString(dateString_2);
  if (validStringDate1 && validStringDate2) {
    return DateTime.fromISO(dateString_1) < DateTime.fromISO(dateString_2);
  }
  return false;
}

export function areDateStringsEqual(dateString_1: string, dateString_2: string) {
  const validStringDate1 = validateDateString(dateString_1);
  const validStringDate2 = validateDateString(dateString_2);
  if (validStringDate1 && validStringDate2) {
    return DateTime.fromISO(dateString_1).equals(DateTime.fromISO(dateString_2));
  }
  return false;
}

export function dateOfBirthValidator(dateString: string) {
  return DateTime.fromFormat(dateString, 'dd-MM-yyyy').isValid;
}
