import { useAlert } from 'shared/components/alerts';
import { branchOfficeAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { BranchOfficeFormData, VAT } from '@types';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { BranchOfficeForm } from './branch-office-form';
import { useCreateBranchOffice } from './hooks/use-create-branch-office';
import { IconApproved, IconCancel } from '../../../../../../../../../../../assets/img';

const BranchOfficeCreate = () => {
  const { companyId } = useParams<'companyId'>();
  invariant(companyId, 'Company id is not defined');
  const methods = useForm<BranchOfficeFormData>({
    defaultValues: {
      VAT: VAT.HIGH,
    },
  });
  const mutation = useCreateBranchOffice();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    methods.handleSubmit((formData) =>
      mutation.mutate(
        { ...formData, companyId },
        {
          onSuccess: () => {
            alertSuccess(alerts.success.created);
            navigate(generatePath(routes.CompanyDetail, { id: companyId }));
          },
          onError: (e) => alertError(e),
        }
      )
    )();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Page
          header={<Header titleText="Nieuwe vestiging aanmaken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Vestiging aanmaken
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(routes.CompanyDetail, { id: companyId }))}
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <BranchOfficeForm state="create" companyId={companyId} address="" />
        </Page>
      </form>
    </FormProvider>
  );
};
export { BranchOfficeCreate };
