import React from 'react';
import { invariant } from 'shared/utils/utils';

const ProviderFactory = <T>(
  providerNotFoundMessage = 'You are trying to use a provider, but it cannot be found.'
) => {
  // eslint-disable-next-line no-restricted-properties
  const context = React.createContext<T | undefined>(undefined);
  const useContext = () => {
    // eslint-disable-next-line no-restricted-properties
    const _context = React.useContext(context);

    invariant(_context, providerNotFoundMessage);

    return _context;
  };

  return [context.Provider, useContext] as const;
};

export { ProviderFactory };
