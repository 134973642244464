import { Box, Divider, Grid, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { isFreelanceJob } from '@types';
import { FormCheckbox } from 'redesign';
import { useParams } from 'react-router-dom';
import { ShiftJobDetails } from './shift-job-details';
import { ShiftFormProps } from '../shared/types';
import { JobInputs } from '../shared/inputs/job-inputs';
import { ShiftDateTimeInputs } from '../shared/inputs/date-time-inputs';
import { BreakMinutesInput } from '../shared/inputs/break-minutes-input';
import { MaxClaimantsElement } from '../shared/inputs/max-claimants-input';
import { HourlyRateInput } from '../shared/inputs/hourly-rate-input';
import { getHelperTexts } from '../shared/get-helper-texts/get-helper-texts';
import { GetFilteredJobsForCompanyResponse } from '../../../../../../ogp/services/job-service.types';
import { MutableUntilHoursInput } from '../shared/inputs/mutable-until-input';

export const ShiftForm = ({
  isDisabled,
  mode,
  shiftCreateData,
  jobsData,
  jobData,
  employmentTypeOfCompany,
  allowDateBeforeToday,
}: ShiftFormProps) => {
  const { setValue, watch, control } = useFormContext();
  const helperTexts = useMemo(() => getHelperTexts(jobData), [jobData]);
  const contractFieldsDisabled =
    isDisabled || (!allowDateBeforeToday && mode === 'disableContractFields');
  const isDepartmentDisabled = isDisabled || mode !== 'create';

  const selectedJobId = watch('jobId') as string;
  const jobEmploymentType = useMemo(() => {
    return getEmploymentTypeFromJob(selectedJobId, jobsData);
  }, [selectedJobId, jobsData]);

  const isFreelanceCheckboxChecked = watch('isFreelance');

  const { id: possibleShiftId } = useParams<'id'>();
  const shiftId = mode === 'update' || mode === 'duplicate' ? possibleShiftId : undefined;

  const areCheckboxesEnabled = useMemo(() => {
    const companyAllowsBoth =
      employmentTypeOfCompany && Object.values(employmentTypeOfCompany).every(Boolean);
    const jobAllowsBoth = jobEmploymentType && Object.values(jobEmploymentType).every(Boolean);
    const isEnabled = !isDisabled && selectedJobId && companyAllowsBoth && jobAllowsBoth;

    return !isEnabled;
  }, [employmentTypeOfCompany, jobEmploymentType, selectedJobId, isDisabled]);

  const isJobSelectorVisible = mode === 'create';
  const isJobAndDepartmentVisible = mode !== 'duplicate';

  //  setting the values from job only if company allows the employment type (edge case)
  useEffect(() => {
    if (jobEmploymentType && employmentTypeOfCompany) {
      const { isFreelance: isFreelanceJob, isTempwork: isTempworkJob } = jobEmploymentType;
      const { offersFreelanceWork, offersTempWork } = employmentTypeOfCompany;

      setValue('isFreelance', isFreelanceJob && offersFreelanceWork);
      setValue('isTempwork', isTempworkJob && offersTempWork);
    }
  }, [jobEmploymentType, employmentTypeOfCompany]);

  useEffect(() => {
    if (!jobData) {
      return;
    }
    if (mode !== 'create') {
      return;
    }

    setValue('breakMinutes', jobData.breakMinutes);
    if (isFreelanceJob(jobData) && employmentTypeOfCompany?.offersFreelanceWork) {
      setValue('hourlyRate', jobData.freelanceJob.hourlyRateCents / 100);
      setValue('mutableUntilHours', jobData.freelanceJob.mutableUntilMinutes / 60);
    } else {
      setValue('hourlyRate', 0);
      setValue('noShowFine', 0);
    }
  }, [jobData, mode, employmentTypeOfCompany]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" height="100%">
          <form name="shift-form">
            <Grid container item spacing={4} xs={12}>
              {isJobAndDepartmentVisible ? (
                <Grid item xs={12}>
                  <JobInputs
                    isJobDisabled={contractFieldsDisabled}
                    isDepartmentDisabled={isDepartmentDisabled}
                    jobsData={jobsData ?? []}
                    branchOfficesWithDepartments={
                      shiftCreateData?.branchOfficesWithDepartments ?? []
                    }
                    isJobSelectorVisible={isJobSelectorVisible}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <ShiftDateTimeInputs
                  disabled={contractFieldsDisabled}
                  allowDateBeforeToday={allowDateBeforeToday}
                />
              </Grid>
              <Grid item xs={6}>
                <MaxClaimantsElement disabled={isDisabled} />
              </Grid>
              <Grid item xs={6}>
                <BreakMinutesInput
                  options={shiftCreateData?.breakOptions ?? []}
                  disabled={contractFieldsDisabled}
                  helperText={helperTexts.breakMinutes}
                />
              </Grid>
              <Grid item xs={12}>
                <FormCheckbox
                  name="isTempwork"
                  label="Uitzendkracht"
                  control={control}
                  disabled={areCheckboxesEnabled}
                />
              </Grid>
              {/* FREELANCE FIELDS */}
              <Grid item xs={12}>
                <FormCheckbox
                  name="isFreelance"
                  label="Zelfstandige"
                  control={control}
                  disabled={areCheckboxesEnabled}
                />
              </Grid>
              <Grid item xs={6}>
                <HourlyRateInput
                  disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                  helperText={helperTexts.hourlyRate ?? ''}
                  placeholder={`${(jobData?.freelanceJob?.hourlyRateCents || 0) / 100}`}
                  required={isFreelanceCheckboxChecked}
                />
              </Grid>
              <Grid item xs={6}>
                <MutableUntilHoursInput
                  disabled={!isFreelanceCheckboxChecked || contractFieldsDisabled}
                  required={isFreelanceCheckboxChecked}
                ></MutableUntilHoursInput>
              </Grid>
            </Grid>
          </form>

          <Box>
            <StyledDivider orientation="vertical" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <ShiftJobDetails
          selectedJobId={selectedJobId}
          shiftId={shiftId}
          companyGrossHourlyRate={jobData?.tempWorkJob?.grossHourlyRateCents}
        />
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));

const getEmploymentTypeFromJob = (
  jobId: string,
  jobsData: GetFilteredJobsForCompanyResponse['jobs']
) => {
  if (!jobId || !jobsData) {
    return null;
  }

  const selectedJob = jobsData.find((job) => job.id === jobId);
  if (!selectedJob) {
    return {
      isFreelance: false,
      isTempwork: false,
    };
  }
  return {
    isFreelance: selectedJob.isFreelance,
    isTempwork: selectedJob.isTempwork,
  };
};
