import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const getFilterClasses = makeStyles((theme: Theme) => ({
  content: {
    width: theme.spacing(90),
    padding: theme.spacing(4),
    overflow: 'auto',
    flex: 1,
    height: 'calc(100% - 90px)',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2, 0, 2),
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxRow: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& span': {
      fontWeight: 'bold',
    },
  },
  actions: {
    padding: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 'auto',
  },
  spinner: {
    display: 'block',
    margin: theme.spacing(20, 'auto'),
  },
  errorMessage: {
    marginTop: theme.spacing(4),
  },
}));

export { getFilterClasses };
