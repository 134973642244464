import { useCallback } from 'react';
import { UseInfiniteQueryResult } from 'react-query';

type Props<T> = {
  infiniteQueryResult: UseInfiniteQueryResult<T>;
  flatData: any[];
};

const PIXELS_TO_BOTTOM = 300;

export const useOnScrollDown = <T extends { totalRows: number }>({
  infiniteQueryResult,
  flatData,
}: Props<T>) => {
  const { data, isFetching, fetchNextPage } = infiniteQueryResult;
  const totalDBRowCount = data?.pages?.[0]?.totalRows ?? 0;
  const totalFetched = flatData.length;

  const fetchIfBottomScrolled = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // Once the user has scrolled within PIXELS_TO_BOTTOM of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < PIXELS_TO_BOTTOM &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );
  return { fetchIfBottomScrolled };
};
