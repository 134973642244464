import type { RouteDefinition } from 'Routes';
import { PolicyGroups } from 'shared/utils/policy-controller';

type PartnerRouteKeys = keyof typeof PARTNER_PATHS;

const PARTNER_PATHS: Record<string, RouteDefinition> = {
  Unauthorized: {
    path: 'unauthorized',
  },
  ShiftsPlanning: {
    path: 'shift-planning',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  Placement: {
    path: 'placements',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  Inbox: {
    path: 'message-inbox',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  Checkout: {
    path: 'checkout-inbox',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
    queryParams: {
      checkoutState: 'counterOffer',
      fromDate: 'fromDate',
      toDate: 'toDate',
      companies: 'companies',
      jobs: 'jobs',
      workers: 'workers',
    },
  },
  CheckoutCreate: {
    path: 'checkout-inbox/create/:id',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  CounterOffer: {
    path: 'checkout-inbox/counteroffer/:id',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  Worker: {
    path: 'partner-workers',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  WorkerDetail: {
    path: 'partner-workers/:id',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  WorkerCreate: {
    path: 'partner-workers/create',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  WorkerUpdate: {
    path: 'partner-workers/edit/:id',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
  CheckedOut: {
    path: 'checked-outs',
    policyGroup: PolicyGroups.PARTNER,
    roles: 'PARTNER',
  },
} as const;

export { PARTNER_PATHS };
export type { PartnerRouteKeys };
