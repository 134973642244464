import { Tooltip, Typography } from '@mui/material';
import { IconTrash } from 'assets/img';
import type { Column } from 'react-table';
import { LwIconButton, TableCell, TableHeader, UserAvatar } from 'redesign';
import type { CellDefinition, ColumnDefinition } from './flex-workers-table.types';

const getFlexWorkersColumnDefinition = (): Record<
  Exclude<keyof ColumnDefinition, 'id'>,
  Column<ColumnDefinition>
> => {
  return {
    imageUrl: {
      accessor: 'imageUrl',
      Header: '',
      Cell: (cell: CellDefinition<'imageUrl'>) => {
        const [firstName, lastName] = cell.row.original.name.split(' ');

        return (
          <UserAvatar
            onClick={() => cell.onShowFlexWorker(cell.row.original.id)}
            firstName={firstName}
            lastName={lastName}
            imageUrl={cell.value}
          />
        );
      },
    },
    name: {
      accessor: 'name',
      Header: () => <TableHeader>Naam</TableHeader>,
      Cell: ({ value }) => {
        return <TableCell>{value}</TableCell>;
      },
    },
    email: {
      accessor: 'email',
      Header: () => <TableHeader>Emailadres</TableHeader>,
      Cell: ({ value }) => (
        <TableCell>
          <a href={`mailto:${value}`}>{value}</a>
        </TableCell>
      ),
    },
    phone: {
      accessor: 'phone',
      Header: () => <TableHeader>Telefoonnummer</TableHeader>,
      Cell: ({ value }) => {
        return <TableCell>{value}</TableCell>;
      },
    },
    rating: {
      accessor: 'rating',
      Header: () => <TableHeader>Beoordeling</TableHeader>,
      Cell: ({ value }) => (value ? <Typography noWrap>{value}%</Typography> : null),
    },
    deleteAction: {
      accessor: 'deleteAction',
      Header: '',
      Cell: (cell: CellDefinition<'deleteAction'>) => (
        <Tooltip title="Verwijder">
          <LwIconButton
            color="default"
            iconColorMode="stroke"
            onClick={() => cell.onRemoveFlexWorker(cell.row.original.id)}
          >
            <IconTrash />
          </LwIconButton>
        </Tooltip>
      ),
    },
  };
};

export { getFlexWorkersColumnDefinition };
