import { useQuery } from 'react-query';
import { workApplicationService as ogpWorkApplicationService } from 'ogp/services';
import { workApplicationService as hqWorkApplicationService } from 'hq/services';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../../../../query-keys/project';

export const useGetApplicants = (projectId: string) => {
  const siteDetectionResult = useSiteDetection();
  const queryFn = getQueryFunction(siteDetectionResult, projectId);

  return useQuery(
    SHARED_QUERY_KEYS_PROJECT.workApplicationsForProject(siteDetectionResult),
    queryFn
  );
};

const getQueryFunction = (siteDetectionResult: SiteDetectionResult, projectId: string) => {
  if (siteDetectionResult.isHQ) {
    return async () => await hqWorkApplicationService.workApplicationsForProject(projectId);
  }
  if (siteDetectionResult.isOGP) {
    return async () => await ogpWorkApplicationService.workApplicationsForProject(projectId);
  }

  throw new Error(`useGetApplicants: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
