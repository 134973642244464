import { EnvironmentService } from 'shared/services';

type Pool = {
  userPoolId?: string;
  userPoolWebClientId?: string;
};

const pools: Record<'ogp' | 'hq' | 'partner', Pool> = {
  ogp: {
    userPoolId: EnvironmentService.getVariable('COGNITO_OGP_USER_POOL_ID'),
    userPoolWebClientId: EnvironmentService.getVariable('COGNITO_OGP_USER_POOL_CLIENT_ID'),
  },
  hq: {
    userPoolId: EnvironmentService.getVariable('COGNITO_HQ_USER_POOL_ID'),
    userPoolWebClientId: EnvironmentService.getVariable('COGNITO_HQ_USER_POOL_CLIENT_ID'),
  },
  partner: {
    userPoolId: EnvironmentService.getVariable('COGNITO_PARTNER_USER_POOL_ID'),
    userPoolWebClientId: EnvironmentService.getVariable('COGNITO_PARTNER_USER_POOL_CLIENT_ID'),
  },
};

export { pools };
export type { Pool };
