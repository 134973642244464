import { branchOfficeAlerts } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components';
import { BranchOfficeFormData } from '@types';
import { useParams } from 'react-router-dom';
import { BranchOfficeCreatePage } from './branch-office-create-page';
import { invariant } from '../../../../../../../shared/utils/utils';
import { useGetBranchOffice } from '../../../../../../hooks/queryHooks/branchoffices/use-get-branch-office';
import { useGetCompanyDetailAndJobGroups } from '../../../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

export const BranchOfficeDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Branch office ID must be provided via URL');
  const companyQuery = useGetCompanyDetailAndJobGroups();
  const branchOfficeQuery = useGetBranchOffice(id);

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  if (branchOfficeQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficeQuery} />;
  }

  const defaultValues: BranchOfficeFormData = {
    name: branchOfficeQuery.data.name,
    companyId: branchOfficeQuery.data.companyId,
    location: branchOfficeQuery.data.location,
    locationUrl: branchOfficeQuery.data.locationUrl,
    legalPerson: branchOfficeQuery.data.legalPerson,
    cocNumber: branchOfficeQuery.data.cocNumber,
    companyNameForOVO: branchOfficeQuery.data.companyNameForOVO,
    VAT: branchOfficeQuery.data.VAT,
    costCenter: branchOfficeQuery.data.costCenter ?? '',
    billingEntityId: branchOfficeQuery.data.billingEntityId ?? '',
  };

  return (
    <BranchOfficeCreatePage
      defaultValues={defaultValues}
      successMessage={branchOfficeAlerts.success.created}
      pageTitle={`Branch office ${defaultValues.name} dupliceren`}
    />
  );
};
