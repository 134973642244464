import { Box, Grid } from '@mui/material';
import { useGetCompaniesAsSelect } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components';
import { FormEvent, useCallback } from 'react';
import {
  AutocompleteOption,
  Header,
  LwButton,
  LwFormMultiSelect,
  LwFormSelect,
  Page,
  Toolbox,
} from 'redesign';
import { useAddFlexWorkerToFlexpool } from './hooks/use-add-flex-worker-to-flex-pool';
import { useGetFlexPools } from './hooks/use-get-flex-pools';
import { useGetFlexPoolsForFlexWorker } from '../use-get-flex-pools-for-flex-worker';
import { invariant } from '../../../../../../../../shared/utils/utils';
import { IconApproved, IconCancel } from '../../../../../../../../assets/img';
import { hqRoutes } from '../../../../../../../Routes';
import { CompaniesSelectModel } from '../../../../../../../services/mappers/mappers';
import { useHqRelativePath } from '../../../../../../../hooks/useHqRelativePath';
import { FlexPool } from '../../../../../../../services/flex-pool-service.types';

const FlexPoolsAddFlexWorker = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'No FW ID provided');
  invariant(id, 'Incorrect employment type provided');

  const companiesQuery = useGetCompaniesAsSelect();
  const flexPoolsQuery = useGetFlexPools();
  const selectedFlexPoolsQuery = useGetFlexPoolsForFlexWorker(id);

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  if (flexPoolsQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexPoolsQuery} />;
  }

  if (selectedFlexPoolsQuery.status !== 'success') {
    return <RenderUnsettledUI data={selectedFlexPoolsQuery} />;
  }

  return (
    <FlexPoolsAddFlexWorkerImpl
      companyData={companiesQuery.data}
      flexPoolData={flexPoolsQuery.data}
      selectedFlexPoolsData={selectedFlexPoolsQuery.data}
      flexWorkerId={id}
    />
  );
};

const FlexPoolsAddFlexWorkerImpl = ({
  companyData,
  flexPoolData,
  selectedFlexPoolsData,
  flexWorkerId,
}: {
  companyData: CompaniesSelectModel;
  flexPoolData: FlexPool[];
  selectedFlexPoolsData: FlexPool[];
  flexWorkerId: string;
}) => {
  const { alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { handleSubmit, control, watch } = useForm<FlexWorkerFlexPoolFormData>();

  const { mutate, isLoading: isSaving } = useAddFlexWorkerToFlexpool();

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(({ flexPools }) =>
        mutate(
          { flexWorkerId, flexPoolIds: flexPools.map((i) => i.value) },
          {
            onSuccess: () => navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId })),
            onError: (e) => alertError(e),
          }
        )
      )();
    },
    [flexWorkerId]
  );

  const companyOptions = companyData;
  const companyId = watch('companyId');
  const selectedCompany = companyOptions.find((i) => i.value === companyId);
  const openFlexPoolsForCompany = flexPoolData
    .filter((fp) => fp.companyName === selectedCompany?.label)
    .filter((fp) => selectedFlexPoolsData.find((i) => i.id === fp.id) === undefined);
  const flexPoolOptions = openFlexPoolsForCompany.map((i) => ({ value: i.id, label: i.name }));

  return (
    <form onSubmit={onSubmit}>
      <Page
        header={<Header titleText={LABELS.title} />}
        toolbox={
          <Toolbox>
            <LwButton
              type="submit"
              iconColorMode="stroke"
              color="primary"
              startIcon={<IconApproved />}
            >
              {LABELS.title}
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId }))}
              iconColorMode="fill"
              color="secondary"
              startIcon={<IconCancel />}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <Box width="50%">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <LwFormSelect
                data-testid="client-dropdown"
                name="companyId"
                label={LABELS.clientLabel}
                defaultLabel="Selecteer een bedrijf"
                options={companyOptions || []}
                disabled={isSaving}
                control={control}
                rules={{ required: 'Selecteer een bedrijf' }}
              />
            </Grid>
            <Grid item xs={12}>
              <LwFormMultiSelect
                data-testid="flexpool-dropdown"
                name="flexPools"
                control={control}
                rules={{ required: 'Selecteer minstens één functie' }}
                options={flexPoolOptions}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!selectedCompany || isSaving}
                label={LABELS.flexpoolLabel}
                getOptionLabel={(option) => option.label}
                getOptionKey={(option) => option.value}
              />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </form>
  );
};

const LABELS = {
  title: 'Flexpool beheer',
  clientLabel: 'Client',
  flexpoolLabel: 'Flexpool',
};

type FlexWorkerFlexPoolFormData = {
  companyId: string;
  flexPools: AutocompleteOption[];
};

export { FlexPoolsAddFlexWorker };
