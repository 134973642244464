import { HqApiService } from './hqApiService';
import { HqProjectGetResponse } from './project-service.types';
import {
  AddPlacementPayload,
  ProjectCancellation,
} from '../../shared/services/project-service.types';

export class ProjectService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getProjectDetail = async (id: string) => {
    const response = await this.hqApiService.get<HqProjectGetResponse>({
      path: `projects/${id}`,
    });

    return response;
  };

  public createPlacement = async (body: AddPlacementPayload) => {
    const response = await this.hqApiService.create<void>({
      path: `projects/${body.projectId}/create-placement`,
      body,
    });

    return response;
  };

  public getProjectCancellations = async (workId: string) => {
    const response = await this.hqApiService.get<ProjectCancellation[]>({
      path: `projects/${workId}/cancellations`,
    });

    return response;
  };
}
