import { Grid } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectFormType } from 'shared/components/project-forms/types/project-form.types';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { JobListItem } from '@types';

import { CompanyInput } from 'shared/components/shift-forms/components/forms/shared/inputs/company-input';
import { HqProjectCreatePayload } from 'hq/services/project-planning-service.types';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { ProjectFormProvider } from './project-form-provider';
import { useCreateProject } from '../../hooks/use-create-project';
import { useProjectPlanningPage } from '../../table/hooks/use-project-planning';

type HqProjectFormType = ProjectFormType & { companyId: string };
type ProjectCreateProps = {
  jobsData: JobListItem[];
  companies: GetCompaniesResponse[];
  defaultValues?: Partial<ProjectFormType> | Partial<HqProjectFormType>;
  successMessage: string;
  pageTitle: string;
};

export const ProjectCreateFormPage = ({
  jobsData,
  companies,
  defaultValues = {},
  successMessage,
  pageTitle,
}: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();

  const formMethods = useForm<HqProjectFormType>({
    defaultValues,
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const mutation = useCreateProject();
  const { params: filters } = useProjectPlanningPage();
  const isDuplicateForm = 'companyId' in defaultValues;

  const companyId = formMethods.watch('companyId');

  useEffect(() => {
    if (!isDuplicateForm) {
      formMethods.reset({ companyId }, { keepDefaultValues: true });
    }
  }, [companyId, formMethods]);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data: HqProjectFormType) => {
      const payload: HqProjectCreatePayload = {
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        hourlyRateCents: Math.round(+data.hourlyRate * 100),
      };
      mutation.mutate(payload, {
        onSuccess: (id) => {
          alertSuccess(successMessage);
          navigate(
            generatePath(
              hqRoutes.PlannerProject,
              {},
              { ...stringifyFilters(filters), projectDetailId: id }
            )
          );
        },
        onError: (e) => alertError(e),
      });
    })();
  }, []);

  const onCancel = useCallback(
    () => navigate(generatePath(hqRoutes.PlannerProject, stringifyFilters(filters))),
    [filters, generatePath, navigate]
  );

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <CompanyInput
              options={companies.map((i) => ({ label: i.name, value: i.id }))}
              disabled={!!isDuplicateForm}
            ></CompanyInput>
            {companyId ? <ProjectFormProvider companyId={companyId} jobsData={jobsData} /> : null}
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
