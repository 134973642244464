import { Typography, TypographyProps } from '@mui/material';
import { escapeRegExp } from 'lodash';

type HighlightedTextProps = {
  text: string;
  highlight: string;
  variant: TypographyProps['variant'];
};

export const HighlightedText = ({ text, highlight, variant }: HighlightedTextProps) => {
  if (!highlight.trim()) {
    return <Typography variant={variant}>{text}</Typography>;
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex).filter(Boolean);
  const jsx = parts.map((part) => {
    const shouldHighlight = regex.test(part);
    if (shouldHighlight) {
      return <mark key={`${text}--${part}`}>{part}</mark>;
    }
    return <span key={`${text}--${part}`}>{part}</span>;
  });
  return <Typography variant={variant}>{jsx}</Typography>;
};
