import Grid from '@mui/material/Grid';
import { useGetBranchOffices } from 'hq/hooks';
import { useGetCompaniesForFlexWorker } from 'hq/hooks/queryHooks/companies/use-get-companies-for-flex-worker';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { emptyShiftClaimFilters } from 'shared/contexts/FilterContext';
import { InputSelectOption } from '@types';
import { LwFormDate, LwFormSelect } from 'redesign';
import { useShiftClaimFilters } from './shift-claim-filters-provider';
import { useGetJobTypes } from '../../../../../../hooks/queryHooks/job-types/use-get-job-types';

type Props = {
  flexWorkerId: string;
};

const ShiftClaimListFilters = ({ flexWorkerId }: Props) => {
  const { control, watch, setValue } = useForm();
  const { shiftClaimFilters, setShiftClaimFilters } = useShiftClaimFilters();

  useEffect(() => {
    return () => setShiftClaimFilters(emptyShiftClaimFilters);
  }, [setShiftClaimFilters]);

  const createListItem = (el: { id: string; name: string }) => ({
    value: el.id,
    label: el.name,
  });

  const companiesQuery = useGetCompaniesForFlexWorker(flexWorkerId);
  const companyOptions = useMemo(
    () => companiesQuery.data?.map(createListItem) ?? [],
    [companiesQuery.data]
  );

  const branchOfficeQuery = useGetBranchOffices({
    companyId: shiftClaimFilters.companyId ?? '',
    includeArchived: true,
  });
  const branchOfficeOptions = useMemo(
    () => branchOfficeQuery.data?.map(createListItem) ?? [],
    [branchOfficeQuery.data]
  );

  const jobTypesQuery = useGetJobTypes(
    shiftClaimFilters.branchOfficeId,
    shiftClaimFilters.companyId
  );
  const jobTypeOptions = useMemo(
    () => jobTypesQuery.data?.map(createListItem) ?? [],
    [jobTypesQuery.data]
  );

  const companyId = watch('companyId');
  const branchOfficeId = watch('branchOfficeId');
  const jobTypeId = watch('jobTypeId');
  const state = watch('state');
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  useEffect(() => {
    setShiftClaimFilters((current) => ({
      ...current,
      companyId: companyId || undefined,
      branchOfficeId: branchOfficeId || undefined,
      jobTypeId: jobTypeId || undefined,
      state: state || undefined,
      fromDate: fromDate || undefined,
      toDate: toDate || undefined,
    }));
  }, [companyId, branchOfficeId, jobTypeId, state, fromDate, toDate, setShiftClaimFilters]);

  const all: InputSelectOption = {
    value: '',
    label: 'Alle',
  };

  return (
    <form>
      <Grid container item spacing={2} xs={6}>
        <Grid item xs={6}>
          <LwFormDate name="fromDate" label="Vanaf" maxDate={watch('toDate')} control={control} />
        </Grid>
        <Grid item xs={6}>
          <LwFormDate name="toDate" label="Tot" control={control} />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="companyId"
            label="Bedrijf"
            options={[all, ...companyOptions]}
            onChange={(e) => {
              setValue('companyId', e.target.value as string);
              setValue('branchOfficeId', undefined);
              setValue('jobTypeId', undefined);
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="branchOfficeId"
            label="Vestiging"
            options={[all, ...branchOfficeOptions]}
            control={control}
            disabled={!shiftClaimFilters.companyId}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="jobTypeId"
            label="Functiegroep"
            options={[all, ...jobTypeOptions]}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="state"
            label="Shiftclaim status"
            options={[
              all,
              {
                value: 'canceled',
                label: 'Geannuleerd',
              },
              {
                value: 'accepted',
                label: 'Geclaimed',
              },
              {
                value: 'completed',
                label: 'Afgerond',
              },
              {
                value: 'noShow',
                label: 'No-show',
              },
              {
                value: 'pending',
                label: 'Pending',
              },
            ]}
            control={control}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { ShiftClaimListFilters };
