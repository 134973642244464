import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { IconAdd } from 'assets/img';
import { FeeType } from 'ogp/services/billing-entity-service.types';
import { LwButton } from 'redesign';
import { BillingEntityFormFields } from './billing-entity-form.types';
import { FeeItem } from './fee-item';

type DynamicFeesProps = {
  feeType: FeeType;
};

const DynamicFees = ({ feeType }: DynamicFeesProps) => {
  const { control, watch } = useFormContext<BillingEntityFormFields>();
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: '_id',
    name: `fees.${feeType}`,
  });
  const getFee = (index: number) => watch(`fees.${feeType}.${index}`);

  const appendNewFee = (feeType: FeeType) =>
    append({
      hourlyRate: 0.01,
      startDate: DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      endDate: '',
      feeType,
    });

  const isRemovable = (fee: { id?: string }) => !fee.id;

  const isEndDateRequired = (index: number) => fields.length > index + 1;

  return (
    <>
      {fields.map((fee, index) => (
        <Grid key={index} item xs={12}>
          <FeeItem
            fee={getFee(index)}
            onRemove={isRemovable(fee) ? remove : undefined}
            index={index}
            control={control}
            isEndDateRequired={isEndDateRequired(index)}
          />
        </Grid>
      ))}
      <Grid item xs={12} mt={2} mb={-3}>
        <LwButton
          onClick={() => appendNewFee(feeType)}
          iconColorMode="fill"
          startIcon={<IconAdd />}
          color="secondary"
        >
          {addBillingFeeButtonLabel[feeType]}
        </LwButton>
      </Grid>
    </>
  );
};

const addBillingFeeButtonLabel: Record<FeeType, string> = {
  platform: 'Voeg platformkosten toe',
  shiftManager: 'Voeg shiftmanager fee toe',
};

export { DynamicFees };
