import { useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { Header, Page } from 'redesign';
import { formatTime } from 'shared/utils/formatting';
import { DateTime } from 'luxon';
import { getExpenseConfigForEmploymentType } from 'shared/utils/expenses';
import { ShiftCheckoutForm } from './shift-checkout-form';
import { ShiftCheckoutFormData } from './shift-checkout-form.types';
import { useGetWorkedShiftQuery } from '../hooks/use-get-worked-shift';
import { ShiftDetailsHeader } from '../shift-details-header/shift-details-header';

export const CreateCheckout = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const workedShift = useGetWorkedShiftQuery(id);

  if (workedShift.status !== 'success') {
    return <RenderUnsettledUI data={workedShift} />;
  }

  const shiftData = workedShift.data;

  const defaultValues: ShiftCheckoutFormData = {
    startDate: DateTime.fromISO(shiftData.scheduledTime.startDate).toISODate(),
    endDate: DateTime.fromISO(shiftData.scheduledTime.endDate).toISODate(),
    startTime: formatTime(DateTime.fromISO(shiftData.scheduledTime.startDate).toJSDate()),
    endTime: formatTime(DateTime.fromISO(shiftData.scheduledTime.endDate).toJSDate()),
    breakMinutes: shiftData.scheduledTime.breakMinutes ?? 0,
    expenses: getExpenseConfigForEmploymentType(shiftData.workerEmploymentType).map((expense) => ({
      expenseType: expense.id,
      amount: '0',
      unit: expense.unit,
      description: expense.formLabel,
    })),
  };

  return (
    <Page
      header={<Header titleText="Uren indienen" />}
      pageStyle={{ height: '70%' }}
      toolbox={<ShiftDetailsHeader shiftData={shiftData} />}
    >
      <ShiftCheckoutForm
        shiftId={shiftData.shiftId}
        flexWorkerId={shiftData.flexWorkerId}
        workerEmploymentType={shiftData.workerEmploymentType}
        defaultValues={defaultValues}
      />
    </Page>
  );
};
