import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import { useState } from 'react';
import { BlockLink, createBlock, LwBlock, LwButton, Page, SearchBar, Toolbox } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { IconAdd, IconBranchOffices } from 'assets/img';
import { useGetBranchOfficesListing } from 'ogp/hooks/queryHooks/branchoffices/use-get-branch-offices-listing';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'Routes';

const BranchOffices = () => {
  const generatePath = useCompanyRelativePath();
  const { refetch, status, data, error } = useGetBranchOfficesListing({
    includeArchived: false,
  });
  const [filter, setFilter] = useState('');

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  const blocks = data.map((block) => createBlock(block));

  const filtered = blocks.filter((branchOffice) => {
    return branchOffice.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });

  return (
    <Page
      header={
        <HeaderStatistics
          titleText="Vestigingen"
          subheaderText="Hier zie je alle vestigingen binnen jouw bedrijf"
          type="branch-offices"
        />
      }
      toolbox={
        <Toolbox>
          <LwButton startIcon={<IconAdd />} to="create" color="primary" iconColorMode="fill">
            Voeg vestiging toe
          </LwButton>
          <SearchBar
            onChange={setFilter}
            initialValue={filter}
            placeholder="Filter vestigingen op naam"
          />
        </Toolbox>
      }
    >
      {filtered.length === 0 ? (
        <Typography>Er zijn geen vestigingen die aan je filter voldoen.</Typography>
      ) : (
        <Box width="100%">
          <Grid container spacing={4} sx={{ marginBottom: '32px' }}>
            {filtered.map((block) => (
              <Grid item xs={6} md={4} lg={3} key={block.id}>
                <BlockLink to={generatePath(routes.BranchOfficesDetail, { id: block.id })}>
                  <LwBlock
                    icon={<IconBranchOffices width={24} height={24} />}
                    severity={block.type?.severity}
                    id={block.id}
                    title={block.title}
                    subtitle={block.subtitle}
                    dataBlocks={block.dataBlocks}
                  />
                </BlockLink>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Page>
  );
};

export { BranchOffices };
