import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { BillingEntityCreatePage } from './billing-entity-create-page';
import { RenderUnsettledUI } from '../../../../../../../shared/components';
import { billingEntityAlerts } from '../../../../../../../shared/components/alerts';
import { invariant } from '../../../../../../../shared/utils/utils';
import { useGetBillingEntityAsDetail } from '../../hooks/use-get-billing-entity';
import { BillingEntityFormFeeType, BillingEntityFormFields } from '../../components';
import { mapFeesResponseToForm } from '../../utils/utils';
import { FeeType } from '../../../../../../services/billing-entity-service.types';

export const BillingEntityDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Billing entity id is required');
  const billingEntity = useGetBillingEntityAsDetail(id);

  if (billingEntity.status !== 'success') {
    return <RenderUnsettledUI data={billingEntity} />;
  }

  const encodedFees: BillingEntityFormFields['fees'] = mapFeesResponseToForm(
    billingEntity.data.fees,
    true
  );

  const fees = getActiveFees(encodedFees);

  const defaultValues: BillingEntityFormFields = {
    vatNumber: billingEntity.data.vatNumber,
    name: billingEntity.data.name,
    companyName: billingEntity.data.companyName,
    contactPerson: billingEntity.data.contactPerson,
    contactEmail: billingEntity.data.contactEmail,
    cocNumber: billingEntity.data.cocNumber,
    location: billingEntity.data.location,
    fees,
    paymentTerm: billingEntity.data.paymentTerm,
  };

  return (
    <BillingEntityCreatePage
      defaultValues={defaultValues}
      successMessage={billingEntityAlerts.success.created}
      pageTitle={`Facturatiegegevens ${defaultValues.name} dupliceren`}
    />
  );
};

const getActiveFees = (
  formFees: BillingEntityFormFields['fees']
): BillingEntityFormFields['fees'] => {
  if (!formFees) {
    return formFees;
  }

  const result: BillingEntityFormFields['fees'] = {
    platform: [],
    shiftManager: [],
  };
  Object.entries(formFees).forEach(([ft, fees]) => {
    const feeType: FeeType = ft as any;
    if (fees.length == 0) {
      result[feeType] = fees;
      return;
    }

    fees.forEach((fee) => {
      const startDateTime = DateTime.fromISO(fee.startDate);
      const startDiffFromNow = startDateTime.diff(
        DateTime.now().plus({ day: 1 }).startOf('day'),
        'day'
      );

      if (fee.endDate) {
        const endDateTime = DateTime.fromISO(fee.endDate);
        const endDiffFromNow = endDateTime.diff(
          DateTime.now().plus({ day: 1 }).startOf('day'),
          'day'
        );
        if (endDiffFromNow.days < 0) {
          return;
        }
      }

      if (startDiffFromNow.days < 0) {
        const resultFee: BillingEntityFormFeeType = {
          ...fee,
          startDate: DateTime.now().plus({ day: 1 }).toISODate(),
        };

        result[feeType].push(resultFee);
        return;
      }

      result[feeType].push(fee);
    });
  });

  return result;
};
