import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { userService } from 'ogp/services';
import { userResponse2UserFormModel, userResponse2UserModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { FirstParamOfFn } from '@types';

type Response = Awaited<ReturnType<typeof userService.getUser>>;

type Params = FirstParamOfFn<typeof useGetUserBase>;

const useGetUserBase = <TData = Response>(userId: string, select?: (data: Response) => TData) => {
  const queryFn = async () => await userService.getUser(userId);

  return useQuery(QUERY_KEYS_OGP.user(userId), queryFn, {
    select,
  });
};

const useGetUser = (params: Params) => {
  const result = useGetUserBase(params, userResponse2UserModel);
  return result;
};

const useGetUserAsForm = (params: Params) => {
  const result = useGetUserBase(params, userResponse2UserFormModel);
  return result;
};

export { useGetUserAsForm, useGetUser };
