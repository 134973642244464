import { DateTime, Interval } from 'luxon';
import { FeePayload, FeeType } from 'ogp/services/billing-entity-service.types';
import { hasOverlappingIntervals } from 'shared/utils/has-overlapping-date-ranges';
import { TakeDefined } from '@types';
import { BillingEntityFormFields } from '../components';
import { useGetBillingEntityAsDetail } from '../hooks/use-get-billing-entity';

type Period = {
  startDate: DateTime;
  endDate?: DateTime;
};

const feesLabels: Record<FeeType, string> = {
  platform: 'Platformkosten',
  shiftManager: 'Shiftmanager fee',
};

const hasInvalidPeriods = (periods: Period[]) => {
  const fixedPeriods = periods.filter(
    (
      period
    ): period is Omit<typeof period, 'endDate'> & {
      endDate: DateTime;
    } => period.endDate != null
  );
  const openPeriods = periods.filter(
    (
      period
    ): period is Omit<typeof period, 'endDate'> & {
      endDate: undefined;
    } => period.endDate == null
  );

  const fixedIntervals = fixedPeriods.map((fee) => {
    return Interval.fromDateTimes(fee.startDate, fee.endDate);
  });

  if (
    openPeriods[0] &&
    fixedIntervals.some((period) => period.contains(openPeriods[0].startDate))
  ) {
    return true;
  }
  if (hasOverlappingIntervals(fixedIntervals)) {
    return true;
  }
};

const getDateTimes = <T extends { startDate: string; endDate?: string }>(dates: T[]) => {
  return dates.map((date) => {
    return {
      startDate: DateTime.fromISO(date.startDate).startOf('day'),
      endDate: date.endDate ? DateTime.fromISO(date.endDate).endOf('day') : undefined,
    };
  });
};

const decodeFees = (_fees: BillingEntityFormFields['fees']): FeePayload[] => {
  if (!_fees) {
    return [];
  }
  return Object.values(_fees).flatMap((fees) => {
    return fees.map(({ id, hourlyRate, startDate, endDate, feeType }) => ({
      id,
      hourlyRateCents: Math.round(hourlyRate * 100),
      startDate: DateTime.fromFormat(startDate, 'yyyy-MM-dd').toISO(),
      endDate: endDate
        ? DateTime.fromFormat(endDate, 'yyyy-MM-dd').endOf('day').toISO()
        : undefined,
      feeType,
    }));
  });
};

const getOverlappingFeeTypes = (fees: BillingEntityFormFields['fees']): FeeType[] => {
  if (!fees) {
    return [];
  }
  const invalidFeeTypes = (Object.keys(fees) as (keyof typeof fees)[]).filter((feeType) => {
    const feePeriods = getDateTimes(fees[feeType]);
    return hasInvalidPeriods(feePeriods);
  });

  return invalidFeeTypes;
};

const mapFeesResponseToForm = (
  fees: TakeDefined<ReturnType<typeof useGetBillingEntityAsDetail>['data']>['fees'],
  omitId: boolean
): BillingEntityFormFields['fees'] => {
  const result: TakeDefined<BillingEntityFormFields['fees']> = { shiftManager: [], platform: [] };
  for (const key in fees) {
    const currentFees = fees[key];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const feesWithoutId = omitId ? currentFees.map(({ id, ...be }) => be) : currentFees;
    result[key as FeeType] = feesWithoutId;
  }
  return result;
};

export { feesLabels, decodeFees, getDateTimes, getOverlappingFeeTypes, mapFeesResponseToForm };
