import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { userService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { FirstParamOfFn } from '@types';

type UserMutationOptions = UseMutationOptions<
  unknown,
  Error,
  FirstParamOfFn<typeof userService.createUser>
>;

export const useCreateUser = (options?: UserMutationOptions) => {
  const mutationFn = async (user: FirstParamOfFn<typeof userService.createUser>) =>
    await userService.createUser(user);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: UserMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.userList());
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
