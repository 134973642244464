import { useToggle } from 'shared/hooks';

const useSearchBar = () => {
  const { on: folded, toggle } = useToggle(true);

  return {
    folded,
    toggle,
  };
};

export { useSearchBar };
