import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { BlockProps, LwBlock } from 'redesign';
import { useGetFlexPoolsForFlexWorker } from './use-get-flex-pools-for-flex-worker';
import { RenderUnsettledUI } from '../../../../../../../shared/components';

type FlexWorkerFlexPoolProps = {
  flexWorkerId: string;
};

const FlexWorkerFlexPools = ({ flexWorkerId }: FlexWorkerFlexPoolProps) => {
  const flexPoolsQuery = useGetFlexPoolsForFlexWorker(flexWorkerId);

  if (flexPoolsQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexPoolsQuery} />;
  }

  const blocks: BlockProps[] = flexPoolsQuery.data.map((flexPool) => ({
    id: flexPool.id,
    title: flexPool.name,
    subtitle: `${flexPool.departmentName} - ${flexPool.companyName}`,
  }));

  if (blocks.length === 0) {
    return <Typography>Deze flexwerker zit niet in een flexpool</Typography>;
  }

  return (
    <Grid container spacing={4}>
      {blocks.map((block) => {
        return (
          <Grid item xs={4} key={block.id}>
            <LwBlock {...block} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export { FlexWorkerFlexPools };
