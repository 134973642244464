import { GetCompanyPartnerRelationsResponse } from 'hq/services/company-service.types';
import { RenderUnsettledUI } from 'shared/components';

import { CompanyPartnersList } from './company-partners-list/company-partners-list';
import { useGetCompanyPartners } from './hooks/use-get-company-partners';

export const CompanyPartnersDataProvider = ({ id }: { id: string }) => {
  const companyPartnerQuery = useGetCompanyPartners(id);

  if (companyPartnerQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyPartnerQuery} />;
  }

  return <CompanyPartners data={companyPartnerQuery.data} />;
};

const CompanyPartners = ({ data }: { data: GetCompanyPartnerRelationsResponse[] }) => {
  return <CompanyPartnersList data={data} />;
};
