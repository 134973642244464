import { WorkApplicationCancelationReasons } from '@types';
import { ShiftApplicationCancelled } from '../../../src/shared/components/shift-detail-modal/components/tabs/cancelled/cancelled-table.types';
import { ProjectCancellation } from '../services/project-service.types';

type CancelationReasonToTextType<CancelationReasonObject extends string> = {
  [Key in CancelationReasonObject]: string;
};
type WorkApplicationCancelationReasonsToTextType =
  CancelationReasonToTextType<WorkApplicationCancelationReasons>;
export const workApplicationCancelationReasonsToText = {
  unknown: 'Afwijzing onbekend',
  byFW: 'Afmelding door flexwerker',
  automaticAllSpotsFilled: 'Afwijzing door vervullen shift',
  automaticOverlappingShift: 'Afwijzing door overlappende shift',
  automaticJobOrShiftDetailsChanged: 'Afwijzing door vervullen shift',
  archived: 'Afwijzing onbekend',
  byOG: 'Afwijzing door opdrachtgever',
  byHQ: 'Afwijzing door HQ',
  byNotWelcomeList: 'Afwijzing wegens niet-welkom-lijst',
} as const satisfies WorkApplicationCancelationReasonsToTextType;

// TODO: Unify the types to both (ProjectCancellation and ShiftApplicationCancelationReasons) to use `cancelationReason` instead of `cancellationReason`
const isProjectCancelation = (
  input: ShiftApplicationCancelled | ProjectCancellation
): input is ProjectCancellation => {
  return 'cancellationReason' in input;
};

export const getWorkApplicationCancelationReason = (
  input: ShiftApplicationCancelled | ProjectCancellation
) => {
  if (isProjectCancelation(input)) {
    return input.cancellationReason
      ? workApplicationCancelationReasonsToText[input.cancellationReason]
      : workApplicationCancelationReasonsToText.unknown;
  }

  return workApplicationCancelationReasonsToText[input.cancelationReason];
};
