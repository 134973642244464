import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';

type MinimumTempworkerAgeField = {
  minimumTempWorkerAge: number;
};

export const MinimumTempWorkerAgeInput = ({
  disabled,
  helperText,
  placeholder,
  required,
}: {
  disabled?: boolean;
  helperText?: string;
  placeholder?: string;
  required?: boolean;
}) => {
  const { control } = useFormContext<MinimumTempworkerAgeField>();

  return (
    <LwFormNumberInput
      name="minimumTempWorkerAge"
      label="Minimum leeftijd uitzendkracht"
      control={control}
      rules={{
        ...(required ? { required: 'Voer een minimum leeftijd in' } : {}),
      }}
      step={1}
      min={0}
      placeholder={placeholder}
      helperText={helperText}
      disabled={disabled}
    />
  );
};
