import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { companyService } from 'hq/services';
import { useQuery } from 'react-query';

export const useGetCompanyGrossHourlyRates = (id: string) => {
  const queryFn = async () => await companyService.getCompanyGrossHourlyRates(id);

  return useQuery(QUERY_KEYS_HQ.companyGrossHourlyRate(id), {
    queryFn,
  });
};
