import { Box, styled, Tab, Tabs } from '@mui/material';
import { usePartnerCheckoutsMetaData } from 'partner/hooks/queryHooks/partner-checkouts/use-partner-checkouts';
import {
  PartnerCheckoutState,
  usePartnerCheckoutsFiltersState,
} from 'partner/hooks/queryHooks/partner-checkouts/use-partner-checkouts-filters';

const PartnerCheckoutsStateToggle = () => {
  const { data } = usePartnerCheckoutsMetaData();
  const { filters, applyFilters } = usePartnerCheckoutsFiltersState();
  const { checkoutState } = filters;

  function updateCheckoutState(
    _e: React.SyntheticEvent<Element, Event>,
    state: PartnerCheckoutState
  ) {
    applyFilters({ ...filters, checkoutState: state });
  }

  return (
    <Box>
      <Tabs value={checkoutState} onChange={updateCheckoutState}>
        <StyledTab
          label={
            <ContainerBox>
              Gewerkte shifts
              {data && data.workedShiftsCount > 0 ? (
                <NotificationBox>{data.workedShiftsCount}</NotificationBox>
              ) : null}
            </ContainerBox>
          }
          value="workedShifts"
        />
        <StyledTab
          label={
            <ContainerBox>
              Tegenvoorstellen
              {data && data.counterOffersCount > 0 ? (
                <NotificationBox>{data.counterOffersCount}</NotificationBox>
              ) : null}
            </ContainerBox>
          }
          value="counterOffer"
        />
      </Tabs>
    </Box>
  );
};

const StyledTab = styled(Tab)(() => ({
  textTransform: 'uppercase',
  marginRight: '16px',
}));

const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const NotificationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  fontSize: '12px',
  height: '24px',
  borderRadius: '24px',
  backgroundColor: theme.palette.lwSecondary[20],
  marginLeft: theme.spacing(2),
}));

export { PartnerCheckoutsStateToggle };
