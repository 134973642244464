import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { companyService } from 'hq/services';
import { useQuery } from 'react-query';
import { CompanyEmploymentTypes } from '@types';

export const useGetCompanyEmploymentTypes = (companyId: string) => {
  const queryFn = async () => await companyService.getCompanyEmploymentTypes(companyId);

  return useQuery<Promise<CompanyEmploymentTypes>, Error, CompanyEmploymentTypes>(
    QUERY_KEYS_HQ.companyEmploymentTypes(companyId),
    queryFn
  );
};
