import { GetCompanyGrossHourlyRateResponse } from 'hq/services/company-service.types';
import { DateTime } from 'luxon';
import { GrossHourlyRateTableData } from '../company-gross-hourly-rate-table.types';

export const getGrossHourlyRateColumnData = (
  data: GetCompanyGrossHourlyRateResponse
): GrossHourlyRateTableData[] => {
  return data.map((grossHourlyRate) => {
    const startDateTime = DateTime.fromISO(grossHourlyRate.wagePeriodStart);
    const endDateTime = grossHourlyRate.wagePeriodEnd
      ? DateTime.fromISO(grossHourlyRate.wagePeriodEnd)
      : undefined;
    return <GrossHourlyRateTableData>{
      jobTypeName: grossHourlyRate.jobTypeName,
      wagePeriod: {
        endDate: endDateTime,
        startDate: startDateTime,
      },
      actions: {
        id: grossHourlyRate.id,
      },
      grossHourlyRateCents: grossHourlyRate.grossHourlyRateCents,
    };
  });
};
