import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { themeConfig } from 'redesign';
import { PageProps } from './page.types';

export const Page = ({ header, toolbox, children }: PageProps) => {
  return (
    <StyledPage pb={4}>
      <StickyHeader>
        {header}
        {toolbox ? toolbox : null}
      </StickyHeader>
      <StyledContentWrapper>{children || null}</StyledContentWrapper>
    </StyledPage>
  );
};

const StickyHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: themeConfig.backgrounds.main,
  top: 0,
  zIndex: 5,
  padding: `0 ${theme.spacing(8)}`,
  margin: `0 -${theme.spacing(8)}`,
  maxWidth: 'unset',
}));

const StyledPage = styled(Box)({
  width: '100%',
  height: '100vh',
  maxHeight: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  minHeight: 0,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
});
