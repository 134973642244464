import {
  Box,
  Container,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { IconAccessTime, IconPartner } from 'assets/img';
import { ShiftPlanningCellProp } from '../shifts/table/shift-planning-table.types';
import { HqProjectPlanningCellProp } from '../../../../hq/components/views/planner/projects/table/project-planning-table.types';

const BoxDateTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
}));
const BoxTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: theme.spacing(0.5),
}));
const BoxState = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const BoxStateNumbers = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: theme.spacing(4),
}));
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[300],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    color: 'white',
  },
}));
const StyledIconPartner = styled(IconPartner)(() => ({
  '& path': {
    stroke: '#d5d5d5',
    fill: '#d5d5d5',
  },
}));
const Row = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(2),
  },
}));
const PartnerIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  width: '35px',
  height: '35px',
  padding: theme.spacing(0.5),
  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
    backgroundColor: isActive ? '#f3f3f3' : null,
  },
  marginLeft: theme.spacing(2),
}));
export const BoxAction = styled(Box)(() => ({
  display: 'flex',
}));
export const BoxIcon = styled(Box)(() => ({
  padding: '0.5em',
  borderRadius: '50%',
  transition: 'background-color 0.25s',
  '&:hover': {
    backgroundColor: '#D9D9D9',
    cursor: 'pointer',
  },
}));

export const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

export const JobNameCell = (props: ShiftPlanningCellProp<'jobName'>) => {
  return (
    <Tooltip title={props.value}>
      <Typography variant="body1" noWrap style={{ fontWeight: 'bold' }}>
        {props.value}
      </Typography>
    </Tooltip>
  );
};

export const Cell = (
  props:
    | ShiftPlanningCellProp<'companyName' | 'departmentName' | 'location'>
    | HqProjectPlanningCellProp<'companyName' | 'departmentName'>
) => {
  return (
    <Tooltip title={props.value}>
      <Typography variant="body1" noWrap>
        {props.value}
      </Typography>
    </Tooltip>
  );
};

export const StateCell = (
  props: ShiftPlanningCellProp<'state'> | HqProjectPlanningCellProp<'candidateState'>
): JSX.Element => {
  return (
    <BoxState>
      <BoxStateNumbers>
        <Typography variant="body1" data-testid="claimants">
          {props.value.claimants.length}
        </Typography>
        <Typography variant="body1" color="secondary" data-testid="slash-maxclaimants">
          {'/' + props.value.maxClaimants}
        </Typography>
      </BoxStateNumbers>
      {props.children}
    </BoxState>
  );
};

export const DateTimeCell = (props: ShiftPlanningCellProp<'dateTime'>) => {
  return (
    <BoxDateTime>
      <Typography variant="body1" color="secondary">
        {props.value.fromDateTime.toFormat('dd MMM yyyy')}
      </Typography>
      <BoxTime>
        <IconAccessTime />
        <Typography variant="body1">
          {props.value.fromDateTime.toFormat('HH:mm') +
            '-' +
            props.value.toDateTime.toFormat('HH:mm')}
        </Typography>
      </BoxTime>
    </BoxDateTime>
  );
};

export const StartDateCell = (props: HqProjectPlanningCellProp<'fromDate'>) => {
  return (
    <BoxDateTime>
      <Typography variant="body1" color="secondary">
        {props.value.toFormat('dd MMM yyyy')}
      </Typography>
    </BoxDateTime>
  );
};

export const EndDateCell = (props: HqProjectPlanningCellProp<'toDate'>) => {
  return (
    <BoxDateTime>
      <Typography variant="body1" color="secondary">
        {props.value.toFormat('dd MMM yyyy')}
      </Typography>
    </BoxDateTime>
  );
};

export const PartnersCell = (props: ShiftPlanningCellProp<'partners'>) => {
  if (props.value.length === 0) {
    return (
      <StyledTooltip data-testid="partners-tooltip" title={null}>
        <PartnerIconButton isActive={false}>
          <Box>
            <StyledIconPartner data-testid="partners-icon" />
          </Box>
        </PartnerIconButton>
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip
      data-testid="partners-tooltip"
      title={
        <Container>
          <Row>
            {props.value.map((partner) => {
              return <Typography key={partner.name}>{partner.name}</Typography>;
            })}
          </Row>
        </Container>
      }
    >
      <PartnerIconButton isActive={true}>
        <Box>
          <IconPartner data-testid="partners-active-icon" />
        </Box>
      </PartnerIconButton>
    </StyledTooltip>
  );
};
