import { FlexWorkerListItem, PaginatedCollection } from '@types';
import { Box, Grid, Typography } from '@mui/material';
import { FlexWorkerCard } from './flex-worker-card/flex-worker-card';

type FlexWorkerTableProps = {
  flexWorkersData: PaginatedCollection<FlexWorkerListItem>;
  filter: string | undefined;
  openModal: (flexWorkerId: string) => void;
};

export function FlexWorkersTable({ flexWorkersData, filter, openModal }: FlexWorkerTableProps) {
  const flexWorkers = flexWorkersData.items ?? [];

  if (flexWorkers.length === 0) {
    return (
      <Box>
        <Typography>Er zijn geen flexwerkers gevonden!</Typography>
        <Typography>Met de huidige filters zijn er geen flexwerkers gevonden.</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-end">
      <Grid container spacing={1} data-testid="flex-worker-list">
        {flexWorkers.map((flexWorker) => (
          <Grid key={flexWorker.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FlexWorkerCard filter={filter ?? ''} flexWorker={flexWorker} openModal={openModal} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
