import { Box, Chip, styled } from '@mui/material';

const SkillBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(4),
  display: 'flex',
  flexWrap: 'wrap',
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  svg: {
    margin: 0,
    width: '24px',
    path: {
      stroke: theme.palette.lwPrimary[100],
    },
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.lwSecondary[10]}`,
  padding: '8px 16px',
  borderRadius: theme.spacing(6),
  marginBottom: theme.spacing(4),
  backgroundColor: 'transparent',
  height: 'fit-content',
  '.MuiChip-label': {
    color: theme.palette.lwBlack[100],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    padding: 0,
  },
  '.MuiChip-deleteIcon': {
    margin: 0,
    path: {
      stroke: theme.palette.lwPrimary[100],
    },
  },
}));

const StyledWorkRequirementsChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.lwSecondary[10]}`,
  padding: '8px 16px',
  borderRadius: theme.spacing(6),
  borderWidth: '0',
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.lwPrimary[5],
  height: 'fit-content',
  '.MuiChip-label': {
    color: theme.palette.lwBlack[100],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    padding: 0,
  },
}));

export { SkillBox, IconBox, StyledChip, StyledWorkRequirementsChip };
