import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { DateTime } from 'luxon';
import { useMutation, useQueryClient } from 'react-query';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';

export type AddAndApproveAccreditationParams = {
  flexWorkerId: string;
  accreditations: AccreditationFormRepresentation[];
  expirationDate: DateTime | null;
};

export const useAddAndAcceptAccreditation = () => {
  const mutationFn = async ({
    flexWorkerId,
    accreditations,
    expirationDate,
  }: AddAndApproveAccreditationParams) => {
    return await accreditationsService.addAndAcceptAccreditation({
      flexWorkerId,
      accreditations,
      expirationDate,
    });
  };

  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.accreditations());
    },
  });
};
