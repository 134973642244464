import CrossIcon from 'assets/img/icons/cross.svg?react';
import { Box, IconButton, InputAdornment, styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AddButton, LwFormInput } from 'redesign';

export const HqUserToolbox = ({
  search,
  setParams,
}: {
  search: string | undefined;
  setParams: ({ user }: { user: string | undefined }) => void;
}) => {
  const form = useForm<{ search: string | null }>({ defaultValues: { search } });

  return (
    <ToolboxWrapper>
      <Box flex={1}>
        <AddButton>Nieuw gebruiker aanmaken</AddButton>
      </Box>
      <Box flex={1}>
        <LwFormInput
          name="search"
          control={form.control}
          label="Filter gebruikers op naam"
          placeholder="Gebruiker"
          onChange={(e) => {
            setParams({ user: e.target.value ?? undefined });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="veld leegmaken"
                  onClick={() => {
                    form.setValue('search', null);
                    setParams({ user: undefined });
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <CrossIcon width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </ToolboxWrapper>
  );
};

const ToolboxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(6),
}));
