import { FreelanceJobDetailsWithId, JobListItem, TempWorkJobDetailsWithId } from '@types';
import { HqApiService } from './hqApiService';
import { GetJobDetailsForWorkTemplateResponse, GetJobsResponse } from './job-service.types';

export class JobService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Get a list of existing jobs
   */
  public getJobs = async (params: {
    companyId?: string;
    departmentId?: string;
  }): Promise<JobListItem[]> => {
    const { jobs } = await this.hqApiService.get<GetJobsResponse>({
      path: 'jobs',
      params,
    });

    return jobs;
  };

  public getJob = async <T = TempWorkJobDetailsWithId | FreelanceJobDetailsWithId>(
    jobId: string
  ): Promise<T> => {
    const response = await this.hqApiService.get<T>({
      path: `jobs/${jobId}`,
    });

    return response;
  };

  public getJobDetailsForWorkTemplate = async (
    jobId: string
  ): Promise<GetJobDetailsForWorkTemplateResponse> => {
    return await this.hqApiService.get<GetJobDetailsForWorkTemplateResponse>({
      path: `jobs/${jobId}/details-for-work-template`,
    });
  };
}
