import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { HqUser } from '@types';
import { useUsersPage } from '../hooks/params/use-users';
import { useDialog } from '../../../../../ogp/providers/dialog-provider';
import { useAlert, userAlerts } from '../../../../../shared/components/alerts';
import { useDeleteHqUser } from '../hooks/query/use-delete-user';
import { useGetUser } from '../hooks/query/use-get-user';

const env = import.meta.env.VITE_ENV;

export const UserDetail = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const userQuery = useGetUser(id);
  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return <UserDetailImpl data={userQuery.data} />;
};

const UserDetailImpl = ({ data }: { data: HqUser }) => {
  const { alertSuccess, alertError } = useAlert();
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const { filters } = useUsersPage();
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();

  const deleteHqUser = useDeleteHqUser();

  const openDeleteDialog = () => {
    openDialog(
      {
        title: 'Delete user?',
        content:
          env === 'development' || env === 'test'
            ? "🚨🚨 Please be aware that your local and the test environment share the same AWS Cognito instance. This means that if you delete a user on test, it will affect hq users on everyone's local environment as well, and vice versa. Think twice before deleting a user."
            : 'Weet je zeker dat je deze gebruiker wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
      },
      () => {
        disableDialog();
        deleteHqUser.mutate(data.id, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(userAlerts.success.deleted);
            navigate(generatePath(routes.Companies));
          },
          onError: (e) => alertError(e),
          onSettled: () => enableDialog(),
        });
      }
    );
  };

  return (
    <Page
      header={<Header titleText="Gebruiker Details" />}
      toolbox={
        <Toolbox>
          <LwButton color="secondary" to={generatePath(routes.HQUsers, undefined, filters)}>
            Terug
          </LwButton>
          <LwButton to={generatePath(routes.HQUsersEdit, { id: data.id }, filters)}>
            Gebruiker aanpassen
          </LwButton>
          <LwButton color="primary" onClick={openDeleteDialog}>
            Gebruiker verwijderen
          </LwButton>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Voornaam</Typography>
            <Typography variant="body1">{data.firstName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Achternaam</Typography>
            <Typography variant="body1">{data.lastName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Publieke naam</Typography>
            <Typography variant="body1">{data.nickname}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Email</Typography>
            <Typography variant="body1">{data.email}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Role</Typography>
            <Typography variant="body1">{data.roles?.hqRole}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};
