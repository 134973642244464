import { flexPoolService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FlexPool } from '../../../../../../../../services/flex-pool-service.types';

export const useGetFlexPools = () => {
  const queryFn = async () => await flexPoolService.getFlexPools();

  return useQuery<Promise<FlexPool[]>, Error, FlexPool[]>(QUERY_KEYS_HQ.flexPoolList(), queryFn);
};
