import { useNavigate, useParams } from 'react-router-dom';
import { companyAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useUpdateCompany } from 'ogp/hooks/queryHooks/companies/use-update-company';
import { routes } from 'Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import {
  CompanyDetailAndJobGroups,
  CompanyFormData,
  CompanyFormPayload,
  Link as LinkType,
  linkTypes,
  Socials,
} from '@types';
import { invariant } from 'shared/utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { omit } from 'lodash';
import { CompanyForm } from './company-form';
import { CompanyEditProps } from './company-edit.types';
import { CompanyDataProvider } from './company-data-provider';

const CompanyEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Company ID was supposed to be passed via route param');

  return (
    <CompanyDataProvider>
      {(data) => <CompanyEditForm id={id} companyData={data} />}
    </CompanyDataProvider>
  );
};

const CompanyEditForm = ({ id, companyData }: CompanyEditProps) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const mutation = useUpdateCompany(id, {
    onSuccess: () => {
      alertSuccess(alerts.success.updated);
      navigate(generatePath(routes.Company));
    },
    onError: (e) => alertError(e),
  });
  const defaultValues = getDefaultValues(companyData);
  const formMethods = useForm({ defaultValues });

  const handleSubmit = () => {
    formMethods.handleSubmit((formData: CompanyFormData) => {
      const payload = transformData(formData);
      mutation.mutate(payload);
    })();
  };

  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Bedrijf aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton
              onClick={returnTo(routes.Company)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <CompanyForm defaultValues={defaultValues} />
      </Page>
    </FormProvider>
  );
};

function transformData(formData: CompanyFormData) {
  const socials: LinkType[] = linkTypes
    .map((linkType) => ({
      url: formData[linkType],
      type: linkType,
    }))
    .filter((social) => social.url && social.url.length > 0);

  const companyDetails: CompanyFormPayload = {
    ...omit(formData, 'jobGroups', 'employmentType'),
    socials,
  };
  return companyDetails;
}

function getDefaultValues(companyData: CompanyDetailAndJobGroups): CompanyFormData {
  const jobGroupIds = companyData.jobGroups.map((jobGroup) => jobGroup.id);
  const socials: Socials = {
    website: companyData.company.socials?.find((social) => social.type === 'website')?.url,
    facebook: companyData.company.socials?.find((social) => social.type === 'facebook')?.url,
    instagram: companyData.company.socials?.find((social) => social.type === 'instagram')?.url,
    linkedin: companyData.company.socials?.find((social) => social.type === 'linkedin')?.url,
  };
  const defaultValues: CompanyFormData = {
    ...companyData.company,
    ...socials,
    jobGroupIds,
    jobGroups: companyData.jobGroups,
  };
  return defaultValues;
}

export { CompanyEdit };
