import { flexWorkerService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_MEDIUM } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FlexWorkerFilterCounts, FlexWorkerListItem, PaginatedCollection } from '@types';

export const useGetFlexWorkers = (
  params: Parameters<typeof flexWorkerService.getFlexWorkers>[0]
) => {
  const queryFn = async () => await flexWorkerService.getFlexWorkers(params);

  return useQuery<PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts, Error>(
    QUERY_KEYS_HQ.flexWorkersList(params),
    queryFn,
    {
      // Keeps the navigation snappy. And enables small state change animations when editing flexWorkers.
      cacheTime: CACHE_TIME_MEDIUM,
    }
  );
};
