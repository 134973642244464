import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { shiftService } from 'ogp/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';

export const useOgpArchiveShift = (shiftId: string = '', options?: UseMutationOptions) => {
  const mutateFn = async () => await shiftService.archiveShift(shiftId);
  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.shifts()); // Bluntly invalidate ALL shift data because the change could be reflected in a lot of places.

      options?.onSuccess && options.onSuccess(...args);
    },
  });
};
