import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService } from 'hq/services';
import { shiftCreateDataResponse2ShiftCreateDataModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';

export const useHqGetShiftCreateData = (companyId: string) => {
  return useQuery(
    [QUERY_KEYS_HQ.shifts(), QUERY_KEYS_HQ.shiftCreateData(companyId)],
    async () => await shiftService.getShiftCreateData(companyId),
    {
      select: shiftCreateDataResponse2ShiftCreateDataModel,
      enabled: !!companyId,
    }
  );
};
