import { FlexWorkerItem } from './flex-workers.types';

const getFlexWorkersTableData = (data: FlexWorkerItem[]) => {
  return data.map((item) => ({
    id: item.id,
    imageUrl: item.imageUrl,
    email: item.email,
    name: item.name,
    phone: item.phone,
    rating: item.rating,
    deleteAction: null,
  }));
};

export { getFlexWorkersTableData };
