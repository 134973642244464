import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  ProjectPlanningFilters,
  ProjectPlanningFiltersForm,
} from './hooks/use-project-planning-filters.types';
import { useProjectPlanningFilters } from './hooks/use-project-planning-filters';
import { getProjectPlanningDefaultValues } from './get-filter-default-values';
import { useGetProjectWorkers } from './hooks/use-get-project-workers';
import { useGetOgpBusinessUnits } from '../../../../../hooks/query/filters/use-get-business-units';
import { useGetOgpJobTypes } from '../../../../../hooks/query/filters/use-get-job-types';
import { RenderUnsettledUI } from '../../../../../../shared/components';
import {
  OgpBusinessUnitsData,
  OgpJobTypesData,
} from '../../../../../services/ogp-shared-service.types';
import { FilterWrapper } from '../../../../../../shared/components/filters/filter-wrapper';
import { FilterJobTypes } from '../../../../../../shared/components/filters/inputs/job-types/filter-job-types';
import { FilterFilledStatus } from '../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status';
import { FilterBusinessUnits } from '../../../../../../shared/components/filters/inputs/business-units/filter-business-units';
import { FilterApplicantStatus } from '../../../../../../shared/components/filters/inputs/filter-applicant-status/filter-applicant-status';
import {
  ProjectGetParams,
  ProjectGetWorkersResponse,
} from '../../../../../services/project-service.types';
import { FilterToDate } from '../../../../../../shared/components/filters/inputs/date/filter-to-date';
import { FilterWorkers } from '../../../../../../shared/components/filters/inputs/flex-workers/filter-workers';

export const ProjectPlanningFiltersDataProvider = ({
  handleFiltersChange,
  handleClose,
  appliedFilters,
}: {
  handleFiltersChange: ReturnType<typeof useProjectPlanningFilters>['setFilters'];
  handleClose: () => void;
  appliedFilters: ProjectPlanningFilters;
}) => {
  const businessUnitsQuery = useGetOgpBusinessUnits();
  const jobTypeQuery = useGetOgpJobTypes();
  const placedWorkersQuery = useGetProjectWorkers();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (placedWorkersQuery.status !== 'success') {
    return <RenderUnsettledUI data={placedWorkersQuery} />;
  }

  return (
    <ProjectPlanningFiltersComp
      handleFiltersChange={handleFiltersChange}
      appliedFilters={appliedFilters}
      businessUnits={businessUnitsQuery.data}
      handleClose={handleClose}
      jobTypes={jobTypeQuery.data}
      workers={placedWorkersQuery.data}
    />
  );
};

const ProjectPlanningFiltersComp = ({
  handleFiltersChange,
  appliedFilters,
  handleClose,
  businessUnits,
  jobTypes,
  workers,
}: {
  handleFiltersChange: ReturnType<typeof useProjectPlanningFilters>['setFilters'];
  appliedFilters: ProjectPlanningFilters;
  businessUnits: OgpBusinessUnitsData;
  jobTypes: OgpJobTypesData;
  workers: ProjectGetWorkersResponse;
  handleClose: () => void;
}) => {
  const defaultFilterValues = useMemo(() => {
    return getProjectPlanningDefaultValues(appliedFilters, businessUnits, jobTypes, workers);
  }, [appliedFilters, businessUnits, jobTypes, workers]);
  const form = useForm<ProjectPlanningFiltersForm>({ defaultValues: defaultFilterValues });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      form.handleSubmit((data) => {
        const dataToSubmit: ProjectGetParams = {
          toDate:
            data.toDateCheckboxValue && data.toDate
              ? DateTime.fromFormat(data.toDate, 'yyyy-MM-dd')
              : undefined,
          branchOfficeId: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
          hasApplicants:
            data.allowApplicantsFilter && data.hasApplicants !== null
              ? data.hasApplicants
              : undefined,
          filledStatus:
            data.filledStatusCheckboxValue && data.filledStatus !== null
              ? data.filledStatus
              : undefined,
          jobType:
            data.jobTypeCheckboxValue && data.jobType?.length
              ? data.jobType.map((jobTypeSingle) => jobTypeSingle.id)
              : undefined,
          departmentId: data.businessUnitCheckboxValue ? data.department?.value : undefined,
          workerId: data.allowWorkersFilter ? data.worker?.value : undefined,
        };

        handleFiltersChange(dataToSubmit);
        handleClose();
      })();
    },
    [form, handleFiltersChange, handleClose]
  );

  const handleCancel = useCallback(() => {
    handleClose();
  }, [handleClose]);
  const handleClear = useCallback(() => {
    form.reset({
      toDate: defaultFilterValues.toDate,
      toDateCheckboxValue: false,
      businessUnitCheckboxValue: false,
      allowApplicantsFilter: false,
      filledStatusCheckboxValue: false,
      allowWorkersFilter: false,
    });
  }, [defaultFilterValues, form]);
  return (
    <FormProvider {...form}>
      <FilterWrapper
        filterName="Filters"
        onCancel={handleCancel}
        onClear={handleClear}
        onSubmit={handleSubmit}
      >
        <FilterToDate />
        <FilterBusinessUnits branchOffices={businessUnits.branchOffices} />
        <FilterApplicantStatus />
        <FilterFilledStatus />
        <FilterJobTypes jobTypes={jobTypes.jobTypes} />
        <FilterWorkers workers={workers} />
      </FilterWrapper>
    </FormProvider>
  );
};
