import { IconApproved, IconCross, IconTrash } from 'assets/img';
import { ContextMenuItem } from 'redesign';
import { useQueryClient } from 'react-query';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useAlert, workApplicationsAlerts } from 'shared/components/alerts';
import { useDenyWorkApplication } from './use-deny-work-application';
import { useAcceptWorkApplication } from './use-accept-work-application';
import { WorkApplicationTableItem } from '../../../../../../services/work-application-service.types';
import { useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../../../../query-keys/project';

export const useGetApplicantActions = (projectId: string) => {
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const queryClient = useQueryClient();
  const denyWorkApplication = useDenyWorkApplication();
  const acceptWorkApplication = useAcceptWorkApplication();
  const siteDetectionResult = useSiteDetection();

  const handleDenyWorkApplication = (workApplicationId: string, workerId: string) => {
    openDialog(
      {
        title: 'Weet je zeker dat je deze aanmelding wilt afwijzen?',
        secondaryButton: (props) => (
          <SecondaryButton startIcon={<IconCross />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} iconColorMode="stroke" {...props}>
            Wijs af
          </PrimaryButton>
        ),
      },
      () => {
        disableDialog();
        denyWorkApplication.mutate(
          { workApplicationId, workerId },
          {
            onSuccess: () => {
              alertSuccess(workApplicationsAlerts.success.denied);
              closeDialog();
              queryClient.invalidateQueries(
                SHARED_QUERY_KEYS_PROJECT.workApplicationsForProject(siteDetectionResult)
              );
              queryClient.invalidateQueries(
                SHARED_QUERY_KEYS_PROJECT.projectDetail(siteDetectionResult, projectId)
              );
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleAcceptWorkApplication = (workApplicationId: string, workerId: string) => {
    openDialog(
      {
        title: 'De aanmelding mag geaccepteerd worden?',
      },
      () => {
        disableDialog();
        acceptWorkApplication.mutate(
          {
            workerId: workerId,
            workApplicationId: workApplicationId,
            siteDetectionResult,
          },
          {
            onSuccess: () => {
              alertSuccess(workApplicationsAlerts.success.accepted);
              closeDialog();
              queryClient.invalidateQueries(
                SHARED_QUERY_KEYS_PROJECT.workApplicationsForProject(siteDetectionResult)
              );
              queryClient.invalidateQueries(
                SHARED_QUERY_KEYS_PROJECT.projectDetail(siteDetectionResult, projectId)
              );
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const getApplicantActions = ({ id, workerId }: WorkApplicationTableItem) => [
    <ContextMenuItem
      key="accept"
      onClick={() => {
        handleAcceptWorkApplication(id, workerId);
      }}
    >
      <IconApproved /> Accepteren
    </ContextMenuItem>,
    <ContextMenuItem key="deny" onClick={() => handleDenyWorkApplication(id, workerId)}>
      <IconTrash />
      Afwijzen
    </ContextMenuItem>,
  ];

  return { getApplicantActions };
};
