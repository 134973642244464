import {
  CreatePartnerRelationPayload,
  UpdatePartnerRelationPayload,
} from 'hq/components/views/partner-detail/partner-relations/partner-relations.types';
import { HqApiService } from './hqApiService';
import {
  BranchOfficeCreatePayload,
  BranchOfficeUpdatePayload,
  BranchOfficeWithRelatedCompany,
  GetBranchOfficeResponse,
  GetBranchOfficesResponse,
} from './branch-office-service.types';

export class BranchOfficeService {
  constructor(private readonly hqApiService: HqApiService) {}
  /**
   * Create a new branchOffice
   */
  public createBranchOffice = async (branchOffice: BranchOfficeCreatePayload) => {
    await this.hqApiService.create({
      path: `branch-offices`,
      body: branchOffice,
    });
  };

  /**
   * Update an existing branchOffice
   */
  public updateBranchOffice = async (
    branchOfficeId: string,
    branchOfficeData: BranchOfficeUpdatePayload
  ) => {
    await this.hqApiService.update({
      path: `branch-offices/${branchOfficeId}`,
      body: branchOfficeData,
    });
  };

  /**
   * Get a list of existing branchOffices of a company
   */
  public getBranchOffices = async ({
    includeArchived,
    companyId,
  }: {
    includeArchived?: boolean;
    companyId: string;
  }) => {
    const { branchOffices } = await this.hqApiService.get<GetBranchOfficesResponse>({
      params: { companyId, includeArchived },
      path: 'branch-offices',
    });

    return branchOffices;
  };

  /**
   * Get a list of all existing branchOffices
   */
  public getAllActiveBranchOffices = async () => {
    return await this.hqApiService.get<BranchOfficeWithRelatedCompany[]>({
      path: 'branch-offices/all',
    });
  };

  /**
   * Get a single branchOffice
   */
  public getBranchOffice = async (
    branchOfficeId: string
  ): Promise<GetBranchOfficeResponse['branchOffice']> => {
    const { branchOffice } = await this.hqApiService.get<GetBranchOfficeResponse>({
      path: `branch-offices/${branchOfficeId}`,
    });

    return branchOffice;
  };

  public archiveBranchOffice = async (branchOfficeId: string) =>
    await this.hqApiService.delete<undefined>({
      path: `branch-offices/${branchOfficeId}`,
    });

  public createPartnerRelation = async (payload: CreatePartnerRelationPayload) => {
    await this.hqApiService.create({
      path: `branch-offices/${payload.branchOffices.id}/partner-relations`,
      body: {
        jobTypeIds: payload.jobTypeIds,
        partnerId: payload.partnerId,
        forwardingRulePercentage: payload.forwardingRulePercentage,
      },
    });
  };

  public updatePartnerRelation = async (
    branchOfficeId: string,
    partnerRelationId: string,
    payload: UpdatePartnerRelationPayload
  ) => {
    await this.hqApiService.update({
      path: `branch-offices/${branchOfficeId}/partner-relations/${partnerRelationId}`,
      body: payload,
    });
  };

  public archivePartnerRelation = async (partnerRelationId: string) => {
    await this.hqApiService.delete({
      path: `branch-offices/partner-relations/${partnerRelationId}`,
    });
  };
}
