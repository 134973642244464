import { OgpApiService } from './ogp-api-service';

export type PageStatistics = {
  value: number;
  format: 'number' | 'currency' | 'percentage';
  label: string;
};

type AvailableStatistics =
  | 'branch-offices'
  | 'checkouts'
  | 'checkouts-archive'
  | 'departments'
  | 'flex-pools'
  | 'jobs'
  | 'users';

export class StatisticsService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  getStatistics = async (page: AvailableStatistics) => {
    const data = await this.ogpApiService.get<PageStatistics[]>({
      path: `statistics/${page}`,
    });

    return data;
  };
}
