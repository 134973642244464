import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useState } from 'react';

type Action = {
  label: string;
  action?: () => void;
  loading?: boolean;
  autoFocus?: boolean;
};

type ConfirmProps = {
  title: React.ReactNode;
  body?: React.ReactNode;

  /**
   * Called when the Dialog is closed by the user without an explicit action. E.g. using ESC-key or backdrop click.
   */
  onClose?: () => void;

  /**
   * Like Yes, OK, Accept, etc
   */
  positiveAction?: Action;
  /**
   * Like No, Cancel, Decline, etc
   */
  negativeAction?: Action;
};

/**
 * Provides an easy, opinionated, way of using a MUI Dialog for confirmation.
 * Render the returned Element in your component and use the other provided functions to control the Dialog.
 * When an action is not supplied for the positiveAction or negativeAction the Dialog is closed.
 */
const useConfirm = ({ title, body, onClose, positiveAction, negativeAction }: ConfirmProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  function handleOnClose() {
    close();
    onClose && onClose();
  }

  function handleAction(action: Action['action']) {
    action ? action() : close();
  }

  function renderAction({ label, action, loading, autoFocus }: Action) {
    return (
      <Button onClick={() => handleAction(action)} autoFocus={autoFocus} disabled={loading}>
        {label}
        {loading && <Spinner />}
      </Button>
    );
  }

  const Element = (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {body && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {negativeAction && renderAction(negativeAction)}
        {positiveAction && renderAction(positiveAction)}
      </DialogActions>
    </Dialog>
  );

  return { open, close, Element };
};

function Spinner() {
  return (
    <CircularProgress
      size={30}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-15px',
        marginLeft: '-15px',
      }}
    />
  );
}

export { Spinner, useConfirm };
