import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService } from 'ogp/services';
import { useQuery } from 'react-query';
import { JobListItem } from '@types';

export const useGetJobs = (departmentId?: string, enabled = true) => {
  const queryFn = async () => await jobService.getFilteredJobsForCompany(departmentId);

  return useQuery<Promise<JobListItem[]>, Error, JobListItem[]>(
    QUERY_KEYS_OGP.jobList(departmentId),
    queryFn,
    {
      enabled,
    }
  );
};
