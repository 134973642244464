import { TableHeader } from 'redesign';
import { Column } from 'react-table';
import { Typography } from '@mui/material';
import { formatCurrencyCents } from 'shared/utils/formatting';
import {
  GrossHourlyRateTableCell,
  GrossHourlyRateTableData,
} from '../company-gross-hourly-rate-table.types';

export const getGrossHourlyRateColumnDefinition = () => {
  const jobTypeName: Column<GrossHourlyRateTableData> = {
    Header: () => <TableHeader>Functienaam</TableHeader>,
    accessor: 'jobTypeName',
    Cell: SimpleCell,
  };
  const wagePeriod: Column<GrossHourlyRateTableData> = {
    Header: () => <TableHeader>Loontijdvak</TableHeader>,
    width: 300,
    accessor: 'wagePeriod',
    Cell: WagePeriodCell,
  };
  const grossHourlyRate: Column<GrossHourlyRateTableData> = {
    Header: <TableHeader>Bruto uurloon</TableHeader>,
    width: 150,
    accessor: 'grossHourlyRateCents',
    Cell: GrossHourlyRateCell,
  };

  // TODO: Will be in next sprint
  // https://levelworks.atlassian.net/browse/LW1-16892
  // const actions: Column<GrossHourlyRateTableData> = {
  //   accessor: 'actions',
  //   Header: '',
  //   width: 89,
  //   Cell: ActionCell,
  // };

  return [jobTypeName, wagePeriod, grossHourlyRate];
};

const SimpleCell = (cell: GrossHourlyRateTableCell<'jobTypeName'>) => {
  return (
    <Typography noWrap variant="subtitle2">
      {cell.value}
    </Typography>
  );
};

const WagePeriodCell = (cell: GrossHourlyRateTableCell<'wagePeriod'>) => {
  const dateString = cell.value.endDate
    ? `${cell.value.startDate.toISODate()} - ${cell.value.endDate.toISODate()}`
    : `${cell.value.startDate.toISODate()}`;

  return (
    <Typography noWrap variant="subtitle2">
      {dateString}
    </Typography>
  );
};

const GrossHourlyRateCell = (cell: GrossHourlyRateTableCell<'grossHourlyRateCents'>) => {
  return (
    <Typography noWrap variant="subtitle2">
      {`${formatCurrencyCents(cell.value)} / uur`}
    </Typography>
  );
};

// TODO: Will be in next sprint
// https://levelworks.atlassian.net/browse/LW1-16892
// const ActionCell = (cell: GrossHourlyRateTableCell<'actions'>) => {
//   const { delete: handleDelete, edit, value } = cell;

//   return (
//     <ContextMenuButton menuId={`bank-account-actions-${value.id}`}>
//       <ContextMenuItem key="approve" onClick={() => handleDelete(value.id)}>
//         <IconTrash /> Delete
//       </ContextMenuItem>
//       <ContextMenuItem key="reject" onClick={() => edit(value.id)}>
//         <IconEdit /> Edit
//       </ContextMenuItem>
//     </ContextMenuButton>
//   );
// };
