import { Box, TablePagination } from '@mui/material';
import { Pagination, PaginationResultSizes } from '@types';

type Props = {
  pagination: Pagination;
  pageCount: number;
};

export const PaginationIndicator = ({ pagination, pageCount }: Props) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <TablePagination
        component="div"
        slotProps={{
          actions: {
            previousButton: {
              size: 'small',
            },
            nextButton: {
              size: 'small',
            },
          },
        }}
        count={pageCount}
        page={pagination.page}
        onPageChange={(_, newPage) => {
          pagination.setPage(newPage);
        }}
        rowsPerPage={pagination.size}
        onRowsPerPageChange={(e) => {
          const progress = pagination.size * pagination.page;
          const newSize = parseInt(e.target.value, 10);
          pagination.setSize(newSize);
          pagination.setPage(Math.floor(progress / newSize));
        }}
        rowsPerPageOptions={PaginationResultSizes}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count}`}
        labelRowsPerPage="Resultaten per pagina"
      />
    </Box>
  );
};
