import { makeStyles } from '@mui/styles';

const Unauthorized = () => {
  const styles = getStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <h2>Je hebt onvoldoende rechten om deze pagina te zien.</h2>
        <p>Vraag een administrator om je te helpen.</p>
      </div>
    </div>
  );
};

const getStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    width: '50%',
    maxWidth: '450px',
    textAlign: 'center',
  },
}));

export { Unauthorized };
