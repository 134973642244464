import type { Column } from 'react-table';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { IconCertificate, IconFlexPoolOnlyJob } from 'assets/img';
import { ContextMenuButton, StatusIndicator } from 'redesign';
import { convertToExperienceFormat, getAvailabilityStatusAndColor } from './helpers';
import { getShiftDetailsColumnDefinitionBase } from '../../shift-detail-modal/components/tabs/shared/columns/get-shift-details-column-definition-base';
import {
  FlexWorkerCellProp,
  FlexworkerColumnDefinition,
} from '../flex-workers-for-work-table.types';

export const getFlexworkerColumnDefinition = (
  showAvailabilityColumn?: boolean
): Column<FlexworkerColumnDefinition>[] => {
  const [flexWorkerImage, flexWorker] = getShiftDetailsColumnDefinitionBase();

  flexWorkerImage.width = '65px';
  flexWorker.width = '200px';

  const hasRequiredCertificates: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Certificaat</Header>,
    accessor: 'hasRequiredCertificates',
    Cell: CertificateChecksCell,
    width: '75px',
  };

  const telephoneNumber: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Telefoonnummer</Header>,
    accessor: 'phoneNumber',
    Cell: PhoneCell,
    width: '160px',
  };

  const flexPool: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Flexpool</Header>,
    accessor: 'inFlexPool',
    Cell: FlexpoolCell,
    width: '75px',
  };

  const distance: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Afstand</Header>,
    accessor: 'distance',
    Cell: DistanceCell,
    width: '75px',
  };

  const availability: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Beschikbaarheid</Header>,
    accessor: 'availability',
    Cell: AvailabilityCell,
    width: '150px',
  };

  const experience: Column<FlexworkerColumnDefinition> = {
    Header: () => <Header>Werkervaring</Header>,
    accessor: 'experienceInMonths',
    Cell: ExperienceCell,
    width: '150px',
  };

  const actions: Column<FlexworkerColumnDefinition> = {
    Header: '',
    accessor: 'actions',
    Cell: ActionCell,
    width: '88px',
  };

  /*  
  This is temporary solution to hide availability column in project detail.
  Simon will came up with better solution to refactor this. 
  */

  if (showAvailabilityColumn) {
    return [
      flexWorkerImage,
      flexWorker,
      telephoneNumber,
      hasRequiredCertificates,
      flexPool,
      distance,
      experience,
      actions,
    ];
  }

  return [
    flexWorkerImage,
    flexWorker,
    telephoneNumber,
    hasRequiredCertificates,
    flexPool,
    distance,
    availability,
    experience,
    actions,
  ];
};
const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const CertificateChecksCell = ({ value }: FlexWorkerCellProp<'hasRequiredCertificates'>) => {
  return value ? (
    <Tooltip
      title="Deze flexwerker is in het bezit van alle benodigde certificaten"
      aria-label="certificate-check"
    >
      <Box paddingLeft={1}>
        <IconCertificate data-testid="certificate-check-icon" width={24} />
      </Box>
    </Tooltip>
  ) : null;
};

const PhoneCell = (cell: FlexWorkerCellProp<'phoneNumber'>) => {
  return <Typography noWrap>{cell.value}</Typography>;
};

const FlexpoolCell = (cell: FlexWorkerCellProp<'inFlexPool'>) => {
  return cell.value ? <IconFlexPoolOnlyJob data-testid="in-flex-pool-icon" width={24} /> : null;
};

const DistanceCell = (cell: FlexWorkerCellProp<'distance'>) => {
  return (
    <Typography>
      {cell.value === null ? '-' : cell.value === 0 ? '<1 km' : `${cell.value} km`}
    </Typography>
  );
};

const ExperienceCell = (cell: FlexWorkerCellProp<'distance'>) => {
  return <Typography noWrap>{convertToExperienceFormat(cell.value)}</Typography>;
};

const AvailabilityCell = (cell: FlexWorkerCellProp<'availability'>) => {
  const { availability, color } = getAvailabilityStatusAndColor(cell.value);
  const theme = useTheme();
  return (
    <StatusIndicator
      backgroundColor={color}
      label={availability}
      color={theme.palette.lwBlack[100]}
    />
  );
};

const ActionCell = ({ value, getRowActions }: FlexWorkerCellProp<'actions'>) => {
  const actions = getRowActions(value.flexWorkerId, value.entityId, value.flexWorkerName);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${value.flexWorkerId}`}>
      {actions}
    </ContextMenuButton>
  );
};
