import { useMutation, UseMutationOptions } from 'react-query';
import { shiftService } from 'hq/services';

export const useHqArchiveShift = (shiftId: string = '', options?: UseMutationOptions) => {
  const mutateFn = async () => await shiftService.archiveShift(shiftId);

  return useMutation(mutateFn, {
    ...options,
  });
};
