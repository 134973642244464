import { DateTime } from 'luxon';
import { AutocompleteOption } from 'redesign';
import { getJobTypes } from './utils';
import { ShiftPlanningFilters } from '../../table/hooks/use-shift-planning-page.types';
import {
  PartnerShiftPlanningFiltersFormType,
  ShiftPlanningFiltersData,
} from '../shift-planning-filters.types';

const getShiftPlanningFiltersDefaultValues = (
  appliedFilters: ShiftPlanningFilters,
  filterData: ShiftPlanningFiltersData
): PartnerShiftPlanningFiltersFormType => {
  const isFromDateCheckboxSelected = !!appliedFilters.fromDate;
  const fromDate = appliedFilters.fromDate
    ? appliedFilters.fromDate.toFormat('yyyy-MM-dd')
    : DateTime.now().toFormat('yyyy-MM-dd');
  const businessUnitCheckboxValue = !!appliedFilters.company;
  const result: PartnerShiftPlanningFiltersFormType = {
    fromDateCheckboxValue: isFromDateCheckboxSelected,
    fromDate,
    businessUnitCheckboxValue,
    company: null,
    branchOffice: null,
    department: null,
    filledStatusCheckboxValue: !!appliedFilters.filledStatus,
    jobTypeCheckboxValue: !!appliedFilters.jobType?.length,
    partnersCheckboxValue: false,
    partner: null,
    filledStatus: appliedFilters.filledStatus ? appliedFilters.filledStatus : 'inProgress',
    jobType: null,
  };

  if (appliedFilters.jobType?.length) {
    const jobTypes = getJobTypes(appliedFilters, filterData.jobTypes);
    if (jobTypes?.length) {
      result.jobType = jobTypes.map((i) => ({ id: i.value, name: i.label }));
    }
  }

  if (!appliedFilters.company) {
    return result;
  }
  if (!filterData?.companies) {
    return result;
  }
  const companyFilter = filterData.companies.find((c) => c.companyId === appliedFilters.company);

  if (!companyFilter) {
    return result;
  }

  const company: AutocompleteOption = {
    value: companyFilter.companyId,
    label: companyFilter.companyName,
  };
  result.company = company;

  if (!appliedFilters.branchOffice) {
    return result;
  }

  const branchOfficeFilter = companyFilter.branchOffices.find(
    (bo) => bo.branchOfficeId === appliedFilters.branchOffice
  );

  if (!branchOfficeFilter) {
    return result;
  }

  const branchOffice: AutocompleteOption = {
    value: branchOfficeFilter.branchOfficeId,
    label: branchOfficeFilter.branchOfficeName,
  };
  result.branchOffice = branchOffice;

  if (!appliedFilters.department) {
    return result;
  }

  const departmentFilter = branchOfficeFilter.departments.find(
    (dept) => dept.departmentId === appliedFilters.department
  );

  if (!departmentFilter) {
    return result;
  }

  const department = {
    value: departmentFilter.departmentId,
    label: departmentFilter.departmentName,
  };

  result.department = department;

  return result;
};
export { getShiftPlanningFiltersDefaultValues };
