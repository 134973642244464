import { formatFullName } from 'shared/utils/formatting';
import { PaginatedCollection } from '@types';
import { ArchiveTableColumnDefinition } from './archive-table.types';
import { getShiftDateProperties } from '../../../../shared/utils/get-shift-date-properties';
import { ClaimSettledExtended } from '../../../../shared/types/checkouts.types';

export const getArchiveTableData = (data: PaginatedCollection<ClaimSettledExtended>) => {
  const result: ArchiveTableColumnDefinition[] = data.items.map((item) => {
    const shiftDateProperties = getShiftDateProperties(item);

    return {
      worker: { fullName: formatFullName(item.worker), partnerName: undefined },
      job: {
        name: item.shift.name,
        location: item.shift.location,
      },
      date: {
        scheduledStartDate: shiftDateProperties.workTimes.startDate,
        timeZone: item.shift.timeZone,
      },
      scheduledTime: {
        startDate: item.shift.utcStartDate,
        endDate: item.shift.utcEndDate,
        timeZone: shiftDateProperties.timeZone,
        breakInMinutes: item.shift.breakMinutes,
        state: item.state,
      },
      workedTime: {
        startDate: shiftDateProperties.workTimes.startDate,
        endDate: shiftDateProperties.workTimes.endDate,
        timeZone: shiftDateProperties.timeZone,
        breakInMinutes: shiftDateProperties.workBreakInMinutes,
        state: item.state,
        timeDeviationInMs: shiftDateProperties.timeDeviationInMs,
      },
      scheduledVsWorkedDiffAndBreak: {
        deviationInMs: shiftDateProperties.timeDeviationInMs,
        breakInMinutes: shiftDateProperties.workBreakInMinutes,
      },
      expense: item.ogExpense ?? item.fwExpense,
      status:
        item.state === 'noShow'
          ? {
              state: item.state,
              noShowReason: item.noShowReason,
            }
          : {
              state: item.state,
            },
      actions: item,
    };
  });
  return result;
};
