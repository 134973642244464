import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useQuery } from 'react-query';
import { GetBranchOfficeResponse } from '../../../services/branch-office-service.types';

export const useGetBranchOffice = (branchOfficeId: string = '') => {
  const queryFn = async () => await branchOfficeService.getBranchOffice(branchOfficeId);
  const options = {
    enabled: branchOfficeId.length > 0,
  };

  return useQuery<
    Promise<GetBranchOfficeResponse['branchOffice']>,
    Error,
    GetBranchOfficeResponse['branchOffice']
  >(QUERY_KEYS_OGP.branchOffice(branchOfficeId), queryFn, options);
};
