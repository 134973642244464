import React from 'react';
import { useGetOGPUserInfo } from 'ogp/hooks/queryHooks/users/useGetOGPUserInfo';
import { hasAccessWithinPolicyGroup, PolicyGroupAndRoles } from 'shared/utils/policy-controller';
import { useHqAdmin } from 'shared/hooks';

type AuthenticatedElementProps = PolicyGroupAndRoles & { children: React.ReactElement };

const AuthenticatedElement = ({ roles, policyGroup, children }: AuthenticatedElementProps) => {
  const { data: user } = useGetOGPUserInfo();
  // TODO this was meant to be used only on OGP. Since we have shared components, it should work for HQ as well properly!
  const isHqAdmin = useHqAdmin();

  if (isHqAdmin) {
    return children;
  }

  if (!user) {
    return null;
  }

  if (!hasAccessWithinPolicyGroup(policyGroup, user.userInfo, roles)) {
    return null;
  }

  return children;
};

export { AuthenticatedElement };
