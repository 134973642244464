import { placementService as ogpPlacementService } from 'ogp/services';
import { placementService as hqPlacementService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { SiteDetectionResult, useSiteDetection } from '../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../query-keys/project';

type WorkApplicationMutationParams = {
  placementId: string;
  siteDetectionResult: SiteDetectionResult;
};

export const usePlacementCancel = () => {
  const mutationFn = (params: WorkApplicationMutationParams) => getMutationFunction(params);
  const queryClient = useQueryClient();
  const siteDetectionResult = useSiteDetection();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(SHARED_QUERY_KEYS_PROJECT.projects(siteDetectionResult));
    },
  });
};

const getMutationFunction = (params: WorkApplicationMutationParams) => {
  const { siteDetectionResult, placementId } = params;
  if (siteDetectionResult.isHQ) {
    return hqPlacementService.cancelPlacement(placementId);
  }
  if (siteDetectionResult.isOGP) {
    return ogpPlacementService.cancelPlacement(placementId);
  }

  throw new Error(`usePlacementCancel: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
