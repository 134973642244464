import { shiftApplicationService } from 'hq/services';
import { useMutation } from 'react-query';

interface ShiftApplicationMutationParams {
  shiftApplicationId: string;
  flexWorkerId: string;
}

export const useHqDenyShiftApplication = () => {
  const mutationFn = async (params: ShiftApplicationMutationParams) =>
    await shiftApplicationService.denyShiftApplication(
      params.shiftApplicationId,
      params.flexWorkerId
    );

  return useMutation(mutationFn);
};
