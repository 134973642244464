import { Page } from 'redesign';
import { useToggle } from 'shared/hooks';
import { routes } from 'ogp/Routes';
import { HeaderStatistics } from 'ogp/components/common/header-statistics';
import { RenderUnsettledUI } from 'shared/components';
import { PaginationIndicator } from 'ogp/components/common/pagination-indicator/pagination-indicator';
import { useGetCheckoutInboxFilters } from 'ogp/hooks/queryHooks/shiftclaims/useGetCheckoutInboxFilters';
import { Box, Divider, Drawer } from '@mui/material';
import { InboxTableWrapper } from './components/table/inbox-table-wrapper';
import { useLevelPagination } from '../../../../../hooks/queryHooks/misc/use-level-pagination';
import { useGetShiftClaims } from '../hooks/use-get-shift-claims';
import { InboxFilterForm, useInboxFilterFormState, useInboxSorting } from '../../shared';

const DividerElement = (
  <Divider light style={{ backgroundColor: 'rgba(0, 26, 153, 0.2)', marginBottom: '32px' }} />
);

const CheckoutsInbox = () => {
  const { on: drawerOpen, toggle: toggleDrawer } = useToggle();
  const [sortState, handleSortClick] = useInboxSorting(
    routes.CheckoutsInbox,
    routes.CheckoutsInbox.queryParams
  );
  const inboxFilterFormState = useInboxFilterFormState();
  const checkoutsInboxFiltersQuery = useGetCheckoutInboxFilters();
  const pagination = useLevelPagination();
  const claimsQuery = useGetShiftClaims({
    page: pagination.page,
    size: pagination.size,
    ...inboxFilterFormState.activeFilters,
    ...sortState,
  });

  if (claimsQuery.status !== 'success' || checkoutsInboxFiltersQuery.status !== 'success') {
    return <RenderUnsettledUI data={claimsQuery} />;
  }

  return (
    <>
      <Page
        header={
          <HeaderStatistics
            type="checkouts"
            titleText="Checkouts"
            subheaderText="Hier zie je alle inkomende checkouts"
          />
        }
        toolbox={DividerElement}
      >
        <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
          <InboxTableWrapper
            data={claimsQuery.data}
            sortState={sortState}
            handleSortClick={handleSortClick}
            toggleDrawer={toggleDrawer}
            nrOfActiveFilters={inboxFilterFormState.nrOfActiveFilters}
          />
          <Box mt="auto">
            <PaginationIndicator pageCount={claimsQuery.data.totalCount} pagination={pagination} />
          </Box>
        </Box>
      </Page>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <InboxFilterForm
          onCancel={toggleDrawer}
          data={checkoutsInboxFiltersQuery.data}
          {...inboxFilterFormState}
        />
      </Drawer>
    </>
  );
};

export { CheckoutsInbox, DividerElement };
