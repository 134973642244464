import { Box, Grid } from '@mui/material';
import { useGetCompaniesAsSelect } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { useForm } from 'react-hook-form';
import { StateError, StateLoading } from 'shared/components/common/state/State';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useGetBranchOffices } from 'hq/hooks';
import { useGetJobs } from 'hq/hooks/queryHooks/jobs/use-get-jobs';
import { DateTime } from 'luxon';
import {
  FormCheckboxGroup,
  LwButton,
  LwFormDate,
  LwFormMultiSelect,
  LwFormSelect,
  LwFormTime,
  Toolbox,
} from 'redesign';
import { ShiftClaimState } from 'shared/utils/shiftClaimState.types';
import React from 'react';
import { useGetShiftClaimExport } from './hooks/use-get-shift-claim-export';

type ExportFilters = {
  fromDate: string;
  toDate: string;
  companyId: string;
  branchOfficeId: string;
  jobs: { id: string; name: string }[];
  checkoutAtToDate: string;
  checkoutAtToTime: string;
  checkoutAtFromDate: string;
  checkoutAtFromTime: string;
  state: ShiftClaimState[];
};

const Checkouts = ({
  areCheckoutsVisible,
  setAreCheckoutsVisible,
}: {
  areCheckoutsVisible: boolean;
  setAreCheckoutsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    reset: clearForm,
  } = useForm<ExportFilters>({
    defaultValues: {
      fromDate: DateTime.now().minus({ days: 7 }).toISODate(),
      toDate: DateTime.now().toISODate(),
      state: ['checkoutPending', 'counterOffer', 'checkoutSettled', 'noShow'],
    },
  });

  const companyIdWatch = watch('companyId');
  const companiesQuery = useGetCompaniesAsSelect(undefined, undefined);
  const branchOfficeIdWatch = watch('branchOfficeId');
  const branchOfficesQuery = useGetBranchOffices({
    companyId: companyIdWatch,
    includeArchived: true,
  });
  const jobsQuery = useGetJobs(branchOfficeIdWatch);

  const queries = [companiesQuery, branchOfficesQuery, jobsQuery];

  const today = new Date().toISOString().split('T')[0];
  const mutation = useGetShiftClaimExport({
    onSuccess: () => alertSuccess(shiftAlerts.success.downloadExport),
    onError: (e) => alertError(e),
  });

  if (queries.some((query) => query.isLoading)) {
    return <StateLoading />;
  }
  if (queries.some((query) => query.isError)) {
    return <StateError error={queries.find((query) => query.isError)?.error ?? null} />;
  }

  function onSubmit(f: ExportFilters) {
    mutation.mutate({
      fromDate: f.fromDate,
      toDate: f.toDate,
      companyId: f.companyId || undefined,
      branchOfficeId: f.branchOfficeId || undefined,
      jobIds: f.jobs.length > 0 ? f.jobs.map((i) => i.id) : undefined,
      checkoutAtFromDate:
        f.checkoutAtFromDate && `${f.checkoutAtFromDate}T${f.checkoutAtFromTime || '00:00'}`,
      checkoutAtToDate:
        f.checkoutAtToDate && `${f.checkoutAtToDate}T${f.checkoutAtToTime || '23:59'}`,
      state: f.state,
    });
  }

  const submitDisabled = !watch('fromDate') || !watch('toDate') || watch('state').length < 1;

  const getOptionsFromQueryData = (data: { id: string; name: string }[]) =>
    data.map((el) => ({
      value: el.id,
      label: el.name,
    }));

  const companyOptions = companiesQuery.data ?? [];
  const branchOfficesOptions =
    branchOfficesQuery.isSuccess && getOptionsFromQueryData(branchOfficesQuery.data);

  const jobValues: ExportFilters['jobs'] =
    jobsQuery.data?.map((job) => ({
      id: job.id,
      name: `${job.companyName} - ${job.departmentName} - ${job.name}`,
    })) ?? [];

  const stateOptions: Array<{ label: string; value: ShiftClaimState }> = [
    { label: 'Uren ingevoerd', value: 'checkoutPending' },
    { label: 'Tegenvoorstel', value: 'counterOffer' },
    { label: 'Geaccordeerd', value: 'checkoutSettled' },
    { label: 'No show', value: 'noShow' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Toolbox>
        <LwButton color="primary" type="submit" disabled={submitDisabled}>
          Download
        </LwButton>
        <LwButton color="secondary" onClick={() => clearForm()}>
          Wissen
        </LwButton>

        <Toolbox.PushRight>
          <LwButton onClick={() => setAreCheckoutsVisible((prev) => !prev)}>
            Show {areCheckoutsVisible ? 'Verzamelfactuur' : 'Checkouts'}
          </LwButton>
        </Toolbox.PushRight>
      </Toolbox>
      <Box width="50%">
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <LwFormDate
                name="fromDate"
                label="Shift vanaf"
                control={control}
                maxDate={watch('toDate') || today}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormDate
                name="toDate"
                label="Shift tot"
                control={control}
                minDate={watch('fromDate')}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LwFormSelect
              name="companyId"
              label="Bedrijf"
              defaultLabel="Alle bedrijven"
              options={companyOptions || []}
              control={control}
              onChange={(e) => {
                setValue('companyId', e.target.value as string);
                setValue('branchOfficeId', '');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LwFormSelect
              name="branchOfficeId"
              label="Vestigingen"
              defaultLabel="Alle vestigingen"
              options={branchOfficesOptions || []}
              disabled={!watch('companyId')}
              control={control}
              onChange={(e) => {
                setValue('branchOfficeId', e.target.value as string);
                setValue('jobs', []);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LwFormMultiSelect
              name="jobs"
              control={control}
              options={jobValues}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                setValue('jobs', value);
              }}
              disabled={!watch('branchOfficeId')}
              label="Functies"
            />
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <LwFormDate
                name="checkoutAtFromDate"
                label="Moment van checkout vanaf"
                control={control}
                maxDate={watch('checkoutAtToDate') || today}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormTime
                name="checkoutAtFromTime"
                label="Tijd"
                control={control}
                disabled={!watch('checkoutAtFromDate')}
                max={
                  watch('checkoutAtToDate') === watch('checkoutAtFromDate')
                    ? watch('checkoutAtToTime')
                    : undefined
                }
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <LwFormDate
                name="checkoutAtToDate"
                label="Moment van checkout tot"
                control={control}
                minDate={watch('checkoutAtFromDate')}
              />
            </Grid>
            <Grid item xs={6}>
              <LwFormTime
                name="checkoutAtToTime"
                label="Tijd"
                control={control}
                disabled={!watch('checkoutAtToDate')}
                min={
                  watch('checkoutAtToDate') === watch('checkoutAtFromDate')
                    ? watch('checkoutAtFromTime')
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <FormCheckboxGroup
            display="grid"
            name="state"
            control={control}
            items={stateOptions}
            label="Status"
          />
        </Box>
      </Box>
    </form>
  );
};

export { Checkouts };
