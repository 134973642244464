import { IconCancel, IconCoin, IconDuplicate, IconEdit, IconTrash } from 'assets/img';
import { DeleteButton, PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { ContextMenuItem } from 'redesign';
import { useAlert } from 'shared/components/alerts';

import { useHqArchiveShift } from 'shared/components/shift-detail-modal/query-hooks/hq/use-hq-archive-shift';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useShiftPlanningPage } from '../table/hooks/use-shift-planning';
import { stringifyFilters } from '../../../../../../shared/utils/stringify-filters';

type Props = {
  data?: ShiftDetailResponse;
  closeModal: () => void;
  onDelete: () => void;
};

const useGetShiftDetailHeadActions = ({ data, closeModal, onDelete }: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, disableDialog, enableDialog } = useDialog();
  const navigate = useNavigate();
  const { filters: appliedFilters } = useShiftPlanningPage();
  const generatePath = useHqRelativePath();
  const { mutate, isLoading: isArchiving } = useHqArchiveShift(data?.id, {
    onSuccess: () => {
      onDelete();
      alertSuccess('De shift is succesvol verwijderd');
      closeDialog();
      closeModal();
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });
  const handleDelete = () => {
    openDialog(
      {
        title: 'Shift verwijderen',
        content:
          'Weet je zeker dat je de shift wilt verwijderen? Dit kan alleen als er geen geaccepteerde shiftclaims zijn.',
        secondaryButton: (props) => (
          <PrimaryButton startIcon={<IconCancel />} {...props} iconColorMode="fill">
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => <DeleteButton {...props} />,
      },
      () => {
        disableDialog();
        mutate();
      }
    );
  };

  const handleEdit = () => {
    navigate(
      generatePath(
        hqRoutes.PlannerShiftEditShift,
        { id: data?.id },
        stringifyFilters(appliedFilters)
      )
    );
  };

  const handleDuplicate = () => {
    navigate(
      generatePath(
        hqRoutes.PlannerShiftDuplicateShift,
        { id: data?.id },
        stringifyFilters(appliedFilters)
      )
    );
  };

  const handleMultiply = () => {
    navigate(
      generatePath(
        hqRoutes.PlannerShiftMultiplyShift,
        { id: data?.id },
        stringifyFilters(appliedFilters)
      )
    );
  };

  const children = [
    <ContextMenuItem key="edit" onClick={handleEdit}>
      <IconEdit />
      Wijzig
    </ContextMenuItem>,
    <ContextMenuItem key="duplicate" onClick={handleDuplicate}>
      <IconDuplicate />
      Dupliceer
    </ContextMenuItem>,
    <ContextMenuItem key="multiply" onClick={handleMultiply}>
      <IconCoin />
      Meerdere shifts
    </ContextMenuItem>,
    <ContextMenuItem key="delete" onClick={handleDelete}>
      <IconTrash />
      Verwijderen
    </ContextMenuItem>,
  ];

  return { children, isArchiving };
};

export { useGetShiftDetailHeadActions };
