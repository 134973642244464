import { Box, CircularProgress, Grid, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatDateTime, formatTime } from 'shared/utils/formatting';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import {
  ShiftClaimUpdateData,
  useUpdateCounterOffer,
} from 'ogp/hooks/queryHooks/shiftclaims/useUpdateCounterOffer';
import { getMinDate } from 'shared/utils/get-min-date';
import { LwButton, LwFormDate, LwFormNumberInput, LwFormSelect, LwFormTime } from 'redesign';
import { DateTime } from 'luxon';
import {
  expensesConfigById,
  getExpenseFormValue,
  getExpenseValueFromForm,
  mapExpenseTypeToDescription,
} from 'shared/utils/expenses';
import { Expense } from '@types';
import { getShiftDateProperties } from '../shared/utils/get-shift-date-properties';
import { ClaimPendingExtended, ClaimSettledExtended } from '../shared/types/checkouts.types';
import { useGetBreakOptions } from '../../shared';

type ShiftClaimUpdateFormData = Omit<ShiftClaimUpdateData, 'breakMinutes' | 'expenses'> & {
  id: string;
  startTime?: string;
  endTime?: string;
  breakMinutes?: number;
  expenses: Array<Omit<Expense, 'amount'> & { amount: string }> | undefined;
};

const FORM_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm";

export const useCounterOfferDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [claimInfo, setClaimInfo] = useState<ClaimSettledExtended | ClaimPendingExtended>();
  const [ready, setReady] = useState<boolean>(false); // Values persist between different dialogs due to `reset` not being called properly. To be investigated more
  const { alertSuccess, alertError } = useAlert();
  const styles = getStyles();
  const breakOptionsQuery = useGetBreakOptions({ enabled: isOpen });
  const [equalToFwCheckout, setEqualToFwCheckout] = useState(true);
  const { control, handleSubmit, reset, watch } = useForm<ShiftClaimUpdateFormData>();
  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const updateShiftClaim = useUpdateCounterOffer({
    onSuccess: () => {
      close();
      alertSuccess(shiftAlerts.success.updateCheckout);
    },
    onError: (e) => {
      close();
      alertError(e);
    },
  });

  if (breakOptionsQuery.status === 'success' && claimInfo && !ready) {
    const { workTimes, workBreakInMinutes } = getShiftDateProperties(claimInfo);

    const expenses = (claimInfo.ogExpense ?? claimInfo.fwExpense)?.map((exp) => ({
      expenseType: exp.expenseType,
      amount: getExpenseFormValue(exp),
      unit: exp.unit,
      description: mapExpenseTypeToDescription(exp.expenseType),
    }));

    reset({
      startTime: formatTime(workTimes.startDate),
      startDate: DateTime.fromJSDate(workTimes.startDate).toISODate(),
      endTime: formatTime(workTimes.endDate),
      endDate: DateTime.fromJSDate(workTimes.endDate).toISODate(),
      breakMinutes: breakOptionsQuery.data.find((o) => o.value === workBreakInMinutes)
        ? workBreakInMinutes
        : undefined,
      expenses,
    });
    setReady(true);
  }

  useEffect(() => {
    if (breakOptionsQuery.status === 'success' && claimInfo) {
      const subscription = watch((value) => {
        const { startDate, startTime, endDate, endTime, breakMinutes, expenses } = value;
        const { fwStartDate, fwEndDate, fwBreakMinutes, fwExpense, ogExpense } = claimInfo;

        const ogStartDate = new Date(`${startDate}T${startTime}`);
        const ogEndDate = new Date(`${endDate}T${endTime}`);

        const existingExpenses = ogExpense ?? fwExpense;
        const areExpensesSame = !!existingExpenses?.every(
          (item, index) => getExpenseFormValue(item) === expenses?.[index]?.amount
        );

        setEqualToFwCheckout(
          fwStartDate?.getTime() === ogStartDate.getTime() &&
            fwEndDate?.getTime() === ogEndDate.getTime() &&
            fwBreakMinutes === breakMinutes &&
            areExpensesSame
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [breakOptionsQuery, claimInfo, watch]);

  const open = useCallback((claim: ClaimPendingExtended | ClaimSettledExtended) => {
    setClaimInfo(claim);
    setIsOpen(true);
    setReady(false);
  }, []);

  const close = useCallback(() => setIsOpen(false), []);

  function onSubmit(formData: ShiftClaimUpdateFormData) {
    if (formData.breakMinutes === undefined) {
      return;
    }

    const startDate = DateTime.fromFormat(
      `${formData.startDate}T${formData.startTime}`,
      FORM_DATE_TIME_FORMAT
    ).toISO();
    const endDate = DateTime.fromFormat(
      `${formData.endDate}T${formData.endTime}`,
      FORM_DATE_TIME_FORMAT
    ).toISO();

    const expenses = formData.expenses?.map((expense) => ({
      expenseType: expense.expenseType,
      description: mapExpenseTypeToDescription(expense.expenseType),
      amount: getExpenseValueFromForm(expense),
      unit: expense.unit,
    }));

    updateShiftClaim.mutate({
      shiftClaimId: claimInfo?.id ?? '',
      startDate,
      endDate,
      breakMinutes: formData.breakMinutes,
      expenses,
    });
  }

  const changedTimeInfo = claimInfo && getShiftDateProperties(claimInfo);

  function renderBreakOptionsOrStateIndicator() {
    switch (breakOptionsQuery.status) {
      case 'success':
        return (
          <LwFormSelect
            name="breakMinutes"
            label="Pauze"
            defaultLabel="Selecteer de lengte van de pauze..."
            options={breakOptionsQuery.data}
            control={control}
          />
        );
      case 'error':
        return (
          <Typography>
            {`Er ging iets mis bij het ophalen van de pauze opties: ${breakOptionsQuery.error.name}, ${breakOptionsQuery.error.message}`}
          </Typography>
        );
      case 'loading':
        return <CircularProgress size={24} />;
    }
  }

  function renderExpenses() {
    const expenses = claimInfo?.fwExpense ?? claimInfo?.ogExpense;

    return (
      expenses?.map((exp, i) => {
        const expenseConfig = expensesConfigById[exp.expenseType];
        return (
          <Grid key={exp.expenseType} item xs={12}>
            <LwFormNumberInput
              name={`expenses.${i}.amount`}
              label={expenseConfig.formLabel}
              control={control}
              min={0}
            />
          </Grid>
        );
      }) ?? null
    );
  }

  // todo: somehow memoize this element?
  const Element = claimInfo && changedTimeInfo && (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Tegenvoorstel</DialogTitle>
      <DialogContent>
        {/* Overview of the current state of the checkout */}
        <DialogContentText>
          Een tegenvoorstel doen betekent dat de flexwerker de mogelijkheid krijgt om het
          tegenvoorstel te accepteren in de app.
        </DialogContentText>
        <Grid container item spacing={3} xs={12} classes={{ root: styles.timeRowsContainer }}>
          <Grid item xs={12}>
            <DialogContentText>Geplaatste shift details</DialogContentText>
          </Grid>
          <Grid item xs={9} classes={{ root: styles.timeRow }}>
            <DialogContentText classes={{ root: styles.timeCell }}>
              {`${formatDateTime(claimInfo.shift.utcStartDate)} - ${formatDateTime(
                claimInfo.shift.utcEndDate
              )}`}
            </DialogContentText>
            <DialogContentText classes={{ root: styles.timeCell }}>
              {claimInfo.shift.breakMinutes !== 0 ? `${claimInfo.shift.breakMinutes} min` : 'Geen'}
            </DialogContentText>
          </Grid>
          {claimInfo.state === 'counterOffer' ? (
            <>
              <Grid item xs={12}>
                <DialogContentText>Flexwerker checkout</DialogContentText>
              </Grid>
              <Grid item xs={9} classes={{ root: styles.timeRow }}>
                <DialogContentText className={styles.timeCell}>
                  {`${formatDateTime(claimInfo.fwStartDate)} - ${formatDateTime(
                    claimInfo.fwEndDate
                  )}`}
                </DialogContentText>
                <DialogContentText className={styles.timeCell}>
                  {claimInfo.fwBreakMinutes !== 0 ? `${claimInfo.fwBreakMinutes} min` : 'Geen'}
                </DialogContentText>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <DialogContentText>
              {claimInfo.state === 'checkoutPending'
                ? 'Ontvangen uren van Flexwerker'
                : claimInfo.state === 'counterOffer'
                  ? 'Het verstuurde tegenvoorstel'
                  : undefined}
            </DialogContentText>
          </Grid>
          <Grid item xs={9} classes={{ root: styles.timeRow }}>
            <DialogContentText className={styles.timeCell}>
              {`${formatDateTime(changedTimeInfo.workTimes.startDate)} - ${formatDateTime(
                changedTimeInfo.workTimes.endDate
              )}`}
            </DialogContentText>
            <DialogContentText className={styles.timeCell}>
              {changedTimeInfo.workBreakInMinutes !== 0
                ? `${changedTimeInfo.workBreakInMinutes} min`
                : 'Geen'}
            </DialogContentText>
          </Grid>
        </Grid>

        {/* The actual form */}
        <DialogContentText classes={{ root: styles.timeCell }}>Uren aanpassen</DialogContentText>
        <Grid style={{ alignItems: 'end' }} container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <LwFormDate
              name="startDate"
              label="Van"
              control={control}
              minDate={minDate}
              maxDate={watch('endDate')}
            />
          </Grid>
          <Grid item xs={6}>
            <LwFormTime
              name="startTime"
              label=""
              control={control}
              max={watch('startDate') === watch('endDate') ? watch('endTime') : undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <LwFormDate name="endDate" label="Tot" control={control} minDate={watch('startDate')} />
          </Grid>
          <Grid item xs={6}>
            <LwFormTime
              name="endTime"
              label=""
              control={control}
              min={watch('startDate') === watch('endDate') ? watch('startTime') : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            {renderBreakOptionsOrStateIndicator()}
          </Grid>
          {renderExpenses()}
        </Grid>
      </DialogContent>

      <Box
        component={DialogActions}
        mt={8}
        style={{ gap: '32px', justifyContent: 'center', paddingTop: 0 }}
      >
        <LwButton onClick={close} autoFocus color="secondary">
          Annuleer
        </LwButton>
        <Tooltip
          title="Je kan alleen een tegenvoorstel doen als de tijden verschillen van de flexwerker checkout"
          disableHoverListener={!equalToFwCheckout}
          disableFocusListener={!equalToFwCheckout}
        >
          <span>
            <LwButton
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={
                updateShiftClaim.isLoading ||
                watch('breakMinutes') === undefined ||
                equalToFwCheckout
              }
            >
              {updateShiftClaim.isLoading ? <CircularProgress size={24} /> : 'Bevestig'}
            </LwButton>
          </span>
        </Tooltip>
      </Box>
    </Dialog>
  );

  return { open, close, Element };
};

const getStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontWeight: 'bold',
    color: 'black',
    paddingTop: '20px',
  },
  timeRowsContainer: {
    marginTop: theme.spacing(4),
  },
  timeRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '0 !important', // !important is needed to override mui grid item style
  },
  timeCell: {
    color: 'black',
    fontWeight: 'unset',
    padding: theme.spacing(1, 0),
  },
  changedField: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.spacing(4),
    display: 'inline-block',
  },
  changedFieldByOG: {
    backgroundColor: theme.palette.primary.main,
  },
}));
