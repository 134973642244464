import { flexWorkerService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useGetFlexWorker = (flexWorkerId: string = '') => {
  const queryFn = async () => await flexWorkerService.getFlexWorker(flexWorkerId);

  const options = {
    enabled: flexWorkerId.length > 0,
  };

  return useQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId), queryFn, options);
};
