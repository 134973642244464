import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { BranchOfficeFormData } from '@types';
import { BranchOfficeCreateDataProvider } from './branch-office-create';
import { useAlert } from '../../../../../../../shared/components/alerts';
import { useCompanyRelativePath } from '../../../../../../hooks';
import { useCreateBranchOffice } from '../../../../../../hooks/queryHooks/branchoffices/use-create-branch-office';
import { paths } from '../../../../../../paths';
import { routes } from '../../../../../../Routes';
import { BranchOfficeForm } from '../../form/branch-office-form';

type Props = {
  defaultValues: Partial<BranchOfficeFormData>;
  successMessage: string;
  pageTitle: string;
};

export const BranchOfficeCreatePage = ({ defaultValues, successMessage, pageTitle }: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const formMethods = useForm<BranchOfficeFormData>({
    defaultValues,
  });
  const mutation = useCreateBranchOffice();
  const handleSubmit = () => {
    formMethods.handleSubmit((data) => {
      mutation.mutate(data, {
        onSuccess: ({ id }) => {
          alertSuccess(successMessage);
          navigate(generatePath(routes.BranchOfficesDetail, { id }));
        },
        onError: (e) => alertError(e),
      });
    })();
  };
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={returnTo(paths.BranchOffices)}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <BranchOfficeForm state="create" />
      </Page>
    </FormProvider>
  );
};
export { BranchOfficeCreateDataProvider };
