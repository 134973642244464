import React from 'react';
import { hasAccessWithinPolicyGroup, PolicyGroupAndRoles } from 'shared/utils/policy-controller';
import { HqUserInfo, OgpUserInfo } from '@types';
import { hqPrefix } from 'Routes';
import { useAppSettingsProvider } from 'shared/contexts/AppSettingsContext';
import { accessAlerts as alerts, useAlert } from 'shared/components/alerts';
import { generatePath, useNavigate } from 'react-router-dom';
import { useHqAdmin } from 'shared/hooks';
import { GetPartnerUserInfoResponse } from 'partner/shared/services/partner-user-service.types';
import { useGetOGPUserInfo } from 'ogp/hooks';
import { partnerRoutePrefix } from 'partner/settings';
import { routePrefix as ogpPrefix } from '../../ogp/settings';

type AuthenticatedRouteV2Props = React.PropsWithChildren<
  PolicyGroupAndRoles & {
    userInfo: OgpUserInfo | HqUserInfo | GetPartnerUserInfoResponse;
    fallbackPath?: string;
  }
>;

type AuthenticatedOgpGuardProps = React.PropsWithChildren<
  PolicyGroupAndRoles & {
    userInfo: OgpUserInfo;
    fallbackPath?: string;
  }
>;

type AuthenticatedHqGuardProps = React.PropsWithChildren<
  PolicyGroupAndRoles & {
    userInfo: HqUserInfo;
    fallbackPath?: string;
  }
>;

type AuthenticatedPartnerGuardProps = React.PropsWithChildren<
  PolicyGroupAndRoles & {
    userInfo: GetPartnerUserInfoResponse;
    fallbackPath?: string;
  }
>;

const AuthenticatedGuard = (props: AuthenticatedRouteV2Props) => {
  const { domain } = useAppSettingsProvider();

  if (domain === 'hq') {
    return <AuthenticatedHqGuard {...(props as AuthenticatedHqGuardProps)} />;
  }

  if (domain === 'partner') {
    return <AuthenticatedPartnerGuard {...(props as AuthenticatedPartnerGuardProps)} />;
  }

  return <AuthenticatedOgpGuard {...(props as AuthenticatedOgpGuardProps)} />;
};

const AuthenticatedHqGuard = ({
  children,
  userInfo,
  policyGroup,
  roles,
  fallbackPath,
}: AuthenticatedRouteV2Props & { userInfo: HqUserInfo }) => {
  const navigate = useNavigate();
  const { alertError } = useAlert();
  const redirectPath = `${hqPrefix}/${fallbackPath}`;

  React.useEffect(() => {
    if (!hasAccessWithinPolicyGroup(policyGroup, userInfo, roles)) {
      alertError(new Error(alerts.error.illegal));
      navigate(redirectPath);
    }
  }, [alertError, policyGroup, redirectPath, roles, userInfo, navigate]);

  return <>{children}</>;
};

const AuthenticatedOgpGuard = ({
  children,
  userInfo,
  policyGroup,
  roles,
  fallbackPath,
}: AuthenticatedRouteV2Props & { userInfo: OgpUserInfo }) => {
  const userQuery = useGetOGPUserInfo();
  const navigate = useNavigate();
  const { alertError } = useAlert();
  const isHqAdmin = useHqAdmin();
  const redirectPath = `${ogpPrefix}/:companyHash/${fallbackPath}`;

  React.useEffect(() => {
    if (isHqAdmin) {
      return;
    }
    if (!hasAccessWithinPolicyGroup(policyGroup, userInfo, roles)) {
      alertError(new Error(alerts.error.illegal));
      navigate(generatePath(redirectPath, { companyHash: userQuery.data?.userInfo.company.slug }));
    }
  }, [
    isHqAdmin,
    alertError,
    policyGroup,
    redirectPath,
    roles,
    userInfo,
    navigate,
    userQuery.data?.userInfo.company.slug,
  ]);

  return <>{children}</>;
};

const AuthenticatedPartnerGuard = ({
  children,
  userInfo,
  policyGroup,
  roles,
  fallbackPath,
}: AuthenticatedPartnerGuardProps) => {
  const navigate = useNavigate();
  const { alertError } = useAlert();
  const redirectPath = `${partnerRoutePrefix}/${fallbackPath}`;

  React.useEffect(() => {
    if (!hasAccessWithinPolicyGroup(policyGroup, userInfo, roles)) {
      alertError(new Error(alerts.error.illegal));
      navigate(redirectPath);
    }
  }, [alertError, policyGroup, redirectPath, roles, userInfo, navigate]);

  return <>{children}</>;
};

export { AuthenticatedGuard };
