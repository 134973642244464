import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { AccreditationsList } from './accreditations-list';
import { AddAccreditationForm } from './add-accreditation-form';
import { AccreditationInputCertificate } from './accreditations-input.types';
import { invariant } from '../../../shared/utils/utils';

export const AccreditationInput = ({
  certificates,
  name,
  required,
}: {
  certificates: AccreditationInputCertificate[];
  name: string;
  required: boolean;
}) => {
  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <AccreditationInputControlled
            field={field}
            certificates={certificates}
            name={name}
            required={required}
          />
        );
      }}
    />
  );
};

const AccreditationInputControlled = ({
  field,
  certificates,
  required,
}: {
  field: ControllerRenderProps<FieldValues, string>;
  certificates: AccreditationInputCertificate[];
  name: string;
  required: boolean;
}) => {
  const accreditations = useMemo(() => {
    return field.value || [];
  }, [field.value]) as AccreditationFormRepresentation[];

  const setAccreditations = (
    val: (prev: AccreditationFormRepresentation[]) => AccreditationFormRepresentation[]
  ) => {
    field.onChange(val(accreditations));
  };

  const accreditationPresentation = useMemo(() => {
    return accreditations.map((accreditation) => {
      const certificate = certificates.find((cert) => cert.id === accreditation.id);
      invariant(certificate, 'Certificate not found');
      return {
        ...accreditation,
        certificateName: certificate.name,
      };
    });
  }, [accreditations, certificates]);

  const availableCertificates = useMemo(() => {
    return certificates.filter((cert) => !accreditations.find((acc) => acc.id === cert.id));
  }, [certificates, accreditations]);

  return (
    <Box paddingBottom={2}>
      <AddAccreditationForm
        certificates={availableCertificates}
        setAccreditations={setAccreditations}
        required={required}
      />
      <AccreditationsList
        accreditations={accreditationPresentation}
        setAccreditations={setAccreditations}
      />
    </Box>
  );
};
