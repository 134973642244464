import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { branchOfficeService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { PartnerRelationFormData } from '../partner-relations/partner-relations.types';

type PartnerUserMutationOptions = UseMutationOptions<unknown, unknown, PartnerRelationFormData>;

export const useEditPartnerRelation = (
  partnerId: string,
  partnerRelationId: string,
  options?: PartnerUserMutationOptions
) => {
  const mutationFn = async (partnerRelation: PartnerRelationFormData) =>
    await branchOfficeService.updatePartnerRelation(
      partnerRelation.branchOffices.id,
      partnerRelationId,
      {
        jobTypeIds: partnerRelation.jobTypes.map((jobType) => jobType.id),
        forwardingRulePercentage: partnerRelation.forwardingRulePercentage
          ? Number(partnerRelation.forwardingRulePercentage)
          : null,
      }
    );
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: PartnerUserMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.partnerRelations(partnerId));
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
