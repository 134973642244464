import { Box, Typography } from '@mui/material';
import { formatDuration } from 'shared/utils/formatting';
import { ScheduledTimesCell } from '../../../../shared/columns/get-checkouts-column-definition-base';
import { CheckoutsCellProp } from '../../../../shared/columns/get-checkouts-column-definition-base.types';

const WorkedTimeCell = (cell: CheckoutsCellProp<'workedTime'>) => {
  const { state } = cell.value;

  if (state === 'noShow') {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2" color="secondary">
          No show
        </Typography>
        <Typography variant="body2" color="secondary">
          {formatDuration({ milliseconds: 0 })}
        </Typography>
      </Box>
    );
  }

  if (state === 'cancelationOfferSettled') {
    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">Annuleringsaanbod</Typography>
        <Typography variant="body2">{formatDuration({ milliseconds: 0 })}</Typography>
      </Box>
    );
  }

  return <ScheduledTimesCell {...cell} />;
};

export { WorkedTimeCell };
