import { DateTime } from 'luxon';
import { GetPartnerPlacementsResponse } from '../../../../shared/services/partner-placements-service.types';
import { PartnerPlacementsColumnDefinition } from '../partner-placements.types';

export const getPartnerPlacementsTableData = (
  data: GetPartnerPlacementsResponse['items']
): PartnerPlacementsColumnDefinition[] => {
  return data.map((placement) => {
    const dateTime = {
      fromDateTime: DateTime.fromISO(placement.dateTime.fromDateTime),
      toDateTime: DateTime.fromISO(placement.dateTime.toDateTime),
    };

    return {
      ...placement,
      dateTime,
    };
  });
};
