import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useSiteDetection } from 'shared/hooks/use-site-detection';
import { pools } from './config';
import { configureAmplify } from './utils';

type HubListenerFn = Parameters<typeof Hub.listen>['1'];

const CheckAuthentication = ({
  children,
}: {
  children: (signedIn: boolean) => React.ReactNode;
}) => {
  const { site } = useSiteDetection();
  const [auth, setAuth] = React.useState({
    settled: false,
    authenticated: false,
  });

  const signIn = React.useCallback(() => {
    setAuth({
      settled: true,
      authenticated: true,
    });
  }, []);

  const signOut = React.useCallback(() => {
    setAuth({
      settled: true,
      authenticated: false,
    });
  }, []);

  React.useEffect(() => {
    const listener: HubListenerFn = (args) => {
      switch (args.payload.event) {
        case 'signIn':
          signIn();
          break;
        case 'signOut':
          signOut();
          break;
      }
    };
    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  React.useEffect(() => {
    const doSignIn = async () => {
      const signInOrder = [pools.ogp, pools.partner, pools.hq];

      for (let i = 0; i < signInOrder.length; i++) {
        configureAmplify(signInOrder[i]);
        try {
          await Auth.currentAuthenticatedUser();
          signIn();
          return;
        } catch {}
      }

      configureAmplify(pools[site]);
      signOut();
    };

    doSignIn();
  }, [signIn, signOut, site]);

  if (!auth.settled) {
    return null;
  }

  return <>{children(auth.authenticated)}</>;
};

export { CheckAuthentication };
