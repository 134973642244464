import { adminSettingsService } from 'hq/services';
import { useMutation } from 'react-query';
import { BusCSVUploadPayload } from '../../../../services/admin-settings-service.types';

export const useUploadBusCsv = () => {
  const mutationFn = async (data: BusCSVUploadPayload) =>
    await adminSettingsService.uploadBusCsv(data);

  return useMutation({ mutationFn });
};
