import { styled } from '@mui/material';
import { IconLogout } from 'assets/img';
import { WrapMeWhen } from 'ogp/components/common/wrap-me-when';
import { useSidebarProvider } from './sidebar-provider';

const LogoutIcon = () => {
  return <Styled.LogoutIcon />;
};

const LogoutLabel = () => {
  const { isOpen } = useSidebarProvider();
  return (
    <WrapMeWhen
      when={isOpen}
      wrapper={(children) => <Styled.LogoutLabel>{children}</Styled.LogoutLabel>}
    >
      <>Log out</>
    </WrapMeWhen>
  );
};

const Styled = {
  LogoutLabel: styled('span')(({ theme }) => ({
    color: `${theme.palette.lwDanger[100]} !important`,
    fontWeight: 500,
    fontVariationSettings: '"wght" 500',
  })),
  LogoutIcon: styled(IconLogout)(({ theme }) => ({
    '& path': {
      stroke: `${theme.palette.lwDanger[100]} !important`,
    },
  })),
};

export { LogoutIcon, LogoutLabel };
