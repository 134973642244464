import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { FlexPoolFilters, useGetFlexPools } from './hooks/use-get-flex-pools';

export const AffectedFlexPoolsList = ({ jobId, shiftId }: FlexPoolFilters) => {
  const flexPoolsQuery = useGetFlexPools({ jobId, shiftId });

  if (flexPoolsQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexPoolsQuery} />;
  }

  const flexPools = flexPoolsQuery.data;

  return (
    <Box>
      {flexPools.length === 0 ? (
        <DisclaimerTitle paddingBottom="12px">
          Er zijn geen flexpools gekoppeld aan deze shift.
        </DisclaimerTitle>
      ) : (
        <>
          <DisclaimerTitle>
            Deze shift wordt alleen gepubliceerd voor de volgende flexpool(s).
          </DisclaimerTitle>
          <List>
            {flexPools.map((flexPool) => {
              return <ListItem key={flexPool.id}>- {flexPool.name}</ListItem>;
            })}
          </List>
        </>
      )}
    </Box>
  );
};

const DisclaimerTitle = styled(Typography)(() => ({
  fontWeight: 'bold',
}));
