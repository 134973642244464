import { Box, Button, IconButton, Popover, styled, Typography } from '@mui/material';
import { IconCandidates, IconClose } from 'assets/img';
import { useState } from 'react';
import { useGetShiftPartners } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-shift-partners';

export const GridShiftPartnersPopover = ({ shiftId }: { shiftId: string }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { status, data } = useGetShiftPartners(shiftId);

  if (status !== 'success' || data.length === 0) {
    return null;
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box pr={1}>
      <CandidateIconButton onMouseEnter={handleMouseEnter}>
        <IconCandidates />
      </CandidateIconButton>
      <Popover
        id={shiftId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={(theme) => ({
          '&': {
            backgroundColor: 'rgba(0,0,0,0) !important',
            backdropFilter: 'none !important',
          },
          '& .MuiPopover-paper': {
            width: theme.spacing(40),
            border: '2px solid #00000019',
            borderRadius: theme.spacing(2),
            boxShadow: theme.shadows[3],
            padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
          },
        })}
      >
        <Box paddingBottom={1}>
          <CloseIconButton onClick={handleClose} endIcon={<IconClose fill="black" />} />
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Beschikbaar voor:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1} paddingTop={3}>
            {data.map(({ name }) => (
              <Box key={name}>
                <Typography variant="body2">{name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

const CandidateIconButton = styled(IconButton)(({ theme }) => ({
  width: '30px',
  height: '35px',
  padding: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
}));

const CloseIconButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  cursor: 'pointer',
  width: '24px',
  minWidth: '24px',
  height: '24px',
}));
