import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { HqUser } from '@types';
import { userService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

type UserMutationData = { userId: string; data: HqUser };
type UserMutationOptions = UseMutationOptions<unknown, unknown, UserMutationData>;

export const useUpdateUser = () => {
  const mutationFn = async (user: UserMutationData) =>
    await userService.updateUser(user.userId, user.data);

  const queryClient = useQueryClient();
  const mutationOptions: UserMutationOptions = {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.userList());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.user(variables.userId));
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
