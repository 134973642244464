import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CompanyFormData, CompanyRole, LocationAutoSuggestItem } from '@types';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import useGeocode from 'ogp/hooks/queryHooks/locations/useGeocode';
import { filterOptions } from 'shared/utils/utils';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { LwFormAutocomplete, LwFormInput, LwFormMultiSelect, LwFormTextArea } from 'redesign';
import { CompanyFormProps } from './company-form.types';
import { PolicyGroups } from '../../../../../shared/utils/policy-controller';

const CompanyForm = ({ defaultValues, disabled }: CompanyFormProps) => {
  const { control, setValue } = useFormContext<CompanyFormData>();

  const jobGroups = useMemo(() => {
    const result = defaultValues?.jobGroups?.map(({ id, name }) => ({ id, name })) ?? [];
    return result;
  }, [defaultValues?.jobGroups]);

  const defaultAddress = useMemo(() => defaultValues?.location?.address ?? '', [defaultValues]);
  const [locationInputValue, setLocationInputValue] = useState(defaultAddress);
  const debouncedInput = useDebounce(locationInputValue, 300);
  const geoQuery = useGeocode(debouncedInput, debouncedInput !== defaultAddress);
  const { data, isFetching } = geoQuery;
  const [options, setOptions] = useState<LocationAutoSuggestItem[]>([]);

  useEffect(() => {
    if (data) {
      setOptions((previousOptions) => [
        ...previousOptions,
        ...data.filter(
          (option) =>
            previousOptions.every((previousOption) => previousOption.address !== option.address) // We need to filter the options that we include because those can be repeated
        ),
      ]);
    }
  }, [data]);

  return (
    <form>
      <Grid container item spacing={2} xs={6}>
        <Grid item xs={12}>
          <LwFormInput
            name="name"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam in' }}
            label="Bedrijfsnaam"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormTextArea
            name="description"
            label="Over het bedrijf"
            control={control}
            disabled={disabled}
            rows={10}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormMultiSelect
            name="jobGroups"
            label="Functiegroep"
            control={control}
            options={jobGroups}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            onChange={() => {}}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="website"
            label="Website"
            placeholder="https://"
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="facebook"
            label="Facebook"
            placeholder="https://"
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="instagram"
            label="Instagram"
            placeholder="https://"
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="linkedin"
            label="LinkedIn"
            placeholder="https://"
            control={control}
            disabled={disabled}
          />
        </Grid>
        <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
          <Grid item xs={12}>
            <LwFormInput
              name="bannerImageUrl"
              label="Banner foto URL"
              placeholder="https://"
              control={control}
              disabled={disabled}
            />
          </Grid>
        </AuthenticatedElement>
        <AuthenticatedElement roles={CompanyRole.HQADMIN} policyGroup={PolicyGroups.COMPANY}>
          <Grid item xs={12}>
            <LwFormInput
              name="profileImageUrl"
              label="Profielfoto URL"
              placeholder="https://"
              control={control}
              disabled={disabled}
            />
          </Grid>
        </AuthenticatedElement>
        <Grid item xs={12}>
          <LwFormAutocomplete
            name="location"
            label="Locatie"
            isLoading={isFetching}
            control={control}
            disableClearable={true}
            disabled={disabled}
            getOptionKey={(option) => option.hereId ?? ''}
            getOptionLabel={(option) => option.address}
            onChange={(_event, value) => {
              if (value) {
                setValue('location', value);
              }
            }}
            isOptionEqualToValue={(option, value) => option.hereId === value.hereId}
            inputValue={locationInputValue}
            onInputChange={(_event, value) => {
              setLocationInputValue(value);
            }}
            options={options}
            filterOptions={filterOptions}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { CompanyForm };
