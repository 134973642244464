import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexPoolService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

type DeleteFlexWorkerFromFlexPoolData = { flexPoolId: string; flexWorkerId: string };

export const useDeleteFlexWorkerFromFlexPool = () => {
  const queryClient = useQueryClient();
  const mutationFn = async (data: DeleteFlexWorkerFromFlexPoolData) =>
    await flexPoolService.deleteFlexWorkerFromFlexPool(data.flexPoolId, data.flexWorkerId);

  const options = {
    onSettled: async (_: unknown, __: unknown, variables: DeleteFlexWorkerFromFlexPoolData) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.flexPoolWithFlexWorkers(variables.flexPoolId));
      queryClient.invalidateQueries(QUERY_KEYS_OGP.flexWorkersForFlexPool(variables.flexPoolId));
    },
  };
  return useMutation(mutationFn, options);
};
