import { z } from 'zod';
import { PrebuiltBlock } from 'redesign';

type CompanyRole = 'ACCOUNTHOLDER' | 'ADMIN' | 'FINANCE' | 'HQADMIN';

type DepartmentRole = 'MANAGER' | 'PLANNER' | 'SPECTATOR';

const companyRole = ['ACCOUNTHOLDER', 'ADMIN', 'FINANCE', 'HQADMIN'] as const;
const departmentRole = ['MANAGER', 'PLANNER', 'SPECTATOR'] as const;

const GetOGPUserInfoSchema = z.object({
  userInfo: z.object({
    email: z.string().email(),
    firstname: z.string(),
    lastname: z.string(),
    nickname: z.string(),
    phone: z.string(),
    position: z.string(),
    company: z.object({
      slug: z.string(),
      name: z.string(),
      offersFreelanceWork: z.boolean(),
      offersTempWork: z.boolean(),
    }),
    roles: z.object({
      companyRoles: z.optional(
        z.object({
          companyId: z.string(),
          roles: z.array(z.enum(companyRole)),
        })
      ),
      departmentRoles: z.array(
        z.object({
          departmentId: z.string(),
          roles: z.array(z.enum(departmentRole)),
        })
      ),
    }),
  }),
});

type GetOGPUserInfoResponse = z.infer<typeof GetOGPUserInfoSchema>;

type GetUserResponse = {
  ogpUser: {
    id: string;
    firstName: string;
    lastName: string;
    companyId: string;
    email: string;
    phone: string;
    nickname: string;
    position: string;
    archived: boolean;
    accessPhone?: string;
    isArchivable: boolean;
    roles: {
      companyRole?: {
        role: CompanyRole;
        companyId: string;
      };
      departmentRole: {
        branchOffice: {
          id: string;
          name: string;
          departments: {
            id: string;
            name: string;
            role: DepartmentRole;
          }[];
        };
      }[];
    };
  };
};

type GetRolesMatrixResponse = {
  departmentRolesMatrix: {
    id: string;
    name: string;
    companyId: string;
    departments: {
      id: string;
      name: string;
      matrix: {
        name: Capitalize<Lowercase<DepartmentRole>>;
        role: DepartmentRole;
        enabled: boolean;
      }[];
    }[];
  }[];
};

type UpdateUserPayload = {
  accessPhone?: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  phone: string;
  position: string;
  roles: {
    departmentIdRoles: Record<string, DepartmentRole | null>;
    companyRole: {
      companyId: string;
      role: CompanyRole | null;
    };
  };
};

type CreateUserPayload = Pick<
  UpdateUserPayload,
  'email' | 'firstName' | 'lastName' | 'nickname' | 'phone' | 'position'
> & {
  roles?: UpdateUserPayload['roles'];
};

type GetOGPUserListingResponse = {
  ogpUsers: PrebuiltBlock[];
};

export { GetOGPUserInfoSchema };

export type {
  GetUserResponse,
  GetOGPUserInfoResponse,
  GetRolesMatrixResponse,
  CompanyRole,
  DepartmentRole,
  UpdateUserPayload,
  CreateUserPayload,
  GetOGPUserListingResponse,
};
