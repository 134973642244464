import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar, SidebarProvider } from 'ogp/components/views/sidebar';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { useExternalParams } from 'shared/hooks';
import { Wrapper } from './helpers';

const PlanningLayout = () => {
  const location = useLocation();

  return (
    <>
      <SidebarProvider>
        <Sidebar />
      </SidebarProvider>
      <div style={{ width: '100%' }}>
        <Wrapper>
          <PageProvider key={location.pathname}>
            <Outlet />
          </PageProvider>
        </Wrapper>
      </div>
    </>
  );
};

// TODO redesign this should be moved somewhere else
type Context = [
  {
    filters: {
      departmentId?: string;
      branchOfficeId?: string;
    };
  },
  {
    setDepartmentId: (departmentId?: string) => void;
    setBranchOfficeId: (branchOfficeId?: string) => void;
  },
];

const [Provider, usePageProvider] = ProviderFactory<Context>(
  'PageContext must be used within a PageProvider'
);

const PageProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const initialValues = {
    departmentId: {
      initialValue: undefined,
      type: 'string',
    },
    branchOfficeId: {
      initialValue: undefined,
      type: 'string',
    },
  } as const;

  const [params, setParams] = useExternalParams(initialValues);

  const setDepartmentId: Context['1']['setDepartmentId'] = (departmentId) => {
    setParams({ ...params, departmentId });
  };

  const setBranchOfficeId: Context['1']['setBranchOfficeId'] = (branchOfficeId) => {
    setParams({ ...params, branchOfficeId });
  };

  const value: Context = [
    { filters: params },
    {
      setDepartmentId,
      setBranchOfficeId,
    },
  ];

  return <Provider value={value}>{children}</Provider>;
};

export { PlanningLayout, PageProvider, usePageProvider };
