import { Box, styled, Typography } from '@mui/material';
import { HQShiftClaimForFlexWorkerListItem } from 'hq/services/shift-claim-service.types';
import { DateTime } from 'luxon';
import { ShiftClaimCancel } from './shift-claim-cancel';
import { ShiftClaimOvoDownload } from './shift-claim-ovo-download';
import { ShiftClaimPlanningDetail } from './shift-claim-shift-detail';
import { ShiftClaimDetailsModal } from './shift-claim-details-modal';

const ShiftClaimCard = ({ shiftClaim }: { shiftClaim: HQShiftClaimForFlexWorkerListItem }) => {
  return (
    <ShiftClaimCardWrapper
      isPending={
        DateTime.fromFormat(shiftClaim.endDateTime, 'd-M-yyyy HH:mm').diffNow().valueOf() < 0
      }
    >
      <Box>
        <Typography variant="h2">{shiftClaim.jobName}</Typography>
        <Typography variant="body1">{shiftClaim.branchOfficeName}</Typography>
        <Box mt={2}>
          <Typography variant="body1">{`starts: ${shiftClaim.startDateTime}`}</Typography>
          <Typography variant="body1">{`ends: ${shiftClaim.endDateTime}`}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ButtonBox>
          <ShiftClaimDetailsModal shiftClaim={shiftClaim} />
        </ButtonBox>
        <ButtonBox>
          <ShiftClaimPlanningDetail shiftId={shiftClaim.shiftId} />
        </ButtonBox>
        {shiftClaim.isCancelable ? (
          <ButtonBox>
            <ShiftClaimCancel shiftClaimId={shiftClaim.id} />
          </ButtonBox>
        ) : null}
        {shiftClaim.state !== 'pending' ? (
          <ButtonBox>
            <ShiftClaimOvoDownload shiftClaimId={shiftClaim.id} />
          </ButtonBox>
        ) : (
          <Typography variant="body2">Pending</Typography>
        )}
      </Box>
    </ShiftClaimCardWrapper>
  );
};

const ButtonBox = styled(Box)({
  marginLeft: '4px',
});

const ShiftClaimCardWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPending',
})<{ isPending: boolean }>(({ theme, isPending }) => ({
  padding: theme.spacing(5),
  backgroundColor: '#e5e5e5',
  marginBottom: theme.spacing(6),
  display: 'flex',
  justifyContent: 'space-between',
  opacity: isPending ? 0.4 : 1,
}));

export { ShiftClaimCard };
