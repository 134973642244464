import { Box, Grid, Typography } from '@mui/material';
import { IconJobs } from 'assets/img';
import { useCompanyRelativePath } from 'ogp/hooks';
import { routes } from 'ogp/Routes';
import { BlockLink, createBlock, LwBlock } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useGetBillingEntitiesListing } from './use-get-billing-entities-listing';

const BillingEntities = () => {
  const generatePath = useCompanyRelativePath();
  const { data, status, error, refetch } = useGetBillingEntitiesListing();

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ status, error, refetch }} />;
  }

  const blocks = data.map((block) => createBlock(block));
  return (
    <Box role="tabpanel">
      {data.length === 0 ? (
        <Typography>Geen facturatiegegevens beschikbaar</Typography>
      ) : (
        <Grid container spacing={4} sx={{ marginBottom: '32px' }} zIndex={3}>
          {blocks.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id}>
              <BlockLink to={generatePath(routes.BillingEntitiesDetail, { id: block.id })}>
                <LwBlock
                  icon={<IconJobs {...iconProps} />}
                  severity={block.type?.severity}
                  id={block.id}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                />
              </BlockLink>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

const iconProps: Pick<React.CSSProperties, 'width' | 'height'> = {
  width: 24,
  height: 24,
};

export { BillingEntities };
