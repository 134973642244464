import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { paths } from 'ogp/paths';
import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { invariant } from 'shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import { BillingEntityForm, BillingEntityFormFields } from '../../components';
import { useCreateBillingEntity } from '../../hooks/use-create-billing-entity';
import { decodeFees, getOverlappingFeeTypes } from '../../utils/utils';
import { FeeTypeProvider } from '../../providers/fee-type-provider';
import { useInvalidateQuery } from '../../../../../../../shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_OGP } from '../../../../../../query-keys';
import { routes } from '../../../../../../Routes';

type Props = {
  defaultValues?: BillingEntityFormFields;
  successMessage: string;
  pageTitle: string;
};

export const BillingEntityCreatePage = ({ defaultValues, successMessage, pageTitle }: Props) => {
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const invalidateQuery = useInvalidateQuery();
  const formMethods = useForm<BillingEntityFormFields>({ defaultValues });
  const mutation = useCreateBillingEntity();
  const { alertSuccess, alertError } = useAlert();
  const { submitButtonProps, cancelButtonProps, returnTo } = useFormButtons(mutation.status);
  const userInfo = useGetOGPUserInfo();

  const handleSubmit = () => {
    const companyId = userInfo.data?.userInfo.company.slug;
    invariant(companyId, 'companyId is required');

    formMethods.handleSubmit((data) => {
      const { fees, ...rest } = data;
      const overlappingFeeTypes = getOverlappingFeeTypes(fees);

      if (overlappingFeeTypes.length > 0) {
        alertError(
          new Error(`Vergoedingsintervallen overlappen elkaar ${overlappingFeeTypes.join(', ')}`)
        );

        return;
      }

      const decodedFees = decodeFees(fees);

      mutation.mutate(
        {
          ...rest,
          fees: decodedFees,
          companyId,
          paymentTerm: Math.round(rest.paymentTerm),
        },
        {
          onSuccess: ({ id }) => {
            invalidateQuery(QUERY_KEYS_OGP.billingEntities());
            alertSuccess(successMessage);
            navigate(generatePath(routes.BillingEntitiesDetail, { id }));
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton onClick={returnTo(paths.Company)} iconColorMode="fill" {...cancelButtonProps}>
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <FeeTypeProvider>
          <BillingEntityForm />
        </FeeTypeProvider>
      </Page>
    </FormProvider>
  );
};
