import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { OGPMultiplyShiftPayload } from 'ogp/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftMutationOptions = UseMutationOptions<unknown, unknown, OGPMultiplyShiftPayload, unknown>;

export const useOgpMultiplyShift = (options?: ShiftMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: OGPMultiplyShiftPayload) => await shiftService.multiplyShift(params),
    {
      ...options,
      onSuccess: (_, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS_OGP.shifts()); // Bluntly invalidate ALL shift data because the change could be reflected in a lot of places.
        options?.onSuccess && options.onSuccess(null, variables, context);
      },
    }
  );
};
