import React from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { Alert } from './alert-provider.types';
import { AlertContainer } from './alert-container';

const [Provider, useAlertProvider] = ProviderFactory<{
  alert: Alert | undefined;
  openAlert: (alert: Alert) => void;
  closeAlert: () => void;
}>('You tried to access AlertProvider, but it was not found.');

const AlertProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [alert, setAlert] = React.useState<Alert>();

  const closeAlert = () => {
    setAlert(undefined);
  };

  const openAlert = (alert: Alert) => {
    setAlert(alert);
  };

  return (
    <Provider value={{ alert, openAlert, closeAlert }}>
      <AlertContainer />
      {children}
    </Provider>
  );
};

export { AlertProvider, useAlertProvider };
