export type State = (typeof stateValues)[number];
export const stateValues = ['verified', 'pending', 'rejected', 'expired'] as const;
export const translatedStates = [
  'Geverifieerd',
  'Verlopen',
  'Afgewezen',
  'In behandeling',
] as const;

export const translateState = (state: State) => {
  switch (state) {
    case 'verified':
      return 'geverifieerd';
    case 'pending':
      return 'in behandeling';
    case 'rejected':
      return 'afgewezen';
    case 'expired':
      return 'verlopen';
  }
};

export const ACCREDITATIONS_INITIAL_VALUES = {
  flexWorkerId: {
    initialValue: undefined,
    type: 'string',
  },
  certificateId: {
    initialValue: undefined,
    type: 'string',
  },
  order: {
    initialValue: 'desc',
    type: 'string',
  },
  filter: {
    initialValue: undefined,
    type: 'string',
  },
  certificate: {
    initialValue: 'alle',
    type: 'string',
  },
  state: {
    initialValue: stateValues[1],
    type: 'string',
  },
} as const;

export type AccreditationParams = {
  flexWorkerId?: string;
  certificateId?: string;
  order: 'asc' | 'desc';
  filter?: string;
  certificate: string;
  state: State;
};

export type SetAccreditationParams = (params: Partial<AccreditationParams>) => void;
