import { useAlert } from 'shared/components/alerts';
import { useState } from 'react';
import { branchOfficeAlerts } from 'shared/components/alerts/alert-messages';
import { useConfirm } from 'shared/hooks';
import { useArchiveBranchOffice } from '../../../../../../../../../hooks/queryHooks/branch-offices/use-archive-branch-office';

const useArchiveBranchOfficeDialog = () => {
  const [branchOfficeId, setBranchOfficeId] = useState<string>('');
  const { alertSuccess, alertError } = useAlert();
  const archiveBranchOffice = useArchiveBranchOffice({
    onSuccess: () => {
      dialog.close();
      alertSuccess(branchOfficeAlerts.success.deleted);
    },
    onError: (e) => alertError(e),
  });

  function handleArchiveBranchOffice() {
    archiveBranchOffice.mutate(branchOfficeId);
  }

  const dialog = useConfirm({
    title: 'Weet je zeker dat je deze vestiging wilt verwijderen?',
    positiveAction: { label: 'Ja', action: handleArchiveBranchOffice },
    negativeAction: { label: 'Nee', autoFocus: true },
  });

  function open(id: string) {
    setBranchOfficeId(id);
    dialog.open();
  }

  return { ...dialog, open };
};

export { useArchiveBranchOfficeDialog };
