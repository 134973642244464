import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ShiftSchedule } from 'partner/shared/services/partner-checkouts-service.types';
import type { Column } from 'react-table';
import { ContextMenuButton, LevelTableCellProps } from 'redesign';

export type PartnerWorkedShiftsColumnDefinition = {
  shiftId: string;
  shiftClaimId: string;
  flexWorkerName: string;
  jobName: string;
  companyName: string;
  workedDate: string;
  scheduledTime: Omit<ShiftSchedule, 'breakMinutes'>;
  breakMinutes: ShiftSchedule['breakMinutes'];
};

export type PartnerWorkedShiftsTableOptions = {
  getRowActions: (shiftClaimId: string) => React.ReactNode[];
};

type WorkedShiftsCellProp<T extends keyof PartnerWorkedShiftsColumnDefinition> =
  LevelTableCellProps<
    PartnerWorkedShiftsColumnDefinition,
    PartnerWorkedShiftsColumnDefinition[T],
    PartnerWorkedShiftsTableOptions
  >;

const getWorkedShiftsColumnDefinition: () => Column<PartnerWorkedShiftsColumnDefinition>[] = () => {
  const worker = {
    Header: () => <Header>Flexwerker</Header>,
    accessor: 'flexWorkerName',
    Cell: WorkerCell,
    width: '150px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const job = {
    Header: () => <Header>Functie</Header>,
    accessor: 'jobName',
    Cell: JobCell,
    width: '150px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const company = {
    Header: () => <Header>Bedrijf</Header>,
    accessor: 'companyName',
    Cell: CompanyCell,
    width: '150px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const date = {
    Header: () => <Header>Datum</Header>,
    accessor: 'workedDate',
    Cell: WorkedDateCell,
    width: '80px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const scheduledTime = {
    Header: () => <Header>Shift tijd</Header>,
    accessor: 'scheduledTime',
    Cell: ScheduledTimeCell,
    width: '100px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const breakMinutes = {
    Header: () => <Header>Pauze</Header>,
    accessor: 'breakMinutes',
    Cell: BreakCell,
    width: '50px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  const action = {
    Header: () => <Header>Actie</Header>,
    accessor: 'shiftClaimId',
    Cell: ActionCell,
    width: '65px',
  } as Column<PartnerWorkedShiftsColumnDefinition>;

  return [worker, job, company, date, scheduledTime, breakMinutes, action];
};

const Header = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
    {children}
  </Typography>
);

const WorkerCell = (cell: WorkedShiftsCellProp<'flexWorkerName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const JobCell = (cell: WorkedShiftsCellProp<'jobName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const CompanyCell = (cell: WorkedShiftsCellProp<'companyName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const WorkedDateCell = (cell: WorkedShiftsCellProp<'workedDate'>) => {
  return (
    <Typography variant="body1" noWrap>
      {DateTime.fromISO(cell.value).toFormat('dd LLL', { locale: 'nl' })}
    </Typography>
  );
};

const ScheduledTimeCell = (cell: WorkedShiftsCellProp<'scheduledTime'>) => {
  return (
    <Typography variant="body1" noWrap>
      {DateTime.fromISO(cell.value.startDate).toFormat('HH:mm')} -{' '}
      {DateTime.fromISO(cell.value.endDate).toFormat('HH:mm')}
    </Typography>
  );
};

const BreakCell = (cell: WorkedShiftsCellProp<'breakMinutes'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value ? `${cell.value} min.` : 'geen'}
    </Typography>
  );
};

const ActionCell = (cell: WorkedShiftsCellProp<'shiftClaimId'>) => {
  const actions = cell.getRowActions(cell.value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${cell.value}`}>{actions}</ContextMenuButton>
  );
};

export { getWorkedShiftsColumnDefinition };
