export const config = {
  development: {
    environment: 'dev',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  },
  test: {
    environment: 'test',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  },
  accept: {
    environment: 'acc',
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  },
  production: {
    environment: 'prod',
    tracesSampleRate: 0.4,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  },
};
