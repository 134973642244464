import React from 'react';
import { RenderUnsettledUI } from 'shared/components';
import { PartnerRelationFormData } from './partner-relations.types';
import { PartnerRelationForm } from './partner-relation-form';
import { useGetAllActiveBranchOffices } from './hooks/use-get-all-active-branch-offices';
import { useGetWorkPlanningJobTypesData } from '../../planner/common/hooks/use-get-work-planning-filters-data';

interface Props {
  state: 'update' | 'create';
  defaultValues?: Partial<PartnerRelationFormData>;
}

const PartnerRelationFormDataProvider = ({ state, defaultValues }: Props) => {
  const branchOfficesQuery = useGetAllActiveBranchOffices();
  const jobTypesQuery = useGetWorkPlanningJobTypesData();

  if (branchOfficesQuery.status !== 'success' || jobTypesQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficesQuery} />;
  }

  return (
    <PartnerRelationForm
      branchOffices={branchOfficesQuery.data}
      jobTypes={jobTypesQuery.data}
      state={state}
      defaultValues={defaultValues}
    />
  );
};

export { PartnerRelationFormDataProvider };
