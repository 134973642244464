import { getShiftDurationInMs } from './get-shift-duration-in-ms';
import { ShiftDateProperties, WorkedShiftItem } from './get-shift-date-properties.types';
import { ClaimPendingExtended, ClaimSettledExtended } from '../types/checkouts.types';

/**
 * Retrieves properties based on current state of checkout item.
 * @param item is the archived or inboxed shift.
 * @returns an object defining actual worked times, who (if) edited the shift, and time deviation properties
 */
export const getShiftDateProperties = (
  item: ClaimSettledExtended | ClaimPendingExtended
): WorkedShiftItem => {
  const shiftDateProperties = getTimeDeviationItem(item);
  const scheduledDates: WorkedShiftItem['workTimes'] = {
    startDate: shiftDateProperties.scheduledStartDate,
    endDate: shiftDateProperties.scheduledEndDate,
  };

  if (shiftDateProperties.checkoutState === 'noShow') {
    return {
      workTimes: scheduledDates,
      workBreakInMinutes: shiftDateProperties.breakInMinutes,
      timeZone: shiftDateProperties.timeZone,
      timeDeviationInMs: 0,
      workedTimeInMs: 0,
    };
  }

  const { workTimes, workBreakInMinutes } = getWorkedValues(shiftDateProperties);

  const hasChangedTimes =
    scheduledDates.startDate.getTime() !== workTimes.startDate.getTime() ||
    scheduledDates.endDate.getTime() !== workTimes.endDate.getTime();

  const scheduledTimeInMs = getShiftDurationInMs(
    scheduledDates.startDate,
    scheduledDates.endDate,
    shiftDateProperties.breakInMinutes
  );
  const workedTimeInMs = getShiftDurationInMs(
    workTimes.startDate,
    workTimes.endDate,
    workBreakInMinutes
  );
  const timeDeviationInMs = workedTimeInMs - scheduledTimeInMs;

  return {
    workTimes,
    workBreakInMinutes,
    timeZone: shiftDateProperties.timeZone,
    hasChangedShiftTime: hasChangedTimes,
    hasChangedBreakTime: shiftDateProperties.breakInMinutes !== workBreakInMinutes,
    workedTimeInMs,
    timeDeviationInMs,
  };
};

/**
 * Helper function that retrieves properties based on the current state of checkout.
 * If an OG user entered start date, then it has been changed by him
 * If an OG user has not entered start date, but the shift was completed, then the dates were added by flexworker when shift was finished.
 * Otherwise, it was a no-show.
 */
const getTimeDeviationItem = (
  item: ClaimSettledExtended | ClaimPendingExtended
): ShiftDateProperties => {
  if (item.ogStartDate) {
    return {
      checkoutState: item.state,
      scheduledStartDate: item.shift.utcStartDate,
      scheduledEndDate: item.shift.utcEndDate,
      breakInMinutes: item.shift.breakMinutes,
      timeZone: item.shift.timeZone,
      fwBreakMinutes: item.fwBreakMinutes,
      fwEndDate: item.fwEndDate,
      fwStartDate: item.fwStartDate,
      ogStartDate: item.ogStartDate,
      ogEndDate: item.ogEndDate,
      ogBreakMinutes: item.ogBreakMinutes,
    };
  }
  if (item.fwStartDate) {
    return {
      checkoutState: item.state,
      scheduledStartDate: item.shift.utcStartDate,
      scheduledEndDate: item.shift.utcEndDate,
      breakInMinutes: item.shift.breakMinutes,
      timeZone: item.shift.timeZone,
      fwBreakMinutes: item.fwBreakMinutes,
      fwEndDate: item.fwEndDate,
      fwStartDate: item.fwStartDate,
      ogStartDate: null,
      ogEndDate: null,
      ogBreakMinutes: null,
    };
  }

  return {
    checkoutState: 'noShow',
    scheduledStartDate: item.shift.utcStartDate,
    scheduledEndDate: item.shift.utcEndDate,
    breakInMinutes: item.shift.breakMinutes,
    timeZone: item.shift.timeZone,
    ogStartDate: null,
    ogEndDate: null,
    ogBreakMinutes: null,
    fwBreakMinutes: item.shift.breakMinutes,
    fwEndDate: item.shift.utcEndDate,
    fwStartDate: item.shift.utcStartDate,
  };
};

function getWorkedValues(
  shiftDateProperties: ShiftDateProperties
): Pick<WorkedShiftItem, 'workTimes' | 'workBreakInMinutes'> {
  if (shiftDateProperties.ogStartDate) {
    return {
      workTimes: {
        startDate: shiftDateProperties.ogStartDate,
        endDate: shiftDateProperties.ogEndDate,
      },
      workBreakInMinutes: shiftDateProperties.ogBreakMinutes,
    };
  }
  return {
    workTimes: {
      startDate: shiftDateProperties.fwStartDate,
      endDate: shiftDateProperties.fwEndDate,
    },
    workBreakInMinutes: shiftDateProperties.fwBreakMinutes,
  };
}
