import { styled } from '@mui/material/styles';
import { Box, Checkbox as MuiCheckbox } from '@mui/material';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { iconButtonClasses } from '@mui/material/IconButton';
import React from 'react';
import { CheckboxProps } from './checkbox.types';
import Checkmark from '../../assets/img/icons/redesign/checkmark.svg?react';

const StyledMuiCheckbox = styled(MuiCheckbox)(() => ({
  '&.MuiCheckbox-root': {
    padding: '4px',
    borderColor: '#001A9966',
    borderWidth: '1.5px',
    'svg[class^=MuiSvgIcon-root]': {
      borderRadius: '6px',
    },
  },
  '&:PrivateSwitchBase-input': {},
  '&:hover': {
    borderRadius: '6px',
    border: '1.50px',
    borderColor: '#e5e9ff',
  },
  '&:active': {
    borderColor: '#001A9966',
  },
  '&.Mui-disabled': {
    [`& svg[class^=${svgIconClasses.root}]`]: {
      opacity: 0.5,
      backgroundColor: '#F7F7F7',
      fill: '#F7F7F7',
    },
  },
  '&.Mui-checked': {
    [`& svg[class^=${svgIconClasses.root}]`]: {
      fill: '#002BFF',
      borderColor: '#002BFF',
      backgroundColor: '#002BFF',
    },
  },
  [`& svg[class^=${svgIconClasses.root}]`]: {
    fill: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 26, 153, 0.2)',
    borderRadius: '6px',
    '& path': {
      transform: 'scale(1)',
    },
  },
  [`&[class*=${iconButtonClasses.root}]`]: {
    width: '28px',
    height: '28px',
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0',
    marginRight: '16px',
  },
}));

const LwCheckbox = React.forwardRef((props: CheckboxProps, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <Box>
      <StyledMuiCheckbox
        checkedIcon={<Checkmark className="MuiSvgIcon-root" />}
        {...props}
        ref={ref}
      />
    </Box>
  );
});

export { LwCheckbox };
