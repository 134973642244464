import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { HeaderProps } from './header.types';

export const Header = ({ subheaderText, titleText, labelGroups }: HeaderProps) => {
  const styles = getStyles();

  return (
    <Grid className={styles.wrapper}>
      <Grid className={styles.titleWrapper} container>
        <Grid item xs={12} className={clsx(subheaderText && styles.subheader)}>
          <Typography variant="subtitle1" color="secondary" noWrap>
            {subheaderText ?? ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" noWrap>
            {titleText}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {labelGroups
          ? labelGroups.map(({ title, description }, index) => {
              return (
                <Grid item xs={2} key={index}>
                  <Typography variant="subtitle2" color="secondary" noWrap>
                    {title}
                  </Typography>
                  <Typography variant="body1" noWrap>
                    {description}
                  </Typography>
                </Grid>
              );
            })
          : null}
      </Grid>
    </Grid>
  );
};

const getStyles = makeStyles(() => ({
  wrapper: {
    paddingBottom: 24,
    paddingTop: 32,
    width: '100%',
  },
  titleWrapper: {
    marginBottom: 24,
  },
  title: {
    lineHeight: 1,
  },
  subheader: {
    marginBottom: 4,
  },
}));
