import { flexPoolService as flexPoolServiceOGP } from 'ogp/services';
import { flexPoolService as flexPoolServiceHQ } from 'hq/services/index';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

export type FlexPoolFilters = {
  departmentId?: string;
  jobId?: string;
  shiftId?: string;
};

export type GetFlexPoolsResponse = {
  id: string;
  name: string;
  description?: string | undefined;
  departmentId: string;
  departmentName: string;
  companyId: string;
  companyName: string;
}[];

export const useGetFlexPools = (params: FlexPoolFilters) => {
  const { isHQ } = useSiteDetection();

  let queryFn: () => Promise<GetFlexPoolsResponse>;
  if (isHQ) {
    queryFn = async () => await flexPoolServiceHQ.getFlexPools(params);
  } else {
    queryFn = async () => await flexPoolServiceOGP.getFlexPools(params);
  }

  return useQuery(QUERY_KEYS_OGP.flexPoolsWithParams(params), queryFn, {
    keepPreviousData: true,
  });
};
