import { Grid, styled } from '@mui/material';
import { GridDayColumnProps } from './grid-day-column.types';
import { GridShift } from './grid-shift';

export const GridDayColumn = ({ data, dayCount }: GridDayColumnProps) => {
  return (
    <ShiftWrapper item dayCount={dayCount}>
      {data.length ? (
        data.map((shift) => <GridShift key={shift.id} shiftData={shift} />)
      ) : (
        <GridShift shiftData={null} />
      )}
    </ShiftWrapper>
  );
};

const ShiftWrapper = styled(Grid, { shouldForwardProp: (prop) => prop !== 'dayCount' })<{
  dayCount: number;
}>(({ theme, dayCount }) => ({
  width: `calc(100% / ${dayCount})`,
  padding: theme.spacing(2),
  '&:last-child': {
    borderRight: 'none',
  },
}));
