import { Certificate } from './accreditation-service.types';
import { PartnerApiService } from './partner-api-service';

class AccreditationService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public getCertificates = async (): Promise<Certificate[]> => {
    const result = this.partnerApiService.get<Certificate[]>({
      path: 'accreditations/certificates',
    });

    return result;
  };
}

export { AccreditationService };
