import { IconIsolationMode } from 'assets/img';
import { ShiftPlanningCellProp } from 'shared/components/planner/shifts/table/shift-planning-table.types';
import type { Column } from 'react-table';
import {
  BoxAction,
  BoxIcon,
  Cell,
  DateTimeCell,
  Header,
  JobNameCell,
  PartnersCell,
  StateCell,
} from 'shared/components/planner/common/utils';
import {
  HqShiftPlanningCellProp,
  HqShiftPlanningColumnDefinition,
} from '../shift-planning-table.types';
import { CandidateStateTooltip } from '../../../common/state-tooltip/candidate-state-tooltip';

const getShiftPlanningColumnDefinition: () => Column<HqShiftPlanningColumnDefinition>[] = () => {
  const jobName = {
    Header: () => <Header>Functie</Header>,
    accessor: 'jobName',
    Cell: JobNameCell,
    width: '200px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const companyName = {
    Header: () => <Header>Bedrijf</Header>,
    accessor: 'companyName',
    Cell: Cell,
    width: '200px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const departmentName = {
    Header: () => <Header>Afdeling</Header>,
    accessor: 'departmentName',
    Cell: Cell,
    width: '350px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const dateTime = {
    Header: () => <Header>Datum & tijd</Header>,
    accessor: 'dateTime',
    Cell: DateTimeCell,
    width: '230px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const state = {
    Header: () => <Header>Status</Header>,
    accessor: 'state',
    Cell: PartnerStateCell,
    width: '100px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const partners = {
    Header: () => <Header>Partners</Header>,
    accessor: 'partners',
    Cell: PartnersCell,
    width: '85px',
  } as Column<HqShiftPlanningColumnDefinition>;
  const action = {
    Header: () => <Header>Actie</Header>,
    accessor: 'action',
    Cell: ActionCell,
    width: '65px',
  } as Column<HqShiftPlanningColumnDefinition>;

  return [jobName, companyName, departmentName, dateTime, state, partners, action];
};

const ActionCell = (props: HqShiftPlanningCellProp<'action'>) => {
  return (
    <BoxAction>
      <BoxIcon>
        <IconIsolationMode onClick={() => props.onShowDetail(props.value)} />
      </BoxIcon>
    </BoxAction>
  );
};

const PartnerStateCell = (props: ShiftPlanningCellProp<'state'>) => {
  return (
    <StateCell {...props}>
      <CandidateStateTooltip candidateState={props.value} />
    </StateCell>
  );
};

export { getShiftPlanningColumnDefinition };
