import { HqUser, HqUserInfo } from '@types';
import { HqApiService } from './hqApiService';
import { GetHqUserResponse, GetHqUsers, HqUsersResponse } from './user-service.types';

export type RoleMatrix = {
  name: string;
  role: string;
  enabled: true | 'DEVELOPER';
};

export class UserService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getUserInfo = async (): Promise<HqUserInfo> => {
    const userInfo = await this.hqApiService.get<HqUserInfo>({
      path: `get-user-info`,
    });

    return userInfo;
  };

  public getUsers = async (): Promise<GetHqUsers[]> => {
    const response = await this.hqApiService.get<HqUsersResponse>({
      path: 'users',
    });
    return response.hqUsers;
  };

  public getUser = async (userId: string): Promise<HqUser> => {
    const { hqUser } = await this.hqApiService.get<GetHqUserResponse>({
      path: `users/${userId}`,
    });

    return hqUser;
  };

  public getRolesMatrix = async (): Promise<RoleMatrix[]> => {
    const { rolesMatrix } = await this.hqApiService.get<{ rolesMatrix: RoleMatrix[] }>({
      path: `users/get-roles-matrix`,
    });

    return rolesMatrix;
  };

  public createUser = async (user: HqUser) => {
    await this.hqApiService.create({
      path: `users`,
      body: user,
    });
  };

  public updateUser = async (userId: string, user: HqUser) => {
    await this.hqApiService.update({
      path: `users/${userId}`,
      body: user,
    });
  };

  public deleteUser = async (userId: string) => {
    await this.hqApiService.delete({
      path: `users/${userId}`,
    });
  };
}
