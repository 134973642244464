import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { geocodeService } from '../../../services';

export default function useGeocode(inputAddress: string, enabled = false) {
  const { alertError } = useAlert();
  return useQuery(
    inputAddress,
    async ({ signal }) => await geocodeService.geocode(inputAddress, signal),
    {
      enabled: inputAddress.length >= 3 && enabled, // Only geocode with 3 characters or more. Less just costs money and will never return a useful result.
      keepPreviousData: true,
      staleTime: Infinity, // We can safely keep this around. Addresses don't change a lot (in our time frame) ;-)
      onError: (e) => alertError(e),
    }
  );
}
