import { AccreditationInput, LwFormDate } from 'redesign';
import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { HQCertificateResponse } from 'hq/services/accreditations-service.types';
import { WorkerList } from 'hq/services/flex-worker-service.types';
import { FlexWorkerSelect, FlexWorkerSelectStateProps } from '../../flexWorkers/flex-worker-select';

type AddAccreditationFormProps = FlexWorkerSelectStateProps & {
  certificates: HQCertificateResponse[];
  flexWorkers: WorkerList[];
};

export const AddAccreditationFormHq = (props: AddAccreditationFormProps) => {
  const formMethods = useFormContext();
  return (
    <Grid>
      <Grid item xs={1}>
        <Box display="flex" flex={1}>
          <FlexWorkerSelect
            workers={props.flexWorkers}
            // TODO create an own provider and not pass these props LW1-16844
            flexWorkerOption={props.flexWorkerOption}
            flexWorkerSearch={props.flexWorkerSearch}
            setFlexWorkerSearch={props.setFlexWorkerSearch}
            setFlexWorkerOption={props.setFlexWorkerOption}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box display="flex" flex={1}>
          <AccreditationInput
            certificates={props.certificates}
            name="accreditations"
            required={true}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <LwFormDate
          name="expirationDate"
          label="Vervaldatum"
          control={formMethods.control}
          data-testid="start-date"
          minDate={DateTime.now().toISODate()}
        />
      </Grid>
    </Grid>
  );
};
