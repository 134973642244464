import { companyService } from 'ogp/services';
import { FlexWorkerForNotWelcomeList } from 'ogp/services/company-service.types';
import { useQuery } from 'react-query';
import { useAlert } from 'shared/components/alerts';

export const useGetAvailableFlexWorkers = (filter: string) => {
  const { alertError } = useAlert();
  return useQuery<Promise<FlexWorkerForNotWelcomeList[]>, Error, FlexWorkerForNotWelcomeList[]>(
    filter,
    async ({ signal }) => await companyService.getAvailableFlexWorkers(filter, signal),
    {
      enabled: filter.length >= 3,
      keepPreviousData: true,
      staleTime: Infinity,
      onError: (e) => alertError(e),
    }
  );
};
