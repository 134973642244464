import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { useCalendarDefaults } from 'shared/hooks/useCalendarDefaults';
import {
  CALENDAR_MONTHS_AVAILABLE_FOR_PLANNING,
  ShiftMultiplyCalendarProps,
} from './shift-multiply-calendar.types';

const ShiftMultiplyCalendar = ({ shiftDate, onChange }: ShiftMultiplyCalendarProps) => {
  const [dates, setDates] = React.useState<DateTime[]>([]);
  const classes = getClasses();
  const { monthLabels, dayLabels, calendarStyles } = useCalendarDefaults();
  const today = DateTime.now().startOf('day');
  const maxAvailableDate = today.plus({ months: CALENDAR_MONTHS_AVAILABLE_FOR_PLANNING });
  const disabledDate = dateTimeToDateObject(shiftDate);
  const floatingMonthsAvailableForPlanning = getFloatingMonths(today);

  return (
    <section className={classes.calendarGrid}>
      {[...Array(Math.ceil(floatingMonthsAvailableForPlanning))]
        .map((_, months) => today.plus({ months }))
        .map((month) => (
          <Box
            data-testid="calendar"
            className={classes.calendarWrapper}
            key={month.toString()}
            minWidth={0}
          >
            <Calendar
              className={calendarStyles.calendar}
              value={dates.map(dateTimeToDateObject)}
              currentDate={dateTimeToDateObject(month)}
              mapDays={({ date, isSameDate }) => ({
                children: date.format('DD'),
                className: isSameDate(date, disabledDate) ? 'shift-date' : undefined,
              })}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                const isEnabled = (when: DateObject) => when.unix !== disabledDate.unix;
                const dates = (date instanceof Array ? date : [date])
                  .filter(isEnabled)
                  .map(dateObjectToDateTime);

                onChange(dates);
                setDates(dates);
              }}
              weekStartDayIndex={1}
              months={monthLabels}
              weekDays={dayLabels}
              minDate={dateTimeToDateObject(today)}
              maxDate={dateTimeToDateObject(maxAvailableDate)}
              buttons={false}
              multiple
              disableMonthPicker
              disableYearPicker
            />
          </Box>
        ))}
    </section>
  );
};

const getFloatingMonths = (from: DateTime) => {
  const daysToTheStartOfMonth = from.diff(DateTime.now().startOf('month'), 'days').days;
  const maxAvailableDate = from.plus({ months: CALENDAR_MONTHS_AVAILABLE_FOR_PLANNING });
  const floatingMonths = maxAvailableDate.plus(daysToTheStartOfMonth).diff(from, 'months').months;

  return floatingMonths;
};

const dateTimeToDateObject = (input: DateTime): DateObject => new DateObject(input.toJSDate());

const dateObjectToDateTime = (input: DateObject): DateTime => DateTime.fromJSDate(input.toDate());

const dateSize = `min(50px, ${(24 / 1024) * 100}vw)`;
const calendarSpacing = `${(15 / 1024) * 100}vw`;

const getClasses = makeStyles((theme) => ({
  calendarGrid: {
    background: theme.palette.grey[100],
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(4),
  },
  calendarWrapper: {
    padding: calendarSpacing,
    position: 'relative', // Needed for the border between calendars

    '&:nth-child(odd)': {
      paddingLeft: 0,
    },

    '&:nth-child(even)': {
      paddingRight: 0,
    },

    '&:nth-child(-n+2)': {
      paddingTop: 0,
    },

    '&:nth-last-child(-n+2)': {
      paddingBottom: 0,
    },

    '&::before, &::after': {
      content: '""',
      backgroundColor: theme.palette.grey[300],
      position: 'absolute',
    },

    '&:nth-child(even)::before': {
      height: `calc(100% - 40px - ${calendarSpacing})`,
      width: '1px',
      top: 40,
      left: 0,
    },

    '&:nth-last-child(n+3)::after': {
      height: '1px',
      width: `calc(100% - ${calendarSpacing})`,
      bottom: 0,
      left: theme.spacing(2),
    },

    // Shift multiply specific calendar element styling below here

    '& .rmdp-week-day': {
      width: dateSize,
      height: dateSize,
    },

    '& .rmdp-day': {
      width: dateSize,
      height: dateSize,

      '& span.shift-date': {
        background: 'black !important',
        color: 'white !important',
        borderRadius: '4px !important', // Make sure we override default border radius, color and background
        pointerEvents: 'none',
        cursor: 'default',
      },
    },

    // No bold text for today, because it makes no sense
    '& .rmdp-today span': {
      fontWeight: 'normal !important',
      textDecoration: 'none !important',
    },

    // Month name in header
    '& .rmdp-header': {
      height: 'auto',

      '& .rmdp-header-values': {
        margin: `0 0 ${theme.spacing(2)}px 0`,

        '& > span': {
          paddingLeft: '1ch',
        },
      },
    },
  },
}));

export { ShiftMultiplyCalendar };
