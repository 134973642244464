import { Auth } from 'aws-amplify';
import { AbstractApiService, TokenProvider } from 'shared/services/abstractApiService';

interface Config {
  endpoint: string;
}

class OgpTokenProvider implements TokenProvider {
  public async currentToken(): Promise<string> {
    // TODO: does this also fix refreshing of tokens?
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }
}

export class OgpApiService extends AbstractApiService {
  constructor(config: Config) {
    super(config.endpoint, new OgpTokenProvider());
  }

  public getHeaders() {
    const companyHash = window.location.pathname.split('/')[2];

    return {
      ...super.getHeaders(),
      CompanyId: companyHash,
    };
  }
}
