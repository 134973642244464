import { accreditationService } from 'partner/shared/services';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';
import { useQuery } from 'react-query';
import { QUERY_KEYS_PARTNER } from '../partner-query-keys';

export const useGetCertificates = () => {
  const queryFn = async () => await accreditationService.getCertificates();

  return useQuery(QUERY_KEYS_PARTNER.certificates(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
