export type Pagination = {
  page: number;
  setPage: (page: number) => void;
  size: number;
  setSize: (page: number) => void;
};

export type ParamsWithPagination<T> = T & {
  page: number;
  size: number;
};

export type PaginatedCollection<T> = {
  items: T[];
  totalCount: number;
  unfilteredCount: number;
};

export const PaginationResultSizes = [25, 50, 100];
