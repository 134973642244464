import { RatingService } from 'hq/services/ratingService';
import { EnvironmentService } from 'shared/services';
import { ProjectService } from 'ogp/services/project-service';
import { OgpApiService } from './ogp-api-service';
import { JobService } from './job-service';
import { ShiftClaimService } from './shift-claim-service';
import { ShiftApplicationService } from './shift-application-service';
import { UserService } from './user-service';
import { FlexPoolService } from './flex-pool-service';
import { BranchOfficeService } from './branch-office-service';
import { GeocodeService } from './geocodeService';
import { FlexWorkerService } from './flex-worker-service';
import { NoShowReasonService } from './noShowReasonService';
import { StatisticsService } from './statistics-service';
import { CompanyService } from './company-service';
import { ShiftService } from './shift-service';
import { DepartmentService } from './department-service';
import { PlacementService } from './placement-service';
import { BillingEntityService } from './billing-entity-service';
import { AccreditationsService } from './accreditations-service';
import { WorkApplicationService } from './work-application-service';
import { OgpSharedService } from './ogp-shared-service';
import { JobTypeService } from './job-type-service';
import { VersionService } from '../../shared/services/versionService';

const endpoint = EnvironmentService.getVariable('BACKEND_OGP_URI');

const ogpApiService = new OgpApiService({ endpoint });
export const billingEntityService = new BillingEntityService(ogpApiService);
export const departmentService = new DepartmentService(ogpApiService);
export const jobService = new JobService(ogpApiService);
export const shiftService = new ShiftService(ogpApiService);
export const shiftClaimService = new ShiftClaimService(ogpApiService);
export const shiftApplicationService = new ShiftApplicationService(ogpApiService);
export const userService = new UserService(ogpApiService);
export const flexPoolService = new FlexPoolService(ogpApiService);
export const branchOfficeService = new BranchOfficeService(ogpApiService);
export const companyService = new CompanyService(ogpApiService);
export const geocodeService = new GeocodeService(ogpApiService);
export const versionService = new VersionService(ogpApiService);
export const ratingService = new RatingService(ogpApiService);
export const flexWorkerService = new FlexWorkerService(ogpApiService);
export const noShowReasonService = new NoShowReasonService(ogpApiService);
export const statisticsService = new StatisticsService(ogpApiService);
export const projectService = new ProjectService(ogpApiService);
export const placementService = new PlacementService(ogpApiService);
export const accreditationsService = new AccreditationsService(ogpApiService);
export const workApplicationService = new WorkApplicationService(ogpApiService);
export const ogpSharedService = new OgpSharedService(ogpApiService);
export const jobTypeService = new JobTypeService(ogpApiService);
