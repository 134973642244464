import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { shiftPlanningListItemsResponse2PlanningModel } from 'ogp/services/mappers/mappers';
import { GetShiftPlanningListItemsParams } from 'ogp/services/shift-service.types';
import { useQuery } from 'react-query';
import { CACHE_TIME_MEDIUM } from 'shared/react-query/query-defaults';

export const useGetShiftsPlanning = (params: GetShiftPlanningListItemsParams) => {
  return useQuery(
    QUERY_KEYS_OGP.shiftPlanning(params),
    async () => await shiftService.getShiftPlanningListItems(params),
    { cacheTime: CACHE_TIME_MEDIUM, select: shiftPlanningListItemsResponse2PlanningModel }
  );
};
