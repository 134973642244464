import { AddAndApproveAccreditationParams } from 'hq/components/views/accreditations/hooks/use-add-and-approve-accreditation';
import {
  addAccreditationFiles,
  addFieldsToFormData,
} from 'shared/utils/file-handling/file-handling-utils';
import { DateTime } from 'luxon';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { HqApiService } from './hqApiService';
import {
  AccreditationDataHQ,
  HQCertificateResponse,
  RawAccreditationData,
} from './accreditations-service.types';
import { AccreditationParams } from '../components/views/accreditations/accreditations.types';

class AccreditationsService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getAccreditations(
    filterParams: AccreditationParams
  ): Promise<RawAccreditationData[]> {
    const response = await this.hqApiService.get<RawAccreditationData[]>({
      path: 'accreditations',
      params: Object.fromEntries(Object.entries(filterParams).filter(([, value]) => value !== '')),
    });
    return response;
  }

  public async getCertificates(): Promise<HQCertificateResponse[]> {
    const response = await this.hqApiService.get<HQCertificateResponse[]>({
      path: 'accreditations/certificates',
    });
    return response;
  }

  public async getAccreditationsForFlexWorker(
    flexWorkerId: string
  ): Promise<AccreditationDataHQ[]> {
    const response = await this.hqApiService.get<AccreditationDataHQ[]>({
      path: `accreditations/${flexWorkerId}`,
    });
    return response;
  }

  public async getAccreditationDetail(
    certificateId: string,
    flexWorkerId: string
  ): Promise<RawAccreditationData> {
    const response = await this.hqApiService.get<RawAccreditationData>({
      path: `accreditations/${flexWorkerId}/${certificateId}`,
    });
    return response;
  }

  public async acceptAccreditation(
    flexWorkerId: string,
    certificateId: string,
    date?: Date
  ): Promise<void> {
    await this.hqApiService.update<void>({
      body: { expirationDate: date },
      path: `accreditations/${flexWorkerId}/${certificateId}/approve`,
    });
  }

  public async addAndAcceptAccreditation({
    expirationDate,
    accreditations,
    flexWorkerId,
  }: AddAndApproveAccreditationParams): Promise<void> {
    const formData = getWorkerPayloadFormData({ expirationDate, accreditations });
    await this.hqApiService.create<void>({
      body: formData,
      path: `accreditations/${flexWorkerId}/add-and-approve`,
    });
  }

  public async updateAccreditation(
    flexWorkerId: string,
    certificateId: string,
    expirationDate: Date | undefined
  ): Promise<void> {
    await this.hqApiService.update<void>({
      body: { expirationDate },
      path: `accreditations/${flexWorkerId}/${certificateId}/update`,
    });
  }

  public async rejectAccreditation(flexWorkerId: string, certificateId: string): Promise<void> {
    await this.hqApiService.delete<void>({
      path: `accreditations/${flexWorkerId}/${certificateId}/reject`,
    });
  }
}

function getWorkerPayloadFormData(body: {
  accreditations: AccreditationFormRepresentation[];
  expirationDate: DateTime | null;
}): FormData {
  const formData = new FormData();

  addAccreditationFiles(formData, body.accreditations);

  const accreditations = JSON.stringify(
    body.accreditations?.map((accreditation) => accreditation.id) ?? []
  );

  const bodyWithoutFiles: { [key: string]: string | undefined } = {
    accreditations,
    expirationDate: body.expirationDate ? body.expirationDate.toString() : undefined,
  };

  addFieldsToFormData(formData, bodyWithoutFiles);

  return formData;
}

export { AccreditationsService };
