import AddIcon from '@mui/icons-material/Add';
import { ChangeEvent } from 'react';
import { LwButton } from 'redesign';

type FileUploaderProps = {
  title: string;
  fileType: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
};

const FileUploader = ({ title, fileType, onChange, disabled }: FileUploaderProps) => {
  return (
    <LwButton color="secondary" component="label" startIcon={<AddIcon />} disabled={disabled}>
      {title}
      <input name="file" type="file" accept={fileType} hidden onChange={onChange} />
    </LwButton>
  );
};

export { FileUploader };
