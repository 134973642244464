import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useCallback } from 'react';
import { partnerCounterOfferAlerts, useAlert } from 'shared/components/alerts';
import { useNavigate } from 'react-router-dom';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PARTNER_PATHS } from 'partner/paths';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { useQueryClient } from 'react-query';
import { ShiftClaimData, useCreateShiftCheckout } from './use-create-shift-checkout';
import { getShiftTimespan } from '../utils/get-shift-timespan';

export const useSubmitCheckoutForm = () => {
  const { alertError, alertSuccess } = useAlert();
  const { openDialog, disableDialog, enableDialog, closeDialog } = useDialog();

  const navigate = useNavigate();
  const generatePath = usePartnerRelativePath();
  const addClaimShift = useCreateShiftCheckout();
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    (data: ShiftClaimData) => {
      const startDateTime = data.startDate + 'T' + data.startTime;
      const endDateTime = data.endDate + 'T' + data.endTime;
      const shiftTimespan = getShiftTimespan(startDateTime, endDateTime);
      openDialog(
        {
          title: `Ben je akkoord met de volgende checkout?`,
          content: `${shiftTimespan} met ${data.breakMinutes} min pauze`,
          primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
        },
        () => {
          disableDialog();
          addClaimShift.mutate(data, {
            onSuccess: () => {
              alertSuccess(partnerCounterOfferAlerts.success.hoursSubmitted);
              closeDialog();
              queryClient.invalidateQueries(QUERY_KEYS_PARTNER.checkouts());
              queryClient.invalidateQueries(QUERY_KEYS_PARTNER.notifications());
              navigate(generatePath(PARTNER_PATHS.Checkout));
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          });
        }
      );
    },
    [
      addClaimShift,
      alertError,
      alertSuccess,
      closeDialog,
      disableDialog,
      enableDialog,
      generatePath,
      navigate,
      openDialog,
      queryClient,
    ]
  );

  return onSubmit;
};
