import { projectService as ogpProjectService } from 'ogp/services';
import { projectService as hqProjectService } from 'hq/services';
import { useQuery } from 'react-query';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../../../../query-keys/project';

export const useGetProjectCancellations = (workId: string) => {
  const siteDetectionResult = useSiteDetection();
  const queryFn = getQueryFunction(siteDetectionResult, workId);

  return useQuery(
    SHARED_QUERY_KEYS_PROJECT.workApplicationsForProject(siteDetectionResult),
    queryFn
  );
};

function getQueryFunction(siteDetectionResult: SiteDetectionResult, workId: string) {
  if (siteDetectionResult.isHQ) {
    return async () => await hqProjectService.getProjectCancellations(workId);
  }

  if (siteDetectionResult.isOGP) {
    return async () => await ogpProjectService.getProjectCancellations(workId);
  }

  throw new Error(
    `useGetProjectCancellations: site "${siteDetectionResult}" is neither HQ nor OGP.`
  );
}
