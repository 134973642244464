import { Box, styled } from '@mui/material';
import { DayBox } from './day-box';
import { weekDays } from '../../../utils/date-utils';

const DayTableHeader = () => (
  <DayTableWrapper>
    {weekDays.map((weekDay) => (
      <DayBox day={weekDay} key={weekDay}></DayBox>
    ))}
  </DayTableWrapper>
);

export { DayTableHeader };

const DayTableWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  justifyContent: 'space-between',
}));
