import { FlexWorkersFilters, PaginationResultSizes } from '@types';
import { DEFAULT_LEVEL_FILTER } from './flex-workers.settings';

export const FLEX_WORKERS_INITIAL_PARAMS = {
  flexWorkerId: {
    initialValue: undefined,
    type: 'string',
  },
  level: {
    initialValue: DEFAULT_LEVEL_FILTER,
    type: 'string',
  },
  employmentType: {
    initialValue: 'ALL',
    type: 'string',
  },
  disabled: {
    initialValue: false,
    type: 'boolean',
  },
  filter: {
    initialValue: undefined,
    type: 'string',
  },
  page: {
    initialValue: 0,
    type: 'number',
  },
  size: {
    initialValue: PaginationResultSizes[0],
    type: 'number',
  },
} as const;

export const FLEX_WORKERS_INITIAL_COUNTS = {
  incompleteCount: {
    initialValue: 0,
    type: 'number',
  },
  explorerCount: {
    initialValue: 0,
    type: 'number',
  },
  starterCount: {
    initialValue: 0,
    type: 'number',
  },
  workerCount: {
    initialValue: 0,
    type: 'number',
  },
  candidateCount: {
    initialValue: 0,
    type: 'number',
  },
  disabledCount: {
    initialValue: 0,
    type: 'number',
  },
  unfilteredCount: {
    initialValue: 0,
    type: 'number',
  },
} as const;

export type FlexWorkerParams = {
  flexWorkerId?: string;
  level: FlexWorkersFilters['level'];
  employmentType: FlexWorkersFilters['employmentType'];
  disabled: FlexWorkersFilters['disabled'];
  filter?: string;
  page: number;
  size: number;
};

export const isLevelTypeFilter = (
  level: string | undefined
): level is FlexWorkersFilters['level'] => {
  const flexWorkerLevels: FlexWorkersFilters['level'][] = [
    'ALL',
    'EXPLORER',
    'STARTER',
    'WORKER',
    'CANDIDATE',
    'INCOMPLETE',
  ];
  return flexWorkerLevels.includes(level as FlexWorkersFilters['level']);
};

export const isEmploymentTypeFilter = (
  employmentType: string | undefined
): employmentType is FlexWorkersFilters['employmentType'] => {
  const employmentTypes: FlexWorkersFilters['employmentType'][] = [
    'ALL',
    'FREELANCER',
    'TEMPWORKER',
  ];
  return employmentTypes.includes(employmentType as FlexWorkersFilters['employmentType']);
};

export type SetFlexWorkerParams = (params: Partial<FlexWorkerParams>) => void;
