export const themeConfig = {
  backgrounds: {
    main: '#FFFFFF',
  },
  blocks: {
    height: '210px',
    heightBigger: '260px',
  },
  icons: {
    height: '24px',
    width: '24px',
  },
} as const;
