import React from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { useSessionStorage } from './use-session-storage';

const [Provider, usePartnerSidebarProvider] = ProviderFactory<{
  isOpen: boolean;
  open: () => void;
  close: () => void;
}>('SidebarContext must be used within a SidebarProvider');

const PartnerSidebarProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  // FIXME this shouldn't be needed if we were using the proper routing structure
  const [isOpen, setValue] = useSessionStorage('sidebar-open', true);

  const open = React.useCallback(() => {
    setValue(true);
  }, [setValue]);

  const close = React.useCallback(() => {
    setValue(false);
  }, [setValue]);

  return <Provider value={{ isOpen, open, close }}>{children}</Provider>;
};

export { PartnerSidebarProvider, usePartnerSidebarProvider };
