import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService } from 'hq/services';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { useQuery } from 'react-query';

export const useHqGetShift = (shiftId: string | null) => {
  const queryFn = async () => await shiftService.getShift(shiftId!);
  const queryKey = QUERY_KEYS_HQ.shift(shiftId!);

  return useQuery<Promise<ShiftDetailResponse>, Error, ShiftDetailResponse>({
    queryKey,
    queryFn,
    enabled: shiftId != null,
  });
};
