import {
  GetProjectPlanningProjectsResponse,
  HqProjectCreatePayload,
  HqProjectCreateResponse,
  HqProjectEditPayload,
  ProjectPlanningParams,
} from './project-planning-service.types';
import { HqApiService } from './hqApiService';

class ProjectPlanningService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getProjectPlanningProjects({
    toDate,
    company,
    branchOffice,
    department,
    pageParam,
    pageSizeParam,
    jobType,
    filledStatus,
    hasApplicants,
  }: ProjectPlanningParams) {
    const response = await this.hqApiService.get<GetProjectPlanningProjectsResponse>({
      params: {
        page: pageParam,
        pageSize: pageSizeParam,
        toDate: toDate?.toISO(),
        companyId: company,
        branchOfficeId: branchOffice,
        departmentId: department,
        jobType: jobType,
        filledStatus,
        hasApplicants,
      },
      path: 'project-planning/projects',
    });

    return response;
  }

  public async createProject(body: HqProjectCreatePayload) {
    const { id } = await this.hqApiService.create<HqProjectCreateResponse>({
      path: `project-planning/projects`,
      body,
    });
    return id;
  }

  public async editProject(projectId: string, body: HqProjectEditPayload) {
    await this.hqApiService.update({
      path: `project-planning/projects/${projectId}`,
      body,
    });
  }

  public async archiveProject(projectId: string) {
    await this.hqApiService.delete({
      path: `project-planning/projects/${projectId}`,
    });
  }
}

export { ProjectPlanningService };
