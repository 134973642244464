import { useGetBranchOfficesAsList } from 'hq/hooks/queryHooks/branch-offices/use-get-branch-offices';
import { Box } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { BranchOfficeCard } from './branch-office-card/branch-office-card';

interface BranchOfficeListProps {
  companyId: string;
}
const BranchOfficeList = ({ companyId }: BranchOfficeListProps) => {
  const branchOfficesQuery = useGetBranchOfficesAsList({ companyId, includeArchived: false });

  if (branchOfficesQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficesQuery} />;
  }

  return (
    <Box mt={4} data-testid="branch-office-list">
      {branchOfficesQuery.data.map((branchOffice, index) => (
        <BranchOfficeCard branchOffice={branchOffice} key={index} />
      ))}
    </Box>
  );
};

export { BranchOfficeList };
