export enum VAT {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NONE = 'NONE',
  REVERSE = 'REVERSE',
}

function vatLabel(value: VAT) {
  switch (value) {
    case VAT.HIGH:
      return 'Hoog';
    case VAT.LOW:
      return 'Laag';
    case VAT.REVERSE:
      return 'BTW verlegging';
    default:
      return 'Geen';
  }
}

export const vatListOptions = (includeReverseVat = false) => {
  return Object.keys(VAT)
    .filter((key) => (includeReverseVat ? key === key : key !== VAT.REVERSE))
    .map((item) => ({
      value: item,
      label: vatLabel(item as VAT),
    }));
};

export { vatLabel };
