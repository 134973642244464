import { Box, styled, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  CheckboxOption,
  FilterModal,
  FormCheckbox,
  FormCheckboxGroup,
  FormSelectGroup,
} from 'redesign';
import {
  FlexWorkersForShiftFilterOptions,
  FlexWorkersForShiftFiltersStrings,
} from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';

import { EmploymentType } from 'types/common/employment-type.types';
import {
  parseFlexWorkersForShiftFilters,
  stringifyFlexWorkersForShifts,
} from '../../../../flex-workers-for-work-table/utils/filtering-utils';
import { useFeatureFlagContext } from '../../../../../contexts/feature-flag-provider';
import { useHqAdmin, useSiteDetection } from '../../../../../hooks';

interface Props {
  handleOpeneningFilters: (val: boolean) => void;
  isOpen: boolean;
  filterValues: FlexWorkersForShiftFilterOptions;
  setFilterValues: (props: FlexWorkersForShiftFilterOptions) => void;
  allowedEmploymentTypes: EmploymentType[];
}

export const ShiftAddFlexWorkerFilters = (props: Props) => {
  const { handleOpeneningFilters, isOpen, setFilterValues, filterValues, allowedEmploymentTypes } =
    props;

  const filterValuesStrings = stringifyFlexWorkersForShifts(filterValues);
  const defaultFilterValues: FlexWorkersForShiftFiltersStrings = useMemo(
    () => ({
      flexPoolOnly: filterValuesStrings.flexPoolOnly ?? undefined,
      maxDistance: filterValuesStrings.maxDistance ?? '0',
      isAvailable: filterValuesStrings.isAvailable ?? undefined,
      workedForDepartment: filterValuesStrings.workedForDepartment ?? undefined,
      hasCertificate: filterValuesStrings.hasCertificate ?? undefined,
      availabilityStatus: filterValuesStrings.availabilityStatus ?? '0',
      isFreelancer: filterValuesStrings.isFreelancer ?? undefined,
      isTempWorker: filterValuesStrings.isTempWorker ?? undefined,
    }),
    [filterValuesStrings]
  );
  const emptyFilterValues: FlexWorkersForShiftFiltersStrings = useMemo(
    () => ({
      flexPoolOnly: '',
      maxDistance: '0',
      isAvailable: '',
      workedForDepartment: '',
      hasCertificate: '',
      availabilityStatus: '0',
      isFreelance: '',
      isTempWork: '',
    }),
    []
  );
  const featureFlags = useFeatureFlagContext();
  const isHqAdmin = useHqAdmin();
  const { site } = useSiteDetection();

  const form = useForm<FlexWorkersForShiftFiltersStrings>({
    defaultValues: { ...defaultFilterValues },
  });

  const handleCancel = useCallback(() => {
    form.reset(defaultFilterValues);
    handleOpeneningFilters(false);
  }, [form, handleOpeneningFilters]);

  const handleClear = useCallback(() => {
    form.reset(emptyFilterValues);
  }, [form]);

  const handleSubmit = () => {
    const res = form.getValues();
    if (allowedEmploymentTypes.length >= 2 && !res.isFreelancer && !res.isTempWorker) {
      // At least one employment type should be checked
      form.setError('isFreelancer', {
        type: 'minChecked',
        message: 'Selecteer er minstens een',
      });
      return;
    }
    setFilterValues(
      parseFlexWorkersForShiftFilters({
        flexPoolOnly: res.flexPoolOnly?.[0],
        maxDistance: res.maxDistance,
        isAvailable: res.isAvailable?.[0],
        workedForDepartment: res.workedForDepartment?.[0],
        hasCertificate: res.hasCertificate?.[0],
        availabilityStatus: res.availabilityStatus,
        isFreelancer: res.isFreelancer === undefined ? undefined : res.isFreelancer ? '1' : '0',
        isTempWorker: res.isTempWorker === undefined ? undefined : res.isTempWorker ? '1' : '0',
      })
    );
    handleOpeneningFilters(false);
  };

  const maxDistanceOptions = [
    { label: 'Alle afstanden', value: '0' },
    { label: '< 5km', value: '5' },
    { label: '< 10km', value: '10' },
    { label: '< 30km', value: '30' },
    { label: '< 50km', value: '50' },
    { label: '< 75km', value: '75' },
    { label: '< 100km', value: '100' },
  ];

  const flexpoolCheckboxes: CheckboxOption[] = [
    { label: 'Ja', value: '1' },
    { label: 'Nee', value: '0' },
  ];

  const availabilityStatusOptions =
    featureFlags.allowAvailability && (isHqAdmin || site === 'hq')
      ? [
          { value: '0', label: '-' },
          { value: '1', label: 'Beschikbaar' },
          { value: '2', label: 'Volledig en mogelijk beschikbaar' },
        ]
      : [
          { value: '0', label: '-' },
          { value: '2', label: 'Volledig en mogelijk beschikbaar' },
        ];

  const workedForDepartment: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  const hasCertificateCheckboxes: CheckboxOption[] = [{ label: 'Ja', value: '1' }];

  return isOpen ? (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <FilterModal
          handleCancel={handleCancel}
          handleClear={handleClear}
          isOpen={isOpen}
          handleSubmit={handleSubmit}
        >
          <FilterGrid>
            <FormCheckboxGroup
              label="In de flexpool"
              name="flexPoolOnly"
              items={flexpoolCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormSelectGroup
              label="Maximale afstand"
              name={'maxDistance'}
              control={form.control}
              options={maxDistanceOptions}
            />
            <FormSelectGroup
              label="Beschikbaarheid"
              name={'availabilityStatus'}
              control={form.control}
              options={availabilityStatusOptions}
              informationText="Beschikbaarheid van de flexwerker"
            />
            <FormCheckboxGroup
              label="Heeft eerder voor deze afdeling gewerkt"
              name="workedForDepartment"
              items={workedForDepartment}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            <FormCheckboxGroup
              label="In bezit van certificaten"
              name="hasCertificate"
              items={hasCertificateCheckboxes}
              control={form.control}
              display="flex"
              maxChecked={1}
            />
            {allowedEmploymentTypes.length >= 2 && <EmploymentTypeCheckboxes />}
          </FilterGrid>
        </FilterModal>
      </form>
    </FormProvider>
  ) : null;
};

const EmploymentTypeCheckboxes = () => {
  const form = useFormContext<FlexWorkersForShiftFiltersStrings>();
  return (
    <>
      <Box>
        <Typography variant="body1">Dienstverband</Typography>
        <StyledHelperText hasError={form.formState.errors.isFreelancer?.message !== undefined}>
          Selecteer er minstens een
        </StyledHelperText>
      </Box>
      <Box>
        {[
          { name: 'isFreelancer' as const, label: 'Zelfstandige' },
          { name: 'isTempWorker' as const, label: 'Uitzendkracht' },
        ].map((item) => (
          <FormCheckbox
            label={item.label}
            key={item.name}
            name={item.name}
            onChange={(val) => {
              if (val) {
                form.clearErrors('isFreelancer');
              }
            }}
            control={form.control}
          />
        ))}
      </Box>
    </>
  );
};

const FilterGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content auto',
  gap: theme.spacing(7),
  alignItems: 'flex-start',
}));

const StyledHelperText = styled('div')<{ hasError: boolean }>(({ theme, hasError }) => ({
  textDecorationLine: hasError ? 'underline' : 'none',
  color: hasError ? theme.palette.lwDanger[100] : 'inherit',
  fontSize: '12px',
}));
