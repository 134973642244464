import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftService } from 'ogp/services';
import { shiftCreateDataResponse2ShiftCreateDataModel } from 'ogp/services/mappers/mappers';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useOgpGetShiftCreateData = () => {
  return useQuery(
    QUERY_KEYS_OGP.getShiftCreateData(),
    async () => await shiftService.getShiftCreateData(),
    {
      cacheTime: CACHE_TIME_LONG,
      staleTime: STALE_TIME_LONG,
      select: shiftCreateDataResponse2ShiftCreateDataModel,
    }
  );
};
