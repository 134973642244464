import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { projectService } from 'ogp/services';
import { ProjectsUpdatePayload } from 'ogp/services/project-service.types';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateProject = () => {
  const mutationFn = async (project: ProjectsUpdatePayload) =>
    await projectService.updateProject(project);
  const queryClient = useQueryClient();
  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.allProjects());
    },
  });
};
