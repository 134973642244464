import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';

export const useGetPartnerInboxMessages = () => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.partnerInboxMessages(),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerInboxService.getPartnerInboxMessages({
        pageParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
