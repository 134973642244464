import { jobTypeService as jobTypeServiceHQ } from 'hq/services/index';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { QueryKey, useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';
import { jobTypeService as jobTypeServiceOGP } from 'ogp/services';
import { RequiredCertificatesForJobType } from 'ogp/services/job-type-service.types';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export type FlexPoolFilters = {
  departmentId?: string;
  jobId?: string;
  shiftId?: string;
};

export type GetFlexPoolsResponse = {
  id: string;
  name: string;
  description?: string | undefined;
  departmentId: string;
  departmentName: string;
  companyId: string;
  companyName: string;
}[];

export const useGetRequiredCertificates = (jobTypeId: string) => {
  const { isHQ, isOGP } = useSiteDetection();

  let queryFn: () => Promise<RequiredCertificatesForJobType>;
  let queryKey: QueryKey;
  if (isHQ) {
    queryFn = async () => await jobTypeServiceHQ.getRequiredCertificates(jobTypeId);
    queryKey = QUERY_KEYS_HQ.requiredCertificatesForJobType(jobTypeId);
  } else if (isOGP) {
    queryFn = async () => await jobTypeServiceOGP.getRequiredCertificates(jobTypeId);
    queryKey = QUERY_KEYS_OGP.requiredCertificatesForJobType(jobTypeId);
  } else {
    throw new Error('Site is not supported');
  }

  return useQuery(queryKey, queryFn, {
    keepPreviousData: true,
  });
};
