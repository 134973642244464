import { FlexWorkerFlexPoolsData } from 'shared/components/dialogs/flex-pools-for-flex-worker-dialog/hooks/ogp/use-ogp-update-flex-worker-flex-pools';
import { FlexWorkersForProjectFilters } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { FlexWorkerProfileData } from '@types';
import { GetFlexWorkersForShiftPlanningFilterProps } from 'shared/components/shift-detail-modal/query-hooks/hq/use-get-flex-workers-for-shift-planning.types';
import { OgpApiService } from './ogp-api-service';
import { GetAllFlexWorkersResponse } from './flex-worker-service.types';

class FlexWorkerService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Update the flexPools of a specific flexworker.
   */
  public updateFlexPoolForFlexWorker = async (queryData: FlexWorkerFlexPoolsData) => {
    const { flexWorkerId, ...otherData } = queryData;

    const response = await this.ogpApiService.update({
      path: `flex-workers/${flexWorkerId}/flex-pools`,
      body: otherData,
    });
    return response;
  };

  public async getAllFlexWorkersForShift(props: GetFlexWorkersForShiftPlanningFilterProps) {
    return await this.ogpApiService.get<GetAllFlexWorkersResponse>({
      path: `flex-workers/in-relation-to-shift/${props.shiftId}`,
      params: { page: props.page, pageSize: props.pageSize, ...props.filters },
    });
  }

  public async getAllFlexWorkersForProject(props: {
    projectId: string;
    page: number;
    pageSize: number;
    filters?: FlexWorkersForProjectFilters;
  }) {
    return await this.ogpApiService.get<GetAllFlexWorkersResponse>({
      path: `flex-workers/in-relation-to-project/${props.projectId}`,
      params: { page: props.page, pageSize: props.pageSize, ...props.filters },
    });
  }

  /**
   * Get the FlexWorker Profile cover for a FlexWorker
   */
  public getFlexWorkerProfileCover = async (flexWorkerId: string) => {
    const response = await this.ogpApiService.get<FlexWorkerProfileData>({
      path: `flex-workers/profile-cover/${flexWorkerId}`,
    });
    return response;
  };
}

export { FlexWorkerService };
