import { Box, Divider, Grid, styled } from '@mui/material';
import React from 'react';
import { CompanyEmploymentTypes, TakeDefined } from '@types';
import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { useFormContext } from 'react-hook-form';
import { useGetJobFormData } from './use-get-temp-work-job-form-elements';
import { JobFormState } from './use-get-shared-job-form-elements';
import { JobTypeRequiredCertificatesWrapper } from '../../job-type-required-certificates-list/job-type-required-certificates-wrapper';

export const JobForm = ({
  employmentType,
  jobCreateData,
  formState,
}: {
  employmentType: CompanyEmploymentTypes;
  jobCreateData: TakeDefined<ReturnType<typeof useGetJobCreateData>['data']>;
  formState: JobFormState;
}) => {
  const inputsToShow = useGetJobFormData(formState, jobCreateData, employmentType);
  const { watch } = useFormContext();
  const jobTypeIdWatch = watch('jobTypeId');

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" height="100%">
          <form aria-label="Job form">
            <Grid container item spacing={4} xs={12}>
              {Object.entries(inputsToShow).map(([key, node]) => (
                <React.Fragment key={key}>{node}</React.Fragment>
              ))}
            </Grid>
          </form>
          <Box>
            <StyledDivider orientation="vertical" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <JobTypeRequiredCertificatesWrapper jobTypeId={jobTypeIdWatch} />
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
