import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { branchOfficeService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

type BranchOfficeMutationOptions = UseMutationOptions<unknown, unknown, string>;

export const useArchiveBranchOffice = (options?: BranchOfficeMutationOptions) => {
  const mutationFn = async (branchOfficeId: string) =>
    await branchOfficeService.archiveBranchOffice(branchOfficeId);
  const invalidateQuery = useInvalidateQuery();
  const mutationOptions: BranchOfficeMutationOptions = {
    onSettled: () => {
      invalidateQuery(QUERY_KEYS_HQ.branchOffices());
    },
    ...options,
  };

  return useMutation(mutationFn, mutationOptions);
};
