import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { useUserRoles } from 'ogp/components/views/sidebar/use-user-roles';
import React from 'react';

const UserRoles = ({ roles }: { roles: ReturnType<typeof useUserRoles> }) => {
  if (!roles) {
    return null;
  }

  const { branchOfficesWithDepartmentRoles } = roles;

  return (
    <>
      {branchOfficesWithDepartmentRoles.map(({ branchOffice }, index) => (
        <DepartmentBox
          key={branchOffice.id}
          bgcolor={index % 2 === 0 ? 'transparent' : 'rgba(242, 242, 242, 0.6)'}
        >
          <Typography variant="subtitle1" marginBottom="10px">
            {branchOffice.name}{' '}
          </Typography>
          <List dense disablePadding>
            {branchOffice.departmentRoles.map((departmentRole) => (
              <React.Fragment key={departmentRole.id}>
                <Box component={ListItem} py={0.5} pl={2}>
                  <Typography>{departmentRole.name}</Typography>
                </Box>
                <Box pl={2} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                  {departmentRole.roles.map((role) => (
                    <DepartmentRoles key={role}>{role}</DepartmentRoles>
                  ))}
                </Box>
              </React.Fragment>
            ))}
          </List>
        </DepartmentBox>
      ))}
    </>
  );
};

const DepartmentBox = styled(Box)(({ theme }) => ({
  paddingLeft: `${theme.spacing(3)}`,
  paddingTop: '7px',
  paddingBottom: `${theme.spacing(3)}`,
}));

const DepartmentRoles = styled(Typography)(() => ({
  marginRight: '10px',
  marginTop: '8px',
  color: 'rgba(119, 119, 119, 1)!important',
  border: '1px solid rgba(119, 119, 119, 1)',
  paddingRight: '8px',
  paddingLeft: '8px',
  borderRadius: '25px',
}));

export { UserRoles };
