import { ContextMenuItem, Table } from 'redesign';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { PartnerClaimantsQueryResponse } from 'partner/shared/services/partner-planner-service.types';
import { IconCloseCircle } from 'assets/img';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { QueryObserverSuccessResult, useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import { getPlannerModalTableDefinition } from './definition/use-get-planner-modal-table-definition';
import { getPlannerModalTableData } from './definition/get-planner-modal-table-data';
import { usePartnerGetClaimants } from '../hooks/use-partner-get-claimants';
import { usePartnerCancelShift } from '../hooks/use-partner-cancel-shift';

export const PlannerClaimantTableDataProvider = ({ shiftId }: { shiftId: string }) => {
  const query = usePartnerGetClaimants(shiftId);

  if (query.status !== 'success') {
    return (
      <RenderUnsettledUI
        data={query}
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
      />
    );
  }

  return <PlannerClaimantTable query={query} shiftId={shiftId} />;
};

const PlannerClaimantTable = ({
  query,
  shiftId,
}: {
  query: QueryObserverSuccessResult<PartnerClaimantsQueryResponse, unknown>;
  shiftId: string;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const queryClient = useQueryClient();
  const flatData = query.isSuccess && query.data.items;
  const tableData = useMemo(() => getPlannerModalTableData(flatData), [flatData]);
  const columns = getPlannerModalTableDefinition();
  const claimShiftMutation = usePartnerCancelShift();

  const handleCancelShiftClaim = (shiftClaimId: string, shiftId: string) => {
    claimShiftMutation.mutate(
      { shiftClaimId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerWorkers());
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftPlanning());
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftPlanningShiftDetail(shiftId));
          alertSuccess(shiftAlerts.success.unsubscribed);
        },
        onError: (e) => alertError(e),
      }
    );
  };

  const getRowActions = useCallback(({ shiftClaimId }) => {
    return [
      <ContextMenuItem
        onClick={() => handleCancelShiftClaim(shiftClaimId, shiftId)}
        key="shiftClaimId"
      >
        <IconCloseCircle />
        Annuleren
      </ContextMenuItem>,
    ];
  }, []);

  return (
    <Table
      stickyHeader
      columns={columns}
      data={tableData}
      getRowActions={getRowActions}
      data-testid="planner-claimant-table"
    />
  );
};
