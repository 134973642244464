import { PrebuiltBlock } from 'redesign';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';

type Certificate = {
  id: string;
  name: string;
  fileUrl?: string;
};

export enum EmploymentType {
  FREELANCER = 'FREELANCER',
  TEMPWORKER = 'TEMPWORKER',
}

export type GetPartnerWorkersResponse = {
  totalRows: number;
  nextPage: number;
  items: PrebuiltBlock[];
};

export type GetPartnerWorkerResponse = {
  id: string;
  firstName: string;
  lastName: string;
  infix: string | undefined;
  email: string;
  phone: string;
  accreditations: Certificate[];
  employmentType: EmploymentType;
  employmentId: string | null;
};

export type CreatePartnerWorkerPayload = {
  firstName: string;
  infix: string | undefined;
  lastName: string;
  email: string;
  phone: string;
  accreditations: AccreditationFormRepresentation[] | undefined;
  employmentType: EmploymentType;
  employmentId: string | undefined;
};

export type UpdatePartnerWorkerPayload = {
  firstName: string | undefined;
  infix: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  accreditations: AccreditationFormRepresentation[] | undefined;
  employmentType: EmploymentType | undefined;
  employmentId: string | undefined;
};
