import { flexPoolService } from 'ogp/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';

const useGetFlexPool = (flexPoolId: string = '') => {
  const queryFn = async () => await flexPoolService.getFlexPool(flexPoolId);

  return useQuery(QUERY_KEYS_OGP.flexPool(flexPoolId), queryFn, {
    enabled: flexPoolId.length > 0,
  });
};

export { useGetFlexPool };
