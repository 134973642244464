import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions } from 'react-query';

type MutationData = string | undefined;

export const useApproveFlexWorkerIdentityCheck = (
  identityVerificationId: string = '',
  options: UseMutationOptions<unknown, unknown, MutationData>
) => {
  const mutationFn = async (bsn: string | undefined) =>
    await flexWorkerService.approveFlexWorkerIdCheck(identityVerificationId, bsn);
  return useMutation(mutationFn, options);
};
