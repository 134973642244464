import { Box, styled } from '@mui/material';

interface LabelProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  required?: boolean;
  id?: string;
}

const StyledLabel = ({ children, icon, required, id }: LabelProps) => {
  return (
    <StyledLabelBox {...(id ? { id } : undefined)}>
      <StyledLabelText>{children}</StyledLabelText>
      {required && <StyledAsteriskBox>*</StyledAsteriskBox>}
      {icon}
    </StyledLabelBox>
  );
};

const StyledLabelBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  height: '24px',
}));

const StyledLabelText = styled(Box)(() => ({
  lineHeight: '20px',
  margin: 0,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  color: 'rgba(0, 0, 0, 0.6)',
}));

const StyledAsteriskBox = styled(Box)(() => ({
  marginLeft: '4px',
  height: '24px',
  display: 'flex',
  alignItems: 'flex-end',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export { StyledLabel };
