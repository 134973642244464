import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from '../../../query-keys';
import { noShowReasonService } from '../../../services';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from '../../../../shared/react-query/query-defaults';
import { NoShowReason } from '../../../services/noShowReasonService';

const useGetNoShowReasons = () => {
  return useQuery<NoShowReason[], Error>(
    QUERY_KEYS_OGP.noShowReasons(),
    async () => await noShowReasonService.getList(),
    {
      cacheTime: CACHE_TIME_LONG,
      staleTime: STALE_TIME_LONG,
    }
  );
};

export { useGetNoShowReasons };
