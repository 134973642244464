import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService } from 'hq/services';
import { OGPMultiplyShiftPayload } from 'ogp/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftMutationOptions = UseMutationOptions<unknown, unknown, OGPMultiplyShiftPayload, unknown>;

export const useHqMultiplyShift = (options?: ShiftMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: OGPMultiplyShiftPayload) => await shiftService.multiplyShift(params),
    {
      ...options,
      onSuccess: (_, variables, context) => {
        queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
        queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
        options?.onSuccess && options.onSuccess(null, variables, context);
      },
    }
  );
};
