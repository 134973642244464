import { DateTime } from 'luxon';
import { useUpdatePlanningPath } from './planning';

const usePlanningUrls = (date: DateTime) => {
  const updatePlanningPath = useUpdatePlanningPath();
  const previousWeekUrl = updatePlanningPath({ date: date.minus({ weeks: 1 }).startOf('week') });
  const nextWeekUrl = updatePlanningPath({ date: date.plus({ weeks: 1 }).startOf('week') });
  const todayUrl = updatePlanningPath({ date: DateTime.now() });

  return { previousWeekUrl, nextWeekUrl, todayUrl };
};

export { usePlanningUrls };
