import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { billingEntityService } from 'ogp/services';
import { useQuery } from 'react-query';
import { FirstParamOfFn } from '@types';

type Params = FirstParamOfFn<typeof useGetBillingEntity>;
type Response = Awaited<ReturnType<typeof billingEntityService.getBillingEntity>>;

const useGetBillingEntity = <TData = Response>(id: string, select?: (data: Response) => TData) => {
  const query = useQuery(
    QUERY_KEYS_OGP.billingEntity(id),
    async () => await billingEntityService.getBillingEntity(id),
    {
      select,
    }
  );

  return query;
};

const useGetBillingEntityAsDetail = (params: Params) => {
  const result = useGetBillingEntity(params, (data) => {
    const fees = groupBy(
      data.fees.map(({ hourlyRateCents, ...fee }) => ({
        ...fee,
        hourlyRate: hourlyRateCents / 100,
        startDate: DateTime.fromISO(fee.startDate).toFormat('yyyy-MM-dd'),
        endDate: fee.endDate ? DateTime.fromISO(fee.endDate).toFormat('yyyy-MM-dd') : '',
      })),
      (fee) => fee.feeType
    );

    return {
      ...data,
      fees,
    };
  });
  return result;
};

export { useGetBillingEntity, useGetBillingEntityAsDetail };
