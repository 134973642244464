import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { getPlatformClient } from 'shared/utils/platform-utils';

type FooterInformation = {
  phoneNumber: string;
  client: string;
  termsOfUse: string;
  privacyStatement: string;
};

const SignInFooter = () => {
  const styles = getStyles();

  return (
    <div className={styles.contactWrapper}>
      <Typography
        variant="body1"
        display="block"
        className={styles.contact}
        marginBottom="32px"
        marginTop="10px"
      >
        Door aan te melden ga je akkoord met de{' '}
        <a href={termsOfUse} target="_blank" rel="noreferrer">
          gebruiksvoorwaarden
        </a>{' '}
        en de{' '}
        <a href={privacyStatement} target="_blank" rel="noreferrer">
          privacyverklaring.
        </a>
      </Typography>
      <Typography variant="body1" display="block" className={styles.contact}>
        Neem contact op met {client} support
      </Typography>
      <Typography variant="body1" display="block" className={styles.contact}>
        {phoneNumber} als je een probleem hebt tijdens het inloggen.
      </Typography>
    </div>
  );
};

const footerInformationBasedOnClient: Record<
  ReturnType<typeof getPlatformClient>,
  FooterInformation
> = {
  LEVELWORKS: {
    phoneNumber: '085 2089605',
    client: 'Level.works',
    termsOfUse: 'https://nl.level.works/legal/gebruikersvoorwaarden-opdrachtgevers',
    privacyStatement: 'https://level.works/privacy-statement/',
  },
  AETHON: {
    phoneNumber: '088 0351525',
    client: 'Aelio',
    termsOfUse: 'https://aelio.nl/gebruikersvoorwaarden-opdrachtgevers/',
    privacyStatement: 'https://aelio.nl/privacy/',
  },
  HOLLANDWORX: {
    phoneNumber: '085 3037286',
    client: 'Hollandworx',
    termsOfUse: 'https://doc.hollandworx.nl/gebruikersvoorwaarden-opdrachtgever/',
    privacyStatement: 'https://doc.hollandworx.nl/privacy-statement/',
  },
};

const platformClient = getPlatformClient();
const { phoneNumber, termsOfUse, privacyStatement, client } =
  footerInformationBasedOnClient[platformClient];

const getStyles = makeStyles((theme: Theme) => ({
  contactWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(3)} 0 0 0`,
  },
  contact: {
    color: grey['500'],
  },
}));

export { SignInFooter };
