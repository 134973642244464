import { Box, styled, Typography } from '@mui/material';
import { IconCalendar, IconClock, IconPause } from 'assets/img';
import { useFeatureFlagContext } from '../../../../../shared/contexts/feature-flag-provider';

type Props = {
  header: string;
  backgroundColor?: string;
  data: {
    startDate: ChangeableProposalProp;
    shiftStart: ChangeableProposalProp;
    shiftEnd: ChangeableProposalProp;
    breakMinutes: ChangeableProposalProp;
    expenses: (ChangeableProposalProp & { name: string })[];
    shiftDuration: ChangeableProposalProp;
  };
};

type ChangeableProposalProp = {
  value: string;
  hasChanged?: boolean;
};

export const Proposal = ({ header, data, backgroundColor }: Props) => {
  const { allowExpenses } = useFeatureFlagContext();

  const { startDate, shiftStart, shiftEnd, breakMinutes, shiftDuration, expenses } = data;
  const iconProps = {
    width: 16,
    height: 16,
  };
  return (
    <Box mb={5} mt={5} aria-label={header}>
      <Box pb={1}>{header}</Box>
      <ProposalDetail bgcolor={backgroundColor}>
        <ProposalUpperFields>
          <FieldWithIcon>
            <IconCalendar {...iconProps} />
            <ChangeableField {...startDate} />
          </FieldWithIcon>
          <FieldWithIcon>
            <IconClock {...iconProps} />
            <Typography>
              <ChangeableField {...shiftStart} /> - <ChangeableField {...shiftEnd} />
            </Typography>
          </FieldWithIcon>
          <FieldWithIcon>
            <IconPause {...iconProps} />
            <Typography>
              Pauze <ChangeableField {...breakMinutes} /> mins
            </Typography>
          </FieldWithIcon>
        </ProposalUpperFields>
        {allowExpenses ? (
          <ProposalUpperFields>
            {expenses.map(({ name, ...rest }) => (
              <Typography key={name}>
                {name}: <ChangeableField {...rest} />
              </Typography>
            ))}
          </ProposalUpperFields>
        ) : null}
        <Typography variant="h6">
          <ChangeableField {...shiftDuration} />
        </Typography>
      </ProposalDetail>
    </Box>
  );
};

const ChangeableField = ({ value, hasChanged }: ChangeableProposalProp) => {
  return (
    <Box component="span" style={{ color: hasChanged ? 'red' : 'black' }}>
      {value}
    </Box>
  );
};

const ProposalDetail = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
}));

const ProposalUpperFields = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: theme.spacing(5),
  marginBottom: theme.spacing(3),
}));

const FieldWithIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
