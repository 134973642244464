import { DateTime } from 'luxon';
import { PARTNER_PATHS } from 'partner/paths';
import { InboxFiltersParams } from 'partner/shared/components/filter-form/inbox-filter-form.types';
import { useCallback, useMemo } from 'react';
import { useExternalParams } from 'shared/hooks';
import { useQueryParams } from 'shared/hooks/useQueryParams';

export type PartnerCheckoutState = 'counterOffer' | 'workedShifts';

type PartnerCheckoutsFilters = {
  checkoutState: PartnerCheckoutState;
  fromDate?: DateTime;
  toDate?: DateTime;
  companies?: string[];
  jobs?: string[];
  workers?: string[];
};

const initialValues = {
  checkoutState: {
    initialValue: 'workedShifts',
    type: 'string',
  },
  fromDate: {
    initialValue: undefined,
    type: 'date',
  },
  toDate: {
    initialValue: undefined,
    type: 'date',
  },
  companies: {
    initialValue: undefined,
    type: 'string',
  },
  jobs: {
    initialValue: undefined,
    type: 'string',
  },
  workers: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

function verifyExternalCheckoutState(checkoutState?: string): PartnerCheckoutState {
  return checkoutState === 'counterOffer' ? 'counterOffer' : 'workedShifts';
}

const queryParams = PARTNER_PATHS.Checkout.queryParams;

const usePartnerCheckoutsFiltersState = () => {
  const [params, setParams] = useExternalParams(initialValues);
  const queryString = useQueryParams();

  const filters: PartnerCheckoutsFilters = {
    checkoutState: verifyExternalCheckoutState(params.checkoutState),
    fromDate: params.fromDate,
    toDate: params.toDate,
    companies: params.companies ? JSON.parse(params.companies) : [],
    jobs: params.jobs ? JSON.parse(params.jobs) : [],
    workers: params.workers ? JSON.parse(params.workers) : [],
  };

  const applyFiltersWithoutState = useCallback(
    (filters: InboxFiltersParams) => {
      const filterParams = {
        ...filters,
        companies: filters.companies ? JSON.stringify(filters.companies) : undefined,
        jobs: filters.jobs ? JSON.stringify(filters.jobs) : undefined,
        workers: undefined,
      };
      setParams(filterParams);
    },
    [setParams]
  );

  const applyFilters = useCallback(
    (filters: InboxFiltersParams) => {
      applyFiltersWithoutState(filters);
    },
    [applyFiltersWithoutState]
  );

  const activeFilters: InboxFiltersParams = useMemo(() => {
    const fromDateStr = queryParams ? queryString.get(queryParams.fromDate) : null;
    const toDateStr = queryParams ? queryString.get(queryParams.toDate) : null;
    const companiesStr = queryParams ? queryString.get(queryParams.companies) : null;
    const jobsStr = queryParams ? queryString.get(queryParams.jobs) : null;
    const workersStr = queryParams ? queryString.get(queryParams.workers) : null;

    const filters: InboxFiltersParams = {
      fromDate: fromDateStr ? DateTime.fromISO(fromDateStr) : undefined,
      toDate: toDateStr ? DateTime.fromISO(toDateStr) : undefined,
      companies: companiesStr ? JSON.parse(companiesStr) : [],
      jobs: jobsStr ? JSON.parse(jobsStr) : [],
      workers: workersStr ? JSON.parse(workersStr) : [],
    };

    return filters;
  }, [queryString]);

  const nrOfActiveFilters = useMemo(() => {
    let count = 0;
    if (activeFilters.companies && activeFilters.companies.length === 1) {
      count++;
    }
    if (activeFilters.jobs && activeFilters.jobs.length) {
      count++;
    }

    if (activeFilters.fromDate) {
      count++;
    }

    return count;
  }, [activeFilters]);

  return {
    filters,
    activeFilters,
    applyFilters,
    clear: () => null,
    nrOfActiveFilters,
  };
};

export { usePartnerCheckoutsFiltersState };
