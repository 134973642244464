import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Calendar } from 'react-multi-date-picker';
import { useCalendarDefaults } from 'shared/hooks/useCalendarDefaults';
import { isShiftClaimState, stateLabels } from 'shared/utils/shiftClaimState';
import { getFilterClasses, LwButton, LwCheckbox, LwMultiSelectUncontrolled } from 'redesign';
import { useFlexWorkerAutocomplete } from 'ogp/components/views/administration/shared/components/filter-form/use-flexworker-autocomplete';
import { useCalendar } from 'shared/hooks/use-calendar';
import { useGetCheckoutsArchiveFilters } from '../../hooks/use-get-checkouts-archive-filters';
import type { ArchiveFilters as TArchiveFilters } from '../../../../shared/hooks/use-checkouts-archive-page.types';

const CheckoutsArchiveFilters = ({
  filters,
  onCancel,
  onSubmit,
}: {
  filters: TArchiveFilters;
  onCancel: () => void;
  onSubmit: (data: TArchiveFilters) => void;
}) => {
  const [resetKey, hasBeenReset, reset] = useReset();
  const { monthLabels, dayLabels, calendarStyles } = useCalendarDefaults();
  const classes = getClasses();
  const filterClasses = getFilterClasses();
  const { status, data } = useGetCheckoutsArchiveFilters();
  const branchOffices = data?.branchOffices ?? [];
  const flexWorkers = data?.flexWorkers ?? [];
  const states = data?.states ?? [];
  const labeledStates = states.map((s) => ({ label: stateLabels[s], value: s }));
  const optionLabels = useMemo(() => {
    const labels: { [key: string]: string } = {};
    flexWorkers.forEach((i) => (labels[i.id] = i.fullName));
    return labels;
  }, [flexWorkers]);

  const { flexWorkerOptions, resetFlexWorkers, selectFlexWorkers, selectedFlexWorkers } =
    useFlexWorkerAutocomplete({
      available: flexWorkers,
      initial: filters.flexWorkerIds,
    });

  const memoizedSelectedFlexWorkers = useMemo(
    () => selectedFlexWorkers.map((id) => ({ id, name: optionLabels[id] })) ?? [],
    [selectedFlexWorkers, optionLabels]
  );

  const { dateRange, handleDateChange, getDateRangeFrom, handleResetDateRange } = useCalendar({
    fromDate: filters.fromDate,
    toDate: filters.toDate,
  });

  const handleReset = () => {
    handleResetDateRange();
    resetFlexWorkers();
    reset();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const keys = [...formData.keys()];
    const result: TArchiveFilters = {
      departmentIds: keys
        .filter((key) => key.startsWith(formElementNames.department))
        .map((key) => key.replace(formElementNames.department, '')),
      flexWorkerIds: selectedFlexWorkers,
      states: keys
        .filter((key) => key.startsWith(formElementNames.state))
        .map((key) => key.replace(formElementNames.state, ''))
        .filter(isShiftClaimState),
      ...dateRange,
    };

    onSubmit(result);
  };

  const jsx: Record<typeof status, React.ReactElement> = {
    error: (
      <Typography variant="body2" className={filterClasses.errorMessage}>
        De filters konden niet opgehaald worden.
      </Typography>
    ),
    loading: <CircularProgress size={48} className={filterClasses.spinner} />,
    idle: <CircularProgress size={48} className={filterClasses.spinner} />,
    success: (
      <>
        <Typography variant="body1" className={filterClasses.title}>
          Selecteer een of meer vestigingen om enkel checkouts van die vestiging(en) te zien.
        </Typography>
        <FormGroup row>
          {branchOffices.map((branchOffice) => (
            <div className={filterClasses.checkboxGroup} key={`${branchOffice.id}-${resetKey}`}>
              <Typography variant="body1">{branchOffice.name}</Typography>
              {branchOffice.departments.map((department) => (
                <FormControlLabel
                  className={filterClasses.checkboxRow}
                  key={department.id}
                  label={department.name}
                  control={
                    <LwCheckbox
                      name={`${formElementNames.department}${department.id}`}
                      defaultChecked={
                        hasBeenReset ? false : (filters.departmentIds ?? []).includes(department.id)
                      }
                      title={department.name}
                    />
                  }
                />
              ))}
            </div>
          ))}
        </FormGroup>
        <Divider className={filterClasses.divider} />
        <Typography variant="body1" className={filterClasses.title}>
          Selecteer een of meer statussen om enkel checkouts met die status te zien.
        </Typography>
        <FormGroup row>
          {labeledStates.map((state) => (
            <div className={filterClasses.checkboxRow} key={`${state.value}-${resetKey}`}>
              <FormControlLabel
                label={state.label}
                control={
                  <LwCheckbox
                    name={`${formElementNames.state}${state.value}`}
                    defaultChecked={
                      hasBeenReset ? false : (filters.states ?? []).includes(state.value)
                    }
                    title={state.label}
                  />
                }
              />
            </div>
          ))}
        </FormGroup>
        <Divider className={filterClasses.divider} />
        <Typography variant="body1" className={filterClasses.title}>
          Selecteer een of meer flexwerkers om enkel checkouts van die flexwerker(s) te zien.
        </Typography>

        <LwMultiSelectUncontrolled
          value={memoizedSelectedFlexWorkers}
          onChange={(_e, values) => {
            selectFlexWorkers(values.map((i) => i.id));
          }}
          options={flexWorkerOptions}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </>
    ),
  };

  return (
    <form onSubmit={handleSubmit} className={filterClasses.form}>
      <div className={filterClasses.content}>
        <Typography variant="h2" className={filterClasses.title}>
          Filter Checkouts
        </Typography>
        <Calendar
          className={clsx(calendarStyles.calendar, classes.calendarWrapper)}
          weekStartDayIndex={1}
          range
          months={monthLabels}
          weekDays={dayLabels}
          value={getDateRangeFrom(dateRange.fromDate, dateRange.toDate)}
          maxDate={TODAY}
          onChange={handleDateChange}
        />
        <Box flex="1" justifyContent="flex-end">
          <LwButton color="secondary" onClick={handleResetDateRange}>
            Datumselectie wissen
          </LwButton>
        </Box>
        <Divider className={filterClasses.divider} />
        {jsx[status]}
      </div>
      <div className={filterClasses.actions}>
        <Box flexBasis="100%">
          <LwButton color="primary" type="submit">
            Filter
          </LwButton>
        </Box>

        <LwButton color="secondary" onClick={onCancel}>
          Terug
        </LwButton>
        <LwButton color="secondary" onClick={handleReset}>
          Filters wissen
        </LwButton>
      </div>
    </form>
  );
};

const formElementNames = {
  state: 'state-',
  department: 'department-',
};

const TODAY = new Date();

const useReset = () => {
  const [counter, setCounter] = React.useState(0);
  const hasBeenReset = counter > 0;

  const reset = () => {
    setCounter((cur) => cur + 1);
  };

  return [counter, hasBeenReset, reset] as const;
};

const getClasses = makeStyles((theme: Theme) => ({
  calendarWrapper: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

export { CheckoutsArchiveFilters };
