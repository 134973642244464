import { CompanyDetailSelectModel } from 'hq/services/mappers/mappers';
import { CompanyBranchOffices } from './tabs/company-branch-offices/company-branch-offices';
import { CompanyInfo } from './tabs/company-info/company-info';
import { CompanyPartnersDataProvider } from './tabs/partners/company-partners';
import { CompanyGrossHourlyRateDataProvider } from './tabs/company-gross-hourly-rate/company-gross-hourly-rate';

export const getCompanyDetailTabs = (
  allowPartnerRelations: boolean,
  data: CompanyDetailSelectModel
) => {
  return [
    {
      id: 'company-information',
      tabLabel: 'Bedrijfsdetails',
      element: <CompanyInfo data={data} />,
    },
    {
      id: 'company-branch-offices',
      tabLabel: 'Vestigingen',
      element: <CompanyBranchOffices id={data.id} />,
    },
    ...(allowPartnerRelations
      ? [
          {
            id: 'partner-relations',
            tabLabel: 'Partner relaties',
            element: <CompanyPartnersDataProvider id={data.id} />,
          },
        ]
      : []),

    {
      id: 'gross-hourly-rate',
      tabLabel: 'Bruto uurloon',
      element: <CompanyGrossHourlyRateDataProvider id={data.id} />,
    },
  ];
};
