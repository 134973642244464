import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerWorkerService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const useGetPartnerWorker = (workerId: string) => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.partnerWorker(workerId),
    queryFn: async () => await partnerWorkerService.getPartnerWorker(workerId),
  });
};
