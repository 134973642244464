import { billingEntityService } from 'ogp/services';
import { useMutation } from 'react-query';
import { FirstParamOfFn } from '@types';

const useCreateBillingEntity = () => {
  const mutationFn = async (
    billingEntity: FirstParamOfFn<typeof billingEntityService.createBillingEntity>
  ) => await billingEntityService.createBillingEntity(billingEntity);

  return useMutation({
    mutationFn,
  });
};

export { useCreateBillingEntity };
