import { projectService as ogpProjectService } from 'ogp/services';
import { projectService as hqProjectService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { AddPlacementPayload } from '../../../../../../services/project-service.types';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../../../../query-keys/project';

type PlacementMutationOptions = UseMutationOptions<unknown, unknown, AddPlacementPayload>;

export const useCreatePlacement = (options?: PlacementMutationOptions) => {
  const siteDetectionResult = useSiteDetection();
  const mutationFn = async (body: AddPlacementPayload) =>
    await getMutationFunction({ body, siteDetectionResult });
  const queryClient = useQueryClient();
  return useMutation(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        SHARED_QUERY_KEYS_PROJECT.projects(siteDetectionResult, variables.projectId)
      );
      queryClient.invalidateQueries(
        SHARED_QUERY_KEYS_PROJECT.projectDetail(siteDetectionResult, variables.projectId)
      );
      queryClient.invalidateQueries(
        SHARED_QUERY_KEYS_PROJECT.workApplicationsForProject(siteDetectionResult)
      );
      options && options?.onSuccess?.(data, variables, context);
    },
  });
};

type GetMutationFunctionParams = {
  body: AddPlacementPayload;
  siteDetectionResult: SiteDetectionResult;
};

const getMutationFunction = (props: GetMutationFunctionParams) => {
  const { body, siteDetectionResult } = props;

  if (siteDetectionResult.isHQ) {
    return hqProjectService.createPlacement(body);
  }
  if (siteDetectionResult.isOGP) {
    return ogpProjectService.createPlacement(body);
  }

  throw new Error(`useCreatePlacement: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
