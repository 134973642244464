import { JobTypeListItem } from '@types';
import { RequiredCertificatesForJobType } from 'ogp/services/job-type-service.types';
import { HqApiService } from './hqApiService';
import { GetJobTypesResponse } from './job-type-service.types';

export class JobTypeService {
  constructor(private readonly hqApiService: HqApiService) {}

  public getJobTypes = async (params: {
    companyId?: string;
    branchOfficeId?: string;
  }): Promise<JobTypeListItem[]> => {
    const { jobTypes } = await this.hqApiService.get<GetJobTypesResponse>({
      path: 'job-types',
      params,
    });

    return jobTypes;
  };

  public async getRequiredCertificates(jobTypeId: string) {
    const response = await this.hqApiService.get<RequiredCertificatesForJobType>({
      path: `job-types/${jobTypeId}/required-certificates`,
    });
    return response;
  }
}
