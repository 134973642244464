import { DateTime, Duration } from 'luxon';
import { PlannerShiftResponse } from 'partner/shared/services/partner-planner-service.types';
import { daysHoursMinutesUntil } from 'shared/components/shift-detail-modal/components/shift-detail-head/utils/days-hours-minutes-until';
import { formatDateFromString } from 'shared/utils/formatting';
import { getNumberOfSeats } from '../../../utils/get-number-of-seats';

export const getShiftProperties = (data: PlannerShiftResponse) => {
  const startDateTime = DateTime.fromISO(data.isoStartDateTime);
  const endDateTime = DateTime.fromISO(data.isoEndDateTime);
  const shiftTimeLengthInMs = endDateTime.diff(startDateTime).milliseconds;
  const duration = Duration.fromMillis(shiftTimeLengthInMs).normalize().toFormat('hh:mm');
  const startTime = startDateTime.toFormat('T', { locale: 'nl' });
  const endTime = endDateTime.toFormat('T', { locale: 'nl' });
  const cooldownDuration = Duration.fromObject({ minutes: data.workCooldownMinutes })
    .normalize()
    .toFormat('hh:mm');
  const numberOfSeats = getNumberOfSeats(data);

  return {
    companyAndLocation: `${data.companyName} / ${data.location}`,
    jobName: data.name,
    seats: `${numberOfSeats} beschikbare plaatsen`,
    startDate: formatDateFromString(startDateTime.toISODate()),
    duration: `${startTime} - ${endTime} (${duration} uren)`,
    break: `${data.breakMinutes === 0 ? 'Geen' : `${data.breakMinutes} min. pauze`}`,
    cancelationPeriod: `${daysHoursMinutesUntil(data.mutableUntilDate, 'Verstreken')}`,
    cooldownPeriod: `${cooldownDuration} rustperiode na shift`,
  };
};
