import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { FeeType } from 'ogp/services/billing-entity-service.types';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { CompanyEmploymentTypes } from '@types';

const [InternalFeeTypeProvider, useFeeTypeProvider] = ProviderFactory<FeeType[]>(
  'Fee types are only available inside FeeTypeProvider. Did you forget to render it?'
);

const FeeTypeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const employmentType = useGetCompanyEmploymentTypes();

  if (employmentType.status !== 'success') {
    return null;
  }

  return (
    <InternalFeeTypeProvider value={getFeeTypesFromEmploymentType(employmentType.data)}>
      {children}
    </InternalFeeTypeProvider>
  );
};

const getFeeTypesFromEmploymentType = (employmentType: CompanyEmploymentTypes): FeeType[] => {
  if (employmentType.offersFreelanceWork) {
    return ['platform', 'shiftManager'];
  } else {
    return ['shiftManager'];
  }
};

export { FeeTypeProvider, useFeeTypeProvider };
