import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useQuery } from 'react-query';
import { useMarkMessageAsRead } from './use-mark-message-as-read';

export const useGetPartnerMessageDetail = (messageId: string) => {
  const mutation = useMarkMessageAsRead();
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.partnerInboxMessage(messageId),
    queryFn: async () => await partnerInboxService.getPartnerInboxMessage(messageId),
    onSuccess: (data) => {
      !data.isRead && mutation.mutate({ messageId });
    },
  });
};
