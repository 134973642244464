import { useLocation } from 'react-router-dom';

const useSiteDetection = () => {
  const { pathname } = useLocation();
  const site = pathname.split('/')[1] as 'ogp' | 'partner' | 'hq';

  return {
    site,
    isOGP: site === 'ogp',
    isHQ: site === 'hq',
    isPartner: site === 'partner',
  } as const;
};

export { useSiteDetection };
