import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { projectService } from 'ogp/services';
import { useMutation } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

const useArchiveProject = () => {
  const { alertError, alertSuccess } = useAlert();
  const invalidateQuery = useInvalidateQuery();
  return useMutation(async (id: string) => await projectService.archiveProject(id), {
    onError: (e) => {
      alertError(e);
    },
    onSuccess: () => {
      alertSuccess('Project gearchiveerd');
      invalidateQuery(QUERY_KEYS_OGP.allProjects());
    },
  });
};

export { useArchiveProject };
