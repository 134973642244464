import { QUERY_KEYS_OGP } from '../../ogp/query-keys';
import { QUERY_KEYS_HQ } from '../../hq/query-keys';
import { SiteDetectionResult } from '../hooks';

const siteQueryKeyForProjectDetail = (
  siteDetectionResult: SiteDetectionResult,
  projectId: string
) => {
  switch (siteDetectionResult.site) {
    case 'ogp':
      return QUERY_KEYS_OGP.projectDetail(projectId);
    case 'hq':
      return QUERY_KEYS_HQ.projectDetail(projectId);
    default:
      throw new Error(
        `site: ${siteDetectionResult.site} is not supported for siteQueryKeyForProjectDetail query key`
      );
  }
};

const siteQueryKeyForWorkApplications = (siteDetectionResult: SiteDetectionResult) => {
  switch (siteDetectionResult.site) {
    case 'ogp':
      return [...QUERY_KEYS_OGP.allOGP(), 'project-work-applications'];
    case 'hq':
      return [...QUERY_KEYS_HQ.allHQ(), 'project-work-applications'];
    default:
      throw new Error(
        `site: ${siteDetectionResult.site} is not supported for siteQueryKeyForWorkApplications query key`
      );
  }
};

const siteQueryKeyForProjects = (
  siteDetectionResult: SiteDetectionResult,
  departmentId?: string
) => {
  switch (siteDetectionResult.site) {
    case 'ogp':
      return [...QUERY_KEYS_OGP.departmentProjects(departmentId)];
    case 'hq':
      return [...QUERY_KEYS_HQ.projects(departmentId)];
    default:
      throw new Error(
        `site: ${siteDetectionResult.site} is not supported for siteQueryKeyForProjects query key`
      );
  }
};

export const SHARED_QUERY_KEYS_PROJECT = {
  projectDetail: (siteDetectionResult: SiteDetectionResult, projectId: string) => [
    siteQueryKeyForProjectDetail(siteDetectionResult, projectId),
    { projectId },
  ],
  workApplicationsForProject: (siteDetectionResult: SiteDetectionResult) => [
    siteQueryKeyForWorkApplications(siteDetectionResult),
  ],
  projects: (siteDetectionResult: SiteDetectionResult, departmentId?: string) => [
    siteQueryKeyForProjects(siteDetectionResult, departmentId),
  ],
} as const;
