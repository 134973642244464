import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const monthLabels = [
  ['Januari', 'jan'],
  ['Februari', 'feb'],
  ['Maart', 'mrt'],
  ['April', 'apr'],
  ['Mei', 'mei'],
  ['Juni', 'jun'],
  ['Juli', 'jul'],
  ['Augustus', 'aug'],
  ['September', 'sep'],
  ['Oktober', 'okt'],
  ['November', 'nov'],
  ['December', 'dec'],
];

const dayLabels = ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'];

export const useCalendarDefaults = () => {
  const calendarStyles = styles();

  return {
    monthLabels,
    dayLabels,
    calendarStyles,
  };
};

const styles = makeStyles((theme: Theme) => ({
  calendar: {
    background: 'transparent',

    // Reset default react calendar styling
    borderRadius: 0,
    boxShadow: 'none',
    width: 'auto',

    '& .rmdp-arrow-container': {
      width: 'calc(100% / 7)',
      aspectRatio: 1,
      height: 'auto',
      margin: '-2px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover:not(.disabled)': {
        backgroundColor: 'white',
        boxShadow: theme.shadows[1],

        '& i': {
          borderColor: 'black',
        },
      },
    },

    '& .rmdp-arrow': {
      marginTop: 0,
      borderColor: 'black',
    },

    '& .rmdp-day-picker, & .rmdp-top-class': {
      display: 'block !important', // Make sure we override default display
    },

    '& .rmdp-day-picker': {
      padding: 0,
      '& > div': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },

    // Weekdays in header
    '& .rmdp-week-day': {
      fontSize: 12,
      fontWeight: 700,
      color: 'black',
      width: 'calc(100% / 7)',
      height: 'auto',
      aspectRatio: 1,

      '@media (min-width:1280px)': {
        fontSize: 14,
      },

      '@media (min-width:1920px)': {
        fontSize: 16,
      },
    },

    '& .rmdp-range': {
      backgroundColor: theme.palette.primary.main,
      color: 'black',
      boxShadow: 'none',
    },

    // Month name in header
    '& .rmdp-header .rmdp-header-values > span': {
      fontSize: 12,
      fontWeight: 700,

      '@media (min-width:1280px)': {
        fontSize: 16,
      },

      '@media (min-width:1920px)': {
        fontSize: 20,
      },
    },

    // Days in month to select
    '& .rmdp-day': {
      cursor: 'default',
      width: 'calc(100% / 7)',
      height: 'auto',

      '& > span': {
        height: '100%',
        width: '100%',
        fontSize: 10,
        cursor: 'pointer',

        // Reset default react calendar styling
        boxShadow: 'none !important', // Make sure we override default box shadow
        position: 'static',
        aspectRatio: 1,

        '@media (min-width:1280px)': {
          fontSize: 12,
        },

        '@media (min-width:1920px)': {
          fontSize: 16,
        },
      },

      '&.rmdp-disabled span': {
        opacity: 0.5,
        cursor: 'default',
      },

      '&.rmdp-today span': {
        backgroundColor: 'inherit',
        color: 'inherit',
        fontWeight: 'bold',
        textDecoration: 'underline',
      },

      // Style the selected highlight for date buttons
      '&.rmdp-selected span': {
        backgroundColor: theme.palette.primary.main,
        color: 'black',
      },

      // Style the hover highlight for selected date buttons
      '&.rmdp-selected span:hover': {
        backgroundColor: theme.palette.primary.light,
        color: 'black',
      },

      // Style the hover highlight of the date button
      '&:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
        backgroundColor: theme.palette.grey[300],
        color: 'black',
      },
    },
  },
}));
