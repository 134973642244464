import { Box, Tooltip, Typography } from '@mui/material';
import { DefaultTheme, styled } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import { IconCalendar, IconClock, IconCloseCircle, IconPause, IconProfileUsers } from 'assets/img';
import { LwIconButton } from 'redesign';
import { PlannerShiftResponse } from 'partner/shared/services/partner-planner-service.types';
import { getShiftProperties } from './utils/get-shift-properties';

type ShiftDetailHeadProps = {
  data: PlannerShiftResponse;
  close: () => void;
};

export const PartnerPlannerModalHead = ({ data, close }: ShiftDetailHeadProps) => {
  const shiftProperties = getShiftProperties(data);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
          <Typography noWrap color="secondary" variant="body1" style={{ fontWeight: 'bold' }}>
            {shiftProperties.companyAndLocation}
          </Typography>
          <Typography noWrap variant="h5" mt={2}>
            {data.name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" ml="auto" alignItems="center" gap={8}>
          <LwIconButton
            iconColorMode="stroke"
            color="secondary"
            onClick={close}
            data-testid="partner-planner-modal-close"
          >
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box gap={3} display="flex" flexDirection="row" marginTop={5}>
        <Box display="flex" flexDirection="column" flex={2} minWidth={0}>
          <Box display="flex" flexDirection="row" marginBottom={3}>
            <UsersIcon />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.seats}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom={3}>
            <CalendarIcon />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.startDate}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={2} minWidth={0}>
          <Box display="flex" flexDirection="row" marginBottom={3}>
            <ClockIcon />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.duration}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom={3}>
            <PauseIcon />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.break}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1.5}>
          <Box display="flex" flexDirection="row" marginBottom={3} flex={1}>
            <CloseIcon />
            <Tooltip
              title={
                shiftProperties.cancelationPeriod === 'Verstreken'
                  ? 'Annuleringstermijn verstreken'
                  : `Annuleringstermijn eindigt over: ${shiftProperties.cancelationPeriod}`
              }
            >
              <Typography noWrap variant="subtitle2">
                {shiftProperties.cancelationPeriod}
              </Typography>
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="row" marginBottom={3} flex={1}>
            <PauseIcon />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.cooldownPeriod}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const getIconStyles = (theme: DefaultTheme) => ({
  opacity: 0.6,
  marginRight: theme.spacing(3),
  flexShrink: 0,
  width: '24px',
  height: '24px',
});

const CloseIcon = styled(IconCloseCircle)(({ theme }) => getIconStyles(theme));
const PauseIcon = styled(IconPause)(({ theme }) => getIconStyles(theme));
const ClockIcon = styled(IconClock)(({ theme }) => getIconStyles(theme));
const CalendarIcon = styled(IconCalendar)(({ theme }) => getIconStyles(theme));
const UsersIcon = styled(IconProfileUsers)(({ theme }) => getIconStyles(theme));
