export type TypeSafeOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Enforce that what you pass extends a certain type, while retaining the specific type information.
 *
 * Usage: `const sth = extendsType<SomeType>()(...)`
 * => this gives auto completion / type checking on `...`, while specific values and extra fields are not lost.
 */
export const extendsType =
  <E extends any>() =>
  <T extends E>(arg: T) =>
    arg;

/*
 * API-returned types where we use Dates return these as ISO-formatted string.
 * This helper types replaces all Dates by the `string` type so we know what to
 * expect from the API.
 */
export type WithStringDates<T> = {
  [K in keyof T]: T[K] extends Date
    ? string
    : T[K] extends Date | null
      ? string | null
      : T[K] extends Date | null | undefined
        ? string | null | undefined
        : T[K] extends Record<any, any>
          ? WithStringDates<T[K]>
          : T[K];
};

/**
 * Utility type that takes a generic function type `T`, which returns a Promise,
 * and extracts the return type of the resolved Promise.
 *
 * @template T - The generic function type from which to extract the resolved Promise return type.
 * @param {T} args - The function for which to extract the resolved Promise return type.
 * @returns {ReturnType<T extends (...args: any) => infer R ? R : never>} - The resolved Promise return type.
 * @example
 *   async function fetchData(): Promise<string> {
 *     return "Data fetched";
 *   }
 *
 *   type ResultType = GetPromiseReturnType<typeof fetchData>;
 *   // Result: string
 */
export type GetPromiseReturnType<T extends (...args: any) => void> = Awaited<ReturnType<T>>;
