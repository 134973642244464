import { PolicyGroupAndRolesOptional } from 'shared/utils/policy-controller';

import { routes as ogpRouteDefinitions } from 'ogp/Routes';
import { hqRoutes as hqRouteDefinitions } from 'hq/Routes';
import { RouteProps } from 'react-router-dom';
import React from 'react';
import { PARTNER_PATHS } from 'partner/paths';

export type RouteDefinition = RouteProps & {
  path: string;
  fallbackPath?: string;
  layout?: React.ComponentType;
  queryParams?: Record<string, string>;
  search?: string;
} & PolicyGroupAndRolesOptional;

export const hqPrefix = '/hq';

export const routes = {
  ...ogpRouteDefinitions,
  ...hqRouteDefinitions,
  ...PARTNER_PATHS,
};
