import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from '../../../query-keys';
import { ogpSharedService } from '../../../services';
import { OgpJobTypesData } from '../../../services/ogp-shared-service.types';

export const useGetOgpJobTypes = () => {
  return useQuery<OgpJobTypesData, Error>({
    queryKey: QUERY_KEYS_OGP.jobTypes(),
    queryFn: async () => await ogpSharedService.getJobTypes(),
  });
};
