import React from 'react';
import { TakeDefined } from '@types';
import { DialogButton, Dialog as TDialog } from './components/buttons.types';

type PossibleButton<T extends 'secondaryButton' | 'primaryButton'> = {
  button: TDialog[T];
  props: React.ComponentProps<TakeDefined<TDialog[T]>>;
};

type FallbackButton = { button: DialogButton; props: React.ComponentProps<DialogButton> };

type GiveMeButton = (
  possible: PossibleButton<'secondaryButton'> | PossibleButton<'primaryButton'>,
  fallback: FallbackButton
) => React.ReactElement | null;

const giveMeButton: GiveMeButton = (possible, fallback) => {
  if (possible.button === null) {
    return null;
  }

  if (possible.button === undefined) {
    return <fallback.button {...fallback.props} />;
  }

  return <possible.button {...possible.props} />;
};

export { giveMeButton };
