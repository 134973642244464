import { IconApproved } from 'assets/img';
import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useCallback } from 'react';
import { ContextMenuItem } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { useCreatePlacement } from './hooks/use-create-placement';

const useGetCreatePlacementActions = () => {
  const { alertError, alertSuccess } = useAlert();
  const { openDialog, disableDialog, closeDialog } = useDialog();
  const createPlacement = useCreatePlacement({
    onSuccess: () => {
      alertSuccess('Plaatsing aangemaakt');
    },
    onError: (e) => {
      alertError(e);
    },
    onSettled: () => {
      closeDialog();
    },
  });

  const handleCreatePlacement = useCallback(
    (flexWorkerId: string, projectId: string, flexWorkerName: string) => {
      openDialog(
        {
          title: `Plaatsing maken`,
          content: `Weet je zeker dat je ${flexWorkerName} op dit project wilt plaatsen?`,
          primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
        },
        () => {
          disableDialog();
          createPlacement.mutate({ flexWorkerId, projectId });
        }
      );
    },
    [createPlacement, disableDialog, openDialog]
  );

  const getRowActions = useCallback(
    (flexWorkerId: string, shiftId: string, flexWorkerName: string) => {
      return [
        <ContextMenuItem
          onClick={() => handleCreatePlacement(flexWorkerId, shiftId, flexWorkerName)}
          key="addClaim"
        >
          <IconApproved />
          Op project plaatsen
        </ContextMenuItem>,
      ];
    },
    [handleCreatePlacement]
  );

  return getRowActions;
};

export { useGetCreatePlacementActions };
