import React from 'react';
import { Box, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useGetShiftClaimsForFlexWorker } from './hooks/use-get-shift-claims-for-flex-worker';
import { useShiftClaimFilters } from './shift-claim-filters-provider';
import { ShiftClaimCard } from './shift-claim-card';

const ShiftClaimList = ({ flexWorkerId }: { flexWorkerId: string }) => {
  const { shiftClaimFilters } = useShiftClaimFilters();

  const shiftClaimsQuery = useGetShiftClaimsForFlexWorker({
    ...shiftClaimFilters,
    flexWorkerId: flexWorkerId,
  });

  if (shiftClaimsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftClaimsQuery} />;
  }

  if (shiftClaimsQuery.data.length === 0) {
    return <Box>Geen shiftClaims voor huidige filter</Box>;
  }

  let tempDate: string = '';

  return (
    <Box>
      {shiftClaimsQuery.data.map((shiftClaim) => {
        if (tempDate !== shiftClaim.dateLabel) {
          tempDate = shiftClaim.dateLabel;
        }
        const renderDivider = tempDate !== shiftClaim.dateLabel;

        return (
          <React.Fragment key={shiftClaim.id}>
            {renderDivider ? (
              <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                {shiftClaim.dateLabel}
              </Typography>
            ) : null}
            <ShiftClaimCard shiftClaim={shiftClaim} />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export { ShiftClaimList };
