import { useMutation, UseMutationOptions } from 'react-query';
import { shiftClaimService } from 'ogp/services';
import { saveAs } from 'file-saver';

type ShiftClaimOvoMutationOptions = UseMutationOptions<Blob, Error, string>;

export const useGetShiftClaimContract = (options?: ShiftClaimOvoMutationOptions) => {
  const mutationFn = async (shiftClaimId: string) =>
    await shiftClaimService.getShiftClaimOvo(shiftClaimId);

  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftClaimOvoMutationOptions = {
    onSuccess: async (data, variables, context) => {
      const shiftClaimId = variables;
      saveAs(data, `contract_${shiftClaimId}.pdf`);
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
