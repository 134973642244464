import { RenderUnsettledUI } from 'shared/components';
import { GetCompanyGrossHourlyRateResponse } from 'hq/services/company-service.types';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { CompanyGrossHourlyRateTable } from './components/company-gross-hourly-rate-table/company-gross-hourly-rate-table';
import { useGetCompanyGrossHourlyRates } from './hooks/use-get-company-gross-hourly-rates';

export const CompanyGrossHourlyRateDataProvider = ({ id }: { id: string }) => {
  const query = useGetCompanyGrossHourlyRates(id);

  if (query.status !== 'success') {
    return <RenderUnsettledUI data={query} />;
  }

  return <CompanyGrossHourlyRate data={query.data} id={id} />;
};

const CompanyGrossHourlyRate = ({
  data,
  id,
}: {
  data: GetCompanyGrossHourlyRateResponse;
  id: string;
}) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();

  return (
    <Box>
      <LwButton
        onClick={() => {
          navigate(generatePath(routes.CompanyGrossHourlyRateCreate, { id: id }));
        }}
        color="primary"
      >
        Bruto uurloon aanmaken
      </LwButton>
      <Box mt={5}>
        <CompanyGrossHourlyRateTable data={data} />
      </Box>
    </Box>
  );
};
