import { useGetOGPUserInfo } from 'ogp/hooks';
import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { useFormContext } from 'react-hook-form';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { useMemo } from 'react';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { useOgpGetShiftCreateData } from '../hooks/ogp/use-ogp-get-shift-create-data';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { ShiftFormProps } from '../components/forms/shared/types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';
import { useGetJob } from '../../../hooks/query-hooks/use-get-job';

export const OgpShiftForm = ({ mode }: Pick<ShiftFormProps, 'mode'>) => {
  const { watch } = useFormContext<FreelanceShiftFormData | TempWorkShiftFormData>();
  const { jobId } = watch();
  const shiftCreateDataQuery = useOgpGetShiftCreateData();
  const jobsQuery = useGetJobs();
  const selectedJobQuery = useGetJob(jobId);

  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes();

  const { data: ogpUserInfo } = useGetOGPUserInfo();
  const isHqAdmin =
    !!ogpUserInfo &&
    hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, ogpUserInfo.userInfo, CompanyRole.HQADMIN);

  const disabled = useMemo(
    () =>
      mode === 'read' ||
      jobsQuery.status !== 'success' ||
      shiftCreateDataQuery.status !== 'success',
    [jobsQuery.status, mode, shiftCreateDataQuery.status]
  );

  if (employmentTypeOfCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={employmentTypeOfCompanyQuery} />;
  }

  return (
    <ShiftForm
      mode={mode}
      isDisabled={disabled}
      jobsData={jobsQuery.data ?? []}
      shiftCreateData={
        shiftCreateDataQuery.data ?? { branchOfficesWithDepartments: [], breakOptions: [] }
      }
      jobData={selectedJobQuery.data}
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      allowDateBeforeToday={isHqAdmin}
    />
  );
};
