import type { Column } from 'react-table';
import { Chip, Typography } from '@mui/material';
import { ContextMenuButton, TableHeader } from 'redesign';
import {
  PartnerRelationCellProp,
  PartnerRelationColumnDefinition,
} from '../partner-relations.types';

const getPartnerRelationColumnDefinition: () => Column<PartnerRelationColumnDefinition>[] = () => {
  const company = {
    Header: () => <TableHeader>Vestiging</TableHeader>,
    accessor: 'branchOfficeName',
    Cell: BranchOfficeNameCell,
    width: '600px',
  } as Column<PartnerRelationColumnDefinition>;

  const jobTypes = {
    Header: () => <TableHeader>Functie groep</TableHeader>,
    accessor: 'jobTypes',
    Cell: JobTypesCell,
    width: '600px',
  } as Column<PartnerRelationColumnDefinition>;

  const forwardingRulePercentage = {
    Header: () => <TableHeader>Watervalpercentage</TableHeader>,
    accessor: 'forwardingRulePercentage',
    Cell: ForwardingRulePercentageCell,
    width: '50px',
  } as Column<PartnerRelationColumnDefinition>;

  const actions: Column<PartnerRelationColumnDefinition> = {
    accessor: 'actions',
    Header: 'Action',
    Cell: ActionCell,
    width: '40px',
  };

  return [company, jobTypes, forwardingRulePercentage, actions];
};

const BranchOfficeNameCell = (cell: PartnerRelationCellProp<'branchOfficeName'>) => {
  return (
    <Typography variant="body1" noWrap>
      {cell.value}
    </Typography>
  );
};

const JobTypesCell = (cell: PartnerRelationCellProp<'jobTypes'>) => {
  return (
    <Typography component={'div'}>
      {cell.value.map((jobType) => (
        <Chip key={jobType.id} label={jobType.name} />
      ))}
    </Typography>
  );
};

const ForwardingRulePercentageCell = (cell: PartnerRelationCellProp<'jobTypes'>) => {
  return <Typography variant="body1">{cell.value ? `${cell.value}%` : null}</Typography>;
};

const ActionCell = (cell: PartnerRelationCellProp<'actions'>) => {
  const actions = cell.getRowActions(cell.value.partnerRelationId);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`partner-relation-${cell.value.companyId}`}>
      {actions}
    </ContextMenuButton>
  );
};

export { getPartnerRelationColumnDefinition };
