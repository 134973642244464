import { GetPartnerRelationsResponse } from 'hq/services/partner-service.types';
import { PartnerRelationColumnDefinition } from '../partner-relations.types';

export const getPartnerRelationTableData = (data: GetPartnerRelationsResponse[]) => {
  const result: PartnerRelationColumnDefinition[] = data.map((partnerRelation) => {
    return {
      partnerRelationId: partnerRelation.partnerRelationId,
      branchOfficeId: partnerRelation.branchOfficeId,
      branchOfficeName: `${partnerRelation.companyName} - ${partnerRelation.branchOfficeName}`,
      companyName: partnerRelation.companyName,
      name: partnerRelation.companyName,
      jobTypes: partnerRelation.jobTypes,
      forwardingRulePercentage: partnerRelation.forwardingRulePercentage,
      actions: partnerRelation,
    };
  });
  return result;
};
