import { useMutation } from 'react-query';
import { projectPlanningService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

export const useArchiveProject = () => {
  const mutationFn = async (projectId: string) =>
    await projectPlanningService.archiveProject(projectId);
  const invalidateQuery = useInvalidateQuery();
  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.projects());
    },
  });
};
