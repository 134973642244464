import { Box } from '@mui/material';
import { useCallback } from 'react';
import { Header, LWModal, Page, Toolbox } from 'redesign';
import {
  AccreditationParams,
  ACCREDITATIONS_INITIAL_VALUES,
  SetAccreditationParams,
} from './accreditations.types';
import { useGetAccreditations } from './hooks/use-get-accreditations';
import { useGetCertificateNames } from './hooks/use-get-certificates-names';
import { AccreditationsTable } from './accreditations-table';
import { AccreditationDetail } from './detail/accreditations-detail';
import { AccreditationsToolbox } from './toolbox/accreditations-toolbox';
import { useExternalParams } from '../../../../shared/hooks';
import { RenderUnsettledUI } from '../../../../shared/components';
import {
  HQCertificateResponse,
  RawAccreditationData,
} from '../../../services/accreditations-service.types';

export const Accreditations = () => {
  const [params, setParams] = useExternalParams(ACCREDITATIONS_INITIAL_VALUES);

  const accreditationsQuery = useGetAccreditations(params as AccreditationParams);
  const certificatesQuery = useGetCertificateNames();

  if (accreditationsQuery.status !== 'success') {
    return <RenderUnsettledUI data={accreditationsQuery} />;
  }

  if (certificatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={certificatesQuery} />;
  }

  return (
    <AccreditationsImpl
      accreditationData={accreditationsQuery.data}
      certificateData={certificatesQuery.data}
      params={params as AccreditationParams}
      setParams={setParams}
    />
  );
};

const AccreditationsImpl = ({
  accreditationData,
  certificateData,
  params,
  setParams,
}: {
  accreditationData: RawAccreditationData[];
  certificateData: HQCertificateResponse[];
  params: AccreditationParams;
  setParams: SetAccreditationParams;
}) => {
  const closeDetail = useCallback(() => setParams({ flexWorkerId: undefined }), []);

  return (
    <>
      <Page
        header={<Header titleText="Verificatie status" />}
        toolbox={
          <Toolbox alignCenter={true}>
            <AccreditationsToolbox
              data={accreditationData}
              certificateData={certificateData}
              params={params}
              setParams={setParams}
            ></AccreditationsToolbox>
          </Toolbox>
        }
      >
        <Box width="100%">
          <AccreditationsTable data={accreditationData} params={params} setParams={setParams} />
        </Box>
      </Page>
      <LWModal isOpen={!!params.flexWorkerId} onClose={closeDetail}>
        {params.flexWorkerId && params.certificateId ? (
          <AccreditationDetail
            flexWorkerId={params.flexWorkerId}
            certificateId={params.certificateId}
            close={closeDetail}
          />
        ) : null}
      </LWModal>
    </>
  );
};
