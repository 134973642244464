import { ProjectForm } from 'shared/components/project-forms/components/project-form';
import { JobListItem } from '@types';
import { RenderUnsettledUI } from 'shared/components';
import { useGetDepartmentsAsSelect } from '../../hooks/use-get-departments';

type Props = {
  companyId: string;
  jobsData: JobListItem[];
};

export const ProjectFormProvider = ({ companyId, jobsData }: Props) => {
  const departmentsQuery = useGetDepartmentsAsSelect(companyId, { includeArchived: false });

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectForm
      mode="create"
      jobsData={jobsData}
      isProjectWithActivePlacement={false}
      departments={departmentsQuery.data}
    />
  );
};
