import { Header, Page } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { BankAccountList } from './bank-account-table/bank-account-list';
import { useGetPendingBankAccounts } from './bank-account-table/hooks/use-get-pending-bank-accounts';

export const BankAccount = () => {
  const bankAccounts = useGetPendingBankAccounts();
  const { allowEmploymentId } = useFeatureFlagContext();

  return (
    <Page header={<Header titleText="Bank Accounts" />}>
      {bankAccounts.status !== 'success' ? (
        <RenderUnsettledUI data={bankAccounts} />
      ) : (
        <BankAccountList data={bankAccounts.data} allowEmploymentId={allowEmploymentId} />
      )}
    </Page>
  );
};
