import { GetPartnerCheckedOutsResponse } from 'partner/shared/services/checked-outs-service.types';
import { DateTime } from 'luxon';
import { PartnerCheckedOutColumnDefinition } from '../partner-checked-out.types';

export const getPartnerCheckedOutTableData = (
  data: GetPartnerCheckedOutsResponse['items']
): PartnerCheckedOutColumnDefinition[] => {
  const result: PartnerCheckedOutColumnDefinition[] = data.map((item) => {
    return {
      ...item,
      shiftStartDate: DateTime.fromISO(item.plannedSchedule.startDate).toFormat('dd LLL', {
        locale: 'nl',
      }),
      scheduledDateTime: {
        fromDateTime: DateTime.fromISO(item.plannedSchedule.startDate).toFormat('HH:mm'),
        toDateTime: DateTime.fromISO(item.plannedSchedule.endDate).toFormat('HH:mm'),
        breakMins: item.plannedSchedule.breakMinutes,
      },
      workedDateTime: {
        fromDateTime: item.workedSchedule.startDate
          ? DateTime.fromISO(item.workedSchedule.startDate).toFormat('HH:mm')
          : 'n.v.t.',
        toDateTime: item.workedSchedule.endDate
          ? DateTime.fromISO(item.workedSchedule.endDate).toFormat('HH:mm')
          : 'n.v.t.',
        breakMins: item.workedSchedule.breakMinutes,
      },
    };
  });
  return result;
};
