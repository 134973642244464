import { PartnerWorker } from 'partner/shared/services/partner-planner-service.types';
import { PartnerPlannerTableDefinition } from './partner-planner-modal-table.types';

export const getPlannerModalTableData = (
  data: PartnerWorker[]
): PartnerPlannerTableDefinition[] => {
  return data.map((worker) => {
    return {
      name: worker.name,
      email: worker.email,
      phone: worker.phone,
      hasRequiredCertificates: worker.hasRequiredCertificates,
      actions: {
        id: worker.shiftClaimId,
        workerId: worker.id,
      },
      availability: worker.availability,
    };
  });
};
