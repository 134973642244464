import { PrebuiltBlock } from 'redesign';
import { BranchOfficeFormData } from '@types';
import {
  CreateBranchOfficeResponse,
  GetBranchOfficeResponse,
  GetBranchOfficesListingResponse,
  GetBranchOfficesResponse,
} from './branch-office-service.types';
import { OgpApiService } from './ogp-api-service';

export class BranchOfficeService {
  constructor(private readonly ogpApiService: OgpApiService) {}
  /**
   * Create a new branchOffice
   */
  public createBranchOffice = async (
    branchOfficeData: BranchOfficeFormData
  ): Promise<CreateBranchOfficeResponse> => {
    return await this.ogpApiService.create<CreateBranchOfficeResponse>({
      path: `branch-offices`,
      body: branchOfficeData,
    });
  };

  /**
   * Update an existing branchOffice
   */
  public updateBranchOffice = async (
    branchOfficeId: string,
    branchOfficeData: BranchOfficeFormData
  ) => {
    return await this.ogpApiService.update({
      path: `branch-offices/${branchOfficeId}`,
      body: branchOfficeData,
    });
  };

  /**
   * Get a list of existing branchOffices
   */
  public getBranchOffices = async (
    { includeArchived }: { includeArchived: boolean },
    companyId?: string
  ): Promise<GetBranchOfficesResponse['branchOffices']> => {
    const { branchOffices } = await this.ogpApiService.get<GetBranchOfficesResponse>({
      params: { companyId, includeArchived },
      path: 'branch-offices',
    });

    return branchOffices;
  };

  public getBranchOfficesListing = async (
    { includeArchived }: { includeArchived: boolean },
    companyId?: string
  ): Promise<PrebuiltBlock[]> => {
    const { branchOffices } = await this.ogpApiService.get<GetBranchOfficesListingResponse>({
      params: { companyId, includeArchived },
      path: 'branch-offices/listing',
    });

    return branchOffices;
  };

  /**
   * Get a single branchOffice
   */
  public getBranchOffice = async (branchOfficeId: string) => {
    const { branchOffice } = await this.ogpApiService.get<GetBranchOfficeResponse>({
      path: `branch-offices/${branchOfficeId}`,
    });

    return branchOffice;
  };
}
