import { Auth } from 'aws-amplify';

import { AbstractApiService, TokenProvider } from 'shared/services/abstractApiService';

interface Config {
  endpoint: string;
}

class HqTokenProvider implements TokenProvider {
  public async currentToken(): Promise<string> {
    // TODO: does this also fix refreshing of tokens?
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }
}

export class HqApiService extends AbstractApiService {
  constructor(config: Config) {
    super(config.endpoint, new HqTokenProvider());
  }
}
