import { DateTime } from 'luxon';
import {
  usePlanningDateRange,
  useReadShiftDetailPath,
  useUpdatePlanningPath,
} from 'ogp/hooks/planning';
import { useGetShiftsPlanning } from 'ogp/components/views/planning/planning/hooks/use-get-shifts-planning';
import { usePageProvider } from 'ogp/layouts';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { PlanningShiftGrid } from './planning-shift-grid/planning-shift-grid';
import { PlanningHeader } from './planning-header/planning-header';
import { PlanningModalProvider } from './planning-modal/planning-modal';

export const Planning = () => {
  const navigate = useNavigate();
  const { startDate, endDate } = usePlanningDateRange();
  const { shiftDetailId, date: paramsDate } = useReadShiftDetailPath();
  const updatePlanningPath = useUpdatePlanningPath();
  const [{ filters }] = usePageProvider();

  const planningQuery = useGetShiftsPlanning({
    departmentId: filters.departmentId,
    fromDate: startDate.toISODate(),
    toDate: endDate.toISODate(),
  });

  useEffect(() => {
    if (!paramsDate || !paramsDate.startOf('week').isValid) {
      const path = updatePlanningPath({ date: DateTime.now() });
      navigate(path, { replace: true });
    }
  }, [paramsDate, navigate, updatePlanningPath]);

  const closeShiftDetail = useCallback(() => {
    const path = updatePlanningPath({ shiftDetailId: null });
    navigate(path, { replace: true });
  }, [navigate, updatePlanningPath]);

  if (planningQuery.status !== 'success') {
    return <RenderUnsettledUI data={planningQuery} />;
  }

  return (
    <>
      <PlanningHeader date={startDate} />
      <PlanningShiftGrid data={planningQuery.data} />
      <PlanningModalProvider onClose={closeShiftDetail} shiftDetailId={shiftDetailId} />
    </>
  );
};
