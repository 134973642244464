import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
  FlexWorkersFilters,
  PaginatedCollection,
} from '@types';
import CrossIcon from 'assets/img/icons/cross.svg?react';
import { FormCheckbox, LwFormInput, LwFormSelect } from 'redesign';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { getEmploymentTypeOptions } from 'shared/utils/filters';
import { SetFlexWorkerParams } from './flex-workers.types';

type FlexWorkerTableProps = {
  data: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts;
  filter: string | undefined;
  setParams: SetFlexWorkerParams;
  status: string;
};

const FlexWorkersFilterForm = ({ data, filter, setParams, status }: FlexWorkerTableProps) => {
  const { control, setValue } = useFormContext<FlexWorkersFilters>();
  const [filterValue, setFilterValue] = useState(filter);
  const debounceFilter = useDebounce(filterValue, 1000);
  const { allowFreelancerEmploymentType, allowTempWorkerEmploymentType } = useFeatureFlagContext();

  useEffect(() => {
    if (debounceFilter !== undefined && debounceFilter !== filter) {
      setValue('filter', debounceFilter, { shouldDirty: true });
      setParams({ filter: debounceFilter });
    }
  }, [debounceFilter, setValue, setParams]);

  const levelOptions = useMemo(() => getLevelOptions(data), [data]);

  const employmentTypeOptions = getEmploymentTypeOptions({
    allowFreelancerEmploymentType,
    allowTempWorkerEmploymentType,
  });

  const disabled = status === 'error';

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <LwFormInput
          disabled={disabled}
          control={control}
          name="filter"
          label="Zoeken"
          placeholder="Naam, email of telefoonnummer"
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="veld leegmaken"
                  onClick={() => setFilterValue('')}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <CrossIcon width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LwFormSelect
          disabled={disabled}
          name="level"
          control={control}
          label="Level"
          options={levelOptions}
          onChange={(e) => {
            setParams({ level: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LwFormSelect
          disabled={disabled}
          name="employmentType"
          control={control}
          label="Flexwerker type"
          options={employmentTypeOptions}
          onChange={(e) => {
            setParams({ employmentType: e.target.value });
          }}
        />
      </Grid>
      <Box alignSelf={'center'} ml={4}>
        <FormCheckbox
          disabled={disabled}
          name="disabled"
          control={control}
          label={`Disabled (${data ? data.disabledCount : ''})`}
          onChange={(e) => {
            setParams({ disabled: e });
          }}
        />
      </Box>
    </Grid>
  );
};

const getLevelOptions = (
  data: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts
) => [
  {
    label: `Alles  ${data.unfilteredCount ? `(${data.unfilteredCount})` : ''}`,
    value: 'ALL',
  },
  {
    label: `Incomplete ${data.incompleteCount ? `(${data.incompleteCount})` : ''}`,
    value: 'INCOMPLETE',
  },
  {
    label: `Explorer ${data.explorerCount ? `(${data.explorerCount})` : ''}`,
    value: 'EXPLORER',
  },
  {
    label: `Starter ${data.starterCount ? `(${data.starterCount})` : ''} `,
    value: 'STARTER',
  },
  {
    label: `Candidate ${data.candidateCount ? `(${data.candidateCount})` : ''} `,
    value: 'CANDIDATE',
  },
  {
    label: `Worker ${data.workerCount ? `(${data.workerCount})` : ''} `,
    value: 'WORKER',
  },
];

export { FlexWorkersFilterForm };
