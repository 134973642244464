import { RenderUnsettledUI } from 'shared/components';
import { PartnerRelationsTable } from './partner-relations-table';
import { useGetPartnerRelations } from '../../partners/hooks/use-get-partner-relations';

type Props = { partnerId: string; deactivated: boolean };

export const PartnerRelationsTableProvider = ({ partnerId, deactivated }: Props) => {
  const partnerRelationsQuery = useGetPartnerRelations(partnerId);

  if (partnerRelationsQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerRelationsQuery} />;
  }

  return (
    <PartnerRelationsTable
      data={partnerRelationsQuery.data}
      partnerId={partnerId}
      deactivated={deactivated}
    />
  );
};
