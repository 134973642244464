import { RenderUnsettledUI } from 'shared/components';
import { useGetJobs } from 'hq/hooks/queryHooks/jobs/use-get-jobs';
import { useGetCompanies } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { useParams } from 'react-router-dom';
import { invariant } from '../../../../../../shared/utils/utils';
import { useGetProjectDetail } from '../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-project-detail';
import { useGetCompanyBasicInfo } from '../../../../../../shared/components/planner/project-detail-modal/detail/applications/hooks/use-get-company-basic-info';
import { ProjectCreateFormPage } from '../create/form/project-create-form-page';

export const ProjectDuplicateDataProvider = () => {
  const jobsQuery = useGetJobs();
  const companiesQuery = useGetCompanies();

  const { id: projectId } = useParams<'id'>();
  invariant(projectId, 'Project ID should be defined');

  const projectQuery = useGetProjectDetail(projectId);
  const projectCompanyQuery = useGetCompanyBasicInfo(projectId);

  if (jobsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobsQuery} />;
  }

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  if (projectQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectQuery} />;
  }

  if (projectCompanyQuery.status !== 'success') {
    return <RenderUnsettledUI data={projectCompanyQuery} />;
  }

  const defaultValues = {
    companyId: projectCompanyQuery.data.id,
    departmentId: projectQuery.data.department.id,
    jobId: projectQuery.data.jobId,
    startDate: projectQuery.data.startDate,
    endDate: projectQuery.data.endDate,
    hourlyRate: projectQuery.data.hourlyRateCents / 100,
    weeklyHours: projectQuery.data.weekHourVolume,
  };

  return (
    <ProjectCreateFormPage
      jobsData={jobsQuery.data}
      companies={companiesQuery.data}
      defaultValues={defaultValues}
      successMessage={`Project gedupliceerd`}
      pageTitle={`Project dupliceren`}
    />
  );
};
