import { AutocompleteOption, FormCheckbox, LwFormAutocomplete } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { Box, FilterOptionsState } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { getFilterOptions } from 'hq/components/views/planner/shifts/filters/utils/utils';
import {
  BusinessUnitsFilterCompany,
  BusinessUnitsWithCompanyFormContext,
} from './filter-business-units-with-company.types';
import { getBusinessUnitWithCompanyOptions } from './get-business-units-with-company-options';

type Props = {
  companies: BusinessUnitsFilterCompany[];
};

export const FilterBusinessUnitsWithCompany = (props: Props) => {
  const { companies } = props;
  const { setValue, watch, control } = useFormContext<BusinessUnitsWithCompanyFormContext>();

  const {
    company: selectedCompany,
    branchOffice: selectedBranchOffice,
    businessUnitCheckboxValue: selectedBusinessUnitCheckbox,
  } = watch();

  const options = useMemo(() => {
    return getBusinessUnitWithCompanyOptions(
      companies,
      selectedCompany as AutocompleteOption | undefined,
      selectedBranchOffice as AutocompleteOption | undefined
    );
  }, [companies, selectedCompany, selectedBranchOffice]);

  const filterOptions = useCallback(
    (
      autocompleteOptions: AutocompleteOption[] | null,
      autocompleteState: FilterOptionsState<any>
    ) => {
      const res = getFilterOptions(autocompleteOptions, autocompleteState);
      return res;
    },
    []
  );

  if (!selectedBusinessUnitCheckbox) {
    return (
      <Box>
        <FormCheckbox name="businessUnitCheckboxValue" control={control} label="Bedrijfseenheid" />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <FormCheckbox name="businessUnitCheckboxValue" control={control} label="Bedrijfseenheid" />
      </Box>
      <LwFormAutocomplete
        name="company"
        isLoading={false}
        label="Bedrijf"
        getOptionLabel={(value) => value?.label ?? ''}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value.value;
        }}
        getOptionKey={(option) => option.value}
        options={options.companies}
        filterOptions={(options, state) => filterOptions(options, state)}
        control={control}
        onChange={(_event, value) => {
          if (value) {
            setValue('company', value);
            setValue('branchOffice', null);
            setValue('department', null);
          }
        }}
      />
      <LwFormAutocomplete
        isLoading={false}
        name="branchOffice"
        label="Vestigingen"
        getOptionKey={(option) => option.value}
        getOptionLabel={(value) => value?.label ?? ''}
        disabled={!selectedCompany}
        options={options.branchOffices}
        filterOptions={(options, state) => filterOptions(options, state)}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value;
        }}
        control={control}
        onChange={(_event, value) => {
          if (value) {
            setValue('branchOffice', value);
            setValue('department', null);
          }
        }}
      />
      <LwFormAutocomplete
        isLoading={!companies?.length}
        name="department"
        label="Afdelingen"
        getOptionKey={(option) => option.value}
        getOptionLabel={(value) => value?.label ?? ''}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value.value;
        }}
        filterOptions={(options, state) => filterOptions(options, state)}
        disabled={!(selectedCompany && selectedBranchOffice)}
        options={options.departments}
        control={control}
        onChange={(_event, value) => {
          if (value) {
            setValue('department', value);
          }
        }}
      />
    </Box>
  );
};
