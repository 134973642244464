import {
  FlexWorkerRelation,
  useGetFlexWorkerProfile,
} from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { Box, styled } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { Profile } from './profile';

type PopupProps = {
  id: string;
  flexWorkerRelation?: FlexWorkerRelation;
  isOpen: boolean;
  close: () => void;
};

const PopupModalContent = ({
  id,
  flexWorkerRelation,
}: Pick<PopupProps, 'id' | 'flexWorkerRelation'>) => {
  const query = useGetFlexWorkerProfile(id, flexWorkerRelation);

  if (query.status !== 'success') {
    return <RenderUnsettledUI data={query} />;
  }

  const { data } = query;

  return (
    <WrapperBox>
      <Profile data={data} id={id} />
    </WrapperBox>
  );
};

const WrapperBox = styled(Box)(() => ({
  overflowY: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));

export { PopupModalContent };
