import { areAtLeastTwoEmploymentTypesEnabled, EmploymentTypeFeatureFlags } from './feature-flag';

export const getEmploymentTypeOptions = (featureFlags: EmploymentTypeFeatureFlags) => {
  const atLeastTwoEnabledEmploymentTypes = areAtLeastTwoEmploymentTypesEnabled(featureFlags);

  if (atLeastTwoEnabledEmploymentTypes) {
    return [
      { label: `Alles`, value: 'ALL' },
      { label: 'Freelancer', value: 'FREELANCER' },
      { label: 'Uitzendkracht', value: 'TEMPWORKER' },
    ];
  } else if (featureFlags.allowFreelancerEmploymentType) {
    return [{ label: 'Freelancer', value: 'FREELANCER' }];
  } else if (featureFlags.allowTempWorkerEmploymentType) {
    return [{ label: 'Uitzendkracht', value: 'TEMPWORKER' }];
  }
  throw new Error('No employment types are enabled');
};

export const getDefaultEmploymentTypeFilter = (featureFlags: EmploymentTypeFeatureFlags) => {
  const atLeastTwoEnabledEmploymentTypes = areAtLeastTwoEmploymentTypesEnabled(featureFlags);

  if (atLeastTwoEnabledEmploymentTypes) {
    return 'ALL';
  } else if (featureFlags.allowFreelancerEmploymentType) {
    return 'FREELANCER';
  } else if (featureFlags.allowTempWorkerEmploymentType) {
    return 'TEMPWORKER';
  }
  throw new Error('No employment types are enabled');
};
