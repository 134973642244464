import { Box, Card, ListItem, ListItemText, styled, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { HighlightedText } from 'shared/components/common/highlighted-text';
import {
  formatEmploymentType,
  formatFlexWorkerLevel,
  formatFullNameReverse,
} from 'shared/utils/formatting';
import { FlexWorkerListItem } from '@types';
import { FlexWorkerAvatar } from './flex-worker-avatar';

interface FlexWorkerCardProps {
  flexWorker: FlexWorkerListItem;
  filter: string;
  openModal: (flexWorkerId: string) => void;
}

export const FlexWorkerCard = ({ flexWorker, filter, openModal }: FlexWorkerCardProps) => {
  const name = useMemo(() => formatFullNameReverse(flexWorker), [flexWorker]);
  const employmentType = useMemo(
    () => formatEmploymentType(flexWorker.employmentType),
    [flexWorker.employmentType]
  );
  const flexWorkerLevel = useMemo(
    () => formatFlexWorkerLevel(flexWorker.level),
    [flexWorker.level]
  );
  const handleOpenModal = useCallback(() => openModal(flexWorker.id), [flexWorker]);

  return (
    <StyledCard data-testid="flex-worker" elevation={0} disabled={flexWorker.disabled}>
      <ListItem
        data-testid="flex-worker-link"
        alignItems="flex-start"
        sx={{ cursor: 'pointer' }}
        onClick={handleOpenModal}
      >
        <FlexWorkerAvatar flexWorker={flexWorker} />
        <Box>
          <ListItemText>
            <HighlightedText
              text={flexWorker.level ? name : flexWorker.email}
              highlight={filter}
              variant="body1"
            />
          </ListItemText>
          {flexWorker.level && (
            <>
              <Tooltip title={flexWorker.email}>
                <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  <HighlightedText text={flexWorker.email} highlight={filter} variant="body2" />
                </Box>
              </Tooltip>
              <Box overflow="hidden">
                <HighlightedText text={flexWorker.phone} highlight={filter} variant="body2" />
              </Box>
            </>
          )}
          <Typography
            data-testid="level-employment-type"
            variant="body2"
          >{`${flexWorkerLevel} (${employmentType})`}</Typography>
        </Box>
      </ListItem>
    </StyledCard>
  );
};

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'disabled' })<{
  disabled: boolean;
}>(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
  },
}));
