import { useGetPartnerUserInfo } from 'partner/shared/hooks/use-get-partner-user-info';
import { hasAccessWithinPolicyGroup, PolicyGroupAndRoles } from 'shared/utils/policy-controller';

type AuthenticatedElementProps = PolicyGroupAndRoles & { children: React.ReactElement };

export const PartnerAuthenticatedElement = ({
  roles,
  policyGroup,
  children,
}: AuthenticatedElementProps) => {
  const { data } = useGetPartnerUserInfo();

  if (!data) {
    return null;
  }

  if (!hasAccessWithinPolicyGroup(policyGroup, data, roles)) {
    return null;
  }

  return children;
};
