import type { Column } from 'react-table';
import { Chip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableCell, TableHeader } from 'redesign';
import { IconFlexPoolOnlyJob } from 'assets/img';
import { getShiftDetailsColumnDefinitionBase } from 'shared/components/shift-detail-modal/components/tabs/shared/columns/get-shift-details-column-definition-base';
import { CancelledCellProps } from 'shared/components/shift-detail-modal/components/tabs/cancelled/helpers/cancelled-table.types';
import { CancelledColumnDefinitionProject } from '../types/cancelled-table-project.types';

export const getCancelledColumnDefinitionProject: () => Column<CancelledColumnDefinitionProject>[] =
  () => {
    const [flexWorkerImage, fw] = getShiftDetailsColumnDefinitionBase();

    const cancellationReason: Column<CancelledColumnDefinitionProject> = {
      Header: '',
      accessor: 'cancellationReason',
      Cell: CancellationReasonCell,
    };

    const flexPoolIndicator: Column<CancelledColumnDefinitionProject> = {
      Header: '',
      accessor: 'flexPoolIndicator',
      Cell: FlexPoolIndicatorCell,
    };
    const hourlyRate: Column<CancelledColumnDefinitionProject> = {
      Header: () => <TableHeader>Uurtarief</TableHeader>,
      accessor: 'hourlyRate',
      Cell: HourlyRateCell,
    };

    return [flexWorkerImage, cancellationReason, flexPoolIndicator, fw, hourlyRate];
  };

const HourlyRateCell = ({ value }: CancelledCellProps<'hourlyRate'>) => {
  return (
    <TableCell>
      {value.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      })}
    </TableCell>
  );
};

const FlexPoolIndicatorCell = ({ value }: CancelledCellProps<'flexPoolIndicator'>) => {
  return value ? <IconFlexPoolOnlyJob width={24} /> : null;
};

const CancellationReasonCell = ({ value }: CancelledCellProps<'cancellationReason'>) => {
  const classes = getClasses();
  return <Chip className={classes.chip} size="small" label={value} />;
};

const getClasses = makeStyles((theme: Theme) => {
  return {
    chip: {
      backgroundColor: theme.palette.warning.main,
      '& classˆ="MuiChip-label"': {
        ...theme.typography.body2,
      },
    },
  };
});
