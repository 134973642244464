import { Grid, Typography } from '@mui/material';
import { Fee } from 'ogp/services/billing-entity-service.types';
import { Fragment } from 'react';
import { formatCurrencyCents, formatDateFromString } from 'shared/utils/formatting';
import { feesLabels } from '../../utils/utils';

type FeeDetailsProps = {
  fee: Fee;
  index: number;
};

const FeeDetails = ({ fee, index }: FeeDetailsProps) => {
  const { startDate, endDate, hourlyRateCents, feeType } = fee;

  const formattedStartDate = formatDateFromString(startDate);
  const formattedEndDate = endDate ? formatDateFromString(endDate) : null;
  const formattedHourlyRate = `${formatCurrencyCents(hourlyRateCents)} / uur`;

  const timeSpan = formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : `${formattedStartDate} - voortdurende`;

  return (
    <Fragment key={index}>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="secondary" noWrap>
          {`${feesLabels[feeType]} #${index + 1}`}
        </Typography>
        <Typography variant="body1">{formattedHourlyRate}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle2" color="secondary" noWrap>
          Timespan
        </Typography>
        <Typography variant="body1">{timeSpan}</Typography>
      </Grid>
    </Fragment>
  );
};

export { FeeDetails };
