import { CheckoutsTypeProvider, useCheckoutsTypeProvider } from './checkouts-type-provider';
import { ProjectsCheckoutsInbox } from '../projects-checkouts/inbox/projects-checkouts-inbox';
import { CheckoutsInbox } from '../shifts-checkouts/inbox/checkouts-inbox';

const SharedCheckoutsInbox = () => {
  return (
    <CheckoutsTypeProvider>
      <CheckoutsInboxPage />
    </CheckoutsTypeProvider>
  );
};

const CheckoutsInboxPage = () => {
  const { type } = useCheckoutsTypeProvider();

  return type === 'shifts' ? <CheckoutsInbox /> : <ProjectsCheckoutsInbox />;
};

export { SharedCheckoutsInbox };
