import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { RouteDefinition } from 'Routes';
import { partnerRoutePrefix } from 'partner/settings';
import { useGetPartnerUserInfo } from './use-get-partner-user-info';

const partnerRelativePrefix = `${partnerRoutePrefix}/:partnerHash/`;

export const usePartnerRelativePath = () => {
  const userQuery = useGetPartnerUserInfo();

  return useCallback(
    (route: RouteDefinition, params?: { [key: string]: string | undefined }) => {
      // TODO: decide what to do if there is no partner

      return generatePath(`${partnerRelativePrefix}${route.path}`, {
        ...params,
        partnerHash: userQuery.data?.partner.slug ?? '',
      });
    },
    [userQuery.data]
  );
};
