import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  AccreditationInput,
  AccreditationInputCertificate,
  LwFormInput,
  LwFormSelect,
} from 'redesign';
import { useGetCertificates } from 'partner/shared/hooks/accreditations/use-get-certificates';
import { useMemo } from 'react';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { PartnerAuthenticatedElement } from 'partner/shared/components/partner-authenticated-element/partner-authenticated-element';
import { HqRole } from '@types';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { PartnerWorkerFormValues } from './partner-worker-form.types';
import { RenderUnsettledUI } from '../../../../../shared/components';
import { EmploymentType } from '../../../../shared/services/partner-worker-service.types';
import { formatEmploymentType } from '../../../../../shared/utils/formatting';

export const PartnerWorkerFormDataProvider = () => {
  const certificatesQuery = useGetCertificates();
  const { allowEmploymentId } = useFeatureFlagContext();

  if (certificatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={certificatesQuery} />;
  }

  return <PartnerWorkerForm data={certificatesQuery.data} allowEmploymentId={allowEmploymentId} />;
};

const PartnerWorkerForm = ({
  data,
  allowEmploymentId,
}: {
  data: AccreditationInputCertificate[];
  allowEmploymentId: boolean;
}) => {
  const { control } = useFormContext<PartnerWorkerFormValues>();
  const phoneNumberRegex = new RegExp('^\\+[0-9]+$');

  const employmentTypeOptions = useMemo(() => {
    return Object.keys(EmploymentType).map((item) => ({
      value: item,
      label: formatEmploymentType(item as keyof typeof EmploymentType),
    }));
  }, []);

  return (
    <Grid container item display="flex" spacing={2} xs={6}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <LwFormInput
            name="firstName"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
            label="Voornaam"
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput name="infix" label="Tussenvoegsel" control={control} />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <LwFormInput
            name="lastName"
            label="Achternaam"
            control={control}
            rules={{ required: 'Voer een achternaam in' }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormInput
            name="phone"
            inputType="tel"
            label="Telefoon"
            control={control}
            placeholder="+31612345678"
            rules={{
              required: 'Voer een telefoon in',
              pattern: {
                value: phoneNumberRegex,
                message: 'Voorbeeld: +31612345678',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <LwFormInput
            name="email"
            inputType="email"
            label="Email"
            control={control}
            rules={{ required: 'Voer een email in' }}
          />
        </Grid>
        <Grid item xs={6}>
          <LwFormSelect
            name="employmentType"
            control={control}
            options={employmentTypeOptions}
            label="Werkvorm"
            rules={{ required: 'Selecteer de juiste werkvorm' }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <AccreditationInput certificates={data} name="accreditations" required={false} />
        </Grid>
        {allowEmploymentId ? (
          <PartnerAuthenticatedElement roles={HqRole.SUPERADMIN} policyGroup={PolicyGroups.HQ}>
            <Grid item xs={6}>
              <LwFormInput name="employmentId" label="Kies employment ID" control={control} />
            </Grid>
          </PartnerAuthenticatedElement>
        ) : null}
      </Grid>
    </Grid>
  );
};
