import { useParams } from 'react-router-dom';
import { useGetBranchOffice } from 'ogp/hooks/queryHooks/branchoffices/use-get-branch-office';
import { routes } from 'ogp/Routes';
import { useCompanyRelativePath } from 'ogp/hooks';
import { Box, Grid, Typography } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { IconDuplicate, IconEdit } from 'assets/img';
import { vatLabel } from '@types';
import { GetBranchOfficeResponse } from '../../../../../services/branch-office-service.types';

const BranchOfficeDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  const branchOfficeQuery = useGetBranchOffice(id);

  if (branchOfficeQuery.status !== 'success') {
    return <RenderUnsettledUI data={branchOfficeQuery} />;
  }

  return <BranchOfficeDetail data={branchOfficeQuery.data} id={id} />;
};

const BranchOfficeDetail = ({
  data,
  id,
}: {
  data: GetBranchOfficeResponse['branchOffice'];
  id: string | undefined;
}) => {
  const generatePath = useCompanyRelativePath();

  const statistics: React.ComponentProps<typeof Header>['labelGroups'] = [
    {
      title: 'Bedrijfsnaam voor OVO',
      description: data.companyNameForOVO,
    },
    {
      title: 'Wettelijke vertegenwoordiger',
      description: data.legalPerson,
    },
    {
      title: 'KVK-nummer',
      description: data.cocNumber,
    },
  ];

  return (
    <Page
      header={
        <Header titleText={data.name} subheaderText="Vestigingsdetails" labelGroups={statistics} />
      }
      toolbox={
        <Toolbox>
          <LwButton to={generatePath(routes.BranchOffices)} color="secondary">
            Terug
          </LwButton>
          <LwButton
            to={generatePath(routes.BranchOfficesEdit, { id })}
            color="secondary"
            iconColorMode="fill"
            startIcon={<IconEdit />}
          >
            Vestiging aanpassen
          </LwButton>

          <LwButton
            to={generatePath(routes.BranchOfficesDuplicate, { id })}
            color="secondary"
            iconColorMode="fill"
            startIcon={<IconDuplicate />}
          >
            Vestiging dupliceren
          </LwButton>
        </Toolbox>
      }
    >
      <Box>
        <Grid container spacing={2} item xs={12} md={12} lg={8}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Adres
            </Typography>
            <Typography variant="body1">{data.location.address}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              BTW-tarief
            </Typography>
            <Typography variant="body1">{vatLabel(data.VAT)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Locatie (maps link)
            </Typography>
            <a href={data.locationUrl} rel="noopener noreferrer" target="_blank">
              {data.locationUrl}
            </a>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary" noWrap>
              Kostenplaats
            </Typography>
            <Typography variant="body1">{data.costCenter ?? '-'}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export { BranchOfficeDetailDataProvider };
