import { useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { CompanyDetailSelectModel } from 'hq/services/mappers/mappers';
import { Box } from '@mui/material';
import { Header, Page, Toolbox } from 'redesign';
import { CompanyDetailTabs } from './company-detail-tabs/company-detail-tabs';
import { CompanyDetailToolbox } from './company-detail-toolbox/company-detail-toolbox';
import { useGetCompanyAsDetail } from '../../../../../hooks/queryHooks/companies/use-get-company-detail';

export const CompanyDetailDataProvider = () => {
  const { id } = useParams<'id'>();
  invariant(id);

  const companyQuery = useGetCompanyAsDetail(id);

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  return <CompanyDetail data={companyQuery.data} />;
};

const CompanyDetail = ({ data }: { data: CompanyDetailSelectModel }) => {
  return (
    <Page
      header={<Header titleText={data.name} subheaderText="Bedrijfsdetails" />}
      toolbox={
        <Toolbox>
          <CompanyDetailToolbox isArchivable={data.isArchivable} companyId={data.id} />
        </Toolbox>
      }
    >
      <Box width="100%">
        <CompanyDetailTabs data={data} />
      </Box>
    </Page>
  );
};
