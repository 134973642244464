import { ValidationError } from 'shared/services/abstractApiService';
import { useAlertProvider } from './alert-provider';

const useAlert = () => {
  const { openAlert } = useAlertProvider();

  const alertSuccess = (message: string) => {
    openAlert({
      severity: 'success',
      message,
    });
  };

  const alertError = (error: unknown) => {
    openAlert({ severity: 'error', ...resolveErrorMessage(error) });
  };

  return {
    alertSuccess,
    alertError,
  };
};

const resolveErrorMessage = (error: unknown) => {
  if (error instanceof ValidationError) {
    const errorMessage = error.errors.message
      .map((individualError) => `${individualError.msg}\n`)
      .toString();
    return { message: errorMessage, error: new Error(errorMessage) };
  } else if (error instanceof Error) {
    return { message: error.message, error };
  }

  throw new Error('Error cannot be resolved.');
};

export { useAlert };
