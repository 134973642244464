import { userService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useGetUsers = () => {
  const queryFn = async () => await userService.getUsers();

  return useQuery(QUERY_KEYS_HQ.userList(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
  });
};
