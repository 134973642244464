import { LwCheckbox } from 'redesign';
import { Controller, FieldValues } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, styled } from '@mui/material';
import { FormCheckboxProps } from './form-checkbox.types';
import { StyledErrorText, StyledHelperTextWrapper } from '../../utils/helper-text';

const FormCheckbox = <ControlProps extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  checked,
  onChange,
  className,
  checkboxError,
}: FormCheckboxProps<ControlProps>) => {
  return (
    <CheckboxContainer>
      <FormControlLabel
        disabled={disabled}
        className={className}
        htmlFor={name}
        sx={{
          '& span.MuiFormControlLabel-label': {
            paddingLeft: '12px',
          },
        }}
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <LwCheckbox
                {...field}
                title={label}
                checked={field.value || checked || false}
                id={name}
                value={field.value ?? false}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (onChange && typeof onChange === 'function') {
                    onChange(e.target.checked);
                  }
                }}
                disabled={disabled}
              />
            )}
          />
        }
        label={label}
      />
      <StyledHelperTextWrapper className="error-text-wrapper">
        {checkboxError ? (
          <StyledErrorText className={'error-state'}>{checkboxError}</StyledErrorText>
        ) : null}
      </StyledHelperTextWrapper>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

export { FormCheckbox };
