import { RenderUnsettledUI } from 'shared/components';
import { ProjectPlanningFilters } from './project-planning-filters';
import { ProjectPlanningFiltersDataProviderProps } from './project-planning-filters.types';
import {
  useGetWorkPlanningBusinessUnitsData,
  useGetWorkPlanningJobTypesData,
} from '../../common/hooks/use-get-work-planning-filters-data';

export const ProjectPlanningDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: ProjectPlanningFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetWorkPlanningBusinessUnitsData();
  const jobTypeQuery = useGetWorkPlanningJobTypesData();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <ProjectPlanningFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
      }}
    />
  );
};
