import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { versionService } from 'ogp/services';
import { useQuery } from 'react-query';
import { OgpVersionInfo } from './site-info.types';

const useGetApiVersionInfo = () => {
  async function queryFn(): Promise<OgpVersionInfo> {
    return await versionService.getVersion();
  }

  return useQuery<Promise<OgpVersionInfo>, Error, OgpVersionInfo>(
    QUERY_KEYS_OGP.version(),
    queryFn,
    { enabled: true }
  );
};

export { useGetApiVersionInfo };
