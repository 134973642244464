import { EnvironmentService } from 'shared/services';
import { PartnerApiService } from './partner-api-service';
import { PartnerUserService } from './partner-user-service';
import { PartnerWorkerService } from './partner-worker-service';
import { PartnerInboxService } from './partner-inbox-service';
import { PartnerCheckedOutService } from './checked-outs-service';
import { PartnerCheckoutsService } from './partner-checkouts-service';
import { PartnerNotificationService } from './partner-notification-service';
import { PartnerPlannerService } from './partner-planner-service';
import { AccreditationService } from './accreditation-service';
import { PartnerPlacementService } from './partner-placements-service';

const endpoint = EnvironmentService.getVariable('BACKEND_PARTNER_URI');
const partnerApiService = new PartnerApiService({ endpoint });

export const partnerUserService = new PartnerUserService(partnerApiService);
export const partnerWorkerService = new PartnerWorkerService(partnerApiService);
export const partnerInboxService = new PartnerInboxService(partnerApiService);
export const partnerCheckedOutService = new PartnerCheckedOutService(partnerApiService);
export const partnerCheckoutsService = new PartnerCheckoutsService(partnerApiService);
export const partnerNotificationService = new PartnerNotificationService(partnerApiService);
export const partnerPlannerService = new PartnerPlannerService(partnerApiService);
export const accreditationService = new AccreditationService(partnerApiService);
export const partnerPlacementService = new PartnerPlacementService(partnerApiService);
