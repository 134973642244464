import { flexWorkerService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useActivateFlexWorker = (flexWorkerId: string, options?: UseMutationOptions) => {
  const mutateFn = async () => await flexWorkerService.activateFlexWorker(flexWorkerId);

  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.completeFlexWorkers());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.flexWorker(flexWorkerId));

      options?.onSuccess && options.onSuccess(...args);
    },
  });
};
