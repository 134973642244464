import { Column } from 'react-table';
import { Box, Tooltip, Typography } from '@mui/material';
import { IconCertificate } from 'assets/img';
import { ContextMenuButton } from 'redesign';
import {
  PartnerPlannerTableCellProp,
  PartnerPlannerTableDefinition,
} from './partner-planner-modal-table.types';

export const getPlannerModalTableDefinition: () => Column<PartnerPlannerTableDefinition>[] = () => {
  const name: Column<PartnerPlannerTableDefinition> = {
    Header: 'Naam',
    accessor: 'name',
    Cell: NameCell,
    width: '200px',
  };
  const email: Column<PartnerPlannerTableDefinition> = {
    Header: 'E-mailadres',
    accessor: 'email',
    Cell: EmailCell,
  };
  const phone: Column<PartnerPlannerTableDefinition> = {
    Header: 'Telefoonnummer',
    accessor: 'phone',
    Cell: PhoneCell,
    width: '150px',
  };
  const certificate: Column<PartnerPlannerTableDefinition> = {
    Header: '',
    accessor: 'hasRequiredCertificates',
    Cell: CertificateCell,
    width: '24px',
  };
  const actions: Column<PartnerPlannerTableDefinition> = {
    Header: '',
    accessor: 'actions',
    Cell: ActionCell,
    width: '50px',
  };

  return [name, email, phone, certificate, actions];
};

const NameCell = (cell: PartnerPlannerTableCellProp<'name'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const EmailCell = (cell: PartnerPlannerTableCellProp<'email'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const PhoneCell = (cell: PartnerPlannerTableCellProp<'phone'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const CertificateCell = (cell: PartnerPlannerTableCellProp<'hasRequiredCertificates'>) => {
  return cell.value ? (
    <Tooltip
      title="Deze flexwerker is in het bezit van alle benodigde certificaten"
      aria-label="certificate-check"
    >
      <Box>
        <IconCertificate width={24} />
      </Box>
    </Tooltip>
  ) : null;
};

const ActionCell = (cell: PartnerPlannerTableCellProp<'actions'>) => {
  const actions = cell.getRowActions({
    workerId: cell.value.workerId,
    shiftClaimId: cell.value.id,
  });

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${cell.value.id}`}>
      {actions}
    </ContextMenuButton>
  );
};
