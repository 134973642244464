import { useQuery } from 'react-query';
import { CompanyListItemBasicData } from '@types';
import { companyService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useGetCompaniesForFlexWorker = (flexWorkerId: string) => {
  const queryFn = async () => await companyService.getCompaniesForFlexWorker(flexWorkerId);
  const options = {
    enabled: flexWorkerId.length > 0,
  };

  return useQuery<CompanyListItemBasicData[], Error>(QUERY_KEYS_HQ.companies(), queryFn, options);
};
