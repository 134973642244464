import {
  Box,
  IconButton,
  styled,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { IconCandidates, IconFlexPools } from 'assets/img';
import { formatFullName } from 'shared/utils/formatting';
import { CandidateInfo, CandidateState } from '../../../../../../shared/services/planning.types';

const NamesList = ({
  candidates,
  fontColor = 'black',
  sx,
}: {
  candidates: CandidateInfo[];
  fontColor?: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <NameContainer>
      {candidates.map((candidate) => (
        <Box key={`${candidate.lastName}-${candidate.firstName}`}>
          <Typography variant="body2" color={fontColor} sx={sx}>
            {formatFullName(candidate)}
          </Typography>
        </Box>
      ))}
    </NameContainer>
  );
};

export const CandidateStateTooltip = ({ candidateState }: { candidateState: CandidateState }) => {
  const numberOfApplicants = candidateState.applicants.length;
  const hasApplicants = numberOfApplicants > 0;
  const numberOfClaimants = candidateState.claimants.length;
  const hasClaimants = numberOfClaimants > 0;

  return (
    <StyledTooltip
      data-testid="planning-tooltip"
      title={
        <Container>
          {candidateState.flexPoolOnly ? (
            <Row>
              <FlexpoolsRow>
                <FlexpoolsIcon />
                <Typography variant="body2">Flexpool required</Typography>
              </FlexpoolsRow>
            </Row>
          ) : null}
          <Row>
            <Typography>
              Claims: {numberOfClaimants}/{candidateState.maxClaimants}
            </Typography>
            {hasClaimants ? (
              <NamesList candidates={candidateState.claimants} sx={{ fontWeight: 'bold' }} />
            ) : null}
          </Row>
          <Row>
            <Typography>Aanmeldingen: {numberOfApplicants}</Typography>
            {hasApplicants ? (
              <NamesList candidates={candidateState.applicants} fontColor="#808080" />
            ) : null}
          </Row>
        </Container>
      }
    >
      <CandidateIconButton>
        <Box>
          <IconCandidates />
          {hasApplicants ? <ApplicantsInfo>+{numberOfApplicants}</ApplicantsInfo> : null}
        </Box>
      </CandidateIconButton>
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[300],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    color: 'white',
  },
}));

const ApplicantsInfo = styled(Box)(() => ({
  position: 'absolute',
  fontSize: '10px',
  left: '26px',
  top: '0px',
  color: 'black',
}));

const CandidateIconButton = styled(IconButton)(({ theme }) => ({
  width: '35px',
  height: '35px',
  padding: theme.spacing(0.5),
  '&.MuiButtonBase-root.MuiIconButton-root:hover': {
    backgroundColor: '#D9D9D9',
  },
  marginLeft: theme.spacing(1),
}));

const Container = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(3),
    '&:not(:has(div))': {
      paddingBottom: theme.spacing(5),
    },
  },
}));

const Row = styled(Box)(({ theme }) => ({
  '& > :not(:last-child)': {
    paddingBottom: theme.spacing(2),
  },
}));

const FlexpoolsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& > :not(:last-child)': {
    paddingRight: theme.spacing(1),
  },
}));

const FlexpoolsIcon = styled(IconFlexPools)(() => ({
  width: '32px',
  height: '32px',
}));

const NameContainer = styled(Box)(({ theme }) => {
  const columnGap = theme.spacing(3);
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1)} ${columnGap}`,
    '& > div': {
      minWidth: `calc(50% - ${columnGap} / 2)`,
    },
  };
});
