import { SearchBar, Toolbox } from 'redesign';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useMemo, useState } from 'react';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { Box } from '@mui/material';
import { FlexWorkersForShiftFilterOptions } from 'shared/components/flex-workers-for-work-table/flex-workers-for-work.types';
import { ShiftAddFlexWorkerFilters } from './shift-add-flex-worker-filters';
import { ShiftAddFlexWorkerTableProvider } from './shift-add-flex-worker-table-provider';
import { FlexWorkerTableOptions } from '../../../../flex-workers-for-work-table/flex-workers-for-work-table.types';

type Props = {
  shiftDetails: {
    shiftId: string;
    isFreelance: boolean;
    isTempWork: boolean;
  };
  getRowActions: FlexWorkerTableOptions['getRowActions'];
};

export const ShiftAddFlexWorker = ({ ...props }: Props) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput, 800);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const allowedEmploymentTypes = [
    { isAllowed: props.shiftDetails.isFreelance, value: 'FREELANCER' as const },
    { isAllowed: props.shiftDetails.isTempWork, value: 'TEMPWORKER' as const },
  ]
    .filter((e) => e.isAllowed)
    .map((e) => e.value);

  const [filterValues, setFilterValues] = useState<FlexWorkersForShiftFilterOptions>(
    allowedEmploymentTypes.length >= 2
      ? {
          isFreelancer: props.shiftDetails.isFreelance,
          isTempWorker: props.shiftDetails.isTempWork,
        }
      : {}
  );

  const handleOpeneningFilters = (val: boolean) => {
    setIsOpen(val);
  };

  const numberOfAppliedFilters = useMemo(
    () => Object.values(filterValues).filter((f) => f !== undefined && f !== '0' && f).length,
    [filterValues]
  );

  return (
    <>
      <Box display="flex" marginTop={4} marginBottom={8}>
        <SearchBar onChange={setSearchInput} placeholder="Flex werker naam" />
        <Toolbox.PushRight>
          <ShiftAddFlexWorkerFilters
            handleOpeneningFilters={handleOpeneningFilters}
            isOpen={isOpen}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            allowedEmploymentTypes={allowedEmploymentTypes}
          />
          <FilterButton
            onClick={() => handleOpeneningFilters(true)}
            nrOfActiveFilters={numberOfAppliedFilters}
          />
        </Toolbox.PushRight>
      </Box>
      <ShiftAddFlexWorkerTableProvider
        filters={{ fullNameSearch: debouncedSearchInput || undefined, ...filterValues }}
        {...props}
        shiftId={props.shiftDetails.shiftId}
      />
    </>
  );
};
