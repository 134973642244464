import { DateTime } from 'luxon';
import { ProjectPlanningFilters } from './hooks/use-project-planning-filters.types';

export const stringifyProjectFilters = (
  appliedFilters: ProjectPlanningFilters
): { [key: string]: string } | undefined => {
  const result: { [key: string]: string } = {};

  Object.entries(appliedFilters).forEach(([key, value]) => {
    if (value == null || value == '') {
      return;
    }

    if (value instanceof DateTime) {
      result[key] = value.toISODate();
      return;
    }
    result[key] = JSON.stringify(value);
  });

  if (Object.keys(result).length === 0) {
    return undefined;
  }

  return result;
};
