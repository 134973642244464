import { RenderUnsettledUIProps } from './render-unsettled-ui.types';
import { StateError, StateLoading } from '../common/state/State';

const RenderUnsettledUI = <T,>({
  renderError,
  renderLoading,
  data: { status, error, refetch },
}: RenderUnsettledUIProps<T>) => {
  if (status === 'loading' || status === 'idle') {
    return renderLoading ? <>{renderLoading}</> : <StateLoading />;
  }

  if (status === 'error') {
    return renderError ? <>{renderError(error as Error, refetch)}</> : <StateError error={error} />;
  }

  return null;
};

export { RenderUnsettledUI };
