import { Box } from '@mui/material';
import { IconApproved, IconCancel, IconEdit, IconTrash } from 'assets/img';
import { AuthenticatedElement } from 'hq/components/common/authenticated-element';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { HqRole } from '@types';
import { useCallback } from 'react';
import { useArchiveCompany } from './hooks/use-archive-company';
import { companyAlerts, useAlert } from '../../../../../../../shared/components/alerts';
import {
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from '../../../../../../../ogp/providers/dialog-provider';
import { Dialog } from '../../../../../../../ogp/providers/dialog-provider/components/buttons.types';

export const CompanyDetailToolbox = ({
  isArchivable,
  companyId,
}: {
  isArchivable: boolean;
  companyId: string;
}) => {
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const archiveCompany = useArchiveCompany();

  const dialogActions: Dialog = isArchivable
    ? {
        title: 'Weet je zeker dat je deze opdrachtgever wilt verwijderen?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => (
          <SecondaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </SecondaryButton>
        ),
      }
    : {
        title:
          'Het is niet mogelijk om deze opdrachtgever te verwijderen aangezien er nog actieve plaatsingen zijn.',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: () => <></>,
      };

  const handleArchiveCompany = useCallback(() => {
    openDialog(dialogActions, () => {
      if (!isArchivable) {
        return;
      }
      disableDialog();
      archiveCompany.mutate(companyId, {
        onSuccess: () => {
          closeDialog();
          alertSuccess(companyAlerts.success.deleted);
          navigate(generatePath(hqRoutes.Companies));
        },
        onError: (e) => alertError(e),
        onSettled: () => enableDialog(),
      });
    });
  }, []);

  return (
    <>
      <Box mt={2} display="flex" flexDirection={'row'} gap={2}>
        <LwButton
          onClick={() => {
            navigate(generatePath(hqRoutes.Companies));
          }}
          color="secondary"
          iconColorMode="fill"
          startIcon={<IconCancel />}
        >
          Terug
        </LwButton>
        <LwButton
          onClick={() => {
            navigate(generatePath(hqRoutes.CompaniesEdit, { id: companyId }));
          }}
          color="secondary"
          startIcon={<IconEdit />}
        >
          Bedrijf aanpassen
        </LwButton>
        <AuthenticatedElement roles={HqRole.SUPERADMIN} policyGroup={PolicyGroups.HQ}>
          <LwButton onClick={handleArchiveCompany} color="secondary" startIcon={<IconTrash />}>
            Verwijderen
          </LwButton>
        </AuthenticatedElement>
      </Box>
    </>
  );
};
