import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerInboxService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type MarkMessageAsUnreadParams = {
  messageId: string;
};

export const useMarkMessageAsUnread = () => {
  const mutationFn = async ({ messageId }: MarkMessageAsUnreadParams) =>
    await partnerInboxService.markInboxMessageAsUnread(messageId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerInboxMessages());
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerInboxMessage(variables.messageId));
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.notifications());
    },
  });
};
