import React from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';

type VirtualTableData<T> = {
  rows: T[];
  tableContainerRef: React.RefObject<HTMLDivElement>;
};

type TableData = {
  paddingTop: number;
  paddingBottom: number;
  visibleRows: VirtualItem[];
};

export function useTableData<T>(data: VirtualTableData<T>): TableData {
  const rowVirtualizer = useVirtual({
    parentRef: data.tableContainerRef,
    size: data.rows.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return {
    paddingTop,
    paddingBottom,
    visibleRows: virtualRows,
  };
}
