import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { CancelledTableProject } from './cancelled-table-project';
import { useGetProjectCancellations } from './hooks/use-get-cancellations';

type Props = {
  projectId: string;
  hourlyRateCents: number;
};

export const CancelledTableProvider = ({ projectId, hourlyRateCents }: Props) => {
  const query = useGetProjectCancellations(projectId);

  if (query.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={query}
      />
    );
  }

  return <CancelledTableProject data={query.data} hourlyRateCents={hourlyRateCents} />;
};
