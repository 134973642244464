import {
  FlexWorkersForProjectFilterOptions,
  FlexWorkersForProjectFilterOptionsStrings,
  FlexWorkersForShiftFilterOptions,
  FlexWorkersForShiftFiltersStrings,
  MaxDistance,
} from '../flex-workers-for-work.types';

const stringFilterToBoolean = (value: string | undefined) => {
  switch (value) {
    case '1':
      return true;
    case '0':
      return false;
    default:
      return undefined;
  }
};

const booleanFilterToString = (value: boolean | undefined) => {
  switch (value) {
    case true:
      return '1';
    case false:
      return '0';
    default:
      return undefined;
  }
};

// TODO this can definitely be refactored

export const parseFlexWorkersForShiftFilters = (props: FlexWorkersForShiftFiltersStrings) => {
  const res: FlexWorkersForShiftFilterOptions = {};
  res.flexPoolOnly = stringFilterToBoolean(props.flexPoolOnly);
  res.maxDistance =
    props.maxDistance !== undefined && Number(props.maxDistance) !== 0
      ? (Number(props.maxDistance) as MaxDistance)
      : undefined;
  res.isAvailable = stringFilterToBoolean(props.isAvailable);
  res.workedForDepartment = stringFilterToBoolean(props.workedForDepartment);
  res.hasCertificate = stringFilterToBoolean(props.hasCertificate);
  res.availabilityStatus = props.availabilityStatus;
  res.isFreelancer = stringFilterToBoolean(props.isFreelancer);
  res.isTempWorker = stringFilterToBoolean(props.isTempWorker);

  return res;
};

export const stringifyFlexWorkersForShifts = (props: FlexWorkersForShiftFilterOptions) => {
  const res: FlexWorkersForShiftFiltersStrings = {};
  res.flexPoolOnly = booleanFilterToString(props.flexPoolOnly);
  res.maxDistance = props.maxDistance ? String(props.maxDistance) : undefined;
  res.isAvailable = booleanFilterToString(props.isAvailable);
  res.workedForDepartment = booleanFilterToString(props.workedForDepartment);
  res.hasCertificate = booleanFilterToString(props.hasCertificate);
  res.isFreelancer = booleanFilterToString(props.isFreelancer);
  res.isTempWorker = booleanFilterToString(props.isTempWorker);

  return res;
};

export const stringifyFlexWorkersForProjectFilters = (
  props: FlexWorkersForProjectFilterOptions
) => {
  const res: FlexWorkersForProjectFilterOptionsStrings = {};
  res.flexPoolOnly = booleanFilterToString(props.flexPoolOnly);
  res.maxDistance = props.maxDistance ? String(props.maxDistance) : undefined;
  res.workedForDepartment = booleanFilterToString(props.workedForDepartment);
  res.hasCertificate = booleanFilterToString(props.hasCertificate);

  return res;
};

export const parseFlexWorkersForProjectFilters = (
  props: FlexWorkersForProjectFilterOptionsStrings
) => {
  const res: FlexWorkersForProjectFilterOptions = {};
  res.flexPoolOnly = stringFilterToBoolean(props.flexPoolOnly);
  res.maxDistance =
    props.maxDistance && props.maxDistance !== '0'
      ? (Number(props.maxDistance) as MaxDistance)
      : undefined;
  res.workedForDepartment = stringFilterToBoolean(props.workedForDepartment);
  res.hasCertificate = stringFilterToBoolean(props.hasCertificate);

  return res;
};
