import { Grid } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToolboxFormCreate } from 'shared/components/common/toolbox-form-create';
import { ProjectFormType } from 'shared/components/project-forms/types/project-form.types';
import { Header, Page } from 'redesign';
import { useAlert } from 'shared/components/alerts';
import { JobListItem } from '@types';
import { CompanyInput } from 'shared/components/shift-forms/components/forms/shared/inputs/company-input';
import { HqProjectCreatePayload } from 'hq/services/project-planning-service.types';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { stringifyFilters } from 'shared/utils/stringify-filters';
import { GetCompaniesResponse } from 'hq/services/company-service.types';
import { ProjectFormProvider } from './project-form-provider';
import { useProjectPlanningPage } from '../../table/hooks/use-project-planning';
import { useEditProject } from '../../hooks/use-edit-project';

type ProjectCreateProps = {
  jobsData: JobListItem[];
  companies: GetCompaniesResponse[];
  defaultValues?: Partial<ProjectFormType>;
  successMessage: string;
  pageTitle: string;
  projectId: string;
  isProjectWithActivePlacement: boolean;
};

type HqProjectFormType = ProjectFormType & { companyId: string };

export const ProjectEditFormPage = ({
  jobsData,
  companies,
  defaultValues = {},
  successMessage,
  pageTitle,
  projectId,
  isProjectWithActivePlacement,
}: ProjectCreateProps) => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<HqProjectFormType>({
    defaultValues,
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const mutation = useEditProject();
  const { params } = useProjectPlanningPage();

  const { companyId } = formMethods.watch();

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data: HqProjectFormType) => {
      const payload: HqProjectCreatePayload = {
        jobId: data.jobId,
        startDate: data.startDate,
        endDate: data.endDate,
        weekHourVolume: +data.weeklyHours,
        hourlyRateCents: Math.round(+data.hourlyRate * 100),
      };
      mutation.mutate(
        { projectId, projectData: payload },
        {
          onSuccess: () => {
            alertSuccess(successMessage);
            navigate(
              generatePath(
                hqRoutes.PlannerProject,
                {},
                { ...stringifyFilters(params), projectDetailId: projectId }
              )
            );
          },
          onError: (e) => alertError(e),
        }
      );
    })();
  }, [projectId]);

  const onCancel = useCallback(
    () => navigate(generatePath(hqRoutes.PlannerProject, stringifyFilters(params))),
    [params, generatePath, navigate]
  );

  const memoizedCompanies = useMemo(
    () => companies.map((i) => ({ label: i.name, value: i.id })),
    [companies]
  );

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText={pageTitle} />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={6}>
            <CompanyInput options={memoizedCompanies} disabled={true}></CompanyInput>
            {companyId ? (
              <ProjectFormProvider
                companyId={companyId}
                jobsData={jobsData}
                isProjectWithActivePlacement={isProjectWithActivePlacement}
              />
            ) : null}
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
