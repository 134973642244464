import { Table } from 'redesign';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { ReactNode } from 'react';
import { getClaimantTableData } from './helpers/get-claimant-table-data';
import { getClaimantColumnDefinition } from './helpers/get-claimant-column-definition';
import { ClaimantColumnDefinition, ClaimantsTableOptions } from './helpers/claimant-table.types';
import { ShiftClaimant, ShiftDetailData } from './claimant-table.types';

type Props = {
  shiftDetails: ShiftDetailData;
  shiftClaimants: ShiftClaimant[];
  getActions: (candidate: ShiftClaimant, shiftData: ShiftDetailData) => ReactNode[];
};

function ClaimantTable({ shiftDetails, shiftClaimants, getActions }: Props) {
  const columns = getClaimantColumnDefinition();
  const tableData = getClaimantTableData(shiftClaimants);
  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();
  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    openPopupProfile(flexWorkerId, relation);
  };

  return shiftClaimants.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn geen flexwerkers ingeschreven!"
      subtitle="Er hebben zich nog geen flexwerkers aangemeld voor deze shift."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<ClaimantColumnDefinition, ClaimantsTableOptions>
        stickyHeader={true}
        rowPropGetter={(_, rowDefinition) => {
          const isNoshow = rowDefinition.row.original.noShow;
          if (isNoshow) {
            return { style: { opacity: 0.5 } };
          }
          return {};
        }}
        columns={columns}
        data={tableData}
        onProfileClick={onProfileClick}
        getRowActions={(candidate) => getActions(candidate, shiftDetails)}
      />
    </>
  );
}

export { ClaimantTable };
