import { useFormContext } from 'react-hook-form';
import { LwFormSelect } from 'redesign';

type BreakMinutesFields = {
  breakMinutes: number;
};

export const BreakMinutesInput = ({
  options,
  disabled,
  helperText,
}: {
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
  helperText: React.ComponentProps<typeof LwFormSelect>['helperText'];
}) => {
  const { control } = useFormContext<BreakMinutesFields>();
  return (
    <LwFormSelect
      name="breakMinutes"
      label="Pauze"
      defaultLabel="Selecteer de lengte van de pauze..."
      options={options}
      control={control}
      rules={{ required: 'Selecteer een pauze of geef aan dat er geen pauze is' }}
      disabled={disabled}
      data-testid="break-mins"
      helperText={helperText}
    />
  );
};
