import { useAlert } from 'shared/components/alerts';
import { partnersAlerts } from 'shared/components/alerts/alert-messages';
import { useNavigate } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';

import { FormProvider, useForm } from 'react-hook-form';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useCallback } from 'react';
import { PartnerFormData } from './partner-form.types';
import { PartnerForm } from './partner-form';
import { useCreatePartner } from './hooks/use-create-partner';

export const PartnerCreate = ({ defaultValues }: { defaultValues?: Partial<PartnerFormData> }) => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const createPartner = useCreatePartner({
    onSuccess: () => {
      alertSuccess(partnersAlerts.success.created);
      navigate(generatePath(hqRoutes.Partners));
    },
    onError: (e) => alertError(e),
  });
  const formMethods = useForm<PartnerFormData>({
    defaultValues,
  });
  const { submitButtonProps, cancelButtonProps } = useFormButtons(createPartner.status);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) => createPartner.mutate(data))();
  }, [formMethods, createPartner]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe partner aanmaken" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Maak aan
            </LwButton>
            <LwButton
              onClick={() => navigate(generatePath(hqRoutes.Partners))}
              iconColorMode="fill"
              {...cancelButtonProps}
            >
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <PartnerForm onSubmit={handleSubmit} />
      </Page>
    </FormProvider>
  );
};
