import { useGetJobCreateData } from 'ogp/hooks/queryHooks/jobs/useGetJobCreateData';
import { RenderUnsettledUI } from 'shared/components';
import { useParams } from 'react-router-dom';
import { useGetJob } from 'shared/hooks/query-hooks/use-get-job';
import { useGetCompanyEmploymentTypes } from 'ogp/hooks/queryHooks/companies/use-get-company-employment-types';
import { JobCreatePage } from './job-create-page';
import { jobAlerts } from '../../../../../../../shared/components/alerts';
import { encodeJobToJobForm } from '../utils';

export const JobDuplicateDataProvider = () => {
  const { id } = useParams<'id'>();
  const jobQuery = useGetJob(id);
  const jobCreateDataQuery = useGetJobCreateData();
  const employmentType = useGetCompanyEmploymentTypes();

  if (jobCreateDataQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobCreateDataQuery} />;
  }

  if (jobQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobQuery} />;
  }

  if (employmentType.status !== 'success') {
    return <RenderUnsettledUI data={employmentType} />;
  }

  const defaultValues = encodeJobToJobForm(jobQuery.data);

  return (
    <JobCreatePage
      jobCreateData={jobCreateDataQuery.data}
      pageTitle={`Functie ${defaultValues.name} dupliceren`}
      defaultValues={defaultValues}
      successMessage={jobAlerts.success.created}
      employmentType={employmentType.data}
    />
  );
};
