import { useExternalParams } from 'shared/hooks/use-external-params';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { ProjectPlanningFilters } from './use-project-planning-filters.types';
import { stringifyProjectFilters } from '../stringify-project-filters';
import { FilledStatus } from '../../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status.types';

const initialValues = {
  toDate: {
    initialValue: DateTime.now(),
    type: 'date',
  },
  companyId: {
    initialValue: undefined,
    type: 'string',
  },
  branchOfficeId: {
    initialValue: undefined,
    type: 'string',
  },
  departmentId: {
    initialValue: undefined,
    type: 'string',
  },
  filledStatus: {
    initialValue: undefined,
    type: 'string',
  },
  hasApplicants: {
    initialValue: undefined,
    type: 'boolean',
  },
  jobType: {
    initialValue: undefined,
    type: 'array',
  },
  workerId: {
    initialValue: undefined,
    type: 'string',
  },
  projectId: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export const useProjectPlanningFilters = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters: ProjectPlanningFilters = useMemo(() => {
    return {
      toDate: params.toDate || undefined,
      companyId: params.companyId || undefined,
      branchOfficeId: params.branchOfficeId || undefined,
      departmentId: params.departmentId || undefined,
      filledStatus: (params.filledStatus as FilledStatus) || undefined,
      hasApplicants: params.hasApplicants ?? undefined,
      jobType: ((params.jobType || undefined) as string[]) ?? undefined,
      workerId: params.workerId ?? undefined,
    };
  }, [params]);

  const selectedFilterCount = Object.values(filters).filter((value) => {
    return value !== null && value !== undefined && value !== '';
  }).length;

  const strigifiedFilters = useMemo(() => stringifyProjectFilters(filters), [filters]);
  const setProjectId = (projectId: string | undefined) => setParams({ projectId });

  return {
    filters,
    setFilters: setParams,
    selectedFilterCount,
    strigifiedFilters: strigifiedFilters,
    projectId: params.projectId,
    setProjectId,
  };
};
