import { shiftClaimService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from '../../../../../../../query-keys';

export const useCancelShiftClaim = () => {
  const mutationFn = async (shiftClaimId: string) =>
    await shiftClaimService.cancelShiftClaim(shiftClaimId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftClaims());
    },
  });
};
