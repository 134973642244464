import { HqApiService } from './hqApiService';
import { WorkApplicationTableItem } from '../../shared/services/work-application-service.types';

export class WorkApplicationService {
  constructor(private readonly hqApiService: HqApiService) {}

  workApplicationsForProject = async (projectId: string) => {
    const response = await this.hqApiService.get<WorkApplicationTableItem[]>({
      path: `work-applications/project/${projectId}`,
    });
    return response;
  };

  denyWorkApplication = async (workApplicationId: string, workerId: string) => {
    await this.hqApiService.update<void>({
      path: `work-applications/deny/${workApplicationId}`,
      body: { workerId },
    });
  };

  acceptWorkApplication = async (workApplicationId: string, workerId: string) => {
    await this.hqApiService.update<void>({
      path: `work-applications/accept/${workApplicationId}`,
      body: { workerId },
    });
  };
}
