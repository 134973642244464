import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign';

interface ShiftDetailProps {
  shiftId: string;
}

export const ShiftClaimPlanningDetail = ({ shiftId }: ShiftDetailProps) => {
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();

  return (
    <LwButton
      color="secondary"
      onClick={() =>
        navigate(generatePath(hqRoutes.PlannerShift, undefined, { shiftDetailId: shiftId }))
      }
    >
      Planning detail
    </LwButton>
  );
};
