import { CollectiveInvoicesFormPayload } from 'hq/components/views/finance/collective-invoices';
import { HqApiService } from './hqApiService';

class CollectiveInvoicesService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async generateCollectiveInvoice(data: CollectiveInvoicesFormPayload) {
    return await this.hqApiService.create({
      body: data,
      path: 'collective-invoices',
    });
  }
}

export { CollectiveInvoicesService };
