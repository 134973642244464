import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useQuery } from 'react-query';

const useGetWorkPlanningBusinessUnitsData = () => {
  return useQuery({
    queryKey: QUERY_KEYS_HQ.businessUnits(),
    queryFn: async () => await shiftPlanningService.getShiftPlanningBusinessUnitsData(),
  });
};

const useGetWorkPlanningJobTypesData = () => {
  return useQuery({
    queryKey: QUERY_KEYS_HQ.jobTypes(),
    queryFn: async () => await shiftPlanningService.getShiftPlanningJobTypesData(),
  });
};

export { useGetWorkPlanningJobTypesData, useGetWorkPlanningBusinessUnitsData };
