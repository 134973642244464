export const MINIMUM_DATE = '2021-01-01';

export const getMinDate = ({
  allowDateBeforeToday,
}: {
  allowDateBeforeToday: boolean | undefined;
}) => {
  if (allowDateBeforeToday) {
    return { minDate: new Date(MINIMUM_DATE).toISOString().split('T')[0], minTime: null };
  }

  const currentDate = new Date();
  const minDate = currentDate.toISOString().split('T')[0];
  const minTime = currentDate.toTimeString().split(':').slice(0, 2).join(':');

  return {
    minDate,
    minTime,
  };
};
