import React from 'react';

const useToggle = (initialValue = false) => {
  const [isOn, setIsOn] = React.useState(initialValue);

  return {
    on: isOn,
    toggle: React.useCallback(() => setIsOn((cur) => !cur), []),
    setOn: React.useCallback(() => setIsOn(true), []),
    setOff: React.useCallback(() => setIsOn(false), []),
    reset: React.useCallback(() => setIsOn(initialValue), [initialValue]),
  };
};

export { useToggle };
