import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { JobPayload } from '@types';
import { CreateJobResponse } from '../../../services/job-service.types';

type JobMutationOptions = UseMutationOptions<CreateJobResponse, unknown, JobPayload>;

export const useCreateJob = () => {
  const mutationFn = async (job: JobPayload) => await jobService.createJob(job);

  const queryClient = useQueryClient();
  const mutationOptions: JobMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.jobList());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
