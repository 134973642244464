import { Table } from 'redesign';
import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { getCancelledColumnDefinitionProject } from './get-cancelled-column-definition-project';
import { getCancelledTableDataProject } from './get-cancelled-table-data-project';
import {
  CancelledColumnDefinitionProject,
  CancelledTableOptionsProject,
} from '../types/cancelled-table-project.types';
import { ProjectCancellation } from '../../../../../services/project-service.types';

type Props = {
  data: ProjectCancellation[];
  hourlyRateCents: number;
};

export function CancelledTableProject({ data, hourlyRateCents }: Props) {
  const columns = getCancelledColumnDefinitionProject();
  const tableData = getCancelledTableDataProject(data, hourlyRateCents);

  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();
  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    openPopupProfile(flexWorkerId, relation);
  };

  return tableData.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn geen annuleringen gevonden!"
      subtitle="Er zijn geen aanmeldingen voor dit project geannuleerd."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<CancelledColumnDefinitionProject, CancelledTableOptionsProject>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        onProfileClick={onProfileClick}
      />
    </>
  );
}
