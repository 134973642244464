import { useGetFlexWorkers } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkers';
import { useCallback, useMemo } from 'react';
import { Header, LWModal, Page, Toolbox } from 'redesign';
import { UseQueryResult } from 'react-query';
import { FlexWorkerFilterCounts, FlexWorkerListItem, PaginatedCollection } from '@types';
import { FlexWorkerToolbox } from './flex-worker-toolbox/flex-worker-toolbox';
import {
  FLEX_WORKERS_INITIAL_COUNTS,
  FLEX_WORKERS_INITIAL_PARAMS,
  FlexWorkerParams,
  isEmploymentTypeFilter,
  isLevelTypeFilter,
  SetFlexWorkerParams,
} from './flex-workers.types';

import { FlexWorkerContent } from './flex-worker-content';
import { FlexWorkerDetail } from './flex-worker-detail/flex-worker-detail';
import { useExternalParams } from '../../../../shared/hooks';
import { RenderUnsettledUI, SmallLoader } from '../../../../shared/components';

const FlexWorkers = () => {
  const [params, setParams] = useExternalParams(FLEX_WORKERS_INITIAL_PARAMS);

  const { level, employmentType, disabled, page, size } = params;

  if (
    !(
      isLevelTypeFilter(level) &&
      isEmploymentTypeFilter(employmentType) &&
      page != null &&
      size != null &&
      disabled != null
    )
  ) {
    return <SmallLoader />;
  }

  return (
    <FlexWorkersImpl
      params={{ ...params, level, employmentType, disabled, size, page }}
      setParams={setParams}
    />
  );
};

const FlexWorkersImpl = ({
  params,
  setParams,
}: {
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
}) => {
  const closeModal = useCallback(() => setParams({ flexWorkerId: undefined }), [setParams]);
  const openModal = useCallback((flexWorkerId: string) => setParams({ flexWorkerId }), [setParams]);

  const flexWorkersQuery = useGetFlexWorkers({
    level: params.level,
    employmentType: params.employmentType,
    disabled: params.disabled,
    filter: params.filter,
    page: params.page,
    size: params.size,
  });

  const fwData = useMemo(() => setFlexworkersData(flexWorkersQuery), [flexWorkersQuery]);

  const pagination = useMemo(
    () => ({
      page: params.page as number,
      setPage: (page: number) => setParams({ page }),
      setSize: (size: number) => setParams({ size }),
      size: params.size as number,
    }),
    [params]
  );

  return (
    <>
      <Page
        header={<Header titleText="Flexwerkers" />}
        toolbox={
          <Toolbox>
            <FlexWorkerToolbox
              params={params as FlexWorkerParams}
              setParams={setParams}
              data={fwData}
              status={flexWorkersQuery.status}
            />
          </Toolbox>
        }
      >
        {flexWorkersQuery.status === 'success' ? (
          <FlexWorkerContent
            filter={params.filter}
            fwData={fwData}
            openModal={openModal}
            pagination={pagination}
          />
        ) : (
          <RenderUnsettledUI data={flexWorkersQuery} />
        )}
      </Page>
      <LWModal isOpen={!!params.flexWorkerId} onClose={closeModal} testId="detail-modal">
        {params.flexWorkerId ? (
          <FlexWorkerDetail id={params.flexWorkerId} onClose={closeModal} />
        ) : null}
      </LWModal>
    </>
  );
};

function setFlexworkersData(
  query: UseQueryResult<PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts, Error>
): PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts {
  if (query.status === 'success') {
    return query.data;
  }

  const data = {
    incompleteCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    explorerCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    starterCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    workerCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    candidateCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    disabledCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    totalCount: FLEX_WORKERS_INITIAL_COUNTS.incompleteCount.initialValue,
    items: [],
    unfilteredCount: FLEX_WORKERS_INITIAL_COUNTS.unfilteredCount.initialValue,
  };

  return data;
}

export { FlexWorkers };
