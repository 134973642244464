import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService } from 'hq/services';
import { HQDuplicateShiftPayload } from 'hq/services/shift-service.types';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type ShiftMutationOptions = UseMutationOptions<string, Error, HQDuplicateShiftPayload>;

export const useHqDuplicateShift = () => {
  const mutationFn = async (shift: HQDuplicateShiftPayload) =>
    await shiftService.duplicateShift(shift);
  const queryClient = useQueryClient();
  const options: ShiftMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
    },
  };

  return useMutation(mutationFn, options);
};
