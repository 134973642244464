import { Sorting } from 'ogp/components/views/administration';
import {
  ClaimPendingExtended,
  ClaimSettledExtended,
} from 'ogp/components/views/administration/shifts-checkouts/shared/types/checkouts.types';
import { WithStringDates } from 'shared/utils/ts-utils';
import { FlexWorkerProfileData, PaginatedCollection, ParamsWithPagination } from '@types';
import { OgpApiService } from './ogp-api-service';
import {
  OGPCheckoutInboxFiltersResponse,
  OGPCheckoutOutboxFiltersResponse,
  OGPShiftClaimExportPayload,
  OGPShiftClaimSetNoShowPayload,
  ShiftsInboxFiltersParams,
  ShiftsOutboxFiltersParams,
  UpdateCounterOfferPayload,
} from './shift-claim-service.types';

export class ShiftClaimService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Cancel a shiftClaim
   */
  public cancelShiftClaim = async (shiftClaimId: string) =>
    await this.ogpApiService.update({
      path: `shift-claims/cancel-claim/${shiftClaimId}`,
    });

  /**
   * Download a shiftClaim
   */
  public getShiftClaimOvo = async (shiftClaimId: string) =>
    await this.ogpApiService.get({
      path: `shift-claims/contract/${shiftClaimId}`,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      decoder: (arrayBuffer: any) => new Blob([arrayBuffer], { type: 'application/pdf' }),
    });

  /**
   * Download a shiftClaim CSV
   */
  public getShiftClaimsExport = async (getShiftClaimsData: OGPShiftClaimExportPayload) =>
    await this.ogpApiService.get<Blob>({
      path: `shift-claims/export`,
      responseType: 'blob',
      params: getShiftClaimsData,
    });

  public getCheckoutInboxExport = async (shiftClaimIds: string[]) =>
    await this.ogpApiService.create<Blob>({
      path: `shift-claims/export-checkout-inbox`,
      responseType: 'blob',
      body: { shiftClaimIds },
    });

  /**
   * Add a shift claimant
   */
  public createShiftClaim = async (shiftId: string, flexWorkerId: string) =>
    await this.ogpApiService.create({
      path: `shift-claims`,
      body: {
        shiftId,
        flexWorkerId,
      },
    });

  /**
   * Update startDate, endDate or breakMinutes in shiftClaim
   */
  public updateCounterOffer = async (shiftClaimId: string, body: UpdateCounterOfferPayload) => {
    return await this.ogpApiService.update({
      path: `shift-claims/counter-offer/${shiftClaimId}`,
      body,
    });
  };

  /**
   * Get the checkouts that are in pending state
   */
  public getCheckoutsArchive = async (
    params: ParamsWithPagination<ShiftsOutboxFiltersParams> & Sorting
  ) =>
    await this.ogpApiService.get({
      path: `shift-claims/checkouts-outbox`,
      params: {
        ...params,
        fromDate: params.fromDate?.toFormat('yyyy-MM-dd'),
        toDate: params.toDate?.toFormat('yyyy-MM-dd'),
      },
      decoder: (res) => {
        const result = res as PaginatedCollection<WithStringDates<ClaimSettledExtended>>;
        return {
          totalCount: result.totalCount,
          unfilteredCount: result.unfilteredCount,
          items: result.items.map(
            (c) =>
              ({
                ...c,
                checkoutAt: c.checkoutAt && new Date(c.checkoutAt),
                fwStartDate: c.fwStartDate && new Date(c.fwStartDate),
                fwEndDate: c.fwEndDate && new Date(c.fwEndDate),
                ogStartDate:
                  typeof c.ogStartDate === 'string' ? new Date(c.ogStartDate) : c.ogStartDate,
                ogEndDate: typeof c.ogEndDate === 'string' ? new Date(c.ogEndDate) : c.ogEndDate,
                shift: {
                  ...c.shift,
                  utcStartDate: new Date(c.shift.utcStartDate),
                  utcEndDate: new Date(c.shift.utcEndDate),
                },
              }) as ClaimSettledExtended
          ),
        };
      },
    });

  public async getWorkedShiftsInPastMonth(
    flexWorkerId: string
  ): Promise<{ shiftClaimsAmount: number }> {
    return await this.ogpApiService.get({
      path: `shift-claims/worked-shifts/${flexWorkerId}`,
    });
  }

  /**
   * Get the checkouts that are in pending state
   */
  public getCheckoutsInbox = async (
    params: ParamsWithPagination<ShiftsInboxFiltersParams> & Sorting
  ) =>
    await this.ogpApiService.get({
      path: `shift-claims/checkouts-inbox`,
      params: {
        ...params,
        fromDate: params.fromDate?.toISODate(),
        toDate: params.toDate?.toISODate(),
      },
      decoder: (res) => {
        const result = res as PaginatedCollection<WithStringDates<ClaimPendingExtended>>;
        return {
          totalCount: result.totalCount,
          unfilteredCount: result.unfilteredCount,
          items: result.items.map(
            (c) =>
              ({
                ...c,
                checkoutAt: c.checkoutAt && new Date(c.checkoutAt),
                fwStartDate: c.fwStartDate && new Date(c.fwStartDate),
                fwEndDate: c.fwEndDate && new Date(c.fwEndDate),
                ogStartDate:
                  typeof c.ogStartDate === 'string' ? new Date(c.ogStartDate) : c.ogStartDate,
                ogEndDate: typeof c.ogEndDate === 'string' ? new Date(c.ogEndDate) : c.ogEndDate,
                shift: {
                  ...c.shift,
                  utcStartDate: new Date(c.shift.utcStartDate),
                  utcEndDate: new Date(c.shift.utcEndDate),
                },
              }) as ClaimPendingExtended
          ),
        };
      },
    });

  /**
   * Get the checkout inbox filters
   */
  public getCheckoutInboxFilters = async () => {
    const checkoutInboxFilters = await this.ogpApiService.get<OGPCheckoutInboxFiltersResponse>({
      path: `shift-claims/checkouts-inbox-filters`,
    });

    return checkoutInboxFilters;
  };

  /**
   * Get the all checkouts filters
   */
  public getCheckoutsArchiveFilters = async () => {
    const filters = await this.ogpApiService.get<OGPCheckoutOutboxFiltersResponse>({
      path: `shift-claims/checkouts-outbox-filters`,
    });

    return filters;
  };

  /**
   * Set shiftClaim as no show
   */
  public setNoShow = async ({ shiftClaimId, ...body }: OGPShiftClaimSetNoShowPayload) => {
    return await this.ogpApiService.update({
      path: `shift-claims/no-show/${shiftClaimId}`,
      body,
    });
  };

  /**
   * Undo shiftClaim no show
   */
  public undoNoShow = async (shiftClaimId: string) => {
    return await this.ogpApiService.update({
      path: `shift-claims/undo-no-show/${shiftClaimId}`,
    });
  };

  /**
   * Settle checkout(s).
   */
  public settleCheckouts = async (shiftClaimIds: string[]): Promise<string[]> =>
    await this.ogpApiService.create<string[]>({
      path: `shift-claims/settle-checkouts`,
      body: {
        shiftClaimIds,
      },
    });

  /**
   * Get FlexWorker Profile through ShiftClaim
   **/
  public getFlexWorkerProfileThroughShiftClaim = async (
    shiftClaimId: string,
    flexWorkerId: string
  ) => {
    const response = await this.ogpApiService.get<FlexWorkerProfileData>({
      path: `shift-claims/${shiftClaimId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
