import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';

type ApproveAccreditationParams = {
  flexWorkerId: string;
  certificateId: string;
  expirationDate: Date | undefined;
};

export const useApproveAccreditation = () => {
  const mutationFn = async ({
    flexWorkerId,
    certificateId,
    expirationDate,
  }: ApproveAccreditationParams) =>
    await accreditationsService.acceptAccreditation(flexWorkerId, certificateId, expirationDate);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.accreditations());
    },
  });
};
