import {
  Box,
  FormControlLabel,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import React, { useCallback } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { LwCheckbox } from 'redesign';
import { FormCheckboxGroupsProps } from './form-checkbox-group.types';
import { IconInfoRedesigned } from '../../../assets/img';

export const FormCheckboxGroup = <T extends FieldValues>({
  label,
  name,
  control,
  items,
  disabled,
  display = 'grid',
  maxChecked,
  informationText,
}: FormCheckboxGroupsProps<T>) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const values = value as string[];

  const onChangeItem = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let res = [];

      if (!checked) {
        res = Array.isArray(values) ? values.filter((x) => x !== e.target.name) : [];
      } else if (!maxChecked) {
        res = [...values, e.target.name];
      } else if (maxChecked === 1) {
        res = [e.target.name];
      } else {
        const newValues = values.length ? values?.slice(-(maxChecked - 1)) || [] : [];
        res = [...newValues, e.target.name];
      }
      onChange(res);
    },
    [maxChecked, onChange, values]
  );

  return (
    <>
      <Box>
        <LabelWrapper>
          {label ? <Typography variant="body1">{label}</Typography> : null}
          {informationText ? (
            <StyledTooltip title={informationText}>
              <Box display="flex" alignItems="inherit">
                <IconInfoRedesigned style={{ cursor: 'pointer' }} />
              </Box>
            </StyledTooltip>
          ) : null}
        </LabelWrapper>
      </Box>
      <FormGroup aria-label={`${name} filter checkboxes`}>
        <Box display={display} gap={10}>
          {items.map((item) => (
            <FormControlLabel
              key={item.value}
              disabled={disabled}
              control={
                <LwCheckbox
                  checked={values?.includes(item.value) ?? false}
                  onChange={onChangeItem}
                  name={item.value}
                />
              }
              label={item.label}
            />
          ))}
        </Box>
      </FormGroup>
    </>
  );
};

const LabelWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '0.7em',
  alignItems: 'inherit',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#edf0f7',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#edf0f7',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#edf0f7',
    color: 'black',
    boxShadow: theme.shadows[5],
  },
}));
