import { PartnerApiService } from './partner-api-service';
import { GetPartnerUserInfoResponse, GetPartnerUserInfoSchema } from './partner-user-service.types';

export class PartnerUserService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public getUserInfo = async (): Promise<GetPartnerUserInfoResponse> => {
    const response = await this.partnerApiService.get({
      path: `get-user-info`,
    });

    const result = GetPartnerUserInfoSchema.parse(response);

    return result;
  };
}
