import { ContextMenuButton, ContextMenuItem, Table, TableCell, TableHeader } from 'redesign';
import SortIcon from 'assets/img/icons/caret-down.svg?react';
import { useHqAdmin } from 'shared/hooks';
import { useDialog } from 'ogp/providers/dialog-provider';
import { ItemOf } from '@types';
import { invariant } from 'shared/utils/utils';
import { IconApproved, IconCounterOffer } from 'assets/img';
import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { CellProps, Column, HeaderProps } from 'react-table';
import type { LevelColumn, Props } from './checkouts-archive-table.types';
import {
  AddProposalDialog,
  useAddProposalDialog,
  useSettlePlacementCheckout,
} from '../../../shared';
import { useAddProposal } from '../../../inbox/hooks';
import { ProjectsInboxTableData } from '../../../inbox/components/projects-inbox-table.types';
import { getExpenseWithNameAndValue } from '../../../../../../../../shared/utils/expenses';

export const CheckoutsArchiveTable = ({ data: queryData }: Props) => {
  const isHqAdmin = useHqAdmin();
  const columns = React.useMemo(() => getArchiveColumnDefinition({ isHqAdmin }), [isHqAdmin]);

  return <Table columns={columns} data={queryData.items} />;
};

const getArchiveColumnDefinition = ({ isHqAdmin }: { isHqAdmin: boolean }) => {
  const columns: Column<LevelColumn>[] = [
    {
      Header: () => <TableHeader>Flexwerker</TableHeader>,
      accessor: 'flexWorker',
      Cell: (cell) => <TableCell>{cell.value.fullName}</TableCell>,
    },
    {
      Header: () => <TableHeader>Project</TableHeader>,
      accessor: 'project',
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    },
    {
      accessor: 'createdAt',
      Header: CreatedAtHeaderCell,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    },
    {
      accessor: 'workedFrom',
      Header: () => <TableHeader>Begintijd</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    },
    {
      accessor: 'workedTo',
      Header: () => <TableHeader>Eindtijd</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    },
    {
      Header: () => <TableHeader>Gemaakte kosten</TableHeader>,
      accessor: 'expense',
      Cell: (cell) => (
        <TableCell>
          {cell.value?.length
            ? cell.value.map((expense) => (
                <Box key={expense.expenseType}>{getExpenseWithNameAndValue(expense)}</Box>
              ))
            : 'Geen'}
        </TableCell>
      ),
    },
    {
      accessor: 'state',
      Header: () => <TableHeader>Status</TableHeader>,
      Cell: (cell) => {
        const checkoutState = {
          settled: 'Akkoord',
          counterOffer: 'Tegenvoorstel',
        };

        return <TableCell>{checkoutState[cell.value]}</TableCell>;
      },
    },
  ];

  if (isHqAdmin) {
    columns.push({
      accessor: 'actions',
      Header: '',
      Cell: ActionCell,
    });
  }

  return columns;
};

const ActionCell = (cell: CellProps<LevelColumn>) => {
  const { openDialog, disableDialog, enableDialog, closeDialog } = useDialog();
  const settlePlacementCheckoutMutation = useSettlePlacementCheckout();
  const addProposal = useAddProposal();
  const { getProposalData, handleUpdateProposalData } = useAddProposalDialog();

  const handleAddProposal = (item: ItemOf<ProjectsInboxTableData['items']>['actions']) => () => {
    openDialog(
      {
        title: 'Tegenvoorstel',
        content: <AddProposalDialog onChange={handleUpdateProposalData} item={item} />,
      },
      () => {
        disableDialog();
        const proposalData = getProposalData();
        invariant(proposalData, 'Proposal data not set');
        addProposal.mutate(
          {
            placementId: item.placement.id,
            checkoutId: item.id,
            payload: proposalData,
          },
          {
            onSuccess: closeDialog,
            onSettled: enableDialog,
          }
        );
      }
    );
  };

  const handleSettleCheckout = () => {
    openDialog(
      {
        title: 'Checkout goedkeuren',
        content: 'Weet je zeker dat je deze checkout namens de flexwerker wilt goedkeuren?',
      },
      () => {
        settlePlacementCheckoutMutation.mutate([cell.value.id], {
          onSettled: enableDialog,
          onSuccess: closeDialog,
        });
      }
    );
  };

  const actions = [
    cell.value.state === 'counterOffer' ? (
      <ContextMenuItem onClick={handleSettleCheckout}>
        <IconApproved /> Akkoord
      </ContextMenuItem>
    ) : undefined,
    cell.value.state === 'counterOffer' ? (
      <ContextMenuItem onClick={handleAddProposal(cell.value)}>
        <IconCounterOffer />
        Tegenvoorstel
      </ContextMenuItem>
    ) : undefined,
  ].filter(Boolean);

  return actions.length > 0 ? (
    <ContextMenuButton menuId={`project-actions-menu-${cell.value.id}`}>
      {actions}
    </ContextMenuButton>
  ) : null;
};

const CreatedAtHeaderCell = (cell: HeaderProps<LevelColumn>) => {
  if (!cell.data[0]) {
    return null;
  }

  const { sortState, handleSortClick } = cell.data[0];

  return (
    <Button
      variant="text"
      onClick={() => {
        handleSortClick('date');
      }}
    >
      <TableHeader>Datum</TableHeader>
      <StyledSortIcon
        style={{
          transform: sortState.order === 'asc' ? 'rotate(180deg)' : '',
          visibility: sortState.sort === 'date' ? 'visible' : 'hidden',
        }}
      />
    </Button>
  );
};

const StyledSortIcon = styled(SortIcon)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  height: '12px',
  width: '12px',
  transition: 'ease transform .5s',
}));
