import { pluralize } from 'shared/utils/pluralize';

const getSettleCheckoutCopy = (ids: string[]) => {
  return {
    getResultMessage(numberOfCheckouts: number) {
      return `De ${pluralize(
        numberOfCheckouts,
        'checkout is',
        `${numberOfCheckouts} checkouts zijn`
      )} geaccordeerd!`;
    },
    dialog:
      ids.length > 1
        ? {
            title: `Weet je zeker dat je deze ${pluralize(
              ids.length,
              'checkout',
              `${ids.length} checkouts`
            )} wilt accorderen?`,
            content:
              'De checkouts worden hiermee geaccordeerd en de beoordelingen worden toegevoegd aan de profielen van de flexwerkers.',
          }
        : {
            title: 'Checkout accorderen en de flexwerker beoordelen?',
            content:
              'De checkout wordt hiermee geaccordeerd en de beoordeling wordt toegevoegd aan het profiel van de flexwerker.',
          },
  };
};

export { getSettleCheckoutCopy };
