import { sortBy } from 'lodash';
import { GetBillingEntitiesResponse } from '../billing-entity/billing-entity-service.types';
import { EnvironmentConfigurationResponse } from '../shared-api.service';

export type BillingEntitiesSelectModel = { value: string; label: string }[];
const billingEntitiesResponse2BillingEntitiesSelectModel = (
  billingEntitiesResponse: GetBillingEntitiesResponse[]
): BillingEntitiesSelectModel => {
  const billingEntities: BillingEntitiesSelectModel = [];
  sortBy(billingEntitiesResponse, (billingEntity) => billingEntity.name.toLowerCase()).forEach(
    (billingEntity) => {
      billingEntities.push({
        value: billingEntity.id,
        label: billingEntity.name,
      });
    }
  );

  return billingEntities;
};

export { billingEntitiesResponse2BillingEntitiesSelectModel };

export type FeatureFlags = EnvironmentConfigurationResponse['backend']['featureFlags'];
export const environmentConfigurationResponse2FeatureFlagsModel = (
  environmentConfigurationResponse: EnvironmentConfigurationResponse
): FeatureFlags => {
  return environmentConfigurationResponse.backend.featureFlags;
};
