import { branchOfficeService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { BranchOfficeUpdatePayload } from '../../../../../../../../../../../services/branch-office-service.types';

export const useUpdateBranchOffice = (branchOfficeId: string) => {
  const mutationFn = async (branchOffice: BranchOfficeUpdatePayload) =>
    await branchOfficeService.updateBranchOffice(branchOfficeId, branchOffice);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.branchOfficesList(variables.companyId));
      queryClient.invalidateQueries(QUERY_KEYS_HQ.branchOffice(branchOfficeId));
    },
  });
};
