const LIGHT_BLUE = {
  5: 'rgba(0, 43, 255, 0.05);',
  10: 'rgba(0, 43, 255, 0.1);',
  20: 'rgba(0, 43, 255, 0.2);',
  40: 'rgba(0, 43, 255, 0.4);',
  100: '#002BFF',
};

const DARK_BLUE = {
  5: 'rgba(0, 26, 153, 0.05);',
  10: 'rgba(0, 26, 153, 0.1);',
  20: 'rgba(0, 26, 153, 0.2);',
  30: 'rgba(0, 26, 153, 0.3);',
  40: 'rgba(0, 26, 153, 0.4);',
  60: 'rgba(0, 26, 153, 0.6);',
  80: 'rgba(0, 26, 153, 0.8);',
  100: '#001A99',
};

const ORANGE = {
  20: 'rgba(255, 153, 102, 0.2);',
  30: 'rgba(255, 153, 102, 0.3);',
  100: '#FF9966',
};

const GREEN = {
  20: 'rgba(0, 204, 136, 0.2);',
  100: '#00CC88',
};

const RED = {
  10: 'rgba(204, 68, 0, 0.1);',
  20: 'rgba(204, 68, 0, 0.2);',
  100: '#CC4400',
};

const BLACK = {
  100: '#000000',
  60: 'rgba(0, 0, 0, 0.6);',
  40: 'rgba(0, 0, 0, 0.4);',
  30: 'rgba(0, 0, 0, 0.3);',
  20: 'rgba(0, 0, 0, 0.2);',
  10: 'rgba(0, 0, 0, 0.1);',
  6: 'rgba(0, 0, 0, 0.06);',
  2: 'rgba(0, 0, 0, 0.02);',
};

const WHITE = {
  100: '#FFFFFF',
  80: 'rgba(255, 255, 255, 0.8);',
};

export { DARK_BLUE, LIGHT_BLUE, ORANGE, GREEN, RED, BLACK, WHITE };
