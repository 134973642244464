import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { PartnerWorkerInfiniteQueryResponse } from 'partner/shared/services/partner-planner-service.types';
import { ContextMenuItem, SearchBar, Table, useOnScrollDown } from 'redesign';
import { IconApproved } from 'assets/img';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { Box } from '@mui/material';
import { InfiniteQueryObserverSuccessResult, useQueryClient } from 'react-query';
import { useCallback, useMemo, useState } from 'react';
import { getPlannerModalTableData } from './definition/get-planner-modal-table-data';
import { useGetPlannerWorkerTableColumnDefinition } from './definition/use-get-planner-worker-table-column-definition';
import { usePartnerClaimShift } from '../hooks/use-partner-claim-shift';
import { usePartnerGetWorkers } from '../hooks/use-partner-get-workers';

export const PlannerWorkerTableWithSearchbar = ({ shiftId }: { shiftId: string }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debounced = useDebounce(searchInput, 800);
  const fullNameSearch = debounced || undefined;

  return (
    <Box>
      <Box display="flex" marginTop={4} marginBottom={8}>
        <SearchBar onChange={setSearchInput} placeholder="Flex werker naam" />
      </Box>
      <PlannerWorkerTable fullNameSearch={fullNameSearch} shiftId={shiftId} />
    </Box>
  );
};

const PlannerWorkerTable = ({
  shiftId,
  fullNameSearch,
}: {
  shiftId: string;
  fullNameSearch: string | undefined;
}) => {
  const query = usePartnerGetWorkers(shiftId, DEFAULT_PAGE_SIZE, fullNameSearch);

  if (query.status !== 'success') {
    return (
      <RenderUnsettledUI
        data={query}
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
      />
    );
  }
  return <PlannerWorkerTableImpl query={query} shiftId={shiftId} />;
};

const PlannerWorkerTableImpl = ({
  query,
  shiftId,
}: {
  query: InfiniteQueryObserverSuccessResult<PartnerWorkerInfiniteQueryResponse, unknown>;
  shiftId: string;
}) => {
  const { alertSuccess, alertError } = useAlert();
  const queryClient = useQueryClient();
  const flatData = useMemo(
    () => query.data.pages.flatMap((page) => page.items) ?? [],
    [query.data]
  );
  const tableData = useMemo(() => getPlannerModalTableData(flatData), [flatData]);
  const columns = useGetPlannerWorkerTableColumnDefinition();

  const claimShiftMutation = usePartnerClaimShift();

  const handleClaimShift = (flexWorkerId: string) => {
    claimShiftMutation.mutate(
      { flexWorkerId, shiftId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerWorkers());
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftPlanning());
          queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftPlanningShiftDetail(shiftId));
          alertSuccess(shiftAlerts.success.claimed);
        },
        onError: (e) => alertError(e),
      }
    );
  };

  const getRowActions = useCallback(({ workerId }) => {
    return [
      <ContextMenuItem onClick={() => handleClaimShift(workerId)} key="claim">
        <IconApproved />
        Op shift plaatsen
      </ContextMenuItem>,
    ];
  }, []);

  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: query,
    flatData: flatData,
  });

  return (
    <Table
      stickyHeader
      columns={columns}
      data={tableData}
      getRowActions={getRowActions}
      onScroll={fetchIfBottomScrolled}
      data-testid="planner-worker-table"
    />
  );
};
