import { ItemOf } from '@types';
import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { invariant } from 'shared/utils/utils';
import { pluralize } from 'shared/utils/pluralize';
import { useAlert } from 'shared/components/alerts';
import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { LwButton } from 'redesign';
import {
  ProjectsInboxTableColumnDefinition,
  ProjectsInboxTableData,
  ProjectsInboxTableProps,
} from './projects-inbox-table.types';
import { ProjectsInboxTable } from './projects-inbox-table';
import { useAddProposal, useGetProjectsCheckoutsExport } from '../hooks';
import { AddProposalDialog, useAddProposalDialog, useSettlePlacementCheckout } from '../../shared';
import { FilterButton, getSettleCheckoutCopy } from '../../../shared';
import { CheckoutTypeToggle } from '../../../checkouts/checkouts-type-provider';

export const ProjectsInboxTableWrapper = ({
  data,
  handleSortClick,
  sortState,
  toggleDrawer,
  nrOfActiveFilters,
}: ProjectsInboxTableProps) => {
  const { openDialog, disableDialog, enableDialog, closeDialog } = useDialog();
  const { alertError, alertSuccess } = useAlert();
  const settleCheckout = useSettlePlacementCheckout();
  const exportCheckout = useGetProjectsCheckoutsExport();
  const addProposal = useAddProposal();
  const { getProposalData, handleUpdateProposalData } = useAddProposalDialog();
  const [selected, setSelected] = useState<string[]>([]);

  const onSelectionChange = useCallback(
    (rows: ProjectsInboxTableColumnDefinition[]) => {
      setSelected(rows.map((i) => i.id));
    },
    [data]
  );

  const handleExportCheckout = useCallback(
    (checkoutIds: string[]) => () => {
      const pluralTitleCheckouts = pluralize(
        checkoutIds.length,
        'checkout',
        `${checkoutIds.length} checkouts`
      );
      const title = `Weet je zeker dat je deze ${pluralTitleCheckouts} wilt exporteren?`;

      openDialog(
        {
          title,
          content: `De export wordt als csv bestand opgeslagen`,
          primaryButton: (props) => <PrimaryButton {...props}>Exporteer</PrimaryButton>,
        },
        () => {
          disableDialog();
          exportCheckout.mutate(
            { checkoutIds },
            {
              onSuccess: () => {
                const pluralCheckouts = pluralize(
                  checkoutIds.length,
                  'checkout is',
                  `${checkoutIds.length} checkouts zijn`
                );
                alertSuccess(`De ${pluralCheckouts} geëxporteerd!`);
                closeDialog();
              },
              onError: (e) => {
                alertError(e);
              },
              onSettled: enableDialog,
            }
          );
        }
      );
    },
    []
  );

  const handleSettleCheckout = useCallback(
    (checkoutIds: string[]) => () => {
      const { dialog } = getSettleCheckoutCopy(checkoutIds);
      openDialog(
        {
          ...dialog,
          primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
        },
        () => {
          disableDialog();
          settleCheckout.mutate(checkoutIds, {
            onSuccess: () => {
              closeDialog();
              setSelected([]);
            },
            onSettled: enableDialog,
          });
        }
      );
    },
    []
  );

  const handleAddProposal = useCallback(
    (item: ItemOf<ProjectsInboxTableData['items']>['actions']) => () => {
      openDialog(
        {
          title: 'Tegenvoorstel',
          content: <AddProposalDialog onChange={handleUpdateProposalData} item={item} />,
        },
        () => {
          disableDialog();
          const proposalData = getProposalData();
          invariant(proposalData, 'Proposal data not set');
          addProposal.mutate(
            {
              placementId: item.placement.id,
              checkoutId: item.id,
              payload: proposalData,
            },
            {
              onSuccess: closeDialog,
              onSettled: enableDialog,
            }
          );
        }
      );
    },
    []
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end" gap={10} marginBottom={4}>
        <LwButton
          onClick={handleSettleCheckout(selected)}
          disabled={selected.length === 0}
          color="primary"
        >
          Akkoord
        </LwButton>
        <LwButton
          disabled={selected.length === 0}
          onClick={handleExportCheckout(selected)}
          color="primary"
        >
          Exporteer
        </LwButton>
        <CheckoutTypeToggle />
        <FilterButton onClick={toggleDrawer} nrOfActiveFilters={nrOfActiveFilters} />
      </Box>
      <ProjectsInboxTable
        data={data}
        sortState={sortState}
        handleSortClick={handleSortClick}
        onSelectionChange={onSelectionChange}
        handleAddProposal={handleAddProposal}
        handleSettleCheckout={handleSettleCheckout}
      />
    </>
  );
};
