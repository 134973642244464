import { OgpApiService } from 'ogp/services/ogp-api-service';
import { OgpBusinessUnitsData, OgpJobTypesData } from './ogp-shared-service.types';

export class OgpSharedService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public async getBusinessUnits(): Promise<OgpBusinessUnitsData> {
    const response = await this.ogpApiService.get<OgpBusinessUnitsData>({
      path: 'shared/business-units',
    });

    return response;
  }

  public async getJobTypes(): Promise<OgpJobTypesData> {
    const response = await this.ogpApiService.get<OgpJobTypesData>({
      path: 'shared/job-types',
    });
    return response;
  }
}
