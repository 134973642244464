import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { branchOfficeService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { FirstParamOfFn } from '@types';
import { GetBranchOfficesResponse } from '../../../services/branch-office-service.types';

export const useGetBranchOffices = (
  options: FirstParamOfFn<typeof branchOfficeService.getBranchOffices>
) => {
  const queryFn = async () => await branchOfficeService.getBranchOffices(options);

  return useQuery<
    Promise<GetBranchOfficesResponse['branchOffices']>,
    Error,
    GetBranchOfficesResponse['branchOffices']
  >(QUERY_KEYS_OGP.branchOfficesList(), queryFn, { cacheTime: CACHE_TIME_LONG });
};
