import { Box, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { styled } from '@mui/styles';

import { IconEdit, IconTrash } from 'assets/img';
import { LwButton } from 'redesign';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { routes } from 'Routes';
import { useGetPartner } from '../../partners/hooks/use-get-partner';
import { PartnerPage } from '../partner-page';
import { useArchivePartnerDialog } from '../../partner-detail/hooks/use-archive-partner-dialog';

export const PartnerInfo = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const generatePath = useHqRelativePath();
  const partnerQuery = useGetPartner(id);

  const { open: archivePartner, Element: ElementArchive } = useArchivePartnerDialog(id ?? '');

  if (partnerQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerQuery} />;
  }
  return (
    <PartnerPage>
      <StyledBox>
        <Grid container role="partner-actions" gap={4} marginTop={4}>
          <LwButton
            type={'button'}
            to={generatePath(routes.PartnerDetailEdit, { id })}
            color="primary"
            iconColorMode="fill"
            startIcon={<IconEdit />}
          >
            Partnergegevens bewerken
          </LwButton>

          <LwButton
            type={'button'}
            onClick={() => {
              archivePartner(id);
            }}
            color="secondary"
            iconColorMode="stroke"
            startIcon={<IconTrash />}
            disabled={!partnerQuery.data.deactivated}
          >
            Verwijderen
          </LwButton>
        </Grid>
        <StyledGrid container spacing={4} role="partner-info">
          <Grid item>
            <Typography variant="subtitle1" color="secondary" noWrap>
              Naam
            </Typography>
            <Typography variant="body1">{partnerQuery.data.name}</Typography>
          </Grid>
        </StyledGrid>
        {ElementArchive}
      </StyledBox>
    </PartnerPage>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
