import { OgpPlacementStates } from '../../../../../ogp/services/project-service.types';
import { ProjectCancellation } from '../../../../services/project-service.types';
import { getWorkApplicationCancelationReason } from '../../../../utils/cancelation-reasons';

const stateMessages: { [key in OgpPlacementStates]?: string } = {
  canceled: 'Geannuleerd',
  terminated: 'Beëindigd',
  completed: 'Voltooid',
};

const isWork = (input: ProjectCancellation): boolean => {
  return input.relation.relation === 'placement';
};

const isWorkApplication = (input: ProjectCancellation): boolean => {
  return input.relation.relation === 'workApplication';
};

const getProjectCancelationReason = (input: ProjectCancellation): string => {
  let cancelationReason: string | undefined = undefined;
  if (isWork(input)) {
    cancelationReason = stateMessages[input.state];
  } else if (isWorkApplication(input)) {
    cancelationReason = getWorkApplicationCancelationReason(input);
  }

  return cancelationReason ?? 'Onbekend';
};

export { getProjectCancelationReason };
