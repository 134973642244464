import { IconError, IconWarning } from 'assets/img';
import { styled } from '@mui/material';

const StyledIconWarning = styled(IconWarning)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwDanger[100]}`,
  },
}));

const StyledIconError = styled(IconError)(({ theme }) => ({
  path: {
    stroke: `${theme.palette.lwDanger[100]}`,
  },
}));

const StyledHelperTextWrapper = styled('div')(({ theme }) => ({
  '&.error-text-wrapper': {
    height: '20px',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Inter',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const StyledErrorText = styled('div')(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&.error-state': {
    color: theme.palette.lwDanger[100],
  },
}));

export { StyledErrorText, StyledHelperTextWrapper, StyledIconWarning, StyledIconError };
