import { useMutation } from 'react-query';
import { partnerUserService } from 'hq/services';
import { CreatePartnerUserPayload } from 'hq/services/partner-user-service.types';

export const useCreatePartnerUser = () => {
  const mutationFn = async (partner: CreatePartnerUserPayload) =>
    await partnerUserService.createUser(partner);

  return useMutation(mutationFn);
};
