import { KeysMatching, Option } from './select.types';

/**
 * Chooses suitable object properties to be used within `<Select />` component.
 *
 * Only `string` properties are offered to you to be used as a `label`.
 * Only `string | number` properties are offered to you to be used as a `value`.
 */
const createSelectOption = <T extends Record<string, any>>(
  el: T,
  {
    label,
    value,
  }: {
    label: KeysMatching<T, Option['label']>;
    value: KeysMatching<T, Option['value']>;
  }
): Option => ({
  value: el[value],
  label: el[label],
});

export { createSelectOption };
