import { CompanyDetailSelectModel } from 'hq/services/mappers/mappers';
import { Grid, Typography } from '@mui/material';
import { useGetOrts } from '../../../../../../../../hooks/queryHooks/companies/use-get-orts';
import { RenderUnsettledUI } from '../../../../../../../../../shared/components';

export const CompanyInfo = ({ data }: { data: CompanyDetailSelectModel }) => {
  const orts = useGetOrts();

  if (orts.status !== 'success') {
    return <RenderUnsettledUI data={orts} />;
  }

  const ort = orts.data.find((item) => item.id === data.ortId);

  return (
    <Grid item container spacing={3} xs={8}>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Bedrijfsnaam</Typography>
        <Typography variant="body2">{data.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1">ORT</Typography>
        <Typography variant="body2">{ort ? ort.name : '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Biedt uitzendwerk aan</Typography>
        <Typography variant="body2">{data.offersTempWork === true ? 'Ja' : 'Nee'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Biedt freelance werk aan</Typography>
        <Typography variant="body2">{data.offersFreelanceWork === true ? 'Ja' : 'Nee'}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle1">Minimale tijd tussen shifts (in uren)</Typography>
        <Typography variant="body2">{data.workCooldownMinutes / 60}</Typography>
      </Grid>
    </Grid>
  );
};
