type Role =
  | 'ACCOUNTHOLDER'
  | 'ADMIN'
  | 'FINANCE'
  | 'HQADMIN'
  | 'MANAGER'
  | 'PLANNER'
  | 'SPECTATOR'
  | 'Manager'
  | 'Planner'
  | 'Spectator';

const dutchRoles: Record<Role, string> = {
  ACCOUNTHOLDER: 'REKENINGHOUDER',
  ADMIN: 'BEHEERDER',
  FINANCE: 'FINANCIËN',
  HQADMIN: 'HOOFDKANTOOR BEHEERDER',
  MANAGER: 'MANAGER',
  PLANNER: 'PLANNER',
  SPECTATOR: 'TOESCHOUWER',
  Manager: 'MANAGER',
  Planner: 'PLANNER',
  Spectator: 'TOESCHOUWER',
};

export const translateRoleToDutch = (role: Role | string): string => {
  const uppercasedRole = role.toUpperCase() as Role;
  return dutchRoles[uppercasedRole] || role;
};
