import { DefaultTexts } from '@aws-amplify/ui';
import '@aws-amplify/ui-react/styles.css';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { I18n } from 'aws-amplify';
import { partnerRoutePrefix } from 'partner/settings';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { hqPrefix } from 'Routes';
import { MyAuthenticator } from 'shared/components/auth';
import { getPlatformClient } from 'shared/utils/platform-utils';
import '../../../../assets/styles/aws-amplify.css';
import { routePrefix as ogpPrefix } from '../../../../ogp/settings';

const platformClient = getPlatformClient();

// These could move to s3 as we get more separate images and clients
const imagePaths = {
  AETHON: '/images/sign-in/background-aethon.jpg',
  LEVELWORKS: '/images/sign-in/background-level.jpg',
  HOLLANDWORX: '/images/sign-in/background.jpg',
};

const signinImage = imagePaths[platformClient];

const SignIn = (): React.ReactElement => (
  <Routes>
    <Route path={`${ogpPrefix}/signin`} element={<SignInForm target="OGP" />} />
    <Route path={`${hqPrefix}/signin`} element={<SignInForm target="HQ" />} />
    <Route path={`${partnerRoutePrefix}/signin`} element={<SignInForm target="PARTNER" />} />
    <Route path={`${ogpPrefix}/*`} element={<Navigate replace to={`${ogpPrefix}/signin`} />} />
    <Route path={`${hqPrefix}/*`} element={<Navigate replace to={`${hqPrefix}/signin`} />} />
    <Route
      path={`${partnerRoutePrefix}/*`}
      element={<Navigate replace to={`${partnerRoutePrefix}/signin`} />}
    />
    <Route path="*" element={<Navigate replace to={`${ogpPrefix}/signin`} />} />
  </Routes>
);

const SignInForm = ({ target }: { target: 'HQ' | 'OGP' | 'PARTNER' }) => {
  const styles = getStyles();

  useEffect(() => {
    I18n.setLanguage('nl-NL');
    I18n.putVocabulariesForLanguage('nl-NL', translations);
  }, []);

  return (
    <Container>
      {target === 'HQ' ? (
        <Typography variant="h1" className={styles.hqAnimation}>
          HQ
        </Typography>
      ) : null}
      <Image withOverlay={target === 'HQ'} />
      <MyAuthenticator />
    </Container>
  );
};

const translations = {
  [DefaultTexts.SIGN_IN_BUTTON]: 'Inloggen',
  [DefaultTexts.CONFIRM_PASSWORD]: 'Herhaal je wachtwoord',
  [DefaultTexts.LOGIN_NAME]: <span>E-mailadres *</span>,
  [DefaultTexts.ENTER_USERNAME]: 'E-mailadres *',
  [DefaultTexts.PASSWORD]: <span>Wachtwoord *</span>,
  [DefaultTexts.CONFIRMING]: 'Bevestigen',
  [DefaultTexts.SENDING]: 'Versturen',
  'Create account': <span></span>,
  'No account? ': <span></span>,
  [DefaultTexts.FORGOT_YOUR_PASSWORD]: (
    <span className="forgot-password">Wachtwoord vergeten?</span>
  ),
  'Reset password': <span className="anchor forgot-password">Wachtwoord vergeten?</span>,
  'Forgot your password? ': <span className="forgot-password hidden"></span>,
  'Reset your password': 'Wachtwoord opnieuw instellen',
  'Send code': 'Code verzenden',
  'Back to Sign In': <span className="anchor forgot-password">Terug naar inloggen</span>,
  'Username cannot be empty': <span className="error">E-mailadres mag niet leeg zijn</span>,
  'No MFA mechanism registered in the account.': (
    <span className="error">Er ging iets mis, neem contact op met Level.works.</span>
  ),
  'Signing in': 'Inloggen',
  'User does not exist.': <span>Gebruiker bestaat niet</span>,
  'CUSTOM_AUTH is not enabled for the client.': <span></span>,
  'Incorrect username or password.': <span>Onjuiste e-mailadres of wachtwoord</span>,
  'Change Password': 'Wijzig je wachtwoord',
  'New Password': 'Nieuw wachtwoord *',
  'Confirmation code cannot be empty': 'Vul de sms-code in, dit veld mag niet leeg zijn',
  [DefaultTexts.CONFIRM]: 'Inloggen',
  [DefaultTexts.CONFIRM_SMS]: (
    <Typography variant="body1">
      We hebben een SMS-code verstuurd naar het nummer dat bij ons bekend is.
      <br />
      <br />
      Vul de SMS-code in
    </Typography>
  ),
  Change: 'Wijzig',
  'Password does not conform to policy: Password not long enough': (
    <p style={{ marginBottom: '24px' }}>Dit wachtwoord is niet lang genoeg</p>
  ),
  'Password did not conform with policy: Password not long enough': (
    <p style={{ marginBottom: '24px' }}>Dit wachtwoord is niet lang genoeg</p>
  ),
  'Password does not conform to policy: Password must have uppercase characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook hoofdletters bevatten</p>
  ),
  'Password did not conform to policy: Password must have uppercase characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook hoofdletters bevatten</p>
  ),
  'Password does not conform to policy: Password must have lowercase characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook kleine letters bevatten</p>
  ),
  'Password did not conform to policy: Password must have lowercase characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook kleine letters bevatten</p>
  ),
  'Password does not conform to policy: Password must have numeric characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook cijfers bevatten</p>
  ),
  'Password did not conform to policy: Password must have numeric characters': (
    <p style={{ marginBottom: '24px' }}>Je wachtwoord moet ook cijfers bevatten</p>
  ),
  'Invalid code or auth state for the user.': 'Ongeldige code of auth-status voor de gebruiker',
  'Resend Code': <span className="anchor forgot-password">Code opnieuw sturen</span>,
  Submit: 'Verstuur',
  Submitting: 'Wordt verstuurd',
  'Invalid verification code provided, please try again.': 'Onjuiste code',
  'Attempt limit exceeded, please try after some time':
    'Teveel pogingen, probeer het later opnieuw',
  'Confirm TOTP Code': <p style={{ marginBottom: '24px' }}>Google Authenticator code</p>,
  'Scan then enter verification code': (
    <p style={{ marginBottom: '24px' }}>
      Scan onderstaande QR-code met de Google Authenticator app:
    </p>
  ),
  'Enter Security Code:': <p style={{ margin: '24px 0' }}>Beveiligingscode:</p>,
  'Invalid session for the user, session is expired.': 'Oeps! Er ging iets mis, log opnieuw in.',
  'Your passwords must match': 'Je wachtwoorden moeten overeenkomen',
};

/**
 * The amplify component is styled in the aws-amplify.css file
 */

const Image = styled(
  ({ withOverlay, ...rest }: { withOverlay: boolean } & React.HTMLAttributes<HTMLDivElement>) => (
    <div {...rest} />
  )
)((props) => {
  return {
    backgroundImage: `${hqGradient(props.withOverlay)}, url(${signinImage})`,
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center left',
    height: '100%',
    aspectRatio: '0.75',
  };
});

const Container = styled('div')(() => {
  return {
    display: 'flex',
    height: '100vh',
    justifyContent: 'space-between',
  };
});

const hqGradient = (on: boolean) =>
  on
    ? 'linear-gradient( 180deg, hsla(207deg 47% 47% / .65) 0%, hsla(208deg 43% 66% / .65) 18%, hsla(208deg 53% 79% / .65) 26%, hsla(206deg 88% 90% / .65) 33%, hsla(206deg 88% 90% / .65) 39%, hsla(206deg 88% 90% / .65) 44%, hsla(206deg 88% 90% / .65) 50%, hsla(206deg 88% 90% / .65) 56%, hsla(206deg 88% 90% / .65) 61%, hsla(206deg 88% 90% / .65) 67%, hsla(208deg 53% 79% / .65) 74%, hsla(208deg 43% 66% / .65) 82%, hsla(207deg 47% 47% / .65) 100%)'
    : 'linear-gradient(to right, transparent 100%, white 0%)';

const getStyles = makeStyles({
  '@keyframes hq': {
    '0%': {
      bottom: '-3rem',
      left: '3rem',
    },
    '2%': {
      bottom: '-1rem',
    },
    '5%': {
      bottom: '-1rem',
    },
    '10%': {
      bottom: '-3rem',
    },
    '15%': {
      top: '-4rem',
      rotate: '0deg',
    },
    '20%': {
      bottom: 'unset',
      rotate: '180deg',
    },
    '25%': {
      top: '-1rem',
    },
    '30%': {
      top: '-2rem',
    },
    '40%': {
      top: '3rem',
    },
    '45%': {
      top: '3rem',
    },
    '50%': {
      left: '3rem',
      top: '-3rem',
    },
    '55%': {
      left: '3rem',
      top: '-3rem',
    },
    '60%': {
      left: '-4rem',
      top: '-3rem',
      rotate: '180deg',
    },
    '62%': {
      left: '-4rem',
      top: '50%',
      transform: 'translateY(-50%)',
      rotate: '0deg',
    },
    '64%': {
      left: '-1rem',
    },
    '68%': {
      left: '-3.5rem',
    },
    '72%': {
      left: '-3.5rem',
    },
    '77%': {
      left: '-1rem',
    },
    '82%': {
      left: '5rem',
      top: '50%',
      transform: 'translateY(-50%)',
      rotate: '0deg',
    },
    '90%': {
      left: '5rem',
      top: '50%',
      transform: 'translateY(-50%)',
      bottom: 'unset',
    },
    '92%': {
      left: '6.5rem',
      top: '50%',
      transform: 'translateY(-50%)',
      bottom: 'unset',
    },
    '100%': {
      left: '-5rem',
      top: '50%',
      transform: 'translateY(-50%)',
      bottom: 'unset',
    },
  },
  hqAnimation: {
    position: 'fixed',
    bottom: '-3rem',
    animation: '$hq 5s backwards infinite ease-in-out',
  },
});

export default SignIn;
