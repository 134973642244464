import { Box } from '@mui/material';
import { Settings as LuxonSettings } from 'luxon';
import { Router } from 'router';
import { AlertProvider } from 'shared/components/alerts';
import * as Sentry from '@sentry/react';
import { EnvironmentService } from 'shared/services';
import { FeatureFlagProvider } from 'shared/contexts/feature-flag-provider';
import { config as sentryConfig } from '../sentry-config';

const config = sentryConfig[EnvironmentService.getVariable('ENV')];

Sentry.init({
  environment: config.environment,
  dsn: 'https://6f865ff86dba0e3e1720923742f94a0c@o4506167674863616.ingest.sentry.io/4506183610531840',
  integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: config.tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate: config.replaysSessionSampleRate,
  replaysOnErrorSampleRate: config.replaysOnErrorSampleRate,
});
Sentry.setTag('platform', EnvironmentService.getVariable('PLATFORM_CLIENT'));

LuxonSettings.defaultLocale = 'nl';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Box display="flex" style={{ height: '100%', backgroundColor: '#ffffff' }}>
        <AlertProvider>
          <FeatureFlagProvider>
            <Router />
          </FeatureFlagProvider>
        </AlertProvider>
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export { App };
