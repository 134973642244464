import { Controller, FieldValues } from 'react-hook-form';
import { LwAutocomplete } from 'redesign/autocomplete/autocomplete';
import { FormControl } from '@mui/material';
import { AutocompleteElementFormProps } from './form-autocomplete.types';

const LwFormAutocomplete = <
  T extends object = object,
  ControlFields extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  disabled,
  isLoading,
  ...autocompleteProps
}: AutocompleteElementFormProps<T, ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="filled" fullWidth disabled={disabled} error={!!error}>
          <LwAutocomplete
            {...field}
            {...autocompleteProps}
            rules={rules}
            name={name}
            label={label}
            error={!!error}
            helperText={error ? error.message : null}
            disabled={disabled}
            isLoading={isLoading}
          />
        </FormControl>
      )}
    />
  );
};

export { LwFormAutocomplete };
