import { shiftApplicationService } from 'ogp/services';
import { useMutation } from 'react-query';
import {
  BulkAcceptShiftApplicationOptions,
  BulkAcceptShiftApplicationParams,
} from '../use-bulk-accept-shift-applications.types';

const useOgpBulkAcceptShiftApplications = (options: BulkAcceptShiftApplicationOptions) => {
  const mutationFn = async (params: BulkAcceptShiftApplicationParams) =>
    await shiftApplicationService.bulkAcceptShiftApplications(params);

  return useMutation(mutationFn, options);
};

export { useOgpBulkAcceptShiftApplications };
