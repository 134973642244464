import { PartnerApiService } from './partner-api-service';
import { GetPartnerPlacementsResponse } from './partner-placements-service.types';

export type GetPartnerPlacementsParams = {
  pageParam: number;
  pageSizeParam: number;
};

class PartnerPlacementService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  getPartnerPlacements = async (
    params: GetPartnerPlacementsParams
  ): Promise<GetPartnerPlacementsResponse> => {
    return await this.partnerApiService.get<GetPartnerPlacementsResponse>({
      path: 'placements',
      params: {
        page: params.pageParam,
        pageSize: params.pageSizeParam,
      },
    });
  };

  cancelShiftPlacement = async (placementId: string): Promise<void> => {
    await this.partnerApiService.delete({
      path: `placements/shift-claim/cancel-placement/${placementId}`,
    });
  };

  cancelShiftPlacements = async (placementIds: string[]): Promise<void> => {
    await this.partnerApiService.update({
      path: 'placements/shift-claim/cancel-placements',
      body: { placementIds },
    });
  };
}

export { PartnerPlacementService };
