import { useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { Header, Page } from 'redesign';
import { useGetCounterOfferQuery } from './hooks/use-get-counter-offer';
import { CounterOfferDetails } from './counter-offer-details';
import { ShiftDetailsHeader } from '../shift-details-header/shift-details-header';
import { useGetWorkedShiftQuery } from '../hooks/use-get-worked-shift';

export const SettleCounterOffer = () => {
  const { id } = useParams<'id'>();
  invariant(id);
  const workedShift = useGetWorkedShiftQuery(id);
  const counterOffer = useGetCounterOfferQuery(id);

  if (workedShift.status !== 'success') {
    return <RenderUnsettledUI data={workedShift} />;
  }
  if (counterOffer.status !== 'success') {
    return <RenderUnsettledUI data={counterOffer} />;
  }
  const shiftData = workedShift.data;
  const counterOfferData = counterOffer.data;

  return (
    <Page
      header={<Header titleText="Tegenvoorstel" />}
      pageStyle={{ height: '70%' }}
      toolbox={<ShiftDetailsHeader shiftData={shiftData} />}
    >
      <CounterOfferDetails data={counterOfferData} shiftClaimId={id} />
    </Page>
  );
};
