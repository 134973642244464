import { DateTime } from 'luxon';
import { PartnerApiService } from './partner-api-service';
import { GetPartnerCheckedOutsResponse } from './checked-outs-service.types';

export type GetPartnerCheckedOutParams = {
  pageParam: number;
  pageSizeParam: number;
  fromDate?: DateTime;
  toDate?: DateTime;
  companies?: string[];
  jobs?: string[];
  workers?: string[];
};

type CreateCheckedOutExportParams = {
  shiftClaimIds?: string[];
  fromDate?: DateTime;
  toDate?: DateTime;
  companies?: string[];
  jobs?: string[];
  workers?: string[];
};

class PartnerCheckedOutService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  getCheckedOuts = async (
    params: GetPartnerCheckedOutParams
  ): Promise<GetPartnerCheckedOutsResponse> => {
    return await this.partnerApiService.get<GetPartnerCheckedOutsResponse>({
      path: 'checkouts/checked-out-shifts',
      params: {
        ...params,
        page: params.pageParam,
        pageSize: params.pageSizeParam,
      },
    });
  };

  createCheckedOutExport = async (
    createCheckedOutExportParams: CreateCheckedOutExportParams
  ): Promise<void> => {
    return await this.partnerApiService.create<void>({
      path: 'checkouts/checked-out-shifts/export',
      body: createCheckedOutExportParams,
    });
  };
}

export { PartnerCheckedOutService };
