import React from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { LwButton } from 'redesign';

const [Provider, useCheckoutsTypeProvider] = ProviderFactory<{
  type: 'shifts' | 'projects';
  switchType: () => void;
}>('CheckoutsInboxProvider not found');

const CheckoutsTypeProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [type, setType] = React.useState<'shifts' | 'projects'>('shifts');

  const switchType = () => {
    setType(type === 'shifts' ? 'projects' : 'shifts');
  };

  return <Provider value={{ type, switchType }}>{children}</Provider>;
};

const CheckoutTypeToggle = () => {
  const { type, switchType } = useCheckoutsTypeProvider();

  const handleClick = () => {
    switchType();
  };

  return (
    <LwButton color="primary" onClick={handleClick}>
      {type === 'shifts' ? 'Projecten' : 'Shifts'}
    </LwButton>
  );
};

export { CheckoutsTypeProvider, useCheckoutsTypeProvider, CheckoutTypeToggle };
