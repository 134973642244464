import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { userService } from 'ogp/services';
import { useMutation, UseMutationOptions } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

type UserMutationOptions = UseMutationOptions<unknown, unknown, string>;

export const useArchiveUser = (options?: UserMutationOptions) => {
  const mutationFn = async (branchofficeId: string) =>
    await userService.archiveUser(branchofficeId);
  const invalidateQuery = useInvalidateQuery();
  const mutationOptions: UserMutationOptions = {
    onSettled: () => {
      invalidateQuery(QUERY_KEYS_OGP.userList());
    },
    ...options,
  };

  return useMutation(mutationFn, mutationOptions);
};
