import { useMutation } from 'react-query';
import { partnerUserService } from 'hq/services';
import { EditPartnerUserPayload } from 'hq/services/partner-user-service.types';

export const useEditPartnerUser = (partnerId: string, userId: string) => {
  const mutationFn = async (partner: EditPartnerUserPayload) =>
    await partnerUserService.editUser(userId, partner);

  return useMutation(mutationFn);
};
