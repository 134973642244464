import { DateTime } from 'luxon';
import { pluralizeWithValue } from 'shared/utils/pluralize';

export const daysHoursMinutesUntil = (date: string, ended: string = '-'): string => {
  const a = DateTime.now();
  const b = DateTime.fromISO(date);

  const { days, hours, minutes } = b.diff(a, ['days', 'hours', 'minutes']).toObject();

  let relativeTime = '';

  if (days && days > 0) {
    relativeTime += pluralizeWithValue(days, 'dag', 'dagen');
  }

  if ((days && days > 0 && hours && hours > 0) || (hours && hours > 0)) {
    if (relativeTime) {
      relativeTime += ', ';
    }

    relativeTime += pluralizeWithValue(hours, 'uur', 'uur');
  }

  if (!days && !hours && minutes && minutes > 0) {
    if (relativeTime) {
      relativeTime += ', ';
    }

    relativeTime += pluralizeWithValue(Math.round(minutes), 'minuut', 'minuten');
  }

  if (!relativeTime) {
    return ended;
  }

  return relativeTime;
};
