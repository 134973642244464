import { ContextMenuButton, ContextMenuItem, TableHeader } from 'redesign';
import { Column } from 'react-table';
import { Typography } from '@mui/material';
import { BankAccountTableCell, BankAccountTableData } from '../bank-account-table.types';

export const getBankAccountColumnDefinition = (showEmploymentId: boolean) => {
  const workerName: Column<BankAccountTableData> = {
    Header: () => <TableHeader>Naam</TableHeader>,
    accessor: 'workerName',
    Cell: SimpleCell,
  };
  const iban: Column<BankAccountTableData> = {
    Header: () => <TableHeader>Rekeningnummer</TableHeader>,
    width: 300,
    accessor: 'iban',
    Cell: SimpleCell,
  };
  const previousIban: Column<BankAccountTableData> = {
    Header: <TableHeader>Oud rekeningnummer</TableHeader>,
    width: 300,
    accessor: 'previousIban',
    Cell: SimpleCell,
  };
  const employmentId: Column<BankAccountTableData> = {
    Header: <TableHeader>Personeelsnummer</TableHeader>,
    width: 150,
    accessor: 'employmentId',
    Cell: SimpleCell,
  };
  const employmentType: Column<BankAccountTableData> = {
    Header: <TableHeader>Dienstverband</TableHeader>,
    width: 150,
    accessor: 'employmentType',
    Cell: SimpleCell,
  };

  const actions: Column<BankAccountTableData> = {
    accessor: 'actions',
    Header: '',
    width: 89,
    Cell: ActionCell,
  };

  return [
    workerName,
    iban,
    previousIban,
    ...(showEmploymentId ? [employmentId] : []),
    employmentType,
    actions,
  ];
};

const SimpleCell = (
  cell:
    | BankAccountTableCell<'workerName'>
    | BankAccountTableCell<'iban'>
    | BankAccountTableCell<'previousIban'>
) => {
  return (
    <Typography noWrap variant="subtitle2">
      {cell.value}
    </Typography>
  );
};

const ActionCell = (cell: BankAccountTableCell<'actions'>) => {
  const { approve, reject, value } = cell;

  return (
    <ContextMenuButton menuId={`bank-account-actions-${value.id}`}>
      <ContextMenuItem key="approve" onClick={() => approve(value.id)}>
        Goedkeuren
      </ContextMenuItem>
      <ContextMenuItem key="reject" onClick={() => reject(value.id)}>
        Afkeuren
      </ContextMenuItem>
    </ContextMenuButton>
  );
};
