import { Box, Grid, styled } from '@mui/material';

const TitleGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const TagBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export { TitleGrid, TagBox };
