import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { placementService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'shared/components/alerts';

type Params = Parameters<typeof placementService.addProposal>;

const useAddProposal = () => {
  const { alertError, alertSuccess } = useAlert();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      placementId,
      checkoutId,
      payload,
    }: {
      placementId: Params[0];
      checkoutId: Params[1];
      payload: Params[2];
    }) => await placementService.addProposal(placementId, checkoutId, payload),
    onError: (e) => {
      alertError(e);
    },
    onSuccess: () => {
      alertSuccess('Nieuw voorstel toegevoegd aan de checkout');
      queryClient.invalidateQueries(QUERY_KEYS_OGP.projectsCheckouts());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.checkoutInboxProjectsFilters());
    },
  });
};

export { useAddProposal };
