import { HqApiService } from './hqApiService';
import {
  CreatePartnerUserPayload,
  EditPartnerUserPayload,
  GetPartnerUserResponse,
  GetPartnerUsersResponse,
} from './partner-user-service.types';

export class PartnerUserService {
  constructor(private readonly hqApiService: HqApiService) {}

  getUser = async (userId: string): Promise<GetPartnerUserResponse> => {
    const response = await this.hqApiService.get<GetPartnerUserResponse>({
      path: `partner-users/${userId}`,
    });

    return response;
  };

  getUsers = async (partnerId?: string) => {
    const response = await this.hqApiService.get<GetPartnerUsersResponse>({
      path: `partner-users`,
      params: { partnerId },
    });

    return response;
  };

  createUser = async (payload: CreatePartnerUserPayload) => {
    await this.hqApiService.create<void>({
      path: `partner-users`,
      body: payload,
    });
  };

  editUser = async (userId: string, payload: EditPartnerUserPayload) => {
    await this.hqApiService.update<void>({
      path: `partner-users/${userId}`,
      body: payload,
    });
  };

  archiveUser = async (userId: string) => {
    await this.hqApiService.delete<void>({
      path: `partner-users/${userId}`,
    });
  };
}
