import { IconClose } from 'assets/img';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { Card, Modal, styled } from '@mui/material';
import { LwIconButton } from 'redesign';
import { PopupModalContent } from './popup-modal-content';

type PopupProps = {
  id: string;
  flexWorkerRelation?: FlexWorkerRelation;
  isOpen: boolean;
  close: () => void;
};

const Popup = ({ id, flexWorkerRelation, isOpen, close }: PopupProps) => {
  return (
    <Modal open={isOpen} onClose={close}>
      <StyledCard>
        <LwStyledIconbutton iconColorMode="fill" color="secondary" onClick={close}>
          <IconClose width={20} height={20} />
        </LwStyledIconbutton>
        <PopupModalContent id={id} flexWorkerRelation={flexWorkerRelation} />
      </StyledCard>
    </Modal>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: theme.spacing(160),
  maxWidth: theme.spacing(200),
  width: theme.spacing(200),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  display: 'flex',
  borderRadius: theme.spacing(4),
}));

const LwStyledIconbutton = styled(LwIconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(8),
  right: theme.spacing(11),
  cursor: 'pointer',
}));

export { Popup };
