import type { Column } from 'react-table';
import { ContextMenuButton, TableCell, TableHeader } from 'redesign';
import { IconFlexPoolOnlyJob } from 'assets/img';
import { Typography } from '@mui/material';
import { ClaimantCellProps, ClaimantColumnDefinition } from './claimant-table.types';
import { getShiftDetailsColumnDefinitionBase } from '../../shared/columns/get-shift-details-column-definition-base';

export const getClaimantColumnDefinition: () => Column<ClaimantColumnDefinition>[] = () => {
  const [flexWorkerImage, fw] = getShiftDetailsColumnDefinitionBase();

  const flexPoolIndicator: Column<ClaimantColumnDefinition> = {
    Header: '',
    accessor: 'flexPoolIndicator',
    Cell: FlexPoolIndicatorCell,
  };
  const email: Column<ClaimantColumnDefinition> = {
    Header: () => <TableHeader>E-mailadres</TableHeader>,
    accessor: 'email',
    Cell: EmailCell,
  };

  const phone: Column<ClaimantColumnDefinition> = {
    Header: () => <TableHeader>Telefoonnummer</TableHeader>,
    accessor: 'phone',
    Cell: PhoneCell,
  };

  const actions: Column<ClaimantColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  const noShow: Column<ClaimantColumnDefinition> = {
    accessor: 'noShow',
    Header: '',
    Cell: NoShowCell,
  };

  return [flexWorkerImage, flexPoolIndicator, fw, email, phone, noShow, actions];
};

const FlexPoolIndicatorCell = ({ value }: ClaimantCellProps<'flexPoolIndicator'>) => {
  return value ? <IconFlexPoolOnlyJob width={24} /> : null;
};

const EmailCell = ({ value }: ClaimantCellProps<'email'>) => {
  return <TableCell>{value}</TableCell>;
};

const PhoneCell = ({ value }: ClaimantCellProps<'phone'>) => {
  return <TableCell>{value}</TableCell>;
};

const NoShowCell = ({ value }: ClaimantCellProps<'noShow'>) => {
  if (value) {
    return (
      <Typography variant="body1" color="secondary">
        No-show
      </Typography>
    );
  }
  return <></>;
};

const ActionCell = ({ value, getRowActions }: ClaimantCellProps<'actions'>) => {
  const actions = getRowActions(value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`claimant-actions-menu-${value.workerId}`}>
      {actions}
    </ContextMenuButton>
  );
};
