export const COMPANIES_FILTER_INITIAL_VALUE = {
  filter: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export type CompaniesFilterParams = {
  filter?: string;
};
export type SetCompaniesFilterParams = (params: CompaniesFilterParams) => void;
