import { WorkerEmploymentType } from '@types';

export const getFlexWorkerLabel = (
  employmentType: WorkerEmploymentType | undefined,
  partnerName?: string,
  hasCandidateLevel?: boolean
) => {
  if (partnerName) {
    return partnerName;
  }

  if (hasCandidateLevel) {
    return 'Candidate';
  }

  if (employmentType === 'FREELANCER') {
    return 'Zelfstandige';
  }

  if (employmentType === 'TEMPWORKER') {
    return 'Uitzendkracht';
  }

  return undefined;
};
