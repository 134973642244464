import { Box, CircularProgress } from '@mui/material';
import { IconApproved, IconCancel, IconTrash } from 'assets/img';
import { LwButton } from 'redesign';
import { DialogButton, SealedDialogButton } from './buttons.types';

const SecondaryButton: DialogButton = ({
  children = 'Annuleer',
  startIcon = <IconCancel />,
  iconColorMode = 'stroke',
  ...props
}) => (
  <LwButton iconColorMode={iconColorMode} startIcon={startIcon} color="secondary" {...props}>
    {children}
  </LwButton>
);

const PrimaryButton: DialogButton = ({
  children = 'Bevestig',
  startIcon = <IconApproved />,
  iconColorMode = 'stroke',
  ...props
}) => {
  return (
    <LwButton
      startIcon={props.disabled ? <CircularProgress size={24} /> : startIcon}
      iconColorMode={iconColorMode}
      color="primary"
      {...props}
    >
      {children}
    </LwButton>
  );
};

const AcknowledgeButton: SealedDialogButton = (props) => {
  return (
    <LwButton color="primary" {...props}>
      Oké
    </LwButton>
  );
};

const DeleteButton: SealedDialogButton = (props) => {
  return (
    <PrimaryButton color="secondary" startIcon={<IconTrash />} {...props}>
      <Box component="span" color="red">
        Verwijder
      </Box>
    </PrimaryButton>
  );
};

export { SecondaryButton, PrimaryButton, AcknowledgeButton, DeleteButton };
