import { PartnerApiService } from './partner-api-service';
import { GetPartnerNotificationsResponse } from './partner-notification-service.types';

export class PartnerNotificationService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public async getPartnerNotifications() {
    const result = await this.partnerApiService.get<GetPartnerNotificationsResponse>({
      path: `notifications`,
    });
    return result;
  }
}
