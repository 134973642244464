import { Box, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useGetShiftClaimExport } from 'ogp/hooks/queryHooks/shiftclaims/useGetShiftClaimExport';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { LwFormMultiSelect } from 'redesign/forms/multi-select';
import { LwButton, LwFormDate, LwIconButton } from 'redesign';
import { IconCross } from 'assets/img';
import { ExportFilters, ExportModalProps } from './export-drawer.types';
import { useGetOgpJobTypes } from '../../../../../../hooks/query/filters/use-get-job-types';

export const ExportModal = ({
  exportModalOpen,
  setExportModalOpen,
  startDate,
  endDate,
}: ExportModalProps) => {
  const theme = useTheme();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, watch, control, setValue, reset } = useForm<ExportFilters>();
  const classes = getClasses();
  const jobTypeQuery = useGetOgpJobTypes();

  const today = new Date().toISOString().split('T')[0];
  const mutation = useGetShiftClaimExport({
    onSuccess: () => alertSuccess(alerts.success.downloadExport),
    onError: (e) => alertError(e),
  });

  useEffect(() => {
    setValue('fromDate', startDate.toFormat('yyyy-LL-dd'));
    setValue('toDate', endDate.toFormat('yyyy-LL-dd'));
  }, [exportModalOpen, startDate, endDate, setValue]);

  const disabled = !(watch('fromDate') && watch('toDate') && watch('jobTypes')?.length);

  function onSubmit(value: ExportFilters) {
    const { fromDate, toDate, jobTypes } = value;
    const filterData = {
      fromDate,
      toDate,
      jobTypeIds: jobTypes.map((jobType) => jobType.id),
    };

    mutation.mutate(filterData);
  }

  function clearForm() {
    reset();
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }

  return (
    <Drawer
      anchor="right"
      open={exportModalOpen}
      onClose={() => setExportModalOpen(false)}
      ModalProps={{ style: { zIndex: theme.zIndex.appBar - 1 } }}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.drawerContent}>
        <Box className={classes.head}>
          <Typography variant="h3">Exporteer shifts</Typography>
          <LwIconButton
            iconColorMode="stroke"
            color="secondary"
            onClick={() => setExportModalOpen(false)}
          >
            <IconCross />
          </LwIconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Box className={classes.formFields}>
            <Box className={classes.formDates}>
              <LwFormDate
                name="fromDate"
                label="Vanaf"
                control={control}
                maxDate={watch('toDate') || today}
                className={classes.date}
              />
              <LwFormDate
                name="toDate"
                label="Tot"
                control={control}
                minDate={watch('fromDate')}
                className={classes.date}
              />
            </Box>
            <LwFormMultiSelect
              control={control}
              name="jobTypes"
              ariaLabelInput={'jobType'}
              options={jobTypeQuery.data.jobTypes}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(_, selectedOptions) => {
                setValue('jobTypes', selectedOptions);
              }}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value.id;
              }}
              label="Functies"
            />
          </Box>
          <Box className={classes.actions}>
            <LwButton color="primary" type="submit" disabled={disabled}>
              Download
            </LwButton>
            <LwButton color="secondary" onClick={clearForm}>
              Wissen
            </LwButton>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  drawerContent: {
    width: theme.spacing(100),
    height: '100%',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(8, 8, 6),
  },
  form: {
    height: 'calc(100% - 112px)', // head height + margin bottom
  },
  formDates: {
    display: 'flex',
    gap: '14px',
  },
  formFields: {
    padding: theme.spacing(2, 4, 2, 8),
    height: 'calc(100% - 105px)', // form actions height
    overflowY: 'scroll',
  },
  branchOffice: {
    marginTop: theme.spacing(4),
    '& span': {
      fontWeight: 'bold',
    },
  },
  branchOfficeCheckbox: {
    margin: theme.spacing(1, 0),
  },
  branchOfficeJobs: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(10),
    '& span': {
      fontWeight: 'normal',
    },
  },
  jobCheckbox: {
    margin: theme.spacing(1, 0),
  },
  date: {
    width: '160px',
  },
  drawerPaper: {
    zIndex: 1,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(4, 8, 8),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    background: 'white',
  },
}));
