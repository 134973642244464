import React from 'react';

type SetValueFn<T> = (value: T) => void;
type Result<T> = [T, SetValueFn<Exclude<T, undefined>>, () => void];

function useSessionStorage<T>(key: string): Result<T | undefined>;
function useSessionStorage<T>(key: string, initialValue: T): Result<T>;
function useSessionStorage<T>(key: string, initialValue?: T): Result<T | undefined> {
  const [state, setState] = React.useState<T | undefined>(() => {
    const item = window.sessionStorage.getItem(key);

    if (item !== null) {
      try {
        return JSON.parse(item);
      } catch {
        // if item is string value
        return item;
      }
    }

    if (initialValue !== undefined) {
      return initialValue;
    }

    return undefined;
  });

  const setValue: SetValueFn<T> = (value) => {
    setState(value);
  };

  const removeValue = () => {
    setState(undefined);
  };

  React.useEffect(() => {
    if (state === undefined) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setValue, removeValue];
}

export { useSessionStorage };
