import { Box, CircularProgress, styled, TableCell } from '@mui/material';

const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-15px',
  marginLeft: '-15px',
});

const ErrorRow = ({ message, ...props }: { message: string }) => (
  <TableCell {...props} color="red">
    <Box component="span" color="red" fontWeight="bold">
      <span>ERROR! </span>
      {message}
    </Box>
  </TableCell>
);

const SuccessRow = ({ message, ...props }: { message: string }) => (
  <TableCell {...props}>
    <Box component="span" color="green" fontWeight="bold">
      {message}
    </Box>
  </TableCell>
);

export { StyledCircularProgress, SuccessRow, ErrorRow };
