import { Box, styled } from '@mui/material';
import { ReactNode, useState } from 'react';
import { SmallLoader } from 'shared/components';

export const LazyImage = ({
  alt,
  fallBackComponent,
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & { fallBackComponent?: ReactNode }) => {
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return <>{fallBackComponent}</>;
  }

  return (
    <StyledBox>
      {!loaded && <SmallLoader />}
      <img alt={alt} {...props} onLoad={() => setLoaded(true)} onError={() => setHasError(true)} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)({ width: '100%', objectFit: 'contain' });
