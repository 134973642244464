import { useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { ScheduleSegment } from '@types';
import { ScheduleColumn } from './components/schedule-column';
import { TimesContainer } from './components/times-container';
import { WeekDays } from '../../utils/date-utils';

interface ScheduleTableColumnProps {
  schedule: Record<WeekDays, ScheduleSegment[]>;
}

const ScheduleTableContent = ({ schedule }: ScheduleTableColumnProps) => {
  const columns = useMemo(
    () => Object.keys(schedule).map((key) => ({ key, segments: schedule[key as WeekDays] })),
    [schedule]
  );

  return (
    <SheduleTableWrapper data-testid="availability-container">
      {columns.map(({ key, segments }) => (
        <ScheduleColumn key={key} segments={segments} />
      ))}
      <TimesContainer times={times} />
    </SheduleTableWrapper>
  );
};

const times = ['0', '6', '12', '18', '24'];

export { ScheduleTableContent };

const SheduleTableWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '90%',
  marginLeft: theme.spacing(1),
  flex: 1,
}));
