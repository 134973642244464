import React from 'react';
import { emptyShiftClaimFilters, ShiftClaimFilters } from 'shared/contexts/FilterContext';
import { ProviderFactory } from 'shared/providers/provider-factory';

const [Provider, useShiftClaimFilters] = ProviderFactory<{
  shiftClaimFilters: ShiftClaimFilters;
  setShiftClaimFilters: React.Dispatch<React.SetStateAction<ShiftClaimFilters>>;
  resetShiftClaimFilters: () => void;
}>('ShiftClaimFilterContext must be used within a ShiftClaimFilterProvider');

const ShiftClaimFiltersProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [shiftClaimFilters, setShiftClaimFilters] =
    React.useState<ShiftClaimFilters>(emptyShiftClaimFilters);

  const reset = React.useCallback(() => {
    setShiftClaimFilters(emptyShiftClaimFilters);
  }, []);

  return (
    <Provider value={{ shiftClaimFilters, setShiftClaimFilters, resetShiftClaimFilters: reset }}>
      {children}
    </Provider>
  );
};

export { ShiftClaimFiltersProvider, useShiftClaimFilters };
