import { ReactNode, useMemo } from 'react';
import { Table } from 'redesign';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { QueryObserverSuccessResult } from 'react-query';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { getApplicantColumnDefinition } from 'shared/components/shift-detail-modal/components/tabs/applicants/components/helpers/get-applicant-column-definition';
import {
  ApplicantColumnDefinition,
  ApplicantsTableOptions,
} from 'shared/components/shift-detail-modal/components/tabs/applicants/components/helpers/applicant-table.types';
import { EmploymentType } from 'partner/shared/services/partner-worker-service.types';
import { getApplicantTableData } from './get-applicant-table-data';
import { EmptyTableMessage } from '../../../../../../ogp/components/common/empty-table-message';
import { WorkApplicationTableItem } from '../../../../../services/work-application-service.types';

type Props = {
  query: QueryObserverSuccessResult<WorkApplicationTableItem[]>;
  getActions: (application: WorkApplicationTableItem) => ReactNode[];
};

export const ProjectApplicantTable = ({ query, getActions }: Props) => {
  const columns = getApplicantColumnDefinition<WorkApplicationTableItem>(
    EmploymentType.FREELANCER,
    true,
    {
      showAppliedShifts: false,
    }
  );
  const { data: applicants } = query;
  const tableData = useMemo(() => getApplicantTableData(applicants), [applicants]);
  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();

  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    // TODO: add profile relation for workApplication
    openPopupProfile(flexWorkerId, relation);
  };

  return applicants.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn nog geen aanmeldingen!"
      subtitle="Er hebben zich nog geen flexwerkers aangemeld voor dit project."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<
        ApplicantColumnDefinition<WorkApplicationTableItem>,
        ApplicantsTableOptions<WorkApplicationTableItem>
      >
        stickyHeader={true}
        columns={columns}
        data={tableData}
        onProfileClick={onProfileClick}
        getRowActions={(application) => getActions(application)}
      />
    </>
  );
};
