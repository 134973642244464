import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { billingEntityService } from 'ogp/services';
import { useQuery } from 'react-query';

const useGetBillingEntitiesListing = () => {
  const query = useQuery(
    QUERY_KEYS_OGP.billingEntities(),
    async () => await billingEntityService.getBillingEntitiesListing()
  );

  return query;
};

export { useGetBillingEntitiesListing };
