import { FlexWorkerFlexPools, FlexWorkerProfileData } from '@types';
import { FlexPoolFilters } from 'shared/components/shift-forms/components/affected-flex-pools-list/hooks/use-get-flex-pools';
import { FlexPool } from './flex-pool-service.types';
import { HqApiService } from './hqApiService';

export class FlexPoolService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Get a list of existing flexPools
   */
  public getFlexPools = async (params?: FlexPoolFilters): Promise<FlexPool[]> => {
    const flexPools = await this.hqApiService.get<FlexPool[]>({
      path: 'flex-pools',
      params: { ...params },
    });

    return flexPools;
  };

  public getFlexWorkerFlexPools = async (flexWorkerId: string, shiftId: string) => {
    const response = await this.hqApiService.get<FlexWorkerFlexPools[]>({
      path: `flex-workers/${flexWorkerId}/flex-pools-for-shift/${shiftId}`,
    });
    return response;
  };

  /**
   * Get flexPools for flexWorker
   */
  public getFlexPoolsForFlexWorker = async (flexWorkerId: string): Promise<FlexPool[]> => {
    const flexPools = await this.hqApiService.get<FlexPool[]>({
      path: `flex-pools/for-flexworker?flexWorkerId=${flexWorkerId}`,
    });

    return flexPools;
  };

  public getFlexWorkerProfileThroughFlexPool = async (flexPoolId: string, flexWorkerId: string) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `flex-pools/${flexPoolId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
