import { hqPrefix, RouteDefinition } from 'Routes';
import { Unauthorized } from 'shared/components/views/unauthorized/Unauthorized';
import { useAlert } from 'shared/components/alerts';
import { ExtendedError, ExtendedErrorType } from 'shared/services/abstractApiService';
import { RenderUnsettledUI } from 'shared/components';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DialogProvider } from 'ogp/providers/dialog-provider';
import { RouteRenderer } from 'router';
import { NotFound } from 'shared/components/views/404/404';
import { PARTNER_PATHS, PartnerRouteKeys } from './paths';
import { partnerRoutePrefix } from './settings';
import { useGetPartnerUserInfo } from './shared/hooks/use-get-partner-user-info';
import { usePartnerRelativePath } from './shared/hooks/use-partner-relative-path';
import { PartnerLayout } from './partner-layout';
import { PartnerShiftPlanning } from './components/views/partner-shift-planning/partner-shift-planning';
import { PartnerWorkers } from './components/views/partner-worker/partner-worker';
import { PartnerCheckouts } from './components/views/partner-checkouts/partner-checkouts';
import { PartnerInbox } from './components/views/partner-inbox/partner-inbox';
import { PartnerWorkerDetail } from './components/views/partner-worker/partner-worker-detail-modal/partner-worker-detail-modal';
import { CheckedOutDataProvider } from './components/views/checked-out/partner-checked-out';
import { CreateCheckout } from './components/views/partner-checkouts/checkout-create/create-checkout';
import { PartnerWorkerCreate } from './components/views/partner-worker/partner-worker-form/partner-worker-create';
import { PartnerWorkerUpdate } from './components/views/partner-worker/partner-worker-form/partner-worker-update';
import { SettleCounterOffer } from './components/views/partner-checkouts/settle-counter-offer/settle-counter-offer';
import { PartnerPlacements } from './components/views/partner-placements/partner-placements';

const partnerComponents: Record<PartnerRouteKeys, RouteDefinition> = {
  Unauthorized: { ...PARTNER_PATHS.Unauthorized, element: <Unauthorized /> },
  ShiftsPlanning: { ...PARTNER_PATHS.ShiftsPlanning, element: <PartnerShiftPlanning /> },
  Checkout: { ...PARTNER_PATHS.Checkout, element: <PartnerCheckouts /> },
  CheckoutCreate: { ...PARTNER_PATHS.CheckoutCreate, element: <CreateCheckout /> },
  CounterOffer: { ...PARTNER_PATHS.CounterOffer, element: <SettleCounterOffer /> },
  Inbox: { ...PARTNER_PATHS.Inbox, element: <PartnerInbox /> },
  Placement: { ...PARTNER_PATHS.Placement, element: <PartnerPlacements /> },
  CheckedOut: { ...PARTNER_PATHS.CheckedOut, element: <CheckedOutDataProvider /> },
  Worker: { ...PARTNER_PATHS.Worker, element: <PartnerWorkers /> },
  WorkerDetail: { ...PARTNER_PATHS.WorkerDetail, element: <PartnerWorkerDetail /> },
  WorkerCreate: { ...PARTNER_PATHS.WorkerCreate, element: <PartnerWorkerCreate /> },
  WorkerUpdate: { ...PARTNER_PATHS.WorkerUpdate, element: <PartnerWorkerUpdate /> },
};

const partnerBasePath = `:partnerHash`;

const PartnerRouter = () => {
  const { alertError } = useAlert();
  const generatePath = usePartnerRelativePath();
  const userQuery = useGetPartnerUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userQuery.error instanceof ExtendedError &&
      userQuery.error.type === ExtendedErrorType.ImposterMissingPartnerId
    ) {
      alertError(userQuery.error);
      navigate(`${hqPrefix}/sales/companies`);
    }
  }, [alertError, userQuery.error, navigate]);

  if (userQuery.status !== 'success') {
    return <RenderUnsettledUI data={userQuery} />;
  }

  return (
    <DialogProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate replace to={`${partnerBasePath}/${partnerComponents.ShiftsPlanning.path}`} />
          }
        />
        {userQuery.data
          ? RouteRenderer(
              Object.values(partnerComponents),
              partnerBasePath,
              userQuery.data,
              PartnerLayout
            )
          : null}

        <Route
          path={partnerRoutePrefix}
          element={<Navigate replace to={generatePath(partnerComponents.ShiftsPlanning)} />}
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </DialogProvider>
  );
};

export { PartnerRouter };
