import { workApplicationService as ogpWorkApplicationService } from 'ogp/services';
import { workApplicationService as hqWorkApplicationService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';
import { SHARED_QUERY_KEYS_PROJECT } from '../../../../../../query-keys/project';

type WorkApplicationMutationParams = {
  workApplicationId: string;
  workerId: string;
};

export const useDenyWorkApplication = () => {
  const siteDetectionResult = useSiteDetection();
  const mutationFn = (params: WorkApplicationMutationParams) =>
    getMutationFunction(params, siteDetectionResult);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: async () => {
      queryClient.invalidateQueries(SHARED_QUERY_KEYS_PROJECT.projects(siteDetectionResult));
    },
  });
};

const getMutationFunction = (
  params: WorkApplicationMutationParams,
  siteDetectionResult: SiteDetectionResult
) => {
  const { workApplicationId, workerId } = params;
  if (siteDetectionResult.isHQ) {
    return hqWorkApplicationService.denyWorkApplication(workApplicationId, workerId);
  }
  if (siteDetectionResult.isOGP) {
    return ogpWorkApplicationService.denyWorkApplication(workApplicationId, workerId);
  }

  throw new Error(
    `useDenyWorkApplication: site "${siteDetectionResult.site}" is neither HQ nor OGP.`
  );
};
