import { useCallback, useState } from 'react';
import { Dialog } from '@mui/material';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { ShiftPlanningTableDataProvider } from './table/shift-planning-table';
import { useShiftPlanningPage } from './table/hooks/use-shift-planning';
import { ShiftPlanningDataProvider } from './filters/shift-planning-filters-data-provider';
import { BulkForwardToPartnerDialog } from './bulk-forward-to-partner/bulk-forward-to-partner';
import { FilterButton } from '../../../../../ogp/components/views/administration/shared';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';
import { hqRoutes } from '../../../../Routes';
import { stringifyFilters } from '../../../../../shared/utils/stringify-filters';
import { IconAdd } from '../../../../../assets/img';
import { useToggle } from '../../../../../shared/hooks';

const ShiftPlanning = () => {
  const generatePath = useHqRelativePath();
  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState(false);
  const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]); // State lifted here

  const { on: filtersOpen, toggle: toggleFilters } = useToggle();

  const { filters: appliedFilters, setFilters, selectedFilterCount } = useShiftPlanningPage();

  const handleSelectionChange = useCallback((ids: string[]) => {
    setSelectedShiftIds(ids);
  }, []);

  return (
    <Page
      header={<Header titleText="Shift Planning" />}
      toolbox={
        <Toolbox underline={false}>
          <LwButton
            to={generatePath(
              hqRoutes.PlannerShiftCreateShift,
              undefined,
              stringifyFilters(appliedFilters)
            )}
            color="primary"
            startIcon={<IconAdd />}
            iconColorMode="fill"
          >
            Voeg shift toe
          </LwButton>
          <LwButton
            disabled={selectedShiftIds.length === 0}
            color="secondary"
            onClick={() => setIsBulkDialogOpen(true)}
            iconColorMode="fill"
          >
            Naar partners{selectedShiftIds.length > 0 ? ` (${selectedShiftIds.length})` : ''}
          </LwButton>

          <Toolbox.PushRight>
            <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      <ShiftPlanningDataProvider
        appliedFilters={appliedFilters}
        handleFiltersChange={setFilters}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />
      <ShiftPlanningTableDataProvider onSelectionChange={handleSelectionChange} />
      <Dialog open={isBulkDialogOpen} onClose={() => setIsBulkDialogOpen(false)} fullWidth={true}>
        <BulkForwardToPartnerDialog
          shiftIds={selectedShiftIds}
          closeDialog={() => setIsBulkDialogOpen(false)}
        />
      </Dialog>
    </Page>
  );
};

export { ShiftPlanning };
