import { FormProvider, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { FeatureFlagsForm } from './feature-flags-form';
import { useUpdateFeatureFlags } from './hooks/use-update-feature-flags';
import { useFeatureFlagContext } from '../../../../shared/contexts/feature-flag-provider';
import { FeatureFlags } from '../../../../shared/services';

const FeatureFlagPage = () => {
  const featureFlags = useFeatureFlagContext();
  const { mutate } = useUpdateFeatureFlags();

  const methods = useForm<FeatureFlags>({
    defaultValues: featureFlags,
  });

  const changedFlags = Object.keys(methods.formState.dirtyFields);

  const onSubmit = useCallback(
    (data: FeatureFlags) => {
      const fieldsToUpdate: Partial<FeatureFlags> = {};

      changedFlags.forEach((featureFlag) => {
        fieldsToUpdate[featureFlag as keyof FeatureFlags] = data[featureFlag as keyof FeatureFlags];
      });

      mutate(fieldsToUpdate, {
        onSuccess: () => {
          methods.reset({}, { keepValues: true });
        },
      });
    },
    [changedFlags]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Page
          header={<Header titleText="Feature Flags" />}
          toolbox={
            <Toolbox>
              <LwButton color="primary" type="submit">
                Update {changedFlags.length}
              </LwButton>
            </Toolbox>
          }
        >
          <FeatureFlagsForm featureFlags={featureFlags} />
        </Page>
      </form>
    </FormProvider>
  );
};

export { FeatureFlagPage };
