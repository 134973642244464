import {
  BoxAction,
  BoxIcon,
  Cell,
  EndDateCell,
  Header,
  JobNameCell,
  StartDateCell,
  StateCell,
} from 'shared/components/planner/common/utils';
import type { Column } from 'react-table';
import {
  HqProjectPlanningCellProp,
  HqProjectPlanningColumnDefinition,
} from '../project-planning-table.types';
import { CandidateStateTooltip } from '../../../common/state-tooltip/candidate-state-tooltip';
import { IconIsolationMode } from '../../../../../../../assets/img';

const getProjectPlanningColumnDefinition: () => Column<HqProjectPlanningColumnDefinition>[] =
  () => {
    const ActionCell = (props: HqProjectPlanningCellProp<'action'>) => {
      return (
        <BoxAction>
          <BoxIcon>
            <IconIsolationMode
              onClick={() => props.onShowDetail(props.value)}
              data-testid="action-button"
            />
          </BoxIcon>
        </BoxAction>
      );
    };

    const jobName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      Cell: JobNameCell,
      width: '200px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const companyName = {
      Header: () => <Header>Bedrijf</Header>,
      accessor: 'companyName',
      Cell: Cell,
      width: '200px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const departmentName = {
      Header: () => <Header>Afdeling</Header>,
      accessor: 'departmentName',
      Cell: Cell,
      width: '200px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const fromDate = {
      Header: () => <Header>Vanaf datum</Header>,
      accessor: 'fromDate',
      Cell: StartDateCell,
      width: '115px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const endDate = {
      Header: () => <Header>Tot datum</Header>,
      accessor: 'toDate',
      Cell: EndDateCell,
      width: '115px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const state = {
      Header: () => <Header>Status</Header>,
      accessor: 'candidateState',
      Cell: CandiadateStateTooltip,
      width: '85px',
    } as Column<HqProjectPlanningColumnDefinition>;
    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'action',
      Cell: ActionCell,
      width: '65px',
    } as Column<HqProjectPlanningColumnDefinition>;

    return [jobName, companyName, departmentName, fromDate, endDate, state, action];
  };

const CandiadateStateTooltip = (props: HqProjectPlanningCellProp<'candidateState'>) => {
  return (
    <StateCell {...props}>
      <CandidateStateTooltip candidateState={props.value} />
    </StateCell>
  );
};

export { getProjectPlanningColumnDefinition };
