import { Box, Button, Grid, styled, TextField, Typography } from '@mui/material';
import { useApproveFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useApproveFlexWorkerIdentityCheck';
import { useGetFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorkerIdentityCheck';
import { useRejectFlexWorkerIdentityCheck } from 'hq/hooks/queryHooks/flexWorkers/useRejectFlexWorkerIdentityCheck';
import { useState } from 'react';
import { RenderUnsettledUI } from 'shared/components';
import { useAlert } from 'shared/components/alerts';
import { WorkerEmploymentType } from '@types';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { Controller, useForm } from 'react-hook-form';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { PlatformSettings } from 'shared/services';
import { FlexWorkerIdentityVerificationForm } from './flex-worker-id-check.types';
import { getWorkerAgeFromIso, isWorkerUnderMinumumAge } from '../../utils/worker-age';

const FlexWorkerIdCheck = ({
  flexWorkerId,
  closeModal,
  employmentType,
  platformSettings,
}: {
  flexWorkerId: string;
  closeModal: () => void;
  employmentType: WorkerEmploymentType | null;
  platformSettings: PlatformSettings;
}) => {
  const { alertError } = useAlert();
  const { data, status, error, refetch } = useGetFlexWorkerIdentityCheck(flexWorkerId);
  const [isIdDocumentDownloaded, setIsIdDocumentDownloaded] = useState(false);
  const { allowRequiredVatForWorkActions, allowCandidateOnboarding } = useFeatureFlagContext();
  const invalidateQuery = useInvalidateQuery();
  const identityVerificationId = data?.id;
  const isBSNFieldShown =
    employmentType === 'FREELANCER' &&
    allowRequiredVatForWorkActions === false &&
    data?.vatNumber === undefined &&
    !data?.bsn;

  const { handleSubmit, control } = useForm<FlexWorkerIdentityVerificationForm>({
    defaultValues: {
      bsn: data?.bsn ?? undefined,
    },
  });

  const approveMutation = useApproveFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });
  const denyMutation = useRejectFlexWorkerIdentityCheck(identityVerificationId, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_HQ.flexWorkers());
      invalidateQuery(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
      invalidateQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId));
      closeModal();
    },
    onError: (e) => alertError(e),
  });

  const onApprove = (formData: FlexWorkerIdentityVerificationForm) => {
    approveMutation.mutate(formData.bsn);
    closeModal();
  };

  const onDeny = async () => {
    await denyMutation.mutateAsync();
    closeModal();
  };

  const viewIdDocument = () => {
    setIsIdDocumentDownloaded(true);
    window.open(data?.idDocumentImage);
  };

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ error, refetch, status }} />;
  }

  const ageLimit = platformSettings.minimumTempWorkerAge;
  const workerAge = getWorkerAgeFromIso(data.dateOfBirth) ?? 0;
  const isWorkerUnderAge = isWorkerUnderMinumumAge(workerAge, ageLimit);

  return (
    <form onSubmit={handleSubmit(onApprove)}>
      <Grid container item spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <h2 className="subtitle">ID Check</h2>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <StyledImage src={data.portraitImage} alt="Portrait" />
        </Grid>
        <Grid item xs={6} spacing={2}>
          <StyledImage src={data.selfieImage} alt="Selfie" />
        </Grid>
        {data.idDocumentImage ? (
          <Grid item xs={12}>
            <StyledButton onClick={viewIdDocument} variant="contained" size="large" color="primary">
              <Typography variant="body2">Bekijk identiteitsbewijs</Typography>
            </StyledButton>
          </Grid>
        ) : null}
        <Grid item xs={6}>
          <TextField
            title="Voornaam"
            value={data.firstName}
            label="Voornaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            title="Achternaam"
            value={data.lastName}
            label="Achternaam"
            variant="filled"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} display="flex" alignItems="end">
          <TextField
            title="Geboortedatum"
            value={new Date(data.dateOfBirth).toLocaleDateString('nl-NL')}
            label="Geboortedatum"
            variant="filled"
            disabled={true}
          />
          {employmentType === 'TEMPWORKER' && isWorkerUnderAge && !allowCandidateOnboarding ? (
            <WarningText>
              Deze uitzendkracht is op dit moment {Math.floor(workerAge)} jaar: dit is onder de
              leeftijdgrens van {ageLimit} jaar. Het ID goedkeuren heeft daarom geen invloed op het
              level van deze uitzendkracht.
            </WarningText>
          ) : null}
        </Grid>
        {isBSNFieldShown ? (
          <Grid item xs={4}>
            <Controller
              control={control}
              name="bsn"
              render={({ field: { onChange } }) => (
                <TextField
                  title="BSN"
                  value={data.bsn}
                  label="BSN"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <StyledButton
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={
              data.idDocumentImage
                ? employmentType === 'TEMPWORKER' && !isIdDocumentDownloaded
                : false
            }
          >
            <Typography variant="body2">Goedkeuren</Typography>
          </StyledButton>
          <StyledButton onClick={onDeny} variant="contained" size="large" color="primary">
            <Typography variant="body2">Afwijzen</Typography>
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

const StyledImage = styled('img')({
  width: '100%',
});

const StyledButton = styled(Button)({
  marginRight: 12,
});

const WarningText = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.main,
  paddingLeft: theme.spacing(2),
  fontSize: 12,
  width: '400px',
}));

export { FlexWorkerIdCheck };
