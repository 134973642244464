import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService } from 'ogp/services';
import { useMutation, UseMutationOptions } from 'react-query';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

type JobMutationOptions = UseMutationOptions<unknown, unknown, string>;

export const useArchiveJob = (options?: JobMutationOptions) => {
  const mutationFn = async (jobId: string) => await jobService.archiveJob(jobId);
  const invalidateQuery = useInvalidateQuery();
  const mutationOptions: JobMutationOptions = {
    onSettled: () => {
      invalidateQuery(QUERY_KEYS_OGP.jobList());
    },
    ...options,
  };

  return useMutation(mutationFn, mutationOptions);
};
