import { useQuery } from 'react-query';
import { branchOfficeService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { FirstParamOfFn } from '@types';
import { branchOffices2BranchOfficeListItemsModel } from 'hq/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof branchOfficeService.getBranchOffices>>;

type Params = FirstParamOfFn<typeof useGetBranchOffices>;

const useGetBranchOffices = <TData = Response>(
  options: FirstParamOfFn<typeof branchOfficeService.getBranchOffices>,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await branchOfficeService.getBranchOffices(options);

  return useQuery(QUERY_KEYS_HQ.branchOfficesList(options.companyId), queryFn, {
    select,
    enabled: !!options.companyId,
  });
};

const useGetBranchOfficesAsList = (params: Params) => {
  const result = useGetBranchOffices(params, branchOffices2BranchOfficeListItemsModel);
  return result;
};

export { useGetBranchOffices, useGetBranchOfficesAsList };
