import { usePartnerWorkedShifts } from 'partner/hooks/queryHooks/partner-checkouts/use-partner-checkouts';
import { ContextMenuItem, Table, useOnScrollDown } from 'redesign';
import { IconCounterOffer } from 'assets/img';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PARTNER_PATHS } from 'partner/paths';
import { RenderUnsettledUI } from 'shared/components';
import {
  PartnerPortalCheckouts,
  PartnerPortalCheckoutsParams,
} from 'partner/shared/services/partner-checkouts-service.types';
import { InfiniteQueryObserverSuccessResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import {
  getWorkedShiftsColumnDefinition,
  PartnerWorkedShiftsColumnDefinition,
  PartnerWorkedShiftsTableOptions,
} from './utils/partner-worked-shifts-table-columns';
import { getPartnerWorkedShiftsTableData } from './utils/get-partner-worked-shifts-table-data';

export type PartnerWorkedShiftsTableProviderProps = {
  filters: PartnerPortalCheckoutsParams;
};

export const PartnerWorkedShiftsTableProvider = (props: PartnerWorkedShiftsTableProviderProps) => {
  const partnerWorkedShiftsQuery = usePartnerWorkedShifts(props.filters);

  if (partnerWorkedShiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnerWorkedShiftsQuery} />;
  }

  return <PartnerWorkerShiftsTable query={partnerWorkedShiftsQuery} />;
};

const PartnerWorkerShiftsTable = ({
  query,
}: {
  query: InfiniteQueryObserverSuccessResult<PartnerPortalCheckouts, unknown>;
}) => {
  const flatData = useMemo(
    () => query.data.pages.flatMap((page) => page.items) ?? [],
    [query.data.pages]
  );
  const data = useMemo(() => getPartnerWorkedShiftsTableData(flatData), [flatData]);
  const columns = useMemo(() => getWorkedShiftsColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: query,
    flatData: flatData,
  });

  const navigate = useNavigate();
  const generatePath = usePartnerRelativePath();
  const getRowActions = (shiftClaimId: string) => [
    <ContextMenuItem
      onClick={() => {
        navigate(generatePath(PARTNER_PATHS.CheckoutCreate, { id: shiftClaimId }));
      }}
      key={`${shiftClaimId}__submit-hours`}
    >
      <IconCounterOffer />
      Uren indienen
    </ContextMenuItem>,
  ];

  return (
    <Box maxHeight={'100%'}>
      <Table<PartnerWorkedShiftsColumnDefinition, PartnerWorkedShiftsTableOptions>
        stickyHeader={true}
        columns={columns}
        data={data}
        tableStyle={{ tableLayout: 'fixed' }}
        onScroll={fetchIfBottomScrolled}
        data-testid="checked-out-virtual-table"
        getRowActions={getRowActions}
      />
    </Box>
  );
};
