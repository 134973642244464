import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// https://reactrouter.com/web/example/query-parameters
// https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/examples/QueryParams.js

/**
 * Utility to access to query params from useLocation.
 *
 * Renamed to useQueryParams instead of useQuery because of the naming conflict with React Query.
 */
export function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
