import { Box, styled } from '@mui/material';

type Props = {
  label: string;
  backgroundColor: string;
  color: string;
  width?: string;
};

const StatusIndicatorWrapper = styled(Box)<{ backgroundColor: string; color: string }>(
  ({ theme, backgroundColor, color }) => ({
    textAlign: 'center',
    padding: '4px',
    fontWeight: '700',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRadius: theme.spacing(3),
    backgroundColor: backgroundColor,
    color,
  })
);

export const StatusIndicator = (props: Props) => {
  return (
    <StatusIndicatorWrapper
      backgroundColor={props.backgroundColor}
      color={props.color}
      width={props.width || undefined}
    >
      {props.label}
    </StatusIndicatorWrapper>
  );
};
