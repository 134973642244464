import Avatar from '@mui/material/Avatar';
import type { PopoverProps } from '@mui/material/Popover';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import type { useUserRoles } from 'ogp/components/views/sidebar/use-user-roles';
import { UserRoles } from './user-roles';
import { FeatureFlagsList } from './feature-flags-list';

type Props = {
  userLabel: string;
  userRoles: ReturnType<typeof useUserRoles>;
  id: string | undefined;
};

const UserPopoverOgp = ({
  userLabel,
  userRoles,
  id,
  children,
  ...props
}: React.PropsWithChildren<Props & PopoverProps>) => {
  const styles = getStyles();

  if (!userRoles) {
    return null;
  }

  const { companyRoles } = userRoles;

  return (
    <Box id={id} className={styles.popover} {...props}>
      <Box ml={1} mr={1} className={styles.userBox}>
        <Avatar className={styles.avatar} alt={userLabel}>
          <Typography variant="h2" className={styles.avatarLabel}>
            {userLabel[0]}
          </Typography>
        </Avatar>
        <Box>
          <Typography className={styles.userLabel}>{userLabel}</Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {companyRoles.map((role) => (
              <Typography variant="subtitle1" key={role} className={styles.role}>
                {role}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Box>
        <FeatureFlagsList />
      </Box>
      <Box>
        <UserRoles roles={userRoles} />
      </Box>
      {children}
    </Box>
  );
};

const usePopoverHandlers = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const id = anchorEl ? 'simple-popover' : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { id, anchorEl, handleOpen, handleClose };
};

type PropsHq = {
  userLabel: string;
  userRoles: ReturnType<typeof useUserRoles>;
};

const UserPopoverHq = ({ userLabel, userRoles }: PropsHq) => {
  const styles = getStyles();
  if (!userRoles) {
    return null;
  }

  const { companyRoles } = userRoles;

  return (
    <Box>
      <Box ml={1} mr={1} className={styles.userBox}>
        <Avatar className={styles.avatarHq} alt={userLabel}>
          <Typography variant="h2" className={styles.avatarLabelHq}>
            {userLabel[0]}
          </Typography>
        </Avatar>
        <Box>
          <Typography className={styles.userLabelHq}>{userLabel}</Typography>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {companyRoles.map((role) => (
              <Typography variant="subtitle1" key={role} className={styles.roleHq}>
                {role}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <Box width="100%">
        <FeatureFlagsList />
      </Box>
      <Box width="100%" ml={1} mr={1}>
        <UserRoles roles={userRoles} />
      </Box>
    </Box>
  );
};

const UserPopoverPartner = ({
  userLabel,
  id,
  children,
  ...props
}: React.PropsWithChildren<Omit<Props, 'userRoles'> & PopoverProps>) => {
  const styles = getStyles();

  return (
    <Box id={id} className={styles.popover} {...props}>
      <Box ml={1} mr={1} className={styles.userBox}>
        <Avatar className={styles.avatar} alt={userLabel}>
          <Typography variant="h2" className={styles.avatarLabel}>
            {userLabel[0]}
          </Typography>
        </Avatar>
        <Box>
          <Typography className={styles.userLabel}>{userLabel}</Typography>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Box>
        <FeatureFlagsList />
      </Box>
      {children}
    </Box>
  );
};

const getStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: '398px',
    overflow: 'auto',
    flex: 1,
    '&::-webkit-scrollbar': {
      width: '22px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.2)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.4)',
        backgroundClip: 'content-box',
        border: '8px solid transparent',
        borderRadius: '12px',
      },
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0.1)',
      border: '8px solid white',
      borderRadius: '12px',
    },
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    height: theme.spacing(20),
    width: theme.spacing(20),
    marginRight: theme.spacing(4),
  },
  avatarLabel: {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: '34px',
  },
  userLabel: {
    lineHeight: theme.spacing(8),
  },
  userRole: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  role: {
    marginRight: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.6)',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  popoverHq: {
    '& .MuiPopover-paper': {
      boxShadow: theme.shadows[3],
      borderRadius: theme.spacing(3),
      width: theme.spacing(60),
      padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  avatarHq: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    marginRight: theme.spacing(2),
  },
  avatarLabelHq: {
    textTransform: 'capitalize',
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  userLabelHq: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  userRoleHq: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  roleHq: {
    marginRight: theme.spacing(2),
    fontSize: '14px',
    border: '1px solid rgba(0, 0, 0, 0.6)',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}));

export { UserPopoverOgp, UserPopoverHq, usePopoverHandlers, UserPopoverPartner };
