import { Button, Typography } from '@mui/material';
import { shiftAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useCallback } from 'react';
import { useCancelShiftClaim } from './hooks/use-cancel-shift-claim';
import {
  PrimaryButton,
  SecondaryButton,
  useDialog,
} from '../../../../../../../ogp/providers/dialog-provider';
import { IconApproved, IconCancel } from '../../../../../../../assets/img';
import { Dialog } from '../../../../../../../ogp/providers/dialog-provider/components/buttons.types';

interface ShiftClaimCancelProps {
  redirect?: string;
  shiftClaimId: string;
}

const ShiftClaimCancel = ({ shiftClaimId }: ShiftClaimCancelProps) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();

  const cancelShiftClaim = useCancelShiftClaim();

  const dialogActions: Dialog = {
    title: 'Weet je zeker dat je deze inschrijving wilt annuleren?',
    secondaryButton: (props) => (
      <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
        Annuleer
      </PrimaryButton>
    ),
    primaryButton: (props) => (
      <SecondaryButton startIcon={<IconApproved />} {...props}>
        Bevestig
      </SecondaryButton>
    ),
  };

  const handleCancelShiftClaim = useCallback(() => {
    openDialog(dialogActions, () => {
      disableDialog();
      cancelShiftClaim.mutate(shiftClaimId, {
        onSuccess: () => {
          closeDialog(), alertSuccess(alerts.success.unsubscribed);
        },
        onError: (e) => alertError(e),
        onSettled: () => enableDialog(),
      });
    });
  }, []);

  return (
    <Button
      onClick={handleCancelShiftClaim}
      variant="contained"
      size="small"
      color="primary"
      style={{ marginRight: '8px' }}
    >
      <Typography variant="body2">Annuleer shiftClaim</Typography>
    </Button>
  );
};

export { ShiftClaimCancel };
