import { flexWorkerService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useAddFlexWorkerToPayday = () => {
  const mutateFn = async (flexWorkerId: string): Promise<string> => {
    await flexWorkerService.onboardFlexworkerToPayday(flexWorkerId);
    return flexWorkerId;
  };

  const queryClient = useQueryClient();

  return useMutation(mutateFn, {
    onSuccess: (flexWorkerId: string) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.completeFlexWorkers());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.flexWorker(flexWorkerId));
    },
  });
};
