import React from 'react';
import { Auth } from 'aws-amplify';
import { Drawer, styled } from '@mui/material';
import { LOGO_PATH, SMALL_LOGO_PATH } from 'config';
import { usePopoverHandlers } from 'shared/components/user-popover';
import { Link } from 'react-router-dom';
import { SiteInfo } from 'shared/components';
import { useToggle } from 'shared/hooks';
import { UserPopoverPartner } from 'shared/components/user-popover/user-popover';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PartnerAuthenticatedElement } from 'partner/shared/components/partner-authenticated-element/partner-authenticated-element';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { useGetPartnerNotification } from 'partner/hooks/use-get-partner-notifications';
import { useQueryClient } from 'react-query';
import { SidebarSection } from './sidebar-section';
import { usePartnerSidebarProvider } from './sidebar-provider';
import { SidebarToggle } from './sidebar-toggle';
import { items } from './sidebar-items';
import { SidebarPartnerName } from './sidebar-partner-name';
import { SiteInfoContainer } from './site-info';

const Sidebar = () => {
  const { isOpen } = usePartnerSidebarProvider();
  const [userLabel, setUserLabel] = React.useState('');
  const { id: popoverId, anchorEl } = usePopoverHandlers();
  const generatePath = usePartnerRelativePath();
  const { on: userInfoOpen, toggle: toggleUserInfo } = useToggle();
  const { data } = useGetPartnerNotification();
  const queryClient = useQueryClient();

  const logout = async () => {
    await Auth.signOut();
    queryClient.removeQueries();
  };

  // TODO this might be moved to popover itself?
  React.useEffect(() => {
    let isMounted = true;
    Auth.currentUserInfo().then((res) => {
      if (isMounted && res) {
        setUserLabel(res.username);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Styled.Sidebar variant="permanent" folded={!isOpen}>
        <Styled.Logo folded={!isOpen} to={generatePath(items.navigation[0])}>
          <img src={isOpen ? LOGO_PATH : SMALL_LOGO_PATH} alt="Level.works" />
        </Styled.Logo>

        <SidebarPartnerName />

        <SidebarSection>
          <SidebarSection.List>
            {items.navigation.map((item) => {
              return (
                <PartnerAuthenticatedElement
                  key={item.label}
                  roles={'PARTNER'}
                  policyGroup={PolicyGroups.PARTNER}
                >
                  <SidebarSection.Link
                    {...item}
                    notifications={data || { inbox: 0, checkouts: 0 }}
                  />
                </PartnerAuthenticatedElement>
              );
            })}
          </SidebarSection.List>
        </SidebarSection>

        <SidebarSection style={{ marginTop: 'auto' }}>
          <SidebarSection.Title>Profiel</SidebarSection.Title>
          <SidebarSection.List>
            <SidebarSection.Button {...items.profile[0]} onClick={toggleUserInfo} />
            <SidebarSection.Button {...items.profile[1]} onClick={logout} />
          </SidebarSection.List>
        </SidebarSection>
        <SiteInfoContainer>
          <SiteInfo />
        </SiteInfoContainer>
      </Styled.Sidebar>
      <SidebarToggle />
      <Drawer anchor="right" open={userInfoOpen} onClose={toggleUserInfo}>
        <UserPopoverPartner
          userLabel={userLabel}
          id={popoverId}
          open={!!anchorEl}
          anchorEl={anchorEl}
        />
      </Drawer>
    </>
  );
};

const Styled = {
  Sidebar: styled(Drawer, { shouldForwardProp: (prop) => prop !== 'folded' })<{ folded: boolean }>(
    ({ theme, folded }) => ({
      '&.MuiDrawer-root': {
        width: folded ? '120px' : '248px',
      },
      '&.MuiDrawer-root > *': {
        fontFamily: 'Urbanist',
        fontWeight: 500,
        fontVariationSettings: '"wght" 500',
        gap: theme.spacing(5),
        paddingLeft: theme.spacing(folded ? 3 : 6),
        paddingRight: theme.spacing(folded ? 1 : 4),
        width: folded ? '120px' : '248px',
        '::-webkit-scrollbar': {
          width: '22px',
        },
        '::-webkit-scrollbar-track': {
          background: 'rgba(0, 0, 0, 0.1)',
          border: '8px solid white',
          borderRadius: '12px',
        },

        '::-webkit-scrollbar-thumb': {
          background: 'rgba(0, 0, 0, 0.2)',
          backgroundClip: 'content-box',
          border: '8px solid transparent',
          borderRadius: '12px',
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.4)',
            backgroundClip: 'content-box',
            border: '8px solid transparent',
            borderRadius: '12px',
          },
        },
      },

      '& .MuiPaper-root': {
        borderRight: 0,
        overflowX: 'hidden',
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: folded
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
        }),
      },

      ...(folded && {
        '&.MuiDrawer-root section p:first-of-type': {
          paddingLeft: 0,
        },

        '& .MuiBox-root': {
          justifyContent: 'center',
        },
      }),
    })
  ),
  Logo: styled(Link, { shouldForwardProp: (prop) => prop !== 'folded' })<{ folded: boolean }>(
    ({ theme, folded }) => ({
      height: '24px',
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(8),
      textAlign: 'center',
      width: '100%',

      '& img': {
        maxHeight: '40px',
        maxWidth: folded ? '60%' : '100%',
      },
    })
  ),
};

export { Sidebar };
