import { Box } from '@mui/material';
import { WrapMeWhen } from 'ogp/components/common/wrap-me-when';
import { useAppSettingsProvider } from 'shared/contexts/AppSettingsContext';
import { useHqAdmin } from 'shared/hooks';
import { EnvironmentService } from 'shared/services';
import { useGetApiVersionInfo } from './use-get-api-version-info';

const ogpVersion = EnvironmentService.getVariable('VERSION');
const ogpCommitHash = EnvironmentService.getVariable('COMMIT_HASH');
const ogpBuildTimestamp = EnvironmentService.getVariable('BUILD_TIMESTAMP');
const linkToOgpCommitHash = `https://github.com/Level-works/Levelworks.OGP/commit/${ogpCommitHash}`;

const SiteInfo = () => {
  const { env } = useAppSettingsProvider();
  const backendVersion = useGetApiVersionInfo();
  const linkToBackendCommitHash = `https://github.com/Level-works/Levelworks.Backend/commit/${backendVersion.data?.commitHash}`;

  return (
    <Box textAlign="left" flexDirection="column" display="flex">
      <span>{env.shortLabel !== 'prod' ? `env: ${env.shortLabel}` : null}</span>
      {backendVersion.status === 'success' ? (
        <VersionInfo
          type="api"
          link={linkToBackendCommitHash}
          version={backendVersion.data?.version}
          timestamp={backendVersion.data?.buildTimestamp}
          commitHash={backendVersion.data?.commitHash}
        />
      ) : null}
      <VersionInfo
        type="web"
        link={linkToOgpCommitHash}
        version={ogpVersion}
        timestamp={ogpBuildTimestamp}
        commitHash={ogpCommitHash.length > 0 ? ogpCommitHash : undefined}
      />
    </Box>
  );
};

const VersionInfo = ({
  type,
  link,
  version,
  timestamp,
  commitHash,
}: {
  type: string;
  link: string;
  version: string;
  timestamp: string;
  commitHash?: string;
}) => {
  const { env } = useAppSettingsProvider();
  const isHqAdmin = useHqAdmin();
  const details = [`built at ${timestamp}`];

  if (commitHash) {
    details.push(`using ${commitHash}`);
  }

  return (
    <span>
      <WrapMeWhen
        wrapper={(children) => (
          <a target="_blank" href={link} rel="noreferrer">
            {children}
          </a>
        )}
        when={!!commitHash && (isHqAdmin || env.shortLabel !== 'prod')}
      >
        <>
          {type}: {version}
        </>
      </WrapMeWhen>{' '}
      <abbr title={details.join(' ')}>(?)</abbr>
    </span>
  );
};

export { SiteInfo };
