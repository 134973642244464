/**
 * Data like Companies that hardly ever changes.
 */
export const CACHE_TIME_LONG = 1000 * 60 * 30;

/**
 * Data like FlexWorkers, that could change in the near future
 */
export const CACHE_TIME_MEDIUM = 1000 * 60 * 5;

/**
 * Data like User Roles that hardly ever changes.
 */
export const STALE_TIME_LONG = 1000 * 60 * 5;
