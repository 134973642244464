import { useNavigate, useParams } from 'react-router-dom';
import { companyAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useUpdateCompany } from 'hq/hooks/queryHooks/companies/use-update-company';
import { routes } from 'Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { invariant } from 'shared/utils/utils';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { FormEvent, useCallback } from 'react';
import { Box } from '@mui/material';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { OrtListItem } from '@types';
import { CompanyForm, CompanyFormData } from './company-form';
import { useGetCompanyAsDetail } from '../../../../../hooks/queryHooks/companies/use-get-company-detail';
import { CompanyDetailSelectModel } from '../../../../../services/mappers/mappers';
import { useGetOrts } from '../../../../../hooks/queryHooks/companies/use-get-orts';
import { IconApproved, IconCancel } from '../../../../../../assets/img';

export const CompanyEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Company ID not provided');
  const companyQuery = useGetCompanyAsDetail(id);
  const orts = useGetOrts();

  if (orts.status !== 'success') {
    return <RenderUnsettledUI data={orts} />;
  }

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  return <CompanyEditImpl data={companyQuery.data} orts={orts.data} companyId={id} />;
};

const CompanyEditImpl = ({
  data,
  orts,
  companyId,
}: {
  data: CompanyDetailSelectModel;
  orts: OrtListItem[];
  companyId: string;
}) => {
  const generatePath = useHqRelativePath();
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      name: data.name,
      ortId: data.ortId,
      offersTempWork: data.offersTempWork,
      offersFreelanceWork: data.offersFreelanceWork,
      workCooldownMinutes: data.workCooldownMinutes,
    },
  });
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();

  const updateCompany = useUpdateCompany(data.id);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      formMethods.handleSubmit((data) => {
        updateCompany.mutate(data, {
          onSuccess: () => {
            alertSuccess(alerts.success.updated);
            navigate(generatePath(routes.CompanyDetail, { id: companyId }));
          },
          onError: (e) => alertError(e),
        });
      })();
    },
    [data]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Page
          header={<Header titleText="Bedrijf bewerken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Bedrijf aanpassen
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(routes.CompanyDetail, { id: data.id }))}
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <Box width="100%">
            <CompanyForm state="update" orts={orts} defaultOrtId={data.ortId} />
          </Box>
        </Page>
      </form>
    </FormProvider>
  );
};
