import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerCheckedOutService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';
import { GetPartnerCheckedOutParams } from '../../../../shared/services/checked-outs-service';

const useGetPartnerCheckedOuts = (params: GetPartnerCheckedOutParams) => {
  return useInfiniteQuery(QUERY_KEYS_PARTNER.shiftClaims(params), {
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckedOutService.getCheckedOuts({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetPartnerCheckedOuts };
