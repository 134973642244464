import { useExternalParams } from 'shared/hooks/use-external-params';
import { useMemo } from 'react';
import { ProjectPlanningPageParams } from './use-project-planning.types';
import { FilledStatus } from '../../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status.types';
import { stringifyFilters } from '../../../../../../../shared/utils/stringify-filters';

const initialValues = {
  projectDetailId: {
    initialValue: undefined,
    type: 'string',
  },
  toDate: {
    initialValue: undefined,
    type: 'date',
  },
  company: {
    initialValue: undefined,
    type: 'string',
  },
  branchOffice: {
    initialValue: undefined,
    type: 'string',
  },
  department: {
    initialValue: undefined,
    type: 'string',
  },
  filledStatus: {
    initialValue: undefined,
    type: 'string',
  },
  hasApplicants: {
    initialValue: undefined,
    type: 'boolean',
  },
  jobType: {
    initialValue: undefined,
    type: 'array',
  },
} as const;

const useProjectPlanningPage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const projectPlanningPageParams: ProjectPlanningPageParams = useMemo(() => {
    return {
      toDate: params.toDate || undefined,
      company: params.company || undefined,
      branchOffice: params.branchOffice || undefined,
      department: params.department || undefined,
      filledStatus: (params.filledStatus as FilledStatus) || undefined,
      hasApplicants: params.hasApplicants ?? undefined,
      jobType: ((params.jobType || undefined) as string[]) ?? undefined,
      projectDetailId: params.projectDetailId || undefined,
    };
  }, [params]);

  const selectedFilterCount = Object.entries(projectPlanningPageParams).filter(([key, value]) => {
    return value !== null && value !== undefined && value !== '' && key !== 'projectDetailId';
  }).length;

  const strigifiedFilters = useMemo(
    () => stringifyFilters(projectPlanningPageParams),
    [projectPlanningPageParams]
  );

  return {
    params: projectPlanningPageParams,
    setParams,
    selectedFilterCount,
    strigifiedFilters: strigifiedFilters,
  };
};

export { useProjectPlanningPage };
