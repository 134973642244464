import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { userService } from 'ogp/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { PrebuiltBlock } from 'redesign';

export const useGetUserListing = () => {
  const queryFn = async () => await userService.getUserListing();

  return useQuery<Promise<PrebuiltBlock[]>, Error, PrebuiltBlock[]>(
    QUERY_KEYS_OGP.userList(),
    queryFn,
    {
      cacheTime: CACHE_TIME_LONG,
    }
  );
};
