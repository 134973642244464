import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { PartnerPortalCheckoutsParams } from 'partner/shared/services/partner-checkouts-service.types';
import { useInfiniteQuery, useQuery } from 'react-query';

const usePartnerWorkedShifts = (params: PartnerPortalCheckoutsParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.checkouts(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckoutsService.getWorkedShifts({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

const usePartnerCounterOffers = (params: PartnerPortalCheckoutsParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.counterOffers(params),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerCheckoutsService.getCounterOffers({
        ...params,
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

const usePartnerCheckoutsMetaData = () => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.checkoutsMetaData(),
    queryFn: async () => await partnerCheckoutsService.getMetaData(),
  });
};

export { usePartnerWorkedShifts, usePartnerCounterOffers, usePartnerCheckoutsMetaData };
