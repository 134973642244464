import { useQuery } from 'react-query';
import { QUERY_KEYS_OGP } from '../../../query-keys';
import { ogpSharedService } from '../../../services';
import { OgpBusinessUnitsData } from '../../../services/ogp-shared-service.types';

export const useGetOgpBusinessUnits = () => {
  return useQuery<OgpBusinessUnitsData, Error>({
    queryKey: QUERY_KEYS_OGP.businessUnits(),
    queryFn: async () => await ogpSharedService.getBusinessUnits(),
  });
};
