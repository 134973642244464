import { Divider, Grid, Typography } from '@mui/material';

export const Section = ({
  children,
  sectionLabel,
}: {
  children: React.ReactNode;
  sectionLabel: string;
}) => {
  return (
    <Grid item xs={12}>
      <Divider style={{ height: '1px', marginBottom: '14px' }} />
      <Typography variant="h3" marginBottom={2}>
        {sectionLabel}
      </Typography>
      {children}
      <Divider style={{ height: '1px' }} />
    </Grid>
  );
};
