import { generatePath } from 'react-router-dom';
import { getQueryParameters } from 'shared/hooks/query-hooks/relative-path-utils';
import { hqPrefix, RouteDefinition } from '../../Routes';

export const useHqRelativePath = () => {
  return (
    route: RouteDefinition,
    params?: { [key: string]: string | undefined },
    queryParams?: { [key: string]: string | undefined }
  ) => {
    const path = generatePath(`${hqPrefix}/${route.path}`, {
      ...params,
    });
    return path + getQueryParameters(queryParams);
  };
};
