import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AddButton, BlockLink, createBlock, Header, LwBlock, Page, Toolbox } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { IconBranchOffices } from 'assets/img';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useGetPartnersAsBlocks } from './hooks/use-get-partners';
import { partnerRoutePrefix } from '../../../../partner/settings';

const Partners = () => {
  const getPartnersQuery = useGetPartnersAsBlocks();
  const { data, status } = getPartnersQuery;
  const generatePath = useHqRelativePath();

  if (status !== 'success') {
    return <RenderUnsettledUI data={getPartnersQuery} />;
  }

  const blocks = data.map((block) => createBlock(block));

  if (!blocks) {
    return <Typography>Er zijn geen partners gevonden.</Typography>;
  }

  return (
    <Page
      header={<Header titleText="Partners" />}
      toolbox={
        <Toolbox>
          <AddButton>Voeg een partner toe</AddButton>
        </Toolbox>
      }
    >
      <Box width="100%">
        <Grid container spacing={4}>
          {blocks.map((block) => (
            <Grid item xs={6} md={4} lg={3} key={block.id} aria-label="block-element">
              <BlockLink to={generatePath(hqRoutes.PartnerDetail, { id: block.id })}>
                <LwBlock
                  icon={<IconBranchOffices width={24} height={24} />}
                  id={block.id}
                  title={block.title}
                  subtitle={block.subtitle}
                  dataBlocks={block.dataBlocks}
                  navigateCallback={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    window.open(`${partnerRoutePrefix}/${block.id}/shift-planning`);
                  }}
                  navigateLabel="Naar portal"
                />
              </BlockLink>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Page>
  );
};

export { Partners };
