import { ratingService } from 'ogp/services';
import { useMutation } from 'react-query';

type RateShiftClaimMutationData = { ratingId: string; value: boolean };
export const useRateShiftClaim = () => {
  const mutationFn = async (rateShiftClaimData: RateShiftClaimMutationData) =>
    await ratingService.rateShiftClaim(
      rateShiftClaimData.ratingId,
      rateShiftClaimData.value ? 1 : 0
    );
  return useMutation(mutationFn);
};
