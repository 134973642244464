const WrapMeWhen = ({
  children,
  when,
  wrapper,
}: {
  children: React.ReactElement;
  when: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
}) => (when ? wrapper(children) : children);

export { WrapMeWhen };
