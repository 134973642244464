import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import notfound from 'assets/img/notfound.png';

const NotFound = () => {
  const styles = getStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <h2>Pagina niet gevonden</h2>
        <img src={notfound} alt="Page Not Found" className={styles.image} />
      </div>
    </div>
  );
};

const getStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    margin: theme.spacing(2),
  },
  error: {
    postion: 'relative',
    width: '33vw',
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignSelf: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
}));

export { NotFound };
