import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticatedGuard } from 'shared/guards';
import { Router as OgpRouter } from 'ogp/router';
import { Router as HqRouter } from 'hq/router';
import { NotFound } from 'shared/components/views/404/404';
import { HqUserInfo, OgpUserInfo } from '@types';
import { GetPartnerUserInfoResponse } from 'partner/shared/services/partner-user-service.types';
import { partnerRoutePrefix } from 'partner/settings';
import { PartnerRouter } from 'partner/router';
import { routePrefix as ogpPrefix } from './ogp/settings';
import { hqPrefix, RouteDefinition } from './Routes';

const Router = () => {
  return (
    <Routes>
      <Route path={`${ogpPrefix}/signin`} element={<Navigate replace to={ogpPrefix} />} />
      <Route path={`${hqPrefix}/signin`} element={<Navigate replace to={hqPrefix} />} />
      <Route
        path={`${partnerRoutePrefix}/signin`}
        element={<Navigate replace to={partnerRoutePrefix} />}
      />
      <Route path="/" element={<Navigate replace to={ogpPrefix} />} />
      <Route path={`${ogpPrefix}/*`} element={<OgpRouter />} />
      <Route path={`${hqPrefix}/*`} element={<HqRouter />} />
      <Route path={`${partnerRoutePrefix}/*`} element={<PartnerRouter />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export function RouteRenderer(
  routes: RouteDefinition[],
  prefix: string,
  userInfo: HqUserInfo | OgpUserInfo | GetPartnerUserInfoResponse,
  fallbackLayout?: React.ComponentType
) {
  return routes.map((route) => {
    const path = `${prefix}/${route.path}`;
    const Layout = route.layout ?? fallbackLayout;

    if (Layout && route.policyGroup && route.roles) {
      return (
        <Route key={path} element={<Layout />}>
          <Route
            path={path}
            element={
              // @ts-expect-error TODO fix typing
              <AuthenticatedGuard
                fallbackPath={route.fallbackPath}
                userInfo={userInfo}
                roles={route.roles}
                policyGroup={route.policyGroup}
              >
                {route.element}
              </AuthenticatedGuard>
            }
          />
        </Route>
      );
    }

    if (route.policyGroup && route.roles) {
      return (
        <Route
          key={path}
          path={path}
          element={
            // @ts-expect-error TODO fix typing
            <AuthenticatedGuard
              fallbackPath={route.fallbackPath}
              userInfo={userInfo}
              roles={route.roles}
              policyGroup={route.policyGroup}
            >
              {route.element}
            </AuthenticatedGuard>
          }
        />
      );
    }

    if (Layout) {
      return (
        <Route key={path} element={<Layout />}>
          <Route key={path} path={path} element={<Layout>{route.element}</Layout>} />
        </Route>
      );
    }

    return <Route key={path} path={path} element={route.element} />;
  });
}

export { Router };
