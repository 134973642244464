import { Box, styled, Tooltip } from '@mui/material';
import { ScheduleSegment } from '@types';
import { SECONDS_IN_DAY, timeToSeconds } from '../../../utils/date-utils';

type ScheduleColumnProps = {
  segments: ScheduleSegment[];
};

export const ScheduleColumn = ({ segments }: ScheduleColumnProps) => {
  return (
    <ColumnContainer data-testid="column-container">
      {segments.map((segment) => {
        const { top, height } = calculateAvailabilityTimeBlockPosition(segment);
        return (
          <Tooltip
            title={`${segment.startTime} tot ${segment.endTime}`}
            aria-label="availability"
            key={segment.id}
            arrow={true}
          >
            <AvailabilityBlock
              data-testid="availability-block"
              key={segment.startTime + segment.endTime}
              top={top}
              height={height}
            ></AvailabilityBlock>
          </Tooltip>
        );
      })}
    </ColumnContainer>
  );
};

const ColumnContainer = styled(Box)({
  display: 'flex',
  borderLeft: '1px solid #D3D3D3',
  width: '100%',
  justifyContent: 'center',
  ':first-of-type': {
    borderLeft: 'none',
  },
  zIndex: 1,
});

const AvailabilityBlock = styled(Box)<{ top: string; height: string }>(({ top, height }) => ({
  top,
  height,
  borderRadius: 10,
  position: 'absolute',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '10%',
  backgroundColor: '#CCD5FF',
}));

const calculateAvailabilityTimeBlockPosition = ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  const parsedStartTimeToSeconds = timeToSeconds(startTime);
  const parsedEndTimeToSeconds = timeToSeconds(endTime);

  const top = (parsedStartTimeToSeconds / SECONDS_IN_DAY) * 100;
  const height = (parsedEndTimeToSeconds / SECONDS_IN_DAY) * 100 - top;

  return { top: `${top}%`, height: `${height}%` };
};
