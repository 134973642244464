import { IconApproved } from 'assets/img';
import { PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { useCallback } from 'react';
import { ContextMenuItem } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { shiftClaimService as hqShiftClaimService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftClaimService as ogpShiftClaimService } from 'ogp/services';

const useGetAddShiftClaimActions = ({ isHq }: { isHq: boolean }) => {
  const { alertError, alertSuccess } = useAlert();
  const { openDialog, disableDialog, enableDialog, closeDialog } = useDialog();
  const getMutation = isHq ? useHQCreateShiftClaim : useOGPCreateShiftClaim;
  const addClaimShift = getMutation({
    onSuccess: () => {
      alertSuccess(shiftAlerts.success.claimed);
      closeDialog();
    },
    onError: (e) => alertError(e),
    onSettled: () => {
      enableDialog();
    },
  });

  const handleShiftClaimAddition = useCallback(
    (flexWorkerId: string, shiftId: string, flexWorkerName: string) => {
      openDialog(
        {
          title: `Weet je zeker dat je ${flexWorkerName} aan deze shift wilt toevoegen?`,
          content: 'Let op: de annuleringsvoorwaarden van deze dienst zijn van toepassing.',
          primaryButton: (props) => <PrimaryButton {...props}>Akkoord</PrimaryButton>,
        },
        () => {
          disableDialog();
          addClaimShift.mutate({ flexWorkerId: flexWorkerId, shiftId });
        }
      );
    },
    [addClaimShift, disableDialog, openDialog]
  );

  const getRowActions = useCallback(
    (flexWorkerId: string, shiftId: string, flexWorkerName: string) => {
      return [
        <ContextMenuItem
          onClick={() => handleShiftClaimAddition(flexWorkerId, shiftId, flexWorkerName)}
          key="addClaim"
        >
          <IconApproved />
          Op shift plaatsen
        </ContextMenuItem>,
      ];
    },
    [handleShiftClaimAddition]
  );

  return getRowActions;
};

type ShiftClaimData = { shiftId: string; flexWorkerId: string };
type ShiftClaimMutationOptions = UseMutationOptions<unknown, unknown, ShiftClaimData>;

const useHQCreateShiftClaim = (options?: ShiftClaimMutationOptions) => {
  const mutationFn = async (createShiftClaimData: ShiftClaimData) =>
    await hqShiftClaimService.createShiftClaim(
      createShiftClaimData.shiftId,
      createShiftClaimData.flexWorkerId
    );

  const queryClient = useQueryClient();
  const mutationOptions: ShiftClaimMutationOptions = {
    ...options,
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shift(variables.shiftId));
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shifts());
      queryClient.invalidateQueries(QUERY_KEYS_HQ.shiftPlanningShifts());
      options && options?.onSuccess?.(data, variables, context);
    },
  };

  return useMutation(mutationFn, mutationOptions);
};

const useOGPCreateShiftClaim = (options?: ShiftClaimMutationOptions) => {
  const mutationFn = async (createShiftClaimData: ShiftClaimData) =>
    await ogpShiftClaimService.createShiftClaim(
      createShiftClaimData.shiftId,
      createShiftClaimData.flexWorkerId
    );

  const queryClient = useQueryClient();
  const mutationOptions: ShiftClaimMutationOptions = {
    ...options,
    onSettled: async (data, error, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.shifts());
      if (options?.onSettled) {
        options.onSettled(data, error, variables, context);
      }
    },
  };

  return useMutation(mutationFn, mutationOptions);
};

export { useGetAddShiftClaimActions };
