import { DateTime, Interval } from 'luxon';
import { PartnerCounterOffer } from 'partner/shared/services/partner-checkouts-service.types';
import {
  mapExpenseToDisplayValue,
  mapExpenseTypeToDescription,
} from '../../../../../../shared/utils/expenses';

export const mapProposalsToVisuals = ({ submitted, counterOffer }: PartnerCounterOffer) => {
  const submittedStartDate = DateTime.fromISO(submitted.startDate);
  const submittedEndDate = DateTime.fromISO(submitted.endDate);
  const shiftDuration = Interval.fromDateTimes(submittedStartDate, submittedEndDate)
    .toDuration(['hours', 'minutes'])
    .minus({ minutes: submitted.breakMinutes ?? 0 })
    // calling normalize twice seems weird, but Luxon does not manage to handle the negative minutes once they overlap one hour (in this case when break time is 90 minutes)
    // TODO: check if this works once Luxon gets updated to later versions
    .normalize()
    .normalize();

  const fwExpenses = submitted.expenses.map((expense) => ({
    name: mapExpenseTypeToDescription(expense.expenseType),
    value: mapExpenseToDisplayValue(expense),
  }));

  const submittedShift = {
    startDate: { value: submittedStartDate.toFormat('dd-MM-yyyy') },
    shiftStart: { value: submittedStartDate.toFormat('HH:mm') },
    shiftEnd: { value: submittedEndDate.toFormat('HH:mm') },
    breakMinutes: { value: (submitted.breakMinutes ?? 0).toString() },
    expenses: fwExpenses,
    shiftDuration: { value: shiftDuration.toHuman() },
  };

  const counterOfferStartDate = DateTime.fromISO(counterOffer.startDate);
  const counterOfferEndDate = DateTime.fromISO(counterOffer.endDate);
  const counterOfferShiftDuration = Interval.fromDateTimes(
    counterOfferStartDate,
    counterOfferEndDate
  )
    .toDuration(['hours', 'minutes'])
    .minus({ minutes: counterOffer.breakMinutes ?? 0 })
    .normalize()
    .normalize();

  const ogExpenses = counterOffer.expenses.map((expense) => ({
    name: mapExpenseTypeToDescription(expense.expenseType),
    value: mapExpenseToDisplayValue(expense),
    hasChanged:
      fwExpenses.find((e) => e.name === expense.description)?.value !==
      mapExpenseToDisplayValue(expense),
  }));

  const counterOfferShift = {
    startDate: {
      value: counterOfferStartDate.toFormat('dd-MM-yyyy'),
      hasChanged: !counterOfferStartDate.startOf('day').equals(submittedStartDate.startOf('day')),
    },
    shiftStart: {
      value: counterOfferStartDate.toFormat('HH:mm'),
      hasChanged: !counterOfferStartDate.equals(submittedStartDate),
    },
    shiftEnd: {
      value: counterOfferEndDate.toFormat('HH:mm'),
      hasChanged: !counterOfferEndDate.equals(submittedEndDate),
    },
    breakMinutes: {
      value: (counterOffer.breakMinutes ?? 0).toString(),
      hasChanged: counterOffer.breakMinutes !== submitted.breakMinutes,
    },
    expenses: ogExpenses,
    shiftDuration: {
      value: counterOfferShiftDuration.toHuman(),
      hasChanged: !counterOfferShiftDuration.equals(shiftDuration),
    },
  };

  return { submittedShift, counterOfferShift };
};
