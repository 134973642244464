import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useHqGetShiftDetailsTabs } from 'shared/components/shift-detail-modal/hooks/hq/use-hq-get-shift-detail-tabs';
import { useHqGetShift } from 'shared/components/shift-forms/hooks/hq/use-hq-get-shift';
import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { Box, Divider } from '@mui/material';
import { ModalPanelBody } from 'redesign';
import { ShiftDetailHead } from 'shared/components/shift-detail-modal/components/shift-detail-head/shift-detail-head';
import { useGetShiftDetailHeadActions } from '../hooks/use-get-shift-detail-head-actions';

export const ShiftPlanningModalProvider = ({
  closeShiftDetail,
  onDelete,
  shiftDetailId,
}: {
  closeShiftDetail: () => void;
  onDelete: () => void;
  shiftDetailId: string | null;
}) => {
  const shiftQuery = useHqGetShift(shiftDetailId);

  if (!shiftDetailId) {
    return <></>;
  }

  if (shiftQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={shiftQuery}
      />
    );
  }

  return (
    <ShiftPlanningModal
      closeShiftDetail={closeShiftDetail}
      onDelete={onDelete}
      data={shiftQuery.data}
    />
  );
};

const ShiftPlanningModal = ({
  data,
  onDelete,
  closeShiftDetail,
}: {
  data: ShiftDetailResponse;
  closeShiftDetail: () => void;
  onDelete: () => void;
}) => {
  const shiftDetailTabs = useHqGetShiftDetailsTabs(data);
  const { children, isArchiving } = useGetShiftDetailHeadActions({
    data: data,
    closeModal: closeShiftDetail,
    onDelete,
  });

  return (
    <Box p={8} height="100%" display="flex" flexDirection="column">
      <ShiftDetailHead
        data={data}
        close={closeShiftDetail}
        actions={children}
        isArchiving={isArchiving}
        showTempWorkRate={true}
      />
      <Box mt={8} mb={8}>
        <Divider />
      </Box>
      <ModalPanelBody tabs={shiftDetailTabs} />
    </Box>
  );
};
