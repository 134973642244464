import { InputSelectOption } from '@types';
import { Controller, FieldValues } from 'react-hook-form';
import arrowDown from 'assets/img/icons/redesign/arrow-down.svg';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from '@mui/material';
import { StyledErrorText, StyledHelperTextWrapper, StyledIconWarning, StyledLabel } from 'redesign';
import { inputBaseClasses } from '@mui/material/InputBase';
import { FormSelectProps } from './form-select.types';

// TODO: Should we merge this with the other select or keep this one for forms only due to form-control support?
const FormSelect = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  options,
  control,
  rules,
  disabled,
  defaultLabel,
  onChange,
  helperText,
  renderOption,
  'data-testid': dataTestId,
  ...props
}: FormSelectProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...props} variant="filled" fullWidth disabled={disabled} error={!!error}>
          <InputLabel required={!!rules?.required} id={`${name}-label`}>
            {label}
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            {...field}
            title={label}
            labelId={`${name}-label`}
            id={name}
            value={field.value ?? ''}
            onChange={(e) => {
              field.onChange(e);
              if (onChange != null) {
                onChange(e);
              }
            }}
            data-testid={dataTestId}
            inputProps={{ role: 'textbox' }}
          >
            {defaultLabel && <MenuItem value="">{defaultLabel}</MenuItem>}
            {renderOption
              ? // @ts-expect-error TODO DEPARTMENTS
                options.map(renderOption)
              : options.map((option: InputSelectOption) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </Select>
          <FormHelperText>{error ? error.message : helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

const LwFormSelect = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  options,
  control,
  rules,
  disabled,
  defaultLabel,
  onChange,
  helperText,
  renderOption,
  icon,
  'data-testid': dataTestId,
  ...props
}: FormSelectProps<ControlFields>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const message = error ? error.message : helperText;
        return (
          <FormControl {...props} variant="filled" fullWidth disabled={disabled} error={!!error}>
            <StyledLabel
              icon={error ? <StyledIconWarning /> : icon}
              required={!!rules?.required}
              id={`${name}-label`}
            >
              {label}
            </StyledLabel>
            <StyledSelect
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  sx: (theme) => ({
                    border: error
                      ? `2px solid ${theme.palette.lwDanger[100]}`
                      : `2px solid ${theme.palette.lwSecondary[40]}`,
                    marginTop: '6px',
                    '& .MuiMenuItem-root': {
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      fontSize: '14px',
                      marginRight: theme.spacing(2),
                      marginLeft: theme.spacing(2),
                      borderRadius: theme.spacing(2),
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(2),
                      '&:hover': {
                        color: theme.palette.lwSecondary[100],
                        backgroundColor: theme.palette.lwPrimary[5],
                      },
                      '&.Mui-selected': {
                        color: theme.palette.lwPrimary[100],
                        backgroundColor: theme.palette.lwPrimary[10],
                      },
                    },
                    '::-webkit-scrollbar': {
                      width: '20px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: theme.palette.lwSecondary[40],
                      border: '8px solid white',
                      borderRadius: theme.spacing(4),
                    },

                    '::-webkit-scrollbar-thumb': {
                      background: theme.palette.lwSecondary[100],
                      backgroundClip: 'content-box',
                      border: '8px solid transparent',
                      borderRadius: theme.spacing(4),
                    },
                  }),
                },
              }}
              {...field}
              title={label}
              id={name}
              value={field.value ?? ''}
              labelId={`${name}-label`}
              error={!!error}
              onChange={(e) => {
                field.onChange(e);
                if (onChange != null) {
                  onChange(e);
                }
              }}
              data-testid={dataTestId}
              inputProps={{ role: 'textbox' }}
            >
              {defaultLabel && <MenuItem value="">{defaultLabel}</MenuItem>}
              {renderOption
                ? // @ts-expect-error TODO DEPARTMENTS
                  options.map(renderOption)
                : options.map((option: InputSelectOption) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
            </StyledSelect>

            <StyledHelperTextWrapper className={errorTextWrapperClassName}>
              {message && (
                <StyledErrorText className={error ? 'error-state' : ''}>{message}</StyledErrorText>
              )}
            </StyledHelperTextWrapper>
          </FormControl>
        );
      }}
    />
  );
};

const errorTextWrapperClassName = 'error-text-wrapper';

export const StyledSelect = styled(Select)<SelectProps>(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),

    [`&.${inputBaseClasses.focused}`]: {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwSecondary[20]}`,
    },
    '&:hover': {
      borderColor: theme.palette.lwPrimary[100],
    },
    [`&.${inputBaseClasses.error}`]: {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
    '& .MuiSelect-icon': {
      display: 'block',
      width: '24px',
      height: '24px',
      color: 'transparent',
      backgroundImage: `url("${arrowDown}")`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    '& .MuiSelect-select': {
      maxHeight: '1.4375em',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  [`&.${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export { FormSelect, LwFormSelect, errorTextWrapperClassName };
