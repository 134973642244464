import { GetBillingEntitiesResponse } from 'shared/services/billing-entity/billing-entity-service.types';
import { HqApiService } from './hqApiService';

class BillingEntityService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getBillingEntities(companyId: string) {
    const response = await this.hqApiService.get<GetBillingEntitiesResponse[]>({
      params: { companyId },
      path: 'billing-entities',
    });

    return response;
  }
}

export { BillingEntityService };
