import { DateTime } from 'luxon';
import { formatFloatNumber } from 'shared/utils/formatting';

export type TextValueFormat =
  | 'string'
  | 'date'
  | 'currency'
  | 'number'
  | 'percentage'
  | 'interval'
  | undefined;

export const formatTextValue = (
  value: string | number | undefined,
  format?: TextValueFormat
): string => {
  if (value === undefined) {
    return formatUndefinedValue(format);
  }

  switch (format) {
    case undefined:
    case 'string':
    case 'interval':
      return value + '';
    case 'number':
      return formatFloatNumber(+value);
    case 'date':
      if (typeof value === 'string') {
        return DateTime.fromISO(value).toFormat('dd-LL-yyyy'); // Able to format based on client location if we want
      } else {
        return value + '';
      }
    case 'currency':
      if (typeof value === 'number') {
        return `€ ${value.toLocaleString('nl-NL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`; // Able to format based on client location if we want
      } else {
        return `€ ${value}`;
      }
    case 'percentage':
      return `${value} %`;
  }
};

const formatUndefinedValue = (format: TextValueFormat | undefined) => {
  switch (format) {
    case undefined:
    case 'string':
    case 'date':
    case 'interval':
      return '';
    case 'currency':
      return '€ 0';
    case 'number':
      return '0';
    case 'percentage':
      return '–';
  }
};
