import {
  isFreelanceShift,
  isTempworkShift,
  ShiftDetailResponse,
} from 'shared/services/shift-service-types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

const getDefaultValuesForShiftForm = (
  shift: ShiftDetailResponse
):
  | ((
      | FreelanceShiftFormData
      | TempWorkShiftFormData
      | (FreelanceShiftFormData & TempWorkShiftFormData)
    ) & { companyId: string })
  | undefined => {
  if (isFreelanceShift(shift) && isTempworkShift(shift)) {
    return {
      ...shift,
      hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
      grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
      mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
      jobId: shift.job.id,
      companyId: shift.job.companyId,
      departmentId: shift.job.department.id,
      isFreelance: true,
      isTempwork: true,
    };
  }
  if (isFreelanceShift(shift)) {
    return {
      ...shift,
      hourlyRate: shift.freelanceProperties.hourlyRateCents / 100,
      mutableUntilHours: shift.freelanceProperties.mutableUntilMinutes / 60,
      jobId: shift.job.id,
      companyId: shift.job.companyId,
      departmentId: shift.job.department.id,
      isFreelance: true,
    };
  }
  if (isTempworkShift(shift)) {
    return {
      ...shift,
      grossHourlyRate: shift.tempWorkProperties.grossHourlyRateCents / 100,
      jobId: shift.job.id,
      companyId: shift.job.companyId,
      departmentId: shift.job.department.id,
      isTempwork: true,
    };
  }
};

export { getDefaultValuesForShiftForm };
