import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftPlanningService } from 'hq/services';
import { useQuery } from 'react-query';

export const useGetShiftsPartners = (shiftIds: string[]) => {
  const queryKey = QUERY_KEYS_HQ.plannerShiftsPartners(shiftIds);
  const queryFn = async () => await shiftPlanningService.getShiftsPartners(shiftIds);

  return useQuery(queryKey, queryFn);
};
