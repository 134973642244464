import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const HighlightBox = ({ children }: React.PropsWithChildren<unknown>) => {
  const classes = getClasses();

  return (
    <Box display="inline-flex" flexWrap="wrap" flexDirection="column" className={classes.box}>
      {React.Children.map(children, (kid) => (
        <Typography variant="body2" className={classes.changedField}>
          {kid}
        </Typography>
      ))}
    </Box>
  );
};

const getClasses = makeStyles(() => ({
  box: {
    backgroundColor: 'rgba(191, 64, 64, 0.2)',
    borderRadius: '16px',
    padding: '6px 12px',
  },
  changedField: {
    color: 'rgba(191, 64, 64, 1)',
  },
}));

export { HighlightBox };
