import { QUERY_KEYS_OGP } from '../../ogp/query-keys';
import { QUERY_KEYS_HQ } from '../../hq/query-keys';
import { SiteDetectionResult } from '../hooks';

const siteQueryKeyForCompanies = (siteDetectionResult: SiteDetectionResult) => {
  switch (siteDetectionResult.site) {
    case 'ogp':
      return QUERY_KEYS_OGP.companies();
    case 'hq':
      return QUERY_KEYS_HQ.companies();
    default:
      throw new Error(
        `site: ${siteDetectionResult.site} is not supported for projectDetail query key`
      );
  }
};

export const SHARED_QUERY_KEYS_COMPANY = {
  ownCompany: (siteDetectionResult: SiteDetectionResult) => [
    siteQueryKeyForCompanies(siteDetectionResult),
    'own-company',
  ],
} as const;
