import { useFormContext } from 'react-hook-form';
import { LwFormMultiSelect } from 'redesign';
import { CompanyListItem, JobListItem } from '@types';
import { useMemo } from 'react';
import { InboxFormValues } from './inbox-filter-form.types';

const BusinessUnitFiltering = ({
  companies,
  jobs,
}: {
  companies: CompanyListItem[];
  jobs: JobListItem[];
}) => {
  const { setValue, watch, control } = useFormContext<InboxFormValues>();
  const selectedCompanies = watch('companies');

  const companyOptions = useMemo(() => {
    return companies.map((c) => ({ id: c.id, name: c.name })) ?? [];
  }, [companies]);

  const jobOptions = useMemo(() => {
    if (!selectedCompanies) {
      return [];
    }

    const companyById: Record<string, { id: string; name: string }> = {};
    selectedCompanies.forEach((company) => {
      companyById[company.id] = company;
    });

    const jobOptionsRelatedToSelectedCompanies = jobs.filter((job) => !!companyById[job.companyId]);
    if (!jobOptionsRelatedToSelectedCompanies) {
      return [];
    }

    const uniqueJobs: Record<string, { id: string; name: string }> = {};
    jobOptionsRelatedToSelectedCompanies.forEach((job) => {
      uniqueJobs[job.id] = job;
    });

    return Object.values(uniqueJobs);
  }, [selectedCompanies, jobs]);

  return (
    <>
      <LwFormMultiSelect
        name="companies"
        label="Bedrijven"
        control={control}
        onChange={(_e, value) => {
          setValue('companies', value);
        }}
        options={companyOptions}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value.id;
        }}
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
      />
      <LwFormMultiSelect
        name="jobs"
        label="Functies"
        control={control}
        onChange={(_e, value) => {
          setValue('jobs', value);
        }}
        options={jobOptions}
        getOptionKey={(option) => option.id}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value.id;
        }}
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        disabled={!selectedCompanies || selectedCompanies?.length < 1}
      />
    </>
  );
};

export { BusinessUnitFiltering };
