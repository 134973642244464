import { FormProvider, useForm } from 'react-hook-form';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
  FlexWorkersFilters,
  PaginatedCollection,
  ParamsWithPagination,
} from '@types';
import { FlexWorkersFilterForm } from '../flex-workers-filter-form';
import { FlexWorkerParams, SetFlexWorkerParams } from '../flex-workers.types';

export const FlexWorkerToolbox = ({
  params,
  setParams,
  data,
  status,
}: {
  params: FlexWorkerParams;
  setParams: SetFlexWorkerParams;
  data: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts;
  status: string;
}) => {
  const methods = useForm<ParamsWithPagination<FlexWorkersFilters>>({
    mode: 'onChange',
    defaultValues: {
      filter: params.filter,
      level: params.level,
      employmentType: params.employmentType,
      disabled: params.disabled,
    },
    shouldUnregister: false,
  });

  return (
    <FormProvider {...methods}>
      <FlexWorkersFilterForm
        data={data}
        filter={params.filter}
        setParams={setParams}
        status={status}
      />
    </FormProvider>
  );
};
