import { PartnerApiService } from './partner-api-service';
import {
  GetPartnerInboxMessageResponse,
  GetPartnerInboxMessagesResponse,
  PartnerInboxParams,
} from './partner-inbox-service.types';

export class PartnerInboxService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public async getPartnerInboxMessages({ pageParam }: PartnerInboxParams) {
    const response = await this.partnerApiService.get<GetPartnerInboxMessagesResponse>({
      path: `inbox`,
      params: {
        page: pageParam,
      },
    });
    return response;
  }

  public async getPartnerInboxMessage(messageId: string) {
    const response = await this.partnerApiService.get<GetPartnerInboxMessageResponse>({
      path: `inbox/messages?messageId=${messageId}`,
    });
    return response;
  }

  public async markInboxMessageAsRead(messageId: string) {
    await this.partnerApiService.update<void>({
      path: `inbox/messages/read-message/${messageId}`,
    });
  }

  public async markInboxMessageAsUnread(messageId: string) {
    await this.partnerApiService.update<void>({
      path: `inbox/messages/unread-message/${messageId}`,
    });
  }

  public async resolveInboxMessageTask(messageId: string) {
    await this.partnerApiService.update<void>({
      path: `inbox/messages/resolve-task/${messageId}`,
    });
  }

  public async unresolveInboxMessageTask(messageId: string) {
    await this.partnerApiService.update<void>({
      path: `inbox/messages/unresolve-task/${messageId}`,
    });
  }
}
