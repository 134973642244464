import { routePrefix } from 'ogp/settings';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { getQueryParameters } from 'shared/hooks/query-hooks/relative-path-utils';
import { useGetOGPUserInfo } from './queryHooks/users/useGetOGPUserInfo';
import { RouteDefinition } from '../../Routes';

export const companyRelativePrefix = `${routePrefix}/:companyHash/`;

export const useCompanyRelativePath = () => {
  const userQuery = useGetOGPUserInfo();
  return useCallback(
    (
      route: RouteDefinition,
      params?: { [key: string]: string | undefined },
      queryParams?: { [key: string]: string | undefined }
    ) => {
      // TODO: decide what to do if there is no company
      const path = generatePath(`${companyRelativePrefix}${route.path}`, {
        ...params,
        companyHash: userQuery.data?.userInfo.company.slug ?? null,
      });
      return path + getQueryParameters(queryParams);
    },
    [userQuery.data]
  );
};
