import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthenticatedElement } from 'ogp/components/common/authenticated-element';
import { useGetOGPUserInfo } from 'ogp/hooks';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { useSidebarProvider } from './sidebar-provider';

const CompanyName = () => {
  const { isOpen } = useSidebarProvider();
  const { data: user } = useGetOGPUserInfo();
  const classes = getClasses();

  return isOpen ? (
    <AuthenticatedElement roles={[CompanyRole.HQADMIN]} policyGroup={PolicyGroups.COMPANY}>
      <div className={classes.companyInfo}>
        <Typography variant="body1" className={classes.companyName}>
          {user?.userInfo.company.name}
        </Typography>
      </div>
    </AuthenticatedElement>
  ) : null;
};

const getClasses = makeStyles((theme: Theme) => {
  return {
    companyInfo: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    companyName: {
      overflow: 'hidden',
    },
  };
});

export { CompanyName };
