export function pluralize(
  value: number | undefined,
  single: string,
  multiple: string,
  nil: string = ''
): string {
  switch (value) {
    case 0:
    case undefined:
      return nil;
    case 1:
      return single;
    default:
      return multiple;
  }
}

export function pluralizeWithValue(...args: Parameters<typeof pluralize>): string {
  const pluralized = pluralize(...args);

  if (args[0]) {
    return `${args[0]} ${pluralized}`;
  } else {
    return pluralized;
  }
}
