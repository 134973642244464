import { useCallback } from 'react';
import { useExternalParams } from 'shared/hooks';
import { LWModal } from 'redesign';
import { ShiftPlanningTable } from './table/shift-planning-table';
import { ShiftPlanningModal } from './partner-shift-planning-modal/partner-shift-planning-modal';

const initialValues = {
  shiftId: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

const PartnerShiftPlanning = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const openShiftDetail = useCallback(
    (shiftId: string) => {
      setParams({ shiftId });
    },
    [setParams]
  );

  const closeShiftDetail = useCallback(() => {
    setParams({ shiftId: undefined });
  }, [setParams]);

  return (
    <>
      <ShiftPlanningTable openShiftDetail={openShiftDetail} />
      <LWModal isOpen={params.shiftId != null} onClose={closeShiftDetail} testId="shift-detail">
        {params.shiftId ? (
          <ShiftPlanningModal shiftId={params.shiftId} closeShiftDetail={closeShiftDetail} />
        ) : null}
      </LWModal>
    </>
  );
};

export { PartnerShiftPlanning };
