import { billingEntityAlerts } from 'shared/components/alerts';
import { BillingEntityCreatePage } from './billing-entity-create-page';

export const BillingEntityCreate = () => {
  return (
    <BillingEntityCreatePage
      successMessage={billingEntityAlerts.success.created}
      pageTitle="Nieuwe facturatiegegevens aanmaken"
    />
  );
};
