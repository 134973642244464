import { GetPendingBankAccountsResponse } from 'hq/services/bank-account-service.types';
import { BankAccountTableData } from '../bank-account-table.types';

export const getBankAccountTableData = (
  data: GetPendingBankAccountsResponse
): BankAccountTableData[] => {
  return data.map((bankAccount) => ({
    workerName: bankAccount.workerName,
    iban: bankAccount.iban,
    previousIban: bankAccount.previousIban,
    employmentId: bankAccount.employmentId ?? '',
    employmentType: bankAccount.employmentType,
    actions: {
      id: bankAccount.id,
    },
  }));
};
