import { styled } from '@mui/material';
import { IconLogout } from 'assets/img';
import { usePartnerSidebarProvider } from './sidebar-provider';

const LogoutIcon = () => {
  return <Styled.LogoutIcon />;
};

const LogoutLabel = () => {
  const { isOpen } = usePartnerSidebarProvider();
  return isOpen ? <Styled.LogoutLabel>Log out</Styled.LogoutLabel> : null;
};

const Styled = {
  LogoutLabel: styled('span')(({ theme }) => ({
    color: `${theme.palette.lwDanger[100]} !important`,
    fontWeight: 500,
    fontVariationSettings: '"wght" 500',
  })),
  LogoutIcon: styled(IconLogout)(({ theme }) => ({
    '& path': {
      stroke: `${theme.palette.lwDanger[100]} !important`,
    },
  })),
};

export { LogoutIcon, LogoutLabel };
