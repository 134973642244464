import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { formatFullName } from 'shared/utils/formatting';
import { CancelledColumnDefinition } from './cancelled-table.types';
import { getCancelationReason, isApplicant } from '../../helpers';
import { ShiftApplicationCancelled, ShiftClaimCancelled } from '../cancelled-table.types';

export const getCancelledTableData = (
  candidates: (ShiftApplicationCancelled | ShiftClaimCancelled)[]
): CancelledColumnDefinition[] => {
  return candidates.map((candidate) => {
    return {
      flexWorkerImage: {
        id: candidate.workerId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        imageUrl: candidate.imageUrl,
        isFlexWorker: isShiftClaim(candidate) ? candidate.isFlexWorker : true,
        partnerName: isShiftClaim(candidate) ? candidate.partnerName : undefined,
        relation: {
          id: getCandidateId(candidate),
          relation: isApplicant(candidate)
            ? RelationType.SHIFTAPPLICATION
            : RelationType.SHIFTCLAIM,
        },
        hasCandidateLevel: candidate.hasCandidateLevel ?? false,
        employmentType: candidate.employmentType,
      },
      cancellationReason: getCancelationReason(candidate),
      flexPoolIndicator: candidate.flexPoolIndicator,
      flexWorkerFullName: formatFullName(candidate),
      hourlyRate: candidate.hourlyRateCents / 100,
      actions: candidate,
    };
  });
};

const getCandidateId = (input: ShiftApplicationCancelled | ShiftClaimCancelled): string => {
  if (isApplicant(input)) {
    return input.applicationId;
  }
  return input.claimId;
};

const isShiftClaim = (
  candidate: ShiftApplicationCancelled | ShiftClaimCancelled
): candidate is ShiftClaimCancelled => {
  return (candidate as ShiftClaimCancelled).isFlexWorker !== undefined;
};
