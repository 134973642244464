import { RenderUnsettledUI } from 'shared/components';
import { Box, styled, Typography } from '@mui/material';
import { IconApproved, IconClock, IconError } from 'assets/img';
import { AccreditationChip } from './accreditation-chip';
import { useGetFlexworkerAccreditations } from './use-get-flexworker-accreditations';
import { TagBox, TitleGrid } from '../shared/utils';

type FlexWorkerProfileProps = {
  flexWorkerId: string;
};

const ProfileAccreditations = ({ flexWorkerId }: FlexWorkerProfileProps) => {
  const accreditationsQuery = useGetFlexworkerAccreditations(flexWorkerId);

  if (accreditationsQuery.status !== 'success') {
    return <RenderUnsettledUI data={accreditationsQuery} />;
  }

  const { data } = accreditationsQuery;

  return (
    <Box marginTop={4}>
      <TitleGrid item xs={12}>
        <Typography variant="h3">Certificaten</Typography>
        {data.active.length === 0 && data.pending.length === 0 && data.expired.length === 0 ? (
          <Typography marginTop={2}>Geen geüploade certificaten door flexwerker</Typography>
        ) : (
          <>
            <Box marginTop={2}>
              <StyledText role="sectoin-header">Approved</StyledText>
              <TagBox>
                {data.active.length > 0
                  ? data.active.map((activeAccreditation, index) => (
                      <AccreditationChip
                        icon={<IconApproved />}
                        type="active"
                        accreditation={activeAccreditation}
                        key={index}
                      />
                    ))
                  : 'Geen geverifieerde certificaten'}
              </TagBox>
            </Box>
            <Box marginTop={2}>
              <StyledText role="sectoin-header">Pending</StyledText>
              <TagBoxGreyed marginTop={2}>
                {data.pending.length > 0
                  ? data.pending.map((pendingAccreditation, index) => (
                      <AccreditationChip
                        icon={<IconClock />}
                        type="pending"
                        accreditation={pendingAccreditation}
                        key={index}
                      />
                    ))
                  : 'Geen certificaten in behandeling'}
              </TagBoxGreyed>
            </Box>
            <Box marginTop={2}>
              <StyledText role="sectoin-header">Expired</StyledText>
              <TagBoxGreyed marginTop={2}>
                {data.expired.length > 0
                  ? data.expired.map((expiredAccreditation, index) => (
                      <AccreditationChip
                        icon={<IconError />}
                        type="expired"
                        accreditation={expiredAccreditation}
                        key={index}
                      />
                    ))
                  : 'Geen verlopen certificaten'}
              </TagBoxGreyed>
            </Box>
          </>
        )}
      </TitleGrid>
    </Box>
  );
};

const StyledText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 14,
  textTransform: 'uppercase',
  marginLeft: '4px',
}));

const TagBoxGreyed = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  opacity: 0.4,
}));

export { ProfileAccreditations };
