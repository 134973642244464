import { AutocompleteOption } from 'redesign';
import { ShiftPlanningFiltersCompany } from '../../../../../hq/services/shift-planning-service.types';

export const getBusinessUnitWithCompanyOptions = (
  companies: ShiftPlanningFiltersCompany[],
  selectedCompany?: AutocompleteOption,
  selectedBranchOffice?: AutocompleteOption
) => {
  const options: Record<'companies' | 'branchOffices' | 'departments', AutocompleteOption[]> = {
    companies: [],
    branchOffices: [],
    departments: [],
  };

  if (!companies) {
    return options;
  }

  options.companies = companies.map((company) => {
    return { value: company.companyId, label: company.companyName };
  });

  if (options.companies.length === 0) {
    return options;
  }

  const companyData = companies.find((company) => company.companyId === selectedCompany?.value);

  if (!companyData) {
    return options;
  }

  options.branchOffices = companyData.branchOffices.map((branchOffice) => {
    return {
      label: branchOffice.branchOfficeName,
      value: branchOffice.branchOfficeId,
    };
  });

  if (options.branchOffices.length === 0) {
    return options;
  }

  const branchOfficeData = companyData.branchOffices.find(
    (branchOffice) => branchOffice.branchOfficeId === selectedBranchOffice?.value
  );

  if (!branchOfficeData) {
    return options;
  }

  options.departments = branchOfficeData.departments.map((department) => {
    return {
      label: department.departmentName,
      value: department.departmentId,
    };
  });

  return options;
};
