import { Box, styled, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import IconCross from 'assets/img/icons/cross.svg?react';
import { hasClaimedState } from 'shared/utils/shiftClaimState';
import clsx from 'clsx';
import { ContextMenuButton, LwIconButton } from 'redesign';
import {
  IconCalendar,
  IconCertificate,
  IconClock,
  IconCloseCircle,
  IconCoin,
  IconDollar,
  IconFlexPools,
  IconPause,
  IconProfileUsers,
} from 'assets/img';
import { DateTime, Duration } from 'luxon';
import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { formatCurrencyCents, formatDateFromString } from 'shared/utils/formatting';
import { ReactNode } from 'react';
import {
  isFreelanceShift,
  isTempworkShift,
  ShiftDetailResponse,
} from 'shared/services/shift-service-types';
import { daysHoursMinutesUntil } from './utils/days-hours-minutes-until';
import { ShiftInfoButton } from './shift-info-button/shift-info-button';
import { ShiftInfoTranslator } from './shift-info-button/shift-info-translator';
import { EmploymentType } from '../../../../../types/common/employment-type.types';

type ShiftDetailHeadProps = {
  data: ShiftDetailResponse;
  close: () => void;
  actions: ReactNode;
  isArchiving: boolean;
  showTempWorkRate: boolean;
};

export const ShiftDetailHead = ({
  data,
  close,
  actions,
  isArchiving,
  showTempWorkRate,
}: ShiftDetailHeadProps) => {
  const classes = getClasses();
  const shiftProperties = getShiftProperties(data, showTempWorkRate);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box className={classes.companyInfo}>
          <Typography noWrap color="secondary" variant="body1" style={{ fontWeight: 'bold' }}>
            {shiftProperties.companyAndLocation}
          </Typography>
          <Typography noWrap variant="h5" className={classes.jobName}>
            <FlexPoolOnlyIndicator flexPoolOnly={data.job.flexPoolOnly}>
              {shiftProperties.jobName}
            </FlexPoolOnlyIndicator>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" ml="auto" alignItems="middle" style={{ gap: 8 }}>
          <ShiftInfoButton id="shift-history-detail" history={shiftProperties.history} />
          <ContextMenuButton loading={isArchiving} size="medium" color="secondary">
            {actions}
          </ContextMenuButton>
          <LwIconButton iconColorMode="stroke" color="secondary" onClick={close}>
            <IconCross width={24} height={24} />
          </LwIconButton>
        </Box>
      </Box>
      <Box className={classes.properties} display="flex" flexDirection="row" marginTop={5}>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconProfileUsers width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.seats}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconCalendar width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.startDate}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconPause width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.cooldownPeriod}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconClock width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.duration}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconPause width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.break}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconFlexPools width={24} height={24} className={classes.icon} />
            {shiftProperties.flexPoolNames}
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          {shiftProperties.hourlyRate}
          <Box className={classes.centerText}>
            <IconCoin width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.totalRate}
            </Typography>
          </Box>
          <Box className={classes.centerText}>
            <IconCertificate width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.certificates}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.companyInfo, classes.infoLabel)}>
          <Box className={classes.centerText}>
            <IconCloseCircle width={24} height={24} className={classes.icon} />
            <Typography noWrap variant="subtitle2">
              {shiftProperties.cancelationPeriod}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const displayHourlyRate = (data: ShiftDetailResponse, showTempWorkRate: boolean) => {
  const showFreelance = isFreelanceShift(data);
  const showTempWork = showTempWorkRate && isTempworkShift(data);

  if (!showFreelance && !showTempWork) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="row" marginBottom={3}>
      <StyledIconDollar width={24} height={24} />
      <Box display="flex" flexDirection="column">
        {showFreelance ? (
          <Tooltip title="Uurtarief">
            <Typography
              variant="subtitle2"
              data-testid="hourly-rate"
            >{`${formatCurrencyCents(data.freelanceProperties.hourlyRateCents)} / uur`}</Typography>
          </Tooltip>
        ) : null}
        {showTempWork ? (
          <Tooltip title="Bruto uurloon">
            <Typography
              variant="subtitle2"
              data-testid="gross-hourly-rate"
            >{`${formatCurrencyCents(data.tempWorkProperties.grossHourlyRateCents)} / uur`}</Typography>
          </Tooltip>
        ) : null}
      </Box>
    </Box>
  );
};

const displayTotalRate = (
  data: ShiftDetailResponse,
  shiftTimeLengthInMs: number,
  shiftBreakLengthInMs: number
) => {
  let totalRate = '- ';

  if (isFreelanceShift(data)) {
    totalRate = `${formatCurrencyCents(
      ((shiftTimeLengthInMs - shiftBreakLengthInMs) / 1000 / 60 / 60) *
        data.freelanceProperties.hourlyRateCents
    )} totaal`;
  } else if (isTempworkShift(data)) {
    totalRate = `${formatCurrencyCents(
      ((shiftTimeLengthInMs - shiftBreakLengthInMs) / 1000 / 60 / 60) *
        data.tempWorkProperties.grossHourlyRateCents
    )} totaal`;
  }

  return totalRate;
};

const displayFlexPools = (flexPoolNames: string[]) => {
  return (
    <Tooltip title={flexPoolNames.length > 0 ? flexPoolNames.join(', ') : null}>
      <Typography noWrap variant="subtitle2">
        {`${flexPoolNames.length} flexpool(s)`}
      </Typography>
    </Tooltip>
  );
};

const displayCertificates = (certificates: string[]) => {
  const label = certificates.length === 1 ? 'certificaat' : 'certificaten';
  return (
    <Tooltip title={certificates.length > 0 ? certificates.join(', ') : null}>
      <Typography noWrap variant="subtitle2">
        {certificates.length + ' ' + label}
      </Typography>
    </Tooltip>
  );
};

const getShiftProperties = (data: ShiftDetailResponse, showTempWorkRate: boolean) => {
  const acceptedClaimantsCount = data.claimants.filter(hasClaimedState).length;
  const shiftTimeLengthInMs = DateTime.fromISO(data.isoEndDateTime).diff(
    DateTime.fromISO(data.isoStartDateTime)
  ).milliseconds;
  const shiftBreakLengthInMs = data.breakMinutes * 60 * 1000;
  const duration = Duration.fromMillis(shiftTimeLengthInMs).normalize().toFormat('hh:mm');

  const employmentType: EmploymentType = data.isFreelance ? 'FREELANCER' : 'TEMPWORKER';

  const translator = new ShiftInfoTranslator(employmentType);

  const cooldownDuration = Duration.fromObject({ minutes: data.workCooldownMinutes })
    .normalize()
    .toFormat('hh:mm');

  return {
    companyAndLocation: `${data.job.companyName} / ${data.job.location}`,
    jobName: data.job.name,
    history: translator.translateEvents(data.history),
    seats: `${acceptedClaimantsCount} / ${data.maxClaimants} flexwerkers`,
    startDate: formatDateFromString(data.startDate),
    duration: `${data.startTime} - ${data.endTime} (${duration} uren)`,
    break: `${data.breakMinutes === 0 ? 'Geen' : `${data.breakMinutes} min. pauze`}`,
    hourlyRate: displayHourlyRate(data, showTempWorkRate),
    totalRate: displayTotalRate(data, shiftTimeLengthInMs, shiftBreakLengthInMs),
    cancelationPeriod: `${daysHoursMinutesUntil(data.mutableUntilDate, 'Verstreken')}`,
    cooldownPeriod: `${cooldownDuration} rustperiode na shift`,
    flexPoolNames: displayFlexPools(data.flexPoolNames),
    certificates: displayCertificates(data.certificates),
  };
};

const StyledIconDollar = styled(IconDollar)(({ theme }) => ({
  marginRight: theme.spacing(3),
  flexShrink: 0,
}));

const getClasses = makeStyles(({ spacing }: Theme) => ({
  properties: {
    gap: spacing(3),
    '& svg': {
      opacity: 0.6,
    },
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  jobName: {
    marginTop: spacing(2),
  },
  infoLabel: {
    flex: 1,
    minWidth: 0,
  },
  centerText: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(3),
  },
  icon: {
    marginRight: spacing(3),
    flexShrink: 0,
  },
}));
