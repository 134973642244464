import { CircularProgress } from '@mui/material';
import { IconCancel, IconCheckmark } from 'assets/img';
import { useCompanyRelativePath } from 'ogp/hooks';
import { getQueryParameters } from 'shared/hooks/query-hooks/relative-path-utils';
import type { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useFormButtons = (status: UseQueryResult['status']) => {
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const baseProps = {
    disabled: status === 'loading',
    variant: 'contained',
  } as const;

  const submitButtonProps = {
    ...baseProps,
    startIcon: status === 'loading' ? <CircularProgress size={24} /> : <IconCheckmark />,
    type: 'submit' as const,
    color: 'primary' as const,
  };

  const cancelButtonProps = {
    ...baseProps,
    startIcon: <IconCancel />,
    color: 'secondary' as const,
  };

  const returnTo =
    (
      route: Parameters<typeof generatePath>[0],
      params?: Parameters<typeof generatePath>[1],
      queryParams?: Parameters<typeof generatePath>[2]
    ) =>
    () => {
      const pathname = generatePath(route, params);
      const queryString = getQueryParameters(queryParams);
      const search = params?.['search'] ?? '';
      navigate(
        {
          pathname,
          search: search + queryString,
        },
        { replace: true }
      );
    };

  return { submitButtonProps, cancelButtonProps, returnTo };
};

export { useFormButtons };
