import {
  PlatformSettingsResponse,
  UpdatePlatformSettingsPayload,
} from 'hq/services/admin-settings-service.types';
import { PlatformSettingsFormData } from '../components/platform-settings-form';

export const mapPlatformSettingsToForm = (
  settings: PlatformSettingsResponse
): PlatformSettingsFormData => {
  return {
    noShowBail: (settings.noShowBailCents / 100).toString(),
    minimumTempWorkerAge: settings.minimumTempWorkerAge.toString(),
  };
};

export const mapFormToPlatformSettings = (
  form: Partial<PlatformSettingsFormData>
): UpdatePlatformSettingsPayload => {
  return {
    ...(form.noShowBail ? { noShowBailCents: Number(form.noShowBail) * 100 } : {}),
    ...(form.minimumTempWorkerAge
      ? { minimumTempWorkerAge: Number(form.minimumTempWorkerAge) }
      : {}),
  };
};
