import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { accreditationsService } from 'hq/services';
import { useMutation, useQueryClient } from 'react-query';

type RejectAccreditationParams = {
  flexWorkerId: string;
  certificateId: string;
};

export const useRejectAccreditation = () => {
  const mutationFn = async ({ flexWorkerId, certificateId }: RejectAccreditationParams) =>
    await accreditationsService.rejectAccreditation(flexWorkerId, certificateId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.accreditations());
    },
  });
};
