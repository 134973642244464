import {
  IconCertificate,
  IconCheckouts,
  IconCoin,
  IconCompanies,
  IconFlexPools,
  IconInfoRedesigned,
  IconPartner,
  IconProject,
  IconShifts,
  IconUser,
  IconUsers,
} from 'assets/img';
import { LogoutIcon, LogoutLabel } from './sidebar-logout';
import { getPlannerProjectDefaultFilter, getPlannerShiftDefaultFilter } from './utils';
import { hqRoutes } from '../../Routes';

const items = [
  {
    key: 'operations',
    name: 'Operations',
    children: [
      {
        ...hqRoutes.FlexWorkers,
        key: 'operations',
        label: 'Operations',
        icon: <IconUser />,
      },
      {
        ...hqRoutes.Accreditations,
        key: 'accreditations',
        label: 'Certificaten',
        icon: <IconCertificate />,
      },
      {
        ...hqRoutes.BankAccount,
        key: 'bank-accounts',
        label: 'Bank account',
        icon: <IconCoin />,
      },
    ],
  },
  {
    key: 'sales',
    name: 'Sales & CS',
    children: [
      {
        ...hqRoutes.Companies,
        key: 'sales',
        label: 'Bedrijven',
        icon: <IconCompanies />,
      },
    ],
  },
  {
    key: 'planning',
    name: 'Planning',
    children: [
      {
        ...hqRoutes.PlannerShift,
        path: `${hqRoutes.PlannerShift.path}${getPlannerShiftDefaultFilter()}`,
        key: 'shifts',
        label: 'Shifts',
        icon: <IconShifts />,
      },
      {
        ...hqRoutes.PlannerProject,
        path: `${hqRoutes.PlannerProject.path}${getPlannerProjectDefaultFilter()}`,
        key: 'projects',
        label: 'Projects',
        icon: <IconProject />,
      },
    ],
  },
  {
    key: 'partners',
    name: 'Partners',
    children: [{ ...hqRoutes.Partners, key: 'partners', label: 'Partners', icon: <IconPartner /> }],
  },
  {
    key: 'finance',
    name: 'Finance',
    children: [{ ...hqRoutes.Finance, key: 'finance', label: 'Finance', icon: <IconCheckouts /> }],
  },
  {
    key: 'admin',
    name: 'Admin',
    children: [
      {
        ...hqRoutes.FeatureFlags,
        key: 'featureFlags',
        label: 'Feature Flags',
        icon: <IconFlexPools />,
      },
      {
        ...hqRoutes.UploadPayments,
        key: 'payments',
        label: 'Upload betalingen',
        icon: <IconCoin />,
      },
      {
        ...hqRoutes.PlatformSettings,
        key: 'platformSettings',
        label: 'Platform instellingen',
        icon: <IconInfoRedesigned />,
      },
    ],
  },
  {
    key: 'settings',
    name: 'Instellingen',
    children: [{ ...hqRoutes.HQUsers, key: 'users', label: 'Gebruikers', icon: <IconUsers /> }],
  },
] as const;

const profileInfo = {
  key: 'profile',
  name: 'Profiel',
  children: [
    {
      key: 'profile',
      label: 'Profiel',
      icon: <IconUser />,
    },
    {
      key: 'logout',
      label: <LogoutLabel />,
      icon: <LogoutIcon />,
    },
  ],
};

export { items, profileInfo };
