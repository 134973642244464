import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePageProvider } from 'ogp/layouts';
import { GridDepartment } from './components/grid-department/grid-department';
import { PlanningGridHeader } from './components/grid-header/planning-grid-header';
import { VisibleDaysProvider } from './providers/visible-days-provider';
import { PlanningShiftGridProps } from './planning-shift-grid.types';
import { GridJobType } from './components/grid-job/grid-job';

export const PlanningShiftGrid = ({ data }: PlanningShiftGridProps) => {
  const classes = getClasses();
  const [{ filters }] = usePageProvider();

  if (data.length === 0) {
    return (
      <Box className={classes.noResults}>
        <Typography variant="h2" className={classes.message}>
          Je hebt deze week geen shifts ingepland {filters.departmentId && 'bij deze vestiging'}
        </Typography>
        <Typography>Begin nu met het toevoegen van shifts zodat er gewerkt kan worden!</Typography>
      </Box>
    );
  }

  return (
    <VisibleDaysProvider>
      <PlanningGridHeader />
      <Box>
        <Box className={classes.departmentHeader}>
          <Typography className={classes.name}>Afdelingen</Typography>
          <GridJobType data={undefined} />
        </Box>
        {data.map((dpt) => (
          <GridDepartment key={dpt.id} data={dpt} />
        ))}
      </Box>
    </VisibleDaysProvider>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  departmentHeader: {
    minHeight: theme.spacing(10),
  },
  name: {
    padding: theme.spacing(0, 2, 2, 0),
    position: 'absolute',
    transform: 'translateY(-100%)',
  },
  noResults: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: '1 1 100%',
    '& *': {
      textAlign: 'center',
      maxWidth: theme.spacing(120),
    },
  },
  message: {
    fontWeight: 500,
    marginTop: theme.spacing(24),
    marginBottom: theme.spacing(8),
  },
}));
