import { useQuery } from 'react-query';
import { branchOfficeService } from 'hq/services';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

const useGetAllActiveBranchOffices = () => {
  const queryFn = async () => await branchOfficeService.getAllActiveBranchOffices();

  return useQuery(QUERY_KEYS_HQ.branchOfficesList(), queryFn, {});
};

export { useGetAllActiveBranchOffices };
