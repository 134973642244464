import { Box, styled } from '@mui/material';
import { PartnerCounterOffer } from 'partner/shared/services/partner-checkouts-service.types';
import { LwButton } from 'redesign';
import { useMemo } from 'react';
import { PrimaryButton, SecondaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { IconApproved, IconCancel } from 'assets/img';
import { partnerCounterOfferAlerts, useAlert } from 'shared/components/alerts';
import { useNavigate } from 'react-router';
import { usePartnerRelativePath } from 'partner/shared/hooks/use-partner-relative-path';
import { PARTNER_PATHS } from 'partner/paths';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { mapProposalsToVisuals } from './utils/map-proposals-to-visuals';
import { Proposal } from './proposal';
import { useApproveCounterOfferQuery } from './hooks/use-approve-counter-offer';

type Props = {
  shiftClaimId: string;
  data: PartnerCounterOffer;
};

export const CounterOfferDetails = ({ data, shiftClaimId }: Props) => {
  const { submittedShift, counterOfferShift } = useMemo(() => mapProposalsToVisuals(data), [data]);
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const { alertSuccess, alertError } = useAlert();
  const mutation = useApproveCounterOfferQuery(shiftClaimId);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const generatePath = usePartnerRelativePath();
  const navigateToCounterOffers = () =>
    navigate(`${generatePath(PARTNER_PATHS.Checkout)}?checkoutState=counterOffer`);

  const handleCounterOfferApproval = () => {
    openDialog(
      {
        title: 'Tegenvoorstel goedkeuren?',
        content: 'Zodra je akkoord gaat, zetten wij de facturatie en uitbetaling in gang.',
        secondaryButton: (props) => (
          <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} {...props}>
            Bevestig
          </PrimaryButton>
        ),
      },
      () => {
        disableDialog();
        mutation.mutate(
          { shiftClaimId },
          {
            onSuccess: () => {
              closeDialog();
              navigateToCounterOffers();
              alertSuccess(partnerCounterOfferAlerts.success.approved);
              queryClient.invalidateQueries(QUERY_KEYS_PARTNER.notifications());
              queryClient.invalidateQueries(QUERY_KEYS_PARTNER.counterOffers());
            },
            onError: (e) => alertError(e),
            onSettled: () => {
              enableDialog();
            },
          }
        );
      }
    );
  };

  const handleCounterOfferDecline = () => {
    openDialog(
      {
        title: 'Tegenvoorstel afwijzen?',
        content: 'Neem contact op met de opdrachtgever om dit tegenvoorstel af te wijzen.',
        secondaryButton: (props) => (
          <SecondaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </SecondaryButton>
        ),
        primaryButton: (props) => (
          <PrimaryButton startIcon={<IconApproved />} {...props}>
            OK
          </PrimaryButton>
        ),
      },
      () => {
        closeDialog();
        navigateToCounterOffers();
      }
    );
  };

  return (
    <CounterOfferContainer>
      <ProposalsContainer>
        <Proposal header="Ingediende uren" data={submittedShift} />
        <Proposal
          header="Tegenvoorstel"
          backgroundColor="rgba(247, 249, 252, 1)"
          data={counterOfferShift}
        />
      </ProposalsContainer>
      <ButtonsContainer>
        <LwButton color="secondary" onClick={handleCounterOfferDecline}>
          Oneens
        </LwButton>
        <LwButton onClick={handleCounterOfferApproval}>Akkoord</LwButton>
      </ButtonsContainer>
    </CounterOfferContainer>
  );
};

const CounterOfferContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: `${theme.spacing(5)} 0`,
  gap: theme.spacing(2),
}));

const ProposalsContainer = styled(Box)(({ theme }) => ({
  width: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  margin: `0 ${theme.spacing(10)}`,
}));
