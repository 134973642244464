import { Box } from '@mui/material';
import { GetCompanyGrossHourlyRateResponse } from 'hq/services/company-service.types';
import { useCallback, useMemo } from 'react';
import { Table } from 'redesign';
import {
  GrossHourlyRateTableData,
  GrossHourlyRateTableOptions,
} from './company-gross-hourly-rate-table.types';
import { getGrossHourlyRateColumnDefinition } from './utils/gross-hourly-rate-column-definition';
import { getGrossHourlyRateColumnData } from './utils/gross-hourly-rate-column-data';

export const CompanyGrossHourlyRateTable = ({
  data,
}: {
  data: GetCompanyGrossHourlyRateResponse;
}) => {
  const columnDefinition = useMemo(() => getGrossHourlyRateColumnDefinition(), []);
  const tableData = useMemo(() => getGrossHourlyRateColumnData(data), [data]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleDelete: GrossHourlyRateTableOptions['delete'] = useCallback((_: string) => {
    // TODO: Will be in next sprint
    // https://levelworks.atlassian.net/browse/LW1-16892
  }, []);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const handleEdit: GrossHourlyRateTableOptions['edit'] = useCallback((id: string) => {
    // TODO: Will be in next sprint
    // https://levelworks.atlassian.net/browse/LW1-16892
  }, []);

  return (
    <Box display={'flex'}>
      <Table<GrossHourlyRateTableData, GrossHourlyRateTableOptions>
        columns={columnDefinition}
        data={tableData}
        tableStyle={{ tableLayout: 'fixed' }}
        delete={handleDelete}
        edit={handleEdit}
        data-testid="company-gross-hourly-rate-table"
      />
    </Box>
  );
};
