import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'shared/components/alerts';
import { FlexWorkerFlexPools } from '@types';
import { useForm } from 'react-hook-form';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { flexPoolAlerts as alerts } from 'shared/components/alerts/alert-messages';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { FormCheckboxGroup, LwButton } from 'redesign';
import {
  FlexWorkerFlexPoolsData,
  useOgpUpdateFlexWorkerFlexPools,
} from './hooks/ogp/use-ogp-update-flex-worker-flex-pools';
import { useHqUpdateFlexWorkerFlexPools } from './hooks/hq/use-hq-update-flex-worker-flex-pools';
import { useOgpGetFlexWorkerFlexPools } from './hooks/ogp/use-ogp-flex-worker-flex-pools';
import { useHqGetFlexWorkerFlexPools } from './hooks/hq/use-hq-flex-worker-flex-pools';

type MutationFn = typeof useOgpUpdateFlexWorkerFlexPools | typeof useHqUpdateFlexWorkerFlexPools;
type QueryFn = typeof useOgpGetFlexWorkerFlexPools | typeof useHqGetFlexWorkerFlexPools;

type FlexWorkerFlexPoolsFormData = {
  flexworkerFlexPoolsOptions: string[];
};

export function useChooseFlexWorkerFlexPoolsDialog(
  mutation: MutationFn,
  queryFn: QueryFn,
  companyId?: string
) {
  const queryClient = useQueryClient();
  const [shiftId, setShiftId] = useState<string>('');
  const [flexWorkerId, setFlexWorkerId] = useState<string>('');
  const [flexWorkerFullName, setFlexWorkerFullName] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const updateMutation = mutation();

  const title = `Flexpool beheer (${flexWorkerFullName})`;
  const { data, status, error, isLoading, refetch } = queryFn(flexWorkerId, shiftId, {
    onSuccess: (data) => {
      const flexPoolIds = data.reduce<string[]>((acc, { flexPoolId, isMember }) => {
        return isMember ? [...acc, flexPoolId] : acc;
      }, []);
      setValue('flexworkerFlexPoolsOptions', flexPoolIds);
    },
  });

  const { control, handleSubmit, setValue } = useForm<FlexWorkerFlexPoolsFormData>({
    defaultValues: {},
  });

  useEffect(() => {
    isOpen && refetch();
  }, [isOpen, refetch]);

  const open = useCallback((shiftId: string, flexworkerId: string, flexWorkerFullName: string) => {
    setIsOpen(true);
    setShiftId(shiftId);
    setFlexWorkerId(flexworkerId);
    setFlexWorkerFullName(flexWorkerFullName);
  }, []);

  const close = useCallback(() => {
    setValue('flexworkerFlexPoolsOptions', []);
    setIsOpen(false);
  }, [setValue]);

  const onSubmit = (formData: FlexWorkerFlexPoolsFormData) => {
    const selectedOptions = formData.flexworkerFlexPoolsOptions;
    const requestData: FlexWorkerFlexPoolsData = {
      flexWorkerId,
      flexPoolsToRemove: [],
      flexPoolsToAdd: [],
      companyId,
    };

    data!.forEach((flexWorkerFlexPools) => {
      if (
        selectedOptions.includes(flexWorkerFlexPools.flexPoolId) &&
        !flexWorkerFlexPools.isMember
      ) {
        requestData.flexPoolsToAdd.push(flexWorkerFlexPools.flexPoolId);
      } else if (
        !selectedOptions.includes(flexWorkerFlexPools.flexPoolId) &&
        flexWorkerFlexPools.isMember
      ) {
        requestData.flexPoolsToRemove.push(flexWorkerFlexPools.flexPoolId);
      }
    });
    updateMutation.mutate(requestData, {
      onSuccess: () => {
        close();
        alertSuccess(alerts.success.updated);
        if (shiftId) {
          queryClient.invalidateQueries(QUERY_KEYS_OGP.shift(shiftId));
        }
      },
      onError: (e) => {
        close();
        alertError(e);
      },
    });
  };

  function createFlexPoolOptions() {
    return data!.map(({ flexPoolName, flexPoolId }: FlexWorkerFlexPools) => {
      return {
        label: flexPoolName,
        value: flexPoolId,
      };
    });
  }

  function renderFlexWorkerFlexPoolsIndicator() {
    switch (status) {
      case 'success':
        return data && !!data.length ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormCheckboxGroup
              name="flexworkerFlexPoolsOptions"
              control={control}
              items={createFlexPoolOptions()}
            />
          </form>
        ) : (
          <Typography>{'Er zijn geen flexpools om weer te geven'}</Typography>
        );
      case 'error':
        return (
          <Typography>
            {`Er ging iets mis bij het ophalen van de pauze opties: ${error?.name}, ${error?.message}`}
          </Typography>
        );
      case 'loading':
        return <CircularProgress size={24} />;
    }
  }

  const Element = (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          {renderFlexWorkerFlexPoolsIndicator()}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around' }}>
        <LwButton color="secondary" onClick={close} autoFocus>
          Annuleren
        </LwButton>
        <LwButton color="primary" onClick={handleSubmit(onSubmit)}>
          {isLoading ? <CircularProgress size={24} /> : 'Bevestigen'}
        </LwButton>
      </DialogActions>
    </Dialog>
  );

  return { open, close, Element };
}
