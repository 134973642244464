import React from 'react';
import { useSiteDetection } from 'shared/hooks/use-site-detection';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { EnvironmentService } from 'shared/services';

type AppSettings = {
  env: EnvType;
  domain: 'ogp' | 'hq' | 'partner' | undefined;
};

type EnvType = {
  label: string | undefined;
  shortLabel: 'dev' | 'test' | 'acc' | 'prod';
};

function envShortLabel() {
  switch (EnvironmentService.getVariable('ENV')) {
    case 'development':
      return 'dev';
    case 'test':
      return 'test';
    case 'accept':
      return 'acc';
    default:
      return 'prod';
  }
}

const AppSettingsInit: AppSettings = {
  env: {
    label: EnvironmentService.getVariable('ENV'),
    shortLabel: envShortLabel(),
  },
  domain: undefined,
};

const [Provider, useAppSettingsProvider] = ProviderFactory<AppSettings>(
  'You are trying to use AppSettingsProvider, but it was not found.'
);

const AppSettingsProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [appSettings, setAppSettings] = React.useState<AppSettings>(AppSettingsInit);
  const { site } = useSiteDetection();

  // TODO this whole thing can be done in a static manner, because there is no way for the app to change the `domain` during runtime
  React.useEffect(() => {
    setAppSettings((currentAppSettings) => ({
      ...currentAppSettings,
      domain: site,
    }));
  }, [site]);

  return <Provider value={appSettings}>{children}</Provider>;
};

export { AppSettingsProvider, useAppSettingsProvider };
