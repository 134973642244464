import { Box } from '@mui/material';
import { useState } from 'react';
import { Header, Page } from 'redesign';
import { CollectiveInvoices } from './collective-invoices';
import { Checkouts } from './checkouts';

const Finance = () => {
  const [areCheckoutsVisible, setAreCheckoutsVisible] = useState(false);

  return (
    <Page header={<Header titleText={areCheckoutsVisible ? 'Checkouts' : 'Verzamelfactuur'} />}>
      <Box width="100%">
        {areCheckoutsVisible ? (
          <Checkouts
            areCheckoutsVisible={areCheckoutsVisible}
            setAreCheckoutsVisible={setAreCheckoutsVisible}
          />
        ) : (
          <CollectiveInvoices
            areCheckoutsVisible={areCheckoutsVisible}
            setAreCheckoutsVisible={setAreCheckoutsVisible}
          />
        )}
      </Box>
    </Page>
  );
};

export { Finance };
