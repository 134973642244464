import { AutocompleteOption, FormCheckbox, LwFormAutocomplete } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { Box, FilterOptionsState } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { getFilterOptions } from 'hq/components/views/planner/shifts/filters/utils/utils';
import {
  BusinessUnitsFilterBranchOffice,
  BusinessUnitsFilterFormContext,
} from './filter-business-units.types';
import { getBusinessUnitsOptions } from './get-business-units-options';

type Props = {
  branchOffices: BusinessUnitsFilterBranchOffice[];
};

export const FilterBusinessUnits = (props: Props) => {
  const { branchOffices } = props;
  const { setValue, watch, control } = useFormContext<BusinessUnitsFilterFormContext>();

  const {
    branchOffice: selectedBranchOffice,
    businessUnitCheckboxValue: selectedBusinessUnitCheckbox,
  } = watch();

  const options = useMemo(() => {
    return getBusinessUnitsOptions(
      branchOffices,
      selectedBranchOffice as AutocompleteOption | undefined
    );
  }, [branchOffices, selectedBranchOffice]);

  const filterOptions = useCallback(
    (
      autocompleteOptions: AutocompleteOption[] | null,
      autocompleteState: FilterOptionsState<any>
    ) => {
      const res = getFilterOptions(autocompleteOptions, autocompleteState);
      return res;
    },
    []
  );

  return (
    <>
      <Box>
        <FormCheckbox name="businessUnitCheckboxValue" control={control} label="Bedrijfseenheid" />
      </Box>
      {selectedBusinessUnitCheckbox ? (
        <>
          <LwFormAutocomplete
            isLoading={false}
            name="branchOffice"
            label="Vestigingen"
            getOptionKey={(option) => option.value}
            getOptionLabel={(value) => value?.label ?? ''}
            options={options.branchOffices}
            filterOptions={(options, state) => filterOptions(options, state)}
            isOptionEqualToValue={(option, value) => {
              return option?.value === value?.value;
            }}
            control={control}
            onChange={(_event, value) => {
              if (value) {
                setValue('branchOffice', value);
                setValue('department', null);
              }
            }}
          />
          <LwFormAutocomplete
            isLoading={!branchOffices.length}
            name="department"
            label="Afdelingen"
            getOptionKey={(option) => option.value}
            getOptionLabel={(value) => value?.label ?? ''}
            isOptionEqualToValue={(option, value) => {
              return option?.value === value.value;
            }}
            filterOptions={(options, state) => filterOptions(options, state)}
            disabled={!selectedBranchOffice}
            options={options.departments}
            control={control}
            onChange={(_event, value) => {
              if (value) {
                setValue('department', value);
              }
            }}
          />
        </>
      ) : null}
    </>
  );
};
