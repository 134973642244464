import { usePlanningDateRange } from 'ogp/hooks/planning';
import { useState } from 'react';
import { ExportModal } from './export-drawer/export-drawer';
import { PlanningHeaderPops } from './planning-header.types';
import { PlanningToolbox } from './planning-toolbox/planning-toolbox';

export const PlanningHeader = ({ date }: PlanningHeaderPops) => {
  const { startDate, endDate } = usePlanningDateRange();
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <>
      <ExportModal
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
        startDate={startDate}
        endDate={endDate}
      />
      <PlanningToolbox date={date} setExportModalOpen={setExportModalOpen} />
    </>
  );
};
