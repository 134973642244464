import { Controller, FieldValues } from 'react-hook-form';
import { InputAdornment, styled, TextFieldProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { inputBaseClasses } from '@mui/material/InputBase';
import { StyledErrorText, StyledHelperTextWrapper, StyledIconWarning, StyledLabel } from 'redesign';
import { FormNumberInputDefaultRules, FormNumberInputProps } from './form-number-input.types';

const FormNumberInput = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  placeholder,
  step,
  min,
  max,
  helperText,
  'data-testid': dataTestId,
}: FormNumberInputProps<ControlFields>) => {
  const defaultRules: FormNumberInputDefaultRules = {};
  if (min != null) {
    defaultRules.min = {
      value: min,
      message: `Minimum is ${min}`,
    };
  }
  if (max != null) {
    defaultRules.max = {
      value: max,
      message: `Maximum is ${max}`,
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...defaultRules, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          id={name}
          title={label}
          type="number"
          value={field.value ?? ''}
          variant="filled"
          label={label}
          InputLabelProps={{ shrink: true, required: !!rules?.required, htmlFor: name }}
          fullWidth
          placeholder={placeholder}
          error={!!error}
          helperText={error ? error.message : helperText}
          disabled={disabled}
          inputProps={{ min, max, step }}
          data-testid={dataTestId}
        />
      )}
    />
  );
};

const LwFormNumberInput = <ControlFields extends FieldValues = FieldValues>({
  name,
  label,
  control,
  rules,
  disabled,
  placeholder,
  step,
  min,
  max,
  helperText,
  'data-testid': dataTestId,
}: FormNumberInputProps<ControlFields>) => {
  const defaultRules: FormNumberInputDefaultRules = {};
  if (min != null) {
    defaultRules.min = {
      value: min,
      message: `Minimum is ${min}`,
    };
  }
  if (max != null) {
    defaultRules.max = {
      value: max,
      message: `Maximum is ${max}`,
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...defaultRules, ...rules }}
      render={({ field, fieldState: { error } }) => {
        const message = error ? error.message : helperText;
        return (
          <div>
            <StyledTextField
              {...field}
              id={name}
              title={label}
              type="number"
              value={field.value ?? ''}
              variant="filled"
              label={<StyledLabel required={!!rules?.required}>{label}</StyledLabel>}
              InputLabelProps={{ shrink: true }}
              fullWidth
              placeholder={placeholder}
              error={!!error}
              InputProps={{
                endAdornment: error ? (
                  <InputAdornment position="end">
                    <StyledIconWarning />
                  </InputAdornment>
                ) : null,
              }}
              disabled={disabled}
              inputProps={{ min, max, step }}
              data-testid={dataTestId}
            />
            <StyledHelperTextWrapper className="error-text-wrapper">
              {message && (
                <StyledErrorText className={error ? 'error-state' : ''}>{message}</StyledErrorText>
              )}
            </StyledHelperTextWrapper>
          </div>
        );
      }}
    />
  );
};

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: `2px solid ${theme.palette.lwSecondary[40]}`,
    borderRadius: theme.spacing(3),
    '&:hover, &.Mui-focused': {
      borderColor: theme.palette.lwPrimary[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwPrimary[10]}`,
    },
    '&.Mui-error': {
      borderColor: theme.palette.lwDanger[100],
      boxShadow: `0 0 0 4px ${theme.palette.lwDanger[20]}`,
    },
  },
  '.MuiFormHelperText-root': {
    marginLeft: 10,
    position: 'relative',
  },
  [`& .${inputAdornmentClasses.root}`]: {
    marginRight: theme.spacing(3),
  },
  [`& .${inputBaseClasses.input}`]: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export { FormNumberInput, LwFormNumberInput };
