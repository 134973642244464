import { useFormContext } from 'react-hook-form';
import { LwFormInput } from 'redesign';
import { Grid } from '@mui/material';
import { useGetCompaniesAsSelect } from 'hq/hooks/queryHooks/companies/use-get-companies';
import { RenderUnsettledUI } from 'shared/components';
import { PartnerFormData } from './partner-form.types';

type Props = {
  onSubmit: (data: PartnerFormData) => void;
};

const PartnerForm = ({ onSubmit }: Props) => {
  const { control, handleSubmit } = useFormContext<PartnerFormData>();

  const companiesQuery = useGetCompaniesAsSelect();

  if (companiesQuery.status !== 'success') {
    return <RenderUnsettledUI data={companiesQuery} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item spacing={2} xs={6}>
        <Grid item xs={6}>
          <LwFormInput
            name="name"
            label="Bedrijfsnaam partner"
            control={control}
            rules={{ required: 'Voer een bedrijfsnaam in' }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { PartnerForm };
