import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerWorkerService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

export const useDeletePartnerWorker = (workerId: string) => {
  const queryClient = useQueryClient();
  const mutationFn = async () => await partnerWorkerService.deletePartnerWorker(workerId);

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerWorker(workerId));
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.partnerWorkers());
    },
  });
};
