import { Box } from '@mui/material';
import { IconFlexPoolOnlyJob } from 'assets/img';

const FlexPoolOnlyIndicator = ({
  children,
  flexPoolOnly,
  'data-testid': dataTestId,
}: React.PropsWithChildren<{ flexPoolOnly: boolean; 'data-testid'?: string }>) => {
  return (
    <Box component="div" display="flex" alignItems="center" gap={1} data-testid={dataTestId}>
      {flexPoolOnly ? <IconFlexPoolOnlyJob /> : null} {children}
    </Box>
  );
};

export { FlexPoolOnlyIndicator };
