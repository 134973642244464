import { DateTime } from 'luxon';
import { paths } from 'ogp/paths';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { InboxFiltersParams } from '../../helpers/checkouts.types';

const queryParams = paths.CheckoutsInbox.queryParams;

/**
 * @deprecated
 */
const useInboxFilterFormState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = useQueryParams();

  const applyFiltersWithoutState = useCallback(
    (filters: InboxFiltersParams) => {
      const params = new URLSearchParams(location.search); // Using location.search to keep other params intact.

      if (filters.flexWorkerIds && filters.flexWorkerIds.length) {
        const str = JSON.stringify(filters.flexWorkerIds);
        params.set(queryParams.FLEXWORKER_IDS, str);
      } else {
        params.delete(queryParams.FLEXWORKER_IDS);
      }

      if (filters.branchOffice && filters.branchOffice.value !== undefined) {
        const str = JSON.stringify(filters.branchOffice.value);
        params.set(queryParams.BRANCH_OFFICE_ID, str);
      } else {
        params.delete(queryParams.BRANCH_OFFICE_ID);
      }

      if (
        filters.departmentIds &&
        filters.departmentIds.length > 0 &&
        filters.departmentIds.every(
          (id) => id !== undefined && typeof id === 'string' && id.trim() !== ''
        )
      ) {
        const str = JSON.stringify(filters.departmentIds);
        params.set(queryParams.DEPARTMENT_IDS, str);
      } else {
        params.delete(queryParams.DEPARTMENT_IDS);
      }
      if (filters.flexWorkerIds && filters.flexWorkerIds.length) {
        const str = JSON.stringify(filters.flexWorkerIds);
        params.set(queryParams.FLEXWORKER_IDS, str);
      } else {
        params.delete(queryParams.FLEXWORKER_IDS);
      }

      if (filters.fromDate) {
        params.set(queryParams.FROM_DATE, filters.fromDate.toFormat('yyyy-MM-dd'));
      } else {
        params.delete(queryParams.FROM_DATE);
      }

      if (filters.toDate && filters.toDate.toFormat('yyyy-MM-dd') !== 'Invalied DateTime') {
        params.set(queryParams.TO_DATE, filters.toDate.toFormat('yyyy-MM-dd'));
      } else {
        params.delete(queryParams.TO_DATE);
      }

      navigate(`?${params}`, { replace: true });
    },
    [location.search, navigate]
  );

  const activeFilters: InboxFiltersParams = useMemo(() => {
    const flexWorkerIdsStr = queryString.get(queryParams.FLEXWORKER_IDS);
    const branchOfficeIdStr = queryString.get(queryParams.BRANCH_OFFICE_ID);
    const departmentIdsStr = queryString.get(queryParams.DEPARTMENT_IDS);
    const fromDateStr = queryString.get(queryParams.FROM_DATE);
    const toDateStr = queryString.get(queryParams.TO_DATE);

    const filters: InboxFiltersParams = {
      departmentIds: departmentIdsStr ? JSON.parse(departmentIdsStr) : [],
      flexWorkerIds: flexWorkerIdsStr ? JSON.parse(flexWorkerIdsStr) : [],
      branchOffice: branchOfficeIdStr ? JSON.parse(branchOfficeIdStr) : '',
      fromDate: fromDateStr ? DateTime.fromISO(fromDateStr) : undefined,
      toDate: toDateStr ? DateTime.fromISO(toDateStr) : undefined,
      filterByPrio: false,
    };
    return filters;
  }, [queryString]);

  const [filters, setFilters] = useState(activeFilters);

  const clear = useCallback(() => {
    setFilters({
      departmentIds: [],
      fromDate: undefined,
      toDate: undefined,
      filterByPrio: false,
    });
  }, []);

  const applyFilters = useCallback(
    (filters: InboxFiltersParams) => {
      applyFiltersWithoutState(filters);
    },
    [applyFiltersWithoutState]
  );

  const nrOfActiveFilters = useMemo(() => {
    let count = 0;
    if (activeFilters.departmentIds && activeFilters.departmentIds.length === 1) {
      count++;
    }
    if (activeFilters.flexWorkerIds && activeFilters.flexWorkerIds.length) {
      count++;
    }
    if (activeFilters.branchOffice) {
      count++;
    }

    if (activeFilters.fromDate) {
      count++;
    }

    return count;
  }, [activeFilters]);

  return {
    filters,
    setFilters,
    clear,
    applyFilters,
    activeFilters,
    nrOfActiveFilters,
  };
};

export { useInboxFilterFormState };
