import { HqApiService } from './hqApiService';
import { GetPendingBankAccountsResponse } from './bank-account-service.types';

export class BankAccountService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getPendingBankAccounts(): Promise<GetPendingBankAccountsResponse> {
    return await this.hqApiService.get<GetPendingBankAccountsResponse>({
      path: 'bank-account',
    });
  }

  public async approvePendingBankAccounts(id: string): Promise<void> {
    return await this.hqApiService.update<void>({
      path: `bank-account/${id}/approve`,
    });
  }

  public async rejectPendingBankAccounts(id: string): Promise<void> {
    return await this.hqApiService.update<void>({
      path: `bank-account/${id}/reject`,
    });
  }
}
