export type ExpenseType = 'parking' | 'kilometerAllowance' | 'kilometerReimbursement';

export enum ExpenseUnit {
  EURO_CENT = 'EURO_CENT',
  KILOMETER = 'KILOMETER',
}

export type Expense = {
  description: string;
  amount: number;
  unit: ExpenseUnit;
  expenseType: ExpenseType;
};
