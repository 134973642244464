import _ from 'lodash';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useQuery } from 'react-query';

export const usePartnerCheckoutInboxFilters = () => {
  const queryFn = async () => await partnerCheckoutsService.getCheckoutInboxFilters();

  return useQuery(QUERY_KEYS_PARTNER.checkoutInboxFilters(), {
    queryFn,
    select: (data) => {
      return {
        ...data,
        companies: _.sortBy(data.companies, (company) => company.name.toLowerCase()),
        jobs: _.sortBy(data.jobs, (job) => job.name.toLowerCase()),
      };
    },
  });
};
