import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCancel, IconCross, IconEdit, IconTrash } from 'assets/img';
import { useCompanyRelativePath, useGetOGPUserInfo } from 'ogp/hooks';
import { useDeleteFlexPool } from 'ogp/hooks/queryHooks/flexPools/useDeleteFlexPool';
import { DeleteButton, PrimaryButton, useDialog } from 'ogp/providers/dialog-provider';
import { routes } from 'ogp/Routes';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ContextMenuButton, ContextMenuItem, LwIconButton } from 'redesign';
import { flexPoolAlerts } from 'shared/components/alerts/alert-messages';
import { useAlert } from 'shared/components/alerts';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { BranchOfficeRole } from '@types';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { GetFlexPoolDetailResponse } from '../../../../../services/flex-pool-service.types';

type FlexPoolDetailHeadProps = {
  flexPool: GetFlexPoolDetailResponse['flexPoolDetail'];
  onClose: () => void;
};

export const FlexPoolDetailHead = ({ flexPool, onClose }: FlexPoolDetailHeadProps) => {
  const classes = getClasses();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateFlexPoolPath = useUpdateFlexPoolPath();
  const userQuery = useGetOGPUserInfo();
  const isPlanner =
    userQuery.data &&
    hasAccessWithinPolicyGroup(
      PolicyGroups.DEPARTMENT,
      userQuery.data.userInfo,
      BranchOfficeRole.PLANNER
    );

  const { alertSuccess, alertError } = useAlert();
  const generatePath = useCompanyRelativePath();
  const { openDialog, closeDialog, enableDialog, disableDialog } = useDialog();
  const deleteFlexPool = useDeleteFlexPool();

  if (!flexPool) {
    return null;
  }

  const handleDeleteFlexPool = (id: string) => {
    const flexWorkerCount = flexPool.flexWorkerCount;
    openDialog(
      {
        title: 'Flexpool verwijderen',
        content: flexWorkerCount
          ? `Er zitten nog ${flexWorkerCount} flexwerkers in deze flexpool, weet je zeker dat je deze flexpool wilt verwijderen?`
          : 'Weet je zeker dat je deze flexpool wilt verwijderen?',
        secondaryButton: (props) => (
          <PrimaryButton iconColorMode="fill" startIcon={<IconCancel />} {...props}>
            Annuleer
          </PrimaryButton>
        ),
        primaryButton: (props) => <DeleteButton {...props} />,
      },
      () => {
        disableDialog();
        deleteFlexPool.mutate(id, {
          onSuccess: () => {
            closeDialog();
            alertSuccess(flexPoolAlerts.success.deleted);
            navigate(generatePath(routes.FlexPools));
            queryClient.invalidateQueries(QUERY_KEYS_OGP.flexPoolList());
            queryClient.invalidateQueries(QUERY_KEYS_OGP.statistics());
          },
          onError: (e) => alertError(e),
          onSettled: () => {
            enableDialog();
          },
        });
      }
    );
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="column">
        <Typography color="secondary" variant="body1" style={{ fontWeight: 'bold' }}>
          {flexPool.companyName} / {flexPool.branchOfficeName}
        </Typography>
        <Typography variant="h5" className={classes.jobName}>
          {flexPool.name}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" ml="auto" alignItems="middle" style={{ gap: '32px' }}>
        <ContextMenuButton size="medium" color="secondary">
          <ContextMenuItem disabled={!isPlanner} onClick={() => navigate(updateFlexPoolPath())}>
            <IconEdit />
            Bewerken
          </ContextMenuItem>
          <ContextMenuItem disabled={!isPlanner} onClick={() => handleDeleteFlexPool(flexPool.id)}>
            <IconTrash />
            Verwijderen
          </ContextMenuItem>
        </ContextMenuButton>
        <LwIconButton iconColorMode="stroke" color="secondary" onClick={onClose}>
          <IconCross width={24} height={24} />
        </LwIconButton>
      </Box>
    </Box>
  );
};

function useUpdateFlexPoolPath() {
  const location = useLocation();
  const generatePath = useCompanyRelativePath();
  const { queryParams } = routes.Shifts;

  return useCallback(
    (flexPoolDetailId?: string | null) => {
      const path = generatePath(routes.FlexPoolEdit);
      const params = new URLSearchParams(location.search); // Using location.search to keep other params intact.

      if (flexPoolDetailId === null) {
        params.delete(queryParams.CHECKOUT_LIMIT_DATE);
      } else if (flexPoolDetailId) {
        params.set(queryParams.CHECKOUT_LIMIT_DATE, flexPoolDetailId);
      }

      params.sort();

      return `${path}?${params}`;
    },
    [generatePath, location.search, queryParams]
  );
}

const getClasses = makeStyles(({ spacing }) => ({
  jobName: {
    marginTop: spacing(2),
  },
}));
