import { useGetStatistics } from 'ogp/hooks/queryHooks/statistics/use-get-statistics';
import { Header } from 'redesign';
import { HeaderStatisticsProps } from './header-statistics.types';

export const HeaderStatistics = ({ subheaderText, titleText, type }: HeaderStatisticsProps) => {
  const { status, data } = useGetStatistics(type);
  if (status === 'loading' || status === 'idle' || status === 'error') {
    const text = status === 'error' ? 'Error' : 'Loading...';
    return (
      <Header
        subheaderText={subheaderText}
        titleText={titleText}
        labelGroups={[{ title: text, description: text }]}
      />
    );
  }

  const headerData: React.ComponentProps<typeof Header> = {
    titleText,
    subheaderText,
    labelGroups: data.map((datum) => ({ title: datum.label, description: datum.value + '' })),
  };

  return <Header {...headerData} />;
};
