import { useGetOGPUserInfo } from 'ogp/hooks';
import { DepartmentRole } from 'ogp/services/user-service.types';
import { useGetDepartmentsAsUserRolesList } from '../management/departments/hooks';

type Result = {
  companyRoles: string[];
  branchOfficesWithDepartmentRoles: {
    branchOffice: {
      id: string;
      name: string;
      departmentRoles: {
        id: string;
        name: string;
        roles: DepartmentRole[];
      }[];
    };
  }[];
};

const useUserRoles = (): Result | undefined => {
  const { data: user, status: userInfoStatus } = useGetOGPUserInfo();
  const branchOfficesWithDepartmentsQuery = useGetDepartmentsAsUserRolesList({
    includeArchived: false,
  });
  const roles: Result = {
    companyRoles: [],
    branchOfficesWithDepartmentRoles: [],
  };

  if (
    userInfoStatus === 'idle' ||
    userInfoStatus === 'loading' ||
    userInfoStatus === 'error' ||
    branchOfficesWithDepartmentsQuery.status === 'idle' ||
    branchOfficesWithDepartmentsQuery.status === 'loading' ||
    branchOfficesWithDepartmentsQuery.status === 'error'
  ) {
    return;
  }

  if (user.userInfo.roles.companyRoles) {
    user.userInfo.roles.companyRoles.roles.forEach((role) => {
      roles?.companyRoles.push(role);
    });
  }

  if (user.userInfo.roles.departmentRoles) {
    branchOfficesWithDepartmentsQuery.data.forEach((branchOffice) => {
      const departmentRoles = user.userInfo.roles.departmentRoles.filter((departmentRole) =>
        branchOffice.departments
          .map((department) => department.id)
          .includes(departmentRole.departmentId)
      );

      roles.branchOfficesWithDepartmentRoles.push({
        branchOffice: {
          id: branchOffice.id,
          name: branchOffice.name,
          departmentRoles: departmentRoles.map((departmentRole) => {
            return {
              id: departmentRole.departmentId,
              name:
                branchOffice.departments.find((d) => d.id === departmentRole.departmentId)?.name ??
                '',
              roles: departmentRole.roles,
            };
          }),
        },
      });
    });
  }

  return roles;
};

export { useUserRoles };
