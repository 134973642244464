import { shiftClaimService } from 'hq/services';
import { useMutation } from 'react-query';

type Payload = Parameters<(typeof shiftClaimService)['setNoShow']>['0'];

export const useHqSetNoShow = () => {
  const mutationFn = async (shiftClaimData: Payload) =>
    await shiftClaimService.setNoShow(shiftClaimData);

  return useMutation(mutationFn);
};
