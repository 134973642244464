import { useMutation } from 'react-query';
import { companyService } from 'hq/services';
import { CreateCompanyGrossHourlyRatePayload } from 'hq/services/company-service.types';

export const useCreateCompanyGrossHourlyRate = () => {
  const mutationFn = async (partner: CreateCompanyGrossHourlyRatePayload) =>
    await companyService.createCompanyGrossHourlyRate(partner);

  return useMutation(mutationFn);
};
