import { Box, styled, Typography } from '@mui/material';
import type { Column } from 'react-table';
import {
  BoxAction,
  BoxIcon,
  Cell,
  DateTimeCell,
  Header,
  JobNameCell,
} from 'shared/components/planner/common/utils';
import { IconIsolationMode } from 'assets/img';
import {
  PartnerShiftPlanningCellProp,
  PartnerShiftPlanningColumnDefinition,
} from '../shift-planning-table.types';
import { ShiftPlanningCellProp } from '../../../../../../shared/components/planner/shifts/table/shift-planning-table.types';
import { getNumberOfSeats } from '../../utils/get-number-of-seats';

const getShiftPlanningColumnDefinition: () => Column<PartnerShiftPlanningColumnDefinition>[] =
  () => {
    const jobName = {
      Header: () => <Header>Functie</Header>,
      accessor: 'jobName',
      Cell: JobNameCell,
      width: '200px',
    } as Column<PartnerShiftPlanningColumnDefinition>;
    const companyName = {
      Header: () => <Header>Bedrijf</Header>,
      accessor: 'companyName',
      Cell: Cell,
      width: '200px',
    } as Column<PartnerShiftPlanningColumnDefinition>;
    const departmentName = {
      Header: () => <Header>Afdeling</Header>,
      accessor: 'departmentName',
      Cell: Cell,
    } as Column<PartnerShiftPlanningColumnDefinition>;
    const dateTime = {
      Header: () => <Header>Datum & tijd</Header>,
      accessor: 'dateTime',
      Cell: DateTimeCell,
      width: '230px',
    } as Column<PartnerShiftPlanningColumnDefinition>;
    const state = {
      Header: () => <Header>Plaatsen</Header>,
      accessor: 'state',
      Cell: PartnerStateCell,
      width: '65px',
    } as Column<PartnerShiftPlanningColumnDefinition>;
    const action = {
      Header: () => <Header>Actie</Header>,
      accessor: 'action',
      Cell: ActionCell,
      width: '65px',
    } as Column<PartnerShiftPlanningColumnDefinition>;

    return [jobName, companyName, departmentName, dateTime, state, action];
  };

const BoxState = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const BoxStateNumbers = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: theme.spacing(4),
}));

const PartnerStateCell = (props: ShiftPlanningCellProp<'state'>): JSX.Element => {
  const numberOfSeats = getNumberOfSeats(props.value);
  return (
    <BoxState>
      <BoxStateNumbers>
        <Typography variant="body1" data-testid="open-seats">
          {numberOfSeats}
        </Typography>
      </BoxStateNumbers>
      {props.children}
    </BoxState>
  );
};

const ActionCell = (cell: PartnerShiftPlanningCellProp<'action'>) => {
  return (
    <BoxAction data-testid={`planner-table-action-${cell.value}`}>
      <BoxIcon>
        <IconIsolationMode
          onClick={() => {
            cell.openShiftDetail(cell.value);
          }}
        />
      </BoxIcon>
    </BoxAction>
  );
};

export { getShiftPlanningColumnDefinition };
