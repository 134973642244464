import { Box, Divider, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { getFilterClasses, LwButton } from 'redesign';
import { useCalendarDefaults } from 'shared/hooks/useCalendarDefaults';
import clsx from 'clsx';
import { Calendar } from 'react-multi-date-picker';
import { useCallback, useMemo } from 'react';
import { FilterProps, InboxFiltersParams, InboxFormValues } from './inbox-filter-form.types';
import { BusinessUnitFiltering } from './business-unit-filtering';
import { useCalendar } from '../../../../shared/hooks/use-calendar';

export const InboxFilterForm = ({
  activeFilters,
  applyFilters,
  onCancel,
  clear,
  data,
}: FilterProps<InboxFiltersParams>) => {
  const filterClasses = getFilterClasses();
  const { monthLabels, dayLabels, calendarStyles } = useCalendarDefaults();
  const maxDate = useMemo(() => new Date(), []);

  const { dateRange, handleDateChange, getDateRangeFrom, handleResetDateRange } = useCalendar({
    fromDate: activeFilters.fromDate,
    toDate: activeFilters.toDate,
  });

  const form = useForm<InboxFormValues>({
    defaultValues: {
      ...activeFilters,
      jobs: activeFilters.jobs?.map((jobId) => {
        const job = data.jobs.find((j) => j.id === jobId);
        return { name: job?.name ?? '', id: jobId };
      }),
      companies: activeFilters.companies?.map((companyId) => {
        const company = data.companies.find((c) => c.id === companyId);
        return { name: company?.name ?? '', id: companyId };
      }),
    },
  });

  const onSubmit = useCallback(
    (formData: InboxFormValues) => {
      const submitData: InboxFiltersParams = {
        companies: formData.companies?.map((company) => company.id),
        jobs: formData.jobs?.map((job) => job.id),
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      };

      applyFilters(submitData);
      onCancel();
    },
    [applyFilters, dateRange.fromDate, dateRange.toDate, onCancel]
  );

  const clearForm = useCallback(() => {
    clear();
    form.reset({
      companies: [],
      jobs: [],
    });
    handleResetDateRange();
  }, [clear, form, handleResetDateRange]);

  return (
    <FormProvider {...form}>
      <Box className={filterClasses.content}>
        <Typography variant="h2" className={filterClasses.title}>
          Filter Checkouts
        </Typography>
        <Divider className={filterClasses.divider} />
        <form onSubmit={form.handleSubmit(onSubmit)} className={filterClasses.form}>
          <Box>
            <Calendar
              className={clsx(calendarStyles.calendar)}
              weekStartDayIndex={1}
              range
              months={monthLabels}
              weekDays={dayLabels}
              value={getDateRangeFrom(dateRange.fromDate, dateRange.toDate)}
              maxDate={maxDate}
              onChange={handleDateChange}
            />
            <Box flex="1" justifyContent="flex-end" marginBottom={6}>
              <LwButton color="secondary" onClick={handleResetDateRange}>
                Datumselectie wissen
              </LwButton>
            </Box>
            <BusinessUnitFiltering
              {...{ applyFilters, onCancel, clear }}
              companies={data.companies}
              jobs={data.jobs}
            />
          </Box>
          <div className={filterClasses.actions}>
            <Box flexBasis="100%">
              <LwButton color="primary" type="submit">
                Filter
              </LwButton>
            </Box>
            <LwButton color="secondary" onClick={onCancel}>
              Terug
            </LwButton>
            <LwButton color="secondary" onClick={clearForm}>
              Filters wissen
            </LwButton>
          </div>
        </form>
      </Box>
    </FormProvider>
  );
};
