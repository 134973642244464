import { Box } from '@mui/material';
import { Column } from 'react-table';
import { TableCell, TableHeader } from 'redesign';
import { getExpenseWithNameAndValue } from 'shared/utils/expenses';
import { formatDuration } from 'shared/utils/formatting';
import {
  ProjectsInboxCellProps,
  ProjectsInboxTableColumnDefinition,
} from '../components/projects-inbox-table.types';

export const getProjectsInboxColumnDefinition =
  (): Column<ProjectsInboxTableColumnDefinition>[] => {
    const flexWorker: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'flexWorker',
      Header: () => <TableHeader>Flexwerker</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value.fullName}</TableCell>,
    };
    const project: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'project',
      Header: () => <TableHeader>Project</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    };
    const breakMinutes: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'breakMinutes',
      Header: () => <TableHeader>Pauze</TableHeader>,
      Cell: (cell) => <TableCell>{formatDuration({ minutes: cell.value })}</TableCell>,
    };
    const workedFrom: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'workedFrom',
      Header: () => <TableHeader>Begintijd</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    };
    const workedTo: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'workedTo',
      Header: () => <TableHeader>Eindtijd</TableHeader>,
      Cell: (cell) => <TableCell>{cell.value}</TableCell>,
    };
    const actions: Column<ProjectsInboxTableColumnDefinition> = {
      accessor: 'actions',
      Header: '',
      Cell: ActionCell,
    };
    const expense: Column<ProjectsInboxTableColumnDefinition> = {
      Header: () => <TableHeader>Gemaakte kosten</TableHeader>,
      accessor: 'expense',
      Cell: (cell) => (
        <TableCell>
          {cell.value?.length
            ? cell.value.map((expense) => (
                <Box key={expense.expenseType}>{getExpenseWithNameAndValue(expense)}</Box>
              ))
            : 'Geen'}
        </TableCell>
      ),
    };

    return [flexWorker, project, workedFrom, workedTo, expense, breakMinutes, actions];
  };

const ActionCell = (cell: ProjectsInboxCellProps<'actions'>) => {
  const actions = cell.getRowActions(cell.value);
  return <>{actions}</>;
};
