import { Box } from '@mui/material';
import { BranchOfficeList } from 'hq/components/views/companies/components/company-detail/company-detail-tabs/tabs/company-branch-offices/components/branch-office-list/branch-office-list';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate } from 'react-router-dom';
import { LwButton } from 'redesign';
import { routes } from 'Routes';

export const CompanyBranchOffices = ({ id }: { id: string }) => {
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();

  return (
    <Box>
      <LwButton
        onClick={() => {
          navigate(generatePath(routes.BranchOfficeCreate, { companyId: id }));
        }}
        color="primary"
      >
        Vestiging aanmaken
      </LwButton>
      <BranchOfficeList companyId={id} />
    </Box>
  );
};
