import { Box, Chip, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { DateTime } from 'luxon';
import { Accreditation } from './accreditation-chip.test';

const openFileUrl = (url: string) => {
  window.open(url, '_blank');
};

interface GenerateAccreditationChipsProps {
  accreditation: Accreditation;
  icon: React.ReactNode;
  type: 'active' | 'pending' | 'expired';
}

const AccreditationChip = ({ accreditation, icon, type }: GenerateAccreditationChipsProps) => {
  const isTooltipAvailable = type === 'active' || type === 'expired';
  const tooltipContent =
    isTooltipAvailable && accreditation.expirationDate
      ? 'Verloopt op ' +
        DateTime.fromJSDate(new Date(accreditation.expirationDate)).toFormat('dd/MM/yy')
      : 'Geen vervaldatum';

  const chip = (
    <Chip
      label={
        <IconBox>
          {accreditation.certificate.name}
          {icon}
        </IconBox>
      }
      onClick={() =>
        type === 'active' && accreditation.fileUrl ? openFileUrl(accreditation.fileUrl) : null
      }
    />
  );

  return isTooltipAvailable ? (
    <StyledTooltip title={tooltipContent} arrow placement="bottom">
      {chip}
    </StyledTooltip>
  ) : (
    chip
  );
};

export { AccreditationChip };

const IconBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#CEE8FC',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#CEE8FC',
    padding: '4px 16px',
    borderRadius: theme.spacing(6),
    opacity: 1,
    fontSize: 12,
    color: '#000',
    fontWeight: 600,
  },
}));
