import { SmallLoader } from 'shared/components';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetFlexPoolJobs } from './hooks/use-get-flexpool-jobs';
import { JobsTable } from './jobs-table';

const Jobs = ({ flexPoolId }: { flexPoolId: string }) => {
  const { data: jobs, status, error, refetch } = useGetFlexPoolJobs(flexPoolId);

  if (status !== 'success') {
    return <RenderUnsettledUI renderLoading={<SmallLoader />} data={{ error, status, refetch }} />;
  }

  return <JobsTable data={jobs} />;
};

export { Jobs };
