import { useGetCertificates } from 'hq/hooks/queryHooks/accreditations/use-get-certificates';
import { useGetWorkerList } from 'hq/hooks/queryHooks/flexWorkers/use-get-flex-worker-list';
import { DateTime } from 'luxon';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AutocompleteOption, Header, Page, Toolbox } from 'redesign';
import { AccreditationFormRepresentation } from 'shared/utils/file-handling/file-handling-utils.types';
import { AddAccreditationFormHq } from './add-accreditation-form-hq';
import { AddAccreditationsToolbox } from './add-accreditation-toolbox';

export type AddAccreditationFormTypes = {
  accreditations: AccreditationFormRepresentation[] | null;
  certificate: string | null;
  expirationDate: DateTime | null;
  flexWorkerOption: AutocompleteOption | null;
};

export const AddAccreditationFormHqDataProvider = () => {
  const [flexWorkerSearchValue, setFlexWorkerSearchValue] = useState<string>('');
  const [flexWorkerSelectedOption, setFlexWorkerSelectedOption] =
    useState<AutocompleteOption | null>(null);

  const formMethods = useForm<AddAccreditationFormTypes>({
    defaultValues: {
      accreditations: null,
      certificate: null,
      expirationDate: null,
      flexWorkerOption: null,
    },
  });

  useEffect(() => {
    formMethods.setValue('flexWorkerOption', flexWorkerSelectedOption);
  }, [flexWorkerSelectedOption]);

  const certificatesQuery = useGetCertificates();

  const debouncedFlexWorkerSearchValue = useDebounce(flexWorkerSearchValue, 300);

  const flexWorkersQuery = useGetWorkerList(debouncedFlexWorkerSearchValue);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Certificaat toevoegen" />}
        toolbox={
          <Toolbox>
            <AddAccreditationsToolbox />
          </Toolbox>
        }
      >
        <AddAccreditationFormHq
          certificates={certificatesQuery.data || []}
          flexWorkers={flexWorkersQuery.data || []}
          // TODO make own provider and not pass 4 props LW1-16844
          flexWorkerOption={flexWorkerSelectedOption}
          flexWorkerSearch={flexWorkerSearchValue}
          setFlexWorkerOption={setFlexWorkerSelectedOption}
          setFlexWorkerSearch={setFlexWorkerSearchValue}
        />
      </Page>
    </FormProvider>
  );
};
