import { PolicyGroups } from 'shared/utils/policy-controller';

import { HqRole } from '@types';
import { RouteDefinition } from 'Routes';
import { extendsType } from 'shared/utils/ts-utils';

// The master definition for the HQ routes.
// Each route has the shape of a RouteDefinition, but we can still read all route-specific data.

export const hqRoutes = extendsType<Record<string, RouteDefinition>>()({
  Unauthorized: {
    path: 'unauthorized',
    exact: true,
  },
  Accreditations: {
    path: 'operations/accreditations',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
    queryParams: {
      FLEXWORKER_ID: 'flexWorkerId',
      CERTIFICATE_ID: 'certificateId',
      SORT_ORDER: 'order',
      FILTER: 'filter',
      CERTIFICATE: 'certificate',
      STATE: 'state',
    },
  },
  AccreditationsCreate: {
    path: 'operations/accreditations/create',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  CompaniesCreate: {
    path: 'sales/companies/create',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  Companies: {
    path: 'sales/companies',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
    fallbackPath: 'unauthorized',
  },
  CompaniesEdit: {
    path: 'sales/companies/:id/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  CompanyDetail: {
    path: 'sales/companies/:id',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  CompanyGrossHourlyRateCreate: {
    path: 'sales/companies/:id/gross-hourly-rate/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  FlexWorkers: {
    path: 'operations/flex-workers',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  BankAccount: {
    path: 'operations/bank-accounts',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  FlexWorkersEdit: {
    path: 'operations/flex-workers/:id/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  FlexPoolsAddFlexWorker: {
    path: 'operations/flex-workers/edit-flexpool/:employmentType/:id',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  BranchOfficeCreate: {
    path: 'operations/branch-office/create/:companyId',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  BranchOfficeEdit: {
    path: 'operations/branch-office/:id/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  Finance: {
    path: 'finance/finance',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  HQUsers: {
    exact: true,
    path: 'settings/users',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  HQUsersEdit: {
    path: 'settings/users/:id/edit',
    fallbackPath: 'settings/users',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.SUPERADMIN,
  },
  HQUsersCreate: {
    exact: true,
    path: 'settings/users/create',
    fallbackPath: 'settings/users',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.SUPERADMIN,
  },
  HQUsersDetail: {
    path: 'settings/users/:id',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  UploadPayments: {
    path: 'admin-settings/upload-payments',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.DEVELOPER,
  },
  FeatureFlags: {
    path: 'admin-settings/feature-flags',
    exact: true,
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.DEVELOPER,
  },
  Partners: {
    path: 'partners',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerCreate: {
    path: 'partners/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerDetail: {
    path: 'partners/:id/info',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerDetailEdit: {
    path: 'partners/:id/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerRelation: {
    path: 'partners/:id/relations',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerRelationCreate: {
    path: 'partners/:id/relations/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerRelationEdit: {
    path: 'partners/:id/relations/:partnerRelationId',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerUsers: {
    path: 'partners/:id/users',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerUserCreate: {
    path: 'partners/:id/users/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PartnerUserEdit: {
    path: 'partners/:partnerId/users/:userId/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerShift: {
    path: 'planner/shifts',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerShiftCreateShift: {
    path: 'planner/shifts/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerShiftEditShift: {
    path: 'planner/shifts/:id/edit',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerShiftDuplicateShift: {
    path: 'planner/shifts/:id/duplicate',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerShiftMultiplyShift: {
    path: 'planner/shifts/:id/multiply',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerProject: {
    path: 'planner/projects',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlannerProjectCreate: {
    path: 'planner/projects/create',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  ProjectsDuplicate: {
    path: 'planner/projects/:id/duplicate',
    fallbackPath: 'planning/projects',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  ProjectsEdit: {
    path: 'planner/projects/:id/edit',
    fallbackPath: 'planning/projects',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.ADMIN,
  },
  PlatformSettings: {
    path: 'admin-settings/platform',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.DEVELOPER,
  },
  PlatformSettingsUpdate: {
    path: 'admin-settings/platform/update',
    policyGroup: PolicyGroups.HQ,
    roles: HqRole.DEVELOPER,
  },
} as const);

export type RouteKeys = keyof typeof hqRoutes;
