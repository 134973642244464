import { CheckoutsTypeProvider, useCheckoutsTypeProvider } from './checkouts-type-provider';
import { CheckoutsArchive } from '../shifts-checkouts/archive/checkouts-archive';
import { ProjectsCheckoutsArchive } from '../projects-checkouts/archive/projects-checkouts-archive';

const SharedCheckoutsArchive = () => {
  return (
    <CheckoutsTypeProvider>
      <CheckoutsArchivePage />
    </CheckoutsTypeProvider>
  );
};

const CheckoutsArchivePage = () => {
  const { type } = useCheckoutsTypeProvider();

  return type === 'shifts' ? <CheckoutsArchive /> : <ProjectsCheckoutsArchive />;
};

export { SharedCheckoutsArchive };
