import React from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { useGetFeatureFlags } from 'shared/hooks/query-hooks/use-get-environment-configuration';
import { StateError } from 'shared/components/common/state/State';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { TFeatureFlagContext } from './feature-flag-provider.types';

const [Provider, useFeatureFlagProvider] = ProviderFactory<TFeatureFlagContext>(
  'FeatureFlagContext can only be accessed within FeatureFlagProvider'
);

const FeatureFlagProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const useFeatureFlagsQuery = useGetFeatureFlags();

  if (useFeatureFlagsQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={useFeatureFlagsQuery}
      />
    );
  }
  const { allowFreelancerEmploymentType, allowTempWorkerEmploymentType } =
    useFeatureFlagsQuery.data;
  if (!allowFreelancerEmploymentType && !allowTempWorkerEmploymentType) {
    return <StateError error={new Error('Er zijn geen werkvormen beschikbaar')} />;
  }

  return <Provider value={useFeatureFlagsQuery.data}>{children}</Provider>;
};

const useFeatureFlagContext = () => {
  const featureFlags = useFeatureFlagProvider();

  return featureFlags;
};

export { FeatureFlagProvider, useFeatureFlagContext };
