import { Header, LwButton, Page, Toolbox } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useGetPlatformSettings } from './hooks/use-get-platform-settings';
import { PlatformSettings } from './components/platform-settings';

export const PlatformSettingsPage = () => {
  const settingsQuery = useGetPlatformSettings();
  const generatePath = useHqRelativePath();

  if (settingsQuery.status !== 'success') {
    return <RenderUnsettledUI data={settingsQuery} />;
  }

  return (
    <Page
      header={<Header titleText="Platform instellingen" />}
      toolbox={
        <Toolbox>
          <LwButton color="primary" to={generatePath(hqRoutes.PlatformSettingsUpdate)}>
            Aanpassen
          </LwButton>
        </Toolbox>
      }
    >
      <PlatformSettings data={settingsQuery.data} />
    </Page>
  );
};
