import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useToggle } from 'shared/hooks';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { invariant } from 'shared/utils/utils';
import { PrimaryButton, SecondaryButton } from './components/buttons';
import { Dialog as TDialog } from './components/buttons.types';
import { giveMeButton } from './dialog-provider.helpers';
import { TDialogContext } from './dialog-provider.types';

const [Provider, useDialogProvider] = ProviderFactory<TDialogContext>(
  'DialogContext can only be accessed within DialogProvider'
);

const DialogProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { on: disabled, setOn: disable, setOff: enable } = useToggle();
  const { on: isOpen, setOn: open, setOff: close } = useToggle();
  const dialog = React.useRef<TDialog | undefined>();
  const callback = React.useRef<undefined | (() => void)>();
  const getLatestCallback = () => {
    invariant(callback.current);
    return callback.current;
  };

  const handleShow: TDialogContext['showDialog'] = React.useCallback(
    (_dialog, _callback) => {
      dialog.current = undefined;
      callback.current = _callback;
      dialog.current = _dialog;
      enable();
      open();
    },
    [open, enable]
  );

  const ResolvedSecondaryButton = dialog.current
    ? giveMeButton(
        {
          button: dialog.current.secondaryButton,
          props: { onClick: close },
        },
        {
          button: SecondaryButton,
          props: { onClick: close },
        }
      )
    : null;

  const ResolvedPrimaryButton = dialog.current
    ? giveMeButton(
        {
          button: dialog.current?.primaryButton,
          props: {
            onClick: getLatestCallback(),
            disabled,
          },
        },
        {
          button: PrimaryButton,
          props: {
            onClick: getLatestCallback(),
            disabled,
          },
        }
      )
    : null;

  return (
    <Provider
      value={{
        closeDialog: close,
        showDialog: handleShow,
        disableDialog: disable,
        enableDialog: enable,
      }}
    >
      {children}

      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.current?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialog.current?.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', gap: '32px' }}>
          {ResolvedSecondaryButton}
          {ResolvedPrimaryButton}
        </DialogActions>
      </Dialog>
    </Provider>
  );
};

const useDialog = () => {
  const { closeDialog, showDialog, enableDialog, disableDialog } = useDialogProvider();

  /**
   * Either `title` or `content` (or both) are expected.
   * If you set `secondaryButton` to `null` or `primaryButton` to `null`, it won't be rendered in the dialog.
   * Be aware though that you can't hide both of them at the same time.
   *
   * @example
   *
   * openDialog({
   *   title: 'Custom title',
   *   content: 'Custom content',
   *   secondaryButton: ({ handleCancel }) => <button onClick={handleCancel}>cancel</button>
   *   primaryButton: ({ handleConfirm }) => <button onClick={handleConfirm}>confirm</button>
   * })
   */
  const openDialog = React.useCallback(
    (dialog: TDialog, cb: () => void) => {
      showDialog(dialog, cb);
    },
    [showDialog]
  );

  return { openDialog, closeDialog, enableDialog, disableDialog } as const;
};

export { DialogProvider, useDialog };
