import { DateTime } from 'luxon';
import { Dialog } from 'ogp/providers/dialog-provider/components/buttons.types';
import {
  ShiftClaimant,
  ShiftDetailData,
} from '../../components/tabs/claimants/claimant-table.types';

const checkFreelancerWithinCancelationPeriod = (
  shiftData: ShiftDetailData,
  candidate: ShiftClaimant
) => {
  const isCancelationOffer = DateTime.now() > DateTime.fromISO(shiftData.mutableUntilDate);
  return (
    shiftData.isFreelance &&
    isCancelationOffer &&
    candidate.isFlexWorker &&
    candidate.employmentType === 'FREELANCER'
  );
};

export const getCancellationDialogMessage = (
  shiftData: ShiftDetailData,
  candidate: ShiftClaimant
): Dialog => {
  const isFreelancerWithinCancelationPeriod = checkFreelancerWithinCancelationPeriod(
    shiftData,
    candidate
  );
  if (isFreelancerWithinCancelationPeriod) {
    return {
      title: 'Let op! Je annuleert de flexwerker binnen het annuleringstermijn',
      content:
        'Hierbij heeft de flexwerker recht op 50% van ingeplande uren volgens de Overeenkomst van Opdracht. Bij bevestiging ga je hiermee akkoord.',
    };
  }
  return {
    title: 'Weet je zeker dat je deze inschrijving wilt annuleren?',
  };
};
