import { FlexWorkerProfileData } from '@types';
import { HqApiService } from './hqApiService';
import {
  HQShiftClaimExportPayload,
  HQShiftClaimForFlexWorkerListItem,
  HQShiftClaimSetNoShowPayload,
} from './shift-claim-service.types';

export class ShiftClaimService {
  constructor(private readonly hqApiService: HqApiService) {}

  /**
   * Get shiftClaims
   */
  public getShiftClaimsForFlexWorker = async (params: Record<string, unknown>) => {
    const shiftClaimList = await this.hqApiService.get<HQShiftClaimForFlexWorkerListItem[]>({
      path: `shift-claims/list-for-flex-worker`,
      params,
    });

    return shiftClaimList;
  };

  /**
   * Cancel a shiftClaim
   */
  public cancelShiftClaim = async (shiftClaimId: string) =>
    await this.hqApiService.update({
      path: `shift-claims/cancel-claim/${shiftClaimId}`,
    });

  /**
   * Download a shiftClaim contract
   */
  public getShiftClaimContract = async (shiftClaimId: string) =>
    await this.hqApiService.get({
      path: `shift-claims/contract/${shiftClaimId}`,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      decoder: (arrayBuffer: any) => new Blob([arrayBuffer], { type: 'application/pdf' }),
    });

  /**
   * Download a shiftClaim CSV
   */
  public getShiftClaimsExport = async (getShiftClaimsData: HQShiftClaimExportPayload) =>
    await this.hqApiService.get<Blob>({
      path: `shift-claims/export`,
      responseType: 'blob',
      params: getShiftClaimsData,
    });

  public getFlexWorkerProfileThroughShiftClaim = async (
    shiftClaimId: string,
    flexWorkerId: string
  ) => {
    const response = await this.hqApiService.get<FlexWorkerProfileData>({
      path: `shift-claims/${shiftClaimId}/profile/${flexWorkerId}`,
    });
    return response;
  };

  public createShiftClaim = async (shiftId: string, flexWorkerId: string) =>
    await this.hqApiService.create({
      path: `shift-claims`,
      body: {
        shiftId,
        flexWorkerId,
      },
    });

  public undoNoShow = async (shiftClaimId: string) => {
    return await this.hqApiService.update({
      path: `shift-claims/undo-no-show/${shiftClaimId}`,
    });
  };

  public setNoShow = async ({ shiftClaimId, ...body }: HQShiftClaimSetNoShowPayload) => {
    return await this.hqApiService.update({
      path: `shift-claims/no-show/${shiftClaimId}`,
      body,
    });
  };
}
