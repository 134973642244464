import { shiftClaimService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { HQShiftClaimForFlexWorkerListItem } from 'hq/services/shift-claim-service.types';

export const useGetShiftClaimsForFlexWorker = (shiftClaimsOptions: {
  flexWorkerId?: string;
  companyId?: string;
  branchOfficeId?: string;
  jobTypeId?: string;
  fromDate?: string;
  toDate?: string;
  state?: string;
}) => {
  const queryFn = async () =>
    await shiftClaimService.getShiftClaimsForFlexWorker(shiftClaimsOptions);

  return useQuery<
    Promise<HQShiftClaimForFlexWorkerListItem[]>,
    Error,
    HQShiftClaimForFlexWorkerListItem[]
  >(QUERY_KEYS_HQ.shiftClaimsForFlexWorker(shiftClaimsOptions), queryFn, {
    enabled: !!shiftClaimsOptions.flexWorkerId,
  });
};
