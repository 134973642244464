import React from 'react';
import { MenuItem, Select as MUISelect } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCaretDown } from 'assets/img';
import { SelectProps } from './select.types';

const Select = ({ initialOption, options, icon, onSelect }: SelectProps) => {
  const [current, setCurrent] = React.useState(initialOption);
  const classes = getClasses();
  const Icon = icon
    ? React.cloneElement(icon, {
        ...icon.props,
        style: {
          ...icon.props.style,
          flexShrink: 0,
        },
      })
    : null;

  const handleChange: React.ComponentProps<typeof MUISelect>['onChange'] = (e) => {
    const { value } = e.target;

    const selected = options.find((option) => option.value === value);

    if (selected === undefined) {
      throw new Error(
        `Selected option was not found in a list of provided options, ${value} provided.`
      );
    }

    onSelect(selected);
    setCurrent(selected);
  };

  React.useEffect(() => {
    setCurrent(initialOption);
  }, [initialOption]);

  return (
    <MUISelect
      IconComponent={IconCaretDown}
      classes={{
        select: classes.select,
        icon: classes.icon,
      }}
      value={current.value ?? ''}
      onChange={handleChange}
      displayEmpty
      renderValue={() => (
        <MenuItem classes={{ root: classes.selected }}>
          {Icon}
          <span className={classes.option}>{current.label}</span>
        </MenuItem>
      )}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
  );
};

const getClasses = makeStyles(() => ({
  option: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '120px',
  },
  selected: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    gap: '10px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '40px !important',
    '&:focus': {
      background: 'white',
      borderRadius: '16px',
    },
  },
  icon: {
    right: '21px',
    top: 'calc(50% - 12px)',
  },
}));

export { Select };
