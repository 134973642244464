import { projectService as ogpProjectService } from 'ogp/services';
import { projectService as hqProjectService } from 'hq/services';
import { useQuery } from 'react-query';
import { SHARED_QUERY_KEYS_PROJECT } from 'shared/query-keys/project';
import { SiteDetectionResult, useSiteDetection } from '../../../../../../hooks';

export const useGetProjectDetail = (projectId: string) => {
  const site = useSiteDetection();
  const queryFn = getQueryFunction(site, projectId);

  return useQuery(SHARED_QUERY_KEYS_PROJECT.projectDetail(site, projectId), queryFn);
};

const getQueryFunction = (siteDetectionResult: SiteDetectionResult, projectId: string) => {
  if (siteDetectionResult.isHQ) {
    return async () => await hqProjectService.getProjectDetail(projectId);
  }
  if (siteDetectionResult.isOGP) {
    return async () => await ogpProjectService.getProjectDetail(projectId);
  }

  throw new Error(`useGetProjectDetail: site "${siteDetectionResult.site}" is neither HQ nor OGP.`);
};
