import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerPlacementService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';
import { GetPartnerPlacementsParams } from '../../../../shared/services/partner-placements-service';

const useGetPartnerPlacements = (params: GetPartnerPlacementsParams) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.placements(),
    queryFn: async ({ pageParam = 0 }) =>
      await partnerPlacementService.getPartnerPlacements({
        pageParam,
        pageSizeParam: params.pageSizeParam,
      }),
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};

export { useGetPartnerPlacements };
