export const getNumberOfSeats = (
  workInformation:
    | {
        claimants: unknown[];
        maxClaimants: number;
      }
    | {
        claimants: number;
        maxClaimants: number;
      }
) => {
  if (Array.isArray(workInformation.claimants)) {
    return workInformation.maxClaimants - workInformation.claimants.length;
  } else {
    return workInformation.maxClaimants - workInformation.claimants;
  }
};
