import { RequiredCertificatesForJobType } from './job-type-service.types';
import { OgpApiService } from './ogp-api-service';

class JobTypeService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public async getRequiredCertificates(jobTypeId: string) {
    const response = await this.ogpApiService.get<RequiredCertificatesForJobType>({
      path: `job-types/${jobTypeId}/required-certificates`,
    });
    return response;
  }
}

export { JobTypeService };
