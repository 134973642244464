import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { CompanyDetail } from '@types';
import { GetCompaniesResponse } from '../company-service.types';
import { GetBranchOfficesResponse } from '../branch-office-service.types';
import { AccreditationDataHQ } from '../accreditations-service.types';

export type CompaniesSelectModel = { value: string; label: string }[];
const companiesResponse2CompaniesSelectModel = (
  companiesResponse: GetCompaniesResponse[]
): CompaniesSelectModel => {
  const companies: CompaniesSelectModel = [];
  sortBy(companiesResponse, (company) => company.name.toLowerCase()).forEach((company) => {
    companies.push({
      value: company.id,
      label: company.name,
    });
  });

  return companies;
};

export type CompanyDetailSelectModel = {
  id: string;
  description: string;
  isArchivable: boolean;
  ortId: string | undefined;
  name: string;
  offersFreelanceWork: boolean;
  offersTempWork: boolean;
  workCooldownMinutes: number;
};
const companyResponse2CompanyDetailModel = (
  companyResponse: CompanyDetail
): CompanyDetailSelectModel => {
  const { branchOffice: branchOffices, ...rest } = companyResponse;

  const jobsWithPlacements: string[] = [];

  branchOffices?.forEach((branchOffice) =>
    branchOffice.department?.forEach((department) =>
      department.job?.forEach((job) => jobsWithPlacements.push(job.id))
    )
  );

  return {
    ...rest,
    isArchivable: !jobsWithPlacements.length,
  };
};

export type BranchOfficesListItemSelectModel = Omit<
  GetBranchOfficesResponse['branchOffices'][number],
  'department'
> & {
  isArchivable: boolean;
};
const branchOffices2BranchOfficeListItemsModel = (
  branchOfficesResponse: GetBranchOfficesResponse['branchOffices']
): BranchOfficesListItemSelectModel[] => {
  const result = branchOfficesResponse.map((branchOffice) => {
    const { department: departments, ...rest } = branchOffice;

    const jobsWithPlacements: string[] = [];

    departments?.forEach((department) =>
      department.job?.forEach((job) => jobsWithPlacements.push(job.id))
    );

    return {
      ...rest,
      isArchivable: !jobsWithPlacements.length,
    };
  });

  return result;
};

type AccreditationDataHQmapped = {
  active: AccreditationDataHQ[];
  pending: AccreditationDataHQ[];
  expired: AccreditationDataHQ[];
};

const mapAccreditations = (accreditations: AccreditationDataHQ[]): AccreditationDataHQmapped => {
  const active = accreditations.filter(
    (accreditation) =>
      accreditation.state === 'verified' &&
      (!accreditation.expirationDate ||
        DateTime.fromJSDate(new Date(accreditation.expirationDate)).startOf('day') >=
          DateTime.now().startOf('day'))
  );

  const pending = accreditations.filter((accreditation) => accreditation.state === 'pending');

  const expired = accreditations.filter(
    (accreditation) =>
      accreditation.state === 'verified' &&
      accreditation.expirationDate &&
      DateTime.fromJSDate(new Date(accreditation.expirationDate)).startOf('day') <
        DateTime.now().startOf('day')
  );

  return { active, pending, expired };
};

export {
  companiesResponse2CompaniesSelectModel,
  companyResponse2CompanyDetailModel,
  branchOffices2BranchOfficeListItemsModel,
  mapAccreditations,
};
