import { DateTime } from 'luxon';

type Props = {
  [key: string]: string | string[] | DateTime | boolean | undefined;
};

export const stringifyFilters = (appliedFilters: Props): { [key: string]: string } | undefined => {
  const result: { [key: string]: string } = {};

  Object.entries(appliedFilters).forEach(([key, value]) => {
    if (value !== undefined && value !== '' && value !== null) {
      if (value instanceof DateTime) {
        result[key] = value.toISODate();
      } else if (Array.isArray(value)) {
        result[key] = `[${value.map((item) => JSON.stringify(item)).join(',')}]`;
      } else {
        result[key] = value.toString();
      }
    }
  });

  if (Object.keys(result).length === 0) {
    return undefined;
  }

  return result;
};
