import { QueryKey, useQueryClient } from 'react-query';

const useInvalidateQuery = () => {
  const client = useQueryClient();

  const run = (query: QueryKey) => {
    client.invalidateQueries(query);
  };

  return run;
};

export { useInvalidateQuery };
