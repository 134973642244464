import { useMutation, UseMutationOptions } from 'react-query';
import { shiftClaimService } from 'hq/services';
import { saveAs } from 'file-saver';

type ShiftClaimOvoMutationOptions = UseMutationOptions<Blob, unknown, string>;

export const useGetShiftClaimContract = (options?: ShiftClaimOvoMutationOptions) => {
  const mutationFn = async (shiftClaimId: string) =>
    await shiftClaimService.getShiftClaimContract(shiftClaimId);

  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftClaimOvoMutationOptions = {
    onSuccess: async (data, shiftClaimId, context) => {
      saveAs(data, `contract_${shiftClaimId}.pdf`);
      onSuccess && onSuccess(data, shiftClaimId, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
