import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useGetPartnerUserInfo } from 'partner/shared/hooks/use-get-partner-user-info';
import { PolicyGroups } from 'shared/utils/policy-controller';
import { PartnerAuthenticatedElement } from 'partner/shared/components/partner-authenticated-element/partner-authenticated-element';
import { usePartnerSidebarProvider } from './sidebar-provider';

export const SidebarPartnerName = () => {
  const { isOpen } = usePartnerSidebarProvider();
  const { data: user } = useGetPartnerUserInfo();
  const classes = getClasses();

  return isOpen ? (
    <PartnerAuthenticatedElement roles={'PARTNER'} policyGroup={PolicyGroups.PARTNER}>
      <Box className={classes.companyInfo}>
        <Typography variant="body1" className={classes.companyName}>
          {user?.partner.name}
        </Typography>
      </Box>
    </PartnerAuthenticatedElement>
  ) : null;
};

const getClasses = makeStyles((theme: Theme) => {
  return {
    companyInfo: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    companyName: {
      overflow: 'hidden',
    },
  };
});
