import React from 'react';
import { IconButton, IconButtonProps, styled, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Props } from './icon-button.types';

const LwIconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const theme = useTheme();
  const sxOverride = getSxOverride(props, theme);
  const component = getComponent(props);
  const { iconColorMode, ...rest } = props;

  return <StyledIconButton as={component} sx={sxOverride} ref={ref} {...rest} />;
});

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  '&.MuiIconButton-root': {
    borderRadius: '50%',
    boxShadow: 'none',
    height: '56px',
    width: '56px',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
  },

  '&.MuiIconButton-colorPrimary': {
    backgroundColor: theme.palette.lwWhite[100],
    border: `1px solid ${theme.palette.lwSecondary[20]}`,

    '&:focus': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.lwSecondary[10]}`,
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.lwPrimary[20],
    },
  },

  '&.MuiIconButton-colorSecondary': {
    backgroundColor: theme.palette.lwWhite[100],
    border: `1px solid ${theme.palette.lwSecondary[20]}`,

    '&:hover': {
      border: `1px solid ${theme.palette.lwSecondary[40]}`,
      boxShadow: `0px 8px 24px ${theme.palette.lwSecondary[20]}`,
    },

    '&:focus': {
      boxShadow: `0px 0px 0px 4px ${theme.palette.lwPrimary[10]}`,
    },

    '&:active': {
      backgroundColor: theme.palette.lwSecondary[10],
      border: `1px solid ${theme.palette.lwSecondary[100]}`,

      '&:focus': {
        boxShadow: 'none',
      },
    },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.lwWhite[100],
      border: `1px solid ${theme.palette.lwSecondary[10]}`,
    },
  },
}));

const getSxOverride = ({ iconColorMode, onClick, to }: Props, theme: Theme) => ({
  '&.MuiIconButton-root': {
    ...(!onClick && !to
      ? {
          pointerEvents: 'none',
        }
      : {}),
  },
  '&.MuiIconButton-colorPrimary': {
    '& svg path': {
      [iconColorMode]: theme.palette.lwBlack[100],
    },
    '&:active svg path': {
      [iconColorMode]: theme.palette.lwWhite[80],
    },
  },

  '&.MuiIconButton-colorSecondary': {
    '&:hover svg path': {
      [iconColorMode]: theme.palette.lwPrimary[100],
    },

    '&:active svg path': {
      [iconColorMode]: theme.palette.lwSecondary[100],
    },

    '&.Mui-disabled': {
      '& svg path': {
        [iconColorMode]: theme.palette.lwSecondary[40],
      },
    },

    '& svg path': {
      [iconColorMode]: theme.palette.lwSecondary[100],
    },
  },
});

const getComponent = (props: Props) => {
  if (props.to) {
    return Link;
  }

  if (props.onClick) {
    return IconButton;
  }

  return 'span';
};

export { LwIconButton };
