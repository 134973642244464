import { DialogTitle } from '@mui/material';
import { ShiftPlanningShiftsPartner } from 'hq/services/shift-planning-service.types';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BulkForwardToPartnerDialogActions } from './dialog-actions';
import { BulkForwardToPartnerDialogContent } from './dialog-content';
import { BulkForwardToShiftsFormInput } from '../bulk-forward-to-partner';

export enum SelectableFor {
  All,
  Some,
  None,
}

export const BulkForwardToPartnerDialogForm = ({
  availablePartners,
  numberOfShifts,
  closeDialog,
  onSubmit,
}: {
  availablePartners: ShiftPlanningShiftsPartner[];
  numberOfShifts: number;
  closeDialog: () => void;
  onSubmit: (data: BulkForwardToShiftsFormInput) => void;
}) => {
  const formMethods = useForm<BulkForwardToShiftsFormInput>();
  const { handleSubmit } = formMethods;

  const selectableFor = useMemo(() => {
    const isSelectableForAll =
      availablePartners.length > 0 &&
      availablePartners.every((partner) => partner.isOverlappingForAll);
    if (isSelectableForAll) {
      return SelectableFor.All;
    }
    const isSelectableForNone = availablePartners.every((partner) => !partner.isOverlappingForAll);
    if (isSelectableForNone) {
      return SelectableFor.None;
    }
    return SelectableFor.Some;
  }, [availablePartners]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} id="planner-shift-partners-forward-form">
        <DialogTitle>Wijs diensten toe aan partners</DialogTitle>
        <BulkForwardToPartnerDialogContent
          availablePartners={availablePartners}
          numberOfShifts={numberOfShifts}
          selectableFor={selectableFor}
        />
        <BulkForwardToPartnerDialogActions
          closeDialog={closeDialog}
          selectableFor={selectableFor}
        />
      </form>
    </FormProvider>
  );
};
