import { Avatar, Box, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCross } from 'assets/img';
import { useActivateFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useActivateFlexWorker';
import { useDeactivateFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useDeactivateFlexWorker';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useNavigate } from 'react-router-dom';
import { ContextMenuButton, ContextMenuItem } from 'redesign';
import { routes } from 'Routes';
import {
  formatEmploymentType,
  formatFlexWorkerLevel,
  formatFullName,
} from 'shared/utils/formatting';
import { FlexWorkerData } from '@types';
import { flexWorkerPaydayOnboardingAlerts, useAlert } from 'shared/components/alerts';
import { useAddFlexWorkerToPayday } from './hooks/use-add-flex-worker-to-payday';

type Props = {
  data: FlexWorkerData;
  onClose: () => void;
  allowEmploymentId: boolean;
};

function getReadablePaydayState(paydayState: string | null): string {
  switch (paydayState) {
    case 'NotOnboarding':
      return 'Geen payday account';
    case 'Onboarding':
      return 'Account incompleet, banner zichtbaar in app';
    case 'Onboarded':
      return 'Gebruiker is volledig onboard';
    default:
      return 'Onbekend';
  }
}

export const FlexWorkerDetailHead = ({ data, onClose, allowEmploymentId }: Props) => {
  const classes = getClasses();
  const { alertError, alertSuccess } = useAlert();
  const generatePath = useHqRelativePath();
  const navigate = useNavigate();
  const flexWorkerName = formatFullName(data);
  const flexWorkerLevel = formatFlexWorkerLevel(data.level);
  const employmentType = `(${formatEmploymentType(data.employmentType)})`;
  const addFlexworkerToPayday = useAddFlexWorkerToPayday();
  const initials = flexWorkerName
    .split(' ')
    .map((item) => item[0])
    .join('');

  const { mutate: activate, isLoading: loadingActivate } = useActivateFlexWorker(data.id, {
    onError: (e) => alertError(e),
  });
  const { mutate: deactivate, isLoading: loadingDeactivate } = useDeactivateFlexWorker(data.id, {
    onError: (e) => alertError(e),
  });

  const handleEdit = () => {
    const path = generatePath(routes.FlexWorkersEdit, { id: data.id });
    navigate(path);
  };

  const handleAddFlexworkerToPayday = () => {
    if (data.employmentType === 'FREELANCER' && data.level !== 'STARTER') {
      alertError(
        new Error('Flexwerker (freelancer) mag nog niet werken, hij moet het level STARTER hebben.')
      );
      return;
    }
    if (data.employmentType === 'TEMPWORKER' && data.level !== 'WORKER') {
      alertError(
        new Error(
          'Flexwerker (uitzendkracht) mag nog niet werken, hij moet het level WORKER hebben.'
        )
      );
      return;
    }

    addFlexworkerToPayday.mutate(data.id, {
      onSuccess: () => {
        alertSuccess(flexWorkerPaydayOnboardingAlerts.success.created);
      },
      onError: (e) => alertError(e),
    });
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.flexWorkerInfo}>
          <Avatar classes={{ circular: classes.avatar }} src={data?.profilePictureUrl}>
            {initials}
          </Avatar>
          <Box>
            <Typography variant="h3" mb={1}>
              {flexWorkerName}
            </Typography>
            <Typography mb={1}>{`${flexWorkerLevel} ${employmentType}`}</Typography>
            <Typography mb={1}>
              {data?.workedShifts + ' gewerkte shift' + (data?.workedShifts === 1 ? '' : 's')}
            </Typography>
            <Tooltip title={data?.paydayOnboardingStatus}>
              <Typography mb={1}>
                Payday status: {getReadablePaydayState(data?.paydayOnboardingStatus)}
              </Typography>
            </Tooltip>

            {allowEmploymentId && data?.employmentId ? (
              <Tooltip title={data?.employmentId}>
                <Typography mb={1}>Employment ID: {data.employmentId}</Typography>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Box marginLeft="auto">
          <ContextMenuButton
            loading={loadingActivate || loadingDeactivate || addFlexworkerToPayday.isLoading}
            size="medium"
          >
            <ContextMenuItem
              onClick={() => activate()}
              disabled={!data.disabled || data.hasPendingIdentityVerification}
            >
              Activeren
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => deactivate()}
              disabled={data.disabled || data.hasPendingIdentityVerification}
            >
              Deactiveren
            </ContextMenuItem>
            <ContextMenuItem onClick={() => handleEdit()}>Aanpassen</ContextMenuItem>
            <ContextMenuItem
              onClick={() => handleAddFlexworkerToPayday()}
              disabled={
                data.disabled ||
                data.paydayOnboardingStatus !== 'NotOnboarding' ||
                data.hasPendingIdentityVerification
              }
            >
              Aanmelden bij Payday
            </ContextMenuItem>
          </ContextMenuButton>
          <IconButton onClick={onClose}>
            <IconCross width={24} height={24} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  flexWorkerInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: theme.spacing(26),
    width: theme.spacing(26),
    marginRight: theme.spacing(4),
  },
}));
