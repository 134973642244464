import { usePlanningDateRange } from 'ogp/hooks/planning';
import React from 'react';
import { ProviderFactory } from 'shared/providers/provider-factory';

const [Provider, useVisibleDaysProvider] = ProviderFactory<{ visibleDays: string[] }>(
  'VisibleDays can only be used inside VisibleDaysProvider'
);

const VisibleDaysProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { startDate, endDate } = usePlanningDateRange();
  const diff = endDate.diff(startDate, 'days').toObject().days ?? 0;
  const visibleDays = Array(diff + 1)
    .fill(null)
    .map((_, i) => startDate.plus({ days: i }).toISO());

  return <Provider value={{ visibleDays: visibleDays }}>{children}</Provider>;
};

export { VisibleDaysProvider, useVisibleDaysProvider };
