import { shiftClaimService } from 'hq/services';
import { useMutation } from 'react-query';

type UndoNoShowMutationData = { shiftClaimId: string; shiftId?: string };

export const useHqUndoNoShow = () => {
  const mutationFn = async (shiftClaimData: UndoNoShowMutationData) =>
    await shiftClaimService.undoNoShow(shiftClaimData.shiftClaimId);

  return useMutation(mutationFn);
};
