import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { flexPoolService } from 'ogp/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { OGPUpdateFlexPoolPayload } from '../../../../../../services/flex-pool-service.types';

type FlexPoolMutationOptions = UseMutationOptions<unknown, unknown, OGPUpdateFlexPoolPayload>;

const useUpdateFlexPool = (flexPoolId: string, options?: FlexPoolMutationOptions) => {
  const mutationFn = async (data: OGPUpdateFlexPoolPayload) =>
    await flexPoolService.updateFlexPool(flexPoolId, data);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: FlexPoolMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.flexPoolList());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.flexPool(flexPoolId));
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};

export { useUpdateFlexPool };
