import { flexPoolAlerts as alerts, useAlert } from 'shared/components/alerts';
import { useCompanyRelativePath } from 'ogp/hooks';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { routes } from 'ogp/Routes';
import { RenderUnsettledUI } from 'shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { invariant } from 'shared/utils/utils';
import { Header, LwButton, Page, Toolbox, useFormButtons } from 'redesign';
import { useNavigate } from 'react-router-dom';
import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { useGetFlexPool, useUpdateFlexPool } from './hooks';
import { FlexPoolForm } from '../shared/components';
import { OGPUpdateFlexPoolPayload } from '../../../../../services/flex-pool-service.types';
const { queryParams } = routes.FlexPoolEdit;

const FlexPoolEditDataProvider = () => {
  const queryString = useQueryParams();
  const flexPoolId = queryString.get(queryParams.FLEXPOOL_ID);
  invariant(flexPoolId, 'Flexpool ID should be defined');
  const { data: flexPoolData, status, error, refetch } = useGetFlexPool(flexPoolId);
  const jobQuery = useGetJobs(flexPoolData?.departmentId, flexPoolData != undefined);

  if (status !== 'success') {
    return <RenderUnsettledUI data={{ status, error, refetch }} />;
  }

  if (jobQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobQuery} />;
  }

  const { jobIds } = flexPoolData;
  const defaultJobs = jobIds.map((jobId) => ({
    id: jobId,
    name: jobQuery.data.find((i) => i.id === jobId)?.name ?? '',
  }));

  const defaultValues: OGPUpdateFlexPoolPayload = {
    departmentId: flexPoolData.departmentId,
    description: flexPoolData.description,
    name: flexPoolData.name,
    jobs: defaultJobs,
  };

  return <FlexPoolEdit defaultValues={defaultValues} />;
};

const FlexPoolEdit = ({ defaultValues }: { defaultValues: OGPUpdateFlexPoolPayload }) => {
  const navigate = useNavigate();
  const generatePath = useCompanyRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const queryString = useQueryParams();
  const flexPoolId = queryString.get(queryParams.FLEXPOOL_ID);
  invariant(flexPoolId, 'Flexpool ID should be defined');
  const formMethods = useForm<OGPUpdateFlexPoolPayload>({
    defaultValues,
  });
  const mutation = useUpdateFlexPool(flexPoolId, {
    onSuccess: () => {
      alertSuccess(alerts.success.updated);
      navigate(generatePath(routes.FlexPools));
    },
    onError: (e) => alertError(e),
  });
  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutation.status);

  // TODO why is there no flexpool detail route?
  const handleCancel = () => {
    navigate(`${generatePath(routes.FlexPools)}?${[queryParams.FLEXPOOL_ID]}=${flexPoolId}`);
  };

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => mutation.mutate(data))();
  };

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Flexpool aanpassen" />}
        toolbox={
          <Toolbox underline>
            <LwButton onClick={handleSubmit} iconColorMode="stroke" {...submitButtonProps}>
              Bewaar
            </LwButton>
            <LwButton onClick={handleCancel} iconColorMode="fill" {...cancelButtonProps}>
              Annuleer
            </LwButton>
          </Toolbox>
        }
      >
        <FlexPoolForm state="update" />
      </Page>
    </FormProvider>
  );
};

export { FlexPoolEditDataProvider };
