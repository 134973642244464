import { Box, Grid, Typography } from '@mui/material';
import { IconUser } from 'assets/img';
import { createBlock, LwBlock, useOnScrollDown } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { useEffect, useRef } from 'react';
import { useGetPlannerWorkers } from '../hooks/use-get-planner-workers';

export const PartnerWorkerList = ({
  filter,
  openWorkerDetail,
}: {
  filter: string;
  openWorkerDetail: (workerId: string | undefined) => void;
}) => {
  const workerQuery = useGetPlannerWorkers(filter);
  const containerRef = useRef<HTMLDivElement>(null);

  const blocks =
    workerQuery.data?.pages.flatMap((page) => page.items).map((block) => createBlock(block)) ?? [];

  const { fetchIfBottomScrolled: onScroll } = useOnScrollDown({
    infiniteQueryResult: workerQuery,
    flatData: blocks,
  });

  useEffect(() => {
    if (onScroll) {
      onScroll(containerRef.current);
    }
  }, [containerRef.current]);

  if (workerQuery.status !== 'success') {
    return <RenderUnsettledUI data={workerQuery} />;
  }

  if (blocks.length === 0) {
    return <Typography>Er zijn geen gebruikers die aan je filter voldoen.</Typography>;
  }

  return (
    <Box
      data-testid={'partner-worker-list'}
      height="90%"
      overflow="auto"
      width="100%"
      sx={{ marginBottom: '32px' }}
      ref={containerRef}
      onScroll={(e) => {
        if (!onScroll) {
          return;
        }
        onScroll(e.target as HTMLDivElement);
      }}
    >
      <Grid container spacing={4} data-testid={`partner-worker-container`}>
        {blocks.map((block) => (
          <Grid item xs={6} md={4} lg={3} key={block.id}>
            <Box
              onClick={() => openWorkerDetail(block.id)}
              data-testid={`partner-worker-${block.id}`}
            >
              <LwBlock
                icon={<IconUser width={24} height={24} />}
                id={block.id}
                severity={block.type?.severity}
                title={block.title}
                subtitle={block.subtitle}
                dataBlocks={block.dataBlocks}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
