import { Box, Link, styled, Typography } from '@mui/material';
import { IconApproved } from 'assets/img';
import { GetPartnerInboxMessageResponse } from 'partner/shared/services/partner-inbox-service.types';
import { useCallback } from 'react';
import { LwButton } from 'redesign';
import { useAlert } from '../../../../../shared/components/alerts';
import { useResolveMessageTask } from '../hooks/use-resolve-message-task';
import { useUnresolveMessageTask } from '../hooks/use-unresolve-message-task';

export const MessageDetailDescription = ({
  data,
  close,
}: {
  data: GetPartnerInboxMessageResponse & { id: string };
  close: () => void;
}) => {
  const { alertError } = useAlert();
  const mutationResolve = useResolveMessageTask();
  const mutationUnresolve = useUnresolveMessageTask();

  const handleTaskClick = useCallback(() => {
    if (data.isTaskDone) {
      mutationUnresolve.mutate(
        { messageId: data.id },
        { onSuccess: close, onError: (e) => alertError(e) }
      );
    } else {
      mutationResolve.mutate(
        { messageId: data.id },
        { onSuccess: close, onError: (e) => alertError(e) }
      );
    }
  }, []);

  return (
    <DescriptionContainer>
      <Box>
        <Typography mt={10} mb={3} variant="h6">
          Bericht omschrijving
        </Typography>
        <Typography style={{ whiteSpace: 'pre-line' }} mt={6} variant="body1">
          {data.messageDescription}
        </Typography>
        <Typography mt={10} variant="h6">
          Task
        </Typography>
        <Typography mt={4} variant="body1">
          {data.taskDescription}
        </Typography>
        {data.link ? <LinkItem link={data.link} /> : null}
        <StyledLwButton
          startIcon={<TaskIcon />}
          iconColorMode="stroke"
          color="primary"
          type="button"
          onClick={handleTaskClick}
        >
          Markeer als {data.isTaskDone ? 'Nog te doen' : 'Voltooid'}
        </StyledLwButton>
      </Box>
    </DescriptionContainer>
  );
};

const LinkItem = ({ link }: { link: string }) => (
  <>
    <Link href={link} target="_blank" rel="noreferrer" underline="hover">
      <Typography mt={10} mb={3} variant="h6">
        Link
      </Typography>
    </Link>
  </>
);

const DescriptionContainer = styled(Box)(() => ({
  display: 'flex',
  overflow: 'scroll',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const TaskIcon = styled(IconApproved)(() => ({
  flexShrink: 0,
  width: '20px',
  height: '20px',
}));

const StyledLwButton = styled(LwButton)(() => ({
  marginTop: '20px',
}));
