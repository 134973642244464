import { Box } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components';
import { useGetNotWelcomeList } from './hooks/use-get-not-welcome-list';
import { NotWelcomeListTable } from './not-welcome-list-table';

const CompanyDetailNotWelcomeList = () => {
  const notWelcomeFlexWorkers = useGetNotWelcomeList();

  if (notWelcomeFlexWorkers.status !== 'success') {
    return <RenderUnsettledUI data={notWelcomeFlexWorkers} />;
  }

  return (
    <>
      <Box role="tabpanel">
        <NotWelcomeListTable notWelcomeList={notWelcomeFlexWorkers.data} />
      </Box>
    </>
  );
};

export { CompanyDetailNotWelcomeList };
