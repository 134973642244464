import React, { useCallback, useMemo } from 'react';
import { Drawer } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { getShiftPlanningFiltersDefaultValues } from './utils/get-shift-planning-default-values';
import {
  PartnerShiftPlanningFiltersDataProps,
  PartnerShiftPlanningFiltersFormType,
} from './shift-planning-filters.types';
import { FilterFromDate } from '../../../../../shared/components/filters/inputs/date/filter-from-date';
import { FilterBusinessUnitsWithCompany } from '../../../../../shared/components/filters/inputs/business-units-with-company/filter-business-units-with-company';
import { FilterJobTypes } from '../../../../../shared/components/filters/inputs/job-types/filter-job-types';
import { FilterWrapper } from '../../../../../shared/components/filters/filter-wrapper';

export const ShiftPlanningFilters = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
  filterData,
}: PartnerShiftPlanningFiltersDataProps) => {
  const defaultFilterValues = useMemo(() => {
    return getShiftPlanningFiltersDefaultValues(appliedFilters, filterData);
  }, [appliedFilters, filterData]);

  const form = useForm<PartnerShiftPlanningFiltersFormType>({
    defaultValues: { ...defaultFilterValues },
  });

  const dateFilterMinDate = DateTime.now().toFormat('yyyy-MM-dd');

  const handleClear = useCallback(() => {
    form.reset({
      fromDate: defaultFilterValues.fromDate,
      fromDateCheckboxValue: false,
      businessUnitCheckboxValue: false,
      company: null,
      branchOffice: null,
      department: null,
      filledStatusCheckboxValue: false,
      filledStatus: null,
      jobTypeCheckboxValue: false,
      jobType: [],
      partnersCheckboxValue: false,
      partner: null,
    });
  }, [defaultFilterValues, form]);

  const handleCancel = () => {
    toggleFilters();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      form.handleSubmit((data) => {
        const dataToSubmit = {
          fromDate:
            data.fromDateCheckboxValue && data.fromDate
              ? DateTime.fromFormat(data.fromDate, 'yyyy-MM-dd')
              : undefined,
          company: data.businessUnitCheckboxValue ? data.company?.value : undefined,
          branchOffice: data.businessUnitCheckboxValue ? data.branchOffice?.value : undefined,
          filledStatus:
            data.filledStatusCheckboxValue && data.filledStatus !== null
              ? data.filledStatus
              : undefined,
          jobType:
            data.jobTypeCheckboxValue && data.jobType?.length
              ? data.jobType.map((jobTypeSingle) => jobTypeSingle.id)
              : undefined,
          department: data.businessUnitCheckboxValue ? data.department?.value : undefined,
        };

        handleFiltersChange(dataToSubmit);
        toggleFilters();
      })();
    },
    [form, handleFiltersChange, toggleFilters]
  );

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleCancel}>
      <FormProvider {...form}>
        <FilterWrapper
          filterName="Filter"
          onCancel={handleCancel}
          onClear={handleClear}
          onSubmit={handleSubmit}
        >
          <FilterFromDate minDate={dateFilterMinDate} />
          <FilterBusinessUnitsWithCompany companies={filterData.companies} />
          <FilterJobTypes jobTypes={filterData.jobTypes} />
        </FilterWrapper>
      </FormProvider>
    </Drawer>
  );
};
