import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { shiftClaimService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

export default function useSettleCheckouts() {
  const queryClient = useQueryClient();

  return useMutation(async (ids: string[]) => await shiftClaimService.settleCheckouts(ids), {
    onSuccess: async () => {
      // Wait for the refetch to be done so we have an updated screen after approving.
      await queryClient.invalidateQueries(QUERY_KEYS_OGP.checkouts());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.checkoutInboxFilters());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.statistics());
    },
  });
}
