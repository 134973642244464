import { OgpApiService } from 'ogp/services/ogp-api-service';

export class RatingService {
  constructor(private readonly ogApiService: OgpApiService) {}

  rateShiftClaim = async (ratingId: string, value: number) => {
    await this.ogApiService.update({
      path: `rating/${ratingId}`,
      body: {
        value,
      },
    });
  };
}
