import { Box, styled, Typography } from '@mui/material';

type TimeSeperatorProps = {
  times: string[];
};

export const TimesContainer = ({ times }: TimeSeperatorProps) => {
  return (
    <TimeWrapper>
      {times.map((time) => (
        <Separator key={time}>
          <NumberTypography>{time}</NumberTypography>
        </Separator>
      ))}
    </TimeWrapper>
  );
};

const TimeWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const Separator = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  display: 'flex',
  alignItems: 'flex-end',
  ':first-of-type': {
    alignItems: 'flex-start',
    border: 'none',
  },
  height: 0,
}));

const NumberTypography = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    position: 'relative',
    fontSize: '12px',
    lineHeight: '14px',
  },
}));
