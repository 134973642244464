import { Expense, ExpenseUnit, WorkerEmploymentType } from '@types';
import { keyBy } from 'lodash';
import { formatCurrencyCents } from './formatting';

type ExpenseConfig = {
  id: 'parking' | 'kilometerAllowance' | 'kilometerReimbursement';
  label: string;
  formLabel: string;
  unit: ExpenseUnit;
  employmentTypes: WorkerEmploymentType[];
};

export const expensesConfig: ExpenseConfig[] = [
  {
    id: 'parking',
    label: 'Parkeerkosten',
    formLabel: 'Parkeerkosten (€)',
    unit: ExpenseUnit.EURO_CENT,
    employmentTypes: ['FREELANCER', 'TEMPWORKER'],
  },
  {
    id: 'kilometerAllowance',
    label: 'Reiskosten',
    formLabel: 'Reiskosten (€)',
    unit: ExpenseUnit.EURO_CENT,
    employmentTypes: ['FREELANCER'],
  },
  {
    id: 'kilometerReimbursement',
    label: 'Kilometervergoeding',
    formLabel: 'Kilometervergoeding (km)',
    unit: ExpenseUnit.KILOMETER,
    employmentTypes: ['TEMPWORKER'],
  },
] as const;

export const expensesConfigById = keyBy(expensesConfig, 'id');

export const getExpenseConfigForEmploymentType = (
  employmentType: WorkerEmploymentType
): ExpenseConfig[] => {
  return expensesConfig.filter((config) => config.employmentTypes.includes(employmentType));
};

export const mapExpenseTypeToDescription = (
  expenseType: 'parking' | 'kilometerAllowance' | 'kilometerReimbursement'
): (typeof expensesConfig)[number]['label'] => {
  const expense = expensesConfig.find((config) => config.id === expenseType);
  if (expense) {
    return expense.label;
  }
  throw new Error('Invalid expense type');
};

const getExpenseUnit = (expenseUnit: ExpenseUnit) => {
  switch (expenseUnit) {
    case ExpenseUnit.EURO_CENT:
      return '€';
    case ExpenseUnit.KILOMETER:
      return 'km';
  }
};

export const mapExpenseToDisplayValue = (expense: {
  amount: number;
  unit: ExpenseUnit;
}): string => {
  switch (expense.unit) {
    case ExpenseUnit.EURO_CENT:
      return formatCurrencyCents(expense.amount);
    case ExpenseUnit.KILOMETER:
      return `${expense.amount} ${getExpenseUnit(expense.unit)}`;
  }
};

export const getExpenseFormValue = (expense: { amount: number; unit: ExpenseUnit }): string => {
  switch (expense.unit) {
    case ExpenseUnit.EURO_CENT:
      return (expense.amount / 100).toString();
    case ExpenseUnit.KILOMETER:
      return expense.amount.toString();
  }
};

export const getExpenseValueFromForm = (expense: { amount: string; unit: ExpenseUnit }): number => {
  const amount = parseFloat(expense.amount);
  if (isNaN(amount)) {
    return 0;
  }
  switch (expense.unit) {
    case ExpenseUnit.EURO_CENT:
      return amount * 100;
    case ExpenseUnit.KILOMETER:
      return amount;
  }
};

export const getExpenseWithNameAndValue = (
  expense: Pick<Expense, 'expenseType' | 'amount' | 'unit'>
) => {
  const expenseConfig = expensesConfigById[expense.expenseType];
  const name = expenseConfig.label;
  const value = mapExpenseToDisplayValue(expense);
  return `${name}: ${value}`;
};
