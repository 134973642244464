import { Table } from 'redesign';
import { FlexWorkerRelation } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { EmptyTableMessage } from 'ogp/components/common/empty-table-message';
import { usePopupProfile } from 'ogp/components/views/planning/hooks';
import { ReactNode } from 'react';
import { CancelledColumnDefinition, CancelledTableOptions } from './helpers/cancelled-table.types';
import { getCancelledColumnDefinition } from './helpers/get-cancelled-column-definition';
import { getCancelledTableData } from './helpers/get-cancelled-table-data';
import { ShiftApplicationCancelled, ShiftClaimCancelled } from './cancelled-table.types';

type Props = {
  cancelledCandidates: (ShiftApplicationCancelled | ShiftClaimCancelled)[];
  getActions: (candidate: ShiftApplicationCancelled | ShiftClaimCancelled) => ReactNode[];
};

function CancelledTable({ cancelledCandidates, getActions }: Props) {
  const columns = getCancelledColumnDefinition();
  const tableData = getCancelledTableData(cancelledCandidates);

  const { open: openPopupProfile, Element: PopupProfileElement } = usePopupProfile();
  const onProfileClick = (flexWorkerId: string, relation: FlexWorkerRelation) => {
    openPopupProfile(flexWorkerId, relation);
  };

  return cancelledCandidates.length === 0 ? (
    <EmptyTableMessage
      title="Er zijn geen annuleringen gevonden!"
      subtitle="Er zijn geen aanmeldingen voor deze shift geannuleerd."
    />
  ) : (
    <>
      {PopupProfileElement}
      <Table<CancelledColumnDefinition, CancelledTableOptions>
        stickyHeader={true}
        columns={columns}
        data={tableData}
        onProfileClick={onProfileClick}
        getRowActions={getActions}
      />
    </>
  );
}

export { CancelledTable };
