import { Column } from 'react-table';
import { ContextMenuButton, TableHeader } from 'redesign';
import { Typography } from '@mui/material';
import { PartnerUserCellProp, PartnerUserColumnDefinition } from './partner-user.types';

export const getPartnerUserColumnDefinition = (): Column<PartnerUserColumnDefinition>[] => {
  const userName: Column<PartnerUserColumnDefinition> = {
    Header: () => <TableHeader>Naam</TableHeader>,
    accessor: 'userName',
    Cell: UserNameCell,
  };
  const email: Column<PartnerUserColumnDefinition> = {
    Header: () => <TableHeader>Email</TableHeader>,
    accessor: 'email',
    Cell: EmailCell,
  };
  const telephone: Column<PartnerUserColumnDefinition> = {
    Header: <TableHeader>Telefoonnummer</TableHeader>,
    accessor: 'phoneNumber',
    Cell: PhoneNumberCell,
  };

  const actions: Column<PartnerUserColumnDefinition> = {
    accessor: 'actions',
    Header: '',
    Cell: ActionCell,
  };

  return [userName, email, telephone, actions];
};

const ActionCell = (cell: PartnerUserCellProp<'actions'>) => {
  const actions = cell.getRowActions(cell.value);

  if (actions.length === 0) {
    return null;
  }

  return (
    <ContextMenuButton menuId={`partner-user-actions-menu-${cell.value.userId}`}>
      {actions}
    </ContextMenuButton>
  );
};

const UserNameCell = (cell: PartnerUserCellProp<'userName'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const EmailCell = (cell: PartnerUserCellProp<'email'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};

const PhoneNumberCell = (cell: PartnerUserCellProp<'phoneNumber'>) => {
  return <Typography variant="subtitle2">{cell.value}</Typography>;
};
