import React from 'react';
import { RenderUnsettledUI } from 'shared/components';
import { ShiftPlanningFiltersDataProviderProps } from './shift-planning-filters.types';
import { ShiftPlanningFilters } from './shift-planning-filters';
import { useGetShiftPlanningPartnersData } from '../hooks/use-get-shift-planning-filters-data';
import {
  useGetWorkPlanningBusinessUnitsData,
  useGetWorkPlanningJobTypesData,
} from '../../common/hooks/use-get-work-planning-filters-data';

export const ShiftPlanningDataProvider = ({
  toggleFilters,
  handleFiltersChange,
  appliedFilters,
  isOpen,
}: ShiftPlanningFiltersDataProviderProps) => {
  const businessUnitsQuery = useGetWorkPlanningBusinessUnitsData();
  const jobTypeQuery = useGetWorkPlanningJobTypesData();
  const partnersQuery = useGetShiftPlanningPartnersData();

  if (businessUnitsQuery.status !== 'success') {
    return <RenderUnsettledUI data={businessUnitsQuery} />;
  }

  if (jobTypeQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobTypeQuery} />;
  }
  if (partnersQuery.status !== 'success') {
    return <RenderUnsettledUI data={partnersQuery} />;
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <ShiftPlanningFilters
      toggleFilters={toggleFilters}
      appliedFilters={appliedFilters}
      isOpen={isOpen}
      handleFiltersChange={handleFiltersChange}
      filterData={{
        companies: businessUnitsQuery.data.companies,
        jobTypes: jobTypeQuery.data.jobTypes,
        partners: partnersQuery.data?.partners,
      }}
    />
  );
};
