import { styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { FieldValues } from 'react-hook-form';
import { IconInfoRedesigned } from '../../../assets/img';
import { FormSelect } from '../select/form-select';
import { ControlledForm } from '../shared/controlled-form.types';

export type FormSelectGroupProps<ControlProps extends FieldValues> =
  ControlledForm<ControlProps> & {
    options: { label: string; value: string }[];
    disabled?: boolean;
    display?: 'grid' | 'flex';
    maxChecked?: number;
    label?: string;
    informationText?: string;
  };

export const FormSelectGroup = <T extends FieldValues>({
  label,
  name,
  informationText,
  control,
  options,
}: FormSelectGroupProps<T>) => {
  return (
    <>
      <LabelWrapper>
        {label ? <Typography variant="body1">{label}</Typography> : null}
        {informationText ? (
          <StyledTooltip title={informationText}>
            <div style={{ display: 'flex', alignItems: 'inherit' }}>
              <IconInfoRedesigned style={{ cursor: 'pointer' }} />
            </div>
          </StyledTooltip>
        ) : null}
      </LabelWrapper>
      <FormGroup aria-label={`${name} filter select`}>
        <FormSelect label="" options={options ?? []} name={name} control={control} />
      </FormGroup>
    </>
  );
};

const LabelWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '0.7em',
  alignItems: 'inherit',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#edf0f7',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#edf0f7',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#edf0f7',
    color: 'black',
    boxShadow: theme.shadows[5],
  },
}));
