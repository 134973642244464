import { ShiftPlanningFilters } from './use-shift-planning-page.types';
import { useExternalParams } from '../../../../../../shared/hooks/use-external-params';
import { FilledStatus } from '../../../../../../shared/components/filters/inputs/filled-status/filter-filled-status.types';

const initialValues = {
  fromDate: {
    initialValue: undefined,
    type: 'date',
  },
  company: {
    initialValue: undefined,
    type: 'string',
  },
  branchOffice: {
    initialValue: undefined,
    type: 'string',
  },
  department: {
    initialValue: undefined,
    type: 'string',
  },
  filledStatus: {
    initialValue: undefined,
    type: 'string',
  },
  jobType: {
    initialValue: undefined,
    type: 'array',
  },
} as const;

const useShiftPlanningPage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters: ShiftPlanningFilters = {
    fromDate: params.fromDate || undefined,
    company: params.company || undefined,
    branchOffice: params.branchOffice || undefined,
    department: params.department || undefined,
    filledStatus: (params.filledStatus as FilledStatus) || undefined,
    jobType: ((params.jobType || undefined) as string[]) ?? undefined,
  };
  const selectedFilterCount = Object.values(filters).filter((value) => {
    return value !== null && value !== undefined && value !== '';
  }).length;

  return {
    filters,
    setFilters: setParams,
    selectedFilterCount,
  };
};

export { useShiftPlanningPage };
