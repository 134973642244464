import { useToggle } from 'shared/hooks';
import { Header, Table, Toolbox, useOnScrollDown } from 'redesign';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { DEFAULT_PAGE_SIZE } from 'shared/utils/constants';
import { RenderUnsettledUI } from 'shared/components';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useGetShiftPlanningShifts } from './hooks/use-get-shift-planning-shifts';
import { getShiftPlanningTableData } from './utils/get-shift-planning-table-data';
import {
  INITIAL_PAGE,
  PartnerShiftPlanningColumnDefinition,
  PartnerShiftPlanningTableOptions,
} from './shift-planning-table.types';
import { getShiftPlanningColumnDefinition } from './utils/get-shift-planning-column-definition';
import { useShiftPlanningPage } from './hooks/use-shift-planning-page';
import { ShiftPlanningDataProvider } from '../filters/shift-planning-filters-data-provider';

const ShiftPlanningTable = ({
  openShiftDetail,
}: {
  openShiftDetail: (shiftId: string) => void;
}) => {
  const { filters: appliedFilters, setFilters, selectedFilterCount } = useShiftPlanningPage();

  const shiftsQuery = useGetShiftPlanningShifts({
    fromDate: appliedFilters.fromDate ?? undefined,
    company: appliedFilters.company ?? undefined,
    branchOffice: appliedFilters.branchOffice ?? undefined,
    department: appliedFilters.department ?? undefined,
    jobType: appliedFilters.jobType ?? undefined,
    filledStatus: appliedFilters.filledStatus ?? undefined,
    pageParam: INITIAL_PAGE,
    pageSizeParam: DEFAULT_PAGE_SIZE,
  });
  const { data } = shiftsQuery;
  const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);
  const tableData = useMemo(() => getShiftPlanningTableData(flatData), [flatData]);
  const columns = useMemo(() => getShiftPlanningColumnDefinition(), []);
  const { fetchIfBottomScrolled } = useOnScrollDown({
    infiniteQueryResult: shiftsQuery,
    flatData: flatData,
  });

  const { on: filtersOpen, toggle: toggleFilters } = useToggle();

  if (shiftsQuery.status !== 'success') {
    return <RenderUnsettledUI data={shiftsQuery} />;
  }
  return (
    <>
      <Header titleText="Shift Planning" />
      <Toolbox underline={false}>
        <Toolbox.PushRight>
          <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
        </Toolbox.PushRight>
      </Toolbox>
      <Box style={{ height: '80%' }}>
        <Table<PartnerShiftPlanningColumnDefinition, PartnerShiftPlanningTableOptions>
          stickyHeader={true}
          columns={columns}
          data={tableData}
          tableStyle={{ tableLayout: 'fixed' }}
          onScroll={fetchIfBottomScrolled}
          data-testid="shift-planning-virtual-table"
          openShiftDetail={openShiftDetail}
        />
      </Box>
      <ShiftPlanningDataProvider
        appliedFilters={appliedFilters}
        handleFiltersChange={setFilters}
        isOpen={filtersOpen}
        toggleFilters={toggleFilters}
      />
    </>
  );
};

export { ShiftPlanningTable };
