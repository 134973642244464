import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { ReactNode } from 'react';
import { ModalPanelTabType } from 'redesign';

export enum ShiftDetailTab {
  APPLICATIONS,
  TEAM,
  CANCELS,
  ADD,
  POOL,
  PARTNERS,
}

export type ShiftDetailProps = {
  close: () => void;
  shiftQueryResult: ShiftDetailResponse;
  options: ModalPanelTabType[];
  shiftDetailHeadActions: ReactNode;
  isArchiving: boolean;
};

export type ShiftDetailTabData = {
  id: ShiftDetailTab;
  tabLabel: React.ReactNode;
  element: React.ReactNode;
};
