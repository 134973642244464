import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';

type MutableUntilHoursField = {
  mutableUntilHours: number;
};

export const MutableUntilHoursInput = ({
  disabled,
  required,
}: {
  disabled: boolean;
  required: boolean;
}) => {
  const { control } = useFormContext<MutableUntilHoursField>();

  return (
    <LwFormNumberInput
      name="mutableUntilHours"
      label="Annuleringstermijn"
      placeholder="In uren"
      control={control}
      rules={{ required: required ? 'Voer een annuleringstermijn in' : undefined }}
      step={1}
      min={0}
      disabled={disabled}
    />
  );
};
