import { partnerUserAlerts, useAlert } from 'shared/components/alerts';
import { useState } from 'react';
import { useConfirm } from 'shared/hooks';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useNavigate } from 'react-router-dom';
import { hqRoutes } from 'hq/Routes';
import { useDeletePartnerUser } from './use-delete-partner-user';
import { useHqRelativePath } from '../../../../hooks/useHqRelativePath';

const useArchivePartnerUserDialog = (partnerId: string) => {
  const [partnerUserId, setPartnerUserId] = useState<string>('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const archivePartnerUser = useDeletePartnerUser();

  function handleArchivePartnerUser() {
    archivePartnerUser.mutate(partnerUserId, {
      onSuccess: () => {
        dialog.close();
        queryClient.invalidateQueries(QUERY_KEYS_HQ.partnerUsers(partnerId));
        alertSuccess(partnerUserAlerts.success.deleted);
        navigate(generatePath(hqRoutes.PartnerUsers, { id: partnerId }));
      },
      onError: (e) => alertError(e),
    });
  }

  const dialog = useConfirm({
    title: 'Weet u zeker dat u deze partner gebruiker wilt archiveren?',
    positiveAction: { label: 'Ja', action: handleArchivePartnerUser },
    negativeAction: { label: 'Nee', autoFocus: true },
  });

  function open(id: string) {
    setPartnerUserId(id);
    dialog.open();
  }

  return { ...dialog, open };
};

export { useArchivePartnerUserDialog };
