import { DateTime } from 'luxon';

export const getWorkerAgeFromIso = (dateOfBirth: string | null | undefined): number | null => {
  const birthDateTime = dateOfBirth ? DateTime.fromISO(dateOfBirth) : DateTime.now();
  if (!birthDateTime.isValid) {
    return null;
  }
  return DateTime.now().diff(birthDateTime, 'years').years;
};

export const getWorkerAgeFromFormattedDate = (
  dateOfBirth: string | null | undefined
): number | null => {
  const birthDateTime = dateOfBirth
    ? DateTime.fromFormat(dateOfBirth, 'dd-MM-yyyy')
    : DateTime.now();
  if (!birthDateTime.isValid) {
    return null;
  }
  return DateTime.now().diff(birthDateTime, 'years').years;
};

export const isWorkerUnderMinumumAge = (workerAge: number | null, minimumAge: number): boolean => {
  return workerAge ? workerAge < minimumAge : false;
};
