import { LocationData, SkillType } from '@types';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { jobService as jobServiceHQ } from 'hq/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService as jobServiceOGP } from 'ogp/services';
import { useQuery } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

export type JobDetailsForWorkTemplate = {
  descriptionShort: string;
  descriptionLong: string;
  descriptionLawRequirements: string;
  contactName: string;
  contactPhoneNumber: string;
  location: LocationData;
  jobTypeCertificates: { certificate: { name: string; id: string } }[];
  jobCertificates: { certificate: { name: string; id: string } }[];
  jobRequirements: { id: string; name: string; type: SkillType }[];
};

export const useGetJobDetails = (jobId: string) => {
  const { isHQ } = useSiteDetection();

  let queryFn: () => Promise<JobDetailsForWorkTemplate>;

  isHQ
    ? (queryFn = async () => await jobServiceHQ.getJobDetailsForWorkTemplate(jobId))
    : (queryFn = async () => await jobServiceOGP.getJobDetailsForWorkTemplate(jobId));

  return useQuery(
    isHQ ? QUERY_KEYS_HQ.jobDetails(jobId) : QUERY_KEYS_OGP.jobDetails(jobId),
    queryFn,
    {
      enabled: !!jobId,
    }
  );
};
