import { Box, Divider, Typography } from '@mui/material';
import { useGetEnvironmentConfiguration } from 'shared/hooks/query-hooks/use-get-environment-configuration';
import { makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IconCheckmark, IconCross } from 'assets/img';
import { EnvironmentService } from 'shared/services';
import { useMemo } from 'react';

const ENV = EnvironmentService.getVariable('ENV');
const webappPlatformClient = EnvironmentService.getVariable('PLATFORM_CLIENT');

export const FeatureFlagsList = () => {
  const { data, status } = useGetEnvironmentConfiguration(ENV !== 'production');

  if (!data || status !== 'success') {
    return <></>;
  }

  const {
    backend: { featureFlags, platformClient: backendPlatformClient },
  } = data;

  let paymentFeatureFlags = {};
  let paymentPlatformClient = '';
  if (data.payment) {
    ({ platformClient: paymentPlatformClient, ...paymentFeatureFlags } = data.payment);
  }

  return (
    <Box width="100%" marginBottom={2} marginTop={2}>
      <EnvironmentViewGroup title={'WebApp'} platformClient={webappPlatformClient} />
      <EnvironmentViewGroup
        title={'Backend'}
        platformClient={backendPlatformClient}
        featureFlags={featureFlags}
      />
      <EnvironmentViewGroup
        title={'Payment'}
        platformClient={paymentPlatformClient}
        featureFlags={paymentFeatureFlags}
      />
      <Box mt={2}>
        <Divider />
      </Box>
    </Box>
  );
};

const EnvironmentViewGroup = ({
  featureFlags,
  platformClient,
  title,
}: {
  featureFlags?: Record<string, boolean>;
  platformClient?: string;
  title: string;
}) => {
  const styles = getStyles();

  const sortedFeatureFlags = useMemo(() => {
    const items = featureFlags
      ? Object.entries(featureFlags).map(([name, value]) => ({ name, value }))
      : [];
    return items.sort((a, b) => a.name.localeCompare(b.name));
  }, [featureFlags]);

  return (
    <Box className={styles.featureGroup} data-testid={title}>
      <Typography variant="subtitle1" marginBottom={4}>
        {title}
      </Typography>
      {platformClient ? (
        <Box className={styles.featureDetail} data-testid="value-wrapper">
          <Typography className={styles.featureText}>{'platformClient'}</Typography>
          <Box key={'platformClient'} className={styles.value}>
            <Typography className={styles.featureText}>{platformClient}</Typography>
          </Box>
        </Box>
      ) : null}
      {sortedFeatureFlags.map((featureFlag) => {
        const icon = featureFlag.value ? <StyledCheckmark /> : <StyledCross />;
        const value = featureFlag.value ? 'ON' : 'OFF';
        return (
          <Box key={featureFlag.name} className={styles.featureDetail} data-testid="value-wrapper">
            <Typography className={styles.featureText}>{featureFlag.name}</Typography>
            <Box key={featureFlag.name} className={styles.value}>
              <Typography className={styles.featureText}>{value}</Typography>
              {icon}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const StyledCheckmark = styled(IconCheckmark)(() => ({
  '& path': {
    stroke: 'rgb(0, 128, 0)',
  },
}));

const StyledCross = styled(IconCross)(() => ({
  '& path': {
    stroke: 'rgb(206, 17, 38)',
  },
}));

const getStyles = makeStyles((theme: Theme) => ({
  featureGroup: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  featureDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  featureText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
  },
  value: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));
