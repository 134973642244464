import { Box, IconButton, InputAdornment, styled } from '@mui/material';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CrossIcon from 'assets/img/icons/cross.svg?react';
import { AddButton, LwFormInput } from 'redesign';
import { CompaniesFilterParams, SetCompaniesFilterParams } from '../../companies.types';

type CompaniesFilter = {
  filter: string | null;
};

export const CompaniesFilterToolbox = ({
  params,
  setParams,
}: {
  params: CompaniesFilterParams;
  setParams: SetCompaniesFilterParams;
}) => {
  const form = useForm<CompaniesFilter>({
    defaultValues: {
      filter: params.filter ?? null,
    },
  });

  const [filter, setFilter] = useState<string | undefined>(params.filter);
  const debouncedFilter = useDebounce(filter, 300);

  useEffect(() => {
    setParams({ filter: debouncedFilter ?? undefined });
  }, [debouncedFilter]);

  return (
    <ToolboxWrapper>
      <Box flex={1}>
        <AddButton>Nieuw bedrijf aanmaken</AddButton>
      </Box>
      <Box flex={1}>
        <LwFormInput
          name="filter"
          control={form.control}
          label="Zoeken"
          placeholder="Bedrijfsnaam"
          onChange={(e) => {
            setFilter(e.target.value ?? undefined);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="veld leegmaken"
                  onClick={() => {
                    form.setValue('filter', null);
                    setFilter(undefined);
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <CrossIcon width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </ToolboxWrapper>
  );
};

const ToolboxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(6),
}));
