import { Box, styled, Typography } from '@mui/material';
import { translateDaysToDutch, WeekDays } from '../../../utils/date-utils';

export type DayBoxProps = {
  day: WeekDays;
};

const DayBox = ({ day }: DayBoxProps) => (
  <DayWrapper>
    <DayTypography>{translateDaysToDutch(day)}</DayTypography>
  </DayWrapper>
);

export { DayBox };

const DayTypography = styled(Typography)({
  fontSize: '20px',
});

const DayWrapper = styled(Box)(({ theme }) => ({
  alignContent: 'center',
  textAlign: 'center',
  width: '100%',
  padding: theme.spacing(1, 0),
  backgroundColor: theme.palette.grey[200],
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
  },
}));
