import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import errorImage from 'assets/img/whoops.png';

type Props = {
  error: Error;
  retry?: () => void;
};

/**
 * Smaller error component for in a Modal for example.
 *
 * @todo This is just the start. This component can use a lot of enhancements in the future
 * so it more flexible and can be used in more locations. Ideally we have just 1 error component instead of the 2 we have now.
 */
export function SmallError({ error, retry }: Props) {
  const styles = getStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <Typography variant="h2">Woops er ging iets mis.</Typography>
        <Typography variant="subtitle1">{error.name}</Typography>
        <Typography variant="caption">{error.message}</Typography>
        <img src={errorImage} alt="Error" className={styles.image} />
        {retry && (
          <Button className={styles.button} variant="contained" onClick={retry}>
            <Typography variant="h3">Opnieuw</Typography>
          </Button>
        )}
      </div>
    </div>
  );
}

const getStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(3),
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  image: {
    height: '240px',
    width: '240px',
    alignSelf: 'center',
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
}));
