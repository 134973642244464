import { flexWorkerService } from 'hq/services';
import { useQuery } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useGetFlexWorkerIdentityCheck = (flexWorkerId: string = '') => {
  const queryFn = async () => await flexWorkerService.getFlexWorkerIdCheck(flexWorkerId);

  const options = {
    enabled: flexWorkerId.length > 0,
  };

  return useQuery(QUERY_KEYS_HQ.flexWorkerIdCheck(flexWorkerId), queryFn, options);
};
