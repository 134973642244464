import { useMutation, UseMutationOptions } from 'react-query';
import { shiftClaimService } from 'ogp/services';
import { saveAs } from 'file-saver';
import { formatCsvExportDateTime } from 'shared/utils/formatting';
import { OGPShiftClaimExportPayload } from 'ogp/services/shift-claim-service.types';

type ShiftClaimExportMutationOptions = UseMutationOptions<
  Blob,
  unknown,
  OGPShiftClaimExportPayload
>;

export const useGetShiftClaimExport = (options?: ShiftClaimExportMutationOptions) => {
  const mutationFn = async (getShiftClaimsData: OGPShiftClaimExportPayload) =>
    await shiftClaimService.getShiftClaimsExport(getShiftClaimsData);

  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftClaimExportMutationOptions = {
    onSuccess: (data, ...restArg) => {
      const dateTimeString = formatCsvExportDateTime(new Date(Date.now()));
      saveAs(data, `Planning Export ${dateTimeString}.csv`);
      onSuccess && onSuccess(data, ...restArg);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
