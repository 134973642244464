import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Toolbox = ({
  children,
  underline,
  upperDivider = true,
  alignCenter = false,
}: React.PropsWithChildren<{
  underline?: boolean;
  upperDivider?: boolean;
  alignCenter?: boolean;
}>) => {
  const classes = getClasses();
  const DividerElement = <Divider light classes={{ light: classes.divider }} />;

  return (
    <Box marginBottom={4}>
      {upperDivider ? DividerElement : null}
      <Box
        paddingBottom={4}
        paddingTop={4}
        display="flex"
        justifyContent={alignCenter ? 'center' : 'flex-start'}
        alignItems={alignCenter ? 'center' : 'flex-start'}
        className={classes.toolbox}
      >
        {children}
      </Box>
      {underline ? DividerElement : null}
    </Box>
  );
};

const PushRight = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box ml="auto" display="flex" flexWrap="wrap" gap={8} position="relative">
    {children}
  </Box>
);

const getClasses = makeStyles((theme) => ({
  divider: {
    backgroundColor: 'rgba(0, 26, 153, 0.2)',
  },
  toolbox: {
    gap: theme.spacing(8),
  },
}));

Toolbox.PushRight = PushRight;

export { Toolbox };
