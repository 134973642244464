import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { Root } from './root';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0, // Disables caching by default. For some queries we opt-in to it in the config in the query hook.
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
        <Root />
      </BrowserRouter>
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
);
