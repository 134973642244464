import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { placementService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'shared/components/alerts';
import { FirstParamOfFn } from '@types';
import { getSettleCheckoutCopy } from '../../../shared';

const useSettlePlacementCheckout = () => {
  const queryClient = useQueryClient();
  const { alertError, alertSuccess } = useAlert();
  const mutation = useMutation(
    async (payload: FirstParamOfFn<typeof placementService.settleCheckouts>) =>
      await placementService.settleCheckouts(payload),
    {
      onSuccess: (result) => {
        const { getResultMessage } = getSettleCheckoutCopy(result);
        const message = getResultMessage(result.length);
        alertSuccess(message);
        queryClient.invalidateQueries(QUERY_KEYS_OGP.projectsCheckouts());
        queryClient.invalidateQueries(QUERY_KEYS_OGP.checkoutInboxProjectsFilters());
        queryClient.invalidateQueries(QUERY_KEYS_OGP.projectsCheckoutsArchive());
      },
      onError: (e) => {
        alertError(e);
      },
    }
  );

  return mutation;
};

export { useSettlePlacementCheckout };
