import { usePageProvider } from 'ogp/layouts';
import { errorTextWrapperClassName, LwFormSelect } from 'redesign';
import { useForm } from 'react-hook-form';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { useGetBranchOfficesAsSelect } from 'ogp/components/views/management/branch-offices/use-get-branch-offices';

const PLACEHOLDER_VALUE = 'value';
const PLACEHOLDER_LABEL = 'Toon alle vestigingen';
const PLACEHOLDER_OPTION = [
  {
    label: PLACEHOLDER_LABEL,
    value: PLACEHOLDER_VALUE,
  },
];

const BranchOfficeSelect = () => {
  const [{ filters }, { setBranchOfficeId }] = usePageProvider();
  const form = useForm({
    defaultValues: {
      branchOfficeId: filters.branchOfficeId ?? PLACEHOLDER_VALUE,
    },
  });
  const {
    data: branchOfficesWithNames,
    error,
    refetch,
    status,
  } = useGetBranchOfficesAsSelect({ includeArchived: false });

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setBranchOfficeId(e.target.value === 'value' ? undefined : e.target.value);
  };

  if (status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={{ error, status, refetch }}
      />
    );
  }

  return (
    <LwFormSelect
      sx={{
        [`& .${errorTextWrapperClassName}`]: {
          display: 'none',
        },
      }}
      onChange={handleSelect}
      options={[...PLACEHOLDER_OPTION, ...branchOfficesWithNames]}
      label=""
      name="branchOfficeId"
      control={form.control}
    />
  );
};

export { BranchOfficeSelect };
