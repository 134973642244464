import { LwButton, Toolbox, useFormButtons } from 'redesign';

type CreateToolboxProps = {
  disabledPrimaryButton?: boolean;
  mutationStatus: 'loading' | 'idle' | 'success' | 'error';
  onSubmit: () => void;
  onCancel: () => void;
};

export const ToolboxFormCreate = ({
  onSubmit,
  mutationStatus,
  onCancel,
  disabledPrimaryButton,
}: CreateToolboxProps) => {
  const { submitButtonProps, cancelButtonProps } = useFormButtons(mutationStatus);

  return (
    <Toolbox underline>
      <LwButton
        onClick={onSubmit}
        iconColorMode="stroke"
        {...submitButtonProps}
        disabled={disabledPrimaryButton}
      >
        Maak aan
      </LwButton>
      <LwButton iconColorMode="fill" {...cancelButtonProps} onClick={onCancel}>
        Annuleer
      </LwButton>
    </Toolbox>
  );
};
