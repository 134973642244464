import { Box } from '@mui/material';
import { FormCheckbox, FormRadioGroup } from 'redesign';
import { useFormContext } from 'react-hook-form';
import { FilterApplicantStatusFormContext } from './filter-applicant-status.types';

const items = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nee' },
];

export const FilterApplicantStatus = () => {
  const { watch, control } = useFormContext<FilterApplicantStatusFormContext>();
  const allowApplicantsFilter = watch('allowApplicantsFilter');

  return (
    <Box width={200}>
      <FormCheckbox name="allowApplicantsFilter" label="Heeft aanmeldingen" control={control} />
      {allowApplicantsFilter ? (
        <FormRadioGroup items={items} name="hasApplicants" control={control} />
      ) : null}
    </Box>
  );
};
