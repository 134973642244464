import { RouteDefinition } from 'Routes';
import { extendsType } from 'shared/utils/ts-utils';
import { PlanningLayout } from './layouts';
import { paths } from './paths';

// The master definition for the OGP routes.
// Each route has the shape of a RouteDefinition, but we can still read all route-specific data.

export const routes = extendsType<Record<string, RouteDefinition>>()({
  ...paths,
  FlexPools: {
    ...paths.FlexPools,
    layout: PlanningLayout,
  },
  Jobs: {
    ...paths.Jobs,
    layout: PlanningLayout,
  },
  JobsCreate: {
    ...paths.JobsCreate,
    layout: PlanningLayout,
  },
  JobsDuplicate: {
    ...paths.JobsDuplicate,
    layout: PlanningLayout,
  },
  JobsEdit: {
    ...paths.JobsEdit,
    layout: PlanningLayout,
  },
  JobsDetail: {
    ...paths.JobsDetail,
    layout: PlanningLayout,
  },
  Departments: {
    ...paths.Departments,
    layout: PlanningLayout,
  },
  DepartmentCreate: {
    ...paths.DepartmentCreate,
    layout: PlanningLayout,
  },
  DepartmentDetail: {
    ...paths.DepartmentDetail,
    layout: PlanningLayout,
  },
  DepartmentEdit: {
    ...paths.DepartmentEdit,
    layout: PlanningLayout,
  },
  DepartmentDuplicate: {
    ...paths.DepartmentDuplicate,
    layout: PlanningLayout,
  },
  Shifts: {
    ...paths.Shifts,
    layout: PlanningLayout,
  },
  Projects: {
    ...paths.Projects,
    layout: PlanningLayout,
  },
} as const);

export type RouteKeys = keyof typeof routes;
