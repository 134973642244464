import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { PartnerInformationEditFormData } from '../partner-information/partner-information.types';
import { partnerService } from '../../../../services';

type PartnerInformationMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  PartnerInformationEditFormData
>;

export const useEditPartnerInformation = (partnerId: string) => {
  const mutationFn = async (partnerInformation: PartnerInformationEditFormData) => {
    await partnerService.updatePartnerInformation(partnerId, partnerInformation);
  };

  const queryClient = useQueryClient();
  const options: PartnerInformationMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_HQ.partner(partnerId));
      queryClient.invalidateQueries(QUERY_KEYS_HQ.partners());
    },
  };

  return useMutation(mutationFn, options);
};
