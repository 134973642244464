import { RenderUnsettledUI } from 'shared/components';
import { plafromSettingsAlerts, useAlert } from 'shared/components/alerts';
import { useNavigate } from 'react-router-dom';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useGetPlatformSettings } from './hooks/use-get-platform-settings';
import { useUpdatePlatformSettings } from './hooks/use-update-platform-settings';
import {
  PlatformSettingsForm,
  PlatformSettingsFormData,
} from './components/platform-settings-form';
import { mapFormToPlatformSettings } from './helpers/map-settings-to-form';

export const UpdatePlatformSettingsPage = () => {
  const settingsQuery = useGetPlatformSettings();
  const mutation = useUpdatePlatformSettings();
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();

  if (settingsQuery.status !== 'success') {
    return <RenderUnsettledUI data={settingsQuery} />;
  }

  const handleSubmit = (data: Partial<PlatformSettingsFormData>) => {
    const updatePayload = mapFormToPlatformSettings(data);
    mutation.mutate(updatePayload, {
      onSuccess: () => {
        alertSuccess(plafromSettingsAlerts.success.updated);
        navigate(generatePath(hqRoutes.PlatformSettings));
      },
      onError: (e) => alertError(e),
    });
  };

  return <PlatformSettingsForm data={settingsQuery.data} onSubmit={handleSubmit} />;
};
