import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LwFormAutocomplete, LwFormInput } from 'redesign';
import { useState } from 'react';
import { useDebounce } from 'ogp/hooks/queryHooks/locations/use-debounce';
import { FlexWorkerForNotWelcomeList } from 'ogp/services/company-service.types';
import { RenderUnsettledUI } from 'shared/components';
import { useGetAvailableFlexWorkers } from './hooks/use-get-available-flex-workers';

const NotWelcomeListAddFlexWorker = () => {
  const { control, setValue } = useFormContext<{
    flexWorker: FlexWorkerForNotWelcomeList;
    reason: string;
  }>();

  const [input, setSelected] = useState<string>('');
  const debouncedInput = useDebounce(input, 300);
  const { data, isFetching, status, refetch, error } = useGetAvailableFlexWorkers(debouncedInput);

  if (status === 'error') {
    return <RenderUnsettledUI data={{ status, error, refetch }} />;
  }

  return (
    <>
      <Grid style={{ alignItems: 'end' }} container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <LwFormAutocomplete
            name="flexWorker"
            label="Flexwerker"
            getOptionLabel={(option) => option?.email ?? ''}
            getOptionKey={(option) => option.id ?? ''}
            isOptionEqualToValue={(option, value) => {
              return option?.email === value?.email;
            }}
            disableClearable={true}
            options={data ?? []}
            isLoading={isFetching}
            control={control}
            rules={{ required: true }}
            onChange={(_event, value) => {
              if (value) {
                setValue('flexWorker', value);
              }
            }}
            onInputChange={(_e, value) => {
              setSelected(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput control={control} label="Reden" name="reason" />
        </Grid>
      </Grid>
    </>
  );
};

export { NotWelcomeListAddFlexWorker };
