import { FlexPoolOnlyIndicator } from 'shared/components/common/flexpool-only-indicator';
import { FormMode, HelperTexts } from 'shared/components/shift-forms/components/forms/shared/types';
import { Control, useFormContext } from 'react-hook-form';
import { LwFormDate, LwFormNumberInput, LwFormSelect } from 'redesign';
import { InputSelectOption, JobListItem } from '@types';
import { getMinDate } from 'shared/utils/get-min-date';
import { getHelperTexts } from 'shared/components/shift-forms/components/forms/shared/get-helper-texts/get-helper-texts';
import { ListSubheader, MenuItem } from '@mui/material';
import { ProjectFormType } from '../types/project-form.types';
import { useGetJob } from '../../../hooks/query-hooks/use-get-job';

export type GetProjectFormInputsProps = {
  jobsData: JobListItem[];
  departments: InputSelectOption[];
  mode: FormMode;
  isProjectWithActivePlacement: boolean;
};

type ProjectFormInputs = React.ReactNode[];

export const useGetProjectFormInputs = ({
  jobsData,
  departments,
  mode,
  isProjectWithActivePlacement,
}: GetProjectFormInputsProps): ProjectFormInputs => {
  const { control, watch } = useFormContext<ProjectFormType>();
  const watchedDepartment = watch('departmentId');
  const watchedJobId = watch('jobId');
  const { data: jobData, isLoading: isSelectedJobLoading } = useGetJob(watchedJobId);
  const { minDate } = getMinDate({ allowDateBeforeToday: true });
  const helperTexts = getHelperTexts(jobData);

  const jobOptions = jobsData
    .filter((i) => i.departmentId === watchedDepartment)
    .map((job) => ({
      value: job.id,
      label: (
        <FlexPoolOnlyIndicator flexPoolOnly={job.flexPoolOnly}>{job.name}</FlexPoolOnlyIndicator>
      ),
    }));

  return [
    <DepartmentFormElement
      key="departmentId"
      control={control}
      disabled={mode !== 'create'}
      options={departments}
    />,
    <JobFormElement
      key="jobId"
      control={control}
      disabled={mode !== 'create' || !watchedDepartment}
      options={jobOptions}
    />,
    <StartDateElement
      key="startDate"
      control={control}
      disabled={isProjectWithActivePlacement}
      minDate={minDate}
    />,
    <EndDateElement key="endDate" control={control} disabled={isProjectWithActivePlacement} />,
    <HourlyRateElement
      key="hourlyRate"
      control={control}
      disabled={isSelectedJobLoading || isProjectWithActivePlacement}
      existingRate={jobData?.freelanceJob?.hourlyRateCents}
      helperTexts={helperTexts}
      employmentTypes={{
        isTemp: !!jobData?.tempWorkJob,
        isFreelance: !!jobData?.freelanceJob,
      }}
    />,
    <WeeklyHoursElement
      key="weeklyHours"
      control={control}
      disabled={isSelectedJobLoading}
      existingHours={undefined}
    />,
  ];
};
const DepartmentFormElement = ({
  control,
  options,
  disabled,
}: {
  control: Control<ProjectFormType>;
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  const { setValue } = useFormContext<ProjectFormType>();
  return (
    <LwFormSelect
      name="departmentId"
      label="Afdeling"
      defaultLabel="Selecteer een afdeling..."
      options={options}
      renderOption={(opt) => [
        <ListSubheader key={opt.value} value={opt.value}>
          <em>{opt.label}</em>
        </ListSubheader>,
        opt.options.map((dpt) => (
          <MenuItem key={dpt.value} value={dpt.value}>
            {dpt.label}
          </MenuItem>
        )),
      ]}
      disabled={disabled}
      control={control}
      rules={{ required: 'Selecteer een afdeling' }}
      onChange={() => {
        setValue('jobId', '');
      }}
    />
  );
};

const JobFormElement = ({
  control,
  options,
  disabled,
}: {
  control: Control<ProjectFormType>;
  options: React.ComponentProps<typeof LwFormSelect>['options'];
  disabled: React.ComponentProps<typeof LwFormSelect>['disabled'];
}) => {
  return (
    <LwFormSelect
      name="jobId"
      label="Functie"
      defaultLabel="Selecteer een functie..."
      options={options}
      disabled={disabled}
      control={control}
      data-testid="job-select"
      rules={{ required: 'Selecteer een functie' }}
    />
  );
};

const StartDateElement = ({
  control,
  minDate,
  disabled,
}: {
  control: Control<ProjectFormType>;
  minDate: string | undefined;
  disabled: boolean;
}) => {
  return (
    <LwFormDate
      name="startDate"
      label="Startdatum"
      control={control}
      rules={{ required: 'Voer een startdatum in' }}
      minDate={minDate}
      data-testid="start-date"
      disabled={disabled}
    />
  );
};

const EndDateElement = ({
  control,
  disabled,
}: {
  control: Control<ProjectFormType>;
  disabled: boolean;
}) => {
  const { watch } = useFormContext();
  const minDate = watch('min');
  return (
    <LwFormDate
      name="endDate"
      label="Einddatum"
      control={control}
      rules={{ required: 'Voer een einddatum in' }}
      minDate={minDate}
      data-testid="end-date"
      disabled={disabled}
    />
  );
};

const HourlyRateElement = ({
  control,
  disabled,
  existingRate,
  helperTexts,
  employmentTypes,
}: {
  control: Control<ProjectFormType>;
  disabled: boolean;
  existingRate: number | undefined;
  helperTexts: HelperTexts;
  employmentTypes: {
    isTemp: boolean;
    isFreelance: boolean;
  };
}) => {
  const tempLabel = 'Bruto Uurloon';
  const freelanceLabel = 'Uurtarief';
  const bothLabel = 'Uurtarief / Bruto Uurloon';

  const tempRequiredMessage = 'Voer bruto uurloon in';
  const freelanceRequiredMessage = 'Voer uurtarief in';
  const bothRequiredMessage = 'Voer uurtarief of bruto uurloon in';

  const label = employmentTypes.isTemp
    ? employmentTypes.isFreelance
      ? bothLabel
      : tempLabel
    : freelanceLabel;

  const required = employmentTypes.isTemp
    ? employmentTypes.isFreelance
      ? bothRequiredMessage
      : tempRequiredMessage
    : freelanceRequiredMessage;

  return (
    <LwFormNumberInput
      name="hourlyRate"
      label={label}
      control={control}
      rules={{ required }}
      step={0.01}
      min={0}
      placeholder={`${(existingRate || 0) / 100}`}
      helperText={helperTexts.hourlyRate}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};

const WeeklyHoursElement = ({
  control,
  disabled,
  existingHours,
}: {
  control: Control<ProjectFormType>;
  disabled: boolean;
  existingHours: number | undefined;
}) => {
  return (
    <LwFormNumberInput
      name="weeklyHours"
      label="Uren per week"
      control={control}
      rules={{ required: 'Voer uren per week in' }}
      step={0.01}
      min={0}
      placeholder={`${(existingHours || 0) / 100}`}
      data-testid="hourly-rate"
      disabled={disabled}
    />
  );
};
