import { Box, Grid } from '@mui/material';
import { RenderUnsettledUI } from 'shared/components/render-unsettled-ui';
import { useGetRequiredCertificates } from './hooks/use-get-required-certificates';

export const JobTypeRequiredCertificatesList = ({ jobTypeId }: { jobTypeId: string }) => {
  const certificatesQuery = useGetRequiredCertificates(jobTypeId);

  if (certificatesQuery.status !== 'success') {
    return <RenderUnsettledUI data={certificatesQuery} />;
  }

  const certificates = certificatesQuery.data;

  return (
    <Grid container>
      {certificates.items.length === 0 ? (
        <Grid item xs={12}>
          Voor de geselecteerde functiegroep zijn geen certificaten vereist
        </Grid>
      ) : (
        <Grid item xs={12}>
          {certificates.items.map((certificate) => {
            return <Box key={certificate.id}>{certificate.name}</Box>;
          })}
        </Grid>
      )}
    </Grid>
  );
};
