import { Box, TablePagination } from '@mui/material';
import { useEffect } from 'react';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
  PaginatedCollection,
  Pagination,
  PaginationResultSizes,
} from '@types';

type PaginationOrStateIndicatorProps = {
  data: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts;
  pagination: Pagination;
};

export const FlexWorkerPagination = ({ data, pagination }: PaginationOrStateIndicatorProps) => {
  useEffect(() => {
    // Sanitize the page.
    // If you settle the last checkouts when not at the first page you end up on an empty state and can't move back a page anymore.
    if (data.items.length === 0 && pagination.page !== 0) {
      pagination.setPage(0);
    }
  }, [data.items, pagination]);

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <TablePagination
        component="div"
        count={data.totalCount}
        page={pagination.page}
        onPageChange={(e, newPage) => {
          pagination.setPage(newPage);
        }}
        rowsPerPage={pagination.size}
        onRowsPerPageChange={(e) => {
          const progress = pagination.size * pagination.page;
          const newSize = parseInt(e.target.value, 10);
          pagination.setSize(newSize);
          pagination.setPage(Math.floor(progress / newSize)); // Calculate the new page so you keep looking at the same data.
        }}
        rowsPerPageOptions={PaginationResultSizes}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count}`}
        labelRowsPerPage="Resultaten per pagina"
      />
    </Box>
  );
};
