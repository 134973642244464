import { hqRoutes } from 'hq/Routes';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { useCallback } from 'react';

export function useHqGeneratePlannerPath() {
  const generatePath = useHqRelativePath();

  return useCallback(
    (queryParams?: { [key: string]: string | undefined }) => {
      return generatePath(hqRoutes.PlannerShift, undefined, queryParams);
    },
    [generatePath]
  );
}
