import { Box, Divider, styled, Typography } from '@mui/material';
import { getShiftTimespan } from '../checkout-create/utils/get-shift-timespan';

type Props = {
  shiftData: {
    flexWorkerName: string;
    flexWorkerPhoneNumber: string;
    flexWorkerEmail: string;
    jobName: string;
    companyName: string;
    departmentName: string;
    scheduledTime: {
      startDate: string;
      endDate: string;
      breakMinutes: number | null;
    };
  };
};

export const ShiftDetailsHeader = ({ shiftData }: Props) => {
  const shiftTimespan = getShiftTimespan(
    shiftData.scheduledTime.startDate,
    shiftData.scheduledTime.endDate
  );

  return (
    <>
      <HeaderInfoContainer>
        <HeaderColumn display="flex" flexDirection="column">
          <Typography noWrap variant="h6">
            {shiftData.flexWorkerName}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {shiftData.flexWorkerPhoneNumber}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {shiftData.flexWorkerEmail}
          </Typography>
        </HeaderColumn>
        <HeaderColumn display="flex" flexDirection="column">
          <Typography noWrap variant="h6">
            {shiftData.jobName}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {shiftData.companyName}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {shiftData.departmentName}
          </Typography>
        </HeaderColumn>
        <HeaderColumn display="flex" flexDirection="column">
          <Typography noWrap variant="subtitle2">
            Schedule
          </Typography>
          <Typography noWrap variant="body1">
            {shiftTimespan}
          </Typography>
          {shiftData.scheduledTime.breakMinutes ? (
            <Typography noWrap variant="body1">
              {`Pauze ${shiftData.scheduledTime.breakMinutes} min`}
            </Typography>
          ) : null}
        </HeaderColumn>
      </HeaderInfoContainer>
      <Divider />
    </>
  );
};

const HeaderInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: theme.spacing(20),
  marginBottom: theme.spacing(5),
}));

const HeaderColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));
