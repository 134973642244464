import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerPlacementService } from 'partner/shared/services';
import { useMutation, useQueryClient } from 'react-query';

type CancelShiftPlacementsParams = {
  shiftClaimId: string;
};

export const useCancelShiftPlacement = () => {
  const mutationFn = async ({ shiftClaimId }: CancelShiftPlacementsParams) =>
    await partnerPlacementService.cancelShiftPlacement(shiftClaimId);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.placements());
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftClaims());
      queryClient.invalidateQueries(QUERY_KEYS_PARTNER.shiftPlanning());
    },
  });
};
