import { departmentService } from 'ogp/services';
import { useMutation, UseMutationOptions } from 'react-query';

type DepartmentMutationOptions = UseMutationOptions<unknown, unknown, string>;

export const useArchiveDepartment = (mutationOptions?: DepartmentMutationOptions) => {
  const mutationFn = async (departmentId: string) =>
    await departmentService.archiveDepartment(departmentId);

  return useMutation(mutationFn, mutationOptions);
};
