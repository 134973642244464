import { HqApiService } from './hqApiService';
import { GetHqNotificationsResponseDto } from './notification-service.types';

export class HqNotificationService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getNotifications(): Promise<GetHqNotificationsResponseDto> {
    const response = await this.hqApiService.get<GetHqNotificationsResponseDto>({
      path: 'notifications',
    });
    return response;
  }
}
