import React from 'react';
import { useGetUserInfo } from 'hq/hooks/queryHooks/users/useGetUserInfo';
import { hasAccessWithinPolicyGroup, PolicyGroupAndRoles } from 'shared/utils/policy-controller';

type AuthenticatedElementProps = PolicyGroupAndRoles & { children: React.ReactElement };

const AuthenticatedElement = ({ roles, policyGroup, children }: AuthenticatedElementProps) => {
  const userQuery = useGetUserInfo();
  const userInfo = userQuery.data;

  if (userInfo === undefined || !hasAccessWithinPolicyGroup(policyGroup, userInfo, roles)) {
    return null;
  }

  return children;
};

export { AuthenticatedElement };
