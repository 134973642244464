import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { LwFormInput } from 'redesign';
import { HqUserRoleMatrix } from './user-role-matrix';
import { HqUserFormContext } from './user-create';
import { RoleMatrix } from '../../../../services/userService';

interface UserFormProps {
  state?: 'update' | 'create';
  roleMatrix: RoleMatrix[];
}

export const UserForm = ({ state, roleMatrix }: UserFormProps) => {
  const { control } = useFormContext<HqUserFormContext>();

  return (
    <form>
      <Grid container item xs={6} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" my={1}>
            Persoonlijke informatie
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="firstName"
            label="Voornaam"
            control={control}
            rules={{ required: 'Voer een voornaam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="lastName"
            label="Achternaam"
            control={control}
            rules={{ required: 'Voer een achternaam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="nickname"
            label="Publieke naam"
            control={control}
            rules={{ required: 'Voer een publieke naam in' }}
          />
        </Grid>
        <Grid item xs={12}>
          <LwFormInput
            name="email"
            label="Email"
            inputType="email"
            control={control}
            rules={{ required: 'Voer een email adres in' }}
            disabled={state === 'update'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" my={2}>
            HQ Rollen
          </Typography>
          <HqUserRoleMatrix roleMatrix={roleMatrix} disabled={false} />
        </Grid>
      </Grid>
    </form>
  );
};
