import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { companyService } from 'ogp/services';
import { useMutation, useQueryClient } from 'react-query';

export const useAddFlexWorkerToNotWelcomeList = () => {
  const mutationFn = async ({ flexWorkerId, reason }: { flexWorkerId: string; reason: string }) =>
    await companyService.addFlexWorkerToNotWelcomeList(flexWorkerId, reason);
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_OGP.notWelcomeList());
      queryClient.invalidateQueries(QUERY_KEYS_OGP.notWelcomeListAvailable());
    },
  });
};
