import { useMutation } from 'react-query';
import { shiftClaimService } from 'ogp/services';
import { saveAs } from 'file-saver';
import { formatCsvExportDateTime } from 'shared/utils/formatting';

export default function useGetCheckoutInboxExport() {
  return useMutation(async (ids: string[]) => await shiftClaimService.getCheckoutInboxExport(ids), {
    onSuccess: (data) => {
      const dateTimeString = formatCsvExportDateTime(new Date(Date.now()));
      saveAs(data, `Checkout Inbox Export ${dateTimeString}.csv`);
    },
  });
}
