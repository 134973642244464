import { useCompanyRelativePath } from 'ogp/hooks';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteDefinition } from 'Routes';
import { useQueryParams } from 'shared/hooks/useQueryParams';

type Sorting = {
  order: 'asc' | 'desc';
  sort: 'job' | 'date';
};

function reduceNewState(state: Sorting, key: Sorting['sort']): Sorting {
  if (state.sort !== key) {
    // Change sorting key.
    if (state.order === null) {
      return { sort: key, order: 'asc' };
    } else {
      return { ...state, sort: key };
    }
  } else {
    // Same key. Changing order.
    if (state.order === null) {
      return { ...state, order: 'asc' };
    } else if (state.order === 'asc') {
      return { ...state, order: 'desc' };
    } else {
      return { ...state, order: 'asc' };
    }
  }
}

type QueryParams = {
  SORT_KEY: string;
  SORT_ORDER: string;
};

/**
 * @deprecated
 * TODO migrate to useExternalParams
 */
export function useInboxSorting(route: RouteDefinition, queryParams: QueryParams) {
  const queryString = useQueryParams();
  const navigate = useNavigate();
  const updateSortingUrl = useUpdateSortingUrl(route, queryParams);

  const sortState = useMemo<Sorting>(() => {
    const defaultKey = queryString.get('sort');
    const defaultOrder = queryString.get('order');
    const sort = defaultKey === 'job' ? 'job' : defaultKey === 'date' ? 'date' : 'date';
    const order = defaultOrder === 'asc' ? 'asc' : defaultOrder === 'desc' ? 'desc' : 'asc';

    return { sort, order };
  }, [queryString]);

  const handleSortClick = useCallback(
    (id: string) => {
      if (id === 'job' || id === 'date') {
        const newState = reduceNewState(sortState, id);
        const path = updateSortingUrl(newState);
        navigate(path, { replace: true });
      }
    },
    [navigate, updateSortingUrl, sortState]
  );

  return [sortState, handleSortClick] as const;
}

function useUpdateSortingUrl(route: RouteDefinition, queryParams: QueryParams) {
  const location = useLocation();
  const generatePath = useCompanyRelativePath();

  return useCallback(
    ({ sort, order }: Sorting) => {
      const path = generatePath(route);
      const params = new URLSearchParams(location.search);

      if (sort === null) {
        params.delete(queryParams.SORT_KEY);
      } else {
        params.set(queryParams.SORT_KEY, sort);
      }

      if (order === null) {
        params.delete(queryParams.SORT_ORDER);
      } else {
        params.set(queryParams.SORT_ORDER, order);
      }

      params.sort();

      return `${path}?${params}`;
    },
    [generatePath, location.search, queryParams, route]
  );
}

export type { Sorting };
