import { DateTime } from 'luxon';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useMutation } from 'react-query';
import { Expense } from '@types';

export type ShiftClaimData = {
  shiftId: string;
  flexWorkerId: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  breakMinutes: number;
  expenses: Expense[];
};

export const useCreateShiftCheckout = () => {
  const mutationFn = async (createShiftClaimData: ShiftClaimData) => {
    const params = {
      shiftId: createShiftClaimData.shiftId,
      flexWorkerId: createShiftClaimData.flexWorkerId,
      endDate: DateTime.fromISO(
        createShiftClaimData.endDate + 'T' + createShiftClaimData.endTime
      ).toISO(),
      startDate: DateTime.fromISO(
        createShiftClaimData.startDate + 'T' + createShiftClaimData.startTime
      ).toISO(),
      breakMinutes: createShiftClaimData.breakMinutes,
      expenses: createShiftClaimData.expenses,
    };
    await partnerCheckoutsService.createCheckout(params);
  };

  return useMutation(mutationFn);
};
