import { Box, styled } from '@mui/material';
import { WorkTemplateJobDetails } from 'shared/components/shift-forms/components/job-details/work-template-job-details';

export const ProjectJobDetails = ({
  selectedJobId,
}: {
  selectedJobId: string;
  shiftId?: string;
}) => {
  return (
    <Disclaimer>
      <ScrollableContainer>
        {selectedJobId ? (
          <Box width={'100%'}>
            <WorkTemplateJobDetails jobId={selectedJobId} />
          </Box>
        ) : null}
      </ScrollableContainer>
    </Disclaimer>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
}));

const ScrollableContainer = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '22px',
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));
