import { shiftApplicationService } from 'hq/services';
import { useMutation } from 'react-query';

type ShiftApplicationMutationParams = {
  shiftApplicationId: string;
  shiftId: string;
  flexWorkerId: string;
};

export const useHqAcceptShiftApplication = () => {
  const mutationFn = async (params: ShiftApplicationMutationParams) =>
    await shiftApplicationService.acceptShiftApplication(
      params.shiftApplicationId,
      params.shiftId,
      params.flexWorkerId
    );

  return useMutation(mutationFn);
};
