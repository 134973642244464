import { useCallback, useState } from 'react';
import { createBlock, Header, LwButton, LWModal, Page, SearchBar, Toolbox } from 'redesign';
import { RenderUnsettledUI } from 'shared/components';
import { IconAdd } from 'assets/img';
import { Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetProjectsPlanning } from './hooks/use-get-projects-planning';
import { useProjectPlanningFilters } from './filters/hooks/use-project-planning-filters';
import { ProjectPlanningList } from './components/list/project-planning-list';
import { ProjectPlanningFiltersDataProvider } from './filters/projects-planning-filters';
import { useArchiveProject } from './hooks/use-archive-project';
import { ProjectDetail } from '../../../../../shared/components/planner/project-detail-modal/detail/project-detail';
import { FilterButton } from '../../administration/shared';
import { useToggle } from '../../../../../shared/hooks';
import { routes } from '../../../../../ogp/Routes';
import { useCompanyRelativePath } from '../../../../hooks';
import { useDialog } from '../../../../providers/dialog-provider';
import { stringifyFilters } from '../../../../../shared/utils/stringify-filters';

const ProjectsPlanning = () => {
  const [filter, setFilter] = useState('');
  const {
    filters: appliedFilters,
    setFilters,
    selectedFilterCount,
    projectId: id,
    setProjectId,
  } = useProjectPlanningFilters();
  const { on: filtersOpen, toggle: toggleFilters } = useToggle();
  const query = useGetProjectsPlanning({
    toDate: appliedFilters.toDate ?? undefined,
    branchOfficeId: appliedFilters.branchOfficeId ?? undefined,
    departmentId: appliedFilters.departmentId ?? undefined,
    jobType: appliedFilters.jobType ?? undefined,
    hasApplicants: appliedFilters.hasApplicants ?? undefined,
    filledStatus: appliedFilters.filledStatus ?? undefined,
    workerId: appliedFilters.workerId ?? undefined,
  });

  const handleCloseProjectDetail = useCallback(() => {
    setProjectId(undefined);
  }, [setProjectId]);

  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();
  const { openDialog, enableDialog, disableDialog, closeDialog } = useDialog();
  const archiveProject = useArchiveProject();

  const handleEdit = () => {
    navigate(generatePath(routes.ProjectsEdit, { id }));
  };

  const handleDuplicate = () => {
    navigate(generatePath(routes.ProjectsDuplicate, { id }));
  };

  const handleArchive = () => {
    openDialog(
      {
        title: 'Verwijdering van het project',
        content: 'Weet u zeker dat u dit project wilt verwijderen?',
      },
      () => {
        disableDialog();
        if (!id) {
          throw new Error('No project id');
        }
        archiveProject.mutate(id, {
          onSettled: enableDialog,
          onSuccess: () => {
            closeDialog();
            navigate(generatePath(routes.Projects, {}, stringifyFilters(appliedFilters)));
          },
        });
      }
    );
  };

  if (query.status !== 'success') {
    return <RenderUnsettledUI data={query} />;
  }

  const blocks = query.data.map((block) => createBlock(block));

  const filtered = blocks.filter((block) => {
    return block.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });

  return (
    <Page
      header={
        <Header
          titleText="Projectplanning"
          subheaderText="Hier zie je alle projectplanningen binnen jouw vestiging"
        />
      }
      toolbox={
        <Toolbox>
          <LwButton to="create" color="primary" startIcon={<IconAdd />} iconColorMode="fill">
            Nieuw project
          </LwButton>
          <SearchBar
            onChange={setFilter}
            initialValue={filter}
            placeholder="Filter projecten op naam"
          />
          <Toolbox.PushRight>
            <FilterButton onClick={toggleFilters} nrOfActiveFilters={selectedFilterCount} />
          </Toolbox.PushRight>
        </Toolbox>
      }
    >
      <ProjectPlanningList blocks={filtered} setProjectId={setProjectId} />
      <Drawer anchor="right" open={filtersOpen} onClose={toggleFilters}>
        <ProjectPlanningFiltersDataProvider
          handleFiltersChange={setFilters}
          handleClose={toggleFilters}
          appliedFilters={appliedFilters}
        />
      </Drawer>
      <LWModal isOpen={!!id} onClose={handleCloseProjectDetail} testId="project-detail">
        {id ? (
          <ProjectDetail
            id={id}
            close={handleCloseProjectDetail}
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleArchive={handleArchive}
          />
        ) : null}
      </LWModal>
    </Page>
  );
};

export { ProjectsPlanning };
