export const getAvailableTimeRange = ({
  startDate,
  endDate,
  startTime,
  endTime,
  minTime,
  minDate,
  allowDateBeforeToday,
}: {
  startDate: string;
  endDate: string;
  endTime: string;
  startTime: string;
  minDate: string;
  minTime: string | null;
  allowDateBeforeToday: boolean;
}) => {
  const areFormDatesEqual = endDate === startDate;
  const maxTimeStart = areFormDatesEqual ? endTime : '23:59';
  let minTimeStart = areFormDatesEqual ? '00:00' : endTime;
  const minTimeEnd = areFormDatesEqual ? startTime : '00:00';
  const maxTimeEnd = areFormDatesEqual ? '23:59' : startTime;

  if (allowDateBeforeToday) {
    return {
      minTimeStart,
      maxTimeStart,
      minTimeEnd,
      maxTimeEnd,
    };
  }

  const isCurrentDay = startDate === minDate;
  minTimeStart = isCurrentDay && minTime ? minTime : '00:00';

  return {
    minTimeStart,
    maxTimeStart,
    minTimeEnd,
    maxTimeEnd,
  };
};
