import { useGetFlexWorker } from 'hq/hooks/queryHooks/flexWorkers/useGetFlexWorker';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderUnsettledUI } from 'shared/components';
import { flexWorkerAlerts as alerts, useAlert } from 'shared/components/alerts';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { FlexWorkerData, FlexWorkerDataEdit } from '@types';
import { useGetEnvironmentConfiguration } from 'shared/hooks/query-hooks/use-get-environment-configuration';
import { PlatformSettings } from 'shared/services';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { FlexWorkerFormEdit } from './flexworker-form-edit';
import { IconApproved, IconCancel } from '../../../../../../assets/img';
import { useHqRelativePath } from '../../../../../hooks/useHqRelativePath';
import { invariant } from '../../../../../../shared/utils/utils';
import { useUpdateFlexWorker } from '../../../../../hooks/queryHooks/flexWorkers/useUpdateFlexWorker';
import { hqRoutes } from '../../../../../Routes';

export const FlexWorkerEdit = () => {
  const { id } = useParams<'id'>();
  invariant(id, 'Flex worker ID must be passed as a route param');
  const flexWorkerQuery = useGetFlexWorker(id);
  const environmentConfiguration = useGetEnvironmentConfiguration(true);

  if (flexWorkerQuery.status !== 'success') {
    return <RenderUnsettledUI data={flexWorkerQuery} />;
  }

  if (!environmentConfiguration.data) {
    return <RenderUnsettledUI data={environmentConfiguration} />;
  }

  return (
    <FlexWorkerEditImpl
      data={flexWorkerQuery.data}
      platformSettings={environmentConfiguration.data.backend.platformSettings}
    />
  );
};

const FlexWorkerEditImpl = ({
  data,
  platformSettings,
}: {
  data: FlexWorkerData;
  platformSettings: PlatformSettings;
}) => {
  const generatePath = useHqRelativePath();
  const { allowEmploymentId } = useFeatureFlagContext();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();

  const {
    firstName,
    lastName,
    infix,
    smallBusinessSchemeStartDate,
    smallBusinessSchemeEndDate,
    bsn,
    dateOfBirth,
    level,
    employmentId,
  } = data;

  const formattedSmallBusinessSchemeStartDate = smallBusinessSchemeStartDate
    ? DateTime.fromISO(smallBusinessSchemeStartDate).toFormat('yyyy-LL-dd')
    : undefined;

  const formattedSmallBusinessSchemeEndDate = smallBusinessSchemeEndDate
    ? DateTime.fromISO(smallBusinessSchemeEndDate).toFormat('yyyy-LL-dd')
    : undefined;

  const formattedDateOfBirth = dateOfBirth
    ? DateTime.fromISO(dateOfBirth).toFormat('dd-LL-yyyy')
    : undefined;

  const defaultValues: FlexWorkerDataEdit = {
    firstName,
    lastName,
    infix,
    bsn,
    smallBusinessSchemeStartDate: formattedSmallBusinessSchemeStartDate,
    smallBusinessSchemeEndDate: formattedSmallBusinessSchemeEndDate,
    dateOfBirth: formattedDateOfBirth,
    level,
    ...(allowEmploymentId ? { employmentId } : {}),
  };

  const methods = useForm<FlexWorkerDataEdit>({
    defaultValues,
  });

  const updateFlexWorker = useUpdateFlexWorker(data.id);

  const onSubmit = (formData: FlexWorkerDataEdit) => {
    const formattedDateOfBirth = formData.dateOfBirth
      ? DateTime.fromFormat(formData.dateOfBirth, 'dd-LL-yyyy').toISODate()
      : undefined;

    formData.dateOfBirth = formattedDateOfBirth;
    updateFlexWorker.mutate(formData, {
      onSuccess: () => {
        alertSuccess(alerts.success.updated);
        navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId: data.id }));
      },
      onError: (e) => alertError(e),
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Page
          header={<Header titleText="Flexwerker bewerken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Flexwerker bewerken
              </LwButton>
              <LwButton
                onClick={() =>
                  navigate(generatePath(hqRoutes.FlexWorkers, {}, { flexWorkerId: data.id }))
                }
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <FlexWorkerFormEdit flexWorkerData={data} platformSettings={platformSettings} />
        </Page>
      </form>
    </FormProvider>
  );
};
