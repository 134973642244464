import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { billingEntityService } from 'hq/services';
import { useQuery } from 'react-query';
import { billingEntitiesResponse2BillingEntitiesSelectModel } from 'shared/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof billingEntityService.getBillingEntities>>;

const useGetBillingEntities = <TData = Response>(
  companyId: string,
  select?: (data: Response) => TData
) => {
  const queryFn = async () => await billingEntityService.getBillingEntities(companyId);

  return useQuery(QUERY_KEYS_HQ.billingEntities(companyId), queryFn, {
    select,
    enabled: !!companyId,
  });
};

const useGetBillingEntitiesAsSelect = (companyId: string) => {
  const result = useGetBillingEntities(
    companyId,
    billingEntitiesResponse2BillingEntitiesSelectModel
  );
  return result;
};

export { useGetBillingEntitiesAsSelect };
