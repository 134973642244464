import { Box, CircularProgress, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';

type SmallLoaderProps = {
  show?: boolean;
};

export function SmallLoader({ show }: SmallLoaderProps) {
  const styles = getStyles();
  return (
    <Fade in={show ?? true}>
      <Box data-testid="loader" className={styles.loaderWrapper} borderRadius={24}>
        <CircularProgress size={48} />
      </Box>
    </Fade>
  );
}

const getStyles = makeStyles(() => {
  return {
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
    },
  };
});
