import { Box } from '@mui/material';
import { Header, Page, Toolbox } from 'redesign';
import { HqUserList } from './list/user-list';
import { HqUserToolbox } from './toolbox/user-toolbox';
import { useExternalParams } from '../../../../shared/hooks';

const initialValues = {
  user: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

export const HqUsers = () => {
  const [params, setParams] = useExternalParams(initialValues);

  return (
    <Page
      header={<Header titleText="Gebruikers" />}
      toolbox={
        <Toolbox>
          <HqUserToolbox search={params.user} setParams={setParams} />
        </Toolbox>
      }
    >
      <Box width="100%">
        <HqUserList search={params.user ?? ''} />
      </Box>
    </Page>
  );
};
