import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { useQuery } from 'react-query';
import { accreditationsService } from 'hq/services';
import { HQCertificateResponse } from '../../../../services/accreditations-service.types';

const useGetCertificateNames = () => {
  const queryFn = async () => await accreditationsService.getCertificates();

  return useQuery<HQCertificateResponse[], Error>(
    QUERY_KEYS_HQ.accreditationsCertificates(),
    queryFn
  );
};

export { useGetCertificateNames };
