import { Control, useFormContext } from 'react-hook-form';
import { LwFormDate, LwFormTime } from 'redesign';
import { areDateStringsEqual, compareDateStrings } from 'shared/utils/validate-date';
import { Grid } from '@mui/material';
import { getMinDate } from 'shared/utils/get-min-date';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { getAvailableTimeRange } from '../get-available-time-range';

type DateTimeFields = {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
};

export const ShiftDateTimeInputs = ({
  disabled,
  allowDateBeforeToday,
  allowDateAfterToday = true,
}: {
  disabled: boolean;
  allowDateBeforeToday: boolean;
  allowDateAfterToday?: boolean;
}) => {
  const { watch, setValue, control } = useFormContext<DateTimeFields>();
  const watchedStartDate = watch('startDate');
  const watchedEndDate = watch('endDate');
  const watchedStartTime = watch('startTime');
  const watchedEndTime = watch('endTime');

  const { minDate, minTime } = getMinDate({ allowDateBeforeToday });
  const { minTimeStart, minTimeEnd, maxTimeStart } = getAvailableTimeRange({
    startDate: watchedStartDate,
    endDate: watchedEndDate,
    startTime: watchedStartTime,
    endTime: watchedEndTime,
    minDate,
    minTime,
    allowDateBeforeToday,
  });
  useEffect(() => {
    if (!watchedStartDate) {
      return;
    }
    const endDate = watchedEndDate || DateTime.now().toISODate();

    if (!watchedEndDate || watchedStartDate > endDate) {
      setValue('endDate', watchedStartDate);
    }
  }, [watchedStartDate, watchedEndDate, setValue]);
  const maxDate = allowDateAfterToday ? undefined : DateTime.now().toISODate();

  return (
    <Grid spacing={4} container>
      <Grid item xs={6}>
        <StartDateElement
          control={control}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <EndDateElement control={control} minDate={minDate} maxDate={maxDate} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <StartTimeElement
          control={control}
          min={minTimeStart}
          max={maxTimeStart}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <EndTimeElement control={control} min={minTimeEnd} disabled={disabled} />
      </Grid>
    </Grid>
  );
};

const StartDateElement = ({
  control,
  minDate,
  maxDate,
  disabled,
}: {
  control: Control<DateTimeFields>;
  minDate: string;
  maxDate: string | undefined;
  disabled: boolean;
}) => {
  return (
    <LwFormDate
      name="startDate"
      label="Startdatum"
      control={control}
      rules={{
        required: 'Voer een startdatum in',
        validate: {
          isAfterMinDate: (v) =>
            compareDateStrings(minDate, v.toString()) ||
            areDateStringsEqual(minDate, v.toString()) ||
            'Niet voor de huidige datum',
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      data-testid="start-date"
      disabled={disabled}
    />
  );
};

const EndDateElement = ({
  control,
  minDate,
  maxDate,
  disabled,
}: {
  control: Control<DateTimeFields>;
  minDate: string;
  maxDate: string | undefined;
  disabled: boolean;
}) => {
  return (
    <LwFormDate
      name="endDate"
      label="Einddatum"
      control={control}
      rules={{
        required: 'Voer een einddatum in',
        validate: {
          isAfterMinDate: (v) =>
            compareDateStrings(minDate, v.toString()) ||
            areDateStringsEqual(minDate, v.toString()) ||
            'Niet voor de huidige datum',
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      data-testid="end-date"
      disabled={disabled}
    />
  );
};

const StartTimeElement = ({
  control,
  min,
  max,
  disabled,
}: {
  control: Control<DateTimeFields>;
  min: string;
  max: string | undefined;
  disabled: boolean;
}) => {
  return (
    <LwFormTime
      name="startTime"
      label="Van"
      control={control}
      rules={{
        required: 'Voer een starttijd in',
        validate: {
          isAfterMinTime: (v) =>
            compareDateStrings(min, v.toString()) ||
            areDateStringsEqual(min, v.toString()) ||
            'Mag niet vroeger dan ' + min,
        },
      }}
      min={min}
      max={max}
      data-testid="start-time"
      disabled={disabled}
    />
  );
};

const EndTimeElement = ({
  control,
  min,
  disabled,
}: {
  control: Control<DateTimeFields>;
  min: string;
  disabled: boolean;
}) => {
  return (
    <LwFormTime
      name="endTime"
      label="Tot"
      control={control}
      rules={{
        required: 'Voer een eindtijd in',
        validate: {
          isAfterMinTime: (v) =>
            compareDateStrings(min, v.toString()) ||
            areDateStringsEqual(min, v.toString()) ||
            'Mag niet vroeger dan ' + min,
        },
      }}
      min={min}
      data-testid="end-time"
      disabled={disabled}
    />
  );
};
