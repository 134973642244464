import { Box, Typography } from '@mui/material';

type Props = { title: string; subtitle: string };

export const EmptyTableMessage = ({ title, subtitle }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      gap={2}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography>{subtitle}</Typography>
    </Box>
  );
};
