import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerCheckoutsService } from 'partner/shared/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG, STALE_TIME_LONG } from 'shared/react-query/query-defaults';

export const useGetBreakOptions = () => {
  return useQuery(QUERY_KEYS_PARTNER.breakOptions(), {
    queryFn: async () => await partnerCheckoutsService.getBreakOptions(),
    cacheTime: CACHE_TIME_LONG,
    staleTime: STALE_TIME_LONG,
  });
};
