import { LwButton } from 'redesign';
import { CheckoutTypeToggle } from 'ogp/components/views/administration/checkouts/checkouts-type-provider';
import { FilterButton } from 'ogp/components/views/administration/shared';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback } from 'react';

type InboxTableActionsProps = {
  selectedClaimIds: string[];
  onOpenExport: (ids: string[]) => void;
  onOpenSettle: (ids: string[]) => void;
  toggleDrawer: () => void;
  nrOfActiveFilters: number;
};
export const InboxTableActions = ({
  selectedClaimIds,
  onOpenExport,
  onOpenSettle,
  toggleDrawer,
  nrOfActiveFilters,
}: InboxTableActionsProps) => {
  const classes = getClasses();

  const checkoutInboxExportCallback = useCallback(
    (ids: string[]) => onOpenExport(ids),
    [onOpenExport]
  );

  return (
    <>
      <Box
        className={clsx(classes.selectionButtons, { disabled: selectedClaimIds.length === 0 })}
        marginBottom={1}
        gap={8}
      >
        <LwButton
          disabled={selectedClaimIds.length === 0}
          onClick={() => onOpenSettle(selectedClaimIds)}
          color="primary"
        >
          Akkoord
        </LwButton>
        <LwButton
          disabled={selectedClaimIds.length === 0}
          onClick={() => checkoutInboxExportCallback(selectedClaimIds)}
          color="primary"
        >
          Exporteer
        </LwButton>
        <CheckoutTypeToggle />
        <FilterButton onClick={toggleDrawer} nrOfActiveFilters={nrOfActiveFilters} />
      </Box>
    </>
  );
};

const getClasses = makeStyles(({ spacing }) => ({
  buttonText: {
    textDecoration: 'underline',
    color: 'black',
  },
  selectionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: spacing(4),
  },
}));
