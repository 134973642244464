import { GetPartnerUsersResponse } from 'hq/services/partner-user-service.types';
import { PartnerUserColumnDefinition } from './partner-user.types';

export const getPartnerUserTableData = (
  data: GetPartnerUsersResponse
): PartnerUserColumnDefinition[] => {
  return data.map((user) => ({
    userName: user.fullName,
    email: user.email,
    phoneNumber: user.phone,
    actions: {
      userId: user.id,
    },
  }));
};
