import React from 'react';
import { LwBlock } from '../block';
import {
  HeaderBlock,
  IdBlock,
  NumericValueBlock,
  PrebuiltBlock,
  StringValueBlock,
  SubheaderBlock,
  TypeBlock,
} from '../block.types';

const createBlock = (
  block: PrebuiltBlock
): Omit<React.ComponentProps<typeof LwBlock>, 'severity'> & { type: TypeBlock | null } => {
  const idBlock = getSpecificBlock(block, 'id');
  const headerBlock = getSpecificBlock(block, 'header');
  const subheaderBlock = getSpecificBlock(block, 'subheader');
  const typeBlock = getSpecificBlock(block, 'type');
  const dataBlocks = getDataBlocks(block);

  return {
    id: getResultValue(idBlock) || undefined,
    title: getResultValue(headerBlock),
    subtitle: getResultValue(subheaderBlock),
    type: typeBlock,
    dataBlocks,
  };
};

const getResultValue = (block: IdBlock | HeaderBlock | SubheaderBlock | null) => {
  return block?.value ?? '';
};

function getSpecificBlock(block: PrebuiltBlock, type: TypeBlock['type']): TypeBlock;
function getSpecificBlock(block: PrebuiltBlock, type: HeaderBlock['type']): HeaderBlock;
function getSpecificBlock(block: PrebuiltBlock, type: IdBlock['type']): IdBlock;
function getSpecificBlock(
  block: PrebuiltBlock,
  type: SubheaderBlock['type']
): SubheaderBlock | null;
function getSpecificBlock(
  block: PrebuiltBlock,
  type: (HeaderBlock | IdBlock | SubheaderBlock | TypeBlock)['type']
): IdBlock | HeaderBlock | SubheaderBlock | TypeBlock | null {
  const index = block.findIndex((i) => i.type === type);
  if (index === -1) {
    return null;
  }
  return block[index] as IdBlock | HeaderBlock | SubheaderBlock | TypeBlock;
}

const getDataBlocks = (
  blocks: PrebuiltBlock
): React.ComponentProps<typeof LwBlock>['dataBlocks'] => {
  const valueBlocks = blocks.filter((i) => i.type === 'value') as (
    | NumericValueBlock
    | StringValueBlock
  )[];
  return valueBlocks.map((block) => ({
    format: block.format,
    value: block.value ?? '',
    label: block.label,
  }));
};

export { createBlock };
