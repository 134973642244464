import { useAlert } from 'shared/components/alerts';
import { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { LwButton, Toolbox } from 'redesign';
import { FileUploader } from './shared/file-uploader';
import { useUploadXML } from './hooks/use-upload-xml';
import { XMLUploadPayload } from '../../../services/admin-settings-service.types';

const XMLUploadForm = ({
  isCsvVisible,
  setIsCsvVisible,
}: {
  isCsvVisible: boolean;
  setIsCsvVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { mutate, status } = useUploadXML();
  const { alertSuccess, alertError } = useAlert();
  const { handleSubmit, setValue, watch, reset } = useForm<XMLUploadPayload>();
  const onSubmit = (payload: XMLUploadPayload) => {
    mutate(payload, {
      onSuccess: () => {
        alertSuccess('XML imported successfully');
        reset();
      },
      onError: (e) => alertError(e),
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setValue('file', file);
  };

  return (
    <>
      <Toolbox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" gap={4}>
            <Box display="flex" flexDirection="column">
              <FileUploader
                title="Upload XML"
                fileType=".xml"
                onChange={handleFileUpload}
                disabled={status === 'loading'}
              />
              <Box alignSelf={'center'}>{watch('file')?.name}</Box>
            </Box>
            <Box>
              <Box>
                <LwButton color="primary" type="submit" disabled={status === 'loading'}>
                  <Typography variant="button">Submit</Typography>
                </LwButton>
              </Box>
            </Box>
          </Box>
        </form>
        <Toolbox.PushRight>
          <LwButton onClick={() => setIsCsvVisible((prev) => !prev)}>
            Show {isCsvVisible ? 'XML Upload' : 'CSV Upload'}
          </LwButton>
        </Toolbox.PushRight>
      </Toolbox>
    </>
  );
};

export { XMLUploadForm };
