import { ContextMenuButton, ContextMenuItem, Table } from 'redesign';
import { IconApproved, IconCounterOffer } from 'assets/img';
import { ItemOf } from '@types';
import { useEffect, useMemo, useState } from 'react';
import {
  ProjectInboxTableProps,
  ProjectsInboxTableColumnDefinition,
  ProjectsInboxTableData,
  ProjectsInboxTableOptions,
} from './projects-inbox-table.types';
import { getProjectsInboxColumnDefinition } from '../helpers/get-project-inbox-column-definition';

const ProjectsInboxTable = ({
  data,
  sortState,
  handleSortClick,
  onSelectionChange,
  handleAddProposal,
  handleSettleCheckout,
}: ProjectInboxTableProps) => {
  const columns = useMemo(() => getProjectsInboxColumnDefinition(), []);
  const [tableData, setTableData] = useState(() => data.items);

  const getRowActions = (item: ItemOf<ProjectsInboxTableData['items']>['actions']) => (
    <ContextMenuButton menuId={`project-actions-menu-${item.id}`}>
      <ContextMenuItem onClick={handleAddProposal(item)}>
        <IconCounterOffer />
        Tegenvoorstel
      </ContextMenuItem>
      <ContextMenuItem onClick={handleSettleCheckout([item.id])}>
        <IconApproved /> Akkoord
      </ContextMenuItem>
    </ContextMenuButton>
  );

  useEffect(() => {
    setTableData(() => data.items);
  }, [data]);

  return (
    <Table<ProjectsInboxTableColumnDefinition, ProjectsInboxTableOptions>
      columns={columns}
      data={tableData}
      getRowActions={getRowActions}
      sortState={sortState}
      onSortClick={handleSortClick}
      onSelectionChange={onSelectionChange}
      selectable={true}
      selectAllMode="VISIBLE"
    />
  );
};

export { ProjectsInboxTable };
