import { useGetJobs } from 'ogp/hooks/queryHooks/jobs/useGetJobs';
import { RenderUnsettledUI } from 'shared/components';
import { useGetDepartmentsAsSelect } from 'ogp/components/views/management/departments/hooks';
import { ProjectCreateFormPage } from './form/project-create-form-page';

export const ProjectsCreateDataProvider = () => {
  const jobsQuery = useGetJobs();
  const departmentsQuery = useGetDepartmentsAsSelect({ includeArchived: false });

  if (jobsQuery.status !== 'success') {
    return <RenderUnsettledUI data={jobsQuery} />;
  }

  if (departmentsQuery.status !== 'success') {
    return <RenderUnsettledUI data={departmentsQuery} />;
  }

  return (
    <ProjectCreateFormPage
      jobsData={jobsQuery.data}
      successMessage={`Project aangemaakt`}
      pageTitle={`Maak nieuw project aan`}
      departments={departmentsQuery.data}
    />
  );
};
