import { Box, styled } from '@mui/material';
import { WorkTemplateJobDetails } from '../../job-details/work-template-job-details';
import { AffectedFlexPoolsList } from '../../affected-flex-pools-list/affected-flex-pools-list';

export const ShiftJobDetails = ({
  selectedJobId,
  shiftId,
  companyGrossHourlyRate,
}: {
  selectedJobId: string;
  shiftId?: string;
  companyGrossHourlyRate?: number;
}) => {
  return (
    <Disclaimer>
      <ScrollableContainer>
        {shiftId ? (
          <AffectedFlexPoolsList shiftId={shiftId} />
        ) : selectedJobId ? (
          <Box width={'100%'}>
            <AffectedFlexPoolsList jobId={selectedJobId} />
            <WorkTemplateJobDetails
              jobId={selectedJobId}
              companyGrossHourlyRate={companyGrossHourlyRate}
            />
          </Box>
        ) : null}
      </ScrollableContainer>
    </Disclaimer>
  );
};

const Disclaimer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  paddingLeft: theme.spacing(2),
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
}));

const ScrollableContainer = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '22px',
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));
