import { useCreateCompany } from 'hq/hooks/queryHooks/companies/use-create-company';
import { companyAlerts, useAlert } from 'shared/components/alerts';
import { useNavigate } from 'react-router-dom';
import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { FormProvider, useForm } from 'react-hook-form';
import { FormEvent, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { OrtListItem } from '@types';
import { CompanyForm, CompanyFormData } from './company-form';
import { RenderUnsettledUI } from '../../../../../../shared/components';
import { useGetOrts } from '../../../../../hooks/queryHooks/companies/use-get-orts';
import { routes } from '../../../../../../Routes';
import { IconApproved, IconCancel } from '../../../../../../assets/img';
import { hqRoutes } from '../../../../../Routes';

export const CompanyCreate = () => {
  const orts = useGetOrts();
  if (orts.status !== 'success') {
    return <RenderUnsettledUI data={orts} />;
  }

  return <CompanyCreateImpl data={orts.data} />;
};

const CompanyCreateImpl = ({ data }: { data: OrtListItem[] }) => {
  const formMethods = useForm<CompanyFormData>({
    defaultValues: {
      offersTempWork: false,
      offersFreelanceWork: false,
      workCooldownMinutes: 0,
      ortStartDate: DateTime.now().startOf('day').toISODate(),
    },
  });
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const { alertSuccess, alertError } = useAlert();
  const createCompany = useCreateCompany();

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    formMethods.handleSubmit((data) => {
      createCompany.mutate(data, {
        onSuccess: () => {
          alertSuccess(companyAlerts.success.created);
          navigate(generatePath(hqRoutes.Companies));
        },
        onError: (e) => alertError(e),
      });
    })();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Page
          header={<Header titleText="Nieuw bedrijf aanmaken" />}
          toolbox={
            <Toolbox>
              <LwButton
                type="submit"
                iconColorMode="stroke"
                color="primary"
                startIcon={<IconApproved />}
              >
                Bedrijf aanpassen
              </LwButton>
              <LwButton
                onClick={() => navigate(generatePath(routes.Companies))}
                iconColorMode="fill"
                color="secondary"
                startIcon={<IconCancel />}
              >
                Annuleer
              </LwButton>
            </Toolbox>
          }
        >
          <CompanyForm state="create" orts={data} />
        </Page>
      </form>
    </FormProvider>
  );
};
