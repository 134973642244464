import { HqApiService } from './hqApiService';
import { Department } from './department-service-types';

export class DepartmentService {
  constructor(private readonly hqApiService: HqApiService) {}

  public async getDepartments(companyId: string, params: { includeArchived?: boolean }) {
    const result = await this.hqApiService.get<Department[]>({
      path: `departments/${companyId}`,
      params,
    });

    return result;
  }
}
