import { useGetFlexWorkerSchedule } from './use-get-flex-worker-schedule';
import { SmallError } from '../common/state/SmallError';
import { SmallLoader } from '../common/state/SmallLoader';
import { RenderUnsettledUI } from '../render-unsettled-ui';
import { ScheduleTable } from '../schedule/schedule-table';

export const FlexWorkerAvailability = ({ flexWorkerId }: { flexWorkerId: string }) => {
  const flexWorkerScheduleQuery = useGetFlexWorkerSchedule(flexWorkerId);

  if (flexWorkerScheduleQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={flexWorkerScheduleQuery}
      />
    );
  }

  return <ScheduleTable schedule={flexWorkerScheduleQuery.data.schedule} />;
};
