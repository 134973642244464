import { userService } from 'ogp/services';
import { useMutation, UseMutationOptions } from 'react-query';
import { FirstParamOfFn, SecondParamOfFn } from '@types';

type UserMutationData = {
  userId: FirstParamOfFn<typeof userService.updateUser>;
  data: SecondParamOfFn<typeof userService.updateUser>;
};
type UserMutationOptions = UseMutationOptions<unknown, unknown, UserMutationData>;

export const useUpdateUser = (options?: UserMutationOptions) => {
  const mutationFn = async (user: UserMutationData) =>
    await userService.updateUser(user.userId, user.data);

  return useMutation(mutationFn, options);
};
