import { flexWorkerService } from 'hq/services';
import { useMutation } from 'react-query';

export type FlexWorkerFlexPoolsData = {
  flexWorkerId: string;
  flexPoolsToRemove: string[];
  flexPoolsToAdd: string[];
  companyId?: string;
};

export const useHqUpdateFlexWorkerFlexPools = () => {
  const mutationFn = async (queryData: FlexWorkerFlexPoolsData) =>
    await flexWorkerService.flexWorkerFlexPools(queryData);

  return useMutation(mutationFn);
};
