import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { AutocompleteOption, FormCheckbox, LwFormAutocomplete } from 'redesign';
import { FilterWorkersFormContext } from './filter-workers.types';

type Props = {
  workers: {
    id: string;
    name: string;
  }[];
};

export const FilterWorkers = (props: Props) => {
  const { workers } = props;
  const { watch, control, setValue } = useFormContext<FilterWorkersFormContext>();
  const allowWorkerFilter = watch('allowWorkersFilter');
  const filterOptions: AutocompleteOption[] = useMemo(
    () =>
      workers.map((worker) => ({
        label: worker.name,
        value: worker.id,
      })),
    [workers]
  );

  return (
    <>
      <Box>
        <FormCheckbox name="allowWorkersFilter" control={control} label="Flexwerkers" />
      </Box>
      {allowWorkerFilter ? (
        <LwFormAutocomplete
          name="worker"
          label="Flexwerkers"
          options={filterOptions}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          isOptionEqualToValue={(option, selected) => option.value === selected.value}
          onChange={(_, value) => setValue('worker', value)}
          control={control}
        />
      ) : null}
    </>
  );
};
