/* eslint-disable react/jsx-key */
import { LwCheckbox } from 'redesign';
import {
  Box,
  Table as MuiTable,
  TableCell as MuiTableCell,
  styled,
  TableBody,
  TableHead,
  TableRow,
  tableRowClasses,
  TableRowProps,
  Typography,
} from '@mui/material';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, useTable } from 'react-table';
import { useTableData } from './hooks/use-table-data';
import { LastSelectedTableCheckbox, TableProps } from './table.types';

export const Table = <TableColumns extends object, TableFunctions extends object = object>({
  onRowClick,
  stickyHeader,
  tableStyle,
  rowPropGetter,
  cellPropGetter,
  onScroll,
  selectable,
  onSelectionChange,
  selectAllMode,
  ...tableOptions
}: TableProps<TableColumns, TableFunctions>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const lastSelectedRef = useRef<LastSelectedTableCheckbox | null>(null);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    ...tableOptions,
  });
  const { visibleRows, paddingTop, paddingBottom } = useTableData({
    tableContainerRef,
    rows,
  });
  const [selected, setSelected] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (onScroll) {
      onScroll(tableContainerRef.current);
    }
  }, [onScroll]);

  useEffect(() => {
    setSelected({});
    if (onSelectionChange) {
      onSelectionChange([]);
    }
  }, [onSelectionChange]);

  useEffect(() => {
    const selectedEntries = Object.entries(selected);
    if (selectable && onSelectionChange) {
      const selectedRows = selectedEntries
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        .filter(([_, isSelected]) => isSelected)
        .map(([index]) => rows[+index].original);
      onSelectionChange(selectedRows);
    }
  }, [onSelectionChange, selectable, selected]);

  const allSelected = useMemo(() => {
    return Object.values(selected).filter(Boolean).length === rows.length;
  }, [rows.length, selected]);

  const indeterminate = useMemo(() => {
    return !allSelected && Object.values(selected).length > 0;
  }, [allSelected, selected]);

  const onSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, row: Row<TableColumns>) => {
      const nativeEvent = event.nativeEvent;
      const selectOne = () => {
        const toSelect = {
          ...selected,
          [row.id]: !selected[row.id],
        };
        setSelected(toSelect);
        lastSelectedRef.current = {
          index: +row.id,
          range: toSelect,
        };
      };
      const toggleRange = (index: number, range: Record<string, boolean>) => {
        if (range[index]) {
          range[index] = false;
        }
        res[index] = true;
      };

      const isRegularSelection = !(nativeEvent instanceof MouseEvent) || !nativeEvent.shiftKey;

      if (isRegularSelection || lastSelectedRef.current == null) {
        selectOne();
        return;
      }

      const { index: lastSelectedIndex, range } = lastSelectedRef.current;
      const res = { ...range };
      if (lastSelectedIndex < +row.id) {
        for (let i = lastSelectedIndex; i <= +row.id; i++) {
          toggleRange(i, range);
        }
      } else if (lastSelectedIndex >= +row.id) {
        for (let i = lastSelectedIndex; i >= +row.id; i--) {
          toggleRange(i, range);
        }
      }
      setSelected(res);
    },
    [selected]
  );

  return (
    <StyledTableWrapper
      ref={tableContainerRef}
      onScroll={onScroll ? (e) => onScroll(e.target as HTMLDivElement) : () => {}}
      tabIndex={0}
    >
      <StyledTable
        stickyHeader={stickyHeader}
        {...getTableProps()}
        style={tableStyle}
        sx={{ backgroundColor: 'transparent' }}
        data-testid={tableOptions['data-testid']}
      >
        <colgroup>
          {selectable ? <col style={{ width: 48 }} /> : null}
          {tableOptions.columns.map((column) => {
            if (column.width === undefined) {
              return <col key={column.accessor?.toString() ?? ''} />;
            }
            return <col style={{ width: column.width }} key={column.accessor?.toString()} />;
          })}
        </colgroup>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <StyledTableRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.headers.map((i) => i.id).join('__')}
            >
              {selectable ? (
                <MuiTableCell key={`${headerGroup.id}__selectable`}>
                  {selectAllMode === 'VISIBLE' ? (
                    <LwCheckbox
                      checked={allSelected}
                      indeterminate={indeterminate}
                      onChange={() => {
                        if (allSelected) {
                          setSelected({});
                          return;
                        }
                        const all: Record<string, boolean> = {};
                        rows.forEach((_, i) => {
                          all[i] = true;
                        });
                        setSelected(all);
                      }}
                    />
                  ) : null}
                </MuiTableCell>
              ) : null}
              {headerGroup.headers.map((column) => {
                return (
                  <MuiTableCell
                    {...column.getHeaderProps()}
                    key={`${headerGroup.id}__${column.id}`}
                  >
                    {column.render('Header')}
                  </MuiTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {paddingTop > 0 && (
            <tr>
              <StyledDataCell height={`${paddingTop}px`} />
            </tr>
          )}
          {visibleRows.map((visibleRow) => {
            const row = rows[visibleRow.index];
            prepareRow(row);
            return (
              <StyledTableRow
                {...row.getRowProps(rowPropGetter)}
                key={row.id}
                onClick={() => onRowClick?.(row.original)}
                hover={onRowClick ? true : false}
              >
                {selectable ? (
                  <MuiTableCell>
                    <LwCheckbox
                      checked={!!selected[row.id]}
                      sx={{
                        ':focus-within': {
                          ' svg': {
                            borderWidth: 2,
                          },
                        },
                      }}
                      onChange={(event) => onSelect(event, row)}
                    />
                  </MuiTableCell>
                ) : null}
                {row.cells.map((cell) => (
                  <MuiTableCell
                    {...cell.getCellProps(cellPropGetter)}
                    key={`${row.id}__${cell.column.id}`}
                  >
                    {cell.render('Cell')}
                  </MuiTableCell>
                ))}
              </StyledTableRow>
            );
          })}
          {paddingBottom > 0 && (
            <tr>
              <StyledDataCell height={`${paddingBottom}px`} />
            </tr>
          )}
        </TableBody>
      </StyledTable>
    </StyledTableWrapper>
  );
};

export const TableHeader = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography variant="body2" color="secondary" component="div">
    {children}
  </Typography>
);

export const TableCell = ({
  children,
  style,
}: React.PropsWithChildren<{ style?: React.CSSProperties }>) => (
  <Typography variant="body1" style={style} component="div">
    {children}
  </Typography>
);

const noBorderStyle = {
  border: 'none',
  boxShadow: 'none',
} as const;

const StyledTable = styled(MuiTable)(({ theme }) => ({
  '& tbody tr:has(input[type="checkbox"]:checked)': {
    backgroundColor: theme.palette.lwPrimary?.[5] ?? 'white',
  },
  '& tbody tr:is(.row-group-before, .row-group-last, .row-group-single) td': {
    borderBottom: 'none',
    paddingBottom: '17px',
  },
  // styling of the 'tr' children is necessary, because border radius cannot be applied to a 'tr'
  '& tbody tr.row-group-first td:first-of-type': { borderTopLeftRadius: '16px' },
  '& tbody tr.row-group-first td:last-child': { borderTopRightRadius: '16px' },
  '& tbody tr.row-group-last td:first-of-type': { borderBottomLeftRadius: '16px' },
  '& tbody tr.row-group-last td:last-child': { borderBottomRightRadius: '16px' },
  '& tbody tr.row-group-single td:first-of-type': { borderRadius: '16px 0 0 16px' },
  '& tbody tr.row-group-single td:last-child': { borderRadius: '0 16px 16px 0' },
  '& tbody': {
    borderTop: `solid 2px rgba(0, 26, 153, 0.2)`,
    borderBottom: `solid 2px rgba(0, 26, 153, 0.2)`,
  },
  '& button.MuiButtonBase-root': {
    ...noBorderStyle,
    backgroundColor: 'transparent',
    '&:hover': {
      ...noBorderStyle,
      backgroundColor: 'transparent',
    },
    '&:active': noBorderStyle,
    '&:visited': noBorderStyle,
    '&:focus': noBorderStyle,
  },
}));

const StyledTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
  [`&.${tableRowClasses.hover}:hover `]: {
    backgroundColor: theme.palette.lwPrimary?.[5] ?? 'white',
    cursor: 'pointer',
  },
}));

const StyledDataCell = styled('td')(({ height }: { height: CSSProperties['height'] }) => ({
  height,
}));

const StyledTableWrapper = styled(Box)(() => ({
  height: '100%',
  overflow: 'auto',
  outline: 'none',
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
    border: '8px solid white',
    borderRadius: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    backgroundClip: 'content-box',
    border: '8px solid transparent',
    borderRadius: '12px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.4)',
      backgroundClip: 'content-box',
      border: '8px solid transparent',
      borderRadius: '12px',
    },
  },
}));
