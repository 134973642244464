import { useMutation } from 'react-query';
import { projectService } from 'ogp/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { OgpProjectsCreatePayload } from 'ogp/services/project-service.types';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';

export const useCreateProject = () => {
  const mutationFn = async (project: OgpProjectsCreatePayload) =>
    await projectService.createProject(project);
  const invalidateQuery = useInvalidateQuery();
  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(QUERY_KEYS_OGP.allProjects());
    },
  });
};
