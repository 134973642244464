import {
  addAccreditationFiles,
  addFieldsToFormData,
} from 'shared/utils/file-handling/file-handling-utils';
import { PartnerApiService } from './partner-api-service';
import {
  CreatePartnerWorkerPayload,
  GetPartnerWorkerResponse,
  GetPartnerWorkersResponse,
  UpdatePartnerWorkerPayload,
} from './partner-worker-service.types';

export class PartnerWorkerService {
  constructor(private readonly partnerApiService: PartnerApiService) {}

  public getPartnerWorkersListing = async (
    page: number,
    filter: string
  ): Promise<GetPartnerWorkersResponse> => {
    const result = this.partnerApiService.get<GetPartnerWorkersResponse>({
      path: 'workers/listing',
      params: {
        page,
        filter,
      },
    });

    return result;
  };

  public getPartnerWorker = async (workerId: string): Promise<GetPartnerWorkerResponse> => {
    const result = await this.partnerApiService.get<GetPartnerWorkerResponse>({
      path: `workers/${workerId}`,
    });

    return result;
  };

  public createPartnerWorker = async (body: CreatePartnerWorkerPayload): Promise<void> => {
    const formData = getPartnerWorkerPayloadFormData(body);

    await this.partnerApiService.create({
      path: `workers`,
      body: formData,
    });
  };

  public updatePartnerWorker = async (
    partnerWorkerId: string,
    body: UpdatePartnerWorkerPayload
  ): Promise<void> => {
    const formData = getPartnerWorkerPayloadFormData(body);

    await this.partnerApiService.update({
      path: `workers/${partnerWorkerId}`,
      body: formData,
    });
  };

  public deletePartnerWorker = async (partnerWorkerId: string): Promise<void> => {
    await this.partnerApiService.delete({
      path: `workers/${partnerWorkerId}`,
    });
  };
}

function getPartnerWorkerPayloadFormData(
  body: CreatePartnerWorkerPayload | UpdatePartnerWorkerPayload
): FormData {
  const formData = new FormData();

  addAccreditationFiles(formData, body.accreditations);

  const accreditations = JSON.stringify(
    body.accreditations?.map((accreditation) => accreditation.id) ?? []
  );

  const bodyWithoutFiles: { [key: string]: string | undefined } = {
    ...body,
    accreditations,
  };

  addFieldsToFormData(formData, bodyWithoutFiles);

  return formData;
}
