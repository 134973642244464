import { Interval } from 'luxon';
import { ApplicationDetails } from '@types';
import type { BaseShiftApplication, ShiftApplication } from '../bulk-accept-shift-dialog.types';

type GetOverlappingIntervalsFn = (intervals: Interval[]) => [Interval, Interval[]][];

const transformShiftApplication = (shiftApplication: BaseShiftApplication): ShiftApplication => ({
  ...shiftApplication,
  shift: {
    id: shiftApplication.shift.id,
    interval: createInterval(shiftApplication),
  },
});

const computeOverlaps = (shiftApplications: ShiftApplication[]): Record<string, string[]> => {
  const overlaps = getOverlappingIntervals(shiftApplications.map((item) => item.shift.interval));
  const result = shiftApplications.reduce((prev, cur) => {
    const overlappingIntervals = overlaps.find((o) => o[0].equals(cur.shift.interval))?.[1] ?? [];
    const overlappingShiftApplicationIds = shiftApplications
      .filter((sa) => overlappingIntervals.includes(sa.shift.interval))
      .map((sa) => sa.id);
    return {
      ...prev,
      [cur.id]: overlappingShiftApplicationIds.length === 1 ? [] : overlappingShiftApplicationIds,
    };
  }, {});

  return result;
};

const getOverlappingIntervals: GetOverlappingIntervalsFn = (intervals: Interval[]) => {
  return intervals.map((interval) => {
    const overlaps = intervals.filter((anotherInterval) => interval.overlaps(anotherInterval));
    return [interval, overlaps];
  });
};

const formatShiftInterval = (interval: Interval) =>
  `${interval.start.toFormat('ff')} - ${interval.end.toFormat('ff')}`;

const createInterval = (item: ApplicationDetails['allApplications'][number]) =>
  Interval.fromISO(`${item.shift.utcStartDate}/${item.shift.utcEndDate}`);

export { getOverlappingIntervals, transformShiftApplication, computeOverlaps, formatShiftInterval };
