import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { BankHolidayORT, Ort } from '@types';
import _ from 'lodash';
import { useMemo } from 'react';
import { translateDaysToDutch, weekDays } from '../../../../../../shared/utils/date-utils';

type CompanyDetailOrtsProps = {
  weekdayOrts: Ort | undefined;
  bankHolidayOrts: BankHolidayORT;
};

const CompanyDetailOrts = ({ weekdayOrts, bankHolidayOrts }: CompanyDetailOrtsProps) => {
  return (
    <Box role="tabpanel">
      <CompanyDetailWeekdayOrts weekdayOrts={weekdayOrts} />
      <CompanyDetailBankHolidayOrts bankHolidayOrts={bankHolidayOrts} />
    </Box>
  );
};

const CompanyDetailWeekdayOrts = ({ weekdayOrts }: Pick<CompanyDetailOrtsProps, 'weekdayOrts'>) => {
  return (
    <OrtCalendarGrid container columns={7} data-testid="weekdays-calendar">
      {weekDays.map((weekDay) => (
        <Grid key={weekDay} item xs={1}>
          <OrtWeekDayGrid container>
            <OrtWeekDayGridItem item>
              <HeaderTypography color="secondary" variant="subtitle2">
                {translateDaysToDutch(weekDay)}
              </HeaderTypography>
            </OrtWeekDayGridItem>
            {weekdayOrts && weekdayOrts.calendar
              ? weekdayOrts.calendar.workDays[weekDay].map((ortPeriod) => (
                  <OrtWeekDayGridItem item key={ortPeriod.startTime}>
                    <Typography variant="body1">
                      {DateTime.fromFormat(ortPeriod.startTime, 'HH:mm:ss').toFormat('HH:mm')} -{' '}
                      {DateTime.fromFormat(ortPeriod.endTime, 'HH:mm:ss').toFormat('HH:mm')}
                    </Typography>
                    <Typography variant="body1">{ortPeriod.percentage}% ORT</Typography>
                  </OrtWeekDayGridItem>
                ))
              : undefined}
          </OrtWeekDayGrid>
        </Grid>
      ))}
    </OrtCalendarGrid>
  );
};

const CompanyDetailBankHolidayOrts = ({
  bankHolidayOrts,
}: Pick<CompanyDetailOrtsProps, 'bankHolidayOrts'>) => {
  const chunks = useMemo(() => {
    const bankHolidays = Object.entries(bankHolidayOrts).map(([name, ortPeriod]) => ({
      name,
      ort: ortPeriod,
    }));
    const chunks = _.chunk(bankHolidays, 7);
    return chunks;
  }, [bankHolidayOrts]);

  return (
    <Box data-testid="bank-holidays-calendar">
      {chunks.map((bankHolidayChunk, index) => {
        return (
          <OrtCalendarGrid key={index} container columns={7} mt={16}>
            {bankHolidayChunk.map((bankHolidays) => {
              const name = bankHolidays.name;
              // const date = DateTime.fromISO(bankHolidays.date).toFormat('yyyy-MM-dd');

              return (
                <Grid key={name} item xs={1}>
                  <OrtWeekDayGrid container>
                    <OrtWeekDayGridItem item>
                      <HeaderTypography color="secondary" variant="subtitle2" noWrap title={name}>
                        {name}
                      </HeaderTypography>

                      {/* 
                      TODO: do we want to display the holidays date?
                      <HeaderTypography
                        color="primary"
                        variant="subtitle2"
                        noWrap
                        // title={date}
                        mt={4}
                        // Todo: Updated typography to allow overwriting them
                        sx={{ color: 'rgba(103, 117, 195, 1) !important' }}
                      >
                        {date}
                      </HeaderTypography> */}
                    </OrtWeekDayGridItem>
                    {bankHolidays.ort.map((bankHoliday) => {
                      return (
                        <OrtWeekDayGridItem
                          key={`${bankHoliday.startTime}-${bankHoliday.endTime}`}
                          item
                        >
                          <Typography variant="body1">
                            {DateTime.fromFormat(bankHoliday.startTime, 'HH:mm:ss').toFormat(
                              'HH:mm'
                            )}
                            -
                            {DateTime.fromFormat(bankHoliday.endTime, 'HH:mm:ss').toFormat('HH:mm')}
                          </Typography>
                          {bankHoliday.percentage != null ? (
                            <Typography variant="body1">{`${bankHoliday.percentage}% ORT`}</Typography>
                          ) : (
                            <Typography variant="body1">Regular</Typography>
                          )}
                        </OrtWeekDayGridItem>
                      );
                    })}
                  </OrtWeekDayGrid>
                </Grid>
              );
            })}
          </OrtCalendarGrid>
        );
      })}
    </Box>
  );
};

const HeaderTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
}));

const OrtCalendarGrid = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.grey[200],
}));

const OrtWeekDayGrid = styled(Grid)(({ theme }) => ({
  borderLeft: `${theme.spacing(0.5)} solid`,
  borderRight: `${theme.spacing(0.5)} solid`,
  borderColor: theme.palette.grey[200],
}));

const OrtWeekDayGridItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderBottom: `${theme.spacing(0.5)} solid`,
  borderColor: theme.palette.grey[200],
}));

export { CompanyDetailOrts };
