import { useFormContext } from 'react-hook-form';
import { LwFormNumberInput } from 'redesign';

type NoShowBailField = {
  noShowBail: number;
};

export const NoShowBailInput = ({
  disabled,
  helperText,
  placeholder,
  required,
}: {
  disabled?: boolean;
  helperText?: string;
  placeholder?: string;
  required?: boolean;
}) => {
  const { control } = useFormContext<NoShowBailField>();

  return (
    <LwFormNumberInput
      name="noShowBail"
      label="No-show borg (€)"
      control={control}
      rules={{
        ...(required ? { required: 'Voer een no-show borg in, 0 euro is toegestaan' } : {}),
      }}
      step={0.01}
      min={0}
      placeholder={placeholder}
      helperText={helperText}
      data-testid="no-show"
      disabled={disabled}
    />
  );
};
