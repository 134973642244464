import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { Box, Divider } from '@mui/material';
import { ModalPanelBody, ModalPanelTabType } from 'redesign';
import { useMemo } from 'react';
import { PartnerPlannerModalHead } from './partner-shift-planning-modal-header/partner-shift-planning-modal-header';
import { usePartnerGetShift } from './hooks/use-partner-get-shift';
import { PlannerClaimantTableDataProvider } from './tables/planner-claimant-table';
import { PlannerWorkerTableWithSearchbar } from './tables/planner-worker-table';

type Props = {
  shiftId: string;
  closeShiftDetail: () => void;
};

const ShiftPlanningModal = ({ shiftId, closeShiftDetail }: Props) => {
  const shiftQuery = usePartnerGetShift(shiftId);
  const options: ModalPanelTabType[] = useMemo(
    () => [
      {
        tabLabel: 'Jouw team',
        id: 'Claimants',
        element: <PlannerClaimantTableDataProvider shiftId={shiftId} />,
      },
      {
        tabLabel: 'Kandidaat toevoegen',
        id: 'Workers',
        element: <PlannerWorkerTableWithSearchbar shiftId={shiftId} />,
      },
    ],
    [shiftId]
  );

  if (shiftQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={shiftQuery}
      />
    );
  }
  return (
    <Box
      p={8}
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="partner-planner-modal"
    >
      <PartnerPlannerModalHead data={shiftQuery.data} close={closeShiftDetail} />
      <Box mt={8} mb={8}>
        <Divider />
      </Box>
      <ModalPanelBody tabs={options} />
    </Box>
  );
};

export { ShiftPlanningModal };
