import { shiftClaimService } from 'ogp/services';
import { useMutation, UseMutationOptions } from 'react-query';

type ShiftClaimMutationOptions = UseMutationOptions<unknown, unknown, string>;

export const useCancelShiftClaim = (options?: ShiftClaimMutationOptions) => {
  const mutationFn = async (shiftId: string) => await shiftClaimService.cancelShiftClaim(shiftId);

  return useMutation(mutationFn, options);
};
