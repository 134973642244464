import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { partnerPlannerService } from 'partner/shared/services';
import { useInfiniteQuery } from 'react-query';

export const usePartnerGetWorkers = (
  shiftId: string,
  pageSize: number,
  fullNameSearch: string | undefined
) => {
  return useInfiniteQuery({
    queryKey: QUERY_KEYS_PARTNER.shiftPlanningShiftWorkers(shiftId, fullNameSearch),
    queryFn: async ({ pageParam = 0 }) => {
      return await partnerPlannerService.getShiftWorkers(
        shiftId,
        fullNameSearch,
        pageParam,
        pageSize
      );
    },
    getNextPageParam: (lastGroup) => lastGroup.nextPage,
  });
};
