import React from 'react';
import { Box, css, styled, Theme, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { WrapMeWhen } from 'ogp/components/common/wrap-me-when';
import { SidebarItem, SidebarItemDescription } from './types';
import { useSidebarProvider } from './sidebar-provider';
import { useHqRelativePath } from '../../hooks/useHqRelativePath';

const SidebarSectionTitle = ({ children }: React.PropsWithChildren<unknown>) => {
  const { isOpen } = useSidebarProvider();

  return <Styled.Title folded={!isOpen}>{children}</Styled.Title>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SidebarSectionLink = ({ element, notificationCount, ...props }: SidebarItem) => {
  const createLink = useHqRelativePath();
  const path = createLink(props);

  return (
    <Styled.Link
      style={({ isActive }) => (isActive ? { pointerEvents: 'none' } : {})}
      to={path}
      key={path}
    >
      {({ isActive }) => (
        <Styled.Item
          isActive={isActive}
          icon={props.icon}
          label={props.label}
          notificationCount={notificationCount}
        />
      )}
    </Styled.Link>
  );
};
const SidebarSectionButton = (
  props: SidebarItemDescription & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <Styled.Button onClick={props.onClick}>
      <Styled.Item icon={props.icon} label={props.label} />
    </Styled.Button>
  );
};

const SidebarSectionList = ({ children }: React.PropsWithChildren<unknown>) => {
  return <section>{children}</section>;
};

const SidebarSection = ({
  children,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => {
  return <Styled.Section {...props}>{children}</Styled.Section>;
};

const SidebarSectionBaseItem = ({ icon, label, ...props }: SidebarItemDescription) => {
  const { isOpen } = useSidebarProvider();

  return (
    <WrapMeWhen
      when={!isOpen}
      wrapper={(children) => (
        <Tooltip placement="right" title={label}>
          {children}
        </Tooltip>
      )}
    >
      <Box component="span" display="flex" alignItems="center" gap={4} px={2} {...props}>
        {icon}
        {isOpen ? (
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box>{label}</Box>
            {props.notificationCount ? (
              <Styled.NotificationBox>{props.notificationCount}</Styled.NotificationBox>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </WrapMeWhen>
  );
};

SidebarSection.Button = SidebarSectionButton;
SidebarSection.Link = SidebarSectionLink;
SidebarSection.List = SidebarSectionList;
SidebarSection.Title = SidebarSectionTitle;

const Styled = {
  Section: styled('section')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  })),
  Title: styled(Box, { shouldForwardProp: (prop) => prop !== 'folded' })<{ folded: boolean }>(
    ({ theme, folded }) => ({
      color: theme.palette.lwSecondary[60],
      fontSize: '13px',
      fontVariationSettings: '"wght" 700',
      fontWeight: 700,
      paddingLeft: theme.spacing(2),
      textTransform: 'uppercase',
      ...(folded && {
        fontSize: '10px',
        paddingLeft: 0,
        textAlign: 'center',
      }),
    })
  ),
  Item: styled(SidebarSectionBaseItem, {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'notificationCount',
  })<{
    isActive?: boolean;
  }>(({ theme, isActive }) => ({
    backgroundColor: 'unset',
    border: 'none',
    color: theme.palette.lwSecondary[60],
    cursor: 'pointer',
    height: '40px',
    margin: theme.spacing(2, 0),
    width: '100%',

    '& svg path': {
      stroke: theme.palette.lwSecondary[60],
    },
    ...(isActive && {
      backgroundColor: theme.palette.lwPrimary[10],
      borderRadius: theme.spacing(3),
      color: theme.palette.lwPrimary[100],
      fontWeight: 600,
      fontVariationSettings: '"wght" 600',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& svg path': {
        stroke: theme.palette.lwPrimary[100],
      },
    }),
  })),
  Link: styled(NavLink)(({ theme }) => clickableStyles(theme)),
  Button: styled('button')(({ theme }) => clickableStyles(theme)),
  NotificationBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    fontSize: '12px',
    height: '24px',
    borderRadius: '24px',
    backgroundColor: theme.palette.lwSecondary[20],
  })),
};

const clickableStyles = (theme: Theme) =>
  css({
    backgroundColor: 'unset',
    border: 'none',
    textDecoration: 'none',
    width: '100%',

    '&:hover *': {
      color: theme.palette.lwPrimary[100],
      stroke: 'currentColor',
    },
  });

export { SidebarSection };
