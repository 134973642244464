import { FlexWorkerItem } from 'ogp/components/views/planning/flex-pools/flex-pool-detail/components/flex-workers/flex-workers.types';
import { FlexWorkerFlexPools, FlexWorkerProfileData, ParamsWithPagination } from '@types';
import { FlexPoolFilters } from 'shared/components/shift-forms/components/affected-flex-pools-list/hooks/use-get-flex-pools';
import {
  CreateFlexPoolPayload,
  GetFlexPoolDetailResponse,
  GetFlexPoolListResponse,
  GetFlexPoolResponse,
  GetFlexPoolsResponse,
  OGPUpdateFlexPoolPayload,
} from './flex-pool-service.types';
import { OgpApiService } from './ogp-api-service';

export class FlexPoolService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Create a new flexPool
   */
  public createFlexPool = async (flexPool: CreateFlexPoolPayload): Promise<void> => {
    await this.ogpApiService.create({
      path: `flex-pools`,
      body: {
        ...flexPool,
      },
    });
  };

  /**
   * Update an existing flexPool
   */
  public updateFlexPool = async (flexPoolId: string, flexPool: OGPUpdateFlexPoolPayload) => {
    await this.ogpApiService.update({
      path: `flex-pools/${flexPoolId}`,
      body: flexPool,
    });
  };

  public getFlexPoolList = async (params: ParamsWithPagination<FlexPoolFilters>) => {
    const response = await this.ogpApiService.get<GetFlexPoolListResponse>({
      path: `flex-pools/list`,
      params: { ...params },
    });

    return response;
  };

  /**
   * Get a single flexPool
   */
  public getFlexPool = async (flexPoolId: string): Promise<GetFlexPoolResponse['flexPool']> => {
    const { flexPool } = await this.ogpApiService.get<GetFlexPoolResponse>({
      path: `flex-pools/${flexPoolId}`,
    });

    return flexPool;
  };

  public getFlexPools = async (
    params: FlexPoolFilters
  ): Promise<GetFlexPoolsResponse['flexPools']> => {
    const { flexPools } = await this.ogpApiService.get<GetFlexPoolsResponse>({
      path: `flex-pools`,
      params: { ...params },
    });

    return flexPools;
  };

  public getFlexPoolDetail = async (flexPoolId: string) => {
    const response = await this.ogpApiService.get<GetFlexPoolDetailResponse>({
      path: `flex-pools/${flexPoolId}/detail`,
    });

    return response.flexPoolDetail;
  };

  public getFlexPoolFlexWorkers = async (flexPoolId: string, flexWorkerName?: string) => {
    const response = await this.ogpApiService.get<FlexWorkerItem[]>({
      path: `flex-pools/${flexPoolId}/flex-workers`,
      params: {
        flexWorkerName,
      },
    });

    return response;
  };

  public getJobs = async (flexPoolId: string) => {
    const response = await this.ogpApiService.get<{ id: string; name: string }[]>({
      path: `flex-pools/${flexPoolId}/jobs`,
    });

    return response;
  };

  public deleteFlexPool = async (flexPoolId: string) =>
    await this.ogpApiService.delete({
      path: `flex-pools/${flexPoolId}`,
    });

  public deleteFlexWorkerFromFlexPool = async (flexPoolId: string, flexWorkerId: string) =>
    await this.ogpApiService.delete({
      path: `flex-pools/${flexPoolId}/flex-worker/${flexWorkerId}`,
    });

  public getFlexWorkerFlexPools = async (flexWorkerId: string, shiftId: string) => {
    const response = await this.ogpApiService.get<FlexWorkerFlexPools[]>({
      path: `flex-workers/${flexWorkerId}/flex-pools-for-shift/${shiftId}`,
    });
    return response;
  };

  public getFlexWorkerProfileThroughFlexPool = async (flexPoolId: string, flexWorkerId: string) => {
    const response = await this.ogpApiService.get<FlexWorkerProfileData>({
      path: `flex-pools/${flexPoolId}/profile/${flexWorkerId}`,
    });
    return response;
  };
}
