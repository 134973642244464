import { Grid } from '@mui/material';
import { IconDocument1 } from 'assets/img';
import { Header, LwButton, Page, Toolbox } from 'redesign';
import { getPlatformClient } from 'shared/utils/platform-utils';

type LegalDocuments = {
  termsOfUse: string;
  privacyStatement: string;
  contractTemplate: string;
};

const getDocuments = (): LegalDocuments => {
  if (getPlatformClient() === 'AETHON') {
    return {
      termsOfUse: 'https://aelio.nl/gebruikersvoorwaarden-opdrachtgevers/',
      privacyStatement: 'https://aelio.nl/privacy/',
      contractTemplate: 'TBD',
    };
  } else if (getPlatformClient() === 'HOLLANDWORX') {
    return {
      termsOfUse: 'https://doc.hollandworx.nl/gebruikersvoorwaarden-opdrachtgever/',
      privacyStatement: 'https://doc.hollandworx.nl/privacy-statement/',
      contractTemplate: 'TBD',
    };
  } else {
    return {
      termsOfUse: 'https://nl.level.works/legal/gebruikersvoorwaarden-opdrachtgevers',
      privacyStatement: 'https://nl.level.works/legal/privacy-statement',
      contractTemplate: 'https://nl.level.works/legal/modelovereenkomst-van-opdracht',
    };
  }
};

const Legal = () => {
  const documents = getDocuments();
  return (
    <Page
      header={
        <Header
          subheaderText="Hier ziet je alle juridische documenten"
          titleText="Juridische documenten"
        />
      }
      toolbox={<Toolbox />}
    >
      <Grid container spacing={8}>
        <Grid item xs={12} md={12} lg={4} mt={4}>
          <LwButton
            color="secondary"
            to={documents.termsOfUse}
            target="_blank"
            startIcon={<IconDocument1 />}
            iconVariant="outlined"
            iconColorMode="fill"
          >
            Gebruikersvoorwaarden
          </LwButton>
        </Grid>
        <Grid item xs={12} md={12} lg={4} mt={4}>
          <LwButton
            color="secondary"
            to={documents.privacyStatement}
            target="_blank"
            startIcon={<IconDocument1 />}
            iconVariant="outlined"
            iconColorMode="fill"
          >
            Privacy statement
          </LwButton>
        </Grid>
        <Grid item xs={12} md={12} lg={4} mt={4}>
          <LwButton
            color="secondary"
            to={documents.contractTemplate}
            target="_blank"
            startIcon={<IconDocument1 />}
            iconVariant="outlined"
            iconColorMode="fill"
          >
            Modelovereenkomst
          </LwButton>
        </Grid>
      </Grid>
    </Page>
  );
};

export { Legal };
