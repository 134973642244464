import { ContextMenuItem, Table } from 'redesign';
import { GetPartnerRelationsResponse } from 'hq/services/partner-service.types';

import { useHqRelativePath } from 'hq/hooks/useHqRelativePath';
import { hqRoutes } from 'hq/Routes';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import {
  PartnerRelationColumnDefinition,
  PartnerRelationTableOptions,
} from './partner-relations.types';
import { getPartnerRelationColumnDefinition } from './utils/get-partner-relations-column-definitions';
import { getPartnerRelationTableData } from './utils/get-partner-relations-table-data';
import { useArchivePartnerRelation } from '../hooks/use-archive-partner-relation';
import { useDialog } from '../../../../../ogp/providers/dialog-provider';
import { useAlert } from '../../../../../shared/components/alerts';

type Props = { data: GetPartnerRelationsResponse[]; partnerId: string; deactivated: boolean };

export const PartnerRelationsTable = ({ data, partnerId, deactivated }: Props) => {
  const { alertSuccess, alertError } = useAlert();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const generatePath = useHqRelativePath();
  const columns = useMemo(() => getPartnerRelationColumnDefinition(), []);
  const tableData = useMemo(() => getPartnerRelationTableData(data), [data]);
  const { mutate } = useArchivePartnerRelation(partnerId);

  const handleArchival = (partnerRelationId: string) => {
    openDialog({ title: 'Weet je zeker dat je deze relatie wilt verwijderen?' }, () =>
      mutate(partnerRelationId, {
        onSettled: () => closeDialog(),
        onError: (e) => alertError(e),
        onSuccess: () => alertSuccess('Relatie verwijderd'),
      })
    );
  };

  const getPartnerRelationTableActions = (partnerRelationId: string) => [
    <>
      <ContextMenuItem
        key="edit-relation"
        onClick={() =>
          navigate(generatePath(hqRoutes.PartnerRelationEdit, { id: partnerId, partnerRelationId }))
        }
        disabled={deactivated}
        data-testid="edit-partner-relation"
      >
        Relatie aanpassen
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() => handleArchival(partnerRelationId)}
        data-testid="archive-partner-relation"
      >
        Relatie verwijderen
      </ContextMenuItem>
    </>,
  ];

  return (
    <Table<PartnerRelationColumnDefinition, PartnerRelationTableOptions>
      stickyHeader={true}
      columns={columns}
      data={tableData}
      data-testid="partner-relations-table"
      getRowActions={getPartnerRelationTableActions}
    />
  );
};
