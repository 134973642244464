import { useExternalParams } from 'shared/hooks';

const initialValues = {
  user: {
    initialValue: undefined,
    type: 'string',
  },
} as const;

const useUsersPage = () => {
  const [params, setParams] = useExternalParams(initialValues);

  const filters = {
    user: params.user || undefined,
  };

  return {
    filters,
    setFilters: setParams,
  };
};

export { useUsersPage };
