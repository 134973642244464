import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { shiftService as hqShiftService } from 'hq/services';
import { shiftService as ogpShiftService } from 'ogp/services';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useSiteDetection } from 'shared/hooks';

type OpenShiftToPartnersMutationParams = {
  shiftId: string;
  partnerIds: string[];
};

type ShiftPartnerMutationOptions = UseMutationOptions<
  unknown,
  unknown,
  { shiftId: string; partnerIds: string[] }
>;

export const useOpenShiftToPartners = (shiftId: string, options?: ShiftPartnerMutationOptions) => {
  const { isHQ } = useSiteDetection();
  const queryKeys = isHQ
    ? [
        QUERY_KEYS_HQ.shiftPartners(),
        QUERY_KEYS_HQ.shiftPartner(shiftId),
        QUERY_KEYS_HQ.shiftPlanningShifts(),
      ]
    : [QUERY_KEYS_OGP.shiftPartners(), QUERY_KEYS_OGP.shiftPartner(shiftId)];
  const mutationFn = isHQ
    ? async (params: OpenShiftToPartnersMutationParams) =>
        await hqShiftService.openShiftToPartners(params)
    : async (params: OpenShiftToPartnersMutationParams) =>
        await ogpShiftService.openShiftToPartners(params);

  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftPartnerMutationOptions = {
    onSuccess: (data, variables, context) => {
      queryKeys.forEach((key) => queryClient.invalidateQueries(key));
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
