import React from 'react';
import { Spinner, useSiteDetection, useToggle } from 'shared/hooks';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { ApplicationDetails } from '@types';
import { useInvalidateQuery } from 'shared/hooks/query-hooks/useInvalidateQuery';
import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import _ from 'lodash';
import { usePlanningDateRange } from 'ogp/hooks/planning';
import { LwButton } from 'redesign';
import { transformShiftApplication } from './helpers/helpers';
import type { FlexWorker, ShiftApplication } from './bulk-accept-shift-dialog.types';
import { BulkAcceptShiftDialogForm } from './bulk-accept-shift-dialog-form';
import { BulkAcceptMutationType } from '../../query-hooks/use-bulk-accept-shift-applications.types';
import { QUERY_KEYS_HQ } from '../../../../../hq/query-keys';

const useBulkAcceptApplicationsDialog = (mutation: BulkAcceptMutationType) => {
  const { on: isDialogOpen, setOn: openDialog, setOff: closeDialog } = useToggle(false);
  const [flexWorker, setFlexWorker] = React.useState<FlexWorker>();
  const [shiftApplications, setShiftApplications] = React.useState<{
    current: ShiftApplication;
    rest: ShiftApplication[];
  }>();
  const { alertSuccess, alertError } = useAlert();
  const invalidateQuery = useInvalidateQuery();
  const { isHQ } = useSiteDetection();
  const planningDateRange = usePlanningDateRange();
  const bulkAcceptShiftApplicationsMutation = mutation({
    onSuccess: (result) => {
      closeDialog();
      const succeeded = result.filter((item) => item.status === 'success');
      alertSuccess(shiftAlerts.success.bulkCreated(succeeded.length, result.length));

      if (!shiftApplications) {
        return;
      }

      if (isHQ) {
        invalidateQuery(QUERY_KEYS_HQ.shift(shiftApplications.current.shift.id));
        shiftApplications.rest.forEach(({ shift }) => {
          invalidateQuery(QUERY_KEYS_HQ.shift(shift.id));
        });
        invalidateQuery(QUERY_KEYS_HQ.shiftPlanningShifts());
      } else {
        invalidateQuery(QUERY_KEYS_OGP.shift(shiftApplications.current.shift.id));
        shiftApplications.rest.forEach(({ shift }) => {
          invalidateQuery(QUERY_KEYS_OGP.shift(shift.id));
        });
        invalidateQuery(
          QUERY_KEYS_OGP.shiftPlanning({
            fromDate: planningDateRange.startDate.toISODate(),
            toDate: planningDateRange.endDate.toISODate(),
          })
        );
      }
    },
    onError: (e) => alertError(e),
  });

  const handleBulkAcceptShiftApplications = (shiftApplicationIds: string[]) => {
    if (!(flexWorker && shiftApplications)) {
      return;
    }

    bulkAcceptShiftApplicationsMutation.mutate({
      flexWorkerId: flexWorker.id,
      shiftApplicationIds,
    });
  };

  const Element =
    flexWorker && shiftApplications ? (
      <Dialog maxWidth="xs" onClose={closeDialog} open={isDialogOpen}>
        <DialogContent>
          <BulkAcceptShiftDialogForm
            id="shift-application-approval-bulk-form"
            flexWorker={flexWorker}
            shiftApplications={shiftApplications}
            onSubmit={handleBulkAcceptShiftApplications}
          />
        </DialogContent>
        <Box
          component={DialogActions}
          display="flex"
          style={{ gap: '32px', justifyContent: 'center' }}
        >
          <LwButton autoFocus onClick={closeDialog}>
            Annuleren
          </LwButton>
          <LwButton type="submit" form="shift-application-approval-bulk-form">
            Bevestigen
            {bulkAcceptShiftApplicationsMutation.status === 'loading' ? <Spinner /> : null}
          </LwButton>
        </Box>
      </Dialog>
    ) : null;

  function open(
    flexWorker: FlexWorker,
    shiftApplicationId: string,
    shiftApplications: ApplicationDetails['allApplications']
  ) {
    const applicationsWithIntervals = shiftApplications.map(transformShiftApplication);
    const [[currentApplication], otherApplications] = _.partition(
      applicationsWithIntervals,
      (a) => a.id === shiftApplicationId
    );

    setFlexWorker(flexWorker);
    setShiftApplications({
      current: currentApplication,
      rest: otherApplications,
    });

    openDialog();
  }

  return { Element, open, close: closeDialog };
};

export { useBulkAcceptApplicationsDialog };
