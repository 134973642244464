import {
  ClaimCancelationOfferSettled,
  ClaimCheckoutSettled,
  ClaimCounterOffer,
  ClaimNoShow,
  ShiftApplication,
  ShiftClaim,
} from '@types';
import { ShiftApplicationState, ShiftClaimState } from './shiftClaimState.types';

// FIXME this is completely decoupled from the types and that is a bad thing!
const _canceledStates = [
  'canceled',
  'denied',
  'cancelationOfferPending',
  'cancelationOfferSettled',
] as const;
const _claimedStates = [
  'accepted',
  'checkoutPending',
  'checkoutSettled',
  'counterOffer',
  'noShow',
] as const;

export const stateLabels = {
  accepted: 'Geaccepteerd',
  checkoutPending: 'Uren ingevoerd',
  checkoutSettled: 'Akkoord',
  noShow: 'No show',
  counterOffer: 'Tegenvoorstel',
  cancelationOfferSettled: 'Annuleringsaanbod',
};

const hasClaimedState = <C extends ShiftClaim>(
  c: C
): c is C & { state: (typeof _claimedStates)[number] } =>
  // FIXME this typecast actually covers a typescript error
  (_claimedStates as ReadonlyArray<ShiftClaimState>).includes(c.state);

const hasDeclinedState = <C extends ShiftApplication | ShiftClaim>(
  c: C
): c is C & { state: (typeof _canceledStates)[number] } => isDeclined(c.state);

const isDeclined = (
  state: ShiftApplicationState | ShiftClaimState
): state is (typeof _canceledStates)[number] => {
  // using `.find` instead of `.includes` bypasses Typescript subtype errors in a safe manner
  if (_canceledStates.find((x) => x === state)) {
    return true;
  }

  return false;
};

const isShiftClaimState = (
  value: string
): value is
  | ClaimCounterOffer['state']
  | ClaimNoShow['state']
  | ClaimCheckoutSettled['state']
  | ClaimCancelationOfferSettled['state'] =>
  value === 'counterOffer' ||
  value === 'noShow' ||
  value === 'checkoutSettled' ||
  value === 'cancelationOfferSettled';

export { hasDeclinedState, hasClaimedState, isShiftClaimState };
