import { companyService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_HQ } from 'hq/query-keys';
import { CompanyData } from '@types';

type CompanyMutationOptions = UseMutationOptions<unknown, unknown, CompanyData>;

export const useUpdateCompany = (companyId: string) => {
  const mutationFn = async (company: CompanyData) =>
    await companyService.updateCompany(companyId, company);

  const queryClient = useQueryClient();
  const mutationOptions: CompanyMutationOptions = {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEYS_HQ.companies()),
        queryClient.invalidateQueries(QUERY_KEYS_HQ.company(companyId)),
      ]);
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
