import { Header, Page, Toolbox } from 'redesign';
// import { FilterButton } from 'ogp/components/views/administration/shared';
import { Box, styled } from '@mui/material';
import { InboxMessagesDataProvider } from './inbox-messages';

const PartnerInbox = () => {
  return (
    <StyledBox>
      <Page
        header={<Header titleText="Inbox" />}
        toolbox={
          <Toolbox>
            <Toolbox.PushRight>
              {/* <FilterButton onClick={() => {}} nrOfActiveFilters={0} /> */}
            </Toolbox.PushRight>
          </Toolbox>
        }
        pageStyle={{ height: '75%' }}
      >
        <InboxMessagesDataProvider />
      </Page>
    </StyledBox>
  );
};
const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
});

export { PartnerInbox };
