import { useMutation, UseMutationOptions } from 'react-query';
import { shiftClaimService } from 'hq/services';
import { saveAs } from 'file-saver';
import { formatCsvExportDateTime } from 'shared/utils/formatting';
import { HQShiftClaimExportPayload } from 'hq/services/shift-claim-service.types';

type ShiftClaimExportMutationOptions = UseMutationOptions<Blob, Error, HQShiftClaimExportPayload>;

export const useGetShiftClaimExport = (options?: ShiftClaimExportMutationOptions) => {
  const mutationFn = async (getShiftClaimsData: HQShiftClaimExportPayload) =>
    await shiftClaimService.getShiftClaimsExport(getShiftClaimsData);

  const { onSuccess, ...restOptions } = options ?? {};
  const mutationOptions: ShiftClaimExportMutationOptions = {
    onSuccess: (data, ...restArg) => {
      const dateTimeString = formatCsvExportDateTime(new Date(Date.now()));
      saveAs(data, `HQ Checkout Export ${dateTimeString}.csv`);
      onSuccess && onSuccess(data, ...restArg);
    },
    ...restOptions,
  };

  return useMutation(mutationFn, mutationOptions);
};
