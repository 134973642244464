import { useConfirm } from 'shared/hooks';

const useArchiveBranchOfficeForbiddenDialog = () => {
  const dialog = useConfirm({
    title:
      'Het is niet mogelijk om deze opdrachtgever te verwijderen aangezien er nog actieve plaatsingen zijn.',
    negativeAction: { label: 'Ok', autoFocus: true },
  });

  function open() {
    dialog.open();
  }

  return { ...dialog, open };
};

export { useArchiveBranchOfficeForbiddenDialog };
