import { Box, BoxProps, Chip, Grid, GridProps, IconButton, styled, Tooltip } from '@mui/material';
import React from 'react';
import { formatTextValue, HoverableBlock, HoverableLink } from 'redesign';
import iconCaretRight from 'assets/img/icons/caret-right.svg';
import { LinkProps } from 'react-router-dom';
import { BlockProps } from './block.types';

const blockTypeGuard = (props: LinkProps | BoxProps): props is LinkProps => {
  return !props.onClick;
};

export const BlockLink = ({
  children,
  ...props
}: { children: React.ReactElement<typeof LwBlock> } & (LinkProps | BoxProps)) => {
  React.Children.only(children);
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type !== LwBlock) {
      throw new Error('BlockLink component can only have Block as its only child.');
    }
  });

  if (blockTypeGuard(props)) {
    return <HoverableLink {...props}>{children}</HoverableLink>;
  }
  // @ts-expect-error Fix me later, this is working properly but is throwing error because of some weird MUI types I have no control over
  return <HoverableBlock {...props}>{children}</HoverableBlock>;
};

export const LwBlock = ({
  icon,
  title,
  subtitle,
  chipLabels,
  dataBlocks,
  severity = 'none',
  navigateCallback,
  navigateLabel,
}: BlockProps) => {
  return (
    <BlockWrapper severity={severity}>
      <HeaderWrapper>
        <HeaderRow>
          {icon ? (
            <IconWrap>
              <IconBox>{icon}</IconBox>
            </IconWrap>
          ) : null}
          <TitleWrap>
            <TitleWrapper>
              <StyledTitle>{title}</StyledTitle>
            </TitleWrapper>
            {subtitle != null ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
            {chipLabels != null ? (
              <ChipContainer>
                {chipLabels.map((label) => (
                  <EmploymentTypeChip key={label} label={label} size="small" variant="outlined" />
                ))}
              </ChipContainer>
            ) : null}
          </TitleWrap>
        </HeaderRow>
      </HeaderWrapper>
      {dataBlocks?.length ? (
        <DataBlockWrapper container>
          {dataBlocks.map(({ label, value, format }) => {
            return (
              <Tooltip key={`${label}__${value}`} title={formatTextValue(value, format)}>
                <LabelWrapper item xs={6}>
                  <DataBlockLabel>{label}</DataBlockLabel>
                  <DataBlockData>{formatTextValue(value, format)}</DataBlockData>
                </LabelWrapper>
              </Tooltip>
            );
          })}
        </DataBlockWrapper>
      ) : null}
      {navigateCallback ? (
        <NavigateContainer onClick={navigateCallback}>
          {navigateLabel ? <NavigateLabel>{navigateLabel}</NavigateLabel> : null}
          <IconButtonStyled size="small">
            <ImgStyled src={iconCaretRight} alt="toggle drawer" />
          </IconButtonStyled>
        </NavigateContainer>
      ) : null}
    </BlockWrapper>
  );
};

const BlockWrapper = styled(Grid, { shouldForwardProp: (prop) => prop !== 'severity' })<
  GridProps & { severity: BlockProps['severity'] }
>(({ theme, severity }) => ({
  padding: theme.spacing(6),
  width: '100%',
  height: '100%',
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  borderRadius: theme.spacing(8),
  background:
    severity == null || severity === 'none'
      ? theme.palette.lwBlock['default']
      : theme.palette.lwBlock['actionable'],
  boxShadow:
    '0px 4px 6px 0px rgba(51, 51, 51, 0.03), 0px 0px 8px 0px rgba(51, 51, 51, 0.03), 0px 12px 16px 0px rgba(51, 51, 51, 0.08)',
}));

const HeaderWrapper = styled(Box)<BoxProps>(() => ({
  width: '100%',
}));

const HeaderRow = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const IconWrap = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: 44,
  background: theme.palette.lwPrimary[10],
}));

const IconBox = styled(Box)<BoxProps>(() => ({
  height: '24px',
}));

const TitleWrap = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  gap: theme.spacing(1),
}));

const TitleWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
}));

const StyledTitle = styled(Box)<BoxProps>(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Urbanist',
  fontSize: 16,
  fontWeight: 700,
  fontVariationSettings: '"wght" 700',
  lineHeight: 'normal',
  color: theme.palette.lwBlack[100],
}));

const StyledSubtitle = styled(Box)<BoxProps>(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.lwBlack[60],
}));

const DataBlockWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  margin: 0,
  rowGap: theme.spacing(4),
  columnGap: 0,
  marginTop: theme.spacing(5),
}));

const LabelWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1.5),
}));

const DataBlockLabel = styled(Box)<BoxProps>(({ theme }) => ({
  fontFamily: 'Inter',
  overflow: 'hidden',
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.lwBlack[60],
}));

const DataBlockData = styled(Box)<BoxProps>(({ theme }) => ({
  fontFamily: 'Inter',
  width: '100%',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: 10,
  color: theme.palette.lwBlack[100],
}));

const NavigateContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'flex-end',
  gap: theme.spacing(2),

  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.lwBlack[60],
    '& div': {
      color: theme.palette.lwBlack[60],
    },
    '& img': {
      filter: 'invert(100%)',
    },
    '& button': {
      backgroundColor: theme.palette.lwBlack[10],
    },
  },
  '&:hover img': {
    filter: 'invert(0%)',
  },
}));

const NavigateLabel = styled(Box)<BoxProps>(({ theme }) => ({
  fontFamily: 'Urbanist',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '14px',
  color: theme.palette.lwBlack[100],
}));

const IconButtonStyled = styled(IconButton)(() => ({
  backgroundColor: 'black',
  height: '20px',
  width: '20px',
  marginLeft: 'auto',
}));

const ImgStyled = styled('img')(() => ({
  height: '10px',
  width: '10px',
  filter: 'invert(100%)',
}));

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& > :not(:last-child)': {
    marginRight: theme.spacing(1),
  },
}));

const EmploymentTypeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginBottom: 0,
  fontSize: '12px',
  lineHeight: '14px',
  fontFamily: 'Inter',
  height: 'fit-content',
  '& .MuiChip-label': {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  },
}));
