import { ShiftDetailResponse } from 'shared/services/shift-service-types';
import { RelationType } from 'ogp/hooks/queryHooks/flexWorkers/useGetFlexWorkerProfile';
import { formatFullName } from 'shared/utils/formatting';
import { ClaimCanceled, ClaimDetails, ClaimFlexWorker, PartnerWorker } from '@types';
import { ShiftClaimant } from '../../components/tabs/claimants/claimant-table.types';
import {
  ShiftApplicationCancelled,
  ShiftClaimCancelled,
} from '../../components/tabs/cancelled/cancelled-table.types';
import { ApplicantDetail } from '../../components/tabs/applicants/applicant-table.types';

export const mapShiftDetails = (shiftData: ShiftDetailResponse) => {
  const _claimedStates = [
    'accepted',
    'checkoutPending',
    'checkoutSettled',
    'counterOffer',
    'noShow',
  ];

  const claimants: ShiftClaimant[] = shiftData.claimants
    .filter((obj) => _claimedStates.includes(obj.state))
    .map((obj) => {
      const worker = getWorker(obj);

      const isWorkerInFlexPools =
        'flexPools' in worker && Array.isArray(worker.flexPools) && worker.flexPools.length > 0;

      return {
        claimId: obj.claimId,
        state: obj.state,
        workerId: worker.workerId,
        firstName: worker.firstName,
        lastName: worker.lastName,
        imageUrl: worker.isFlexWorker ? worker.imageUrl : undefined,
        relation: { id: obj.claimId, relation: RelationType.SHIFTCLAIM },
        isFlexWorker: worker.isFlexWorker,
        flexPoolIndicator: isWorkerInFlexPools,
        flexWorkerFullName: formatFullName(worker),
        email: worker.email,
        phone: worker.phone ?? '',
        noShow: obj.state === 'noShow',
        noShowReason: obj.noShowReason ?? null,
        hourlyRateCents: obj.hourlyRateCents,
        attendanceRate: obj.attendanceRate,
        partnerName: obj.partnerWorker?.partner.name,
        employmentType: obj.flexWorker?.employmentType,
      };
    });

  const _canceledStates = [
    'canceled',
    'denied',
    'cancelationOfferPending',
    'cancelationOfferSettled',
  ];

  const isDeclined = (state: string) => _canceledStates.includes(state);

  const canceledClaimants: ShiftClaimCancelled[] = shiftData.claimants
    .filter((obj): obj is ClaimCanceled => isDeclined(obj.state))
    .map((obj) => {
      const worker = getWorker(obj);
      const imageUrl = worker.isFlexWorker ? worker.imageUrl : 'defaultImageUrl';
      const flexPoolIndicator =
        'flexPools' in worker && Array.isArray(worker.flexPools) && worker.flexPools.length > 0;

      return {
        claimId: obj.claimId,
        state: obj.state,
        workerId: worker.workerId,
        firstName: worker.firstName,
        lastName: worker.lastName,
        imageUrl: imageUrl,
        isFlexWorker: worker.isFlexWorker,
        flexPoolIndicator: flexPoolIndicator,
        flexWorkerFullName: formatFullName(worker),
        email: worker.email,
        phone: worker.phone ?? '',
        hourlyRateCents: obj.hourlyRateCents,
        attendanceRate: obj.attendanceRate,
        cancelationReason: obj.cancelationReason,
        partnerName: obj.partnerWorker?.partner.name,
        employmentType: obj.flexWorker?.employmentType,
      };
    });

  const canceledApplicants: ShiftApplicationCancelled[] = shiftData.applicants
    .filter((app) => isDeclined(app.state))
    .map((app) => {
      const worker = app.flexWorker;

      const flexPoolIndicator = (worker.flexPools?.length ?? 0) > 0;

      return {
        applicationId: app.applicationId,
        state: app.state,
        workerId: worker.workerId,
        firstName: worker.firstName,
        lastName: worker.lastName,
        imageUrl: worker.imageUrl,
        flexPoolIndicator: flexPoolIndicator,
        flexWorkerFullName: formatFullName(worker),
        email: worker.email,
        phone: worker.phone ?? '',
        hourlyRateCents: app.hourlyRateCents,
        hasCandidateLevel: app.hasCandidateLevel,
        attendanceRate: app.workedTotal,
        cancelationReason: app.cancelationReason,
        employmentType: app.flexWorker?.employmentType,
      };
    });

  const applicants: ApplicantDetail[] = (shiftData.applicants ?? [])
    .filter(({ state }) => state === 'pending')
    .map((app) => {
      return {
        applicationId: app.applicationId,
        state: app.state,
        firstName: app.flexWorker.firstName,
        lastName: app.flexWorker.lastName,
        imageUrl: app.flexWorker.imageUrl,
        workerId: app.flexWorker.workerId,
        rating: app.ratingPercentage,
        shiftId: shiftData.id,
        attendanceRate: app.attendanceRate,
        hourlyRateCents: app.hourlyRateCents,
        hasCandidateLevel: app.hasCandidateLevel,
        hasRequiredCertificates: app.hasRequiredCertificates,
        allApplications: app.allApplications,
        workedTotal: app.workedTotal,
        phone: app.flexWorker.phone,
        ratingPercentage: app.ratingPercentage,
        experienceInMonths: app.flexWorker.experienceInMonths,
        distanceInKm: app.flexWorker.distanceInKm,
        employmentType: app.flexWorker?.employmentType,
      };
    });

  const pendingApplicants = shiftData?.applicants.filter(({ state }) => state === 'pending');
  const isThereApplicants = !!pendingApplicants?.length;

  return {
    cancelled: [...canceledApplicants, ...canceledClaimants],
    applicants: applicants,
    claimants: claimants,
    pendingApplicants,
    isThereApplicants,
  };
};

const getWorker = (
  obj: ClaimDetails
): (PartnerWorker & { isFlexWorker: false }) | (ClaimFlexWorker & { isFlexWorker: true }) => {
  if (obj.partnerWorker) {
    return {
      ...obj.partnerWorker,
      isFlexWorker: false,
    };
  }

  return {
    ...obj.flexWorker,
    isFlexWorker: true,
  };
};
