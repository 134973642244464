import { AvailabilityStatus } from '../flex-workers-for-work.types';

export const getAvailabilityStatusAndColor = (
  availabilityState: string
): { availability: string; color: string } => {
  switch (availabilityState) {
    case AvailabilityStatus.CONDITIONAL:
      return { availability: 'Voorwaardelijk', color: '#A7E741' };
    case AvailabilityStatus.UNAVAILABLE:
      return { availability: 'Niet beschikbaar', color: '#CC4400' };
    case AvailabilityStatus.AVAILABLE:
      return { availability: 'Beschikbaar', color: '#00CC88' };
    default:
      return { availability: availabilityState, color: 'black' };
  }
};

const MONTHS_IN_YEAR = 12;

export function convertToExperienceFormat(months: number | null | undefined): string {
  if (!months || months < 1) {
    return '-';
  } else if (months < MONTHS_IN_YEAR) {
    return `${months} mnd`;
  } else {
    const monthPart = months % MONTHS_IN_YEAR ? ` ${months % MONTHS_IN_YEAR} mnd` : '';
    return `${Math.floor(months / MONTHS_IN_YEAR)} jr${monthPart}`;
  }
}
