import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useGetJobs } from 'hq/hooks/queryHooks/jobs/use-get-jobs';
import { useGetCompanyEmploymentTypes } from 'hq/hooks/queryHooks/companies/use-get-company-employment-types';
import { ShiftForm } from '../components/forms/shift-form/shift-form';
import { useHqGetShiftCreateData } from '../hooks/hq/use-hq-get-shift-create-data';
import { useHqGetJob } from '../hooks/hq/use-hq-get-job';
import { FormMode } from '../components/forms/shared/types';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../types/shift-forms.types';

type ShiftFormProps = {
  mode: FormMode;
};

export const HqShiftForm = ({ mode }: ShiftFormProps) => {
  const { watch, reset } = useFormContext<
    { companyId: string } & (FreelanceShiftFormData | TempWorkShiftFormData)
  >();
  const { companyId, jobId } = watch();
  const shiftCreateDataQuery = useHqGetShiftCreateData(companyId);
  const employmentTypeOfCompanyQuery = useGetCompanyEmploymentTypes(companyId);
  const jobsQuery = useGetJobs(undefined, companyId, !!companyId);
  const selectedJobQuery = useHqGetJob(jobId);

  useEffect(() => {
    reset({ companyId });
  }, [companyId, reset]);

  return (
    <ShiftForm
      mode={mode}
      isDisabled={
        jobsQuery.status !== 'success' ||
        shiftCreateDataQuery.status !== 'success' ||
        mode === 'read'
      }
      jobsData={jobsQuery.data ?? []}
      shiftCreateData={
        shiftCreateDataQuery.data ?? { branchOfficesWithDepartments: [], breakOptions: [] }
      }
      employmentTypeOfCompany={employmentTypeOfCompanyQuery.data}
      jobData={selectedJobQuery.data}
      allowDateBeforeToday={true}
    />
  );
};
