import { FreelanceJobDetailsWithId, isFreelanceJob, TempWorkJobDetailsWithId } from '@types';
import { HelperTexts } from '../types';

export const getHelperTexts = (
  jobData: FreelanceJobDetailsWithId | TempWorkJobDetailsWithId | undefined
): HelperTexts => {
  if (!jobData) {
    return {
      hourlyRate: undefined,
      breakMinutes: undefined,
    };
  }
  const { breakMinutes } = jobData;
  const result: HelperTexts = {
    breakMinutes: `De standaard voor deze functie is ${breakMinutes} min`,
  };
  if (isFreelanceJob(jobData)) {
    result.hourlyRate = `De standaard voor deze functie is ${(jobData.freelanceJob.hourlyRateCents || 0) / 100} €`;
  }
  return result;
};
