import { adminSettingsService } from 'hq/services';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { QUERY_KEYS_SHARED } from 'shared/react-query/query-keys';
import { FeatureFlags } from 'shared/services/mappers/mappers';

type FeatureFlagMutationOptions = UseMutationOptions<unknown, unknown, Partial<FeatureFlags>>;

export const useUpdateFeatureFlags = () => {
  const mutationFn = async (featureFlags: Partial<FeatureFlags>) =>
    await adminSettingsService.updateFeatureFlags(featureFlags);
  const queryClient = useQueryClient();
  const mutationOptions: FeatureFlagMutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_SHARED.featureFlags());
      queryClient.invalidateQueries(QUERY_KEYS_SHARED.environmentConfiguration());
    },
  };

  return useMutation(mutationFn, mutationOptions);
};
