import { Box, Divider } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { RenderUnsettledUI, SmallError, SmallLoader } from 'shared/components';
import { invariant } from 'shared/utils/utils';
import { useFeatureFlagContext } from 'shared/contexts/feature-flag-provider';
import { PartnerWorkerDetailModalHead } from './partner-worker-detail-modal-header/partner-worker-detail-modal-header';
import { PartnerWorkerDetailAccreditations } from './partner-worker-detail-accreditations';
import { useGetPartnerWorker } from '../hooks/use-get-partner-worker';

export const PartnerWorkerDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  invariant(id, 'ID must be passed as route param');

  const workerQuery = useGetPartnerWorker(id);
  const { allowEmploymentId } = useFeatureFlagContext();

  if (workerQuery.status !== 'success') {
    return (
      <RenderUnsettledUI
        renderLoading={<SmallLoader />}
        renderError={(error) => <SmallError error={error} />}
        data={workerQuery}
      />
    );
  }

  return (
    <Box
      p={8}
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="partner-planner-modal"
    >
      <PartnerWorkerDetailModalHead
        data={workerQuery.data}
        close={() => setSearchParams(() => ({}))}
        allowEmploymentId={allowEmploymentId}
      />
      <Box mt={8} mb={8}>
        <Divider />
      </Box>
      <PartnerWorkerDetailAccreditations accreditations={workerQuery.data.accreditations} />
    </Box>
  );
};
