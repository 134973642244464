import { useGetOGPUserInfo } from 'ogp/hooks';
import { hasAccessWithinPolicyGroup, PolicyGroups } from 'shared/utils/policy-controller';
import { CompanyRole } from '@types';
import { useSiteDetection } from './use-site-detection';

const useHqAdmin = () => {
  // TODO HOTFIX was done in useHqAdmin so it works for HQ as well but this must be refactored
  const isHq = useSiteDetection().isHQ;

  const userQuery = useGetOGPUserInfo();
  const isHqAdmin =
    userQuery.data &&
    hasAccessWithinPolicyGroup(PolicyGroups.COMPANY, userQuery.data.userInfo, CompanyRole.HQADMIN);
  return (isHq || isHqAdmin) ?? false;
};

export { useHqAdmin };
