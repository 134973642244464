import { useQuery } from 'react-query';
import { QUERY_KEYS_SHARED } from 'shared/react-query/query-keys';
import { SharedApiService } from 'shared/services';
import { environmentConfigurationResponse2FeatureFlagsModel } from 'shared/services/mappers/mappers';

const queryFn = async () => await SharedApiService.getEnvironmentConfiguration();

const useGetEnvironmentConfiguration = (enabled: boolean) =>
  useQuery(QUERY_KEYS_SHARED.environmentConfiguration(), {
    queryFn,
    enabled,
  });

const useGetFeatureFlags = () =>
  useQuery(QUERY_KEYS_SHARED.featureFlags(), {
    queryFn,
    select: environmentConfigurationResponse2FeatureFlagsModel,
  });

export { useGetEnvironmentConfiguration, useGetFeatureFlags };
