import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, Page } from 'redesign';
import { shiftAlerts, useAlert } from 'shared/components/alerts';
import { DateTime } from 'luxon';
import { useUpdatePlanningPath } from 'ogp/hooks/planning';
import { paths } from 'ogp/paths';
import { useCompanyRelativePath } from 'ogp/hooks';
import { ToolboxFormCreate } from '../../../common/toolbox-form-create';
import { transformData } from '../../utils/transform-data';
import { useOgpCreateShift } from '../../hooks/ogp/use-ogp-create-shift';
import { OgpShiftForm } from '../ogp-shift-form';
import { FreelanceShiftFormData, TempWorkShiftFormData } from '../../types/shift-forms.types';

export const OgpShiftCreate = () => {
  const { alertSuccess, alertError } = useAlert();
  const formMethods = useForm<
    | FreelanceShiftFormData
    | TempWorkShiftFormData
    | (FreelanceShiftFormData & TempWorkShiftFormData)
  >();
  const updatePlanningPath = useUpdatePlanningPath();
  const generatePath = useCompanyRelativePath();
  const navigate = useNavigate();

  const mutation = useOgpCreateShift({
    onSuccess: (shiftId, payload) => {
      const shiftDetailPath = updatePlanningPath({
        shiftDetailId: shiftId,
        date: DateTime.fromISO(payload.startDate),
      });
      alertSuccess(shiftAlerts.success.created);
      navigate(shiftDetailPath);
    },
    onError: (e) => alertError(e),
  });

  const onCancel = useCallback(() => {
    navigate(generatePath(paths.Shifts));
  }, [navigate, generatePath]);

  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit(
      (
        shiftFormData:
          | FreelanceShiftFormData
          | TempWorkShiftFormData
          | (FreelanceShiftFormData & TempWorkShiftFormData)
      ) => {
        const data = transformData(shiftFormData);
        mutation.mutate({ ...data, jobId: shiftFormData.jobId });
      }
    )();
  }, [formMethods, mutation]);

  return (
    <FormProvider {...formMethods}>
      <Page
        header={<Header titleText="Nieuwe shift aanmaken" />}
        toolbox={
          <ToolboxFormCreate
            onSubmit={handleSubmit}
            mutationStatus={mutation.status}
            onCancel={onCancel}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <OgpShiftForm mode="create" />
          </Grid>
        </Grid>
      </Page>
    </FormProvider>
  );
};
