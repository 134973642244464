import { Box, Divider, Grid, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ProjectJobDetails } from './project-job-details';
import {
  GetProjectFormInputsProps,
  useGetProjectFormInputs,
} from '../hooks/use-get-project-form-inputs';
import { ProjectFormType } from '../types/project-form.types';

type ProjectFormProps = GetProjectFormInputsProps;

export const ProjectForm = (options: ProjectFormProps) => {
  const formInputs = useGetProjectFormInputs(options);
  const { watch } = useFormContext<ProjectFormType>();

  const jobId = watch('jobId');

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box display="flex" flexDirection="row" height="100%">
          <form aria-label="Projects form">
            <Grid container item spacing={4} xs={12}>
              {...formInputs.map((element, i) => (
                <Grid key={i} item xs={6}>
                  {element}
                </Grid>
              ))}
            </Grid>
          </form>
          <StyledDivider />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <ProjectJobDetails selectedJobId={jobId} />
      </Grid>
    </Grid>
  );
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(7),
  width: 2,
}));
