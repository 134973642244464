import {
  BreakOption,
  FreelanceJobDetailsWithId,
  JobListItem,
  JobPayload,
  TempWorkJobDetailsWithId,
  UpdateJobMutationData,
} from '@types';
import { PrebuiltBlock } from 'redesign';
import {
  CreateJobResponse,
  GetBreakOptionsResponse,
  GetFilteredJobsListingResponse,
  GetJobCreateDataResponse,
  GetJobDetailsForWorkTemplateResponse,
} from './job-service.types';
import { OgpApiService } from './ogp-api-service';

export class JobService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  /**
   * Create a new job
   */
  public createJob = async (job: JobPayload): Promise<CreateJobResponse> => {
    return await this.ogpApiService.create<CreateJobResponse>({
      body: job,
      path: `jobs`,
    });
  };

  public getJobCreateData = async () => {
    const response = await this.ogpApiService.get<GetJobCreateDataResponse>({
      path: `jobs/get-job-create-data`,
    });

    return response;
  };

  /**
   * Update an existing jobs
   */
  public updateJob = async ({ id, ...job }: UpdateJobMutationData) => {
    await this.ogpApiService.update({
      body: job,
      path: `jobs/${id}`,
    });
  };

  /**
   * Get a list of existing jobs
   */
  public getFilteredJobsForCompany = async (departmentId?: string): Promise<JobListItem[]> => {
    const response = await this.ogpApiService.get<{ jobs: JobListItem[] }>({
      path: 'jobs',
      params: { departmentId },
    });

    return response.jobs;
  };

  public getFilteredJobsListing = async (departmentId?: string): Promise<PrebuiltBlock[]> => {
    const response = await this.ogpApiService.get<GetFilteredJobsListingResponse>({
      path: 'jobs/listing',
      params: { departmentId },
    });

    return response.jobs;
  };

  /**
   * Get a single job
   */
  public getJob = async <T = TempWorkJobDetailsWithId | FreelanceJobDetailsWithId>(
    jobId: string
  ): Promise<T> => {
    const response = await this.ogpApiService.get<{ job: T }>({
      path: `jobs/${jobId}`,
    });

    return response.job;
  };

  public getJobDetailsForWorkTemplate = async (
    jobId: string
  ): Promise<GetJobDetailsForWorkTemplateResponse> => {
    const jobDetails = await this.ogpApiService.get<GetJobDetailsForWorkTemplateResponse>({
      path: `jobs/${jobId}/details-for-work-template`,
    });

    return jobDetails;
  };

  public getBreakOptions = async (jobId: string): Promise<BreakOption[]> => {
    const response = await this.ogpApiService.get<GetBreakOptionsResponse>({
      path: `jobs/${jobId}/break`,
    });

    return response.breakOptions;
  };

  public archiveJob = async (jobId: string) =>
    await this.ogpApiService.delete<undefined>({
      path: `jobs/${jobId}`,
    });
}
