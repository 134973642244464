import { Avatar, Badge, ListItemAvatar, styled } from '@mui/material';
import { useMemo } from 'react';
import { FlexWorkerListItem } from '@types';

export const FlexWorkerAvatar = ({ flexWorker }: { flexWorker: FlexWorkerListItem }) => {
  const initials = useMemo(
    () =>
      flexWorker.firstName && flexWorker.lastName
        ? flexWorker.firstName.charAt(0) + flexWorker.lastName.charAt(0)
        : '',
    [flexWorker]
  );

  return (
    <ListItemAvatarStyled>
      <StyledBadge
        variant="dot"
        overlap="circular"
        invisible={!flexWorker.hasPendingIdentityVerification}
        actionable={flexWorker.hasPendingIdentityVerification}
      >
        <StyledAvatar src={flexWorker.profilePictureUrl}>{initials}</StyledAvatar>
      </StyledBadge>
    </ListItemAvatarStyled>
  );
};

const AVATAR_SIZE = 80;
const DOT_SIZE = AVATAR_SIZE / 5;

const ListItemAvatarStyled = styled(ListItemAvatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginTop: 0,
}));

const StyledBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== 'actionable' })<{
  actionable: boolean;
}>(({ actionable }) => ({
  '.MuiBadge-anchorOriginTopRightCircular': {
    right: '15%',
    top: '15%',
  },
  '.MuiBadge-dot': {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
    backgroundColor: actionable ? '#ffee00' : undefined,
  },
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
}));
