import { branchOfficeAlerts } from 'shared/components/alerts';
import { BranchOfficeFormData, VAT } from '@types';
import { RenderUnsettledUI } from 'shared/components';
import { BranchOfficeCreatePage } from './branch-office-create-page';
import { useGetCompanyDetailAndJobGroups } from '../../../../../../hooks/queryHooks/companies/use-get-company-detail-and-job-groups';

export const BranchOfficeCreateDataProvider = () => {
  const companyQuery = useGetCompanyDetailAndJobGroups();

  if (companyQuery.status !== 'success') {
    return <RenderUnsettledUI data={companyQuery} />;
  }

  const defaultValues: Partial<BranchOfficeFormData> = {
    companyNameForOVO: companyQuery.data?.company.name,
    VAT: VAT.HIGH,
  };

  return (
    <BranchOfficeCreatePage
      defaultValues={defaultValues}
      successMessage={branchOfficeAlerts.success.created}
      pageTitle={`Nieuwe vestiging aanmaken`}
    />
  );
};
