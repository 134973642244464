import { OgpVersionInfo } from 'shared/components/site-info/site-info.types';
import { AbstractApiService } from 'shared/services/abstractApiService';

export class VersionService {
  constructor(private readonly apiService: AbstractApiService) {}

  public async getVersion(): Promise<OgpVersionInfo> {
    return this.apiService.get({
      path: `version`,
    });
  }
}
