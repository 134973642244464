import { Box, BoxProps, styled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

export const HoverableLink = styled(Link)<LinkProps>(() => ({
  textDecoration: 'none',
  display: 'block',
  borderRadius: 34,

  ' &:hover': {
    boxShadow: ' 0px 12px 16px rgba(51, 51, 51, 0.16), 0px 4px 6px rgba(51, 51, 51, 0.08)',
    transition: '0.5s',
  },
}));

export const HoverableBlock = styled(Box)<BoxProps>(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  display: 'block',
  borderRadius: 34,

  ' &:hover': {
    boxShadow: ' 0px 12px 16px rgba(51, 51, 51, 0.16), 0px 4px 6px rgba(51, 51, 51, 0.08)',
    transition: '0.5s',
  },
}));
