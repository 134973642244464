import { partnerPlannerService } from 'partner/shared/services';
import { QUERY_KEYS_PARTNER } from 'partner/shared/hooks/partner-query-keys';
import { useQuery } from 'react-query';
import { BusinnesUnitFilter, JobTypeFilter } from '../table/hooks/use-shift-planning-page.types';

const useGetShiftPlanningBusinessUnitsData = (params: JobTypeFilter) => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.shiftPlanningBusinessUnitsFilters(),
    queryFn: async () => await partnerPlannerService.getShiftPlanningBusinessUnitsData(params),
  });
};

const useGetShiftPlanningJobTypesData = (params: BusinnesUnitFilter) => {
  return useQuery({
    queryKey: QUERY_KEYS_PARTNER.shiftPlanningJobTypesFilters(),
    queryFn: async () => await partnerPlannerService.getShiftPlanningJobTypesData(params),
  });
};

export { useGetShiftPlanningJobTypesData, useGetShiftPlanningBusinessUnitsData };
