import {
  IconCheckouts,
  IconInbox,
  IconMail,
  IconPlanning,
  IconProfilePlacementUser,
  IconUser,
  IconUsers,
} from 'assets/img';
import { PARTNER_PATHS } from 'partner/paths';
import { LogoutIcon, LogoutLabel } from './sidebar-logout';

const items = {
  navigation: [
    {
      ...PARTNER_PATHS.ShiftsPlanning,
      label: 'Planning',
      icon: <IconPlanning />,
    },
    {
      ...PARTNER_PATHS.Placement,
      label: 'Plaatsingen',
      icon: <IconProfilePlacementUser />,
    },
    {
      ...PARTNER_PATHS.Inbox,
      label: 'Inbox',
      icon: <IconMail />,
    },
    {
      ...PARTNER_PATHS.Checkout,
      label: 'Checkouts',
      icon: <IconInbox />,
    },
    {
      ...PARTNER_PATHS.CheckedOut,
      label: 'Checked out',
      icon: <IconCheckouts />,
    },
    {
      ...PARTNER_PATHS.Worker,
      label: 'Kandidaten',
      icon: <IconUsers />,
    },
  ],
  profile: [
    {
      label: 'Profiel',
      icon: <IconUser />,
    },
    {
      label: <LogoutLabel />,
      icon: <LogoutIcon />,
    },
  ],
};

export { items };
