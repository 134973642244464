import { Divider, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FlexWorkerData } from '@types';

type FlexWorkerDetailsViewParams = {
  flexWorkerData: FlexWorkerData;
};

const FlexWorkerPersonalData = ({ flexWorkerData }: FlexWorkerDetailsViewParams) => {
  const korStartDate = flexWorkerData.smallBusinessSchemeStartDate
    ? DateTime.fromISO(flexWorkerData.smallBusinessSchemeStartDate).toFormat('dd/LL/yyyy')
    : null;
  const korEndDate = flexWorkerData.smallBusinessSchemeEndDate
    ? DateTime.fromISO(flexWorkerData.smallBusinessSchemeEndDate).toFormat('dd/LL/yyyy')
    : null;

  // Calculate age based on date of birth, round down to whole years
  const age = flexWorkerData.dateOfBirth
    ? Math.floor(
        Math.abs(
          DateTime.fromFormat(flexWorkerData.dateOfBirth, 'yyyy-LL-dd').diffNow('years').years
        )
      )
    : null;

  // Make it readable for the user
  const formattedDateOfBirthWithAge = flexWorkerData.dateOfBirth
    ? `${flexWorkerData.dateOfBirth} (${age} jaar)`
    : '-';

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography variant="h1">Persoonsgegevens</Typography>
      </Grid>
      <Grid item container xs={12} spacing={4} marginLeft={0}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Voornaam</Typography>
          <Typography variant="body1">{flexWorkerData.firstName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Tussenvoegsel</Typography>
          <Typography variant="body1">{flexWorkerData.infix}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Achternaam</Typography>
          <Typography variant="body1">{flexWorkerData.lastName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="body1">{flexWorkerData.email}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Telefoonnummer</Typography>
          <Typography variant="body1">{flexWorkerData.phone}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Straat</Typography>
          <Typography variant="body1">{flexWorkerData.location?.street ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Huisnummer</Typography>
          <Typography variant="body1">{flexWorkerData.location?.houseNumber ?? '-'}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">Postcode</Typography>
          <Typography variant="body1">{flexWorkerData.location?.postalCode ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Stad</Typography>
          <Typography variant="body1">{flexWorkerData.location?.city ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Geboortedatum</Typography>
          <Typography variant="body1" data-testid="dateOfBirth">
            {formattedDateOfBirthWithAge}
          </Typography>
        </Grid>
        {flexWorkerData.bsnProvided ? (
          <Grid item xs={4}>
            <Typography variant="subtitle1">BSN</Typography>
            <Typography variant="body1">Ja</Typography>
          </Grid>
        ) : null}
        <Grid item xs={4}>
          <Typography variant="subtitle1">Bio</Typography>
          <Typography variant="body1">{flexWorkerData.bioProvided ? 'Ja' : 'Nee'}</Typography>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h1">Zakelijke gegevens</Typography>
      </Grid>
      <Grid item container xs={12} spacing={4} marginLeft={0}>
        <Grid item xs={4} marginLeft={0}>
          <Typography variant="subtitle1">Bedrijfsnaam</Typography>
          <Typography variant="body1">{flexWorkerData.companyName ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">KVK-nummer</Typography>
          <Typography variant="body1">{flexWorkerData.cocNumber ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Btw-identificatienummer</Typography>
          <Typography variant="body1">{flexWorkerData.vatNumber ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Straat</Typography>
          <Typography variant="body1">{flexWorkerData?.companyLocation?.street ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Huisnummer</Typography>
          <Typography variant="body1">
            {flexWorkerData?.companyLocation?.houseNumber ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Postcode</Typography>
          <Typography variant="body1">
            {flexWorkerData?.companyLocation?.postalCode ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Stad</Typography>
          <Typography variant="body1">{flexWorkerData?.companyLocation?.city ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">KOR Startdatum</Typography>
          <Typography variant="body1">{korStartDate ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">KOR Einddatum</Typography>
          <Typography variant="body1">{korEndDate ?? '-'}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h1">Financiele gegevens</Typography>
      </Grid>
      <Grid item container xs={12} spacing={4} marginLeft={0}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Rekeningnummer</Typography>
          <Typography variant="body1">{flexWorkerData.bankAccountNumber ?? '-'}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Naam rekeninghouder</Typography>
          <Typography variant="body1">{flexWorkerData.bankAccountAscription ?? '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { FlexWorkerPersonalData };
