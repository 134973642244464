import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';

// These are variants of the typography from figma

// # | font | font-weight | font-size | line-height

// 01 | inter    | 400 | 10px | 12px
// 02 | inter    | 500 | 12px | 18px
// 03 | inter    | 500 | 14px | 20px
// 04 | inter    | 500 | 14px | 24px
// 05 | inter    | 500 | 16px | 20px
// 06 | inter    | 600 |  8px |  8px
// 07 | inter    | 600 | 12px | 16px
// 08 | inter    | 600 | 12px | 18px
// 09 | inter    | 600 | 14px | 16px
// 10 | inter    | 600 | 14px | 20px
// 11 | inter    | 600 | 16px | 20px
// 12 | inter    | 700 | 14px | 20px
// 13 | urbanist | 500 | 14px | 20px
// 14 | urbanist | 600 | 14px | 20px
// 15 | urbanist | 600 | 16px | 20px
// 16 | urbanist | 700 | 12px | 14px
// 17 | urbanist | 700 | 16px | 20px
// 18 | urbanist | 700 | 9px  | 10px
// 19 | urbanist | 700 | 20px | 24px
// 20 | urbanist | 700 | 24px | 32px

// Typography variant

// h2
// h3
// h5

// subtitle1
// subtitle2

// body1
// body2

// button
// caption
//

// Naming convention:
// <font-family><font-size>l<line-height>w<font-weight>
// Urbanist14l20w500

// 01 | inter    | 400 | 10px | 12px
export const Inter10l12w400 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '10px',
  lineHeight: '12px',
  fontWeight: '400',
  color: 'black',
}));

// 02 | inter    | 500 | 12px | 18px
export const Inter12l18w500 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: '500',
  color: 'black',
}));

// 03 | inter    | 500 | 14px | 20px
export const Inter14l20w500 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '500',
  color: 'black',
}));

// 04 | inter    | 500 | 14px | 24px
export const Inter14l24w500 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '500',
  color: 'black',
}));

// 05 | inter    | 500 | 16px | 20px
export const Inter16l20w500 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '500',
  color: 'black',
}));

// 06 | inter    | 600 |  8px |  8px
export const Inter8l8w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '8px',
  lineHeight: '8px',
  fontWeight: '600',
  color: 'black',
}));

// 07 | inter    | 600 | 12px | 16px
export const Inter12l16w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: '600',
  color: 'black',
}));

// 08 | inter    | 600 | 12px | 18px
export const Inter12l18w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: '600',
  color: 'black',
}));

// 09 | inter    | 600 | 14px | 16px
export const Inter14l16w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '600',
  color: 'black',
}));

// 10 | inter    | 600 | 14px | 20px
export const Inter14l20w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color: 'black',
}));

// 11 | inter    | 600 | 16px | 20px
export const Inter16l20w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '600',
  color: 'black',
}));

// 12 | inter    | 700 | 14px | 20px
export const Inter14l20w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '700',
  color: 'black',
}));

// 13 | urbanist | 500 | 14px | 20px
export const Urbanist14l20w500 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '500',
  color: 'black',
}));

// 14 | urbanist | 600 | 14px | 20px
export const Urbanist14l20w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color: 'black',
}));

// 15 | urbanist | 600 | 16px | 20px
export const Urbanist16l20w600 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '600',
  color: 'black',
}));

// 16 | urbanist | 700 | 12px | 14px
export const Urbanist12l14w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '700',
  color: 'black',
}));

// 17 | urbanist | 700 | 16px | 20px
export const Urbanist16l20w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '700',
  color: 'black',
}));

// 18 | urbanist | 700 | 9px  | 10px
export const Urbanist9l10w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '9px',
  lineHeight: '10px',
  fontWeight: '700',
  color: 'black',
}));

// 19 | urbanist | 700 | 20px | 24px
export const Urbanist20l24w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '700',
  color: 'black',
}));

// 20 | urbanist | 700 | 24px | 32px
export const Urbanist24l32w700 = styled(Box)<BoxProps>(() => ({
  fontFamily: 'Urbanist',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: '700',
  color: 'black',
  whiteSpace: 'nowrap',
}));
