import { Box, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { AddButton, AutocompleteOption, LwFormInput, LwFormSelect } from 'redesign';
import { AccreditationsToolboxForm } from './accreditations-toolbox.types';
import { AccreditationParams, SetAccreditationParams, stateValues } from '../accreditations.types';
import {
  HQCertificateResponse,
  RawAccreditationData,
} from '../../../../services/accreditations-service.types';

export const AccreditationsToolbox = ({
  certificateData,
  params,
  setParams,
}: {
  certificateData: HQCertificateResponse[];
  data: RawAccreditationData[];
  params: AccreditationParams;
  setParams: SetAccreditationParams;
}) => {
  const form = useForm<AccreditationsToolboxForm>({
    defaultValues: {
      filter: params.filter,
      state: params.state,
      certificate: params.certificate,
    },
  });

  const certificateOptions: AutocompleteOption[] = useMemo(() => {
    const result = certificateData.map((certificate) => ({
      value: certificate.id,
      label: certificate.name,
    }));
    result.unshift({ label: 'Alle certificaten', value: 'alle' });
    return result;
  }, [certificateData]);

  const stateOptions: AutocompleteOption[] = useMemo(() => {
    return stateValues.map((stateValue) => ({
      value: stateValue,
      label: capitalizeFirstLetter(stateValue),
    }));
  }, [stateValues]);

  const handleCertificateChange = useCallback(
    (event: SelectChangeEvent<AccreditationParams['certificate']>): void => {
      const value = event.target.value as string;
      setParams({ certificate: value });
    },
    []
  );
  const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setParams({ filter: e.target.value });
  }, []);

  const handleStateChange = useCallback(
    (event: SelectChangeEvent<AccreditationParams['state']>) => {
      setParams({ state: event.target.value as AccreditationParams['state'] });
    },
    []
  );

  return (
    <>
      <Box display="flex" flex={1}>
        <LwFormInput
          name="filter"
          control={form.control}
          label="Zoeken"
          placeholder="Filter certificaten op flexwerker naam"
          onChange={handleFilterChange}
        />
      </Box>
      <Box display="flex" flex={1}>
        <LwFormSelect
          control={form.control}
          name="state"
          label="Status"
          options={stateOptions}
          onChange={handleStateChange}
        />
      </Box>
      <Box display="flex" flex={1}>
        <LwFormSelect
          control={form.control}
          name="certificate"
          label="Certificaattype"
          options={certificateOptions}
          onChange={handleCertificateChange}
        />
      </Box>
      <Box display="flex" flex={1}>
        <AddButton>Certificaat toevoegen</AddButton>
      </Box>
    </>
  );
};

const capitalizeFirstLetter = (input: string) => {
  return input.slice(0, 1).toUpperCase().concat(input.slice(1));
};
