import { Box, Grid, Paper, styled, Tooltip, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import WorkIcon from '@mui/icons-material/Work';
import { FlexWorkerProfileHQ } from '@types';

const ProfileAbout = ({ data }: { data: FlexWorkerProfileHQ }) => {
  return (
    <Box marginTop={4}>
      <Grid container item xs={9}>
        <BioGrid container item xs={12}>
          <Typography variant="h3">Over {data.firstName}</Typography>
          <Typography variant="body1" aria-label="bio-text">
            {data.bio ?? 'De flexwerker stelt zich hier graag even aan je voor!'}
          </Typography>
        </BioGrid>
        <StatsGrid container item xs={12}>
          <Tooltip title="Beoordeling" aria-label="rating">
            <BlockCard elevation={3}>
              <Typography>{data.calculatedRating ?? '-'}</Typography>
              <StyledThumbUpIcon />
            </BlockCard>
          </Tooltip>
          <Tooltip title="Opkomst" aria-label="attendance">
            <BlockCard elevation={3}>
              <Typography>{data.attendanceRate ?? '-'}</Typography>
              <StyledEmojiPeopleIcon />
            </BlockCard>
          </Tooltip>
          <Tooltip title="Shifts" aria-label="worked">
            <BlockCard elevation={3}>
              <Typography>{data.workedShifts ?? '-'}</Typography>
              <StyledWorkIcon />
            </BlockCard>
          </Tooltip>
        </StatsGrid>
      </Grid>
    </Box>
  );
};

const StatsGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: '100%',
}));

const BioGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  marginTop: 5,
  marginBottom: 5,
  width: '100%',
}));

const BlockCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  marginRight: theme.spacing(3),
  width: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const StyledWorkIcon = styled(WorkIcon)(({ theme }) => ({
  fill: '#ffce2a',
  marginTop: theme.spacing(0.5),
}));

const StyledThumbUpIcon = styled(ThumbUpIcon)(({ theme }) => ({
  fill: '#ffce2a',
  marginTop: theme.spacing(0.5),
}));

const StyledEmojiPeopleIcon = styled(EmojiPeopleIcon)(({ theme }) => ({
  fill: '#ffce2a',
  marginTop: theme.spacing(0.5),
}));

export { ProfileAbout };
