import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { jobService } from 'ogp/services';
import { useQuery } from 'react-query';
import { PrebuiltBlock } from 'redesign';

export const useGetJobsListing = (departmentId?: string) => {
  const queryFn = async () => await jobService.getFilteredJobsListing(departmentId);

  return useQuery<Promise<PrebuiltBlock[]>, Error, PrebuiltBlock[]>(
    QUERY_KEYS_OGP.jobListing(departmentId),
    queryFn
  );
};
