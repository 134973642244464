import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconMore } from 'assets/img';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { useToggle } from 'shared/hooks';
import { ProviderFactory } from 'shared/providers/provider-factory';
import { LwIconButton } from 'redesign';
import { ContextMenuProps } from './context-menu.types';

const [ContextMenuProvider, useContextMenuProvider] = ProviderFactory<() => void>(
  'useContextMenu can only be used within ContextMenuContext'
);

const ContextMenuButton = ({
  loading,
  children,
  menuId,
  buttonClass: buttonClassName,
  color,
}: React.PropsWithChildren<ContextMenuProps>) => {
  const classes = getClasses();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { on: opened, toggle } = useToggle();

  return (
    <ContextMenuProvider value={toggle}>
      <LwIconButton
        iconColorMode="stroke"
        color={color ? color : 'default'}
        aria-controls={menuId}
        aria-haspopup="true"
        className={clsx(buttonClassName)}
        onClick={toggle}
        disabled={loading}
        ref={anchorEl}
        data-testid="action-icon-button"
      >
        {loading ? <CircularProgress size={16} /> : <IconMore />}
      </LwIconButton>

      <Menu
        id={menuId}
        open={opened}
        onClose={toggle}
        className={classes.menu}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: {
            className: classes.paper,
          },
        }}
      >
        {children}
      </Menu>
    </ContextMenuProvider>
  );
};

const ContextMenuItem = ({
  children,
  onClick,
  ...props
}: React.PropsWithChildren<
  { loading?: boolean } & Pick<React.ComponentProps<typeof MenuItem>, 'onClick' | 'disabled'>
>) => {
  const classes = getClasses();
  const closeContextMenu = useContextMenuProvider();

  const handleClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    onClick?.(e);
    closeContextMenu();
  };

  return (
    <MenuItem {...props} className={classes.menuItemHover} onClick={handleClick}>
      <Typography component="span" className={classes.lwMenuItem}>
        {children}
      </Typography>
    </MenuItem>
  );
};

const getClasses = makeStyles((theme: Theme) => ({
  menu: {
    marginTop: theme.spacing(4),
  },
  lwMenuItem: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Urbanist',
    fontWeight: 500,
    fontSize: '16px',
    padding: 0,
    color: 'rgba(0, 26, 153, 1)',
    gap: '10px',
    '&:hover': {
      color: 'rgba(0, 43, 255, 1)',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  menuItemHover: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'rgba(0, 43, 255, 0.05)',
      borderRadius: '12px',
    },
  },
  paper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export { ContextMenuButton, ContextMenuItem };
