import { OgpApiService } from './ogp-api-service';
import { CertificateDataOGP } from './accreditations-service.types';

class AccreditationsService {
  constructor(private readonly ogpApiService: OgpApiService) {}

  public async getAccreditations(flexWorkerId: string) {
    const response = await this.ogpApiService.get<CertificateDataOGP[]>({
      path: `accreditations/${flexWorkerId}`,
    });
    return response;
  }
}

export { AccreditationsService };
