import { styled } from '@mui/material';
import { IconCaretLeft } from 'assets/img';
import { usePartnerSidebarProvider } from './sidebar-provider';

const SidebarToggle = () => {
  const { isOpen, close, open } = usePartnerSidebarProvider();

  return (
    <StyledSidebarToggle folded={!isOpen} color="secondary" onClick={isOpen ? close : open}>
      <IconCaretLeft />
    </StyledSidebarToggle>
  );
};

const StyledSidebarToggle = styled('button', { shouldForwardProp: (prop) => prop !== 'folded' })<{
  folded: boolean;
}>(({ theme, folded }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: 0,
  borderRadius: '46px',
  bottom: '35px',
  boxShadow: `0px 8px 20px ${theme.palette.lwPrimary[10]}`,
  cursor: 'pointer',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  left: folded ? '90px' : '220px',
  opacity: 1,
  position: 'fixed',
  transform: folded ? 'rotateY(180deg)' : '',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: '56px',
  zIndex: 1200,

  '& svg path': {
    stroke: theme.palette.lwSecondary[100],
  },
}));

export { SidebarToggle };
