import { Box } from '@mui/material';
import {
  FlexWorkerFilterCounts,
  FlexWorkerListItem,
  PaginatedCollection,
  Pagination,
} from '@types';
import { FlexWorkerPagination } from './flex-worker-pagination';
import { FlexWorkersTable } from './flex-workers-table';

const FlexWorkerContent = ({
  filter,
  fwData,
  openModal,
  pagination,
}: {
  filter: string | undefined;
  fwData: PaginatedCollection<FlexWorkerListItem> & FlexWorkerFilterCounts;
  openModal: (flexWorkerId: string) => void;
  pagination: Pagination;
}) => {
  return (
    <Box display="flex" width="100%" maxHeight={'100%'} flexDirection={'column'}>
      <Box>
        <FlexWorkersTable filter={filter} openModal={openModal} flexWorkersData={fwData} />
      </Box>
      <Box mt="auto">
        <FlexWorkerPagination data={fwData} pagination={pagination} />
      </Box>
    </Box>
  );
};

export { FlexWorkerContent };
