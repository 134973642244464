import { companyService } from 'hq/services';
import { useQuery } from 'react-query';
import { CACHE_TIME_LONG } from 'shared/react-query/query-defaults';
import { QUERY_KEYS_HQ } from 'hq/query-keys';

export const useGetOrts = () => {
  const queryFn = async () => await companyService.getOrts();

  return useQuery(QUERY_KEYS_HQ.orts(), queryFn, {
    cacheTime: CACHE_TIME_LONG,
  });
};
