import { QUERY_KEYS_OGP } from 'ogp/query-keys';
import { billingEntityService } from 'ogp/services';
import { useQuery } from 'react-query';
import { billingEntitiesResponse2BillingEntitiesSelectModel } from 'shared/services/mappers/mappers';

type Response = Awaited<ReturnType<typeof billingEntityService.getBillingEntities>>;

const useGetBillingEntities = <TData = Response>(select?: (data: Response) => TData) => {
  const queryFn = async () => await billingEntityService.getBillingEntities();

  return useQuery(QUERY_KEYS_OGP.billingEntities(), queryFn, { select });
};

const useGetBillingEntitiesAsSelect = () => {
  const result = useGetBillingEntities(billingEntitiesResponse2BillingEntitiesSelectModel);
  return result;
};

export { useGetBillingEntitiesAsSelect };
